import { all, call, put, takeLatest } from 'redux-saga/effects'

import * as webSocketChannelNameCreators from '../../config/webSocketChannelNameCreators';
import * as actionTypes from 'store/actions/actionTypes'
import ApiAssetEvent from 'apiServices/ApiAssetEvent'

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The Asset Event cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_ASSETEVENT_PROPS, props });
}

/*
 * get AssetEvents
 */
function* getAssetEvents(action) {
  try {
    yield loading();
    // get all assetEvents
    const allAssetEvents = yield call(ApiAssetEvent.getAll, action.props);
    // update state for assetEvents
    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
        assetEvents: allAssetEvents,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getAssetEvent(action) {
  try {
    if (action.props.assetEventId?.length > 13) {
      yield loading();
      // get assetEvent
      const assetEvent = yield call(ApiAssetEvent.getAssetEvent, action.props.assetEventId);
      yield put({
        type: actionTypes.CHANGE_NAMES_PROPS, props: {
          name: assetEvent.idString,
          id: assetEvent.event.id,
          type: "assetEvent"
        }
      });
      if (Object.keys(action.props).length > 1)
        yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 3, ids: action.props, type: 'events' } });
      // update state for assetEvents
      yield put({
        type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
          assetEvent: assetEvent,
          loading: false,
          errors: ''
        }
      });
    }
  } catch (error) {
    yield loading(false, error);
  }

}

function* getByBridge(action) {
  try {
    yield loading();
    if (Object.keys(action.props).length > 1)
      yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 4, ids: action.props, type: 'events' } });
    const assetEvents = yield call(ApiAssetEvent.getByBridge, action.props.bridgeId);
    // get all assetEvents by Bridge
    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
        assetEvents: assetEvents,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchAssetEvents(action) {
  try {
    yield loading();
    // get all assetEvents
    const assetEvents = yield call(ApiAssetEvent.searchAssetEvents, action.props);
    // update state for assetEvents
    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
        assetEvents: assetEvents.content,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}



function* addAssetEvent(action) {
  try {
    yield loading();

    // add  assetEvent
    yield call(ApiAssetEvent.addAssetEvent, action.props);
    // update state for AssetEvents
    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
        assetEvent: {},
        loading: false,
        errors: ''
      }
    });
    yield getByBridge({ props: { bridgeId: action.props.bridge.id } });
  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteAssetEvent(action) {
  try {
    yield loading();

    // delete assetEvent
    yield call(ApiAssetEvent.deleteAssetEvent, action.props.id);
    yield getByBridge({ props: { bridgeId: action.props.bridge.id } });
    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getAssetEventByEvent(action) {
  try {
    yield loading();
    // delete assetEvent
    const assetEvents = yield call(ApiAssetEvent.getAssetEventByEvent, action.props.id, action.props.eventId);

    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
        assetEventsLevelOne: assetEvents,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getAllAssetEvent(action) {
  try {
    yield loading();
    // delete assetEvent
    const assetEvents = yield call(ApiAssetEvent.getAllAssetEvent, action.props.assetId, action.props.eventId);

    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
        assetEventsLevelOne: assetEvents,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getAssetEventByAssetId(action) {
  try {
    yield loading();
    const assetEventId = action.props?.data?.assetEventId ? action.props.data.assetEventId : "" ;

    if (assetEventId.length > 13) {
      const assetEvents = yield call(ApiAssetEvent.getAssetEventByAssetId, assetEventId);

      yield put({
        type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
          assetEventByAssetId: assetEvents,
          loading: false,
          errors: ''
        }
      });

      //Subscribe to a websocket an receive notifications for when the event changes.
      yield put({
        type: actionTypes.SAGA_WEBSOCKET_SUBSCRIBE_TO_CHANNEL,
        props: {
          channelName: webSocketChannelNameCreators.eventStatusUpdate(assetEvents.event.id)
        }
      });

      if (action.props.callback) {
        yield call(action.props.callback);
      }
    }
  } catch (error) {
    yield loading(false, error);
  }
}

function* editAssetEvent(action) {
  try {
    yield loading();
    // update  assetEvent
    yield call(ApiAssetEvent.editAssetEvent, action.props.data);
    // update state for assetEvents
    // yield put({
    //   type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
    //     assetEvent: {},
    //     loading: false,
    //     errors: ''
    //   }
    // });
    // yield put({
    //   type: actionTypes.CHANGE_ASSETEVENT_NAME_PROPS, props: {
    //     name: response.name,
    //   }
    // });

    yield getAssetEvent({ props: { assetEventId: action.props.data.id } });
    yield getAssetEventByAssetId({ props: {data:{ assetEventId: action.props.data.id }} });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}


function* loadAssetEventStructureTypes(action) {
  try {
    const { assetEventId } = action.props;
    if (assetEventId.length > 13) {
      yield put({
        type: actionTypes.CHANGE_ASSETEVENT_START_LOADING_STRUCTURE_TYPES
      });


      const structureTypes = yield call(ApiAssetEvent.getStructureTypes, assetEventId);
      yield put({
        type: actionTypes.CHANGE_ASSETEVENT_SUCCESS_LOADING_STRUCTURE_TYPES,
        props: {
          structureTypes
        }
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_ERROR_LOADING_STRUCTURE_TYPES,
      error: e.message
    })
  }
}


function* loadUsersThatCanWorkOnReports(action) {
  try {
    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_START_LOADING_USERS_WORK_REPORTS
    });

    const { assetEventId, milestoneId } = action.props;

    const usersForReports = yield call(ApiAssetEvent.getUsersThatCanWorkOnReports, { assetEventId: assetEventId, milestoneId: milestoneId });
    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_SUCCESS_LOADING_USERS_WORK_REPORTS,
      props: {
        usersForReports
      }
    });

  } catch (e) {
    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_ERROR_LOADING_USERS_WORK_REPORTS,
      error: e.message
    })
  }
}

/**
 * Gets available inspectors to select.
 */
function* getInspectors(action) {

  try {
    if (action.props.assetEventId.length > 13) {
      yield put({ type: actionTypes.CHANGE_ASSETEVENT_START_LOADING_INSPECTORS });
      const assetEventId = action.props.assetEventId;
      const inspectors = yield call(ApiAssetEvent.getInspectors, assetEventId);
      yield put({ type: actionTypes.CHANGE_ASSETEVENT_SUCCESS_LOADING_INSPECTORS, props: { inspectors } });
    }
  } catch (e) {
    yield put({ type: actionTypes.CHANGE_ASSETEVENT_ERROR_LOADING_INSPECTORS, error: e.message });
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ASSETEVENT_ALL, getAssetEvents),
    takeLatest(actionTypes.SAGA_ASSETEVENT_GET, getAssetEvent),
    takeLatest(actionTypes.SAGA_ASSETEVENT_SEARCH, searchAssetEvents),
    takeLatest(actionTypes.SAGA_ASSETEVENT_EDIT, editAssetEvent),
    takeLatest(actionTypes.SAGA_ASSETEVENT_ADD, addAssetEvent),
    takeLatest(actionTypes.SAGA_BRIDGE_ASSETEVENTS, getByBridge),
    takeLatest(actionTypes.SAGA_ASSETEVENT_DELETE, deleteAssetEvent),
    takeLatest(actionTypes.SAGA_ASSETEVENT_GET_BY_EVENT, getAssetEventByEvent),
    takeLatest(actionTypes.SAGA_ASSETEVENT_BY_ASSET, getAllAssetEvent),
    takeLatest(actionTypes.SAGA_ASSETEVENT_BY_ASSET_GET, getAssetEventByAssetId),
    takeLatest(actionTypes.SAGA_ASSETEVENT_GET_INSPECTORS, getInspectors),

    takeLatest(actionTypes.SAGA_ASSETEVENT_LOAD_STRUCTURE_TYPES, loadAssetEventStructureTypes),
    takeLatest(actionTypes.SAGA_ASSETEVENT_LOAD_USERS_WORK_REPORTS, loadUsersThatCanWorkOnReports)
  ]);
}

export default AuthWatcher;
