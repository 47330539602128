import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/milestone';
const endpointStatus = API_SERVER + '/milestoneStatus';
const endpointEventStatus = API_SERVER + '/eventMilestoneStatus';

export default class ApiMilestone {

  // GET 'api/milestones'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static getByEventType(id){
    return Utils.genericCall(`${endpoint}/eventType/${id}`);
  }

  static getByMilestoneStatus(id){
    return Utils.genericCall(`${endpoint}/milestoneStatus/${id}`);
  }

  static getByEvent(eventId) {
    return Utils.genericCall(`${endpoint}/event/${eventId}`);
  }

  static getMilestoneStatuses(){
    return Utils.genericCall(endpointStatus);
  }

  static getMilestoneStatusesByName() {
    return Utils.genericCall(`${endpointStatus}/names`);
  }

  static getEventMilestoneStatus(eventId) {
    return Utils.genericCall(`${endpointEventStatus}/current/event/${eventId}`);
  }

  static getAllEventMilestoneStatuses(eventId) {
    return Utils.genericCall(`${endpointEventStatus}/getAll/event/${eventId}`);
  }

  static putEventMilestoneStatus(data) {
    return Utils.genericPut(`${endpointEventStatus}/${data.id}`, data);
  }
}
