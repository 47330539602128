import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const resource = '/client';
const endpoint = API_SERVER + resource;
export default class ApiClient {

  // GET 'api/clients'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static getAllClients() {
    return Utils.genericCall(`${endpoint}/all`);
  }

  static searchClients(props) {
    return Utils.genericCall(`${endpoint}/paged?search=${props.search}&page=${props.page}&size=${props.size}`);
  }

  static searchClientsAdmin(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getClient(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editClient(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addClient(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteClient(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteClientChecked(data) {
    return Utils.genericPost(`${endpoint}/delete`, data);
  }

  static clientInspectionsMapStats(data) {
    return Utils.genericPost(`${endpoint}/${data.id}/inspectionsMapStats`, data);
  }

  static clientInspectionsMapPriority(data) {
    return Utils.genericPost(`${endpoint}/${data.id}/inspectionsMapPriority`, data);
  }

  static inspectionsByClient(props) {
    return Utils.genericPost(`${API_SERVER}/inspectionsByClient/${props.id}`, props.data);
  }

  // /client/{clientId}/stats
  static getClientStats(id) {
    return Utils.genericCall(`${endpoint}/${id}/stats`);
  }

  // "/allSections/client/{clientId}" POST
  static getClientBridgesSections(props) {
    return Utils.genericPost(`${API_SERVER}/allSections/client/${props.clientId}`, props.data);
  }

  static getClientContactsPaged(clientId, paginationOptions) {
    return Utils.genericPost(`${endpoint}/${clientId}/contacts/paged`, paginationOptions);
  }

  static editClientContact(clientId, contactId, contactData) {
    return Utils.genericPut(`${endpoint}/${clientId}/contacts/${contactId}`, contactData);
  }

  static addClientContact(clientId, contactData) {
    return Utils.genericPost(`${endpoint}/${clientId}/contacts`, contactData);
  }

  static deleteClientContactList(contactIdList) {
    return Utils.genericPost(`${endpoint}/contacts/deleteList`, contactIdList);
  }




  static getClientDocumentsPaged(clientId, paginationOptions) {
    return Utils.genericPost(`${endpoint}/${clientId}/documents/paged`, paginationOptions);
  }

  static editClientDocument(documentId, documentData) {
    return Utils.genericPut(`${endpoint}/documents/${documentId}`, documentData);
  }

  static addClientDocument(clientId, documentData, fileData) {

    //For files, we have to send the request as a FormData.
    let form = new FormData();
    form.append('file', fileData)
    form.append('document', JSON.stringify(documentData))

    //Remove the Content-Type header. By default is application/json
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    return Utils.genericPost(`${endpoint}/${clientId}/documents`, form, headers);
  }

  static deleteClientDocumentList(documentIdList) {
    return Utils.genericPost(`${endpoint}/documents/deleteList`, documentIdList);
  }

  static getClientDocumentTypes(organizationId) {
    return Utils.genericCall(`${API_SERVER}/${organizationId}/client/documentTypes`);
  }

  static getClientJobTitles(organizationId) {
    return Utils.genericCall(`${API_SERVER}/${organizationId}/client/jobTitles`);
  }

  static getClientOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getClientClients() {
    return Utils.genericCall(`${endpoint}/clients`);
  }

  static getClientContacts() {
    return Utils.genericCall(`${endpoint}/contacts`);
  }
}
