import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
    // document: [],
    document: {},
    documents: [],
    pagination: {},
    loading: false,
    errors: ''
}

function reducerDocument(state = initialState, action) {
    switch (action.type) {

        case actionTypes.CHANGE_DOCUMENT_PROPS:
            return Object.assign({}, state, action.props)

        default:
            return state
    }
}

export default onLoginCleanupReducer(initialState, reducerDocument);
