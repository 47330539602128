import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
    eventNotes: [],
    eventNote: {},
    pagination: {},
    formValues: null,
    loading: false,
    errors: ''
}

function reducerEventNote(state = initialState, action) {
    switch (action.type) {

        case actionTypes.CHANGE_EVENTNOTE_PROPS:
            return Object.assign({}, state, action.props)

        default:
            return state
    }
}

export default onLoginCleanupReducer(initialState, reducerEventNote);
