import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';
import { tripFields } from 'common/objectStructures';

/**
 * State.
 *
 * This state has more properties, but they are managed
 * by "withSubobjectsLoadReducer" reducer.
 */
const initialState = {
  redirectTo: null,
  loading: false,
  errors: '',
  trips: [],
  trip: {},
  tripAssets: [],
  tripPhotos: [],
  tripPhotosPagination: {},
  formValues: {},
  pagination: {},
  paginationRequest: {},

  //adding a new property that is used by withSubobjectsLoadReducer and should be loaded only once,
  // remember to add the proprty to the CLEAR_TRIP_PROPS action handler
  roles: createState(),
  usersRoles: createState(),
  clients: createState()
}

function reducerTrip(state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_TRIP_PROPS:
      if (action.props.formValues != null) {
        let newValues = { id: action.props.formValues.id }
        for (const [key, value] of Object.entries(tripFields)) {
          newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : (value.type === 'list' ? [] : '')));
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_TRIP_PROPS:
      return {
        ...initialState,
        roles: {...state.roles},
        usersRoles: {...state.usersRoles}
      };

    default:
      return state
  }
}

let reducerWithLoginCleanup = onLoginCleanupReducer(initialState, reducerTrip);

export default withSubobjectsLoadReducer(
  reducerWithLoginCleanup,
  {
    roles: [
      actionTypes.CHANGE_TRIP_START_LOADING_ROLES,
      actionTypes.CHANGE_TRIP_SUCCESS_LOADING_ROLES,
      actionTypes.CHANGE_TRIP_ERROR_LOADING_ROLES
    ],
    usersRoles: [
      actionTypes.CHANGE_TRIP_START_LOADING_USERS_ROLES,
      actionTypes.CHANGE_TRIP_SUCCESS_LOADING_USERS_ROLES,
      actionTypes.CHANGE_TRIP_ERROR_LOADING_USERS_ROLES
    ],
    clients: [
      actionTypes.CHANGE_TRIP_START_LOADING_CLIENTS,
      actionTypes.CHANGE_TRIP_SUCCESS_LOADING_CLIENTS,
      actionTypes.CHANGE_TRIP_ERROR_LOADING_CLIENTS
    ]
  }
);
