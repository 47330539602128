import { cloneDeep } from 'lodash';
import * as actionTypes from '../actions/actionTypes'
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
    componentsEvent: [],
    componentEvent: {},
    newComponentEvent: {},
    formValues: null,
}

function reducerComponentEvent(state = initialState, action) {

    const clonedState = cloneDeep(state);

    switch (action.type) {

        case actionTypes.CHANGE_COMPONENTEVENT_PROPS:
            return Object.assign({}, clonedState, action.props);

        default:
            return clonedState
    }
}

export default onLoginCleanupReducer(initialState, reducerComponentEvent);
