import { all, call, put, takeLatest,select } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'

import ApiEventCondition from 'apiServices/ApiEventCondition'
import { cloneDeep } from 'lodash';

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The Condition cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props });
}

function* getEventCondition(action) {
  try {
    yield loading();
    // get EventCondition
    const eventConditions = yield call(ApiEventCondition.getEventCondition, action.props.assetId, action.props.eventId);

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        eventConditions: eventConditions.eventConditions,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* addEventCondition(action) {
  try {
    yield loading();
    // add Event
    let loadedStructureType = yield select(store=>store.structureType)
    
    let conditionPhotos = action.props.props.eventPhotoEventConditions;
    action.props.props.eventPhotoEventConditions = [];
    const eventCondition = yield call(ApiEventCondition.addEventCondition, action.props.props);
    let newStructureData = cloneDeep(loadedStructureType[eventCondition.structureType.id])
    eventCondition.eventPhotoEventConditions = conditionPhotos;
    if(eventCondition.condition.name === 'BAD'){
      let newEventConditions = newStructureData.eventConditions.filter(e =>e.componentEventId !== eventCondition.componentEventId) 
      newEventConditions.push(eventCondition)
      newStructureData.eventConditions = newEventConditions
    }else{
      newStructureData.eventConditions.push(eventCondition)
    }
    // update state for Event
    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        eventCondition: eventCondition,
        loading: false,
        errors: ''
      }
    });

    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        loading: false,
        errors: '',
        [eventCondition.structureType.id]: newStructureData
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback, eventCondition);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* editEventCondition(action) {
  try {
    yield loading();
    let loadedStructureType = yield select(store=>store.structureType)
    let eventCondition = yield call(ApiEventCondition.editEventCondition, action.props.data);

    let index = loadedStructureType[eventCondition.structureType.id].eventConditions.findIndex(e => e.id === eventCondition.id)
    if(index !== -1){
      loadedStructureType[eventCondition.structureType.id].eventConditions[index] = eventCondition
    }

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        eventCondition: eventCondition,
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteEventCondition(action) {
  try {
    yield loading();
    // delete Event
    yield call(ApiEventCondition.deleteEventCondition, action.props.id);

    yield put({ type: actionTypes.SAGA_ASSEMBLY_EVENT, props: { assetEventId: action.props.props.sectionSelected } });

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        eventCondition: {},
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getEventConditionById(action) {
  try {
    yield loading();
    const eventCondition = yield call(ApiEventCondition.getEventConditionById, action.props.value.eventConditionId)

    if (eventCondition.assemblyEvent) {
      yield put({ type: actionTypes.SAGA_LOCATION_ASSEMBLYTYPE_GET, props: { assemblyTypeId: eventCondition.assemblyEvent.assemblyType.id } });
      yield put({ type: actionTypes.SAGA_CONDITION_ASSEMBLYTYPE_GET, props: { assemblyTypeId: eventCondition.assemblyEvent.assemblyType.id } });
    } else if (eventCondition.componentEvent) {
      yield put({ type: actionTypes.SAGA_LOCATION_COMPONENTTYPE_GET, props: { componentTypeId: eventCondition.componentEvent.componentType.id } });
      yield put({ type: actionTypes.SAGA_CONDITION_COMPONENTTYPE_GET, props: { componentTypeId: eventCondition.componentEvent.componentType.id } });
    }

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        eventCondition: eventCondition,
        loading: false,
        errors: ''
      }
    });

    yield call(action.props.callback);

  } catch (error) {
    yield loading(false, error);
  }
}

function* addEventConditionPrevtoNew(action) {
  try {
    yield loading();
    let loadedStructureType = yield select(store=>store.structureType)
    let condition = yield call(ApiEventCondition.addEventConditionPrevtoNew, action.props.props);
    let structureId = condition.structureType.id
    let newStructureType = cloneDeep(loadedStructureType[structureId])
    let index = newStructureType.eventConditions.findIndex(ec => ec.id === condition.previous.id)
    newStructureType.eventConditions[index] = condition

    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        loading: false,
        errors: '',
        [structureId]: newStructureType
      }
    });

    yield put({ type: actionTypes.SAGA_ASSEMBLY_EVENT, props: { assetEventId: action.props.props.sectionSelected } });

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        eventCondition: {},
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* eventConditionUnchanged(action) {
  try {
    yield loading();
    let loadedStructureType = yield select(store=>store.structureType)
    let newEventConditions = yield call(ApiEventCondition.eventConditionUnchanged, action.props);
    let structureId = newEventConditions[0].structureType.id
    let newstructureTypesData = cloneDeep(loadedStructureType[structureId])
    let index
    if(action.props.listIds.length > 0 && newEventConditions.length > 0 ){
      for(let id of action.props.listIds){
        index = newstructureTypesData.eventConditions.findIndex(ec => ec.id === id)
        newstructureTypesData.eventConditions[index] = newEventConditions.find(ec => ec.previous.id === id)
      }
    }

    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        loading: false,
        errors: '',
        [structureId]: newstructureTypesData
      }
    });

    yield put({
      type: actionTypes.SAGA_ASSEMBLY_EVENT,
      props: {
        assetEventId: action.props.sectionSelected
      }
    });

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if(action.props.callback){
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* eventConditionSound(action) {
  try {
    yield loading();
    yield call(ApiEventCondition.eventConditionSound, action.props);

    yield put({
      type: actionTypes.SAGA_ASSEMBLY_EVENT,
      props: {
        assetEventId: action.props.sectionSelected
      }
    });

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* addEventConditionMultiItems(action) {
  try {
    yield loading();
    // add Event
    yield call(ApiEventCondition.addEventConditionMultiItems, action.props.props);

    yield put({
      type: actionTypes.SAGA_ASSEMBLY_EVENT,
      props: {
        assetEventId: action.props.props.sectionSelected
      }
    });

    // update state for Event
    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        eventCondition: {},
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* addPhotosToCondition(action) {
  try {
    yield loading();
    const response = yield call(ApiEventCondition.addPhotosToCondition, action.props.data);

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS_ADD_NEW_PHOTO,
      props: {
        newPhoto: response
      }
    });

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteEventConditionArray(action) {
  try {
    yield loading();
    yield call(ApiEventCondition.deleteEventConditionArray, action.props.eventConditionIds);
    
    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* getPreviousEventConditionById(action) {
  try {
    yield loading();
    const eventCondition = yield call(ApiEventCondition.getPreviousEventConditionById, action.props.value.eventConditionId)

    if (eventCondition.assemblyEvent) {
      yield put({ type: actionTypes.SAGA_LOCATION_ASSEMBLYTYPE_GET, props: { assemblyTypeId: eventCondition.assemblyEvent.assemblyType.id } });
      yield put({ type: actionTypes.SAGA_CONDITION_ASSEMBLYTYPE_GET, props: { assemblyTypeId: eventCondition.assemblyEvent.assemblyType.id } });
    } else if (eventCondition.componentEvent) {
      yield put({ type: actionTypes.SAGA_LOCATION_COMPONENTTYPE_GET, props: { componentTypeId: eventCondition.componentEvent.componentType.id } });
      yield put({ type: actionTypes.SAGA_CONDITION_COMPONENTTYPE_GET, props: { componentTypeId: eventCondition.componentEvent.componentType.id } });
    }

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        eventCondition: eventCondition,
        loading: false,
        errors: ''
      }
    });

    yield call(action.props.callback);

  } catch (error) {
    yield loading(false, error);
  }
}

function* eventConditionSolved(action) {
  try {
    yield loading();
    yield call(ApiEventCondition.eventConditionSolved, action.props);

    yield put({
      type: actionTypes.SAGA_ASSEMBLY_EVENT,
      props: {
        assetEventId: action.props.sectionSelected
      }
    });

    yield put({
      type: actionTypes.CHANGE_EVENTCONDITION_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_EVENTCONDITION_GET, getEventCondition),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_ADD, addEventCondition),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_DELETE, deleteEventCondition),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_GET_BY_ID, getEventConditionById),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_EDIT, editEventCondition),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_PREV_TO_NEW, addEventConditionPrevtoNew),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_UNCHANGED, eventConditionUnchanged),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_SOUND, eventConditionSound),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_ADD_MULTI_ITEMS, addEventConditionMultiItems),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_PHOTO, addPhotosToCondition),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_PHOTO_REMOVE, addPhotosToCondition),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_DELETE_ARRAY, deleteEventConditionArray),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_GET_PREVIOUS_BY_ID, getPreviousEventConditionById),
    takeLatest(actionTypes.SAGA_EVENTCONDITION_SOLVED, eventConditionSolved),
  ]);
}

export default AuthWatcher;
