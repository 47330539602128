import { all, call, put, takeLatest,select } from 'redux-saga/effects';
import * as actionTypes from 'store/actions/actionTypes';
import { cloneDeep } from 'lodash';

import ApiEventNote from 'apiServices/ApiEventNote';

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The Note cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_EVENTNOTE_PROPS, props });
}


function* getEventNote(action) {
  try {
    yield loading();
    // get EventNote
    const eventNotes = yield call(ApiEventNote.getEventNote, action.props.assetId, action.props.eventId);

    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        eventNotes: eventNotes,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* addEventNote(action) {
  try {
    yield loading();
    let loadedStructureType = yield select(store=>store.structureType)
    // add Event
    let notePhotos = action.props.data.eventPhotoEventNote;
    action.props.data.eventPhotoEventNote = [];
    const eventNote = yield call(ApiEventNote.addEventNote, action.props.data);
    eventNote.eventPhotoEventNote = notePhotos;
    loadedStructureType[eventNote.structureType.id].eventNotes.push(eventNote)

    yield put({ type: actionTypes.SAGA_ASSEMBLY_EVENT, props: { assetEventId: action.props.data.sectionSelected } });


    // update structureType
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        structureType: loadedStructureType.structureType,
        loading: false,
        errors: '',
      }
    });

    // update state for Event
    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        eventNote: eventNote,
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback, eventNote);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* editEventNote(action) {
  try {
    yield loading();

    //yield put({ type: actionTypes.SAGA_ASSEMBLY_EVENT, props: { assetEventId: action.props.data.sectionSelected } });


    let loadedStructureType = yield select(store=>store.structureType)
    let eventNote = yield call(ApiEventNote.editEventNote, action.props.data);

    let index = loadedStructureType[eventNote.structureType.id].eventNotes.findIndex(e => e.id === eventNote.id)
    if(index !== -1){
      loadedStructureType[eventNote.structureType.id].eventNotes[index] = eventNote
    }

    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        eventNote: eventNote,
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteEventNote(action) {
  try {
    yield loading();
    // delete Event
    yield call(ApiEventNote.deleteEventNote, action.props.id);

    yield put({ type: actionTypes.SAGA_ASSEMBLY_EVENT, props: { assetEventId: action.props.sectionSelected } });

    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        eventNote: {},
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getEventNoteById(action) {
  try {
    yield loading();
    const eventNote = yield call(ApiEventNote.getEventNoteById, action.props.value.eventNoteId)

    if (eventNote.assemblyEvent) {
      yield put({ type: actionTypes.SAGA_LOCATION_ASSEMBLYTYPE_GET, props: { assemblyTypeId: eventNote.assemblyEvent.assemblyType.id } });
    } else if (eventNote.componentEvent) {
      yield put({ type: actionTypes.SAGA_LOCATION_COMPONENTTYPE_GET, props: { componentTypeId: eventNote.componentEvent.componentType.id } });
    }

    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        eventNote: eventNote,
        loading: false,
        errors: ''
      }
    });
    
    if(action.props.callback){
      yield call(action.props.callback);
    }
    
  } catch (error) {
    yield loading(false, error);
  }

}


function* addPhotosToNote(action) {
  try {
    yield loading();
    yield call(ApiEventNote.addPhotosToNote, action.props);

    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }
}


function* eventNoteUnchanged(action) {
  try {
    yield loading();

    let loadedStructureType = yield select(store=>store.structureType)
    let newEventNotes = yield call(ApiEventNote.eventNoteUnchanged, action.props);
    let structureId = newEventNotes[0].structureType.id
    let newstructureTypesData = cloneDeep(loadedStructureType[structureId])
    let index
    if(action.props.listIds.length > 0 && newEventNotes.length > 0 ){
      for(let id of action.props.listIds){
        index = newstructureTypesData.eventNotes.findIndex(ec => ec.id === id)
        newstructureTypesData.eventNotes[index] = newEventNotes.find(ec => ec.previous.id === id)
      }
    }

    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        loading: false,
        errors: '',
        [structureId]: newstructureTypesData
      }
    });

    yield put({
      type: actionTypes.SAGA_ASSEMBLY_EVENT,
      props: {
        assetEventId: action.props.sectionSelected
      }
    });

    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if(action.props.callback){
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* eventNoteSound(action) {
  try {
    yield loading();
    yield call(ApiEventNote.eventNoteSound, action.props);

    yield put({
      type: actionTypes.SAGA_ASSEMBLY_EVENT,
      props: {
        assetEventId: action.props.sectionSelected
      }
    });

    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* getPreviousEventNoteById(action) {
  try {
    yield loading();
    const eventNote = yield call(ApiEventNote.getPreviousEventNoteById, action.props.value.eventNoteId)

    if (eventNote.assemblyEvent) {
      yield put({ type: actionTypes.SAGA_LOCATION_ASSEMBLYTYPE_GET, props: { assemblyTypeId: eventNote.assemblyEvent.assemblyType.id } });
    } else if (eventNote.componentEvent) {
      yield put({ type: actionTypes.SAGA_LOCATION_COMPONENTTYPE_GET, props: { componentTypeId: eventNote.componentEvent.componentType.id } });
    }

    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        eventNote: eventNote,
        loading: false,
        errors: ''
      }
    });

    yield call(action.props.callback);
  } catch (error) {
    yield loading(false, error);
  }

}

function* addEventNotePrevtoNew(action) {
  try {
    yield loading();

    let loadedStructureType = yield select(store=>store.structureType)
    let note = yield call(ApiEventNote.addEventNotePrevtoNew, action.props.props);
    let structureId = note.structureType.id
    let newStructureType = cloneDeep(loadedStructureType[structureId])
    let index = newStructureType.eventNotes.findIndex(ec => ec.id === note.previous.id)
    newStructureType.eventNotes[index] = note

    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        loading: false,
        errors: '',
        [structureId]: newStructureType
      }
    });

    yield put({ type: actionTypes.SAGA_ASSEMBLY_EVENT, props: { assetEventId: action.props.props.sectionSelected } });

    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        eventNote: {},
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteEventNoteArray(action) {
  try {
    yield loading();
    yield call(ApiEventNote.deleteEventNoteArray, action.props.eventNoteIds);

    yield put({
      type: actionTypes.CHANGE_EVENTNOTE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_EVENTNOTE_GET, getEventNote),
    takeLatest(actionTypes.SAGA_EVENTNOTE_ADD, addEventNote),
    takeLatest(actionTypes.SAGA_EVENTNOTE_DELETE, deleteEventNote),
    takeLatest(actionTypes.SAGA_EVENTNOTE_GET_BY_ID, getEventNoteById),
    takeLatest(actionTypes.SAGA_EVENTNOTE_EDIT, editEventNote),
    takeLatest(actionTypes.SAGA_EVENTNOTE_ADD_PHOTOS, addPhotosToNote),
    takeLatest(actionTypes.SAGA_EVENTNOTE_UNCHANGED, eventNoteUnchanged),
    takeLatest(actionTypes.SAGA_EVENTNOTE_SOUND, eventNoteSound),
    takeLatest(actionTypes.SAGA_EVENTNOTE_GET_PREVIOUS_BY_ID, getPreviousEventNoteById),
    takeLatest(actionTypes.SAGA_EVENTNOTE_PREV_TO_NEW, addEventNotePrevtoNew),
    takeLatest(actionTypes.SAGA_EVENTNOTE_DELETE_ARRAY, deleteEventNoteArray),
  ]);
}

export default AuthWatcher;