import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/event';

const endpointAssets = API_SERVER + '/asset';

const endpointEventUser = API_SERVER + '/eventUser';

const endpointEventByUser = API_SERVER + '/eventByUser';

const endpointDownloadReport = API_SERVER + '/download';

export default class ApiEvent {

  // GET 'api/event'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static searchEvents(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }


  static updateStatusInspections(data) {
    return Utils.genericPut(`${endpoint}/updateStatusInspections`, data);
  }

  static multipleUpdateCertifiedData(data) {
    return Utils.genericPut(`${endpoint}/multipleUpdateCertifiedData`, data);
  }

  static getByAsset(id) {
    return Utils.genericCall(`${endpointAssets}/${id}/event`);
  }

  static searchByBridge(props) {
    return Utils.genericCall(`${endpointAssets}/${props.id}/event/paged?search=${props.search}&page=${props.page}`);
  }

  static getEvent(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editEvent(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addEvent(data) {
    return Utils.genericPost(`${endpoint}`, data);
  }

  static getEventPdf(id) {
    return Utils.genericPreview(`${endpoint}/${id}/report.pdf`);
  }

  static getInitialReportBaseSixFour(props) {
    return Utils.genericCall(`${endpoint}/initialReport/event/${props.eventId}/section/${props.sectionId}/base64`);
  }

  static getFinalReportBaseSixFour(props) {
    return Utils.genericCall(`${endpoint}/finalReport/event/${props.eventId}/section/${props.sectionId}/base64`);
  }

  static deleteEvent(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  /**
   * Deletes multiple events at the same time.
   *
   * @param {string[]} eventsIds the ID's of the events to be deleted.
   * @returns {any} the response from the API.
   */
  static deleteMultipleEvents(eventsIds) {
    return Utils.genericPost(`${endpoint}/delete`, eventsIds);
  }

  static deleteEventItems(items) {
    return Utils.genericPost(`${endpoint}/items/delete`, items);
  }

  static getEventPhotoGallery(id) {
    return Utils.genericCall(`${API_SERVER}/filesBucket/${id}`);
  }

  static beginInspection(data) {
    return Utils.genericPost(`${endpoint}/beginInspection`, data);
  }

  static updateStatusInspection(data) {
    return Utils.genericPut(`${endpoint}/updateStatusInspection`, data);
  }

  static getEventUserByEvent(id) {
    return Utils.genericCall(`${endpointEventUser}/event/${id}`);
  }

  static getEventByUser(data) {
    return Utils.genericPost(`${endpointEventByUser}/paged`, data);
  }

  static getEventHeadersByUser(data) {
    return Utils.genericCall(`${endpointEventByUser}/header`);
  }

  static getFinalReport(sectionId, eventId) {
    return Utils.genericPreview(`${endpoint}/finalReport/event/${eventId}/section/${sectionId}.pdf`);
  }

  static getInitialReport(sectionId, eventId) {
    return Utils.genericPreview(`${endpoint}/initialReport/event/${eventId}/section/${sectionId}.pdf`);
  }

  static generateDraftReports(typeId, data) {
    return Utils.genericPost(`${endpoint}/reports/type/${typeId}`, data);
  }

  static downloadDraftReports(file) {
    return Utils.genericDownload(`${endpointDownloadReport}/${file}`);
  }

  static downloadSelectedReports(data) {
    return Utils.genericPost(`${endpoint}/reportsByMilestonesAndSectionEvents`, data);
  }

  // /event/coverEventPhoto/{idEvent}
  static addCoverPhoto(eventId, data) {
    return Utils.genericPut(`${endpoint}/coverEventPhoto/${eventId}`, data);
  }

  static generateDownloadAndSubmitReports(data) {
    return Utils.genericPost(`${endpoint}/downloadAndSubmit`, data);
  }

  static generateDownloadReports(data) {
    return Utils.genericDownloadPost(`${endpoint}/reportByMilestoneAndSectionEvent.pdf`, data);
  }

  static getLastInspectionInfo(sectionId) {
    return Utils.genericCall(`${endpoint}/lastInspectionInfo/section/${sectionId}`);
  }

  static completeMultipleEvents(data) {
    return Utils.genericPost(`${endpoint}/completeMultipleEvents`, data);
  }

  static hasPhoto(eventId) {
    return Utils.genericCall(`${endpoint}/${eventId}/hasPhotos`);
  }

  static getCompomentTypes() {
    return Utils.genericCall(`${endpoint}/componentTypes`);
  }

  static getAssemblyTypes() {
    return Utils.genericCall(`${endpoint}/assemblyTypes`);
  }

  static getAccessTypes() {
    return Utils.genericCall(`${endpoint}/accessTypes`);
  }

  static getItemTypes() {
    return Utils.genericCall(`${endpoint}/itemTypes`);
  }

  static getCrossingFeatures() {
    return Utils.genericCall(`${endpoint}/crossingFeatures`);
  }

  static getWaterFlows() {
    return Utils.genericCall(`${endpoint}/waterFlows`);
  }

  static getImpactTypes() {
    return Utils.genericCall(`${endpoint}/impactTypes`);
  }

  static getAlignments() {
    return Utils.genericCall(`${endpoint}/alignments`);
  }

  static getAssetTypes() {
    return Utils.genericCall(`${endpoint}/assetTypes`);
  }

  static getClients() {
    return Utils.genericCall(`${endpoint}/clients`);
  }

  // upload excel files
  static uploadEventExcelFile(eventId, structureTypeId,data) {

    return Utils.genericPost(`${endpoint}/${eventId}/structureType/${structureTypeId}/importExcel`,data);
  }

  static downloadDeckImportTemplate() {
    return Utils.genericPreviewByTypeFile(endpoint + "/DeckImportTemplate.xlsx", null, "xlsx");
  }

  static downloadApproachImportTemplate() {
    return Utils.genericPreviewByTypeFile(endpoint + "/ApproachImportTemplate.xlsx", null, "xlsx");
  }

  static getUsersThatWorkOnReportsByClientId(clientId) {
    return Utils.genericCall(endpoint + "/usersThatWorkOnReportsByClientId/" + clientId);
  }


  //Reports annotations endponts

  static getReportComments(props) {
    return Utils.genericCall(`${API_SERVER}/reportComments/finalReport/${props.assetEventId}/${props.eventMilestoneStatusId}`);
  }

  /**
   * 
   * @param {object} props.data = {assetEventId:"", eventMilestonStatusId:"",comments:""} 
   * @returns {any} the response from the API.
   */
  static saveReportComments(props) {
    return Utils.genericPost(`${API_SERVER}/reportComments/finalReport`,props.data);
  }

}
