import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiMilePost from 'tests/mocks/ApiMilePost.mock'
import ApiMilePost from 'apiServices/ApiMilePost'

/*
 * get MilePosts
 */
function* getMilePosts(){
  // get all MilePosts
  const milePosts = yield call(ApiMilePost.getAll);
  
  // update state for milePosts
  yield put({ type: actionTypes.CHANGE_MILEPOST_PROPS, props:{
    milePosts: milePosts,
  }});
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_MILEPOST_ALL, getMilePosts)
  ]);
}

export default AuthWatcher;