import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/messageField';

const endpointMessage = API_SERVER + '/message';

export default class ApiMessageField {

  // GET 'api/messageFields'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchMessageFields(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getMessageField(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editMessageField(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addMessageField(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteMessageField(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteMessageFieldList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getByMessage(id){
    return Utils.genericCall(`${endpointMessage}/${id}/messageField`);
  }

  static getByMessages(props){
    return Utils.genericPost(`${endpoint}/pagedByMessage?page=${props.page}`, props.ids);
  }

  static searchByMessage(props){
     return Utils.genericPost(`${endpointMessage}/${props.id}/messageField/paged`, props.data);
  }

}
