import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
  milestones: [],
  milestonesFiltered: [],
  milestoneStatuses: [],
  milestonesByEvent: [],
  eventMilestoneStatus: {},
  eventMilestoneStatuses: []
}

function reducerMilestone (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_MILESTONE_PROPS:
      return Object.assign({}, state, action.props)

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerMilestone);
