import * as actionTypes from './actionTypes'

/**
 * @namespace ImpactTypeActions
 */


export function getImpactTypes() {
  return { type: actionTypes.SAGA_IMPACTTYPE_ALL };
}

export function loadImpactTypesPaged(props) {
  return { type: actionTypes.SAGA_IMPACTTYPE_SEARCH, props: props };
}

export function getImpactType(props) {
  return { type: actionTypes.SAGA_IMPACTTYPE_GET, props: props };
}

export function editImpactType(props) {
  return { type: actionTypes.SAGA_IMPACTTYPE_EDIT, props: props };
}

export function addImpactType(props) {
  return { type: actionTypes.SAGA_IMPACTTYPE_ADD, props: props };
}

export function deleteImpactType(props) {
  return { type: actionTypes.SAGA_IMPACTTYPE_DELETE, props: props };
}

export function deleteImpactTypeList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_IMPACTTYPE_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

/**
 * @memberof ImpactTypeActions
 * @description Change ImpactType props (no saga action)
 */
export function changeImpactTypeProps(props) {
  return { type: actionTypes.CHANGE_IMPACTTYPE_PROPS, props: props };
}

/**
 * @memberof ImpactTypeActions
 * @description Clears Impact Type props.
 */
export function clearImpactTypeProps() {
  return { type: actionTypes.CLEAR_IMPACT_TYPES_PROPS };
}

/**
 * @memberof ImpactTypeActions
 * @description Clears Impact Types errors messages.
 */
export function clearImpactTypeErrors() {
  return { type: actionTypes.CLEAR_IMPACT_TYPES_ERRORS };
}

/**
 * @memberof ImpactTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations for Impact Types.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_IMPACTTYPE_LOAD_ORGANIZATIONS };
}
