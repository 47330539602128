import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import GAListener from 'components/GAListener';
import { EmptyLayout, MainLayout, BasicLayout, FullScreenLayout, LayoutRoute } from 'components/Layout';
import { changeAuthPropsAction } from 'store/actions/AuthActions'
import utils from 'common/utils';
import ToastContainer from 'components/ToastContainer';
import { Circles } from "react-loader-spinner";
import { Card, CardBody } from 'reactstrap';


/**
 * IF YOU CHANGE ANY ROUTE HERE, REMEMBER TO UPDATE THE FILE "ROUTES.MD" WHERE ALL
 * ROUTE DEFINITIONS ARE DOCUMENTED.
 *
*/

// routes
import {
  // app routes
  ROUTE_HOME,
  ROUTE_HOME_CLIENT,
  ROUTE_LOGOUT,
  ROUTE_LOGIN,
  ROUTE_CLIENTS,
  ROUTE_RAILROADS,
  ROUTE_SUBDIVISIONS,
  ROUTE_CLIENT,
  ROUTE_CLIENT_ADD,
  ROUTE_CLIENT_EDIT,
  ROUTE_RAILROAD,
  ROUTE_RAILROAD_ADD,
  ROUTE_RAILROAD_EDIT,
  ROUTE_SUBDIVISION,
  ROUTE_CLIENT_SUBDIVISIONS,
  ROUTE_CLIENT_SUBDIVISION_ADD,
  ROUTE_SUBDIVISION_ADD,
  ROUTE_SUBDIVISION_EDIT,
  ROUTE_ASSETS,
  ROUTE_ALL_ASSETS,
  ROUTE_ASSET,
  ROUTE_ASSET_ADD,
  ROUTE_ASSET_DOCUMENTS,
  ROUTE_ASSET_PHOTO,
  ROUTE_ASSET_EDIT,
  ROUTE_ASSETCHILD_ADD,
  ROUTE_ASSETCHILD_EDIT,
  ROUTE_EVENTS_DASHBOARD,
  ROUTE_ASSEMBLY_ADD,
  ROUTE_COMPONENT_ADD,
  ROUTE_COMPONENT_EDIT,
  ROUTE_MESSAGES,
  ROUTE_MESSAGE_ADD,
  ROUTE_MESSAGE_EDIT,
  ROUTE_USERS,
  ROUTE_USER_ADD,
  ROUTE_USER_EDIT,
  ROUTE_USER_PROFILE,
  ROUTE_MESSAGEFIELDS,
  ROUTE_MESSAGEFIELD_ADD,
  ROUTE_MESSAGEFIELD_EDIT,
  ROUTE_ASSEMBLYTYPES,
  ROUTE_ASSEMBLYTYPE_ADD,
  ROUTE_ASSEMBLYTYPE_EDIT,
  ROUTE_COMPONENTTYPES,
  ROUTE_COMPONENTTYPE_ADD,
  ROUTE_COMPONENTTYPE_EDIT,
  ROUTE_COMPONENTGROUPS,
  ROUTE_COMPONENTGROUP_ADD,
  ROUTE_COMPONENTGROUP_EDIT,
  ROUTE_DOCUMENTTYPES,
  ROUTE_DOCUMENTTYPE_ADD,
  ROUTE_DOCUMENTTYPE_EDIT,
  ROUTE_ROLES,
  ROUTE_ROLE_ADD,
  ROUTE_ROLE_EDIT,
  ROUTE_CHANGE_PASS,
  ROUTE_FORGOT_PASS_EMAIL,
  ROUTE_CREATE_PASS,
  ROUTE_CLAIMS,
  ROUTE_CLAIM_EDIT,
  ROUTE_STRUCTURETYPES,
  ROUTE_STRUCTURETYPE_ADD,
  ROUTE_STRUCTURETYPE_EDIT,
  ROUTE_IMPACTTYPES,
  ROUTE_IMPACTTYPE_ADD,
  ROUTE_IMPACTTYPE_EDIT,
  ROUTE_ASSETTYPES,
  ROUTE_ASSETTYPE_ADD,
  ROUTE_ASSETTYPE_EDIT,
  ROUTE_CROSSINGFEATURES,
  ROUTE_CROSSINGFEATURE_ADD,
  ROUTE_CROSSINGFEATURE_EDIT,
  ROUTE_TRIP,
  ROUTE_TRIP_SCHEDULEDASSETS,
  ROUTE_TRIPS,
  ROUTE_TRIP_ADD,
  ROUTE_TRIP_ADDASSETS,
  ROUTE_TRIP_EDIT,
  ROUTE_TRIP_EDITASSETS,
  ROUTE_JOBTITLES,
  ROUTE_JOBTITLE_ADD,
  ROUTE_JOBTITLE_EDIT,
  ROUTE_CONTACTS,
  ROUTE_CONTACT_ADD,
  ROUTE_CONTACT_EDIT,
  ROUTE_ACCESSTYPES,
  ROUTE_ACCESSTYPE_ADD,
  ROUTE_ACCESSTYPE_EDIT,
  ROUTE_ORGANIZATIONS,
  ROUTE_ORGANIZATION_ADD,
  ROUTE_ORGANIZATION_EDIT,
  ROUTE_CONDITIONS,
  ROUTE_CONDITION_ADD,
  ROUTE_CONDITION_EDIT,
  ROUTE_LOCATIONS,
  ROUTE_LOCATION_ADD,
  ROUTE_LOCATION_EDIT,
  ROUTE_ASSET_SECTIONS,
  ROUTE_BUILD_MULTI_ASSETS,
  ROUTE_CONTACT_CLIENT,
  ROUTE_CLIENT_REPORT,
  ROUTE_CLIENT_RECOMMENDATION,
  ROUTE_CLIENT_DOCUMENTS,
  ROUTE_CLIENT_BRIDGES,
  ROUTE_HOME_RAILROADS,
  ROUTE_HOME_SUBDIVISIONS,
  ROUTE_HOME_CONTACTS,
  ROUTE_HOME_DOCUMENTS,
  ROUTE_HOME_RECOMMENDATION,
  ROUTE_HOME_REPORT,
  ROUTE_HOME_BRIDGES,
  //inspection pages
  ROUTE_INSPECTIONS_EVENTINSPECTION_PAGE,
  ROUTE_INSPECTIONS_APPROACH_PAGE,
  ROUTE_PDF_REPORT_PAGE,
  ROUTE_REPORT_MAPPING,
  ROUTE_TEST_NESTED,
  ROUTE_STORYBOOK
} from './constants';
import * as claimsConstants from './claimsConstants';
import PermissionsPage from 'components/Layout/PermissionsPage';


// app pages components
const PdfViewerPage = lazy(() => import('pages/syncFusion/pdfViewerPage'));

//DELETE THIS!!! 
const NestedTables = lazy(() => import('pages/nestedTablesExample'));



const ClientsPage = lazy(() => import('pages/client/clients'));
const ClientForm = lazy(() => import('pages/client/clientForm'));
const RailRoadsClientsPage = lazy(() => import('pages/railroad/railroadClientsPage'));
const RailRoadsDashboardPage = lazy(() => import('pages/railroad/railroadClientsPage'));
const SubDivisionsPage = lazy(() => import('pages/subdivision/subdivisions'));
const SubdivisionClientsPage = lazy(() => import('pages/client/subdivisionClientsPage'));
const SubdivisionDashboardPage = lazy(() => import('pages/client/subdivisionClientsPage'));
const BridgesDashboardPage = lazy(() => import('pages/dashboard/bridges-dashboard'));
const ClientsDashboard = lazy(() => import('pages/dashboard/clients-dashboard'));
const ClientPage = lazy(() => import('pages/client/client'));
const RailroadPage = lazy(() => import('pages/railroad/railroad'));
const RailroadForm = lazy(() => import('pages/railroad/railroadForm'));
const SubDivisionPage = lazy(() => import('pages/subdivision/subdivision'));
const SubdivisionForm = lazy(() => import('pages/subdivision/subdivisionForm'));

const EventsPage = lazy(() => import('pages/event/events'));
const ClientReports = lazy(() => import('pages/client/clientReports'));
const ClientRecommendationPage = lazy(() => import('pages/client/clientRecommendation'));
const ClientBridgesPage = lazy(() => import('pages/client/clientBridges'));
const ContactClientsPage = lazy(() => import('pages/contact/contactClientsPage'));
const ContactDashboardPage = lazy(() => import('pages/contact/contactClientsPage'));
const DocumentClientsPage = lazy(() => import('pages/client/documentClientsPage'));
const DocumentDashboardPage = lazy(() => import('pages/client/documentClientsPage'));

const AssetsPage = lazy(() => import('pages/asset/assets'));
const AssetForm = lazy(() => import('pages/asset/assetForm'));
const AddAssetForm = lazy(() => import('pages/asset/components/AddBridge'));

const AssetBridgePage = lazy(() => import('pages/asset/assetBridge'));
const AssetDocumentsPage = lazy(() => import('pages/asset/assetDocuments'));
const AssetPhotoGallery = lazy(() => import('pages/asset/assetPhotoGallery'));
const AllAssets = lazy(() => import('pages/asset/allAssets'));
const AssetSectionsPage = lazy(() => import('pages/asset/assetSections'));

const AssemblyForm = lazy(() => import('pages/assembly/assemblyForm'));
const ComponentForm = lazy(() => import('pages/component/componentForm'));

const MessagesPage = lazy(() => import('pages/message/messages'));
const MessageForm = lazy(() => import('pages/message/messageForm'));

const UsersPage = lazy(() => import('pages/user/users'));
const UserForm = lazy(() => import('pages/user/userForm'));
const UserProfile = lazy(() => import('pages/user/profile'));

const MessageFieldsPage = lazy(() => import('pages/messageField/messageFields'));
const MessageFieldForm = lazy(() => import('pages/messageField/messageFieldForm'));

const AssemblyTypesPage = lazy(() => import('pages/assemblyType/assemblyTypes'));
const AssemblyTypeForm = lazy(() => import('pages/assemblyType/assemblyTypeForm'));

const DocumentTypesPage = lazy(() => import('pages/documentType/documentTypes'));
const DocumentTypeForm = lazy(() => import('pages/documentType/documentTypeForm'));

const ComponentTypesPage = lazy(() => import('pages/componentType/componentTypes'));
const ComponentTypeForm = lazy(() => import('pages/componentType/componentTypeForm'));


const ComponentGroupsPage = lazy(() => import('pages/componentGroup/componentGroups'));
const ComponentGroupForm = lazy(() => import('pages/componentGroup/componentGroupForm'));

const ImpactTypesPage = lazy(() => import('pages/impactType/impactTypes'));
const ImpactTypeForm = lazy(() => import('pages/impactType/impactTypeForm'));


const AssetTypesPage = lazy(() => import('pages/assetType/assetTypes'));
const AssetTypeForm = lazy(() => import('pages/assetType/assetTypeForm'));

const CrossingFeaturesPage = lazy(() => import('pages/crossingFeature/crossingFeatures'));
const CrossingFeatureForm = lazy(() => import('pages/crossingFeature/crossingFeatureForm'));

const RolesPage = lazy(() => import('pages/role/roles'));
const RoleForm = lazy(() => import('pages/role/roleForm'));

const ClaimsPage = lazy(() => import('pages/claim/claims'));
const ClaimForm = lazy(() => import('pages/claim/claimForm'));

const StructureTypesPage = lazy(() => import('pages/structureType/structureTypes'));
const StructureTypeForm = lazy(() => import('pages/structureType/structureTypeForm'));

const TripsPage = lazy(() => import('pages/trip/trips'));
const TripForm = lazy(() => import('pages/trip/tripForm'));
const TripPage = lazy(() => import('pages/trip/trip'));
const TripScheduledAssets = lazy(() => import('pages/trip/components/TripScheduledAssets'));

const TripAssetsForm = lazy(() => import('pages/trip/tripAssetsForm'));

const ContactsPage = lazy(() => import('pages/contact/contacts'));
const ContactForm = lazy(() => import('pages/contact/contactForm'));

const JobTitlesPage = lazy(() => import('pages/jobTitle/jobTitles'));
const JobTitleForm = lazy(() => import('pages/jobTitle/jobTitleForm'));

const AccessTypesPage = lazy(() => import('pages/accessType/accessTypes'));
const AccessTypeForm = lazy(() => import('pages/accessType/accessTypeForm'));
const OrganizationsPage = lazy(() => import('pages/organization/organizations'));
const OrganizationForm = lazy(() => import('pages/organization/organizationForm'));

const ConditionsPage = lazy(() => import('pages/condition/conditions'));
const ConditionForm = lazy(() => import('pages/condition/conditionForm'));

const LocationsPage = lazy(() => import('pages/location/locations'));
const LocationForm = lazy(() => import('pages/location/locationForm'));



const LoginPage = lazy(() => import('pages/auth/login'));
const LogoutPage = lazy(() => import('pages/auth/logout'));
const ForgotPassPage = lazy(() => import('pages/auth/ForgotPass'));
const ForgotPassEmailPage = lazy(() => import('pages/auth/ForgotPassEmail'));
const CreatePassPage = lazy(() => import('pages/auth/CreatePass'));
const BuildMultiAssetsPage = lazy(() => import('pages/asset/BuildMultiAssets'));

//Inspections Pages
const EventInspection = lazy(() => import('pages/inspectionPages/eventInspection'));
const Approach = lazy(() => import('pages/inspectionPages/components/eventInspectionStructures'));


//Report page 
const ReportMapping = lazy(() => import('pages/inspectionPages/reportMapping'));

const Storybook = lazy(() => import('pages/storybook/Storybook'))

/**
 * HOW TO add claims to a route:
 * 1- Add the "claims" property in the definition of the route.
 * 2- The property can be an array or an object.
 *  2.1- If it is an array, the user needs all claims listed in that array to have access to the route.
 *  2.2- If it is an object:
 *   2.2.1- If it has a property called "oneOf", it has to be an array. The user will have accesso to
 *          the route if at least has one of this claims.
 *
 */

//TODO add claims to missing routes. If the route does not need a claim, add a comment why it does not need.
const routes = [
  //DELTE THIS!
  { exact: true, publicAccess: true, path: ROUTE_TEST_NESTED, layout: MainLayout, component: NestedTables },

  { exact: true, publicAccess: true, path: ROUTE_LOGOUT, layout: EmptyLayout, component: LogoutPage },
  { exact: true, publicAccess: true, path: ROUTE_LOGIN, layout: EmptyLayout, component: LoginPage },
  { exact: true, publicAccess: true, path: ROUTE_CHANGE_PASS, layout: EmptyLayout, component: ForgotPassPage },
  { exact: true, publicAccess: true, path: ROUTE_FORGOT_PASS_EMAIL, layout: EmptyLayout, component: ForgotPassEmailPage },
  { exact: true, publicAccess: true, path: ROUTE_CREATE_PASS, layout: EmptyLayout, component: CreatePassPage },
  { exact: true, publicAccess: false, path: ROUTE_CLIENTS, layout: MainLayout, component: ClientsPage, claims: [claimsConstants.CLIENTS_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_HOME_RAILROADS, layout: MainLayout, component: RailRoadsDashboardPage },
  { exact: true, publicAccess: false, path: ROUTE_HOME_SUBDIVISIONS, layout: MainLayout, component: SubdivisionDashboardPage },
  { exact: true, publicAccess: false, path: ROUTE_HOME_CONTACTS, layout: MainLayout, component: ContactDashboardPage },
  { exact: true, publicAccess: false, path: ROUTE_HOME_DOCUMENTS, layout: MainLayout, component: DocumentDashboardPage },
  { exact: true, publicAccess: false, path: ROUTE_HOME_RECOMMENDATION, layout: MainLayout, component: ClientRecommendationPage },
  { exact: true, publicAccess: false, path: ROUTE_HOME_REPORT, layout: MainLayout, component: ClientReports },
  { exact: true, publicAccess: false, path: ROUTE_HOME_BRIDGES, layout: MainLayout, component: ClientBridgesPage },

  { exact: true, publicAccess: false, path: ROUTE_RAILROADS, layout: MainLayout, component: RailRoadsClientsPage },
  { exact: true, publicAccess: false, path: ROUTE_CLIENT_SUBDIVISIONS, layout: MainLayout, component: SubdivisionClientsPage },
  { exact: true, publicAccess: false, path: ROUTE_SUBDIVISIONS, layout: MainLayout, component: SubDivisionsPage },
  { exact: true, publicAccess: false, path: ROUTE_ASSETS, layout: MainLayout, component: AssetsPage },
  { exact: true, publicAccess: false, path: ROUTE_EVENTS_DASHBOARD, layout: MainLayout, component: EventsPage, claims: [claimsConstants.INSPECTION_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_CLIENT_ADD, layout: MainLayout, component: ClientForm },
  { exact: true, publicAccess: false, path: ROUTE_CLIENT, layout: MainLayout, component: ClientPage },
  { exact: true, publicAccess: false, path: ROUTE_CLIENT_EDIT, layout: MainLayout, component: ClientForm },
  { exact: true, publicAccess: false, path: ROUTE_RAILROAD_ADD, layout: MainLayout, component: RailroadForm },
  { exact: true, publicAccess: false, path: ROUTE_RAILROAD, layout: MainLayout, component: RailroadPage },
  { exact: true, publicAccess: false, path: ROUTE_RAILROAD_EDIT, layout: MainLayout, component: RailroadForm },
  { exact: true, publicAccess: false, path: ROUTE_CLIENT_SUBDIVISION_ADD, layout: MainLayout, component: SubdivisionForm },
  { exact: true, publicAccess: false, path: ROUTE_SUBDIVISION_ADD, layout: MainLayout, component: SubdivisionForm },
  { exact: true, publicAccess: false, path: ROUTE_SUBDIVISION, layout: MainLayout, component: SubDivisionPage },
  { exact: true, publicAccess: false, path: ROUTE_SUBDIVISION_EDIT, layout: MainLayout, component: SubdivisionForm },
  { exact: true, publicAccess: false, path: ROUTE_ASSET_ADD, layout: FullScreenLayout, component: AddAssetForm },
  { exact: true, publicAccess: false, path: ROUTE_ASSET_SECTIONS, layout: MainLayout, component: AssetSectionsPage },
  { exact: true, publicAccess: false, path: ROUTE_ASSET_DOCUMENTS, layout: MainLayout, component: AssetDocumentsPage },
  { exact: true, publicAccess: false, path: ROUTE_ASSET_PHOTO, layout: MainLayout, component: AssetPhotoGallery },
  { exact: true, publicAccess: false, path: ROUTE_ASSET, layout: MainLayout, component: AssetBridgePage },
  { exact: true, publicAccess: false, path: ROUTE_ALL_ASSETS, layout: MainLayout, component: AllAssets, claims: { oneOf: [claimsConstants.BRIDGES, claimsConstants.CLIENT_BRIDGES] } },
  { exact: true, publicAccess: false, path: ROUTE_ASSET_EDIT, layout: MainLayout, component: AssetForm },

  { exact: true, publicAccess: false, path: ROUTE_ASSETCHILD_ADD, layout: MainLayout, component: AssetForm },
  { exact: true, publicAccess: false, path: ROUTE_ASSETCHILD_EDIT, layout: MainLayout, component: AssetForm },

  { exact: true, publicAccess: false, path: ROUTE_ASSEMBLY_ADD, layout: MainLayout, component: AssemblyForm },



  { exact: true, publicAccess: false, path: ROUTE_COMPONENT_ADD, layout: MainLayout, component: ComponentForm },
  { exact: true, publicAccess: false, path: ROUTE_COMPONENT_EDIT, layout: MainLayout, component: ComponentForm },

  { exact: true, publicAccess: false, path: ROUTE_MESSAGES, layout: MainLayout, component: MessagesPage, claims: [claimsConstants.MESSAGES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_MESSAGEFIELDS, layout: MainLayout, component: MessageFieldsPage, claims: [claimsConstants.MESSAGES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_MESSAGE_ADD, layout: MainLayout, component: MessageForm, claims: [claimsConstants.MESSAGE_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_MESSAGE_EDIT, layout: MainLayout, component: MessageForm, claims: [claimsConstants.MESSAGE_ADD] },

  //This route has no claims. It can be access by anoyone who is logged in
  { exact: true, publicAccess: false, path: ROUTE_USER_PROFILE, layout: MainLayout, component: UserProfile },
  { exact: true, publicAccess: false, path: ROUTE_USERS, layout: MainLayout, component: UsersPage, claims: [claimsConstants.USERS_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_USER_ADD, layout: MainLayout, component: UserForm, claims: [claimsConstants.USER_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_USER_EDIT, layout: MainLayout, component: UserForm, claims: [claimsConstants.USER_ADD] },


  { exact: true, publicAccess: false, path: ROUTE_TRIPS, layout: MainLayout, component: TripsPage, claims: [claimsConstants.TRIP_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_TRIP_ADD, layout: MainLayout, component: TripForm, claims: [claimsConstants.TRIP_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_TRIP_ADDASSETS, layout: MainLayout, component: TripAssetsForm, claims: [claimsConstants.TRIP_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_TRIP, layout: MainLayout, component: TripPage, claims: [claimsConstants.TRIP_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_TRIP_SCHEDULEDASSETS, layout: MainLayout, component: TripScheduledAssets },


  { exact: true, publicAccess: false, path: ROUTE_TRIP_EDIT, layout: MainLayout, component: TripForm, claims: [claimsConstants.TRIP_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_TRIP_EDITASSETS, layout: MainLayout, component: TripAssetsForm, claims: [claimsConstants.TRIP_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_MESSAGEFIELD_ADD, layout: MainLayout, component: MessageFieldForm },
  { exact: true, publicAccess: false, path: ROUTE_MESSAGEFIELD_EDIT, layout: MainLayout, component: MessageFieldForm },

  { exact: true, publicAccess: false, path: ROUTE_ASSEMBLYTYPES, layout: MainLayout, component: AssemblyTypesPage, claims: [claimsConstants.ASSEMBLY_TYPES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_ASSEMBLYTYPE_ADD, layout: MainLayout, component: AssemblyTypeForm, claims: [claimsConstants.ASSEMBLY_TYPES_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_ASSEMBLYTYPE_EDIT, layout: MainLayout, component: AssemblyTypeForm, claims: [claimsConstants.ASSEMBLY_TYPES_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_DOCUMENTTYPES, layout: MainLayout, component: DocumentTypesPage, claims: [claimsConstants.DOCUMENT_TYPES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_DOCUMENTTYPE_ADD, layout: MainLayout, component: DocumentTypeForm, claims: [claimsConstants.DOCUMENT_TYPES_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_DOCUMENTTYPE_EDIT, layout: MainLayout, component: DocumentTypeForm, claims: [claimsConstants.DOCUMENT_TYPES_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_COMPONENTTYPES, layout: MainLayout, component: ComponentTypesPage, claims: [claimsConstants.COMPONENT_TYPES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_COMPONENTTYPE_ADD, layout: MainLayout, component: ComponentTypeForm, claims: [claimsConstants.COMPONENT_TYPES_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_COMPONENTTYPE_EDIT, layout: MainLayout, component: ComponentTypeForm, claims: [claimsConstants.COMPONENT_TYPES_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_COMPONENTGROUPS, layout: MainLayout, component: ComponentGroupsPage, claims: [claimsConstants.COMPONENT_GROUPS_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_COMPONENTGROUP_ADD, layout: MainLayout, component: ComponentGroupForm, claims: [claimsConstants.COMPONENT_GROUPS_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_COMPONENTGROUP_EDIT, layout: MainLayout, component: ComponentGroupForm, claims: [claimsConstants.COMPONENT_GROUPS_ADD] },



  { exact: true, publicAccess: false, path: ROUTE_ROLES, layout: MainLayout, component: RolesPage, claims: [claimsConstants.ROLES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_ROLE_ADD, layout: MainLayout, component: RoleForm, claims: [claimsConstants.ROLE_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_ROLE_EDIT, layout: MainLayout, component: RoleForm, claims: [claimsConstants.ROLE_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_CLAIMS, layout: MainLayout, component: ClaimsPage, onlySuperAdmin: true },
  { exact: true, publicAccess: false, path: ROUTE_CLAIM_EDIT, layout: MainLayout, component: ClaimForm, onlySuperAdmin: true },

  { exact: true, publicAccess: false, path: ROUTE_STRUCTURETYPES, layout: MainLayout, component: StructureTypesPage, claims: [claimsConstants.STRUCTURE_TYPES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_STRUCTURETYPE_ADD, layout: MainLayout, component: StructureTypeForm, claims: [claimsConstants.STRUCTURE_TYPES_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_STRUCTURETYPE_EDIT, layout: MainLayout, component: StructureTypeForm, claims: [claimsConstants.STRUCTURE_TYPES_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_IMPACTTYPES, layout: MainLayout, component: ImpactTypesPage, claims: [claimsConstants.IMPACT_TYPES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_IMPACTTYPE_ADD, layout: MainLayout, component: ImpactTypeForm, claims: [claimsConstants.IMPACT_TYPES_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_IMPACTTYPE_EDIT, layout: MainLayout, component: ImpactTypeForm, claims: [claimsConstants.IMPACT_TYPES_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_ASSETTYPES, layout: MainLayout, component: AssetTypesPage, claims: [claimsConstants.ASSET_TYPES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_ASSETTYPE_ADD, layout: MainLayout, component: AssetTypeForm, claims: [claimsConstants.ASSET_TYPES_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_ASSETTYPE_EDIT, layout: MainLayout, component: AssetTypeForm, claims: [claimsConstants.ASSET_TYPES_ADD] },


  { exact: true, publicAccess: false, path: ROUTE_CROSSINGFEATURES, layout: MainLayout, component: CrossingFeaturesPage, claims: [claimsConstants.CROSSING_FEATURES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_CROSSINGFEATURE_ADD, layout: MainLayout, component: CrossingFeatureForm, claims: [claimsConstants.CROSSING_FEATURES_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_CROSSINGFEATURE_EDIT, layout: MainLayout, component: CrossingFeatureForm, claims: [claimsConstants.CROSSING_FEATURES_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_CONTACTS, layout: MainLayout, component: ContactsPage, claims: [claimsConstants.CONTACTS_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_CONTACT_ADD, layout: MainLayout, component: ContactForm, claims: [claimsConstants.CONTACTS_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_CONTACT_EDIT, layout: MainLayout, component: ContactForm, claims: [claimsConstants.CONTACTS_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_CONTACT_CLIENT, layout: MainLayout, component: ContactClientsPage, claims: [claimsConstants.CLIENT_CONTACTS_LIST] },

  { exact: true, publicAccess: false, path: ROUTE_JOBTITLES, layout: MainLayout, component: JobTitlesPage, claims: [claimsConstants.JOB_TITLES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_JOBTITLE_ADD, layout: MainLayout, component: JobTitleForm, claims: [claimsConstants.JOB_TITLE_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_JOBTITLE_EDIT, layout: MainLayout, component: JobTitleForm, claims: [claimsConstants.JOB_TITLE_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_CLIENT_REPORT, layout: MainLayout, component: ClientReports },

  { exact: true, publicAccess: false, path: ROUTE_ACCESSTYPES, layout: MainLayout, component: AccessTypesPage, claims: [claimsConstants.ACCESS_TYPES_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_ACCESSTYPE_ADD, layout: MainLayout, component: AccessTypeForm, claims: [claimsConstants.ACCESS_TYPES_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_ACCESSTYPE_EDIT, layout: MainLayout, component: AccessTypeForm, claims: [claimsConstants.ACCESS_TYPES_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_ORGANIZATIONS, layout: MainLayout, component: OrganizationsPage, onlySuperAdmin: true },
  { exact: true, publicAccess: false, path: ROUTE_ORGANIZATION_ADD, layout: MainLayout, component: OrganizationForm, onlySuperAdmin: true },
  { exact: true, publicAccess: false, path: ROUTE_ORGANIZATION_EDIT, layout: MainLayout, component: OrganizationForm, onlySuperAdmin: true },

  { exact: true, publicAccess: false, path: ROUTE_CONDITIONS, layout: MainLayout, component: ConditionsPage, claims: [claimsConstants.CONDITIONS_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_CONDITION_ADD, layout: MainLayout, component: ConditionForm, claims: [claimsConstants.CONDITIONS_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_CONDITION_EDIT, layout: MainLayout, component: ConditionForm, claims: [claimsConstants.CONDITIONS_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_LOCATIONS, layout: MainLayout, component: LocationsPage, claims: [claimsConstants.LOCATIONS_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_LOCATION_ADD, layout: MainLayout, component: LocationForm, claims: [claimsConstants.LOCATIONS_ADD] },
  { exact: true, publicAccess: false, path: ROUTE_LOCATION_EDIT, layout: MainLayout, component: LocationForm, claims: [claimsConstants.LOCATIONS_ADD] },

  { exact: true, publicAccess: false, path: ROUTE_BUILD_MULTI_ASSETS, layout: MainLayout, component: BuildMultiAssetsPage },
  { exact: true, publicAccess: false, path: ROUTE_CLIENT_RECOMMENDATION, layout: MainLayout, component: ClientRecommendationPage, claims: [claimsConstants.CLIENT_VISIT] },
  { exact: true, publicAccess: false, path: ROUTE_CLIENT_BRIDGES, layout: MainLayout, component: ClientBridgesPage, claims: [claimsConstants.CLIENT_BRIDGES] },
  { exact: true, publicAccess: false, path: ROUTE_CLIENT_DOCUMENTS, layout: MainLayout, component: DocumentClientsPage, claims: [claimsConstants.CLIENT_DOCUMENTS_LIST] },
  { exact: true, publicAccess: false, path: ROUTE_HOME, layout: MainLayout, component: BridgesDashboardPage, claims: [claimsConstants.ADMIN_DASHBOARD] },
  { exact: true, publicAccess: false, path: ROUTE_HOME_CLIENT, layout: MainLayout, component: ClientsDashboard, claims: [claimsConstants.CLIENT_DASHBOARD] },

  //inpections pages
  { exact: true, publicAccess: false, path: ROUTE_INSPECTIONS_EVENTINSPECTION_PAGE, layout: FullScreenLayout, component: EventInspection },
  { exact: true, publicAccess: false, path: ROUTE_INSPECTIONS_APPROACH_PAGE, layout: BasicLayout, component: Approach },

  //PDF page
  { exact: true, publicAccess: false, path: ROUTE_PDF_REPORT_PAGE, layout: BasicLayout, component: PdfViewerPage },


  //mapping reports page
  { exact: true, publicAccess: false, path: ROUTE_REPORT_MAPPING, layout: FullScreenLayout, component: ReportMapping },

  //static storybook route
  { exact: true, publicAccess: false, path: ROUTE_STORYBOOK, layout: FullScreenLayout, component: Storybook },
];

const Router = (props) => {
  // const navigate = useNavigate();

  const isAuthenticated = () => props.auth.token !== "";

  const saveRedirect = (uri) => {
    props.changeAuthPropsAction({ redirectTo: uri });
  }



  const routesBuilder = (items) => {
    // const saveRedirect = saveRedirect.bind(props);
    const isSuperAdmin = utils.isSuperAdmin(props.user);
    let array = [];
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      // is it public or the user is logged in?
      const publicRoute = item.publicAccess || isAuthenticated();
      let Component = item.component;

      //Route blocking based on defined claims, only if they are defined.
      if (!isSuperAdmin && publicRoute && item.claims) {
        //The second part of the IF checks if at least one claim is not allowed to the user
        if (Array.isArray(item.claims) && item.claims.some(claim => !utils.isAllowed(claim, props.user))) {
          //If it is declared as an array, the user needs all claims.
          Component = PermissionsPage;
        } else if (typeof item.claims === "object") {
          //The second part of the IF checks that none of the expected claims is allowed to the user
          if (item.claims.oneOf && item.claims.oneOf.every(claim => !utils.isAllowed(claim, props.user))) {
            //The user needs at least one of the claims
            Component = PermissionsPage;
          }
        }
      }

      //Block routes available only for SuperAdmin
      if (!isSuperAdmin && item.onlySuperAdmin) {
        Component = PermissionsPage;
      }

      array.push(
        <Route
          path={item.path}
          exact={item.exact}
          key={index + "route"}

          element={
            <LayoutRoute
              saveRedirect={saveRedirect}
              key={index}
              publicRoute={{
                publicRoute: publicRoute,
                breakpoint: props.breakpoint
              }}
              exact={item.exact}
              path={item.path}
              layout={item.layout}
              component={Component}
              {...props}
            />}
        />
      );
    }
    return array;
  }

  let { user } = props

  const htmlRoutes = routesBuilder(routes);

  const loadingSpinner = () => {
    return(
    <div key="spinner" className="h-100 w-100" style={{'position':'absolute'}}>
      <Card className={`accordion-card`}>
        <CardBody className="center-spinner">
          <Circles height={80} width={80} color='grey' />
        </CardBody>
      </Card>
    </div>)
  }

  return (
    <>
      <BrowserRouter>
        <GAListener>
          <Suspense fallback={loadingSpinner()}>
            <Routes>
              <Route path='/' element={<Navigate to={utils.isAllowed(claimsConstants.ADMIN_DASHBOARD, user) ? ROUTE_EVENTS_DASHBOARD : ROUTE_HOME_CLIENT} />} />
              {htmlRoutes}
            </Routes>
          </Suspense>
        </GAListener>
      </BrowserRouter>
      <ToastContainer />
    </>
  );

}

const mapStateToProps = (store) => ({
  auth: store.auth,
  user: store.auth.session
});

export default connect(mapStateToProps, { changeAuthPropsAction })(Router);
