import * as actionTypes from './actionTypes'

/**
 * @namespace ComponentTypeActions
 */


export function getComponentTypes() {
  return { type: actionTypes.SAGA_COMPONENTTYPE_ALL };
}

export function loadComponentTypesPaged(props) {
  return { type: actionTypes.SAGA_COMPONENTTYPE_SEARCH, props: props };
}

export function getComponentType(props) {
  return { type: actionTypes.SAGA_COMPONENTTYPE_GET, props: props };
}

export function editComponentType(props) {
  return { type: actionTypes.SAGA_COMPONENTTYPE_EDIT, props: props };
}

export function addComponentType(props) {
  return { type: actionTypes.SAGA_COMPONENTTYPE_ADD, props: props };
}

export function deleteComponentType(props) {
  return { type: actionTypes.SAGA_COMPONENTTYPE_DELETE, props: props };
}

export function deleteComponentTypeList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_COMPONENTTYPE_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}
/**
 * @memberof ComponentTypeActions
 * @description Change ComponentType props (no saga action)
 */
export function changeComponentTypeProps(props) {
  return { type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props: props };
}

/**
 * @memberof ComponentTypeActions
 * @description Clears Component Type props.
 */
export function clearComponentTypeProps() {
  return { type: actionTypes.CLEAR_COMPONENT_TYPE_PROPS };
}

/**
 * @memberof ComponentTypeActions
 * @description Clears Component Type errors messages.
 */
export function clearComponentTypeErrors() {
  return { type: actionTypes.CLEAR_COMPONENT_TYPE_ERRORS };
}

/**
 * @memberof ComponentTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations for component types.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_COMPONENTTYPE_LOAD_ORGANIZATIONS };
}

/**
 * @memberof ComponentTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available assembly types for component types.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadAssemblyTypes() {
  return { type: actionTypes.SAGA_COMPONENTTYPE_LOAD_ASSEMBLY_TYPES };
}

/**
 * @memberof ComponentTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available component groups for component types.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadComponentGroups() {
  return { type: actionTypes.SAGA_COMPONENTTYPE_LOAD_COMPONENT_GROUPS };
}

/**
 * @memberof ComponentTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available fields for component types.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadFields() {
  return { type: actionTypes.SAGA_COMPONENTTYPE_LOAD_FIELDS };
}


export function getComponentTypesByOrganization() {
  return { type: actionTypes.SAGA_COMPONENTTYPE_BY_ORGANIZATION };
}

