import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {assetEventFields} from 'common/objectStructures';
import { cloneDeep } from 'lodash';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const initialState = {
  assetEvents: [],
  assetEventsLevelOne: [],
  assetEventByAssetId: {},
  assetEvent: {},
  formValues: null,
  structureTypes: createState(),
  usersForReports: createState(),
  inspectors: createState()
}

function reducerAssetEvent (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_ASSETEVENT_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(assetEventFields)) {
          newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
          if (key === 'location'){
            if (action.props.formValues[key] && action.props.formValues[key] !== ""){
              let result = JSON.parse(action.props.formValues[key]);
              let i = 0;
              if (result.address_components.length === 6)
                i = 1;
              newValues[key] = result.address_components[i].long_name;
              if (result.address_components[i+1])
                newValues[key] += ", "+ result.address_components[i+1].long_name;
              if (result.address_components[i+2])
                newValues[key] += ", "+ result.address_components[i+2].long_name;
            } else newValues[key] =  "";
          }
          if (value.type === 'select'){
              if (action.props.formValues[key] && action.props.formValues[key] !== ""){
                  newValues[key] = action.props.formValues[key].id;
              }
          }
        }
        action.props.formValues = Object.assign({}, action.props.formValues, newValues);
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CHANGE_ASSETEVENT_NAME_PROPS:
      const newAssetEventByAssetId = Object.assign({}, state.assetEventByAssetId, {name: action.props.name})
      return Object.assign({}, state, {assetEventByAssetId: newAssetEventByAssetId})

    case actionTypes.EVENT_STATUS_UPDATED:
      //Check if AssetEvent has been loaded.
      if (!state.assetEventByAssetId || !state.assetEventByAssetId.event || state.assetEventByAssetId.event.id !== action.props.event.id) {
        return state;
      }

      const receivedEvent = action.props.event;
      const lastEventMilestoneStatus = receivedEvent.lastEventMilestoneStatus;

      //Only update properties that refer to statuses.
      let clonedState = cloneDeep(state);

      clonedState.assetEventByAssetId.event.lastEventMilestoneStatus = lastEventMilestoneStatus;
      clonedState.assetEventByAssetId.event.eventMilestoneStatuses = receivedEvent.eventMilestoneStatuses;
      clonedState.assetEventByAssetId.milestoneStatusCompleted = receivedEvent.isCompleted;
      return clonedState;


    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerAssetEvent),
  {
    structureTypes: [
      actionTypes.CHANGE_ASSETEVENT_START_LOADING_STRUCTURE_TYPES,
      actionTypes.CHANGE_ASSETEVENT_SUCCESS_LOADING_STRUCTURE_TYPES,
      actionTypes.CHANGE_ASSETEVENT_ERROR_LOADING_STRUCTURE_TYPES
    ],
    usersForReports: [
      actionTypes.CHANGE_ASSETEVENT_START_LOADING_USERS_WORK_REPORTS,
      actionTypes.CHANGE_ASSETEVENT_SUCCESS_LOADING_USERS_WORK_REPORTS,
      actionTypes.CHANGE_ASSETEVENT_ERROR_LOADING_USERS_WORK_REPORTS
    ],
    inspectors: [
      actionTypes.CHANGE_ASSETEVENT_START_LOADING_INSPECTORS,
      actionTypes.CHANGE_ASSETEVENT_SUCCESS_LOADING_INSPECTORS,
      actionTypes.CHANGE_ASSETEVENT_ERROR_LOADING_INSPECTORS
    ],
  }
);
