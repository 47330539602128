import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'


const endpoint = API_SERVER + '/subdivision';

const endpointRailroad = API_SERVER + '/railroad';

const endpointClient = API_SERVER + '/client';

export default class ApiSubDivision {

  // GET 'api/subdivision'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchSubdivisions(props){
    return Utils.genericCall(`${endpoint}/paged?search=${props.search}&page=${props.page}`);
  }

  static getByRailroads(props){
    return Utils.genericPost(`${endpoint}/pagedByRailroads?page=${props.page}`, props.ids);
  }

  static getByRailroad(id){
    return Utils.genericCall(`${endpointRailroad}/${id}/subdivision`);
  }

  static searchByRailroad(props){
    return Utils.genericCall(`${endpointRailroad}/${props.id}/subdivision/paged?search=${props.search}&page=${props.page}&size=${props.size}&order=${props.order.id}&desc=${props.order.desc}`);
  }

  static getSubdivision(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editSubdivision(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addSubdivision(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteSubdivision(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteSubdivisionChecked(data) {
    return Utils.genericPost(`${endpoint}/delete`, data);
  }

  static filterSubdivisions(data) {
    return Utils.genericCall(`${endpoint}/filter?name=${data}`);
  }

  static searchSubdivisionByName(data) {
    return Utils.genericCall(`${endpoint}/byName?search=${data}`);
  }

  static getByClient(props){
    return Utils.genericPost(`${endpoint}/client/${props.clientId}/paged`, props.data);
  }

  static getSubdivisionsListByCLient(clientId){
    return Utils.genericCall(`${endpointClient}/${clientId}/subdivision/all`);
  }

  // /subdivision/client/{clientId}
  static getByClientFilterByRailroads(props){
    return Utils.genericPost(`${endpoint}/client/${props.clientId}`, props.data);
  }

  static getBridgeInventoryReport(subdivisionId, type){
    return Utils.genericPreviewByTypeFile(`${endpoint}/${subdivisionId}/bridgeInventory.${type}`, null, type);
  }

  static getRecommendationByPriorityReport(subdivisionId, year, type){
    return Utils.genericPreviewByTypeFile(`${endpoint}/${subdivisionId}/year/${year}/recommendationsByPriority.${type}`, null, type);
  }

  static getInitialOwnerReport(subdivisionId, year, type){
    return Utils.genericPreviewByTypeFile(`${endpoint}/${subdivisionId}/year/${year}/initialOwnerReport.${type}`, null, type);
  }

  static addBridgesAndSectionFromTemplate(subdivisionId, data) {
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    return Utils.genericPost(`${API_SERVER}/addBridgesAndSectionFromTemplate/subdivision/${subdivisionId}`, data, headers);
  }


  static getSubdivisionContactsPaged(subdivisionId, paginationOptions) {
    return Utils.genericPost(`${endpoint}/${subdivisionId}/contacts/paged`, paginationOptions);
  }

  static editSubdivisionContact(subdivisionId, contactId, contactData) {
    return Utils.genericPut(`${endpoint}/${subdivisionId}/contacts/${contactId}`, contactData);
  }

  static addSubdivisionContact(subdivisionId, contactData) {
    return Utils.genericPost(`${endpoint}/${subdivisionId}/contacts`, contactData);
  }

  static deleteSubdivisionContactList(contactIdList) {
    return Utils.genericPost(`${endpoint}/contacts/deleteList`, contactIdList);
  }



  static getSubdivisionDocumentsPaged(subdivisionId, paginationOptions) {
    return Utils.genericPost(`${endpoint}/${subdivisionId}/documents/paged`, paginationOptions);
  }

  static editSubdivisionDocument(documentId, documentData) {
    return Utils.genericPut(`${endpoint}/documents/${documentId}`, documentData);
  }

  static addSubdivisionDocument(subdivisionId, documentData, fileData) {

    //For files, we have to send the request as a FormData.
    let form = new FormData();
    form.append('file', fileData)
    form.append('document', JSON.stringify(documentData))

    //Remove the Content-Type header. By default is application/json
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    return Utils.genericPost(`${endpoint}/${subdivisionId}/documents`, form, headers);
  }

  static deleteSubdivisionDocumentList(documentIdList) {
    return Utils.genericPost(`${endpoint}/documents/deleteList`, documentIdList);
  }

  static getSubdivisionDocumentTypes(organizationId) {
    return Utils.genericCall(`${API_SERVER}/${organizationId}/subdivision/documentTypes`);
  }

  static getSubdivisionOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getSubdivisionClients() {
    return Utils.genericCall(`${endpoint}/clients`);
  }

  static getSubdivisionContacts() {
    return Utils.genericCall(`${endpoint}/contacts`);
  }

  static getSubdivisionJobTitles(organizationId) {
    return Utils.genericCall(`${API_SERVER}/${organizationId}/subdivision/jobTitles`);
  }

  static getByRailroadsReduced(props){
    return Utils.genericCall(`${endpoint}/findByRailroad?railroadId=${props.id}`);
  }

}