import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const hasGAId = !!process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

if (hasGAId) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
}

function GAListener(props) {

  let location = useLocation();

  useEffect(() => {
    if (hasGAId) {
      let currentPath = location.pathname + location.search
      if(currentPath){
        sendPageView(currentPath);
      }
    }
  }, [location])

  const sendPageView = (location) => {
    ReactGA.set({ page: location });
  }


  return props.children;

}

export default GAListener;
