import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiAccessType from 'apiServices/ApiAccessType'

import { clearAccessTypeErrors } from '../actions/AccessTypeActions';
import { showRecordAddedToast, showRecordDeletedToast, showRecordUpdatedToast } from '../actions/ToastActions';

const recordTypeName = "access type";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The access type cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_ACCESSTYPE_PROPS, props });
}

/*
 * get AssetsAccess
 */

function* getAccessTypes() {
  try {
    yield loading();
    // get item types
    const allItems = yield call(ApiAccessType.getAll);
    // update state for items
    yield put({
      type: actionTypes.CHANGE_ACCESSTYPE_PROPS, props: {
        accessTypes: allItems,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchAccessType(action) {
  try {
    yield loading();
    // get all AccessType
    const accessType = yield call(ApiAccessType.searchAccessType, action.props);
    const pagination = {
      number: accessType.number,
      numberOfElements: accessType.numberOfElements,
      totalElements: accessType.totalElements,
      totalPages: accessType.totalPages,
      size: accessType.size
    };
    // update state for accessType
    yield put({
      type: actionTypes.CHANGE_ACCESSTYPE_PROPS, props: {
        accessTypes: accessType.content,
        pagination: pagination,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }
}

function* getAccessType(action) {
  try {
    yield loading();
    // get  AccessType
    const accessType = yield call(ApiAccessType.getAccessType, action.props.accessTypeId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: accessType.name,
        id: accessType.id,
        type: "accessType"
      }
    });
    // update state for accessType
    yield put({
      type: actionTypes.CHANGE_ACCESSTYPE_PROPS, props: {
        accessType: accessType,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* editAccessType(action) {

  yield put(clearAccessTypeErrors());

  try {
    yield loading();
    // update  AccessType
    yield call(ApiAccessType.editAccessType, action.props);
    // update state for accessType
    yield put({
      type: actionTypes.CHANGE_ACCESSTYPE_PROPS, props: {
        accessType: {},
        loading: false,
        errors: ""
      }
    });

    yield put(showRecordUpdatedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }
}

function* addAccessType(action) {

  yield put(clearAccessTypeErrors());

  try {
    yield loading();
    // add  AccessType
    yield call(ApiAccessType.addAccessType, action.props);
    // update state for accessType
    yield put({
      type: actionTypes.CHANGE_ACCESSTYPE_PROPS, props: {
        accessType: {},
        loading: false,
        errors: ""
      }
    });

    yield put(showRecordAddedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteAccessType(action) {

  yield put(clearAccessTypeErrors());

  try {
    yield loading();
    // delete  AccessType
    yield call(ApiAccessType.deleteAccessType, action.props);

    // update state for accessType
    yield put({
      type: actionTypes.CHANGE_ACCESSTYPE_PROPS, props: {
        loading: false,
        errors: ""
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteAccessTypeList(action) {

  yield put(clearAccessTypeErrors());

  try {
    yield loading();
    yield call(ApiAccessType.deleteAccessTypeList, action.props.list);

    // update state for accessType
    yield put({
      type: actionTypes.CHANGE_ACCESSTYPE_PROPS, props: {
        loading: false,
        errors: ""
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_ACCESS_TYPES_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiAccessType.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_ACCESS_TYPES_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_ACCESS_TYPES_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ACCESSTYPE_ALL, getAccessTypes),
    takeLatest(actionTypes.SAGA_ACCESSTYPE_SEARCH, searchAccessType),
    takeLatest(actionTypes.SAGA_ACCESSTYPE_GET, getAccessType),
    takeLatest(actionTypes.SAGA_ACCESSTYPE_EDIT, editAccessType),
    takeLatest(actionTypes.SAGA_ACCESSTYPE_ADD, addAccessType),
    takeLatest(actionTypes.SAGA_ACCESSTYPE_DELETE, deleteAccessType),
    takeLatest(actionTypes.SAGA_ACCESSTYPE_DELETE_CHECKED, deleteAccessTypeList),
    takeLatest(actionTypes.SAGA_ACCESSTYPE_LOAD_ORGANIZATIONS, loadOrganizations)
  ]);
}

export default AuthWatcher;
