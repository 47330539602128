import * as actionTypes from '../actions/actionTypes'
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
    workPerformeds: [],
    workPerformed: {},
}

function reducerWorkPerformed(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CHANGE_WORKPERFORMED_PROPS:
            return Object.assign({}, state, action.props)
        case actionTypes.CHANGE_WORKPERFORMED_PROPS_ADD_NEW_PHOTO:
            let workPerformed = Object.assign({}, state.workPerformed, {})
            workPerformed.workPerformedPhotos = action.props.newPhoto
            return Object.assign({}, state, {workPerformed})

        default:
            return state
    }
}

export default onLoginCleanupReducer(initialState, reducerWorkPerformed);
