import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  toast as reactToast,
  ToastContainer as ReactToastContainer
} from 'react-toastify';
import { removeActiveToast } from 'store/actions/ToastActions';

import Toast from 'components/Toast';

/**
 * @private
 * @description Toast options for every one.
 */
const commonToastOptions = {
  position: reactToast.POSITION.TOP_RIGHT,
};

/**
 * @private
 * @description Toast options for type === "success"
 */
const successToastOptions = {
  ...commonToastOptions,
  progressClassName: "success-progress",
}

/**
 * @private
 * @description Toast options for type === "error".
 */
const errorToastOptions = {
  ...commonToastOptions,
  progressClassName: "error-progress"
}

/**
 * @memberof ToastContainer
 * @typedef {object} ToastType
 * @property {string} [type=undefined] - Type of toast to display: "error" or any other value.
 * @property {string} message - Message to be displayed as the title of the toast.
 * @property {string} info - Info message for the user.
 * @property {boolean} [showToast=false] - true if the toast has to be presented, false
 * otherwise.
 */

/**
 * @memberof ToastContainer
 * @callback removeActiveToastCallback
 * @param {integer} toastId - ID of the toast.
 * @returns {undefined}
 */

/**
 * @description A container for {@link Toast} components. This component
 * will display every active toast.
 *
 * @class
 * @param {object} props - React props.
 * @param {ToastType[]} props.toasts - Active toasts.
 * @param {removeActiveToastCallback} props.removeActiveToast - function to call
 * when a Toast has to be removed from the array. The parameter given is the index
 * of the Toast info in the array of active toasts.
 * @returns A JSX component.
 */
const ToastContainer = props => {

  const {
    toasts,
    removeActiveToast
  } = props;

  const [ presentedToasts, setPresentedToasts ] = useState([]);

  // /*
  // KNONW BUG: if more than one toast is pushed at the same
  // time, every toast will be displayed twice but in the same position.
  // */
  const notPresentedToasts = useMemo(() => toasts.filter(current => {
    return !presentedToasts.includes(current.id);
  }), [toasts, presentedToasts]);

  useEffect(() => {

    if (notPresentedToasts.length === 0) {
      return;
    }

    setPresentedToasts(prev => ([
      ...prev,
      ...notPresentedToasts.map(t => t.id)
    ]));
  }, [notPresentedToasts]);

  useEffect(() => {
    if (notPresentedToasts.length > 0) {

      notPresentedToasts.forEach(current => {

        if (!current.showToast) {
          return;
        }

        const currentId = current.id;
        const onToastClose = () => {
          removeActiveToast(currentId);
        }

        let options = {
          onClose: onToastClose,
          toastId: currentId
        }

        if (current.type === "error") {
          options = {
            ...options,
            ...errorToastOptions
          }
        } else {
          options = {
            ...options,
            ...successToastOptions
          }
        }

        reactToast(
          <Toast
            type={current.type}
            message={current.message}
            info={current.info}
            showToast
          />,
          options
        );
      });

    }

  }, [removeActiveToast, notPresentedToasts]);

  return <ReactToastContainer />;
}

function mapStateToProps(store) {
  return {
    toasts: store.toast.activeToasts
  }
}



export default connect(mapStateToProps, {removeActiveToast })(ToastContainer);
