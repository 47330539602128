import * as actionTypes from './actionTypes'

/**
 * @namespace StructureTypeActions
 */


export function getStructureTypes() {
  return { type: actionTypes.SAGA_STRUCTURETYPE_ALL };
}

export function loadStructureTypesPaged(props) {
  return { type: actionTypes.SAGA_STRUCTURETYPE_SEARCH, props: props };
}

export function getStructureType(props) {
  return { type: actionTypes.SAGA_STRUCTURETYPE_GET, props: props };
}

export function getSingleStructureType(props) {
  return { type: actionTypes.SAGA_SINGLESTRUCTURETYPE_GET, props: props };
}


export function editStructureType(props) {
  return { type: actionTypes.SAGA_STRUCTURETYPE_EDIT, props: props };
}

export function addStructureType(props) {
  return { type: actionTypes.SAGA_STRUCTURETYPE_ADD, props: props };
}

export function deleteStructureType(props) {
  return { type: actionTypes.SAGA_STRUCTURETYPE_DELETE, props: props };
}

export function deleteStructureTypeList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_STRUCTURETYPE_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

/**
 * @memberof StructureTypeActions
 * @description Change StructureType props (no saga action)
 */
export function changeStructureTypeProps(props) {
  return { type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: props };
}

/**
 * @memberof StructureTypeActions
 * @description Clears Structure Type props
 */
export function clearStructureTypeProps() {
  return { type: actionTypes.CLEAR_STRUCTURE_TYPE_PROPS };
}

/**
 * @memberof StructureTypeActions
 * @description Clears Structure Type current errors messages.
 */
export function clearStructureTypeErrors() {
  return { type: actionTypes.CLEAR_STRUCTURE_TYPE_ERRORS };
}

/**
 * @memberof StructureTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations for StructureTypes.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_STRUCTURETYPE_LOAD_ORGANIZATIONS };
}

/**
 * @memberof StructureTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available asset types for structure types.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadAssetTypes(props) {
  return { type: actionTypes.SAGA_STRUCTURETYPE_LOAD_ASSET_TYPES };
}

/**
 * @memberof StructureTypeActions
 * @description Get Component by structure type and organization.
 */
 export function getComponents(props) {
  return { type: actionTypes.GET_STRUCTURE_TYPE_COMPONENTS, props: props };
}
