import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/eventCondition';

export default class EventCondition {

  // GET 'api/eventCondition'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static getEventCondition(assetId, eventId) {
    return Utils.genericCall(`${endpoint}/asset/${assetId}/event/${eventId}`);
  }

  static addEventCondition(data) {
    return Utils.genericPost(`${endpoint}`, data);
  }

  static getEventConditionById(eventConditionId) {
    return Utils.genericCall(`${endpoint}/${eventConditionId}`);
  }

  static addEventConditionPrevtoNew(data) {
    return Utils.genericPost(`${endpoint}/previous/${data.eventConditionId}`, data);
  }

  static editEventCondition(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }


  static deleteEventCondition(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static eventConditionUnchanged(data) {
    return Utils.genericPost(`${endpoint}/unchanged`, data);
  }

  static eventConditionSound(data) {
    return Utils.genericPost(`${endpoint}/sound`, data);
  }

  static addEventConditionMultiItems(data) {
    return Utils.genericPost(`${endpoint}/multipleAssignments`, data);
  }

  static addPhotosToCondition(data) {
    return Utils.genericPost(`${endpoint}/${data[0].eventCondition.id}/addPhotos`, data);
  }

  //@PostMapping("/eventCondition/{eventConditionId}/addPhotos")
  // const endpoint = API_SERVER + '/eventCondition';

  static deleteEventConditionArray(data) {
    return Utils.genericPost(`${endpoint}/delete`, data);
  }

  // "/eventCondition/previous/{id}" GET
  static getPreviousEventConditionById(id) {
    return Utils.genericCall(`${endpoint}/previous/${id}`);
  }

  static eventConditionSolved(data) {
    return Utils.genericPost(`${endpoint}/solved`, data);
  }
}
