import * as actionTypes from './actionTypes'

/**
 * Redux action creators for "Component Group".
 * @namespace ComponentGroupActions
 */

/**
 * @memberof ComponentGroupActions
 * @callback emptyCallback
 */


export function getComponentGroups(){
  return { type: actionTypes.SAGA_COMPONENTGROUP_ALL };
}

export function loadComponentGroupsPaged(props){
  return { type: actionTypes.SAGA_COMPONENTGROUP_SEARCH, props: props };
}

export function getComponentGroup(props){
  return { type: actionTypes.SAGA_COMPONENTGROUP_GET, props: props };
}

export function editComponentGroup(props){
  return { type: actionTypes.SAGA_COMPONENTGROUP_EDIT, props: props };
}

export function addComponentGroup(props){
  return { type: actionTypes.SAGA_COMPONENTGROUP_ADD, props: props };
}

export function deleteComponentGroup(props){
  return { type: actionTypes.SAGA_COMPONENTGROUP_DELETE, props: props };
}

/**
 * @memberof ComponentGroupActions
 * @description Creates an action that will trigger the
 * deletion of the "Component Group"s provided.
 * @param {string[]} list ID's of the components groups
 * to be deleted.
 * @param {object} options
 * @param {emptyCallback} options.callback A function to be called
 * when the "Component Groups" have been deleted.
 * @returns a Redux action that can be dispatched.
 */
export function deleteComponentGroupList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_COMPONENTGROUP_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}
/**
 * @memberof ComponentGroupActions
 * @description Change ComponentGroup props (no saga action)
 * @returns A Redux action that can be dispatched.
 */
export function changeComponentGroupProps(props){
  return { type: actionTypes.CHANGE_COMPONENTGROUP_PROPS, props: props };
}

/**
 * @memberof ComponentGroupActions
 * @description Clears Component Group props (no saga action).
 * @returns A Redux action that can be dispatched.
 */
export function clearComponentGroupProps() {
  return { type: actionTypes.CLEAR_COMPONENT_GROUP_PROPS };
}

/**
 * @memberof ComponentGroupActions
 * @description Clears Component Group current error messages.
 * @returns A Redux action that can be dispatched.
 */
export function clearComponentGroupErrors() {
  return { type: actionTypes.CLEAR_COMPONENT_GROUP_ERRORS };
}

/**
 * @memberof ComponentGroupActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations fo ComponentGroups.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_COMPONENTGROUP_LOAD_ORGANIZATIONS };
}
