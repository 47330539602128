import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/documentType';

export default class ApiDocumentType {

  // GET 'api/documentType'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchDocumentTypes(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getDocumentType(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editDocumentType(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addDocumentType(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteDocumentType(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteDocumentTypeList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getDocumentTypeOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }
}
