import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";


const defaultIdExtractor = x => x.id;
const defaultNameExtractor = x => x.name;

/**
 * Displays a modal thats ask the user to select a value from
 * a list.
 * @param {object} props React props.
 * @param {boolean} [props.show=false] true if the modal has to be
 * presented, false otherwise.
 * @param {string} props.title title of the modal.
 * @param {string} props.informationText additional information text
 * for the user.
 * @param {function} props.onCancel function to be called when the
 * user cancels the selection.
 * @param {function} props.onConfirm function to be called when the
 * user confirms a selection. The first parameter will be the object
 * selected extracted from the models prop.
 * @param {object[]} props.model models to present to the user.
 * @param {object | null} [props.defaultValue=null] default value (model)
 * to select. If it's not provided, the first model from the "models" props
 * will be selected.
 * @param {function} [props.idExtractor= x=>x.id] a function that recieves
 * a model and returns the ID of it.
 * @param {function} [props.nameExtractor=x=>x.name] a function that recieves
 * a model and returns the name to be presented to the user.
 * @returns A JSX element.
 */
const ModalValueSelector = props => {

  const {
    show,
    title,
    informationText,
    onCancel,
    onConfirm,
    models,
    defaultValue,
    idExtractor: propsIdExtractor,
    nameExtractor: propsNameExtractor
  } = props;

  const idExtractor = useMemo(() => propsIdExtractor || defaultIdExtractor, [propsIdExtractor]);
  const nameExtractor = useMemo(() => propsNameExtractor || defaultNameExtractor, [propsNameExtractor]);

  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [setSelectedValue, defaultValue]);

  useEffect(() => {
    let newValue = null;
    if (models.length > 0) {
      newValue = models[0];
    }

    if (!defaultValue) {
      setSelectedValue(newValue);
    }

  }, [models, setSelectedValue, defaultValue]);

  const onSubmit = useCallback(() => {
    onConfirm(selectedValue);
  }, [onConfirm, selectedValue]);

  const onSelectionChange = useCallback(selectedId => {
    const value = models.find(x => selectedId === "" + idExtractor(x));
    setSelectedValue(value);
  }, [models, idExtractor, setSelectedValue]);

  const options = useMemo(() => models.map(x => {
    const id = idExtractor(x);
    const name = nameExtractor(x);
    return <option value={id} key={id}>{name}</option>
  }), [models, idExtractor, nameExtractor]);


  const selectedId = useMemo(() => selectedValue ? idExtractor(selectedValue) : "", [selectedValue, idExtractor]);

  return <Modal isOpen={show}>
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>
      {informationText}
      <select
        name="selection"
        id="selection"
        onChange={e => onSelectionChange(e.target.value)}
        value={selectedId}
        className="form-control"
      >
        {options}
      </select>
    </ModalBody>
    <ModalFooter className="form-button-container">
      <Button
        outline
        color="primary"
        onClick={onCancel}
      >
        Cancel
      </Button >
      <Button
        color="primary"
        onClick={onSubmit}
      >
        Confirm
      </Button>
    </ModalFooter>
  </Modal>
}


export default ModalValueSelector;
