import * as actionTypes from '../actions/actionTypes'

const initialState = {
  redirectTo: '/',
  loading: false,
  errors: '',
  message: '',
  token: '',
  session: null, //user data
  roles: [],
}

export default function reducerAuth (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_AUTH_PROPS:
      return Object.assign({}, state, action.props)

    case actionTypes.RESET_AUTH_PROPS:
      return Object.assign({}, initialState)

    default:
      return state
  }
}
