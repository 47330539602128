import * as actionTypes from './actionTypes'

/**
 * Redux actions creatos for Toast notifications.
 * @namespace ToastActions
 */


/**
 * @deprecated Use one of the specific actions instead.
 * @memberof ToastActions
 * @description Changes Toast props.
 *
 * This will not trigger a SAGA action.
 * @param {object} props.
 * @returns A Redux action that can be dispatched.
 */
export function changeToastProps(props) {
  return { type: actionTypes.CHANGE_TOAST_PROPS, props: props };
}

/**
 * @memberof ToastActions
 * @description Creates a Redux action that can be
 * dispatched to show a Toast as error.
 *
 * This will not trigger a SAGA action.
 *
 * @param {string} title - Title of the toast.
 * @param {*} errorMessage - Error message.
 * @returns A Reux action that can be dispatched.
 */
export function showToastAsError(title, errorMessage) {
  return {
    type: actionTypes.CHANGE_TOAST_PROPS,
    props: {
      showToast: true,
      type: 'error',
      message: title,
      info: errorMessage,
      loading: true
    }
  }
}

/**
 * @memberof ToastActions
 * @description Creates a Redux action that can be
 * dispatched to show a Toast as success.
 *
 * This will not trigger a SAGA action.
 *
 * @param {string} title - Title of the toast.
 * @param {string} successMessage - Message of the toast.
 * @returns A Redux action that can be dispatched.
 */
export function showToastAsSuccess(title, successMessage) {
  return {
    type: actionTypes.CHANGE_TOAST_PROPS,
    props: {
      showToast: true,
      type: 'success',
      message: title,
      info: successMessage,
      loading: true
    }
  }
}

/**
 * @memberof ToastActions
 * @description Creates a Redux action that can be
 * dispatched to show a success Toast with a message
 * indicating that the record has been created.
 *
 * This will not trigger a SAGA action.
 *
 * @param {string} recordTypeName - The name of the type of the record added.
 * @returns A Redux action that can be dispatched.
 */
export function showRecordAddedToast(recordTypeName) {
  return showToastAsSuccess('New record added', 'The ' + recordTypeName + ' has been added.');
}

/**
 * @memberof ToastActions
 * @description Creates a Redux action that can be
 * dispatched to show a success Toast with a message
 * indicating that the record has been updated.
 *
 * This will not trigger a SAGA action.
 *
 * @param {string} recordTypeName - The name of the type of the record updated.
 * @returns A Redux action that can be dispatched.
 */
export function showRecordUpdatedToast(recordTypeName) {
  return showToastAsSuccess('Record updated', 'The ' + recordTypeName + ' record has been updated.');
}

/**
 * @memberof ToastActions
 * @description Creates a Redux action that can be
 * dispatched to show a success Toast with a message
 * indicating that the record has been deleted.
 *
 * This will not trigger a SAGA action.
 *
 * @param {string} recordTypeName - The name of the type of the record deleted.
 * @returns A Redux action that can be dispatched.
 */
export function showRecordDeletedToast(recordTypeName) {
  return showToastAsSuccess('Record deleted', 'The ' + recordTypeName + ' has been deleted.');
}

/**
 * @memberof ToastActions
 * @description Generates a success Toast that will inform that a report will be send
 * to the user email when it is generated.
 *
 * @returns A Redux action that can be dispatched.
 */
export function showReportSendToEmailToast() {
  return showToastAsSuccess(
    'Reports being generated',
    'Your request is being processed. You’ll be notified by email when the reports are generated.'
  );
}

/**
 * @memberof ToastActions
 * @description Generates an error Toast that will inform that a report generation
 * has failed.
 *
 * @returns A Redux action that can be dispatched.
 */
export function showRerpotGenerationFailedToast() {
  return showToastAsError(
    'Reports could not be generated',
    'There was an error generating the reports. Try again later.'
  );
}

/**
 * @memberof ToastActions
 * @description Generates an error Toast that will inform that the downloading
 * of a file could not be performed.
 *
 * @param {string} fileName - The name of the file downloaded.
 * @returns A Redux action that can be dispatched.
 */
export function showDownloadErrorFailed(fileName) {
  return showToastAsError(
    'File could not be downloaded',
    'There was an error with the download of the file "' + fileName + '"'
  );
  }

/**
 * @memberof ToastActions
 * @description Removes an active Toast based on the index.
 * @param {number} toastId - The index of the toast.
 * @returns A Redux action that can be dispatched.
 */
export function removeActiveToast(toastId) {
  return {
    type: actionTypes.CHANGE_TOAST_REMOVE_ACTIVE,
    toastId
  }
}

/**
 * @memberof ToastActions
 * @deprecated using toast error and send the error msg
 * @description Shows an error toast with a message that
 * the photos ZIP file could not be generated.
 * @returns A Redux action that can be dispatched.
 */
export function errorGeneratingPhotoZIPFile() {
  return showToastAsError(
    "Photo ZIP generation error",
    "There was an error generating the ZIP file of the requested photos. Please, try again later or contact support."
  );
}

/**
 * @memberof ToastActions
 * @deprecated using toast and send the error msg
 * @description Shows a success toast with a message that
 * the photos ZIP file is available for download.
 * @returns A Redux action that can be dispatched.
 */
export function photoZIPFileGenerated() {
  return showToastAsSuccess(
    "Photo ZIP generation completed",
    "Your requested photos are available for download. Check your email for a download link."
  );
}

export function showErrorAddingRecord(recordTypeName) {
  return showToastAsError(
    "There was an error creating " + recordTypeName,
    "An error ocurred when trying to create the record. Please try again later."
  );
}

export function showErrorUpdatingRecord(recordTypeName) {
  return showToastAsError(
    "There was an error editing " + recordTypeName,
    "An error ocurred when trying to edit the record. Please try again later."
  );
}
