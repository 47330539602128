import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiJobTitle from 'apiServices/ApiJobTitle'

import { clearJobTitleErrors } from '../actions/JobTitleActions';
import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast } from '../actions/ToastActions';

const recordType = "job title";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The job title cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_JOBTITLE_PROPS, props });
}

/*
 * get AssetsAccess
 */

function* getJobTitles() {
  try {
    yield loading();
    // get item types
    const allItems = yield call(ApiJobTitle.getAll);
    // update state for items
    yield put({
      type: actionTypes.CHANGE_JOBTITLE_PROPS, props: {
        jobTitles: allItems,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }
}

function* searchJobTitle(action) {
  try {
    yield loading();
    // get all JobTitle
    const JobTitle = yield call(ApiJobTitle.searchJobTitle, action.props);

    const pagination = {
      number: JobTitle.number,
      numberOfElements: JobTitle.numberOfElements,
      totalElements: JobTitle.totalElements,
      totalPages: JobTitle.totalPages,
      size: JobTitle.size
    };
    // update state for JobTitle
    yield put({
      type: actionTypes.CHANGE_JOBTITLE_PROPS, props: {
        jobTitles: JobTitle.content,
        pagination: pagination,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }
}

function* getJobTitle(action) {
  try {
    yield loading();
    // get  JobTitle
    const jobTitle = yield call(ApiJobTitle.getJobTitle, action.props.jobTitleId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: jobTitle.name,
        id: jobTitle.id,
        type: "jobTitle"
      }
    });
    // update state for JobTitle
    yield put({
      type: actionTypes.CHANGE_JOBTITLE_PROPS, props: {
        jobTitle: jobTitle,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* editJobTitle(action) {

  yield put(clearJobTitleErrors());

  try {
    yield loading();
    // update  JobTitle
    yield call(ApiJobTitle.editJobTitle, action.props);
    // update state for JobTitle
    yield put({
      type: actionTypes.CHANGE_JOBTITLE_PROPS, props: {
        jobTitle: {},
        loading: false,
        errors: ""
      }
    });
    yield put(showRecordUpdatedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }
}

function* addJobTitle(action) {

  yield put(clearJobTitleErrors());

  try {
    yield loading();
    // add  jobTitle
    yield call(ApiJobTitle.addJobTitle, action.props);
    // update state for jobTitle
    yield put({
      type: actionTypes.CHANGE_JOBTITLE_PROPS, props: {
        jobTitle: {},
        loading: false,
        errors: ""
      }
    });
    yield put(showRecordAddedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteJobTitle(action) {

  yield put(clearJobTitleErrors());

  try {
    yield loading();
    // delete  JobTitle
    yield call(ApiJobTitle.deleteJobTitle, action.props);

    // update state for JobTitle
    yield put({
      type: actionTypes.CHANGE_JOBTITLE_PROPS, props: {
        loading: false,
        errors: ""
      }
    });
    yield put(showRecordDeletedToast(recordType));

    if (action.props.callback) {
      action.props.callback();
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteJobTitleList(action) {

  yield put(clearJobTitleErrors());

  try {
    yield loading();
    yield call(ApiJobTitle.deleteJobTitleList, action.props.list);

    // update state for JobTitle
    yield put({
      type: actionTypes.CHANGE_JOBTITLE_PROPS, props: {
        loading: false,
        errors: ""
      }
    });
    yield put(showRecordDeletedToast(recordType));

    if (action.props.callback) {
      action.props.callback();
    }

  } catch (error) {
    yield loading(false, error);
  }
}


function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_JOBTITLE_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiJobTitle.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_JOBTITLE_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_JOBTITLE_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}



/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_JOBTITLE_ALL, getJobTitles),
    takeLatest(actionTypes.SAGA_JOBTITLE_SEARCH, searchJobTitle),
    takeLatest(actionTypes.SAGA_JOBTITLE_GET, getJobTitle),
    takeLatest(actionTypes.SAGA_JOBTITLE_EDIT, editJobTitle),
    takeLatest(actionTypes.SAGA_JOBTITLE_ADD, addJobTitle),
    takeLatest(actionTypes.SAGA_JOBTITLE_DELETE, deleteJobTitle),
    takeLatest(actionTypes.SAGA_JOBTITLE_DELETE_CHECKED, deleteJobTitleList),
    takeLatest(actionTypes.SAGA_JOBTITLE_LOAD_ORGANIZATIONS, loadOrganizations)
  ]);
}

export default AuthWatcher;
