import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
  error: '',
  info: {}
}

function reducer (state = initialState, action) {
  if (action.type === actionTypes.CHANGE_ERROR_PROPS) {
    return Object.assign({}, state, action.props)
  }
  return state
}

export default onLoginCleanupReducer(initialState, reducer);
