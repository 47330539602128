import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const pagedObjectsDefaultState = createState(false, {content: []});

const initialState = {
  subdivisions: [],
  subdivision: {},
  bridges: [],
  pagination: {},
  edited: false,
  loading: false,
  errors: '',

  contactsPaged: pagedObjectsDefaultState,
  documentsPaged: pagedObjectsDefaultState,
  documentTypes: createState(),
  organizations: createState(),
  clients: createState(),
  contacts: createState(),
  jobTitles: createState()
}

function reducerSubdivision (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_SUBDIVISION_PROPS:
      if (action.props.formValues!= null){
        const newValues = {
          id: action.props.formValues.id,
          name: action.props.formValues.name || "",
          manager: action.props.formValues.manager || null,
          roadMaster: action.props.formValues.roadMaster || null,
          bridgeProgramManager: action.props.formValues.bridgeProgramManager || null,
          milepostIncreases: action.props.formValues.milepostIncreases || null,
          directionalLoading: action.props.formValues.directionalLoading || null,
          traffic: action.props.formValues.traffic || null,
          hazmat: action.props.formValues.hazmat || null,
        };
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CHANGE_SUBDIVISION:
      return {
        ...state,
        //Remove the old subdivision and add the new data.
        subdivisions: [...state.subdivisions.filter(x => x.id !== action.props.subdivision.id), action.props.subdivision]
      };

    case actionTypes.CHANGE_SUBDIVISION_REMOVED:
      return {
        ...state,
        subdivision: state.subdivisions.filter(x => action.props.removedSubdivisionsIds.includes(x.id) === false)
      };

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerSubdivision),
  {
    contactsPaged: [
      actionTypes.CHANGE_SUBDIVISION_START_LOADING_CONTACTS_PAGED,
      actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_CONTACTS_PAGED,
      actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_CONTACTS_PAGED
    ],
    documentsPaged: [
      actionTypes.CHANGE_SUBDIVISION_START_LOADING_DOCUMENTS,
      actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_DOCUMENTS,
      actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_DOCUMENTS
    ],
    documentTypes: [
      actionTypes.CHANGE_SUBDIVISION_START_LOADING_DOCUMENT_TYPES,
      actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_DOCUMENT_TYPES,
      actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_DOCUMENT_TYPES
    ],
    organizations: [
      actionTypes.CHANGE_SUBDIVISION_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_ORGANIZATIONS
    ],
    clients: [
      actionTypes.CHANGE_SUBDIVISION_START_LOADING_CLIENTS,
      actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_CLIENTS,
      actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_CLIENTS
    ],
    contacts: [
      actionTypes.CHANGE_SUBDIVISION_START_LOADING_CONTACTS,
      actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_CONTACTS,
      actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_CONTACTS
    ],
    jobTitles: [
      actionTypes.CHANGE_SUBDIVISION_START_LOADING_JOB_TITLES,
      actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_JOB_TITLES,
      actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_JOB_TITLES
    ]
  }
);
