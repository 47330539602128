import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
  permissionsForthis: true
}

function reducerPermissions (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_PERMISSIONS_PROPS:
      return Object.assign({}, state, action.props)

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerPermissions);
