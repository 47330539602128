import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiSubdivision from 'tests/mocks/ApiSubDivision.mock'
import ApiSubdivision from 'apiServices/ApiSubDivision'

import {
  showRecordAddedToast,
  showRecordUpdatedToast,
  showRecordDeletedToast,
  showRerpotGenerationFailedToast,
  showToastAsError
} from '../actions/ToastActions';

const recordType = "subdivision";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The Subdivision cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_SUBDIVISION_PROPS, props });
}

/*
 * get Subdivisions
 */
function* getSubdivisions() {
  try {
    yield loading();
    // get all subdivisions
    const allSubdivisions = yield call(ApiSubdivision.getAll);
    // update state for subdivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivisions: allSubdivisions,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* getSubdivision(action) {
  try {
    yield loading();
    // get subdivision
    const subdivision = yield call(ApiSubdivision.getSubdivision, action.props.subdivisionId);
    yield put({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: subdivision.name,
        id: subdivision.id,
        type: "subdivision"
      }
    });
    if (Object.keys(action.props).length > 1)
      yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 2, ids: action.props } });
    // update state for subdivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivision: subdivision,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getByRailroad(action) {
  try {
    yield loading();
    yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 2, ids: action.props } });
    // get railroad subdivisions first page
    yield searchByRailroad({ props: { id: action.props.railroadId, search: '', page: 0, order: { id: 'name', desc: false } } });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getByRailroads(action) {
  try {
    yield loading();
    // get all subdivisions by railroads
    const subdivisions = yield call(ApiSubdivision.getByRailroads, action.props);
    // update state for subdivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivisions: subdivisions.content,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getByRailroadsReduced(action) {
  try {
    yield loading();
    // get all subdivisions by railroads
    const subdivisions = yield call(ApiSubdivision.getByRailroadsReduced, action.props);
    // update state for subdivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivisions: subdivisions,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchByRailroad(action) {
  try {
    yield loading();
    // get all subdivisions by railroad
    const railroadSubdivisions = yield call(ApiSubdivision.searchByRailroad, action.props);
    // update state for subdivisions
    const pagination = {
      number: railroadSubdivisions.number,
      numberOfElements: railroadSubdivisions.numberOfElements,
      totalElements: railroadSubdivisions.totalElements,
      totalPages: railroadSubdivisions.totalPages,
      size: railroadSubdivisions.size
    };
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivisions: railroadSubdivisions.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchSubdivisions(action) {
  try {
    yield loading();
    // get all SubDivisions
    const subdivisions = yield call(ApiSubdivision.searchSubdivisions, action.props);
    // update state for subdivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivisions: subdivisions.content,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editSubdivision(action) {
  try {
    yield loading();
    yield put({ type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: { edited: true } });
    // update  subdivision
    const subdivision = yield call(ApiSubdivision.editSubdivision, action.props);



    // update state for subdivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivision: {},
        loading: false,
        errors: ''
      }
    });

    yield put({
      type: actionTypes.CHANGE_SUBDIVISION,
      props: {
        subdivision
      }
    });


    yield put(showRecordUpdatedToast(recordType));
    yield getSubdivision({ props: { subdivisionId: action.props.id } });
  } catch (error) {
    yield loading(false, error);
  }

}

function* addSubdivision(action) {
  try {
    yield loading();
    // add  subdivision
    const subdivision = yield call(ApiSubdivision.addSubdivision, action.props);
    // update state for SubDivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivision: {},
        loading: false,
        errors: ''
      }
    });

    yield put({
      type: actionTypes.CHANGE_SUBDIVISION,
      props: {
        subdivision
      }
    });

    yield put(showRecordAddedToast(recordType));

    if (action.props.clientId)
      yield searchByClient({
        props: {
          clientId: action.props.clientId, data: {
            filterArr: [], search: '', page: 0, order: { id: 'name', desc: false }, limit: 10
          }
        }
      })
    else
      yield searchByRailroad({ props: { id: action.props.railRoad.id, search: '', page: 0, order: { id: 'name', desc: false } } });

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteSubdivision(action) {
  try {
    yield loading();
    // delete subdivision
    yield call(ApiSubdivision.deleteSubdivision, action.props.id);
    yield put(showRecordDeletedToast(recordType));
    yield searchByRailroad({ props: { id: action.props.railroad.id, search: '', page: 0, order: { id: 'name', desc: false } } });

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteSubdivisionChecked(action) {
  try {
    yield loading();
    const deletedSubdivisionsIds = yield call(ApiSubdivision.deleteSubdivisionChecked, action.props.data);

    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_REMOVED,
      props: {
        removedSubdivisionsIds: deletedSubdivisionsIds
      }
    });
    yield put(showRecordDeletedToast(recordType));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* filterSubdivisions(action) {
  try {
    yield loading();
    // get filtered subdivisions
    const filteredSubdivisions = yield call(ApiSubdivision.filterSubdivisions, action.props);
    // update state for subdivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivisions: filteredSubdivisions,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchSubdivisionByName(action) {
  try {
    yield loading();
    // search all SubDivisions
    const subdivisions = yield call(ApiSubdivision.searchSubdivisionByName, action.props);
    // update state for subdivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivisions: subdivisions,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchByClient(action) {
  try {
    yield loading();
    // get all subdivisions by railroad
    const clientSubdivisions = yield call(ApiSubdivision.getByClient, action.props);
    // update state for subdivisions
    const pagination = {
      number: clientSubdivisions.number,
      numberOfElements: clientSubdivisions.numberOfElements,
      totalElements: clientSubdivisions.totalElements,
      totalPages: clientSubdivisions.totalPages,
      size: clientSubdivisions.size
    };
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivisions: clientSubdivisions.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getBridgeInventoryReport(action) {
  try {
    yield call(ApiSubdivision.getBridgeInventoryReport, action.props.subdivisionId, action.props.type);
  } catch (error) {
    yield put(showRerpotGenerationFailedToast());
  }
}

function* getRecommendationByPriorityReport(action) {
  try {
    yield call(ApiSubdivision.getRecommendationByPriorityReport, action.props.subdivisionId, action.props.year, action.props.type);
  } catch (error) {
    yield put(showRerpotGenerationFailedToast());
  }
}

function* getInitialOwnerReport(action) {
  try {
    yield call(ApiSubdivision.getInitialOwnerReport, action.props.subdivisionId, action.props.year,  action.props.type);
  } catch (error) {
    yield put(showRerpotGenerationFailedToast());
  }
}

function* getByClientFilterByRailroads(action) {
  try {
    yield loading();
    // search all SubDivisions
    const subdivisions = yield call(ApiSubdivision.getByClientFilterByRailroads, action.props);
    // update state for subdivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivisions: subdivisions,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getSubdivisionsListByCLient(action) {
  try {
    yield loading();
    // search all SubDivisions
    const subdivisions = yield call(ApiSubdivision.getSubdivisionsListByCLient, action.props.clientId);
    // update state for subdivisions
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS, props: {
        subdivisions: subdivisions,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* addBridgesAndSectionFromTemplate(action) {
  try {
    yield loading();
    // search all SubDivisions
    yield call(ApiSubdivision.addBridgesAndSectionFromTemplate, action.props.subdivisionId, action.props.data);

    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_PROPS,
      props: {
        loading: false,
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}


/**
 *
 * @param {*} action
 */
 function* loadSubdivisionContactsPaged(action) {

  try {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_START_LOADING_CONTACTS_PAGED
    });

    const subdivisionId = action.props.subdivisionId;
    const paginationOptions = action.props.paginationOptions;
    const contacts = yield call(ApiSubdivision.getSubdivisionContactsPaged, subdivisionId, paginationOptions);

    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_CONTACTS_PAGED,
      props: {
        contactsPaged: contacts
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_CONTACTS_PAGED,
      error: e.message
    })
  }
}

function* editSubdivisionContact(action) {
  try {
    const subdivisionId = action.props.subdivisionId;
    const contactId = action.props.contactId;
    const contactData = action.props.contactData;

    yield call(ApiSubdivision.editSubdivisionContact, subdivisionId, contactId, contactData);
    yield put(showRecordUpdatedToast("contact"));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch(error) {
    //TODO show error
  }
}

function* addSubdivisionContact(action) {
  try {
    const { subdivisionId, contactData, callback } = action.props;
    yield call(ApiSubdivision.addSubdivisionContact, subdivisionId, contactData);
    yield put(showRecordAddedToast("contact"));

    if (callback) {
      yield call(callback);
    }
  } catch(error) {
    //TODO show errir
  }
}

function* deleteSubdivisionContactList(action) {
  try {
    const { contactIdList, callback } = action.props;
    yield call(ApiSubdivision.deleteSubdivisionContactList, contactIdList);
    yield put(showRecordDeletedToast("contact"));

    if (callback) {
      yield call(callback);
    }

  } catch(error) {
    yield put(showToastAsError("Contacts could not be deleted. They cannot be associated to other object."));
  }
}


/**
 *
 * @param {*} action
 */
 function* loadSubdivisionDocumentsPaged(action) {

  try {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_START_LOADING_DOCUMENTS
    });

    const subdivisionId = action.props.subdivisionId;
    const paginationOptions = action.props.paginationOptions;
    const documents = yield call(ApiSubdivision.getSubdivisionDocumentsPaged, subdivisionId, paginationOptions);
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_DOCUMENTS,
      props: {
        documentsPaged: documents
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_DOCUMENTS,
      error: e.message
    })
  }
}

function* editSubdivisionDocument(action) {
  try {

    const documentId = action.props.documentId;
    const documentData = action.props.documentData;

    yield call(ApiSubdivision.editSubdivisionDocument, documentId, documentData);
    yield put(showRecordUpdatedToast("document"));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch(error) {
    //TODO show error
  }
}

function* addSubdivisionDocument(action) {
  try {
    const { subdivisionId, documentData, fileData, callback } = action.props;
    yield call(ApiSubdivision.addSubdivisionDocument, subdivisionId, documentData, fileData);
    yield put(showRecordAddedToast("document"));
    if (callback) {
      yield call(callback);
    }
  } catch(error) {
    //TODO show error
  }
}

function* deleteSubdivisionDocumentList(action) {
  try {
    const { documentIdList, callback } = action.props;
    yield call(ApiSubdivision.deleteSubdivisionDocumentList, documentIdList);
    yield put(showRecordDeletedToast("document"));

    if (callback) {
      yield call(callback);
    }

  } catch(error) {
    //TODO show error
  }
}

function* loadSubdivisionDocumentTypes(action) {
  try {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_START_LOADING_DOCUMENT_TYPES
    });

    const organizationId = action.props.organizationId;
    const documentTypes = yield call(ApiSubdivision.getSubdivisionDocumentTypes, organizationId);

    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_DOCUMENT_TYPES,
      props: {documentTypes}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_DOCUMENT_TYPES,
      error: error.message
    });
  }
}


function* loadSubdivisionOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiSubdivision.getSubdivisionOrganizations);

    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_ORGANIZATIONS,
      props: {organizations}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_ORGANIZATIONS,
      error: error.message
    });
  }
}


function* loadSubdivisionClients() {
  try {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_START_LOADING_CLIENTS
    });

    const clients = yield call(ApiSubdivision.getSubdivisionClients);

    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_CLIENTS,
      props: {clients}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_CLIENTS,
      error: error.message
    });
  }
}


function* loadSubdivisionContacts() {
  try {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_START_LOADING_CONTACTS
    });

    const contacts = yield call(ApiSubdivision.getSubdivisionContacts);

    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_CONTACTS,
      props: {contacts}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_CONTACTS,
      error: error.message
    });
  }
}

function* loadSubdivisionJobTitles(action) {
  try {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_START_LOADING_JOB_TITLES
    });

    const organizationId = action.props.organizationId;
    const jobTitles = yield call(ApiSubdivision.getSubdivisionJobTitles, organizationId);

    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_SUCCESS_LOADING_JOB_TITLES,
      props: {jobTitles}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_SUBDIVISION_ERROR_LOADING_JOB_TITLES,
      error: error.message
    });
  }
}



/*
 * Watcher
 */
function* SubdivisionWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_SUBDIVISION_ALL, getSubdivisions),
    takeLatest(actionTypes.SAGA_SUBDIVISION_GET, getSubdivision),
    takeLatest(actionTypes.SAGA_SUBDIVISION_SEARCH, searchSubdivisions),
    takeLatest(actionTypes.SAGA_SUBDIVISION_EDIT, editSubdivision),
    takeLatest(actionTypes.SAGA_SUBDIVISION_ADD, addSubdivision),
    takeLatest(actionTypes.SAGA_RAILROAD_SUBDIVISIONS, getByRailroad),
    takeLatest(actionTypes.SAGA_RAILROAD_SUBDIVISIONS_SEARCH, searchByRailroad),
    takeLatest(actionTypes.SAGA_SUBDIVISION_DELETE, deleteSubdivision),
    takeLatest(actionTypes.SAGA_SUBDIVISION_BY_RAILROADS, getByRailroads),
    takeLatest(actionTypes.SAGA_SUBDIVISION_BY_RAILROADS_REDUCED, getByRailroadsReduced),
    takeLatest(actionTypes.SAGA_SUBDIVISION_DELETE_CHECKED, deleteSubdivisionChecked),
    takeLatest(actionTypes.SAGA_SUBDIVISION_FILTER, filterSubdivisions),
    takeLatest(actionTypes.SAGA_SUBDIVISION_BY_NAME, searchSubdivisionByName),
    takeLatest(actionTypes.SAGA_CLIENT_SUBDIVISIONS, searchByClient),
    takeLatest(actionTypes.SAGA_EVENT_GET_BRIDGE_INVENTORY_REPORT, getBridgeInventoryReport),
    takeLatest(actionTypes.SAGA_EVENT_GET_RECOMMENDATION_BY_PRIORITY_REPORT, getRecommendationByPriorityReport),
    takeLatest(actionTypes.SAGA_EVENT_GET_INITIAL_OWNER_REPORT, getInitialOwnerReport),
    takeLatest(actionTypes.SAGA_SUBDIVISION_BY_CLIENT_RAILROADS, getByClientFilterByRailroads),
    takeLatest(actionTypes.SAGA_SUBDIVISION_LIST_BY_CLIENTS, getSubdivisionsListByCLient),
    takeLatest(actionTypes.SAGA_SUBDIVISION_BRIDGE_AND_SECTION_FROM_TEMPLATE, addBridgesAndSectionFromTemplate),

    takeLatest(actionTypes.SAGA_SUBDIVISION_CONTACTS_LOAD_PAGED, loadSubdivisionContactsPaged),
    takeLatest(actionTypes.SAGA_SUBDIVISION_CONTACT_EDIT, editSubdivisionContact),
    takeLatest(actionTypes.SAGA_SUBDIVISION_CONTACT_ADD, addSubdivisionContact),
    takeLatest(actionTypes.SAGA_SUBDIVISION_CONTACT_DELETE_LIST, deleteSubdivisionContactList),

    takeLatest(actionTypes.SAGA_SUBDIVISION_DOCUMENTS_LOAD_PAGED, loadSubdivisionDocumentsPaged),
    takeLatest(actionTypes.SAGA_SUBDIVISION_DOCUMENT_EDIT, editSubdivisionDocument),
    takeLatest(actionTypes.SAGA_SUBDIVISION_DOCUMENT_ADD, addSubdivisionDocument),
    takeLatest(actionTypes.SAGA_SUBDIVISION_DOCUMENT_DELETE_LIST, deleteSubdivisionDocumentList),
    takeLatest(actionTypes.SAGA_SUBDIVISION_LOAD_DOCUMENT_TYPES, loadSubdivisionDocumentTypes),
    takeLatest(actionTypes.SAGA_SUBDIVISION_LOAD_ORGANIZATIONS, loadSubdivisionOrganizations),
    takeLatest(actionTypes.SAGA_SUBDIVISION_LOAD_CLIENTS, loadSubdivisionClients),
    takeLatest(actionTypes.SAGA_SUBDIVISION_LOAD_CONTACTS, loadSubdivisionContacts),
    takeLatest(actionTypes.SAGA_SUBDIVISION_LOAD_JOB_TITLES, loadSubdivisionJobTitles),
  ]);
}

export default SubdivisionWatcher;
