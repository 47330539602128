import React from 'react';
import { Row, Col } from 'reactstrap'
import { MdError, MdCheckCircle } from 'react-icons/md'

/**
 * Displays a Toast message.
 *
 * @class
 * @param {object} props - React props.
 * @param {string} [props.type=undefined] - Type of toast. This will impact in the
 * colors and icons to be displayed. Value can be "error" or anything else.
 * @param {string} props.message - Title of the toast.
 * @param {string} props.info - Message to be displayed in a small text.
 * @returns a JSX element.
 */
const Toast = props => {

  const color = props.type === "error" ? "#eb1414" : "#1cbf2e";
  const IconComponent = props.type === "error" ? MdError : MdCheckCircle;

  return (
    <Row>
      <Col sm='auto' className='icons-toast'>
        <IconComponent color={color} size={40} />
      </Col>
      <Col className='body-toast'>
        <div className='title-toast'>{props.message}</div>
        <div className='info-toast'>{props.info}</div>
      </Col>
    </Row>
  );
}

export default Toast;
