import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {directionalLoadingFields} from 'common/objectStructures';

const initialState = {
  directionalLoadings: [],
  directionalLoading: {},
  formValues: null,
  pagination: {},
}

function reducerDirectionalLoading (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_DIRECTIONALLOADING_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(directionalLoadingFields)) {
          newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerDirectionalLoading);
