import * as actionTypes from '../actions/actionTypes'
import onLoginCleanupReducer from './generic/onLoginCleanupReducer'
import {accessTypeFields} from 'common/objectStructures'
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer'

const initialState = {
  accessTypes: [],
  accessType: {},
  formValues: null,
  pagination: {},
  loading: false,
  errors: "",

  organizations: createState()
}

function reducerAccessType (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_ACCESSTYPE_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(accessTypeFields)) {
          newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_ACCESS_TYPES_PROPS:
      return initialState;

    case actionTypes.CLEAR_ACCESS_TYPES_ERRORS:
      return {
        ...initialState,
        errors: ''
      }

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerAccessType),
  {
    organizations: [
      actionTypes.CHANGE_ACCESS_TYPES_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_ACCESS_TYPES_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_ACCESS_TYPES_ERROR_LOADING_ORGANIZATIONS
    ]
  }
);
