import * as actionTypes from '../actions/actionTypes'
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {componentTypeFields} from 'common/objectStructures'
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const initialState = {
  componentTypes: [],
  componentType: {},
  formValues: null,
  pagination: {},
  loading: false,
  errors: '',
  organizations: createState(),
  assemblyTypes: createState(),
  componentGroups: createState(),
  fields: createState()
}

function reducerComponentType (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_COMPONENTTYPE_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(componentTypeFields)) {
          newValues[key] = action.props.formValues[key] || value.defaultValue;
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_COMPONENT_TYPE_PROPS:
      return initialState;

    case actionTypes.CLEAR_COMPONENT_TYPE_ERRORS:
      return {
        ...state,
        errors: ''
      }

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerComponentType),
  {
    organizations: [
      actionTypes.CHANGE_COMPONENTTYPE_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_COMPONENTTYPE_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_COMPONENTTYPE_ERROR_LOADING_ORGANIZATIONS
    ],
    assemblyTypes: [
      actionTypes.CHANGE_COMPONENTTYPE_START_LOADING_ASSEMBLY_TYPES,
      actionTypes.CHANGE_COMPONENTTYPE_SUCCESS_LOADING_ASSEMBLY_TYPES,
      actionTypes.CHANGE_COMPONENTTYPE_ERROR_LOADING_ASSEMBLY_TYPES,
    ],
    componentGroups: [
      actionTypes.CHANGE_COMPONENTTYPE_START_LOADING_COMPONENT_GROUPS,
      actionTypes.CHANGE_COMPONENTTYPE_SUCCESS_LOADING_COMPONENT_GROUPS,
      actionTypes.CHANGE_COMPONENTTYPE_ERROR_LOADING_COMPONENT_GROUPS,
    ],
    fields: [
      actionTypes.CHANGE_COMPONENTTYPE_START_LOADING_FIELDS,
      actionTypes.CHANGE_COMPONENTTYPE_SUCCESS_LOADING_FIELDS,
      actionTypes.CHANGE_COMPONENTTYPE_ERROR_LOADING_FIELDS,
    ]
  }
);
