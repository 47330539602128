import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiUser from 'apiServices/ApiUser'

import { showRecordUpdatedToast, showRecordAddedToast, showRecordDeletedToast, showToastAsError } from '../actions/ToastActions';
import { clearUserErrors } from '../actions/UserActions';

const recordType = "user";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The user cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_USER_PROPS, props });
}

/*
 * get Users
 */
function* getUsers() {
  try {
    yield loading();
    // get all Users
    const users = yield call(ApiUser.getAll);
    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        users: users,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getUsersRoles() {
  try {
    yield loading();
    // get all Users
    const users = yield call(ApiUser.getAll);
    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        usersRoles: users,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchUsers(action) {
  try {
    yield loading();
    // get all Users
    const users = yield call(ApiUser.searchUsers, action.props);
    const pagination = {
      number: users.number,
      numberOfElements: users.numberOfElements,
      totalElements: users.totalElements,
      totalPages: users.totalPages,
      size: users.size
    };
    // update state for users
    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        users: users.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getUser(action) {
  try {
    yield loading();
    // get  User
    const user = yield call(ApiUser.getUser, action.props.userId);
    // update state for users
    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        user: user,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editUser(action) {

  yield put(clearUserErrors());

  try {
    yield loading();
    // update  User
    yield call(ApiUser.editUser, action.props);
    // update state for users
    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        user: {},
        loading: false,
        errors: ''
      }
    });

    //TODO check if this is necessary. If it is not, remove. If it is, comment why
    yield getUser({ props: { userId: action.props.id } });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield put(showRecordUpdatedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* addUser(action) {

  yield put(clearUserErrors());

  try {
    yield loading();
    // add  User
    const user = yield call(ApiUser.addUser, action.props);

    if (action.props.signature) {
      try {
        const signatureFormData = new FormData();
        signatureFormData.append("file", action.props.signature, action.props.signature.name);
        yield call(ApiUser.uploadUserSegnature, {userId: user.id, formData: signatureFormData});
      } catch(signatureError) {
        //TODO should we show a warning to the user.
        console.log("signature error", signatureError);
      }
    }

    // update state for users
    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        user: user,
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield put(showRecordAddedToast(recordType));

  } catch (error) {
    //yield loading(false, error);
    yield put(showToastAsError("Error creating user", "There was an error creating the user. Please, try again."));
  }

}

function* deleteUser(action) {

  yield put(clearUserErrors());

  try {
    yield loading();
    // delete  User
    yield call(ApiUser.deleteUser, action.props);

    // update state for users
    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
    yield put(showRecordDeletedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteUserList(action) {

  yield put(clearUserErrors());

  try {
    yield loading();
    yield call(ApiUser.deleteUserList, action.props.list);

    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield put(showRecordDeletedToast(recordType));
  } catch (error) {
    yield loading(false, error);
  }

}

function* getUsersForTrips() {
  try {
    yield loading();
    // get all Users
    const users = yield call(ApiUser.getUsersForTrips);
    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        users: users,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}


function* uploadUserSegnature(action) {
  try {
    yield loading();
    yield call(ApiUser.uploadUserSegnature, action.props.data);

    if (action.props.callback) {
      yield call(action.props.callback);
    }
  } catch (error) {
    yield loading(false, error);
  }

}


function* deleteUserSegnature(action) {
  try {
    yield loading();
    yield call(ApiUser.deleteUserSegnature, action.props.data);

    if (action.props.callback) {
      yield call(action.props.callback);
    }
  } catch (error) {
    yield loading(false, error);
  }

}


function* getUserParentOrganization() {
  try {
    yield loading();
    // get  User
    const users = yield call(ApiUser.getAllUserParentOrganization);
    // update state for users
    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        usersRoles: users,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getUserProfile() {

  try {
    yield loading();

    const user = yield call (ApiUser.getUserProfile);

    yield put({
      type: actionTypes.CHANGE_USER_PROPS,
      props: {
        user: user,
        loading: false,
        errors: ''
      }
    });

  } catch(err) {
    yield loading(false, err)
  }
}

function* getOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_USER_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiUser.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_USER_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_USER_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

function* getClients() {
  try {
    yield put({
      type: actionTypes.CHANGE_USER_START_LOADING_CLIENTS
    });

    const clients = yield call(ApiUser.getClients);
    yield put({
      type: actionTypes.CHANGE_USER_SUCCESS_LOADING_CLIENTS,
      props: {
        clients
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_USER_ERROR_LOADING_CLIENTS,
      error: e.message
    })
  }
}

function* findClients() {
  try {
    yield put({
      type: actionTypes.CHANGE_USER_START_LOADING_CLIENTS
    });

    const clients = yield call(ApiUser.findClients);

    yield put({
      type: actionTypes.CHANGE_USER_SUCCESS_LOADING_CLIENTS,
      props: {
        clients
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_USER_ERROR_LOADING_CLIENTS,
      error: e.message
    })
  }
}

function* getContacts() {
  try {
    yield put({
      type: actionTypes.CHANGE_USER_START_LOADING_CONTACTS
    });

    const contacts = yield call(ApiUser.getContacts);
    yield put({
      type: actionTypes.CHANGE_USER_SUCCESS_LOADING_CONTACTS,
      props: {
        contacts
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_USER_ERROR_LOADING_CONTACTS,
      error: e.message
    })
  }
}


function* loadJobTitles() {
  try {
    yield put({
      type: actionTypes.CHANGE_USER_START_LOADING_JOB_TITLES
    });

    const jobTitles = yield call(ApiUser.loadJobTitles);
    yield put({
      type: actionTypes.CHANGE_USER_SUCCESS_LOADING_JOB_TITLES,
      props: {
        jobTitles
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_USER_ERROR_LOADING_JOB_TITLES,
      error: e.message
    })
  }
}


function* getRoles() {
  try {
    yield put({
      type: actionTypes.CHANGE_USER_START_LOADING_ROLES
    });

    const roles = yield call(ApiUser.getRoles);
    yield put({
      type: actionTypes.CHANGE_USER_SUCCESS_LOADING_ROLES,
      props: {
        roles
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_USER_ERROR_LOADING_ROLES,
      error: e.message
    })
  }
}


function* addContact(action) {

  try {
    const contact = action.props.contact;
    const callback = action.props.callback;

    yield call(ApiUser.addContact, contact);
    yield put(showRecordAddedToast("contact"));

    if (callback) {
      yield call(callback);
    }

  } catch(error) {
    yield put(showToastAsError("Error creating contact", error.message));
  }
}

function* cleanAttempts() {

  try {
    yield call(ApiUser.cleanAttempts);

  } catch(error) {
    yield put(showToastAsError("Error cleaning failed attempts", error.message));
  }
}

function* remainingAttempts(action) {

  try {
    let attempts = yield call(ApiUser.remainingAttempts,action.props);
  } catch(error) {
    yield put(showToastAsError("Error trying to find remaining", error.message));
  }
}




/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_USER_ALL, getUsers),
    takeLatest(actionTypes.SAGA_USER_SEARCH, searchUsers),
    takeLatest(actionTypes.SAGA_USER_GET, getUser),
    takeLatest(actionTypes.SAGA_USER_GET_USERSROLES, getUsersRoles),
    takeLatest(actionTypes.SAGA_USER_EDIT, editUser),
    takeLatest(actionTypes.SAGA_USER_ADD, addUser),
    takeLatest(actionTypes.SAGA_USER_DELETE, deleteUser),
    takeLatest(actionTypes.SAGA_USER_DELETE_CHECKED, deleteUserList),
    takeLatest(actionTypes.SAGA_USER_FOR_TRIPS, getUsersForTrips),
    takeLatest(actionTypes.SAGA_USER_SIGNATURE_UPLOAD, uploadUserSegnature),
    takeLatest(actionTypes.SAGA_USER_SIGNATURE_UPLOAD_DELETE, deleteUserSegnature),
    takeLatest(actionTypes.SAGA_USER_GET_PARENT_ORGANIZATION, getUserParentOrganization),
    takeLatest(actionTypes.SAGA_USER_GET_PROFILE, getUserProfile),
    takeLatest(actionTypes.SAGA_USER_GET_ORGANIZATIONS, getOrganizations),
    takeLatest(actionTypes.SAGA_USER_GET_CLIENTS, getClients),
    takeLatest(actionTypes.SAGA_USER_FIND_CLIENTS, findClients),
    takeLatest(actionTypes.SAGA_USER_GET_CONTACTS, getContacts),
    takeLatest(actionTypes.SAGA_USER_GET_ROLES, getRoles),
    takeLatest(actionTypes.SAGA_USER_ADD_CONTACT, addContact),
    takeLatest(actionTypes.SAGA_USER_LOAD_JOB_TITLES, loadJobTitles),
    takeLatest(actionTypes.SAGA_USER_CLEAN_ATTEMPTS, cleanAttempts),
    takeLatest(actionTypes.SAGA_USER_REMAINING_ATTEMPTS, remainingAttempts)
  ]);
}

export default AuthWatcher;
