import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/document';

export default class ApiDocument {

  // GET 'api/Documents'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchDocuments(props) {
      return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getDocument(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editDocument(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data.document);
  }

  static addDocument(data){
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    return Utils.genericPost(endpoint, data, headers);
  }

  static deleteDocument(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteDocumentList(props) {
    return Utils.genericPost(`${endpoint}/delete`, props);
  }

}