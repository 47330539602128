import * as actionTypes from '../actions/actionTypes'
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {bridgeFields} from 'common/objectStructures'
import Utils from 'common/utils'

const initialState = {
  bridges: [],
  bridge: {},
  sections: [],
  sectionOptions: [],
  loading: false,
  errors: ''
}

function reducerBridge (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_BRIDGE_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(bridgeFields)) {
          newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
          if (key === 'location')
            newValues[key] =  Utils.getStringFromLocation(action.props.formValues[key]);
          if (value.type === 'select'){
              if (action.props.formValues[key] && action.props.formValues[key] !== ""){
                  newValues[key] = action.props.formValues[key].id;
              }
          }
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerBridge);
