import * as actionTypes from './actionTypes'

/**
 * @namespace AssetTypeActions
 */

export function getAssetTypes(){
  return { type: actionTypes.SAGA_ASSETTYPE_ALL };
}

export function loadAssetTypesPaged(props){
  return { type: actionTypes.SAGA_ASSETTYPE_SEARCH, props: props };
}

export function getAssetType(props){
  return { type: actionTypes.SAGA_ASSETTYPE_GET, props: props };
}

export function getAllByClient(props){
  return { type: actionTypes.SAGA_ASSETTYPE_ALL_BY_CLIENT, props };
}

export function getAllAssetsByOrganization(){
  return { type: actionTypes.SAGA_ASSETTYPE_ALL_BY_ORGANIZATION};
}


export function editAssetType(props){
  return { type: actionTypes.SAGA_ASSETTYPE_EDIT, props: props };
}

export function addAssetType(props){
  return { type: actionTypes.SAGA_ASSETTYPE_ADD, props: props };
}

export function deleteAssetType(props){
  return { type: actionTypes.SAGA_ASSETTYPE_DELETE, props: props };
}

export function deleteAssetTypeList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_ASSETTYPE_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

export function getAssetTypeByAsset(props){
  return { type: actionTypes.SAGA_ASSETTYPE_BY_ASSET, props: props };
}
/**
 * @memberof AssetTypeActions
 * @description Change AssetType props (no saga action)
 */
export function changeAssetTypeProps(props){
  return { type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: props };
}

/**
 * @memberof AssetTypeActions
 * @description Clears Asset Type props.
 */
export function clearAssetTypeProps() {
  return { type: actionTypes.CLEAR_ASSET_TYPES_PROPS };
}

/**
 * @memberof AssetTypeActions
 * @description Clears Asset Type errors message.
 */
export function clearAssetTypeErrors() {
  return { type: actionTypes.CLEAR_ASSET_TYPES_ERRORS };
}

/**
 * @memberof AssetTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations for asset types.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_ASSETTYPE_LOAD_ORGANIZATIONS };
}
