/*
 * Apps pages routes
 */
export const ROUTE_HOME = '/dashboard';
export const ROUTE_HOME_CLIENT = '/clientdashboard';
export const ROUTE_LOGOUT = '/logout';


//DELETE THIS!!!
export const ROUTE_TEST_NESTED = '/nestedtables';



// export const ROUTE_COMPLETE_PROFILE = '/completeProfile';
export const ROUTE_LOGIN = '/login';
export const ROUTE_CHANGE_PASS = '/changePassword/:confirmationToken';
export const ROUTE_FORGOT_PASS_EMAIL = '/forgotPassEmail';
export const ROUTE_CREATE_PASS = '/createPassword/:confirmationToken';
export const ROUTE_HOME_RAILROADS =  '/clientdashboard/railroads';
export const ROUTE_HOME_SUBDIVISIONS =  '/clientdashboard/subdivisions';
export const ROUTE_HOME_CONTACTS =  '/clientdashboard/contacts';
export const ROUTE_HOME_DOCUMENTS =  '/clientdashboard/documents';
export const ROUTE_HOME_RECOMMENDATION =  '/clientdashboard/recommendations';
export const ROUTE_HOME_REPORT =  '/clientdashboard/reports';
export const ROUTE_HOME_BRIDGES =  '/clientdashboard/bridges';

export const ROUTE_CLIENTS = '/clients';
export const ROUTE_CLIENT = ROUTE_CLIENTS + '/:clientId';
export const ROUTE_CLIENT_ADD = ROUTE_CLIENTS + '/add';
export const ROUTE_CLIENT_EDIT = ROUTE_CLIENT + '/edit';
export const ROUTE_CLIENT_REPORT = ROUTE_CLIENT + '/reports';
export const ROUTE_CLIENT_SUBDIVISIONS = ROUTE_CLIENT + '/subdivisions';
export const ROUTE_CLIENT_RECOMMENDATION = ROUTE_CLIENT + '/recommendations';
export const ROUTE_CLIENT_DOCUMENTS = ROUTE_CLIENT + '/documents';
export const ROUTE_CLIENT_BRIDGES = ROUTE_CLIENT + '/bridges';
export const ROUTE_CLIENT_SUBDIVISION_ADD = ROUTE_CLIENT_SUBDIVISIONS + '/add';

export const ROUTE_RAILROADS = ROUTE_CLIENT + '/railroads';
export const ROUTE_RAILROAD = ROUTE_RAILROADS + '/:railroadId';
export const ROUTE_RAILROAD_ADD = ROUTE_RAILROADS + '/add';
export const ROUTE_RAILROAD_EDIT = ROUTE_RAILROAD + '/edit';

export const ROUTE_SUBDIVISIONS = ROUTE_RAILROAD + '/subdivisions';
export const ROUTE_SUBDIVISION = ROUTE_SUBDIVISIONS + '/:subdivisionId';
export const ROUTE_SUBDIVISION_ADD = ROUTE_SUBDIVISIONS + '/add';
export const ROUTE_SUBDIVISION_EDIT = ROUTE_SUBDIVISION + '/edit';

export const ROUTE_BRIDGES = ROUTE_SUBDIVISION + '/bridges';
export const ROUTE_BRIDGE = ROUTE_BRIDGES + '/:bridgeId';

export const ROUTE_ASSETS = '/bridges' + ROUTE_SUBDIVISION + '/assets';
export const ROUTE_ASSET = ROUTE_ASSETS + '/:assetId';
export const ROUTE_ASSET_ADD = '/bridges/add';
export const ROUTE_ASSET_DOCUMENTS = ROUTE_ASSET + '/documents';
export const ROUTE_ASSET_PHOTO = ROUTE_ASSET + '/photos';
export const ROUTE_ASSET_EDIT = ROUTE_ASSET + '/edit';
export const ROUTE_ALL_ASSETS = '/bridges';
export const ROUTE_ASSET_SECTIONS = ROUTE_ASSET + '/sections';
export const ROUTE_BUILD_MULTI_ASSETS = '/assets/:subdivisionId/:nroBridge';

export const ROUTE_ASSETCHILDREN = ROUTE_ASSET + '/assetChildren/:parentId/:level';
export const ROUTE_ASSETCHILD = ROUTE_ASSETCHILDREN + '/:assetChildId';
export const ROUTE_ASSETCHILD_ADD = ROUTE_ASSETCHILDREN + '/add';
export const ROUTE_ASSETCHILD_EDIT = ROUTE_ASSETCHILD + '/edit';

export const ROUTE_EVENTS_DASHBOARD = '/inspections';
//export const ROUTE_EVENT_PHOTOGALLERY = ROUTE_EVENT + '/photoGallery';

export const ROUTE_ASSEMBLYS = ROUTE_ASSET + '/:parentId/assemblys';
export const ROUTE_ASSEMBLY = ROUTE_ASSEMBLYS + '/:assemblyId';
export const ROUTE_ASSEMBLY_ADD = ROUTE_ASSEMBLYS + '/add';
export const ROUTE_ASSEMBLY_EDIT = ROUTE_ASSEMBLY + '/edit';

export const ROUTE_COMPONENTS = ROUTE_ASSET + '/:parentId/components';
export const ROUTE_COMPONENT = ROUTE_COMPONENTS + '/:componentId';
export const ROUTE_COMPONENT_ADD = ROUTE_COMPONENTS + '/add';
export const ROUTE_COMPONENT_EDIT = ROUTE_COMPONENT + '/edit';

export const ROUTE_MESSAGES = '/messages';
export const ROUTE_MESSAGE = ROUTE_MESSAGES + '/:messageId';
export const ROUTE_MESSAGE_ADD = ROUTE_MESSAGES + '/add';
export const ROUTE_MESSAGE_EDIT = ROUTE_MESSAGE + '/edit';

export const ROUTE_USERS = '/users';
export const ROUTE_USER = ROUTE_USERS + '/:userId';
export const ROUTE_USER_ADD = ROUTE_USERS + '/add';
export const ROUTE_USER_EDIT = ROUTE_USER + '/edit';
export const ROUTE_USER_PROFILE = ROUTE_USERS + "/profile";

export const ROUTE_MESSAGEFIELDS = ROUTE_MESSAGE + '/messageFields';
export const ROUTE_MESSAGEFIELD = ROUTE_MESSAGEFIELDS + '/:messageFieldId';
export const ROUTE_MESSAGEFIELD_ADD = ROUTE_MESSAGEFIELDS + '/add';
export const ROUTE_MESSAGEFIELD_EDIT = ROUTE_MESSAGEFIELD + '/edit';

export const ROUTE_ASSEMBLYTYPES = '/assemblyTypes';
export const ROUTE_ASSEMBLYTYPE = ROUTE_ASSEMBLYTYPES + '/:assemblyTypeId';
export const ROUTE_ASSEMBLYTYPE_ADD = ROUTE_ASSEMBLYTYPES + '/add';
export const ROUTE_ASSEMBLYTYPE_EDIT = ROUTE_ASSEMBLYTYPE + '/edit';

export const ROUTE_COMPONENTTYPES = '/componentTypes';
export const ROUTE_COMPONENTTYPE = ROUTE_COMPONENTTYPES + '/:componentTypeId';
export const ROUTE_COMPONENTTYPE_ADD = ROUTE_COMPONENTTYPES + '/add';
export const ROUTE_COMPONENTTYPE_EDIT = ROUTE_COMPONENTTYPE + '/edit';

export const ROUTE_COMPONENTGROUPS = '/componentGroups';
export const ROUTE_COMPONENTGROUP = ROUTE_COMPONENTGROUPS + '/:componentGroupId';
export const ROUTE_COMPONENTGROUP_ADD = ROUTE_COMPONENTGROUPS + '/add';
export const ROUTE_COMPONENTGROUP_EDIT = ROUTE_COMPONENTGROUP + '/edit';

export const ROUTE_ROLES = '/roles';
export const ROUTE_ROLE = ROUTE_ROLES + '/:roleId';
export const ROUTE_ROLE_ADD = ROUTE_ROLES + '/add';
export const ROUTE_ROLE_EDIT = ROUTE_ROLE + '/edit';




export const ROUTE_CLAIMS = '/claims';
export const ROUTE_CLAIM = ROUTE_CLAIMS + '/:claimId';


export const ROUTE_CLAIM_EDIT = ROUTE_CLAIM + '/edit';

export const ROUTE_STRUCTURETYPES = '/structureTypes';
export const ROUTE_STRUCTURETYPE = ROUTE_STRUCTURETYPES + '/:structureTypeId';
export const ROUTE_STRUCTURETYPE_ADD = ROUTE_STRUCTURETYPES + '/add';
export const ROUTE_STRUCTURETYPE_EDIT = ROUTE_STRUCTURETYPE + '/edit';

export const ROUTE_IMPACTTYPES = '/impactTypes';
export const ROUTE_IMPACTTYPE = ROUTE_IMPACTTYPES + '/:impactTypeId';
export const ROUTE_IMPACTTYPE_ADD = ROUTE_IMPACTTYPES + '/add';
export const ROUTE_IMPACTTYPE_EDIT = ROUTE_IMPACTTYPE + '/edit';

export const ROUTE_ASSETTYPES = '/assetTypes';
export const ROUTE_ASSETTYPE = ROUTE_ASSETTYPES + '/:assetTypeId';
export const ROUTE_ASSETTYPE_ADD = ROUTE_ASSETTYPES + '/add';
export const ROUTE_ASSETTYPE_EDIT = ROUTE_ASSETTYPE + '/edit';

export const ROUTE_CROSSINGFEATURES = '/crossingFeatures';
export const ROUTE_CROSSINGFEATURE = ROUTE_CROSSINGFEATURES + '/:crossingFeatureId';
export const ROUTE_CROSSINGFEATURE_ADD = ROUTE_CROSSINGFEATURES + '/add';
export const ROUTE_CROSSINGFEATURE_EDIT = ROUTE_CROSSINGFEATURE + '/edit';

export const ROUTE_TRIPS = '/trips';
export const ROUTE_TRIP = ROUTE_TRIPS + '/:tripId';
export const ROUTE_TRIP_SCHEDULEDASSETS = ROUTE_TRIP + '/scheduledassets';

export const ROUTE_TRIP_ADD = ROUTE_TRIPS + '/add';
export const ROUTE_TRIP_BASE = ROUTE_TRIPS + '/assetForTrip/trip';
export const ROUTE_TRIP_ADDASSETS = ROUTE_TRIP_BASE + '/:tripId';
export const ROUTE_TRIP_EDIT = ROUTE_TRIP + '/edit';
export const ROUTE_TRIP_EDITASSETS = ROUTE_TRIP_EDIT + '/assetForTrip';

export const ROUTE_CONTACTS = '/contacts';
export const ROUTE_CONTACT = ROUTE_CONTACTS + '/:contactId';
export const ROUTE_CONTACT_ADD = ROUTE_CONTACTS + '/add';
export const ROUTE_CONTACT_EDIT = ROUTE_CONTACT + '/edit';
export const ROUTE_CONTACT_CLIENT = ROUTE_CLIENT + '/contacts';

export const ROUTE_JOBTITLES = '/jobTitles';
export const ROUTE_JOBTITLE = ROUTE_JOBTITLES + '/:jobTitleId';
export const ROUTE_JOBTITLE_ADD = ROUTE_JOBTITLES + '/add';
export const ROUTE_JOBTITLE_EDIT = ROUTE_JOBTITLE + '/edit';

export const ROUTE_ACCESSTYPES = '/accessTypes';
export const ROUTE_ACCESSTYPE = ROUTE_ACCESSTYPES + '/:accessTypeId';
export const ROUTE_ACCESSTYPE_ADD = ROUTE_ACCESSTYPES + '/add';
export const ROUTE_ACCESSTYPE_EDIT = ROUTE_ACCESSTYPE + '/edit';

export const ROUTE_ORGANIZATIONS = '/organizations';
export const ROUTE_ORGANIZATION = ROUTE_ORGANIZATIONS + '/:clientId';
export const ROUTE_ORGANIZATION_ADD = ROUTE_ORGANIZATIONS + '/add';
export const ROUTE_ORGANIZATION_EDIT = ROUTE_ORGANIZATION + '/edit'

export const ROUTE_CONDITIONS = '/conditions';
export const ROUTE_CONDITION = ROUTE_CONDITIONS + '/:conditionId';
export const ROUTE_CONDITION_ADD = ROUTE_CONDITIONS + '/add';
export const ROUTE_CONDITION_EDIT = ROUTE_CONDITION + '/edit';

export const ROUTE_LOCATIONS = '/locations';
export const ROUTE_LOCATION = ROUTE_LOCATIONS + '/:locationId';
export const ROUTE_LOCATION_ADD = ROUTE_LOCATIONS + '/add';
export const ROUTE_LOCATION_EDIT = ROUTE_LOCATION + '/edit';

export const ROUTE_PDF_REPORT_PAGE = '/report/assetEvent/:assetEventId/eventId/:eventId/asset/:assetId/eventMilestoneStatusId/:eventMilestoneStatusId/pdf'

export const ROUTE_DOCUMENTTYPES = '/documentTypes';
export const ROUTE_DOCUMENTTYPE = ROUTE_DOCUMENTTYPES + '/:documentTypeId';
export const ROUTE_DOCUMENTTYPE_ADD = ROUTE_DOCUMENTTYPES + '/add';
export const ROUTE_DOCUMENTTYPE_EDIT = ROUTE_DOCUMENTTYPE + '/edit';

//INSPECTIONS PAGES
export const ROUTE_INSPECTIONS_EVENTINSPECTION_PAGE = '/inspections/:assetEventId/eventInspection';
export const ROUTE_INSPECTIONS_APPROACH_PAGE = '/inspections/:assetEventId/approach';

//report pages
export const ROUTE_REPORT_MAPPING = '/reportMapping/:client/:railroad/:filename';

export const ROUTE_STORYBOOK = '/storybook';

/*
 * Api endpoint servers
 */
export const API_AUTH_SERVER = `${process.env.REACT_APP_AUTH_API_URL}`;
export const API_SERVER = `${process.env.REACT_APP_API_URL}`;

export const  DEFAULT_STRUCTURE_TYPES = [
    {name:'Approach', id:1},
    {name:'Deck', id:2},
    {name:'Superstructure', id:3},
    {name:'Substructure', id:4},
]

export const  MODALS_TYPES = [
    {type:'high', class:"bg-high text-white",icon:'fas fa-exclamation-triangle pr-3', storyBookIcon:'exclamation-triangle'},
    {type:'moderate', class:"bg-moderate",icon:'fas fa-exclamation-circle pr-3', storyBookIcon:'exclamation-circle'},
    {type:'low', class:"bg-low",icon:'fas fa-exclamation-circle pr-3', storyBookIcon:'exclamation-circle'},
    {type:'success', class:"bg-success",icon:'fas fa-check-circle pr-3', storyBookIcon:'check-circle'},
    {type:'information', class:"bg-information",icon:'fas fa-info-circle pr-3', storyBookIcon:'info-circle'},
]