import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiLocation from 'apiServices/ApiLocation'

import { clearLocationErrors, clearLocationProps } from '../actions/LocationActions';
import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast} from '../actions/ToastActions';

const recordTypeName = "location";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The Location cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_LOCATION_PROPS, props });
}

/*
 * get Locations
 */
function* getLocations() {
  // get first page Locations
  yield searchLocations({ props: { search: '', page: 0 } });
}

function* searchLocations(action) {
  try {
    yield loading();
    // get all Locations
    const locations = yield call(ApiLocation.searchLocations, action.props);
    const pagination = {
      number: locations.number,
      numberOfElements: locations.numberOfElements,
      totalElements: locations.totalElements,
      totalPages: locations.totalPages,
      size: locations.size
    };
    // update state for locations
    yield put({
      type: actionTypes.CHANGE_LOCATION_PROPS, props: {
        locations: locations.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getLocation(action) {
  try {
    yield loading();
    // get  Location
    const location = yield call(ApiLocation.getLocation, action.props.locationId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: location.location,
        id: location.id,
        type: "location"
      }
    });
    // update state for locations
    yield put({
      type: actionTypes.CHANGE_LOCATION_PROPS, props: {
        location: location,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }
}

function* editLocation(action) {

  yield put(clearLocationErrors());

  try {
    yield loading();
    // update  Location
    yield call(ApiLocation.editLocation, action.props);
    // update state for locations
    yield put(clearLocationProps());

    yield put(showRecordUpdatedToast(recordTypeName));
  } catch (error) {
    yield loading(false, error);
  }

}

function* addLocation(action) {

  yield put(clearLocationErrors());

  try {
    yield loading();
    // add  Location
    yield call(ApiLocation.addLocation, action.props);
    // update state for locations
    yield put(clearLocationProps());

    yield put(showRecordAddedToast(recordTypeName));
  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteLocation(action) {

  yield put(clearLocationErrors());

  try {
    yield loading();
    // delete  Location
    yield call(ApiLocation.deleteLocation, action.props);
    yield put(showRecordDeletedToast(recordTypeName));

    yield put(clearLocationProps());


    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteLocationList(action) {

  yield put(clearLocationErrors());

  try {
    yield loading();
    yield call(ApiLocation.deleteLocationList, action.props.list);
    yield put(showRecordDeletedToast(recordTypeName));

    yield put(clearLocationProps());


    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}


function* getLocationsByComponentType(action) {
  try {
    yield loading();
    const location = yield call(ApiLocation.getLocationsByComponentType, action.props.componentTypeId);

    yield put({
      type: actionTypes.CHANGE_LOCATION_PROPS, props: {
        locationsByType: location,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getLocationsByAssemblyType(action) {
  try {
    yield loading();
    const location = yield call(ApiLocation.getLocationsByAssemblyType, action.props.assemblyTypeId);

    yield put({
      type: actionTypes.CHANGE_LOCATION_PROPS, props: {
        locationsByType: location,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}



function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_LOCATION_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiLocation.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_LOCATION_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_LOCATION_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

function* loadAssemblyTypes() {
  try {
    yield put({
      type: actionTypes.CHANGE_LOCATION_START_LOADING_ASSEMBLY_TYPES
    });

    const assemblyTypes = yield call(ApiLocation.getAssemblyTypes);
    yield put({
      type: actionTypes.CHANGE_LOCATION_SUCCESS_LOADING_ASSEMBLY_TYPES,
      props: {
        assemblyTypes
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_LOCATION_ERROR_LOADING_ASSEMBLY_TYPES,
      error: e.message
    })
  }
}

function* loadComponentTypes() {
  try {
    yield put({
      type: actionTypes.CHANGE_LOCATION_START_LOADING_COMPONENT_TYPES
    });

    const componentTypes = yield call(ApiLocation.getComponentTypes);
    yield put({
      type: actionTypes.CHANGE_LOCATION_SUCCESS_LOADING_COMPONENT_TYPES,
      props: {
        componentTypes
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_LOCATION_ERROR_LOADING_COMPONENT_TYPES,
      error: e.message
    })
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_LOCATION_ALL, getLocations),
    takeLatest(actionTypes.SAGA_LOCATION_COMPONENTTYPE_GET, getLocationsByComponentType),
    takeLatest(actionTypes.SAGA_LOCATION_ASSEMBLYTYPE_GET, getLocationsByAssemblyType),
    takeLatest(actionTypes.SAGA_LOCATION_SEARCH, searchLocations),
    takeLatest(actionTypes.SAGA_LOCATION_GET, getLocation),
    takeLatest(actionTypes.SAGA_LOCATION_EDIT, editLocation),
    takeLatest(actionTypes.SAGA_LOCATION_ADD, addLocation),
    takeLatest(actionTypes.SAGA_LOCATION_DELETE, deleteLocation),
    takeLatest(actionTypes.SAGA_LOCATION_DELETE_CHECKED, deleteLocationList),
    takeLatest(actionTypes.SAGA_LOCATION_LOAD_ORGANIZATIONS, loadOrganizations),
    takeLatest(actionTypes.SAGA_LOCATION_LOAD_ASSEMBLY_TYPES, loadAssemblyTypes),
    takeLatest(actionTypes.SAGA_LOCATION_LOAD_COMPONENT_TYPES, loadComponentTypes),
  ]);
}

export default AuthWatcher;
