import * as actionTypes from './actionTypes'

/**
 * @namespace JobTitleActions
 */


/**
 * @memberof JobTitleActions
 * @description Creates a Redux action that can be dispatched to
 * fetch all JobTitles.
 *
 * This will dispatch a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getJobTitles(){
  return { type: actionTypes.SAGA_JOBTITLE_ALL };
}

/**
 * @memberof JobTitleActions
 * @deprecated Not using pagination anymore
 * @description Creates a Redux action that can be dispatched to
 * load Job Titles paged.
 *
 * This will dispatch a Saga action.
 *
 * @param {object} props - pagination options.
 * @returns A Redux action that can be dispatched.
 */
export function loadJobTitlePaged(props){
  return { type: actionTypes.SAGA_JOBTITLE_SEARCH, props: props };
}

/**
 * @memberof JobTitleActions
 * @description Creates a Redux action that can be dispatched to
 * fetch a single JobTitle.
 *
 * This will trigger a Saga action.
 *
 * @param {object} props
 * @param {string} props.jobTitleId - ID of the Job Title to fetch.
 * @returns A Redux action that can be dispatched.
 */
export function getJobTitle(props){
  return { type: actionTypes.SAGA_JOBTITLE_GET, props: props };
}

/**
 * @memberof JobTitleActions
 * @description Creates a Redux action that can be dispatched to
 * edit a JobTitle.
 *
 * This will trigger a Saga action.
 *
 * @param {object} props - JobTitle data.
 * @param {string} props.id - ID of the JobTitle to edit.
 * @returns A Redux action that can be dispatched.
 */
export function editJobTitle(props){
  return { type: actionTypes.SAGA_JOBTITLE_EDIT, props: props };
}

/**
 * @memberof JobTitleActions
 * @description Creates a Redux action that can be dispatched
 * to add/create a JobTitle.
 *
 * This will trigger a Saga action.
 *
 * @param {object} props - JobTitle data to save.
 * @returns A Redux action that can be dispatched.
 */
export function addJobTitle(props){
  return { type: actionTypes.SAGA_JOBTITLE_ADD, props: props };
}

/**
 * @memberof JobTitleActions
 * @description Creates a Redux action that can be dispatched
 * to delete a single JobTitle.
 *
 * This will trigger a Saga action.
 *
 * @param {string} props - the ID of the JobTitle to delete.
 * @returns A Redux action that can be dispatched.
 */
export function deleteJobTitle(props){
  return { type: actionTypes.SAGA_JOBTITLE_DELETE, props: props };
}

/**
 * @memberof JobTitleActions
 * @description Creates a Redux action that can be dispatched
 * to delete a list of Job Titles.
 *
 * This will trigger a Saga action.
 *
 * @param {string[]} list - IDs of JobTitles to delete.
 * @param {object} options - additional options for the execution.
 * @param {function} [options.callback=()=>{}] - a callback function
 * to trigger when JobTitles have been deleted successfully.
 *
 * @returns A Redux action that can be dispatched.
 */
export function deleteJobTitleList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_JOBTITLE_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

/**
 * @memberof JobTitleActions
 * @deprecated use more granular actions.
 * @description Change JobTitle props.
 *
 * This will not trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function changeJobTitleProps(props){
  return { type: actionTypes.CHANGE_JOBTITLE_PROPS, props: props };
}

/**
 * @memberof JobTitleActions
 * @description Clears job title props.
 *
 * This will not trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function clearJobTitleProps() {
  return { type: actionTypes.CLEAR_JOB_TITLE_PROPS };
}

/**
 * @memberof JobTitleActions
 * @description Clears job title current errors messages.
 *
 * This will not trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function clearJobTitleErrors() {
  return { type: actionTypes.CLEAR_JOB_TITLE_ERRORS };
}

/**
 * @memberof JobTitleActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations for Job Titles.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be disptached.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_JOBTITLE_LOAD_ORGANIZATIONS };
}
