import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Navbar>
      <Nav navbar>
        <NavItem className="footer-label">
          {currentYear} &copy; ARE corp.
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
