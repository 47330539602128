import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'store/actions/actionTypes';
// APIs to talk to...
// import ApiDocument from 'tests/mocks/ApiDocument.mock'
import ApiDocument from 'apiServices/ApiDocument';

import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast } from '../actions/ToastActions';
const recordType = "document";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The document cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_DOCUMENT_PROPS, props });
}

/*
 * get documents
 */
function* getDocuments() {
  try {
    yield loading();
    // get all documents
    const documents = yield call(ApiDocument.getAll);
    yield put({
      type: actionTypes.CHANGE_DOCUMENT_PROPS, props: {
        documents: documents,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchDocuments(action) {
  try {
    yield loading();
    // get all documents
    const documents = yield call(ApiDocument.searchDocuments, action.props);
    const pagination = {
      number: documents.number,
      numberOfElements: documents.numberOfElements,
      totalElements: documents.totalElements,
      totalPages: documents.totalPages,
      size: documents.size
    };
    // update state for documents
    yield put({
      type: actionTypes.CHANGE_DOCUMENT_PROPS, props: {
        documents: documents.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getDocument(action) {
  try {
    yield loading();
    // get  Document
    const document = yield call(ApiDocument.getDocument, action.props.documentId);
    // update state for documents
    yield put({
      type: actionTypes.CHANGE_DOCUMENT_PROPS, props: {
        document: document,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editDocument(action) {
  try {
    yield loading();
    // update  Documen
    yield call(ApiDocument.editDocument, action.props.props);

    //yield getDocument({ props: { documentId: action.props.id } });
    if (action.props.callback)
      yield call(action.props.callback)

    // update state for documents
    yield put({
      type: actionTypes.CHANGE_DOCUMENT_PROPS, props: {
        document: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordUpdatedToast(recordType));
  } catch (error) {
    yield loading(false, error);
  }

}

function* addDocument(action) {
  try {
    yield loading();
    // add  Document
    yield call(ApiDocument.addDocument, action.props.fd);
    // update state for documents
    yield put({
      type: actionTypes.CHANGE_DOCUMENT_PROPS, props: {
        document: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordAddedToast(recordType));

    if (action.props.callback)
      yield call(action.props.callback)

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteDocument(action) {
  try {
    yield loading();
    // delete  Document
    yield call(ApiDocument.deleteDocument, action.props);
    // update state for documents
    yield put({
      type: actionTypes.CHANGE_DOCUMENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordType));
  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteDocumentList(action) {
  try {
    yield loading();
    // delete  Document
    yield call(ApiDocument.deleteDocumentList, action.props.props);
    // update state for documents
    yield put({
      type: actionTypes.CHANGE_DOCUMENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordType));

    if (action.props.callback)
      yield call(action.props.callback)

  } catch (error) {
    yield loading(false, error);
  }
}
/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_DOCUMENT_ALL, getDocuments),
    takeLatest(actionTypes.SAGA_DOCUMENT_SEARCH, searchDocuments),
    takeLatest(actionTypes.SAGA_DOCUMENT_GET, getDocument),
    takeLatest(actionTypes.SAGA_DOCUMENT_EDIT, editDocument),
    takeLatest(actionTypes.SAGA_DOCUMENT_ADD, addDocument),
    takeLatest(actionTypes.SAGA_DOCUMENT_DELETE, deleteDocument),
    takeLatest(actionTypes.SAGA_DOCUMENT_DELETE_LIST, deleteDocumentList),
  ]);
}

export default AuthWatcher;
