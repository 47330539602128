/**
 * @namespace Claims
 */

/*********************************
 *
 * ASSETS
 *
 ********************************/

/**
 * @description Can list "Access Types"
 * @memberof Claims
 */
export const ACCESS_TYPES_LIST = "ats:V";

/**
 * @description Can view the detail of an "Access Types"
 * @memberof Claims
 */
export const ACCESS_TYPES_VIEW = "at:V";

/**
 * @description Can add "Access Types"
 * @memberof Claims
 */
export const ACCESS_TYPES_ADD = "at:A";

/**
 * @description Can delete "Access Types"
 * @memberof Claims
 */
export const ACCESS_TYPES_DELETE = "at:D";



/**
 * @description Can list "Assembly Types"
 * @memberof Claims
 */
export const ASSEMBLY_TYPES_LIST = "asts:V";

/**
 * @description Can view the detail of an "Assembly Types"
 * @memberof Claims
 */
export const ASSEMBLY_TYPES_VIEW = "ast:V";

/**
 * @description Can add "Assembly Types"
 * @memberof Claims
 */
export const ASSEMBLY_TYPES_ADD = "ast:A";

/**
 * @description Can delete "Assembly Types"
 * @memberof Claims
 */
export const ASSEMBLY_TYPES_DELETE = "ast:D";


/**
 * @description Can list "Asset Types"
 * @memberof Claims
 */
export const ASSET_TYPES_LIST = "assts:V";

/**
 * @description Can view the detail of an "Asset Types"
 * @memberof Claims
 */
export const ASSET_TYPES_VIEW = "asst:V";

/**
 * @description Can add "Asset Types"
 * @memberof Claims
 */
export const ASSET_TYPES_ADD = "asst:A";

/**
 * @description Can delete "Asset Types"
 * @memberof Claims
 */
export const ASSET_TYPES_DELETE = "asst:D";



/**
 * @description Can list "Component Groups"
 * @memberof Claims
 */
export const COMPONENT_GROUPS_LIST = "cgs:V";

/**
 * @description Can view the detail of "Component Groups"
 * @memberof Claims
 */
export const COMPONENT_GROUPS_VIEW = "cg:V";

/**
 * @description Can add "Component Groups"
 * @memberof Claims
 */
export const COMPONENT_GROUPS_ADD = "cg:A";

/**
 * @description Can delete "Component Groups"
 * @memberof Claims
 */
export const COMPONENT_GROUPS_DELETE = "cg:D";


/**
 * @description Can list "Component Types"
 * @memberof Claims
 */
export const COMPONENT_TYPES_LIST = "cts:V";

/**
 * @description Can view the detail of a "Component Types"
 * @memberof Claims
 */
export const COMPONENT_TYPES_VIEW = "ct:V";

/**
 * @description Can add "Component Types"
 * @memberof Claims
 */
export const COMPONENT_TYPES_ADD = "ct:A";

/**
 * @description Can delete "Component Types"
 * @memberof Claims
 */
export const COMPONENT_TYPES_DELETE = "ct:D";



/**
 * @description Can list "Conditions"
 * @memberof Claims
 */
export const CONDITIONS_LIST = "cos:V";

/**
 * @description Can add "Conditions"
 * @memberof Claims
 */
export const CONDITIONS_ADD = "co:A";

/**
 * @description Can delete "Conditions"
 * @memberof Claims
 */
export const CONDITIONS_DELETE = "co:D";



/**
 * @description can list "Crossing Features"
 * @memberof Claims
 */
export const CROSSING_FEATURES_LIST = "cfs:V";

/**
 * @description Can view the detail of a "Crossing Features"
 * @memberof Claims
 */
export const CROSSING_FEATURES_VIEW = "cf:V";

/**
 * @description Can add "Crossing Features"
 * @memberof Claims
 */
export const CROSSING_FEATURES_ADD = "cf:A";

/**
 * @description Can delete "Crossing Features"
 * @memberof Claims
 */
export const CROSSING_FEATURES_DELETE = "cf:D";


/**
 * @description Can list "Impact Types"
 * @memberof Claims
 */
export const IMPACT_TYPES_LIST = "imptypes:V";

/**
 * @description Can add "Impact Types"
 * @memberof Claims
 */
export const IMPACT_TYPES_ADD = "imptype:A";

/**
 * @description Can delete "Impact Types"
 * @memberof Claims
 */
export const IMPACT_TYPES_DELETE = "imptype:D";



/**
 * @description Can list "Locations"
 * @memberof Claims
 */
export const LOCATIONS_LIST = "ls:V";

/**
 * @description Can add "Locations"
 * @memberof Claims
 */
export const LOCATIONS_ADD = "l:A";

/**
 * @description Can delete "Locations"
 * @memberof Claims
 */
export const LOCATIONS_DELETE = "l:D";

/**
 * @description Can list "Structure Types"
 * @memberof Claims
 */
export const STRUCTURE_TYPES_LIST = "sts:V";

/**
 * @description Can view the detail of "Structure Types"
 * @memberof Claims
 */
export const STRUCTURE_TYPES_VIEW = "st:V";

/**
 * @description Can add "Structure Types"
 * @memberof Claims
 */
export const STRUCTURE_TYPES_ADD = "st:A";

/**
 * @description Can delete "Structure Types"
 * @memberof Claims
 */
export const STRUCTURE_TYPES_DELETE = "st:D";





/*************************************
 *
 * TRIPS
 *
 ************************************/

/**
 * @description Can list "Trips"
 * @memberof Claims
 */
export const TRIP_LIST = "t:V";

/**
 * @description Can add/edit trips
 * @memberof Claims
 */
export const TRIP_ADD = "t:A";

/**
 * @description Can delete trips
 * @memberof Claims
 */
export const TRIP_DELETE = "t:D";

/**
 * @description Can set/mark a "Trip" as completed.
 * @memberof Claims
 */
export const TRIP_SET_COMPLETED = "t:Completed";

/*************************************
 *
 * SETTINGS
 *
 ************************************/

/**
 * @description settings
 * @memberof Claims
 */
export const SETTINGS = "s:v";

/*************************************
 *
 * USERS
 *
 ************************************/

/**
 * @description Can list "Users"
 * @memberof Claims
 */
export const USERS_LIST = "us:V";

/**
 * @description Can view the detail of "Users"
 * @memberof Claims
 */
export const USER_VIEW = "u:V";

/**
 * @description Can add/edit "Users"
 * @memberof Claims
 */
export const USER_ADD = "u:A";

/**
 * @description Can delete "Users"
 * @memberof Claims
 */
export const USER_DELETE = "u:D";

/**
 * @description Can See All Users (Active, Inactive)
 * @memberof Claims
 */
 export const USERS_VISIT_ACTIVE = "us:VA";


 /*************************************
 *
 * DOCUMENT TYPES
 *
 ************************************/

 /**
 * @description Can list "Document Types"
 * @memberof Claims
 */
export const DOCUMENT_TYPES_LIST = "dts:V";

/**
 * @description Can view the detail of "Document Types"
 * @memberof Claims
 */
export const DOCUMENT_TYPES_VIEW = "dt:V";

/**
 * @description Can add "Document Types"
 * @memberof Claims
 */
export const DOCUMENT_TYPES_ADD = "dt:A";

/**
 * @description Can delete "Document Types"
 * @memberof Claims
 */
export const DOCUMENT_TYPES_DELETE = "dt:D";


 /*************************************
 *
 * ROLES
 *
 ************************************/

/**
 * @description Can list "Roles"
 * @memberof Claims
 */
export const ROLES_LIST = "rs:V";

/**
 * @description Can view the detail of "Roles"
 * @memberof Claims
 */
export const ROLE_VIEW = "r:V";

/**
 * @description Can add/edit "Roles"
 * @memberof Claims
 */
export const ROLE_ADD = "r:A";

/**
 * @description Can delete "Roles"
 * @memberof Claims
 */
export const ROLE_DELETE = "r:D";


/*************************************
 *
 * MESSAGES
 *
 ************************************/

/**
 * @description Can list "Messages"
 * @memberof Claims
 */
export const MESSAGES_LIST = "ms:V";

/**
 * @description Can view the detail of "Messages"
 * @memberof Claims
 */
export const MESSAGE_VIEW = "m:V";

/**
 * @description Can add/edit "Messages"
 * @memberof Claims
 */
export const MESSAGE_ADD = "m:A";

/**
 * @description Can delete "Messages"
 * @memberof Claims
 */
export const MESSAGE_DELETE = "m:D";


/*************************************
 *
 * CLAIMS
 *
 ************************************/

/**
 * @description Can list "Claims"
 * @memberof Claims
 */
export const CLAIMS_LIST = "cls:V";

/**
 * @description Can view the detail of "Claims"
 * @memberof Claims
 */
export const CLAIM_VIEW = "cl:V";


/**
 * @description Can add/edit "Claims"
 * @memberof Claims
 */
export const CLAIM_EDIT = "cl:E";


/*************************************
 *
 * CONTACTS
 *
 ************************************/

/**
 * @description Can list "Contacts"
 * @memberof Claims
 */
export const CONTACTS_LIST = "cots:V";

/**
 * @description Can add a "Contact"
 * @memberof Claims
 */
export const CONTACTS_ADD = "cot:A";

/**
 * @description Can delete a "Contact"
 * @memberof Claims
 */
export const CONTACTS_DELETE = "cot:D";


/*************************************
 *
 * JOB TITLES
 *
 ************************************/

/**
 * @description Can list "Job Titles"
 * @memberof Claims
 */
export const JOB_TITLES_LIST = "jts:V";

/**
 * @description Can view the detail of "Job Titles"
 * @memberof Claims
 */
export const JOB_TITLE_VIEW = "jt:V";

/**
 * @description Can add "Job Titles"
 * @memberof Claims
*/
export const JOB_TITLE_ADD = "jt:A";

/**
 * @description Can delete "Job Titles"
 * @memberof Claims
 */
export const JOB_TITLE_DELETE = "jt:D";


/*************************************
 *
 * CLIENT
 *
 ************************************/


/**
 * @description Can list "Clients". This may be refered as "CLIENTS" claim.
 * @memberof Claims
 */
export const CLIENTS_LIST = "cs:V";

/**
 * @description Can view the detail of a client
 * @memberof Claims
 */
export const CLIENT_VISIT = "c:V";

/**
 * @description Can add "Clients"
 * @memberof Claims
 */
export const CLIENT_ADD = "c:A";

/**
 * @description Can delete a "Client"
 * @memberof Claims
 */
export const CLIENT_DELETE = "c:D";

/**
 * @description Can view "Client" "Bridges"
 * @memberof Claims
 */
export const CLIENT_BRIDGES = "cb:V";

/**
 * @description Client Dashboard
 * @memberof Claims
 */
export const CLIENT_DASHBOARD = "cd:V";

/**
 * @description Can add a contact to a client.
 * @memberof Claims
 */
export const CLIENT_CONTACTS_ADD = "clicontact:A";

/**
 * @description Can list client contacts.
 * @memberof Claims
 */
export const CLIENT_CONTACTS_LIST = "clicontacts:V";

/**
 * @description Can delete a contact from a client.
 * @memberof Claims
 */
export const CLIENT_CONTACTS_DELETE = "clicontact:D";

/**
 * @description Can list client documents.
 * @memberof Claims
 */
export const CLIENT_DOCUMENTS_LIST = "clidocs:V";

/**
 * @description Can add client documents.
 * @memberof Claims
 */
export const CLIENT_DOCUMENTS_ADD = "clidocs:A";

/**
 * @description Can delete client documents.
 * @memberof Claims
 */
export const CLIENT_DOCUMENTS_DELETE = "clidocs:D";




/**
 * @description Admin dashboard
 * @memberof Claims
 */
export const ADMIN_DASHBOARD = "d:V";



/*************************************
 *
 * RAILROADS
 *
 ************************************/


/**
 * @description Can list contacts at the railroad level.
 * @memberof claims
 */
export const RAILROAD_CONTACTS_LIST = "railcontacts:V";

/**
 * @description Can add contacts at the railroad level.
 * @memberof claims
 */
export const RAILROAD_CONTACT_ADD = "railcontact:A";

/**
 * Can delete contacts at the railroad level.
 * @memberof claims
 */
export const RAILROAD_CONTACT_DELETE = "railcontact:D";


/**
 * @description Can list documents at the railroad level.
 * @memberof claims
 */
export const RAILROAD_DOCUMENT_LIST = "raildocuments:V";

/**
 * @description Can add documents at the railroad level.
 * @memberof Claims
 */
export const RAILROAD_DOCUMENT_ADD = "raildocument:A";

/**
 * @description Can delete documents at the railroad level.
 * @memberof Claims
 */
export const RAILROAD_DOCUMENT_DELETE = "raildocument:D";

/*************************************
 *
 * SUBDIVISIONS
 *
 ************************************/

/**
 * @description Can list contacts at the subdivision level.
 * @memberof Claims
 */
export const SUBDIVISION_CONTACTS_LIST = "subcontacts:V";

/**
 * @description Can add contacts at the subdivision level.
 * @memberof Claims
 */
export const SUBDIVISION_CONTACT_ADD = "subcontact:A";

/**
 * @description Can delete contacts at the subdivision level.
 * @memberof Claims
 */
export const SUBDIVISION_CONTACT_DELETE = "subcontact:D";

/**
 * @description Can list documents at the subdivision level.
 * @memberof Claims
 */
export const SUBDIVISION_DOCUMENTS_LIST = "subdocuments:V";

/**
 * @description Can add documents at the subdivision level.
 * @memberof Claims
 */
export const SUBDIVISION_DOCUMENT_ADD = "subdocument:A";

/**
 * @description Can delete documents at the subdivision level.
 * @memberof Claims
 */
export const SUBDIVISION_DOCUMENT_DELETE = "subdocument:D";



/*************************************
 *
 * INSPECTIONS
 *
 ************************************/

/**
 * @description Can bulk update inspection "statuses"
 * @memberof Claims
 */
 export const BULK_UPDATE_STATUS = "bupdatestatus:BUS";

/**
 * @description Can Edit Inspection and update status if current status is FIELD INSPECTION
 * @memberof Claims
 */
export const INSPECTION_UPDATE_STATUS_FIELD_INSPECTION = "ins:UIMLFI";

/**
 * @description Can Edit Inspection and update status if current status is FINAL INSPECTION REPORT
 * @memberof Claims
 */
export const INSPECTION_UPDATE_STATUS_FINAL_REPORT = "ins:UIMLFIR";

/**
 * @description Can Edit Inspection and update status if current status is INITIAL REPORT
 * @memberof Claims
 */
export const INSPECTION_UPDATE_STATUS_INITIAL_REPORT = "ins:UIMLIR";

/**
 * @description Can Edit Inspection and update status if current status is REDAY FOR REPORTS
 * @memberof Claims
 */
export const INSPECTION_UPDATE_STATUS_CERTIFY_AND_SUBMIT = "ins:UIMLCAS";

/**
 * @description Can Edit Inspection and update status if current status is PRE-INSPECTION
 * @memberof Claims
 */
export const INSPECTION_UPDATE_STATUS_PREINSPECTION = "ins:UIMLPINS";

/**
 * @description Can add an "Inspection"
 * @memberof Claims
 */
 export const INSPECTION_ADD = "ins:A";

 /**
  * @description Can delete an "Inspection"
  * @memberof Claims
  */
 export const INSPECTION_DELETE = "ins:D";
 
 /**
  * @description Can list "Inspection"
  * @memberof Claims
  */
 export const INSPECTION_LIST = "ins:V";
 
 /**
  * @description Can edit an "inspection"
  * @memberof Claims
  */
 export const INSPECTION_EDIT = "ins:E";
 
 /**
  * @description Can edit an "Photos"
  * @memberof Claims
  */
 export const INSPECTION_ADD_PHOTO = "insphoto:A";
 
 /**
  * @description Can download an Inspection photo.
  * @memberof Claims
  */
 export const INSPECTION_DOWNLOAD_PHOTO = "insphoto:download";
 
 /**
  * @description Can download any "Import" template file of inspections.
  * @memberof Claims
  */
 export const INSPECTION_DOWNLOAD_IMPORT_TEMPLATE_FILE = "ins:DIT";
 
 /**
  * @description Can visit an "Photos"
  * @memberof Claims
  */
 export const INSPECTION_VISIT_PHOTO = "insphoto:V";
 
 /**
  * @description Can visit an "edit"
  * @memberof Claims
  */
  export const INSPECTION_EDIT_PHOTO = "insphoto:E";
 
  /**
  * @description Can visit an "Delete"
  * @memberof Claims
  */
   export const INSPECTION_DELETE_PHOTO = "insphoto:D";

 /**
  * @description Can download an "Reports" when is completed
  * @memberof Claims
  */
 export const DOWNLOAD_REPORTS_WHEN_INSPECTION_COMPLETED = "ins:DRWC";
 
 /**
  * @description Can download an "Reports" when is completed or not, created for admins
  * @memberof Claims
  */
  export const DOWNLOAD_REPORTS_WHEN_INSPECTION_IS_NO_COMPLETED = "ins:DRWNC";
 
 
 /**
  * @description Can edit numb of spans in TBS prototype
  * @memberof Claims
  */
  export const EDIT_NUMBER_OF_STRINGERS = "ins:ENOF";
 

 /*************************************
 *
 * BRIDGES 
 * TODO     more claims are needed to ensure permisions on the bridge detail
 *
 ************************************/

 /**
 * @description Can list "Bridges"
 * @memberof Claims
 */
export const BRIDGES = "b:V";


