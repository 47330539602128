import { all, call, put, takeLatest,select, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiEventType from 'tests/mocks/ApiEventType.mock'
import ApiEventType from 'apiServices/ApiEventType'
import CookieManager from 'common/cookieManager'


/*
 * get EventTypes
 */
function* getEventTypes(){

  let loadedEventType = yield select(store=>store.eventType.eventTypes)

  if(loadedEventType.length === 0){
    // get event types
    const allEvents = yield call(ApiEventType.getAll);
    // update state for events
    yield put({ type: actionTypes.CHANGE_EVENTTYPE_PROPS, props:{
      eventTypes: allEvents,
    }});
  }
}

function* searchEventTypes(action){
  // get all EventTypes
  const eventTypes = yield call(ApiEventType.searchEventTypes, action.props);
  const pagination = { number: eventTypes.number,
	                     numberOfElements: eventTypes.numberOfElements,
	                     totalElements: eventTypes.totalElements,
	                     totalPages: eventTypes.totalPages,
	                     size: eventTypes.size};
  // update state for eventTypes
  yield put({ type: actionTypes.CHANGE_EVENTTYPE_PROPS, props:{
    eventTypes: eventTypes.content,
    pagination: pagination,
  }});
}

function* getEventType(action){
  // get  EventType
  const eventType = yield call(ApiEventType.getEventType, action.props.eventTypeId);
  yield putResolve({type: actionTypes.CHANGE_NAMES_PROPS, props:{
    name: eventType.name,
    id: eventType.id,
    type: "eventType"
  }});
  // update state for eventTypes
  yield put({ type: actionTypes.CHANGE_EVENTTYPE_PROPS, props:{
    eventType: eventType,
  }});
}

function* editEventType(action){
  // update  EventType
  yield call(ApiEventType.editEventType, action.props);
  // update state for eventTypes
  yield put({ type: actionTypes.CHANGE_EVENTTYPE_PROPS, props:{
    eventType: {},
  }});
  const cookieStored = CookieManager.getCookiesForTable("EventTypes")
  yield searchEventTypes(CookieManager.getObjectForSearch(cookieStored));
}

function* addEventType(action){
  // add  EventType
  yield call(ApiEventType.addEventType, action.props);
  // update state for eventTypes
  yield put({ type: actionTypes.CHANGE_EVENTTYPE_PROPS, props:{
    eventType: {},
  }});
  const cookieStored = CookieManager.getCookiesForTable("EventTypes")
  yield searchEventTypes(CookieManager.getObjectForSearch(cookieStored));
}

function* deleteEventType(action){
  // delete  EventType
  yield call(ApiEventType.deleteEventType, action.props);
  const cookieStored = CookieManager.getCookiesForTable("EventTypes")
  yield searchEventTypes(CookieManager.getObjectForSearch(cookieStored));
}
/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_EVENTTYPE_ALL, getEventTypes),
    takeLatest(actionTypes.SAGA_EVENTTYPE_SEARCH, searchEventTypes),
    takeLatest(actionTypes.SAGA_EVENTTYPE_GET, getEventType),
    takeLatest(actionTypes.SAGA_EVENTTYPE_EDIT, editEventType),
    takeLatest(actionTypes.SAGA_EVENTTYPE_ADD, addEventType),
    takeLatest(actionTypes.SAGA_EVENTTYPE_DELETE, deleteEventType),
  ]);
}

export default AuthWatcher;
