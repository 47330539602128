import { has } from 'lodash';
import axios from 'axios';

/*
 * Generic request
 */

// default config settings
let config = {
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
		credentials: 'same-origin'
	},
	timeout: 0
}

export default class Api {
	constructor(props = {}) {
		// vars
		const { timeout, debugLevel=0 } = props;
		this.timeout = timeout? timeout : config.timeout;
		this.debugLevel = debugLevel;
		this.log = {};
	}

	/*
	 * Setter/getter for default headers/timeout
	 */

	static set defaultHeaders(value) {
		config.defaultHeaders = value;
	}
	static get defaultHeaders() {
		return config.defaultHeaders;
	}
	static set defaultTimeout(value) {
		config.timeout = value;
	}
	static get defaultTimeout() {
		return config.timeout;
	}

	/*
	 * All supported methods below
	 */

	head(url, args = {}) {
		args.method = "HEAD";
		return this.request(url, args);
	}

	get(url, args = {}) {
		args.method = "GET";
		return this.request(url, args);
	}

	post(url, args = {}) {
		args.method = "POST";
		return this.request(url, args);
	}

	put(url, args = {}) {
		args.method = "PUT";
		return this.request(url, args);
	}

	patch(url, args = {}) {
		args.method = "PATCH";
		return this.request(url, args);
	}

	delete(url, args = {}) {
		args.method = "DELETE";
		return this.request(url, args);
	}

	preview(url, args = {}) {
		args.method = "GET";
		args.responseType = 'arraybuffer';
		return this.customRequest(url, args)
		.then(response => {
			var blob = new Blob([response.data], {type: 'application/pdf'});			
			let contentDisposition = response.headers['content-disposition'].split("=")[1];;			
			contentDisposition = decodeURIComponent((contentDisposition + '').replace(/\+/g, '%20'));
			if (typeof window.navigator.msSaveBlob === 'function') {
				window.navigator.msSaveBlob(blob, contentDisposition);
			} else {
				var link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = contentDisposition;
				document.body.appendChild(link);
				link.click();
			}
		}).catch(error => {
			throw error
		});
	  }

  previewByType(url, args = {}, type) {
    args.method = "GET";
    args.responseType = 'arraybuffer';
	return this.customRequest(url, args)
	.then(response => {
		let contentDisposition = response.headers['content-disposition'].split("=")[1];;			
		contentDisposition = decodeURIComponent((contentDisposition + '').replace(/\+/g, '%20'));
		var blob = new Blob([response.data], {type: `application/${type}`});
		if (typeof window.navigator.msSaveBlob === 'function') {
			window.navigator.msSaveBlob(blob, contentDisposition);
		} else {
			var link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = contentDisposition;
			document.body.appendChild(link);
			link.click();
		}
    }).catch(error => {
		throw error
	});
  }

  previewPost(url, args = {}) {
	args.method = "POST";
	args.responseType = 'arraybuffer';
	return this.customRequest(url, args)
	.then(response => {
		var blob = new Blob([response.data], {type: 'application/pdf'});			
		let contentDisposition = response.headers['content-disposition'].split("=")[1];;			
		contentDisposition = decodeURIComponent((contentDisposition + '').replace(/\+/g, '%20'));
		if (typeof window.navigator.msSaveBlob === 'function') {
			window.navigator.msSaveBlob(blob, contentDisposition);
		} else {
			var link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = contentDisposition;
			document.body.appendChild(link);
			link.click();
		}
	}).catch(error => {
		throw error
	});
  }

	/*
	 * Log funtions below
	 */

	createResponseLog(method, url, time) {
		console.log(
			`Response ${method}: ${url} completed, took: ${+new Date() - time}ms`
		);
	}


	/**
	 * request handler
	 *
	 * @param string url
	 * @param object args
	 * @return Promise
	 */
	request(url, args = {}) {
		let time = new Date();
		let { method, headers, data, responseType } = args;

		// url validation
		if (!url || url instanceof String) {
			return console.log(`No valid url given for method ${method}`);
		}
		headers = Object.assign({}, config.defaultHeaders, headers);

		// return a promise
		return new Promise((resolve, reject) => {
			axios({url, method, headers, data, responseType})
				.then(response => {
					if( !has(response, 'data')){
						// axios allways has data... some big error here...
						reject(response);
					}
					if(has(response.data, 'error')){
						// server response with an error
						reject(response.data);
					}
					resolve(response.data);
				})
				.catch(error => {
					console.log(`Request ${method}: ${url} raised error: ${error}, took ${+new Date() - time}ms`);
					if ( error.hasOwnProperty('response') && error.response != null && error.response.status === 401) {
						localStorage.clear();
						sessionStorage.clear();
						window.location.reload();
					}
					reject(error)
				})
		})
	}

	customRequest(url, args = {}) {
		let time = new Date();
		let { method, headers, data, responseType } = args;

		// url validation
		if (!url || url instanceof String) {
			return console.log(`No valid url given for method ${method}`);
		}
		headers = Object.assign({}, config.defaultHeaders, headers);

		// return a promise
		return new Promise((resolve, reject) => {
			axios({url, method, headers, data, responseType})
				.then(response => {
					if( !has(response, 'data')){
						// axios allways has data... some big error here...
						reject(response);
					}
					if(has(response.data, 'error')){
						// server response with an error
						reject(response);
					}
					resolve(response);
				})
				.catch(error => {
					console.log(`Request ${method}: ${url} raised error: ${error}, took ${+new Date() - time}ms`);
					if ( error.hasOwnProperty('response') && error.response != null && error.response.status === 401) {
						localStorage.clear();
						sessionStorage.clear();
						window.location.reload();
					}
					reject(error)
				})
		})
	}

}
