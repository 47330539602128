import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/component';

const endpointAssemblys = API_SERVER + '/assembly';

export default class ApiComponent {

  // GET 'api/Component'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static searchComponents(text) {
    return Utils.genericCall(`${endpoint}/paged?search=${text}`);
  }

  static getByAssembly(id) {
    return Utils.genericCall(`${endpointAssemblys}/${id}/component`);
  }

  static getByAssemblyEvent(id) {
    return Utils.genericCall(`${endpointAssemblys}/${id}/componentEvent`);
  }

  static searchByAssembly(props) {
    return Utils.genericCall(`${endpointAssemblys}/${props.id}/component/paged?search=${props.search}&page=${props.page}`);
  }

  static getComponent(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editComponent(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addComponent(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteComponent(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static getAllFields() {
    return Utils.genericCall(`${endpoint}/fields`);
  }

  static addComponentToAssembly(data) {
    return Utils.genericPost(`${endpoint}/addComponentToAssembly`,data);
  }

  static addComponentToSubstructures(data) {
    return Utils.genericPost(`${endpoint}/addComponentToSubstructures`,data);
  }

  static updateComponentIntoAssembly(data) {
    return Utils.genericPost(`${endpoint}/updateComponentIntoAssembly`,data);
  }

  static updateComponentIntoSubstructures(data) {
    return Utils.genericPost(`${endpoint}/updateComponentIntoSubstructures`,data);
  }

  static deleteMultipleWithIndex(data) {
    return Utils.genericDelete(`${endpoint}/deleteMultipleWithIndex/${data.assetId}/from/${data.from}/to/${data.to}/type/${data.componentTypeId}`);
  }

  static removeAllcomponentsFromAssembly(data) {
    return Utils.genericDelete(`${endpoint}/deleteMultiple/${data.assetId}/${data.assemblyTypeId}/${data.componentTypeId}`,data);
  }

}