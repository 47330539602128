import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/assembly';

const endpointSpans = API_SERVER + '/bridgeSpan';

const endpointSections = API_SERVER + '/bridgeSection';

const endpointStructureType = API_SERVER + '/structureType';

export default class ApiAssembly {

  // GET 'api/Assembly'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchAssemblys(text){
    return Utils.genericCall(`${endpoint}/paged?search=${text}`);
  }

  static getBySpan(id){
    return Utils.genericCall(`${endpointSpans}/${id}/assembly`);
  }

  static getBySection(id){
    return Utils.genericCall(`${endpointSections}/${id}/assembly`);
  }

  static searchBySpan(props){
    return Utils.genericCall(`${endpointSpans}/${props.id}/assembly/paged?search=${props.search}&page=${props.page}`);
  }

  static getAssembly(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editAssembly(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addAssembly(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteAssembly(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static getAllByEventIdAndAssembly(assetEventId, filter = null) {
    return Utils.genericPost(`${endpointStructureType}/assetEvent/${assetEventId}`, filter);
  }

  static getAllByAssembly(assetId, filter = null) {
    return Utils.genericPost(`${endpointStructureType}/asset/${assetId}`, filter);
  }

  static getAllFields() {
    return Utils.genericCall(`${endpoint}/fields`);
  }

  static getAllAssembliesBySectionAndStructureType(sectionId, structureTypeId) {
    return Utils.genericCall(`${endpoint}/section/${sectionId}/structure/${structureTypeId}`);
  }

  static getAllAssemblysRecursively(sectionId, structureTypeId) {
    return Utils.genericCall(`${endpoint}/recursively/section/${sectionId}/structureType/${structureTypeId}`);
  }

  static addMultiAssembly(data) {
    return Utils.genericPost(`${endpoint}/multiple`, data);
  }

  static editMultiAssembly(data) {
    return Utils.genericPut(`${endpoint}/multiple`, data);
  }
  
  static getStructureAssemblyTree(data) {
    return Utils.genericCall(`${API_SERVER}/assembly/section/${data.sectionId}/structure/${data.structureId}/tree`);
  }

  static addAssemblyToAsset(data) {
    return Utils.genericPost(`${endpoint}/addAssemblyToAsset`, data.structureToSave);
  }
  

  static deleteStructuresTypes(data) {
    return Utils.genericDelete(`${endpoint}/deleteMultiple/${data.assetId}/${data.structureTypeId}`);
  }

  static addAssemblyToAssetWithOrder(data) {
    return Utils.genericPost(`${endpoint}/addAssemblyToAssetWithOrder`, data);
  }

  static addSubstructuresToAssetWithOrder(data) {
    return Utils.genericPost(`${endpoint}/addSubstructuresToAssetWithOrder`, data);
  }
  
  static deleteMultipleWithOrder(data) {
    return Utils.genericPost(`${endpoint}/deleteMultipleWithOrder`, data);
  }

}
