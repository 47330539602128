import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/eventType';

export default class ApiEventType {

  // GET 'api/eventTypes'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchEventTypes(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getEventType(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editEventType(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addEventType(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteEventType(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

}
