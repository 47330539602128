import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/waterFlow';

export default class ApiWaterFlow {

  // GET 'api/waterFlows'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchWaterFlows(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getWaterFlow(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editWaterFlow(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addWaterFlow(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteWaterFlow(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

}
