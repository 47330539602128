import * as actionTypes from './actionTypes'

/**
 * @namespace UserActions
 */


export function getUsers() {
  return { type: actionTypes.SAGA_USER_ALL };
}

export function loadUsersPaged(props) {
  return { type: actionTypes.SAGA_USER_SEARCH, props: props };
}

/**
 * @memberof UserActions
 * @description Creates a Redux action that can be dispatched
 * to fetch a user.
 *
 * This will trigger a Saga action.
 *
 * @param {string} userId - the ID of the user to fetch.
 * @returns A Redux action that can be dispatched.
 */
export function getUser(userId) {
  return {
    type: actionTypes.SAGA_USER_GET, props: {
      userId
    }
  };
}

export function getUsersRoles(props) {
  return { type: actionTypes.SAGA_USER_GET_USERSROLES, props: props };
}

export function editUser(userId, props, callback) {
  return { type: actionTypes.SAGA_USER_EDIT, props: { ...props, id: userId, callback } };
}

export function addUser(props, callback) {
  return { type: actionTypes.SAGA_USER_ADD, props: { ...props, callback } };
}

export function deleteUser(props) {
  return { type: actionTypes.SAGA_USER_DELETE, props: props };
}

export function deleteUserList(list, options = { callback: () => { } }) {
  return {
    type: actionTypes.SAGA_USER_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

/**
 * @memberof UserActions
 * @description Gets a Redux action that can be dispatched to retrieve
 * available users for trips.
 * @returns a Redux action that can be dispatched.
 */
export function getUsersForTrips() {
  return { type: actionTypes.SAGA_USER_FOR_TRIPS };
}

export function uploadUserSegnature(data, callback) {
  return {
    type: actionTypes.SAGA_USER_SIGNATURE_UPLOAD,
    props: { data, callback }
  }
}

export function deleteUserSegnature(data, callback) {
  return {
    type: actionTypes.SAGA_USER_SIGNATURE_UPLOAD_DELETE,
    props: { data, callback }
  }
}

/**
 * @memberof UserActions
 * @description Change User props (no saga action)
 */
export function changeUserProps(props) {
  return { type: actionTypes.CHANGE_USER_PROPS, props: props };
}

export function getUserParentOrganization() {
  return { type: actionTypes.SAGA_USER_GET_PARENT_ORGANIZATION };
}

/**
 * @memberof UserActions
 * @description Clears user props.
 */
export function clearUserProps() {
  return { type: actionTypes.CLEAR_USER_PROPS };
}

/**
 * @memberof UserActions
 * @description Clears user errors messages.
 */
export function clearUserErrors() {
  return { type: actionTypes.CLEAR_USER_ERRORS };
}

/**
 * @memberof UserActions
 * @description Gets current user profile.
 */
export function getProfile() {
  return { type: actionTypes.SAGA_USER_GET_PROFILE }
}

/**
 * @memberof UserActions
 * @description Creates a Redux action that can be dispatched
 * to fetch Organizations available to be assinged to a user.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getOrganizations() {
  return { type: actionTypes.SAGA_USER_GET_ORGANIZATIONS };
}

/**
 * @memberof UserActions
 * @description Creates a Redux action that can be dispatched
 * to fetch clients available to be assigned
 * to a user.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getClients() {
  return { type: actionTypes.SAGA_USER_GET_CLIENTS };
}

/**
 * @memberof UserActions
 * @description Creates a Redux action that can be dispatched
 * to fetch clients available to be assigned
 * to a user.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function findClients() {
  return { type: actionTypes.SAGA_USER_FIND_CLIENTS };
}

/**
 * @memberof UserActions
 * @description Creates a Redux action that can be dispatched
 * to fetch contacts available to be assigned to a user.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getContacts() {
  return { type: actionTypes.SAGA_USER_GET_CONTACTS };
}

/**
 * @memberof UserActions
 * @description Creates a Redux action that can be dispatched
 * to fetch roles available to be assigned to a user.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getRoles() {
  return { type: actionTypes.SAGA_USER_GET_ROLES };
}

/**
 * @memberof UserActions
 * @description Creates a Redux action that can be dispatched
 * to save a new contact.
 *
 * This will trigger a Saga action.
 *
 * @param {object} contactData - contact data to save.
 * @param {object} options - additional options.
 * @param {function} options.callback - function to be called
 * when the contact has been saved successfully.
 * @returns A Redux action that can be dispatched.
 */
export function addContact(contactData, options = { callback: () => { } }) {
  return {
    type: actionTypes.SAGA_USER_ADD_CONTACT,
    props: {
      contact: contactData,
      callback: options.callback
    }
  }
}

/**
 * @memberof UserActions
 * @description Creates a Redux action that can be dispatched
 * to load job titles available for users.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadJobTitles() {
  return { type: actionTypes.SAGA_USER_LOAD_JOB_TITLES };
}

/**
 * @memberof UserActions
 * @description Creates a Redux action that can be dispatched
 * to clean failed attempts when user loginb successfuly.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function cleanAttempts() {
  return { type: actionTypes.SAGA_USER_CLEAN_ATTEMPTS };
}

/**
 * @memberof UserActions
 * @description Creates a Redux action that can be dispatched
 * to clean failed attempts when user loginb successfuly.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function remainingAttempts(user) {
  return {
    type: actionTypes.SAGA_USER_REMAINING_ATTEMPTS,
    props: {
      user: user
    }
  };
}

