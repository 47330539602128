import { all, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'


function* setMenu(action) {
  const sidebarActive = action.props
  yield put({
      type: actionTypes.CHANGE_MENU_ACTIVE_PROPS,
      props: sidebarActive
  });
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_SET_MENU, setMenu),
  ]);
}

export default AuthWatcher;
