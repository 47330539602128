import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';
import {eventFields} from 'common/objectStructures';

const initialState = {
  events: [],
  eventUsers: [],
  event: {},
  pagination: {},
  formValues: null,
  headers:{clients:[], railRoads:[], subdivisions:[], bridges:[], sectionsEvent:[]},
  loading: false,
  loadingPhoto: false,
  errors: '',
  lastInspectionInfo: null,
  hasPhoto: false,
//adding a new property that is used by withSubobjectsLoadReducer and should be loaded only once,
// remember to add the proprty to the CLEAR_EVENT_PROPS action handler
  componentTypes: createState(),
  assemblyTypes: createState(),
  accessTypes: createState(),
  itemTypes: createState(),
  crossingFeatures: createState(),
  waterFlows: createState(),
  impactTypes: createState(),
  alignments: createState(),
  assetTypes: createState(),
  clients: createState(),
  usersWorkReports: createState(),

  downloadingDeckImportTemplate: false,
  downloadingApproachImportTemplate: false
}

function reducerEvent (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_EVENT_PROPS:
    case actionTypes.CHANGE_EVENT_PHOTO_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(eventFields)) {
          newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_EVENT_PROPS:
      return initialState;

    case actionTypes.CHANGE_EVENT_DECK_IMPORT_TEMPLATE_DOWNLOAD_STARTED: return {
      ...state,
      downloadingDeckImportTemplate : true
    }

    case actionTypes.CHANGE_EVENT_DECK_IMPORT_TEMPLATE_DOWNLOAD_ENDED: return {
      ...state,
      downloadingDeckImportTemplate: false
    }

    case actionTypes.CHANGE_EVENT_APPROACH_IMPORT_TEMPLATE_DOWNLOAD_STARTED: return {
      ...state,
      downloadingApproachImportTemplate: true
    }

    case actionTypes.CHANGE_EVENT_APPROACH_IMPORT_TEMPLATE_DOWNLOAD_ENDED: return {
      ...state,
      downloadingApproachImportTemplate: false
    }

    default:
      return state
  }
}

let wrappedReducer = onLoginCleanupReducer(initialState, reducerEvent);
export default withSubobjectsLoadReducer(
  wrappedReducer,
  {
    itemTypes: [
      actionTypes.CHANGE_EVENT_START_LOADING_ITEM_TYPES,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_ITEM_TYPES,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_ITEM_TYPES
    ],
    accessTypes: [
      actionTypes.CHANGE_EVENT_START_LOADING_ACCESS_TYPES,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_ACCESS_TYPES,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_ACCESS_TYPES
    ],
    assemblyTypes: [
      actionTypes.CHANGE_EVENT_START_LOADING_ASSEMBLY_TYPES,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_ASSEMBLY_TYPES,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_ASSEMBLY_TYPES
    ],
    componentTypes: [
      actionTypes.CHANGE_EVENT_START_LOADING_COMPONENT_TYPES,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_COMPONENT_TYPES,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_COMPONENT_TYPES
    ],
    crossingFeatures: [
      actionTypes.CHANGE_EVENT_START_LOADING_CROSSING_FEATURES,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_CROSSING_FEATURES,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_CROSSING_FEATURES
    ],
    waterFlows: [
      actionTypes.CHANGE_EVENT_START_LOADING_WATER_FLOWS,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_WATER_FLOWS,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_WATER_FLOWS
    ],
    impactTypes: [
      actionTypes.CHANGE_EVENT_START_LOADING_IMPACT_TYPES,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_IMPACT_TYPES,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_IMPACT_TYPES
    ],
    alignments: [
      actionTypes.CHANGE_EVENT_START_LOADING_ALIGNMENTS,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_ALIGNMENTS,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_ALIGNMENTS
    ],
    assetTypes: [
      actionTypes.CHANGE_EVENT_START_LOADING_ASSET_TYPES,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_ASSET_TYPES,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_ASSET_TYPES
    ],
    clients: [
      actionTypes.CHANGE_EVENT_START_LOADING_CLIENTS,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_CLIENTS,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_CLIENTS
    ],
    usersWorkReports: [
      actionTypes.CHANGE_EVENT_START_LOADING_USERS_WORK_REPORTS,
      actionTypes.CHANGE_EVENT_SUCCESS_LOADING_USERS_WORK_REPORTS,
      actionTypes.CHANGE_EVENT_ERROR_LOADING_USERS_WORK_REPORTS
    ]
  }
)
