import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
  loading: false,
  errors: '',
  emails: {},
}

function reducerEmail (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_EMAIL_PROPS:
      return Object.assign({}, state, action.props)

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerEmail);
