import {
    API_SERVER
} from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/workPerformed';

export default class WorkPerformed {

    // GET 'api/workPerformed'
    static getAll(id) {
        return Utils.genericCall(`${endpoint}/asset/${id}`);
    }

    static getWorkPerformeds(assetId, eventId) {
        return Utils.genericCall(`${endpoint}/asset/${assetId}/event/${eventId}`);
    }

    static getWorkPerformedById(workPerformedId) {
        return Utils.genericCall(`${endpoint}/${workPerformedId}`);
    }

    static addWorkPerformed(data) {
        return Utils.genericPost(`${endpoint}`, data);
    }

    static editWorkPerformed(data) {
        return Utils.genericPut(`${endpoint}/${data.id}`, data);
    }

    static deleteWorkPerformed(id) {
        return Utils.genericDelete(`${endpoint}/${id}`);
    }

    static addPhotosToWorkPerformed(data) {
        return Utils.genericPost(`${endpoint}/${data[0].workPerformedPhotos.id}/addPhotos`, data);
      }

}