import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
import * as webSocketEventTypes from 'config/webSocketEventTypes';

// APIs to talk to...
import ApiEvent from 'apiServices/ApiEvent'
import ApiAssetEvent from 'apiServices/ApiAssetEvent'

import {
  showDownloadErrorFailed,
  showRecordDeletedToast,
  showRerpotGenerationFailedToast,
  showToastAsSuccess,
  showReportSendToEmailToast,
  showToastAsError
} from '../actions/ToastActions';
import { cloneDeep } from 'lodash';

const recordType = "event";


function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The event cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_EVENT_PROPS, props });
}

function* loadingPhoto(loading = true) {
  const props = { loadingPhoto: loading };
  yield put({ type: actionTypes.CHANGE_EVENT_PHOTO_PROPS, props });
}


/*
 * get Events
 */
function* getEvents() {
  try {
    yield loading();
    // get all events
    const allEvents = yield call(ApiEvent.getAll);
    // update state for events
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        events: allEvents,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getEvent(action) {
  try {
    yield loading();
    // get event
    const event = yield call(ApiEvent.getEvent, action.props.eventId);
    yield put({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: 'From ' + event.DateofInspection,
        id: event.id,
        type: "event"
      }
    });

    if (Object.keys(action.props).length > 1)
      yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 5, ids: action.props } });
    // update state for events
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        event: event,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getBySection(action) {
  try {
    yield loading();

    yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 5, ids: action.props } });

    // get section events first page
    yield searchBySection({ props: { id: action.props.sectionId, search: '', page: 0 } });
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}


function* getByAsset(action) {
  try {
    yield loading();
    if (Object.keys(action.props).length > 1)
      yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 4, ids: action.props } });
    // get asset Events
    const assetEvents = yield call(ApiEvent.getByAsset, action.props.assetId);
    // update state for sections
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        events: assetEvents,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchEvents(action) {
  try {
    yield loading();
    // get all Events
    const events = yield call(ApiEvent.searchEvents, action.props);
    const pagination = {
      number: events.number,
      numberOfElements: events.numberOfElements,
      totalElements: events.totalElements,
      totalPages: events.totalPages,
      size: events.size
    };
    // update state for events
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        events: events.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchBySection(action) {
  try {
    yield loading();
    // get all Section Events
    const sectionEvents = yield call(ApiEvent.searchBySection, action.props);
    // update state for Events
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        events: sectionEvents.content,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* seeEvent(action) {
  try {
    yield loading();
    // open PDF for event
    yield call(ApiEvent.getEventPdf, action.props);
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

/*function* getEventPhotoGallery(action) {
  // return json with photoGallery
  let photoGallery = yield call(ApiEvent.getEventPhotoGallery, action.props.eventId);
  yield put({ type: actionTypes.CHANGE_EVENT_PROPS, props:{
    photoGallery: photoGallery,
  }});
  yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: {level:6, ids: action.props}});
}*/

function* addEvent(action) {
  try {
    yield loading();
    // add Event
    yield call(ApiEvent.addEvent, action.props);
    // update state for Event
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        event: {},
        loading: false,
        errors: ''
      }
    });
    yield put({ type: actionTypes.SAGA_ASSET_GET, props: { assetId: action.props.rootAssetId } });
  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteEvent(action) {
  try {
    yield loading();
    // delete Event
    yield call(ApiEvent.deleteEvent, action.props.id);
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
    yield getByAsset({ props: { assetId: action.props.asset.id } });
  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteEventItems(action) {
  try {
    yield loading();
    // delete Event
    let items = yield call(ApiEvent.deleteEventItems, action.props.items);
    let loadedStructureType = yield select(store => store.structureType)
    let newStructureData = cloneDeep(loadedStructureType[action.props.structureTypeId])
    let currentEvents = []
    let newEvents = []

    if (action.props.items.eventConditionsDelete.length > 0) {
      currentEvents = newStructureData.eventConditions
      newEvents = currentEvents.filter(e => !action.props.items.eventConditionsDelete.find(event => event === e.id))
      if (items.eventConditionPrevious && items.eventConditionPrevious.length > 0) {
        items.eventConditionPrevious.forEach(ec => newEvents.push(ec))
      }
      newStructureData.eventConditions = newEvents
    }

    if (action.props.items.eventConditionsToSolve?.length > 0) {
      currentEvents = newStructureData.eventConditions
      newEvents = currentEvents.filter(e => !action.props.items.eventConditionsToSolve.find(event => event === e.id))
      newStructureData.eventConditions = newEvents
    }

    if (action.props.items.eventNotesDelete.length > 0) {
      currentEvents = newStructureData.eventNotes
      newEvents = currentEvents.filter(e => !action.props.items.eventNotesDelete.find(event => event === e.id))
      if (items.eventNotesPrevious && items.eventNotesPrevious.length > 0) {
        items.eventNotesPrevious.forEach(en => newEvents.push(en))
      }
      newStructureData.eventNotes = newEvents
    }
 
    if (action.props.items.eventNotesToSolve?.length > 0) {
      currentEvents = newStructureData.eventNotes
      newEvents = currentEvents.filter(e => !action.props.items.eventNotesToSolve.find(event => event === e.id))
      newStructureData.eventNotes = newEvents
    }

    if (action.props.items.eventRecommendationsDelete.length > 0) {
      currentEvents = newStructureData.eventRecommendations
      newEvents = currentEvents.filter(e => !action.props.items.eventRecommendationsDelete.find(event => event === e.id))
      if (items.eventRecommendationsPrevious && items.eventRecommendationsPrevious.length > 0) {
        items.eventRecommendationsPrevious.forEach(er => newEvents.push(er))
      }
      newStructureData.eventRecommendations = newEvents
    }

    if (action.props.items.eventWorksPerformedDelete.length > 0) {
      currentEvents = newStructureData.workPerformeds
      newEvents = currentEvents.filter(e => !action.props.items.eventWorksPerformedDelete.find(event => event === e.id))
      newStructureData.workPerformeds = newEvents
    }
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        loading: false,
        errors: '',
        [action.props.structureTypeId]: newStructureData
      }
    });

  } catch (error) {
    yield loading(false, error);
  }
}

/**
 * Deletes a list of events
 * @param {{props: {eventsIds: number[]}}} action The action to perform. Is an
 * object that has a "props" property with an "eventsIds" property, an array of events ID's to
 * be deleted.
 */
function* deleteEventList(action) {

  try {
    yield loading();
    yield call(ApiEvent.deleteMultipleEvents, action.props.eventsIds);

    yield put(showRecordDeletedToast(recordType));

    yield loading(false);

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* editEvent(action) {
  try {
    yield loading();
    // update  event
    yield call(ApiEvent.editEvent, action.props);
    // update state for event
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        event: {},
        loading: false,
        errors: ''
      }
    });
    yield getEvent({ props: { eventId: action.props.id } });
  } catch (error) {
    yield loading(false, error);
  }

}

function* beginInspection(action) {
  try {
    // Begin Inspection
    yield call(ApiEvent.beginInspection, action.props);

    const assetEvents = yield call(ApiAssetEvent.getAssetEventByAssetId, action.props.assetEventId);

    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
        assetEventByAssetId: assetEvents,
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* updateStatusInspection(action) {
  try {
    // update Status Inspection
    yield call(ApiEvent.updateStatusInspection, action.props);
    yield call(ApiAssetEvent.getAssetEventByAssetId, action.props.assetEventId);

    /*
    NEXT CODE IS OLD.

    We no longer need to reload the data. The inspection status update is
    going to come over WebSockets.
    */
    // yield put({
    //   type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
    //     assetEventByAssetId: assetEvents,
    //   }
    // });
  } catch (error) {
    yield loading(false, error);
  }
}

function* updateStatusInspections(action) {
  try {
    // update Status Inspection
    yield call(ApiEvent.updateStatusInspections, action.props);

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* multipleUpdateCertifiedData(action) {
  try {
    // update Status Inspection
    yield call(ApiEvent.multipleUpdateCertifiedData, action.props);

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* getEventUserByEvent(action) {
  try {
    yield loading();
    // get EventUser By Event
    const eventUsers = yield call(ApiEvent.getEventUserByEvent, action.props);
    // update state for event user
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        eventUsers: eventUsers,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getEventByUser(action) {
  try {
    yield loading();
    // get EventUser By Event
    const events = yield call(ApiEvent.getEventByUser, action.props);
    const pagination = {
      number: events.number,
      numberOfElements: events.numberOfElements,
      totalElements: events.totalElements,
      totalPages: events.totalPages,
      size: events.size
    };
    // update state for event user
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        events: events.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getEventHeadersByUser(action) {
  try {
    yield loading();
    // get EventUser By Event
    const eventHeaders = yield call(ApiEvent.getEventHeadersByUser);
    // update state for event user
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        headers: eventHeaders,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getFinalReport(action) {
  try {
    yield call(ApiEvent.getFinalReport, action.props.sectionId, action.props.eventId);
    yield put(showToastAsSuccess("Success", "You have successfully download your report."));
  } catch (error) {
    yield put(showRerpotGenerationFailedToast());
  }
}

function* getInitialReport(action) {
  try {
    yield call(ApiEvent.getInitialReport, action.props.sectionId, action.props.eventId);
    yield put(showToastAsSuccess("Success", "You have successfully download your report."));
  } catch (error) {
    yield put(showRerpotGenerationFailedToast());
  }
}

function* generateDraftReports(action) {
  try {
    yield call(ApiEvent.generateDraftReports, action.props.typeId, action.props.data);
    yield put(showReportSendToEmailToast());
  } catch (error) {
    yield put(showRerpotGenerationFailedToast());
  }
}

function* downloadDraftReports(action) {
  try {
    yield loading();
    yield call(ApiEvent.downloadDraftReports, action.props.file);
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* downloadSelectedReports(action) {
  try {
    yield loading();
    yield call(ApiEvent.downloadSelectedReports, action.props.data);
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
    yield call(action.props.callback);
  } catch (error) {
    yield loading(false, error);
  }
}

function* addCoverPhoto(action) {
  try {
    yield loading();
    yield call(ApiEvent.addCoverPhoto, action.props.sectionSelected.event.id, action.props.data);

    // update event props
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put({
      type: actionTypes.SAGA_ASSETEVENT_BY_ASSET_GET,
      props: {
        data: { assetEventId: action.props.sectionSelected.id },
        callback: action.props.callback
      }
    });

  } catch (error) {
    yield loading(false, error);
  }
}

function* generateDownloadAndSubmitReports(action) {
  try {
    yield call(ApiEvent.generateDownloadAndSubmitReports, action.props.data);
    yield put(showReportSendToEmailToast());
  } catch (error) {
    yield put(showRerpotGenerationFailedToast());
  }
}

function* generateDownloadReports(action) {
  try {
    yield call(ApiEvent.generateDownloadReports, action.props);
    yield put(showToastAsSuccess("Success", "Your request is being processed."));
  } catch (error) {
    yield put(showRerpotGenerationFailedToast());
  }
}

function* getLastInspectionInfo(action) {
  try {
    const lastInspectionInfo = yield call(ApiEvent.getLastInspectionInfo, action.props.sectionId);
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        lastInspectionInfo: lastInspectionInfo,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    console.log('====================================');
    console.log('error ', error);
    console.log('====================================');
  }
}

function* completeMultipleEvents(action) {
  try {
    yield call(ApiEvent.completeMultipleEvents, action.props.data);
    action.props.callback();
  } catch (error) {
    console.log('====================================');
    console.log('error ', error);
    console.log('====================================');
  }
}





function* hasPhoto(action) {
  try {
    // yield loading();
    yield loadingPhoto();
    const hasPhotos = yield call(ApiEvent.hasPhoto, action.props);
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        hasPhoto: hasPhotos,
        errors: ''
      }
    });

    yield loadingPhoto(false);
  } catch (error) {
    //TODO show error?
    yield loadingPhoto(false);
    // yield loading(false, error);
  }
}

function* getComponentTypes() {
  let loadedComponentTypes = yield select(store => store.event.componentTypes.data)
  try {
    if (loadedComponentTypes.length === 0) {

      yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_COMPONENT_TYPES });
      const componentTypes = yield call(ApiEvent.getCompomentTypes);
      yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_COMPONENT_TYPES, props: { componentTypes } });
    }
  } catch (e) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_COMPONENT_TYPES, error: e.message });
  }
}

function* getAssemblyTypes() {
  try {
    let loadedTypes = yield select(store => store.event.assemblyTypes.data);
    if (loadedTypes.length === 0) {
      yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_ASSEMBLY_TYPES });
      const assemblyTypes = yield call(ApiEvent.getAssemblyTypes);
      yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_ASSEMBLY_TYPES, props: { assemblyTypes } });
    }
  } catch (e) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_ASSEMBLY_TYPES, error: e.message });
  }
}


function* getAccessTypes() {
  try {
    let loadedAccessTypes = yield select(store => store.event.accessTypes.data);
    if (loadedAccessTypes.length === 0) {
      yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_ACCESS_TYPES });
      const accessTypes = yield call(ApiEvent.getAccessTypes);
      yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_ACCESS_TYPES, props: { accessTypes } });
    }
  } catch (e) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_ACCESS_TYPES, error: e.message });
  }
}

function* getItemTypes() {
  try {
    let Itemsloaded = yield select(store => store.event.itemTypes.data)
    if (Itemsloaded.length === 0) {
      yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_ITEM_TYPES });
      const itemTypes = yield call(ApiEvent.getItemTypes);
      yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_ITEM_TYPES, props: { itemTypes } });
    }
  } catch (e) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_ITEM_TYPES, error: e.message });
  }
}

function* getCrossingFeatures() {
  try {

    const existentModels = yield select(state => state.event.crossingFeatures.data);

    //If they exist in the store, we will not load it again
    if (existentModels.length > 0) {
      return;
    }

    yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_CROSSING_FEATURES });
    const crossingFeatures = yield call(ApiEvent.getCrossingFeatures);
    yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_CROSSING_FEATURES, props: { crossingFeatures } });
  } catch (error) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_CROSSING_FEATURES, error: error.message });
  }
}

function* getWaterFlows() {
  try {

    const existentModels = yield select(state => state.event.waterFlows.data);

    //If they exist in the store, we will not load it again
    if (existentModels.length > 0) {
      return;
    }

    yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_WATER_FLOWS });
    const waterFlows = yield call(ApiEvent.getWaterFlows);
    yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_WATER_FLOWS, props: { waterFlows } });
  } catch (error) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_WATER_FLOWS, error: error.message });
  }
}

function* getImpactTypes() {
  try {

    const existentModels = yield select(state => state.event.impactTypes.data);

    //If they exist in the store, we will not load it again
    if (existentModels.length > 0) {
      return;
    }

    yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_IMPACT_TYPES });
    const impactTypes = yield call(ApiEvent.getImpactTypes);
    yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_IMPACT_TYPES, props: { impactTypes } });
  } catch (error) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_IMPACT_TYPES, error: error.message });
  }
}

function* getAlignments() {
  try {

    const existentModels = yield select(state => state.event.alignments.data);

    //If they exist in the store, we will not load it again
    if (existentModels.length > 0) {
      return;
    }

    yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_ALIGNMENTS });
    const alignments = yield call(ApiEvent.getAlignments);
    yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_ALIGNMENTS, props: { alignments } });
  } catch (error) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_ALIGNMENTS, error: error.message });
  }
}

function* getAssetTypes() {
  try {

    const existentModels = yield select(state => state.event.assetTypes.data);

    //If they exist in the store, we will not load it again
    if (existentModels.length > 0) {
      return;
    }

    yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_ASSET_TYPES });
    const assetTypes = yield call(ApiEvent.getAssetTypes);
    yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_ASSET_TYPES, props: { assetTypes } });
  } catch (error) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_ASSET_TYPES, error: error.message });
  }
}


function* loadClients() {
  try {
    yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_CLIENTS });
    const clients = yield call(ApiEvent.getClients);
    yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_CLIENTS, props: { clients } });
  } catch (error) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_CLIENTS, error: error.message });
  }
}



function* loadUsersThatWorkOnReportsByClientId(action) {
  try {
    const clientId = action.props.clientId
    yield put({ type: actionTypes.CHANGE_EVENT_START_LOADING_USERS_WORK_REPORTS });
    const usersWorkReports = yield call(ApiEvent.getUsersThatWorkOnReportsByClientId, clientId);
    yield put({ type: actionTypes.CHANGE_EVENT_SUCCESS_LOADING_USERS_WORK_REPORTS, props: { usersWorkReports } });
  } catch (error) {
    yield put({ type: actionTypes.CHANGE_EVENT_ERROR_LOADING_USERS_WORK_REPORTS, error: error.message });
  }
}




function* uploadEventExcelFile(action) {
  try {
    yield call(ApiEvent.uploadEventExcelFile, action.props.eventId, action.props.structureTypeId, action.props.data);
    yield put(showToastAsSuccess("processing data.", "Your data is being process. Please refresh this page or come back again after a few minutes."));

    // update event props
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    //TODO this throws an error. Check if it is neccesary to trigger.
    // yield put({
    //   type: actionTypes.SAGA_ASSETEVENT_BY_ASSET_GET,
    //   props: {
    //     data: { assetEventId: action.props.sectionSelected.id },
    //     callback: action.props.callback ? action.props.callback : () => {}
    //   }
    // });
  } catch (error) {
    yield put(showToastAsError("Failed", "There has been an error uploading your file."));
    yield loading(false, error);
  }
}

function* downloadDeckImportTemplate() {
  try {
    yield put({ type: actionTypes.CHANGE_EVENT_DECK_IMPORT_TEMPLATE_DOWNLOAD_STARTED });
    yield call(ApiEvent.downloadDeckImportTemplate);
  } catch (error) {
    yield put(showDownloadErrorFailed("Deck Import Template"));
  }

  yield put({ type: actionTypes.CHANGE_EVENT_DECK_IMPORT_TEMPLATE_DOWNLOAD_ENDED });
}

function* downloadApproachImportTemplate() {
  try {
    yield put({ type: actionTypes.CHANGE_EVENT_APPROACH_IMPORT_TEMPLATE_DOWNLOAD_STARTED });
    yield call(ApiEvent.downloadApproachImportTemplate);
  } catch (error) {
    yield put(showDownloadErrorFailed("Approach Import Template"));
  }

  yield put({ type: actionTypes.CHANGE_EVENT_APPROACH_IMPORT_TEMPLATE_DOWNLOAD_ENDED });
}


function* webSocketMessageReceived(action) {
  switch (action.props.eventType) {
    case webSocketEventTypes.EVENT_STATUS_UPDATED:
      yield put({
        type: actionTypes.EVENT_STATUS_UPDATED,
        props: {
          event: action.props.payload
        }
      });
      break;

    default:
      break;
  }
}



function* getInitialReportBaseSixFour(action) {
  try {
    yield loading();
    const initialReport = yield call(ApiEvent.getInitialReportBaseSixFour, action.props);
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        initialReportBaseSixFour: initialReport,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}


function* getFinalReportBaseSixFour(action) {
  try {
    yield loading();
    const finalReport = yield call(ApiEvent.getFinalReportBaseSixFour, action.props);
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        finalReportBaseSixFour: finalReport,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getReportComments(action) {
  try {
    yield loading();
    const reportComments = yield call(ApiEvent.getReportComments, action.props);
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        reportComments: reportComments,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* saveReportComments(action) {
  try {
    yield loading();
    const reportComments = yield call(ApiEvent.saveReportComments, action.props);
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        reportComments: reportComments,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_EVENT_ALL, getEvents),
    takeLatest(actionTypes.SAGA_EVENT_GET, getEvent),
    takeLatest(actionTypes.SAGA_EVENT_SEE, seeEvent),
    takeLatest(actionTypes.SAGA_SECTION_EVENTS, getBySection),
    takeLatest(actionTypes.SAGA_ASSET_EVENTS, getByAsset),
    takeLatest(actionTypes.SAGA_SECTION_EVENTS_SEARCH, searchBySection),
    takeLatest(actionTypes.SAGA_EVENT_ADD, addEvent),
    takeLatest(actionTypes.SAGA_EVENT_EDIT, editEvent),
    takeLatest(actionTypes.SAGA_EVENT_SEARCH, searchEvents),
    takeLatest(actionTypes.SAGA_EVENT_DELETE, deleteEvent),
    takeLatest(actionTypes.SAGA_EVENT_DELETE_ITEMS, deleteEventItems),
    takeLatest(actionTypes.SAGA_EVENT_BEGININSPECTION, beginInspection),
    takeLatest(actionTypes.SAGA_EVENT_UPDATESTATUSINSPECTION, updateStatusInspection),
    takeLatest(actionTypes.SAGA_EVENT_UPDATESTATUSINSPECTIONS, updateStatusInspections),
    takeLatest(actionTypes.SAGA_EVENT_MULTIPLEUPDATECERTIFIEDDATA, multipleUpdateCertifiedData),
    takeLatest(actionTypes.SAGA_EVENTUSER_EVENT, getEventUserByEvent),
    takeLatest(actionTypes.SAGA_EVENT_BY_USER, getEventByUser),
    takeLatest(actionTypes.SAGA_EVENT_HEADERS_BY_USER, getEventHeadersByUser),
    takeLatest(actionTypes.SAGA_EVENT_GET_FINAL_REPORT, getFinalReport),
    takeLatest(actionTypes.SAGA_EVENT_GET_INITIAL_REPORT, getInitialReport),
    takeLatest(actionTypes.SAGA_EVENT_DOWNLOAD_SELECTED_REPORTS, downloadSelectedReports),
    takeLatest(actionTypes.SAGA_GENERATE_DRAFT_REPORT, generateDraftReports),
    takeLatest(actionTypes.SAGA_DOWNLOAD_REPORT, downloadDraftReports),
    takeLatest(actionTypes.SAGA_EVENT_ADD_COVER_PHOTO, addCoverPhoto),
    takeLatest(actionTypes.SAGA_GENERATE_DOWNLOAD_AND_SUBMITE_REPORT, generateDownloadAndSubmitReports),
    takeLatest(actionTypes.SAGA_GENERATE_DOWNLOAD__REPORT, generateDownloadReports),
    takeLatest(actionTypes.SAGA_EVENT_LAST_INSPECTION_INFO, getLastInspectionInfo),
    takeLatest(actionTypes.SAGA_EVENT_COMPLETE_MULTIPLE, completeMultipleEvents),
    takeLatest(actionTypes.SAGA_EVENT_HAS_PHOTO, hasPhoto),
    takeLatest(actionTypes.SAGA_EVENT_GET_COMPONENT_TYPES, getComponentTypes),
    takeLatest(actionTypes.SAGA_EVENT_GET_ASSEMBLY_TYPES, getAssemblyTypes),
    takeLatest(actionTypes.SAGA_EVENT_GET_ACCESS_TYPES, getAccessTypes),
    takeLatest(actionTypes.SAGA_EVENT_GET_ITEM_TYPES, getItemTypes),
    takeLatest(actionTypes.SAGA_EVENT_GET_CROSSING_FEATURES, getCrossingFeatures),
    takeLatest(actionTypes.SAGA_EVENT_GET_WATER_FLOWS, getWaterFlows),
    takeLatest(actionTypes.SAGA_EVENT_GET_IMPACT_TYPES, getImpactTypes),
    takeLatest(actionTypes.SAGA_EVENT_GET_ALIGNMENTS, getAlignments),
    takeLatest(actionTypes.SAGA_EVENT_GET_ASSET_TYPES, getAssetTypes),
    takeLatest(actionTypes.SAGA_EVENT_UPLOAD_EXCEL_FILE, uploadEventExcelFile),
    takeLatest(actionTypes.SAGA_EVENT_DELETE_MULTIPLE, deleteEventList),
    takeLatest(actionTypes.SAGA_EVENT_DOWNLOAD_TEMPLATE_DECK_IMPORT, downloadDeckImportTemplate),
    takeLatest(actionTypes.SAGA_EVENT_DOWNLOAD_TEMPLATE_APPROACH_IMPORT, downloadApproachImportTemplate),
    takeLatest(actionTypes.SAGA_EVENT_GET_CLIENTS, loadClients),
    takeLatest(actionTypes.SAGA_EVENT_LOAD_USERS_THAT_WORK_ON_REPORTS_BY_CLIENT_ID, loadUsersThatWorkOnReportsByClientId),
    takeLatest(actionTypes.SAGA_EVENT_GET_INITIAL_BASE_REPORT, getInitialReportBaseSixFour),
    takeLatest(actionTypes.SAGA_EVENT_GET_FINAL_BASE_REPORT, getFinalReportBaseSixFour),
    takeLatest(actionTypes.SAGA_EVENT_GET_REPORT_COMMENTS, getReportComments),
    takeLatest(actionTypes.SAGA_EVENT_SAVE_REPORT_COMMENTS, saveReportComments),
    takeEvery(actionTypes.WEBSOCKET_MESSAGE_RECEIVED, webSocketMessageReceived)
  ]);
}

export default AuthWatcher;
