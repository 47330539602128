/**
 * Redux action types.
 * @namespace ReduxActionTypes
 */

/**
 * Redux action types that trigger Sagas execution.
 * @namespace Sagas
 * @memberof ReduxActionTypes
 */

/**
 * Redux action types that will not trigger Sagas executions.
 * @namespace Common
 * @memberof ReduxActionTypes
 */

/**
 * Redux action types for WebSockets
 * @namespace WebSocket
 * @memberof ReduxActionTypes
 */

/*
 * Saga Acion types
 */
// auth
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_AUTH_LOAD = 'SAGA/AUTH/INIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_AUTH_SINGUP = 'SAGA/AUTH/SINGUP';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_AUTH_LOGIN = 'SAGA/AUTH/LOGIN';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_AUTH_LOGOUT = 'SAGA/AUTH/LOGOUT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_AUTH_ERROR = 'SAGA/AUTH/CHANGE_AUTH_ERROR';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_AUTH_CHANGE_SELECTED_ORGANIZATION = "SAGA/AUTH/CHANGE/SELECTED/ORGANIZATION";;
/**
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_AUTH_CHANGE_SELECTED_CLIENT = "SAGA/AUTH/CHANGE/SELECTED/CLIENT";;
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_AUTH_FORGOT_PASS_EMAIL = 'SAGA/AUTH/FORGOT_PASS_EMAIL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_AUTH_CHANGE_PASS = 'SAGA/AUTH/CHANGE_PASS';

// user
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_ALL = 'SAGA/USER/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_SEARCH = 'SAGA/USER/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_GET = 'SAGA/USER/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_EDIT = 'SAGA/USER/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_ADD = 'SAGA/USER/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_DELETE = 'SAGA/USER/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_DELETE_CHECKED = 'SAGA/USER/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_WITH_FILTERS = 'SAGA/USER/WITH/FILTERS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_FOR_TRIPS = "SAGA/USER/FOR/TRIPS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_GET_USERSROLES = 'SAGA/USER/GET/USERSROLE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_SIGNATURE_UPLOAD = 'SAGA/USER/SIGNATURE/UPLOAD'
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_SIGNATURE_UPLOAD_DELETE = 'SAGA/USER/SIGNATURE/UPLOAD/DELETE'
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_GET_PARENT_ORGANIZATION = 'SAGA/USER/GET/PARENT/ORGANIZATION';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_GET_PROFILE = 'SAGA/USER/GET/PROFILE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_GET_ORGANIZATIONS = "SAGA/USER/GET/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_GET_CLIENTS = "SAGA/USER/GET/CLIENTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_FIND_CLIENTS = "SAGA/USER/FIND/CLIENTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_GET_CONTACTS = "SAGA/USER/GET/CONTACTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_GET_ROLES = "SAGA/USER/GET/ROLES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_ADD_CONTACT = "SAGA/USER/ADD/CONTACT";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_LOAD_JOB_TITLES = "SAGA/USER/LOAD/JOB/TITLES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_CLEAN_ATTEMPTS = "SAGA/USER/CLEAN/ATTEMPTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_REMAINING_ATTEMPTS = "SAGA/USER/REMAINING/ATTEMPTS";



// client
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_ALL = 'SAGA/CLIENT/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENTS_ALL = 'SAGA/CLIENTS/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_SEARCH = 'SAGA/CLIENT/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_SEARCH_ADMIN = 'SAGA/CLIENT/SEARCH/ADMIN';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_GET = 'SAGA/CLIENT/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_EDIT = 'SAGA/CLIENT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_ADD = 'SAGA/CLIENT/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_DELETE = 'SAGA/CLIENT/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_DELETE_CHECKED = 'SAGA/CLIENT/DELETE_CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_RAILROADS = 'SAGA/CLIENT/RAILROADS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_RAILROADS_SEARCH = 'SAGA/CLIENT/RAILROADS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_BRIDGES_SEARCH = 'SAGA/CLIENT/BRIDGES/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_ASSETS_SEARCH = 'SAGA/CLIENT/ASSETS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_SUBDIVISIONS = 'SAGA/CLIENT/SUBDIVISIONS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_INSPECTION_MAP_STATS = 'SAGA/CLIENT/INSPECTION/MAP/STATS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_INSPECTION_MAP_PRIORITY = 'SAGA/CLIENT/INSPECTION/MAP/PRIORITY';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_GET_REPORTS = 'SAGA/CLIENT/GET_REPORTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_GET_STATS = 'SAGA/CLIENT/GET/STATS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_GET_BRIDGES_SECTIONS = 'SAGA/CLIENT/GET/BRIDGES/SECTIONS';

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_CONTACTS_LOAD_PAGED = "SAGA/CLIENT/CONTACTS/LOAD/PAGED";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_CONTACT_EDIT = "SAGA/CLIENT/CONTACT/EDIT";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_CONTACT_ADD = "SAGA/CLIENT/CONTACT/ADD";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_CONTACT_DELETE_LIST = "SAGA/CLIENT/CONTACT/DELETE/LIST";

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLIENT_DOCUMENTS_LOAD_PAGED = "SAGA/CLIENT/DOCUMENTS/LOAD/PAGED";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_CLIENT_DOCUMENT_EDIT = "SAGA/CLIENT/DOCUMENT/EDIT";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_CLIENT_DOCUMENT_ADD = "SAGA/CLIENT/DOCUMENT/ADD";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_CLIENT_DOCUMENT_DELETE_LIST = "SAGA/CLIENT/DOCUMENT/DELETE/LIST";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_CLIENT_LOAD_DOCUMENT_TYPES = "SAGA/CLIENT/LOAD/DOCUMENT/TYPES";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_CLIENT_LOAD_JOB_TITLES = "SAGA/CLIENT/LOAD/JOB/TITLES";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_CLIENT_LOAD_ORGANIZATIONS = "SAGA/CLIENT/LOAD/ORGANIZATIONS";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_CLIENT_LOAD_CLIENTS = "SAGA/CLIENT/LOAD/CLIENTS";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_CLIENT_LOAD_CONTACTS = "SAGA/CLIENT/LOAD/CONTACTS";


// railroad
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_ALL = 'SAGA/RAILROAD/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_GET = 'SAGA/RAILROAD/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_SEARCH = 'SAGA/RAILROAD/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_SUBDIVISIONS = 'SAGA/RAILROAD/SUBDIVISIONS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_EDIT = 'SAGA/RAILROAD/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_ADD = 'SAGA/RAILROAD/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_DELETE = 'SAGA/RAILROAD/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_DELETE_CHECKED = 'SAGA/RAILROAD/DELETE_CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_SUBDIVISIONS_SEARCH = 'SAGA/RAILROAD/SUBDIVIONS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_BY_CLIENTS = 'SAGA/RAILROAD/BY/CLIENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_BY_NAME = 'SAGA/RAILROAD/BY/NAME';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_SEARCH_BY_CLIENTS = 'SAGA/RAILROAD/SEARCH/BY/CLIENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_LIST_BY_CLIENTS = 'SAGA/RAILROAD/LIST/BY/CLIENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_RAILROAD_LIST_BY_CLIENTS_REDUCED = 'SAGA/RAILROAD/LIST/BY/CLIENTS/REDUCED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_BY_CLIENT_ID = "SAGA/RAILROAD/BY/CLIENT/ID";

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_CONTACTS_LOAD_PAGED = "SAGA/RAILROAD/CONTACTS/LOAD/PAGED";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_RAILROAD_CONTACT_EDIT = "SAGA/RAILROAD/CONTACT/EDIT";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_RAILROAD_CONTACT_ADD = "SAGA/RAILROAD/CONTACT/ADD";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_RAILROAD_CONTACT_DELETE_LIST = "SAGA/RAILROAD/CONTACT/DELETE/LIST";


/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_DOCUMENTS_LOAD_PAGED = "SAGA/RAILROAD/DOCUMENTS/LOAD/PAGED";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_DOCUMENT_EDIT = "SAGA/RAILROAD/DOCUMENT/EDIT";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_DOCUMENT_ADD = "SAGA/RAILROAD/DOCUMENT/ADD";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_DOCUMENT_DELETE_LIST = "SAGA/RAILROAD/DOCUMENT/DELETE/LIST";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_LOAD_DOCUMENT_TYPES = "SAGA/RAILROAD/LOAD/DOCUMENT/TYPES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_GET_JOB_TITLES = "SAGA/RAILROAD/GET/JOB/TITLES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_GET_ORGANIZATIONS = "SAGA/RAILROAD/GET/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_GET_CLIENTS = "SAGA/RAILROAD/GET/CLIENTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_RAILROAD_GET_CONTACTS = "SAGA/RAILROAD/GET/CONTACTS";



// subdivision
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_ALL = 'SAGA/SUBDIVISION/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_GET = 'SAGA/SUBDIVISION/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_BRIDGES = 'SAGA/SUBDIVISION/BRIDGES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_ASSETS = 'SAGA/SUBDIVISION/ASSETS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_SEARCH = 'SAGA/SUBDIVISION/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_EDIT = 'SAGA/SUBDIVISION/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_ADD = 'SAGA/SUBDIVISION/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_DELETE = 'SAGA/SUBDIVISION/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_DELETE_CHECKED = 'SAGA/SUBDIVISION/DELETE_CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_BRIDGES_SEARCH = 'SAGA/SUBDIVISION/BRIDGES/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_ASSETS_SEARCH = 'SAGA/SUBDIVISION/ASSETS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_BY_RAILROADS = 'SAGA/SUBDIVISION/BY/RAILROADS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_SUBDIVISION_BY_RAILROADS_REDUCED = 'SAGA/SUBDIVISION/BY/RAILROADS/REDUCED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_FILTER = 'SAGA/SUBDIVISION/FILTER';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_SECTIONS = 'SAGA/SUBDIVISION/SECTIONS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_BY_NAME = 'SAGA/SUBDIVISION/BY/NAME';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_BY_CLIENT_RAILROADS = 'SAGA/SUBDIVISION/BY/CLIENT/RAILROADS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_LIST_BY_CLIENTS = 'SAGA/SUBDIVISION/LIST/BY/CLIENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_BRIDGE_AND_SECTION_FROM_TEMPLATE = 'SAGA/SUBDIVISION/BRIDGE/AND/SECTION/FROM/TEMPLATE';


/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SUBDIVISION_CONTACTS_LOAD_PAGED = "SAGA/SUBDIVISION/CONTACTS/LOAD/PAGED";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_CONTACT_EDIT = "SAGA/SUBDIVISION/CONTACT/EDIT";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_CONTACT_ADD = "SAGA/SUBDIVISION/CONTACT/ADD";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_CONTACT_DELETE_LIST = "SAGA/SUBDIVISION/CONTACT/DELETE/LIST";


/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_DOCUMENTS_LOAD_PAGED = "SAGA/SUBDIVISION/DOCUMENTS/LOAD/PAGED";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_DOCUMENT_EDIT = "SAGA/SUBDIVISION/DOCUMENT/EDIT";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_DOCUMENT_ADD = "SAGA/SUBDIVISION/DOCUMENT/ADD";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_DOCUMENT_DELETE_LIST = "SAGA/SUBDIVISION/DOCUMENT/DELETE/LIST";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_LOAD_DOCUMENT_TYPES = "SAGA/SUBDIVISION/LOAD/DOCUMENT/TYPES";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_LOAD_JOB_TITLES = "SAGA/SUBDIVISION/LOAD/JOB/TITLES";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_LOAD_ORGANIZATIONS = "SAGA/SUBDIVISION/LOAD/ORGANIZATIONS";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_LOAD_CLIENTS = "SAGA/SUBDIVISION/LOAD/CLIENTS";
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_SUBDIVISION_LOAD_CONTACTS = "SAGA/SUBDIVISION/LOAD/CONTACTS";



//event
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_EVENT_GET_REPORT_COMMENTS = 'SAGA/EVENT/GET/REPORT/COMMENTS';
 /**
 * @memberof ReduxActionTypes.Sagas
 */
  export const SAGA_EVENT_SAVE_REPORT_COMMENTS = 'SAGA/EVENT/SAVE/REPORT/COMMENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_EVENT_GET_INITIAL_BASE_REPORT = 'SAGA/EVENT/GET/INITIAL/BASE/REPORT';
 /**
 * @memberof ReduxActionTypes.Sagas
 */
  export const SAGA_EVENT_GET_FINAL_BASE_REPORT = 'SAGA/EVENT/GET/FINAL/BASE/REPORT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_BRIDGE_INVENTORY_REPORT = 'SAGA/EVENT/GET/BRIDGE/INVENTORY/REPORT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_RECOMMENDATION_BY_PRIORITY_REPORT = 'SAGA/EVENT/GET/RECOMMENDATION/PRIORITY/REPORT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_INITIAL_OWNER_REPORT = 'SAGA/EVENT/GET/INITIAL/OWNER/REPORT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_COMPONENT_TYPES = 'SAGA/EVENT/GET/COMPONENT/TYPES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_ASSEMBLY_TYPES = 'SAGA/EVENT/GET/ASSEMBLY/TYPES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_ACCESS_TYPES = 'SAGA/EVENT/GET/ACCESS/TYPES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_ITEM_TYPES = 'SAGA/EVENT/GET/ITEM/TYPES';

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_CROSSING_FEATURES = "SAGA/EVENT/GET/CROSSING/FEATURES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_DECK_TYPES = "SAGA/EVENT/GET/DECK/TYPES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_WATER_FLOWS = "SAGA/EVENT/GET/WATER/FLOWS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_IMPACT_TYPES = "SAGA/EVENT/GET/IMPACT/TYPES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_ALIGNMENTS = "SAGA/EVENT/GET/ALIGNMENTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_ASSET_TYPES = "SAGA/EVENT/GET/ASSET_TYPES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_CLIENTS = "SAGA/EVENT/GET/CLIENTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_UPLOAD_EXCEL_FILE = 'SAGA/EVENT/UPLOAD/EXCEL/FILE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_DELETE_MULTIPLE = 'SAGA/EVENT/DELETE/MULTIPLE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_DOWNLOAD_TEMPLATE_DECK_IMPORT = "SAGA/EVENT/DOWNLOAD/TEMPLATE/DECK/IMPORT";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_DOWNLOAD_TEMPLATE_APPROACH_IMPORT = "SAGA/EVENT/DOWNLOAD/TEMPLATE/APPROACH/IMPORT";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_LOAD_USERS_THAT_WORK_ON_REPORTS_BY_CLIENT_ID = "SAGA/EVENT/LOAD/USERS/THAT/WORK/ON/REPORTS/BY/CLIENT/ID";


// bridge
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_ALL = 'SAGA/BRIDGE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_GET = 'SAGA/BRIDGE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_SECTIONS = 'SAGA/BRIDGE/SECTIONS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_SEARCH = 'SAGA/BRIDGE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_EDIT = 'SAGA/BRIDGE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_ADD = 'SAGA/BRIDGE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_DELETE = 'SAGA/BRIDGE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_SECTIONS_SEARCH = 'SAGA/BRIDGE/SECTIONS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_BY_SUBDIVISIONS = 'SAGA/BRIDGE/BY/SUBDIVISIONS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_BY_RAILROADS = 'SAGA/BRIDGE/BY/RAILROADS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_ASSETEVENTS = 'SAGA/BRIDGE/ASSETEVENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_BRIDGE_SECTIONS_BY_BRIDGE = 'SAGA/BRIDGE/SECTIONS/BY/BRIDGE';

// section
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_ALL = 'SAGA/SECTION/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_GET = 'SAGA/SECTION/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_EVENTS = 'SAGA/SECTION/EVENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_SPANS = 'SAGA/SECTION/SPANS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_ASSEMBLIES = 'SAGA/SECTION/ASSEMBLIES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_SEARCH = 'SAGA/SECTION/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_EDIT = 'SAGA/SECTION/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_ADD = 'SAGA/SECTION/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_DELETE = 'SAGA/SECTION/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_SECTION_DELETE_ASSEMBLIES = 'SAGA/SECTION/DELETE/ASSEMBLIES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_EVENTS_SEARCH = 'SAGA/SECTION/EVENTS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTION_SPANS_SEARCH = 'SAGA/SECTION/SPANS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_SECTIONS_SEARCH = 'SAGA/TRIP/SECTIONS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_SECTIONS_SEARCH_CALLBACK = 'SAGA/TRIP/SECTIONS/SEARCH/CALLBACK';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_GENERATE_FIELD_REPORTS = 'SAGA/TRIP/GENERATE/FIELD/REPORTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_GET_ROLES = "SAGA/TRIP/GET/ROLES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_GET_USERS_ROLES = "SAGA/TRIP/GET/USERS/ROLES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_LOAD_CLIENTS = "SAGA/TRIP/LOAD/CLIENTS";

// inspection
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_ALL = 'SAGA/EVENT/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET = 'SAGA/EVENT/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_SEARCH = 'SAGA/EVENT/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_EDIT = 'SAGA/EVENT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_ADD = 'SAGA/EVENT/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_DELETE = 'SAGA/EVENT/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_DELETE_ITEMS = 'SAGA/EVENT/DELETE/ITEMS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_SEE = 'SAGA/EVENT/SEE';
//export const SAGA_EVENT_PHOTOGALLERY  = 'SAGA/EVENT/PHOTOGALLERY';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_BEGININSPECTION = 'SAGA/EVENT/BEGININSPECTION';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_UPDATESTATUSINSPECTION = 'SAGA/EVENT/UPDATESTATUSINSPECTION';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_UPDATESTATUSINSPECTIONS = 'SAGA/EVENT/UPDATESTATUSINSPECTIONS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_EVENT_MULTIPLEUPDATECERTIFIEDDATA = 'SAGA/EVENT/MULTIPLEUPDATECERTIFIEDDATA';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTUSER_EVENT = 'SAGA/EVENTUSER/EVENT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_BY_USER = 'SAGA/EVENT/BY/USER';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_HEADERS_BY_USER = 'SAGA/EVENT/HEADERS/BY/USER';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_FINAL_REPORT = 'SAGA/EVENT/GET/FINAL/REPORT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_GET_INITIAL_REPORT = 'SAGA/EVENT/GET/INITIAL/REPORT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_DOWNLOAD_SELECTED_REPORTS = 'SAGA/EVENT/DOWNLOAD/SELECTED/REPORTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_GENERATE_DRAFT_REPORT = 'SAGA/GENERATE/DRAFT/REPORT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOWNLOAD_REPORT = 'SAGA/DOWNLOAD/REPORT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTS_BY_CLIENT = 'SAGA/EVENTS/BY/CLIENT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_ADD_COVER_PHOTO = 'SAGA/EVENT/ADD/COVER/PHOTO';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_GENERATE_DOWNLOAD_AND_SUBMITE_REPORT = 'SAGA/GENERATE/DOWNLOAD/AND/SUBMITE/REPORT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_GENERATE_DOWNLOAD__REPORT = 'SAGA/GENERATE/DOWNLOAD/REPORT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_LAST_INSPECTION_INFO = 'SAGA/EVENT/LAST/INSPECTION/INFO';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_PHOTO_NOTES = 'SAGA/EVENT/PHOTO/NOTES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_PHOTO_SEND_EMAIL = 'SAGA/EVENT/PHOTO/SEND/EMAIL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_PHOTO_CLEAR_STRUCTURETYPES = 'SAGA/EVENT/PHOTO/CLEAR/STRUCTURETYPES';
/**
* @memberof ReduxActionTypes.Sagas
*/
export const SAGA_EVENT_PHOTO_ADD_STRUCTURETYPES_MULTIPLE_PHOTOS = 'SAGA/EVENT/PHOTO/ADD/STRUCTURETYPES/MULTIPLE/PHOTOS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_COMPLETE_MULTIPLE = 'SAGA/EVENT/COMPLETE/MULTIPLE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_PHOTO_ROTATE = 'SAGA/EVENT/PHOTO/ROTATE';

// bridge
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_ALL = 'SAGA/ASSET/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_GET = 'SAGA/ASSET/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_LEVEL_ONE_GET = 'SAGA/ASSET/LEVEL/ONE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETCHILD_GET = 'SAGA/ASSETCHILD/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETCHILD_EDIT = 'SAGA/ASSETCHILD/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_ASSETS = 'SAGA/ASSET/ASSETS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_CHILDREN = 'SAGA/ASSET/CHILDREN';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_EVENTS = 'SAGA/ASSET/EVENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_SEARCH = 'SAGA/ASSET/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_EDIT = 'SAGA/ASSET/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_ADD = 'SAGA/ASSET/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_MULTI_ADD = 'SAGA/ASSET/MULTI/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_DELETE = 'SAGA/ASSET/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_DELETE_CHECKED = 'SAGA/ASSET/DELETE_CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_BY_SUBDIVISIONS = 'SAGA/ASSET/BY/SUBDIVISIONS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_BY_RAILROADS = 'SAGA/ASSET/BY/RAILROADS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_ASSETEVENTS = 'SAGA/ASSET/ASSETEVENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_ALL_FIELDS = 'SAGA/ASSET/ALL/FIELDS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_ADD_ASSEMBLIY_TO_ASSET = 'SAGA/ADD/ASSEMBLY/TO/ASSET';
 /**
 * @memberof ReduxActionTypes.Sagas
 */
  export const SAGA_ADD_ASSEMBLIY_TO_ASSET_BY_ORDER = 'SAGA/ADD/ASSEMBLY/TO/ASSET/BY/ORDER';
   /**
 * @memberof ReduxActionTypes.Sagas
 */
    export const SAGA_ADD_SUBSTRUCTURES_TO_ASSET_BY_ORDER = 'SAGA/ADD/SUBSTRUCTURES/TO/ASSET/BY/ORDER';
   /**
 * @memberof ReduxActionTypes.Sagas
 */
    export const SAGA_DELETE_ASSEMBLIY_TO_ASSET_BY_ORDER = 'SAGA/DELETE/ASSEMBLY/TO/ASSET/BY/ORDER';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_BY_STRUCTURE_TYPE = 'SAGA/ASSET/BY/STRUCTURE_TYPE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_IMMEDIATE_ACTIONS = 'SAGA/ASSET/IMMEDIATE/ACTIONS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_FOR_TRIP = 'SAGA/ASSET/FOR/TRIP';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_FOR_TRIP_CALLBACK = 'SAGA/ASSET/FOR/TRIP/CALLBACK';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_FOR_FILTERS = 'SAGA/ASSET/FOR/FILTERS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_ADD_COVER_PHOTO = 'SAGA/ASSET/ADD/COVER/PHOTO';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_UPLOAD_PHOTO = 'SAGA/ASSET/UPLOAD/PHOTO';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_GET_ASSET_TYPES = "SAGA/ASSET/GET/ASSET/TYPES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_GET_CROSSING_FEATURES = "SAGA/ASSET/GET/CROSSING/FEATURES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_GET_DECK_TYPES = "SAGA/ASSET/GET/DECK/TYPES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_GET_WATER_FLOWS = "SAGA/ASSET/GET/WATER/FLOWS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_GET_ALIGNMENTS = "SAGA/ASSET/GET/ALIGNMENTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_GET_IMPACT_TYPES = "SAGA/ASSET/GET/IMPACT/TYPES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSET_LOAD_CLIENTS = "SAGA/ASSET/LOAD/CLIENTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_EVENT_UPLOAD_REPORT = "SAGA/EVENT/UPLOAD/REPORT";
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const  SAGA_EVENT_GET_UPLOADED_REPORT = "SAGA/EVENT/GET/UPLOADED/REPORT";
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const  SAGA_EVENT_UPDATE_MULTIPLE = "SAGA/EVENT/UPLOAD/MULTIPLE";
 /**
 * @memberof ReduxActionTypes.Sagas
 */
  export const  SAGA_EVENT_GET_IMPORT_DATA = "SAGA/EVENT/GET/IMPORT/DATA";
   /**
 * @memberof ReduxActionTypes.Sagas
 */
    export const  SAGA_EVENT_SAVE_IMPORTED_DATA = "SAGA/EVENT/SAVE/IMPORTED/DATA";

       /**
 * @memberof ReduxActionTypes.Sagas
 */
    export const  SAGA_EVENT_DELETE_MAPPED_DATA = "SAGA/EVENT/DELETE/MAPPED/DATA";

           /**
 * @memberof ReduxActionTypes.Sagas
 */
    export const  SAGA_EVENT_CANCEL_BRIDGE_IMPORT= "SAGA/EVENT/CANCEL/BRIDGE/IMPORT";

    
    
//assembly
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_ALL = 'SAGA/ASSEMBLY/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_GET = 'SAGA/ASSEMBLY/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_SEARCH = 'SAGA/ASSEMBLY/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_COMPONENTS = 'SAGA/ASSEMBLY/COMPONENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_EDIT = 'SAGA/ASSEMBLY/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_ADD = 'SAGA/ASSEMBLY/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_DELETE = 'SAGA/ASSEMBLY/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_COMPONENTS_SEARCH = 'SAGA/ASSEMBLY/COMPONENTS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_BY_SPANS = 'SAGA/ASSEMBLY/BY/SPANS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_EVENT = 'SAGA/ASSEMBLY/BY/EVENT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY = 'SAGA/ASSEMBLY';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_ALL_FIELDS = 'SAGA/ASSEMBLY/ALL/FIELDS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_ALL_BY_SECTION_STRUCTURETYPE = 'SAGA/ASSEMBLY/ALL/BY/SECTION/STRUCTURETYPE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_ALL_RECURSIVELY = 'SAGA/ASSEMBLY/ALL/RECURSIVELY';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_ADD_MULTI = 'SAGA/ASSEMBLY/ADD/MULTI';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_EDIT_MULTI = 'SAGA/ASSEMBLY/EDIT/MULTI';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLY_GET_STRUCTURE_TREE = 'SAGA/ASSEMBLY/GET/STRUCTURE/TREE';

//assemblyEvent
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_ALL = 'SAGA/ASSEMBLYEVENT/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_GET = 'SAGA/ASSEMBLYEVENT/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_SEARCH = 'SAGA/ASSEMBLYEVENT/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_EDIT = 'SAGA/ASSEMBLYEVENT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_ADD = 'SAGA/ASSEMBLYEVENT/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_DELETE = 'SAGA/ASSEMBLYEVENT/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_ALL_EVENT = 'SAGA/ASSEMBLYEVENT/ALL/EVENT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_ALL_BY_SECTIONEVENT_STRUCTURETYPE = 'SAGA/ASSEMBLYEVENT/ALL/BY/SECTIONEVENT/STRUCTURETYPE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_ALL_BY_SECTIONEVENT_STRUCTURETYPE_SUPER_REDUCED = 'SAGA/ASSEMBLYEVENT/ALL/BY/SECTIONEVENT/STRUCTURETYPE/SUPER/REDUCED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_ALL_RECURSIVELY = 'SAGA/ASSEMBLYEVENT/ALL/RECURSIVELY';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_GET_ALL_BY_EVENT_ID = 'SAGA/ASSEMBLYEVENT/GET/ALL/BY/EVENT/ID';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_ADD_MULTI = 'SAGA/ASSEMBLYEVENT/ADD/MULTI';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_GET_STRUCTURE_TREE = 'SAGA/ASSEMBLYEVENT/GET/STRUCTURE/TREE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_UPDATE_SINGLE_DYNAMIC_FIELD = "SAGA/ASSEMBLYEVENT/UPDATE/SINGLE/DYNAMIC/FIELD";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_CLEAR_STRUCTURE_TREE = 'SAGA/ASSEMBLYEVENT/CLEAR/STRUCTURE/TREE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_EDIT_MULTI = 'SAGA/ASSEMBLYEVENT/EDIT/MULTI';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_EDIT_MULTI_BUILD_PROCESS = 'SAGA/ASSEMBLYEVENT/EDIT/MULTI/BUILD/PROCESS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYEVENT_REDUCED = 'SAGA/ASSEMBLYEVENT/REDUCED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const DELETE_MULTIPLE_ASSEMBLYEVENT = 'DELETE/MULTIPLE/ASSEMBLYEVENT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ADD_MULTI_ASSEMBLIES = 'SAGA/ADD/MULTI/ASSEMBLIES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_ADD_SPAN = 'SAGA/ADD/SPAN';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ADD_MULTI_COMPONENTS = 'SAGA/ADD/MULTI/COMPONENTS'
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_ADD_SINGLE_COMPONENTS = 'SAGA/ADD/SINGLE/COMPONENTS'
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const UPDATE_ASSEMBLIES_ORDER = 'UPDATE/ASSEMBLIES/ORDER'

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const COPY_ASSEMBLY_COMPONENTS_ITEMS = 'COPY/ASSEMBLY/COMPONENTS/ITEMS'



//component
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_ALL = 'SAGA/COMPONENT/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const ADD_COMPONENT_TO_ASSEMBLY = 'ADD/COMPONENT/TO/ASSEMBLY';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const ADD_COMPONENT_TO_SUBSTRUCTURE = 'ADD/COMPONENT/TO/SUBSTRUCTURE';
 /**
 * @memberof ReduxActionTypes.Sagas
 */
  export const SAGA_DELETE_COMPONENS_TO_SUBSTRUCTURE = 'SAGA/DELETE/COMPONENS/TO/SUBSTRUCTURE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const UPDATE_COMPONENT_TO_ASSEMBLY = 'UPDATE/COMPONENT/TO/ASSEMBLY';
 /**
  * /**
 * @memberof ReduxActionTypes.Sagas
 */
 export const UPDATE_COMPONENT_TO_SUBSTRUCTURE = 'UPDATE/COMPONENT/TO/SUBSTRUCTURE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_REMOVE_COMPONENTS_FROM_ASSEMBLY = 'SAGA/REMOVE/COMPONENTS/FROM/ASSEMBLY';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_GET = 'SAGA/COMPONENT/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_SEARCH = 'SAGA/COMPONENT/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_ITEMS = 'SAGA/COMPONENT/ITEMS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_EDIT = 'SAGA/COMPONENT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_ADD = 'SAGA/COMPONENT/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_DELETE = 'SAGA/COMPONENT/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_ITEMS_SEARCH = 'SAGA/COMPONENT/ITEMS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_BY_ASSEMBLYS = 'SAGA/COMPONENT/BY/ASSEMBLYS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_BY_ASSEMBLYSEVENT = 'SAGA/COMPONENT/BY/ASSEMBLYSEVENT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENT_ALL_FIELDS = 'SAGA/COMPONENT/ALL/FIELDS';
//export const SAGA_COMPONENTEVENT_ALL = 'SAGA/COMPONENTEVENT/ALL';

//componentEvent
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTEVENT_ALL = 'SAGA/COMPONENTEVENT/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTEVENT_GET = 'SAGA/COMPONENTEVENT/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTEVENT_SEARCH = 'SAGA/COMPONENTEVENT/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTEVENT_EDIT = 'SAGA/COMPONENTEVENT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MULTIPLE_COMPONENTEVENT_EDIT = 'SAGA/MULTIPLE/COMPONENTEVENT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTEVENT_UPDATE_SINGLE_DYNAMIC_FIELD = "SAGA/COMPONENTEVENT/UPDATE/SINGLE/DYNAMIC/FIELD";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTEVENT_ADD = 'SAGA/COMPONENTEVENT/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTEVENT_DELETE = 'SAGA/COMPONENTEVENT/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTEVENT_DELETE_MULTIPLE = 'SAGA/COMPONENTEVENT/DELETE/MULTIPLE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTEVENT_ALL_EVENT = 'SAGA/COMPONENTEVENT/ALL/EVENT';


// assemblyType
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYTYPE_ALL = 'SAGA/ASSEMBLYTYPE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYTYPE_SEARCH = 'SAGA/ASSEMBLYTYPE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYTYPE_GET = 'SAGA/ASSEMBLYTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYTYPE_EDIT = 'SAGA/ASSEMBLYTYPE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYTYPE_ADD = 'SAGA/ASSEMBLYTYPE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYTYPE_DELETE = 'SAGA/ASSEMBLYTYPE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYTYPE_DELETE_CHECKED = 'SAGA/ASSEMBLYTYPE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSEMBLYTYPE_LOAD_ORGANIZATIONS = "SAGA/ASSEMBLYTYPE/LOAD/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_ASSEMBLYTYPES_BY_ORGANIZATION = "SAGA/ASSEMBLYTYPE/BY/ORGANIZATION";

 /**
 * @memberof ReduxActionTypes.Sagas
 */
  export const SAGA_ASSEMBLYTYPES_BY_STRUCTURE = "SAGA/ASSEMBLYTYPE/BY/STRUCTURE";

  /**
 * @memberof ReduxActionTypes.Sagas
 */
   export const SAGA_ASSEMBLYTYPES_APPROACHES_TYPES = "SAGA/ASSEMBLYTYPE/APPROACHES/TYPE";


// componentType
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_COMPONENTTYPE_BY_ORGANIZATION= 'SAGA/COMPONENTTYPE/BY/ORGANIZATION';

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_ALL = 'SAGA/COMPONENTTYPE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_SEARCH = 'SAGA/COMPONENTTYPE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_GET = 'SAGA/COMPONENTTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_EDIT = 'SAGA/COMPONENTTYPE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_ADD = 'SAGA/COMPONENTTYPE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_DELETE = 'SAGA/COMPONENTTYPE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_DELETE_CHECKED = 'SAGA/COMPONENTTYPE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_LOAD_ORGANIZATIONS = "SAGA/COMPONENTTYPE/LOAD/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_LOAD_ASSEMBLY_TYPES = "SAGA/COMPONENTTYPE/LOAD/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_LOAD_COMPONENT_GROUPS = "SAGA/COMPONENTTYPE/LOAD/COMPONENT/GROUPS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTTYPE_LOAD_FIELDS = "SAGA/COMPONENTTYPE/LOAD/FIELDS";

// componentGroup
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTGROUP_ALL = 'SAGA/COMPONENTGROUP/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTGROUP_SEARCH = 'SAGA/COMPONENTGROUP/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTGROUP_GET = 'SAGA/COMPONENTGROUP/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTGROUP_EDIT = 'SAGA/COMPONENTGROUP/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTGROUP_ADD = 'SAGA/COMPONENTGROUP/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTGROUP_DELETE = 'SAGA/COMPONENTGROUP/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTGROUP_DELETE_CHECKED = 'SAGA/COMPONENTGROUP/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_COMPONENTGROUP_LOAD_ORGANIZATIONS = "SAGA/COMPONENTGROUP/LOAD/ORGANIZATIONS";

// eventType
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTTYPE_ALL = 'SAGA/EVENTTYPE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTTYPE_SEARCH = 'SAGA/EVENTTYPE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTTYPE_GET = 'SAGA/EVENTTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTTYPE_EDIT = 'SAGA/EVENTTYPE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTTYPE_ADD = 'SAGA/EVENTTYPE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTTYPE_DELETE = 'SAGA/EVENTTYPE/DELETE';

// inspectionType
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_INSPECTIONTYPE_ALL = 'SAGA/INSPECTIONTYPE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_INSPECTIONTYPE_SEARCH = 'SAGA/INSPECTIONTYPE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_INSPECTIONTYPE_GET = 'SAGA/INSPECTIONTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_INSPECTIONTYPE_EDIT = 'SAGA/INSPECTIONTYPE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_INSPECTIONTYPE_ADD = 'SAGA/INSPECTIONTYPE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_INSPECTIONTYPE_DELETE = 'SAGA/INSPECTIONTYPE/DELETE';

// documentType
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENTTYPE_ALL = 'SAGA/DOCUMENTTYPE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENTTYPE_SEARCH = 'SAGA/DOCUMENTTYPE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENTTYPE_GET = 'SAGA/DOCUMENTTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENTTYPE_EDIT = 'SAGA/DOCUMENTTYPE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENTTYPE_ADD = 'SAGA/DOCUMENTTYPE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENTTYPE_DELETE = 'SAGA/DOCUMENTTYPE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENTTYPE_DELETE_CHECKED = 'SAGA/DOCUMENTTYPE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENTTYPE_LOAD_ORGANIZATIONS = "SAGA/DOCUMENTTYPE/LOAD/ORGANIZATIONS";



// assetEvent
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_ALL = 'SAGA/ASSETEVENT/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_GET = 'SAGA/ASSETEVENT/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_ASSETEVENT_GET_INSPECTORS = 'SAGA/ASSETEVENT/GET/INSPECTORS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_BY_ASSET_GET = 'SAGA/ASSETEVENT/ASSET/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_SEARCH = 'SAGA/ASSETEVENT/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_EDIT = 'SAGA/ASSETEVENT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_ADD = 'SAGA/ASSETEVENT/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_DELETE = 'SAGA/ASSETEVENT/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_SECTIONEVENTS = 'SAGA/ASSETEVENT/SECTIONEVENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_GET_BY_EVENT = 'SAGA/ASSETEVENT/GET/BY/EVENT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_BY_ASSET = 'SAGA/ASSETEVENT/BY/ASSET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETEVENT_LOAD_USERS_WORK_REPORTS = "SAGA/ASSETEVENT/LOAD/USERS/WORK/REPORTS";
/**
 * @memberof ReduxActionTypes.Common
 */
 export const SAGA_ASSETEVENT_LOAD_STRUCTURE_TYPES = "SAGA/ASSETEVENT/LOAD/STRUCTURE/TYPES";




// sectionEvent
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTIONEVENT_ALL = 'SAGA/SECTIONEVENT/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTIONEVENT_GET = 'SAGA/SECTIONEVENT/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTIONEVENT_SEARCH = 'SAGA/SECTIONEVENT/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTIONEVENT_EDIT = 'SAGA/SECTIONEVENT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTIONEVENT_ADD = 'SAGA/SECTIONEVENT/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SECTIONEVENT_DELETE = 'SAGA/SECTIONEVENT/DELETE';

//names
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_NAMES_UPDATE = 'SAGA/NAMES/UPDATE';

// message
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_ALL = 'SAGA/MESSAGE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_TYPES = 'SAGA/MESSAGE/TYPES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_EVENTS = 'SAGA/MESSAGE/EVENTS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_LOAD_ORGANIZATIONS = "SAGA/MESSAGE/LOAD/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_SEARCH = 'SAGA/MESSAGE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_GET = 'SAGA/MESSAGE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_EDIT = 'SAGA/MESSAGE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_ADD = 'SAGA/MESSAGE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_DELETE = 'SAGA/MESSAGE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_DELETE_CHECKED = 'SAGA/MESSAGE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_MESSAGEFIELDS = 'SAGA/MESSAGE/MESSAGEFIELDS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGE_MESSAGEFIELDS_SEARCH = 'SAGA/MESSAGE/MESSAGEFIELDS/SEARCH';

// message
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGEFIELD_ALL = 'SAGA/MESSAGEFIELD/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGEFIELD_SEARCH = 'SAGA/MESSAGEFIELD/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGEFIELD_GET = 'SAGA/MESSAGEFIELD/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGEFIELD_EDIT = 'SAGA/MESSAGEFIELD/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGEFIELD_ADD = 'SAGA/MESSAGEFIELD/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGEFIELD_DELETE = 'SAGA/MESSAGEFIELD/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGEFIELD_DELETE_CHECKED = "SAGA/MESSAGEFIELD/DELETE/CHECKED";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MESSAGEFIELD_BY_MESSAGES = 'SAGA/MESSAGEFIELD/BY/MESSAGES';

// rol
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_ALL = 'SAGA/ROLE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_SEARCH = 'SAGA/ROLE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_GET = 'SAGA/ROLE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_EDIT = 'SAGA/ROLE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_ADD = 'SAGA/ROLE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_DELETE = 'SAGA/ROLE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_DELETE_CHECKED = 'SAGA/ROLE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_ROLPAGES = 'SAGA/ROLE/ROLPAGES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_ROLPAGES_SEARCH = 'SAGA/ROLE/ROLPAGES/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_ALL_PARENT_ORGANIZATION = "SAGA/ROLE/ALL/PARENT/ORGANIZATION"
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_GET_ORGANIZATIONS = "SAGA/ROLE/GET/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLE_GET_CLAIMS = "SAGA/ROLE/GET/CLAIMS";


// rolPage
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLPAGE_ALL = 'SAGA/ROLPAGE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLPAGE_SEARCH = 'SAGA/ROLPAGE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLPAGE_GET = 'SAGA/ROLPAGE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLPAGE_EDIT = 'SAGA/ROLPAGE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLPAGE_ADD = 'SAGA/ROLPAGE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLPAGE_DELETE = 'SAGA/ROLPAGE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ROLPAGE_BY_ROLS = 'SAGA/ROLPAGE/BY/ROLS';

// claim
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLAIM_ALL = 'SAGA/CLAIM/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLAIM_SEARCH = 'SAGA/CLAIM/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLAIM_GET = 'SAGA/CLAIM/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLAIM_EDIT = 'SAGA/CLAIM/EDIT';


/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLAIM_ROLPAGES = 'SAGA/CLAIM/ROLPAGES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CLAIM_ROLPAGES_SEARCH = 'SAGA/CLAIM/ROLPAGES/SEARCH';

// structureType
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_STRUCTURETYPE_ALL = 'SAGA/STRUCTURETYPE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_STRUCTURETYPE_SEARCH = 'SAGA/STRUCTURETYPE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_STRUCTURETYPE_GET = 'SAGA/STRUCTURETYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SINGLESTRUCTURETYPE_GET = 'SAGA/SINGLESTRUCTURETYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_STRUCTURETYPE_EDIT = 'SAGA/STRUCTURETYPE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_STRUCTURETYPE_ADD = 'SAGA/STRUCTURETYPE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_STRUCTURETYPE_DELETE = 'SAGA/STRUCTURETYPE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_STRUCTURETYPE_DELETE_CHECKED = 'SAGA/STRUCTURETYPE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_STRUCTURETYPE_LOAD_ORGANIZATIONS = "SAGA/STRUCTURETYPE/LOAD/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_STRUCTURETYPE_LOAD_ASSET_TYPES = "SAGA/STRUCTURETYPE/LOAD/ASSET/TYPES";

// impactType
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_IMPACTTYPE_ALL = 'SAGA/IMPACTTYPE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_IMPACTTYPE_SEARCH = 'SAGA/IMPACTTYPE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_IMPACTTYPE_GET = 'SAGA/IMPACTTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_IMPACTTYPE_EDIT = 'SAGA/IMPACTTYPE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_IMPACTTYPE_ADD = 'SAGA/IMPACTTYPE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_IMPACTTYPE_DELETE = 'SAGA/IMPACTTYPE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_IMPACTTYPE_DELETE_CHECKED = 'SAGA/IMPACTTYPE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_IMPACTTYPE_LOAD_ORGANIZATIONS = "SAGA/IMPACTTYPE/LOAD/ORGANIZATIONS";



// assetType
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETTYPE_ALL = 'SAGA/ASSETTYPE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_ASSETTYPE_ALL_BY_CLIENT = 'SAGA/ASSETTYPE/ALL/BY/CLIENT';
 /**
 * @memberof ReduxActionTypes.Sagas
 */
  export const SAGA_ASSETTYPE_ALL_BY_ORGANIZATION = 'SAGA/ASSETTYPE/ALL/BY/ORGANIZATION';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETTYPE_SEARCH = 'SAGA/ASSETTYPE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETTYPE_GET = 'SAGA/ASSETTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETTYPE_EDIT = 'SAGA/ASSETTYPE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETTYPE_ADD = 'SAGA/ASSETTYPE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETTYPE_DELETE = 'SAGA/ASSETTYPE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETTYPE_BY_ASSET = 'SAGA/ASSETTYPE/BY/ASSET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETTYPE_DELETE_CHECKED = 'SAGA/ASSETTYPE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ASSETTYPE_LOAD_ORGANIZATIONS = "SAGA/ASSETTYPE/LOAD/ORGANIZATIONS";

// Crossing Feature Type
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CROSSINGFEATURE_ALL = 'SAGA/CROSSINGFEATURE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CROSSINGFEATURE_SEARCH = 'SAGA/CROSSINGFEATURE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CROSSINGFEATURE_GET = 'SAGA/CROSSINGFEATURE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CROSSINGFEATURE_EDIT = 'SAGA/CROSSINGFEATURE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CROSSINGFEATURE_ADD = 'SAGA/CROSSINGFEATURE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CROSSINGFEATURE_DELETE = 'SAGA/CROSSINGFEATURE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CROSSINGFEATURE_DELETE_CHECKED = 'SAGA/CROSSINGFEATURE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CROSSINGFEATURE_LOAD_ORGANIZATIONS = "SAGA/CROSSINGFEATURE/LOAD/ORGANIZATIONS";

// page
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_PAGE_ALL = 'SAGA/PAGE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_PAGE_SEARCH = 'SAGA/PAGE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_PAGE_GET = 'SAGA/PAGE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_PAGE_EDIT = 'SAGA/PAGE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_PAGE_ADD = 'SAGA/PAGE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_PAGE_DELETE = 'SAGA/PAGE/DELETE';

//trip
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_ALL = 'SAGA/TRIP/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_SEARCH = 'SAGA/TRIP/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_GET = 'SAGA/TRIP/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_GET_CALLBACK = 'SAGA/TRIP/GET/CALLBACK';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_LOAD_ASSETS = 'SAGA/TRIP/LOAD/ASSETS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_LOAD_ASSETS_CALLBACK = 'SAGA/TRIP/LOAD/ASSETS/CALLBACK';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_LOAD_PHOTOS = 'SAGA/TRIP/LOAD/PHOTOS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_ADD = 'SAGA/TRIP/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_DELETE = 'SAGA/TRIP/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_DELETE_CHECKED = 'SAGA/TRIP/DELETE_CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_ADD_FIRST_SAVE = 'SAGA/TRIP/ADD/FIRST/SAVE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_ADD_SECOND_SAVE = 'SAGA/TRIP/ADD/SECOND/SAVE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_EDIT_FIRST_SAVE = 'SAGA/TRIP/EDIT/FIRST/SAVE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_EDIT_SECOND_SAVE = 'SAGA/TRIP/EDIT/SECOND/SAVE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_COMPLETE = 'SAGA/TRIP/COMPLETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_UPLOAD_PHOTOS = 'SAGA/TRIP/UPLOAD/PHOTOS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_TRIP_DELETE_ASSETEVENT = 'SAGA/TRIP/DELETE/ASSETEVENT';


// contact
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_ALL = 'SAGA/CONTACT/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_SEARCH = 'SAGA/CONTACT/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_GET = 'SAGA/CONTACT/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_EDIT = 'SAGA/CONTACT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_ADD = 'SAGA/CONTACT/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_DELETE = 'SAGA/CONTACT/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_DELETE_CHECKED = 'SAGA/CONTACT/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_BY_CLIENT_ID = 'SAGA/CONTACT/BY/CLIENT/ID';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_SEARCH_FILTER = 'SAGA/CONTACT/SEARCH/FILTER';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_SEARCH_PAGE = 'SAGA/CONTACT/SEARCH/PAGE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_ALL_OF_CLIENT = "SAGA/CONTACT/ALL/OF/CLIENT";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_GET_ORGANIZATIONS = "SAGA/CONTACT/GET/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_GET_CLIENTS = "SAGA/CONTACT/GET/CLIENTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONTACT_GET_JOB_TITLES = "SAGA/CONTACT/GET/JOB/TITLES";





//organization
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ORGANIZATION_ALL = 'SAGA/ORGANIZATION/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ORGANIZATION_TYPES = 'SAGA/ORGANIZATION/TYPES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ORGANIZATION_SEARCH = 'SAGA/ORGANIZATION/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ORGANIZATION_GET = 'SAGA/ORGANIZATION/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ORGANIZATION_DELETE_CHECKED = 'SAGA/ORGANIZATION/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ORGANIZATION_EDIT = "SAGA/ORGANIZATION/EDIT";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ORGANIZATION_ADD = "SAGA/ORGANIZATION/ADD";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ORGANIZATION_CURRENT_USER = "SGA/ORGANIZATION/CURRENT/USER";

//malepost

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MILEPOST_ALL = 'PROPS/MILEPOST/ALL';

//event recommendation
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_ALL = 'PROPS/EVENTRECOMMENDATION/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_GET = 'PROPS/EVENTRECOMMENDATION/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_GET_BY_ID = 'PROPS/EVENTRECOMMENDATION/GET/BY/ID';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_SEARCH = 'SAGA/EVENTRECOMMENDATION/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_EDIT = 'SAGA/EVENTRECOMMENDATION/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_ADD = 'SAGA/EVENTRECOMMENDATION/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_PREV_TO_NEW = 'SAGA/EVENTRECOMMENDATION/PREV/TO/NEW';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_DELETE = 'SAGA/EVENTRECOMMENDATION/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_STATS_BY_CLIENT = 'SAGA/EVENTRECOMMENDATION/STATS/BY/CLIENT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_STATS_BY_CLIENT_PAGED = 'SAGA/EVENTRECOMMENDATION/STATS/BY/CLIENT/PAGED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_ESTIMATE_EDIT = 'SAGA/EVENTRECOMMENDATION/ESTIMATE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_EVENTRECOMMENDATION_PRIORITIES_GET_ALL = 'SAGA/EVENTRECOMMENDATION/PRIORITIES/GET/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTRECOMMENDATION_LAST_REFRESH_VIEW = 'SAGA/EVENTRECOMMENDATION/LAST/REFRESH/VIEW';

//event work performed
export const SAGA_WORKPERFORMED_ALL = 'PROPS/WORKPERFORMED/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WORKPERFORMED_GET = 'PROPS/WORKPERFORMED/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WORKPERFORMED_GET_BY_ID = 'PROPS/WORKPERFORMED/GET/BY/ID';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WORKPERFORMED_SEARCH = 'SAGA/WORKPERFORMED/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WORKPERFORMED_EDIT = 'SAGA/WORKPERFORMED/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WORKPERFORMED_ADD = 'SAGA/WORKPERFORMED/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WORKPERFORMED_DELETE = 'SAGA/WORKPERFORMED/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WORKPERFORMED_PHOTO = 'SAGA/WORKPERFORMED/PHOTO';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WORKPERFORMED_PHOTO_REMOVE = 'SAGA/WORKPERFORMED/PHOTO/REMOVE';

// accessType
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ACCESSTYPE_ALL = 'SAGA/ACCESSTYPE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ACCESSTYPE_SEARCH = 'SAGA/ACCESSTYPE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ACCESSTYPE_GET = 'SAGA/ACCESSTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ACCESSTYPE_EDIT = 'SAGA/ACCESSTYPE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ACCESSTYPE_ADD = 'SAGA/ACCESSTYPE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ACCESSTYPE_DELETE = 'SAGA/ACCESSTYPE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ACCESSTYPE_DELETE_CHECKED = 'SAGA/ACCESSTYPE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ACCESSTYPE_LOAD_ORGANIZATIONS = "SAGA/ACCESSTYPE/LOAD/ORGANIZATIONS";

// JOBTITLE
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_JOBTITLE_ALL = 'SAGA/JOBTITLE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_JOBTITLE_SEARCH = 'SAGA/JOBTITLE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_JOBTITLE_GET = 'SAGA/JOBTITLE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_JOBTITLE_EDIT = 'SAGA/JOBTITLE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_JOBTITLE_ADD = 'SAGA/JOBTITLE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_JOBTITLE_DELETE = 'SAGA/JOBTITLE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_JOBTITLE_DELETE_CHECKED = 'SAGA/JOBTITLE/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_JOBTITLE_LOAD_ORGANIZATIONS = "SAGA/JOBTITLE/LOAD/ORGANIZATIONS";

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const CHANGE_JOBTITLE_START_LOADING_ORGANIZATIONS = "CHANGE/JOBTITLE/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const CHANGE_JOBTITLE_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/JOBTITLE/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const CHANGE_JOBTITLE_ERROR_LOADING_ORGANIZATIONS = "CHANGE/JOBTITLE/ERROR/LOADING/ORGANIZATIONS";



//event condition
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_ALL = 'PROPS/EVENTCONDITION/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_GET = 'PROPS/EVENTCONDITION/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_GET_BY_ID = 'PROPS/EVENTCONDITION/GET/BY/ID';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_SEARCH = 'SAGA/EVENTCONDITION/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_EDIT = 'SAGA/EVENTCONDITION/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_ADD = 'SAGA/EVENTCONDITION/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_DELETE = 'SAGA/EVENTCONDITION/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_PREV_TO_NEW = 'SAGA/EVENTCONDITION/PREV/TO/NEW';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_UNCHANGED = 'SAGA/EVENTCONDITION/UNCHANGED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_SOUND = 'SAGA/EVENTCONDITION/SOUND';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_ADD_MULTI_ITEMS = 'SAGA/EVENTCONDITION/ADD/MULTI/ITEMS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_PHOTO = 'SAGA/EVENTCONDITION/PHOTO';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_PHOTO_REMOVE = 'SAGA/EVENTCONDITION/PHOTO/REMOVE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_DELETE_ARRAY = 'SAGA/EVENTCONDITION/DELETE/ARRAY';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_GET_PREVIOUS_BY_ID = 'SAGA/EVENTCONDITION/GET/PREVIOUS/BY/ID';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTCONDITION_SOLVED = 'SAGA/EVENTCONDITION/SOLVED';

//event note
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_ALL = 'PROPS/EVENTNOTE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_GET = 'PROPS/EVENTNOTE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_GET_BY_ID = 'PROPS/EVENTNOTE/GET/BY/ID';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_SEARCH = 'SAGA/EVENTNOTE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_EDIT = 'SAGA/EVENTNOTE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_ADD = 'SAGA/EVENTNOTE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_ADD_PHOTOS = 'SAGA/EVENTNOTE/ADD/PHOTOS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_DELETE = 'SAGA/EVENTNOTE/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_UNCHANGED = 'SAGA/EVENTNOTE/UNCHANGED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_SOUND = 'SAGA/EVENTNOTE/SOUND';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_GET_PREVIOUS_BY_ID = 'SAGA/EVENTNOTE/GET/PREVIOUS/BY/ID';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_PREV_TO_NEW = 'SAGA/EVENTNOTE/PREV/TO/NEW';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENTNOTE_DELETE_ARRAY = 'SAGA/EVENTNOTE/DELETE/ARRAY';

// condition
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_ALL = 'SAGA/CONDITION/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_SEARCH = 'SAGA/CONDITION/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_GET = 'SAGA/CONDITION/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_COMPONENTTYPE_GET = 'SAGA/CONDITION/COMPONENTTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_ASSEMBLYTYPE_GET = 'SAGA/CONDITION/ASSEMBLYTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_EDIT = 'SAGA/CONDITION/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_ADD = 'SAGA/CONDITION/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_DELETE = 'SAGA/CONDITION/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_DELETE_CHECKED = 'SAGA/CONDITION/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_LOAD_ORGANIZATIONS = "SAGA/CONDITION/LOAD/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_LOAD_ASSEMBLY_TYPES = "SAGA/CONDITION/LOAD/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_CONDITION_LOAD_COMPONENT_TYPES = "SAGA/CONDITION/LOAD/COMPONENT/TYPES";



//location
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_ALL = 'SAGA/LOCATION/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_SEARCH = 'SAGA/LOCATION/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_GET = 'SAGA/LOCATION/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_COMPONENTTYPE_GET = 'SAGA/LOCATION/COMPONENTTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_ASSEMBLYTYPE_GET = 'SAGA/LOCATION/ASSEMBLYTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_EDIT = 'SAGA/LOCATION/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_ADD = 'SAGA/LOCATION/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_DELETE = 'SAGA/LOCATION/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_DELETE_CHECKED = 'SAGA/LOCATION/DELETE/CHECKED';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_LOAD_ORGANIZATIONS = "SAGA/LOCATION/LOAD/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_LOAD_ASSEMBLY_TYPES = "SAGA/LOCATION/LOAD/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_LOCATION_LOAD_COMPONENT_TYPES = "SAGA/LOCATION/LOAD/COMPONENT/TYPES";


// images
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSETEVENT_UPLOAD = "SAGA/FILE/ASSETEVENT/UPLOAD";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_DOCUMENTS = "SAGA/FILE/ASSET/DOCUMENTS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_UPLOAD = "SAGA/FILE/ASSET/UPLOAD";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_PHOTOS = "SAGA/FILE/ASSET/PHOTOS";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_PHOTOTAG = "SAGA/FILE/ASSET/PHOTOTAG";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_DELETE = "SAGA/FILE/ASSET/DELETE";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_PHOTO_TAG_DELETE = "SAGA/PHOTO/TAG/DELETE";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_PHOTOS_PAGED = "SAGA/FILE/ASSET/PHOTOS/PAGED";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_BRIDGE_PHOTOS_PAGED = "SAGA/FILE/BRIDGE/PHOTOS/PAGED";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_GET_PHOTO_BY_ID = "SAGA/FILE/ASSET/GET/PHOTO/BY/ID";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_ADD_PHOTO_INFO = "SAGA/FILE/ASSET/ADD/PHOTO/INFO";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_EDIT_PHOTO_INFO = "SAGA/FILE/ASSET/EDIT/PHOTO/INFO";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_DELETE_PHOTO_INFO = "SAGA/FILE/ASSET/DELETE/PHOTO/INFO";
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_FILE_ASSET_GET_PHOTO = "SAGA/FILE/ASSET/GET/PHOTO";


// milestone
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MILESTONE_ALL = 'SAGA/MILESTONE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MILESTONE_BY_EVENTTYPE = 'SAGA/MILESTONE/BY/EVENTTYPE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MILESTONE_BY_MILESTONESTATUS = 'SAGA/MILESTONE/BY/MILESTONESTATUS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MILESTONE_BY_EVENT = 'SAGA/MILESTONE/BY/EVENT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MILESTONE_STATUS_ALL = 'SAGA/MILESTONE/STATUS/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_MILESTONE_STATUS_ALL_BY_NAME = 'SAGA/MILESTONE/STATUS/ALL/BY/NAME';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_EVENT_MILESTONE_STATUS = 'SAGA/EVENT/MILESTONE/STATUS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
 export const SAGA_ALL_EVENT_MILESTONE_STATUSES = 'SAGA/ALL/EVENT/MILESTONE/STATUSES';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_PUT_EVENT_MILESTONE_STATUS = 'SAGA/PUT/EVENT/MILESTONE/STATUS';

// directionalLoading
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DIRECTIONALLOADING_ALL = 'SAGA/DIRECTIONALLOADING/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DIRECTIONALLOADING_SEARCH = 'SAGA/DIRECTIONALLOADING/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DIRECTIONALLOADING_GET = 'SAGA/DIRECTIONALLOADING/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DIRECTIONALLOADING_EDIT = 'SAGA/DIRECTIONALLOADING/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DIRECTIONALLOADING_ADD = 'SAGA/DIRECTIONALLOADING/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DIRECTIONALLOADING_DELETE = 'SAGA/DIRECTIONALLOADING/DELETE';

// waterFlow
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WATERFLOW_ALL = 'SAGA/WATERFLOW/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WATERFLOW_SEARCH = 'SAGA/WATERFLOW/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WATERFLOW_GET = 'SAGA/WATERFLOW/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WATERFLOW_EDIT = 'SAGA/WATERFLOW/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WATERFLOW_ADD = 'SAGA/WATERFLOW/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WATERFLOW_DELETE = 'SAGA/WATERFLOW/DELETE';

// alignment
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ALIGNMENT_ALL = 'SAGA/ALIGNMENT/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ALIGNMENT_SEARCH = 'SAGA/ALIGNMENT/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ALIGNMENT_GET = 'SAGA/ALIGNMENT/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ALIGNMENT_EDIT = 'SAGA/ALIGNMENT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ALIGNMENT_ADD = 'SAGA/ALIGNMENT/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_ALIGNMENT_DELETE = 'SAGA/ALIGNMENT/DELETE';

// deckType
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DECKTYPE_ALL = 'SAGA/DECKTYPE/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DECKTYPE_SEARCH = 'SAGA/DECKTYPE/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DECKTYPE_GET = 'SAGA/DECKTYPE/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DECKTYPE_EDIT = 'SAGA/DECKTYPE/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DECKTYPE_ADD = 'SAGA/DECKTYPE/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DECKTYPE_DELETE = 'SAGA/DECKTYPE/DELETE';

// document
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENT_ALL = 'SAGA/DOCUMENT/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENT_GET = 'SAGA/DOCUMENT/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENT_SEARCH = 'SAGA/DOCUMENT/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENT_EDIT = 'SAGA/DOCUMENT/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENT_ADD = 'SAGA/DOCUMENT/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENT_DELETE = 'SAGA/DOCUMENT/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_DOCUMENT_DELETE_LIST = 'SAGA/DOCUMENT/DELETE/LIST';


/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SET_MENU = 'SAGA/SET/MENU';

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_USER_CLAIMS = 'SAGA/USER/CLAIMS';


/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WEBSOCKET_START_SESSION = "SAGA/WEBSOCKET/START/SESSION";

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WEBSOCKET_END_SESSION = "SAGA/WEBSOCKET/END/SESSION";

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WEBSOCKET_SUBSCRIBE_TO_CHANNEL = "SAGA/WEBSOCKET/SUBSCRIBE/TO/CHANNEL";

/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_WEBSOCKET_REMOVE_ALL_SUBSCRIPTIONS = "SAGA/WEBSOCKET/REMOVE/ALL/SUBSCRIPTIONS";

/************************************************************************
 *
 *
 *
 * END OF SAGA ACTIONS
 *
 *
 *
 *************************************************************************/



/*
 * Reducer Action types
 */

/**
 * @memberof ReduxActionTypes.Common
 */
export const RESET_AUTH_PROPS = 'PROPS/AUTH/RESET';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_AUTH_PROPS = 'PROPS/AUTH/CHANGE';

/**
 * Whenever a new user logs in. Not the same that was logged.
 */
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_AUTH_USER_PROPS = 'PROPS/AUTH/CHANGE/USER';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_PROPS = 'PROPS/USER/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_USER_PROPS = "CLEAR/USER/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_USER_ERRORS = "CLEAR/USER/ERRORS";
/**
 * @memberof ReduxActionTypes.Common
 */
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_START_LOADING_ORGANIZATIONS = "CHANGE/USER/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/USER/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_ERROR_LOADING_ORGANIZATIONS = "CHANGE/USER/ERROR/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_START_LOADING_CLIENTS = "CHANGE/USER/START/LOADING/CLIENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_SUCCESS_LOADING_CLIENTS = "CHANGE/USER/SUCCESS/LOADING/CLIENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_ERROR_LOADING_CLIENTS = "CHANGE/USER/ERROR/LOADING/CLIENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_START_LOADING_CONTACTS = "CHANGE/USER/START/LOADING/CONTACTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_SUCCESS_LOADING_CONTACTS = "CHANGE/USER/SUCCESS/LOADING/CONTACTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_ERROR_LOADING_CONTACTS = "CHANGE/USER/ERROR/LOADING/CONTACTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_START_LOADING_ROLES = "CHANGE/USER/START/LOADING/ROLES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_SUCCESS_LOADING_ROLES = "CHANGE/USER/SUCCESS/LOADING/ROLES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_ERROR_LOADING_ROLES = "CHANGE/USER/ERROR/LOADING/ROLES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_START_LOADING_JOB_TITLES = "CHANGE/USER/START/LOADING/JOB/TITLES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_SUCCESS_LOADING_JOB_TITLES = "CHANGE/USER/SUCCESS/LOADING/JOB/TITLES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_USER_ERROR_LOADING_JOB_TITLES = "CHANGE/USER/ERROR/LOADING/JOB/TITLES";


/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EMAIL_PROPS = 'PROPS/MSG/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_INTEREST_PROPS = 'PROPS/INTEREST/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_BRIDGE_PROPS = 'PROPS/BRIDGE/CHANGE';



/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_PROPS = 'PROPS/CLIENT/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_START_LOADING_CONTACTS_PAGED = "CHANGE/CLIENT/START/LOADING/CONTACTS/PAGED";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_SUCCESS_LOADING_CONTACTS_PAGED = "CHANGE/CLIENT/SUCCESS/LOADING/CONTACTS/PAGED";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_ERROR_LOADING_CONTACTS_PAGED = "CHANGE/CLIENT/ERROR/LOADING/CONTACTS/PAGED";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_START_LOADING_DOCUMENTS = "CHANGE/CLIENT/START/LOADING/DOCUMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_SUCCESS_LOADING_DOCUMENTS = "CHANGE/CLIENT/SUCCESS/LOADING/DOCUMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_ERROR_LOADING_DOCUMENTS = "CHANGE/CLIENT/ERROR/LOADING/DOCUMENTS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_START_LOADING_DOCUMENT_TYPES = "CHANGE/CLIENT/START/LOADING/DOCUMENT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_SUCCESS_LOADING_DOCUMENT_TYPES = "CHANGE/CLIENT/SUCCESS/LOADING/DOCUMENT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_ERROR_LOADING_DOCUMENT_TYPES = "CHANGE/CLIENT/ERROR/LOADING/DOCUMENT/TYPES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_START_LOADING_JOB_TITLES = "CHANGE/CLIENT/START/LOADING/JOB/TITLES";

//span
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SPAN_ALL = 'SAGA/SPAN/ALL';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SPAN_GET = 'SAGA/SPAN/GET';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SPAN_SEARCH = 'SAGA/SPAN/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SPAN_ASSEMBLYS = 'SAGA/SPAN/ASSEMBLYS';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SPAN_EDIT = 'SAGA/SPAN/EDIT';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SPAN_ADD = 'SAGA/SPAN/ADD';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SPAN_DELETE = 'SAGA/SPAN/DELETE';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SPAN_ASSEMBLYS_SEARCH = 'SAGA/SPAN/ASSEMBLYS/SEARCH';
/**
 * @memberof ReduxActionTypes.Sagas
 */
export const SAGA_SPAN_BY_SECTIONS = 'SAGA/SPAN/BY/SECTIONS';

/************************************************************************
 *
 *
 *
 * END OF SAGA ACTIONS
 *
 *
 *
 *************************************************************************/



/*
 * Reducer Action types
 */

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_SUCCESS_LOADING_JOB_TITLES = "CHANGE/CLIENT/SUCCESS/LOADING/JOB/TITLES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CLIENT_ERROR_LOADING_JOB_TITLES = "CHANGE/CLIENT/ERROR/LOADING/JOB/TITLES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_START_LOADING_ORGANIZATIONS = "CHANGE/CLIENT/START/LOADING/ORGANIZATIONS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CLIENT_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/CLIENT/SUCCESS/LOADING/ORGANIZATIONS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CLIENT_ERROR_LOADING_ORGANIZATIONS = "CHANGE/CLIENT/ERROR/LOADING/ORGANIZATIONS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_START_LOADING_CLIENTS = "CHANGE/CLIENT/START/LOADING/CLIENTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CLIENT_SUCCESS_LOADING_CLIENTS = "CHANGE/CLIENT/SUCCESS/LOADING/CLIENTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CLIENT_ERROR_LOADING_CLIENTS = "CHANGE/CLIENT/ERROR/LOADING/CLIENTS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLIENT_START_LOADING_CONTACTS = "CHANGE/CLIENT/START/LOADING/CONTACTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CLIENT_SUCCESS_LOADING_CONTACTS = "CHANGE/CLIENT/SUCCESS/LOADING/CONTACTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CLIENT_ERROR_LOADING_CONTACTS = "CHANGE/CLIENT/ERROR/LOADING/CONTACTS";






/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_START_LOADING_DOCUMENT_TYPES = "CHANGE/RAILROAD/START/LOADING/DOCUMENT/TYPES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_RAILROAD_SUCCESS_LOADING_DOCUMENT_TYPES = "CHANGE/RAILROAD/SUCCESS/LOADING/DOCUMENT/TYPES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_RAILROAD_ERROR_LOADING_DOCUMENT_TYPES = "CHANGE/RAILROAD/ERROR/LOADING/DOCUMENT/TYPES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_START_LOADING_JOB_TITLES = "CHANGE/RAILROAD/START/LOADING/JOB/TITLES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_RAILROAD_SUCCESS_LOADING_JOB_TITLES = "CHANGE/RAILROAD/SUCCESS/LOADING/JOB/TITLES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_RAILROAD_ERROR_LOADING_JOB_TITLES = "CHANGE/RAILROAD/ERROR/LOADING/JOB/TITLES";


/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_START_LOADING_ORGANIZATIONS = "CHANGE/RAILROAD/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/RAILROAD/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_ERROR_LOADING_ORGANIZATIONS = "CHANGE/RAILROAD/ERROR/LOADING/ORGANIZATIONS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_START_LOADING_CLIENTS = "CHANGE/RAILROAD/START/LOADING/CLIENTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_RAILROAD_SUCCESS_LOADING_CLIENTS = "CHANGE/RAILROAD/SUCCESS/LOADING/CLIENTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_RAILROAD_ERROR_LOADING_CLIENTS = "CHANGE/RAILROAD/ERROR/LOADING/CLIENTS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_START_LOADING_CONTACTS_PAGED = "CHANGE/RAILROAD/START/LOADING/CONTACTS/PAGED";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_RAILROAD_SUCCESS_LOADING_CONTACTS_PAGED = "CHANGE/RAILROAD/SUCCESS/LOADING/CONTACTS/PAGED";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_RAILROAD_ERROR_LOADING_CONTACTS_PAGED = "CHANGE/RAILROAD/ERROR/LOADING/CONTACTS/PAGED";



/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_PROPS = 'PROPS/RAILROAD/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 * @description A railroad has been added or edited.
 */
export const CHANGE_RAILROAD = "CHANGE/RAILROAD/ADDED";
/**
 * @memberof ReduxActionTypes.Common
 * @description A collection of railroads have been removed.
 */
export const CHANGE_RAILROAD_REMOVED = "CHANGE/RAILROAD/REMOVED";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_START_LOADING_CONTACTS = "CHANGE/RAILROAD/START/LOADING/CONTACTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_SUCCESS_LOADING_CONTACTS = "CHANGE/RAILROAD/SUCCESS/LOADING/CONTACTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_ERROR_LOADING_CONTACTS = "CHANGE/RAILROAD/ERROR/LOADING/CONTACTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_START_LOADING_DOCUMENTS = "CHANGE/RAILROAD/START/LOADING/DOCUMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_SUCCESS_LOADING_DOCUMENTS = "CHANGE/RAILROAD/SUCCESS/LOADING/DOCUMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_RAILROAD_ERROR_LOADING_DOCUMENTS = "CHANGE/RAILROAD/ERROR/LOADING/DOCUMENTS";





/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_PROPS = 'PROPS/SUBDIVISION/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION = "CHANGE/SUBDIVISION";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_REMOVED = "CHANGE/SUBDIVISION/REMOVED";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_START_LOADING_CONTACTS_PAGED = "CHANGE/SUBDIVISION/START/LOADING/CONTACTS/PAGED";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_SUBDIVISION_SUCCESS_LOADING_CONTACTS_PAGED = "CHANGE/SUBDIVISION/SUCCESS/LOADING/CONTACTS/PAGED";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_SUBDIVISION_ERROR_LOADING_CONTACTS_PAGED = "CHANGE/SUBDIVISION/ERROR/LOADING/CONTACTS/PAGED";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_SUBDIVISION_START_LOADING_DOCUMENTS = "CHANGE/SUBDIVISION/START/LOADING/DOCUMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_SUCCESS_LOADING_DOCUMENTS = "CHANGE/SUBDIVISION/SUCCESS/LOADING/DOCUMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_ERROR_LOADING_DOCUMENTS = "CHANGE/SUBDIVISION/ERROR/LOADING/DOCUMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_START_LOADING_DOCUMENT_TYPES = "CHANGE/SUBDIVISION/START/LOADING/DOCUMENT/TYPES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_SUBDIVISION_SUCCESS_LOADING_DOCUMENT_TYPES = "CHANGE/SUBDIVISION/SUCCESS/LOADING/DOCUMENT/TYPES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_SUBDIVISION_ERROR_LOADING_DOCUMENT_TYPES = "CHANGE/SUBDIVISION/ERROR/LOADING/DOCUMENT/TYPES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_START_LOADING_ORGANIZATIONS = "CHANGE/SUBDIVISION/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/SUBDIVISION/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_ERROR_LOADING_ORGANIZATIONS = "CHANGE/SUBDIVISION/ERROR/LOADING/ORGANIZATIONS";


/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_START_LOADING_CLIENTS = "CHANGE/SUBDIVISION/START/LOADING/CLIENTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_SUBDIVISION_SUCCESS_LOADING_CLIENTS = "CHANGE/SUBDIVISION/SUCCESS/LOADING/CLIENTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_SUBDIVISION_ERROR_LOADING_CLIENTS = "CHANGE/SUBDIVISION/ERROR/LOADING/CLIENTS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_START_LOADING_CONTACTS = "CHANGE/SUBDIVISION/START/LOADING/CONTACTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_SUCCESS_LOADING_CONTACTS = "CHANGE/SUBDIVISION/SUCCESS/LOADING/CONTACTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_ERROR_LOADING_CONTACTS = "CHANGE/SUBDIVISION/ERROR/LOADING/CONTACTS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SUBDIVISION_START_LOADING_JOB_TITLES = "CHANGE/SUBDIVISION/START/LOADING/JOB/TITLES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_SUBDIVISION_SUCCESS_LOADING_JOB_TITLES = "CHANGE/SUBDIVISION/SUCCESS/LOADING/JOB/TITLES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_SUBDIVISION_ERROR_LOADING_JOB_TITLES = "CHANGE/SUBDIVISION/ERROR/LOADING/JOB/TITLES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SECTION_PROPS = 'PROPS/SECTION/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_PROPS = 'PROPS/EVENT/CHANGE';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_START_LOADING_INSPECTORS = 'PROPS/ASSETEVENT/START/LOADING/INSPECTORS';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_SUCCESS_LOADING_INSPECTORS = 'PROPS/ASSETEVENT/SUCCESS/LOADING/INSPECTORS';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_ERROR_LOADING_INSPECTORS = 'PROPS/ASSETEVENT/ERROR/LOADING/INSPECTORS';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_COMPONENT_TYPES = 'PROPS/EVENT/START/LOADING/COMPONENT/TYPES';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_COMPONENT_TYPES = 'PROPS/EVENT/SUCCESS/LOADING/COMPONENT/TYPES';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_COMPONENT_TYPES = 'PROPS/EVENT/ERROR/LOADING/COMPONENT/TYPES';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_ASSEMBLY_TYPES = 'PROPS/EVENT/START/LOADING/ASSEMBLY/TYPES';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_ASSEMBLY_TYPES = 'PROPS/EVENT/SUCCESS/LOADING/ASSEMBLY/TYPES';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_ASSEMBLY_TYPES = 'PROPS/EVENT/ERROR/LOADING/ASSEMBLY/TYPES';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_ACCESS_TYPES = 'PROPS/EVENT/START/LOADING/ACCESS/TYPES';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_ACCESS_TYPES = 'PROPS/EVENT/SUCCESS/LOADING/ACCESS/TYPES';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_ACCESS_TYPES = 'PROPS/EVENT/ERROR/LOADING/ACCESS/TYPES';


/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_ITEM_TYPES = 'PROPS/EVENT/START/LOADING/ITEM/TYPES';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_ITEM_TYPES = 'PROPS/EVENT/SUCCESS/LOADING/ITEM/TYPES';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_ITEM_TYPES = 'PROPS/EVENT/ERROR/LOADING/ITEM/TYPES';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_CROSSING_FEATURES = "CHANGE/EVENT/START/LOADING/CROSSING/FEATURES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_CROSSING_FEATURES = "CHANGE/EVENT/SUCCESS/LOADING/CROSSING/FEATURES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_CROSSING_FEATURES = "CHANGE/EVENT/ERROR/LOADING/CROSSING/FEATURES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_DECK_TYPES = "CHANGE/EVENT/START/LOADING/DECK/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_DECK_TYPES = "CHANGE/EVENT/SUCCESS/LOADING/DECK/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_DECK_TYPES = "CHANGE/EVENT/ERROR/LOADING/DECK/TYPES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_WATER_FLOWS = "CHANGE/EVENT/START/LOADING/WATER/FLOWS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_WATER_FLOWS = "CHANGE/EVENT/SUCCESS/LOADING/WATER/FLOWS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_WATER_FLOWS = "CHANGE/EVENT/ERROR/LOADING/WATER/FLOWS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_IMPACT_TYPES = "CHANGE/EVENT/START/LOADING/IMPACT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_IMPACT_TYPES = "CHANGE/EVENT/SUCCESS/LOADING/IMPACT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_IMPACT_TYPES = "CHANGE/EVENT/ERROR/LOADING/IMPACT/TYPES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_ALIGNMENTS = "CHANGE/EVENT/START/LOADING/ALIGNMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_ALIGNMENTS = "CHANGE/EVENT/SUCCESS/LOADING/ALIGNMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_ALIGNMENTS = "CHANGE/EVENT/ERROR/LOADING/ALIGNMENTS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_ASSET_TYPES = "CHANGE/EVENT/START/LOADING/ASSET/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_ASSET_TYPES = "CHANGE/EVENT/SUCCESS/LOADING/ASSET/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_ASSET_TYPES = "CHANGE/EVENT/ERROR/LOADING/ASSET/TYPES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_CLIENTS = "CHANGE/EVENT/START/LOADING/CLIENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_CLIENTS = "CHANGE/EVENT/SUCCESS/LOADING/CLIENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_CLIENTS = "CHANGE/EVENT/ERROR/LOADING/CLIENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_START_LOADING_USERS_WORK_REPORTS = "CHANGE/EVENT/START/LOADING/USERS/WORK/REPORTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_SUCCESS_LOADING_USERS_WORK_REPORTS = "CHANGE/EVENT/SUCCESS/LOADING/USERS/WORK/REPORTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_ERROR_LOADING_USERS_WORK_REPORTS = "CHANGE/EVENT/ERROR/LOADING/USERS/WORK/REPORTS";





/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TRIP_START_LOADING_ROLES = "CHANGE/TRIP/START/LOADING/ROLES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TRIP_SUCCESS_LOADING_ROLES = "CHANGE/TRIP/SUCCESS/LOADING/ROLES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TRIP_ERROR_LOADING_ROLES = "CHANGE/TRIP/ERROR/LOADING/ROLES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TRIP_START_LOADING_USERS_ROLES = "CHANGE/TRIP/START/LOADING/USERS/ROLES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TRIP_SUCCESS_LOADING_USERS_ROLES = "CHANGE/TRIP/SUCCESS/LOADING/USERS/ROLES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TRIP_ERROR_LOADING_USERS_ROLES = "CHANGE/TRIP/ERROR/LOADING/USERS/ROLES";


/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TRIP_START_LOADING_CLIENTS = "CHANGE/TRIP/START/LOADING/CLIENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TRIP_SUCCESS_LOADING_CLIENTS = "CHANGE/TRIP/SUCCESS/LOADING/CLIENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TRIP_ERROR_LOADING_CLIENTS = "CHANGE/TRIP/ERROR/LOADING/CLIENTS";






/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_START_LOADING_CROSSING_FEATURES = "CHANGE/ASSET/START/LOADING/CROSSING/FEATURES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_SUCCESS_LOADING_CROSSING_FEATURES = "CHANGE/ASSET/SUCCESS/LOADING/CROSSING/FEATURES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_ERROR_LOADING_CROSSING_FEATURES = "CHANGE/ASSET/ERROR/LOADING/CROSSING/FEATURES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_START_LOADING_DECK_TYPES = "CHANGE/ASSET/START/LOADING/DECK/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_SUCCESS_LOADING_DECK_TYPES = "CHANGE/ASSET/SUCCESS/LOADING/DECK/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_ERROR_LOADING_DECK_TYPES = "CHANGE/ASSET/ERROR/LOADING/DECK/TYPES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_START_LOADING_WATER_FLOWS = "CHANGE/ASSET/START/LOADING/WATER/FLOWS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_SUCCESS_LOADING_WATER_FLOWS = "CHANGE/ASSET/SUCCESS/LOADING/WATER/FLOWS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_ERROR_LOADING_WATER_FLOWS = "CHANGE/ASSET/ERROR/LOADING/WATER/FLOWS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_START_LOADING_ALIGNMENTS = "CHANGE/ASSET/START/LOADING/ALIGNMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_SUCCESS_LOADING_ALIGNMENTS = "CHANGE/ASSET/SUCCESS/LOADING/ALIGNMENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_ERROR_LOADING_ALIGNMENTS = "CHANGE/ASSET/ERROR/LOADING/ALIGNMENTS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_START_LOADING_IMPACT_TYPES = "CHANGE/ASSET/START/LOADING/IMPACT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_SUCCESS_LOADING_IMPACT_TYPES = "CHANGE/ASSET/SUCCESS/LOADING/IMPACT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_ERROR_LOADING_IMPACT_TYPES = "CHANGE/ASSET/ERROR/LOADING/IMPACT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
 export const CHANGE_ASSET_START_LOADING_CLIENTS = "CHANGE/ASSET/START/LOADING/CLIENTS";
 /**
  * @memberof ReduxActionTypes.Common
  */
 export const CHANGE_ASSET_SUCCESS_LOADING_CLIENTS = "CHANGE/ASSET/SUCCESS/LOADING/CLIENTS";
 /**
  * @memberof ReduxActionTypes.Common
  */
 export const CHANGE_ASSET_ERROR_LOADING_CLIENTS = "CHANGE/ASSET/ERROR/LOADING/CLIENTS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_START_LOADING_ASSET_TYPES = "CHANGE/ASSET/START/LOADING/ASSET/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_SUCCESS_LOADING_ASSET_TYPES = "CHANGE/ASSET/SUCCESS/LOADING/ASSET/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_ERROR_LOADING_ASSET_TYPES = "CHANGE/ASSET/ERROR/LOADING/ASSET/TYPES";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_DECK_IMPORT_TEMPLATE_DOWNLOAD_STARTED = "CHANGE/EVENT/DECK/IMPORT/TEMPLATE/DOWNLOAD/STARTED";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_DECK_IMPORT_TEMPLATE_DOWNLOAD_ENDED = "CHANGE/EVENT/DECK/IMPORT/TEMPLATE/DOWNLOAD/ENDED";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_APPROACH_IMPORT_TEMPLATE_DOWNLOAD_STARTED = "CHANGE/EVENT/APPROACH/IMPORT/TEMPLATE/DOWNLOAD/STARTED";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_APPROACH_IMPORT_TEMPLATE_DOWNLOAD_ENDED = "CHANGE/EVENT/APPROACH/IMPORT/TEMPLATE/DOWNLOAD/ENDED";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENT_PHOTO_PROPS = 'PROPS/EVENT/PHOTO/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_EVENT_PROPS = "CLEAR/EVENT/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SPAN_PROPS = 'PROPS/SPAN/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSEMBLY_PROPS = 'PROPS/ASSEMBLY/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENT_PROPS = 'PROPS/COMPONENT/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ITEM_PROPS = 'PROPS/ITEM/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_APP_PROPS = 'PROPS/APP/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_NAMES_PROPS = 'PROPS/NAMES/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_HEADER_PROPS = 'PROPS/HEADER/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_MESSAGE_PROPS = 'PROPS/MESSAGE/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 * @description A message has been added or edited.
 */
export const CHANGE_MESSAGE = "CHANGE/MESSAGE";
/**
 * @memberof ReduxActionTypes.Common
 * @description A collection ofmessages have been removed.
 */
export const CHANGE_MESSAGE_REMOVED = "CHANGE/MESSAGE/REMOVED";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_MESSAGEFIELD_PROPS = 'PROPS/MESSAGEFIELD/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_MESSAGEFIELD_PROPS = "CLEAR/MESSAGEFIELD/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 * @description A message field has been added or edited.
 */
export const CHANGE_MESSAGEFIELD = "CHANGE/MESSAGEFIELD";
/**
* @memberof ReduxActionTypes.Common
* @description A collection of message fields have been removed.
*/
export const CHANGE_MESSAGEFIELD_REMOVED = "CHANGE/MESSAGEFIELD/REMOVED";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_MESSAGE_START_LOADING_ORGANIZATIONS = "CHANGE/MESSAGE/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_MESSAGE_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/MESSAGE/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_MESSAGE_ERROR_LOADING_ORGANIZATIONS = "CHANGE/MESSAGE/ERROR/LOADING/ORGANIZATIONS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ROLE_PROPS = 'PROPS/ROLE/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ROLE_START_LOADING_ORGANIZATIONS = "CHANGE/ROLE/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ROLE_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/ROLE/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ROLE_ERROR_LOADING_ORGANIZATIONS = "CHANGE/ROLE/ERROR/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ROLE_START_LOADING_CLAIMS = "CHANGE/ROLE/START/LOADING/CLAIMS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ROLE_SUCCESS_LOADING_CLAIMS = "CHANGE/ROLE/SUCCESS/LOADING/CLAIMS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ROLE_ERROR_LOADING_CLAIMS = "CHANGE/ROLE/ERROR/LOADING/CLAIMS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ROLPAGE_PROPS = 'PROPS/ROLPAGE/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_PAGE_PROPS = 'PROPS/PAGE/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSEMBLYTYPE_PROPS = 'PROPS/ASSEMBLYTYPE/CHANGE';
/**
  * @memberof ReduxActionTypes.Common
  */
export const CHANGE_ASSEMBLYTYPE_START_LOADING_ORGANIZATIONS = "CHANGE/ASSEMBLYTYPE/START/LOADING/ORGANIZATIONS";
/**
  * @memberof ReduxActionTypes.Common
  */
export const CHANGE_ASSEMBLYTYPE_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/ASSEMBLYTYPE/SUCCESS/LOADING/ORGANIZATIONS";
/**
  * @memberof ReduxActionTypes.Common
  */
export const CHANGE_ASSEMBLYTYPE_ERROR_LOADING_ORGANIZATIONS = "CHANGE/ASSEMBLYTYPE/ERROR/LOADING/ORGANIZATIONS";


/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_PROPS = 'PROPS/COMPONENTTYPE/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTGROUP_PROPS = 'PROPS/COMPONENTGROUP/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_DOCUMENTTYPE_PROPS = 'PROPS/DOCUMENTTYPE/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_DOCUMENT_TYPE_PROPS = "CLEAR/DOCUMENT/TYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_DOCUMENT_TYPE_ERRORS = "CLEAR/DOCUMENT/TYPE/ERRORS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_DOCUMENTTYPE_START_LOADING_ORGANIZATIONS = "CHANGE/DOCUMENTTYPE/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_DOCUMENTTYPE_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/DOCUMENTTYPE/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_DOCUMENTTYPE_ERROR_LOADING_ORGANIZATIONS = "CHANGE/DOCUMENTTYPE/ERROR/LOADING/ORGANIZATIONS";



/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENTTYPE_PROPS = 'PROPS/EVENTTYPE/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CLAIM_PROPS = 'PROPS/CLAIM/CHANGE';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_PROPS = 'PROPS/CONDITION/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_START_LOADING_ORGANIZATIONS = "CHANGE/CONDITION/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/CONDITION/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_ERROR_LOADING_ORGANIZATIONS = "CHANGE/CONDITION/ERROR/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_START_LOADING_ASSEMBLY_TYPES = "CHANGE/CONDITION/START/LOADING/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_SUCCESS_LOADING_ASSEMBLY_TYPES = "CHANGE/CONDITION/SUCCESS/LOADING/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_ERROR_LOADING_ASSEMBLY_TYPES = "CHANGE/CONDITION/ERROR/LOADING/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_START_LOADING_COMPONENT_TYPES = "CHANGE/CONDITION/START/LOADING/COMPONENT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_SUCCESS_LOADING_COMPONENT_TYPES = "CHANGE/CONDITION/SUCCESS/LOADING/COMPONENT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_ERROR_LOADING_COMPONENT_TYPES = "CHANGE/CONDITION/ERROR/LOADING/COMPONENT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_START_LOADING_ITEM_TYPES = "CHANGE/CONDITION/START/LOADING/ITEM/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_SUCCESS_LOADING_ITEM_TYPES = "CHANGE/CONDITION/SUCCESS/LOADING/ITEM/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONDITION_ERROR_LOADING_ITEM_TYPES = "CHANGE/CONDITION/ERROR/LOADING/ITEM/TYPES";




/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_PROPS = 'PROPS/LOCATION/CHANGE';
export const CLEAR_LOCATION_PROPS = "CLEAR/LOCATION/PROPS";
export const CLEAR_LOCATION_ERRORS = "CLEAR/LOCATION/ERRORS";


/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_START_LOADING_ORGANIZATIONS = "CHANGE/LOCATION/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/LOCATION/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_ERROR_LOADING_ORGANIZATIONS = "CHANGE/LOCATION/ERROR/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_START_LOADING_ASSEMBLY_TYPES = "CHANGE/LOCATION/START/LOADING/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_SUCCESS_LOADING_ASSEMBLY_TYPES = "CHANGE/LOCATION/SUCCESS/LOADING/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_ERROR_LOADING_ASSEMBLY_TYPES = "CHANGE/LOCATION/ERROR/LOADING/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_START_LOADING_COMPONENT_TYPES = "CHANGE/LOCATION/START/LOADING/COMPONENT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_SUCCESS_LOADING_COMPONENT_TYPES = "CHANGE/LOCATION/SUCCESS/LOADING/COMPONENT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_ERROR_LOADING_COMPONENT_TYPES = "CHANGE/LOCATION/ERROR/LOADING/COMPONENT/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_START_LOADING_ITEM_TYPES = "CHANGE/LOCATION/START/LOADING/ITEM/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_SUCCESS_LOADING_ITEM_TYPES = "CHANGE/LOCATION/SUCCESS/LOADING/ITEM/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_LOCATION_ERROR_LOADING_ITEM_TYPES = "CHANGE/LOCATION/ERROR/LOADING/ITEM/TYPES";


/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSEMBLYEVENT_PROPS = 'PROPS/ASSEMBLYEVENT/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 * @description An AssemblyEvent has been saved and that record
 * has to be updated in the store.
 */
export const CHANGE_ASSEMBLYEVENT_SAVED = "CHANGE/ASSEMBLYEVENT/SAVED";



/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSEMBLYEVENT_ADD_MULTIPLE_PROPS = 'PROPS/ASSEMBLYEVENT/ADD/MULTIPLE/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 * @description A single dynamic field of an assembly event has
 * been modified.
 */
export const CHANGE_ASSEMBLYEVENT_DYNAMIC_FIELD_UPDATED = "CHANGE/ASSEMBLYEVENT/DYNAMIC/FIELD/UPDATED";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSEMBLYEVENT_ADDED_MULTIPLE_COMPONENTS = "CHANGE/ASSEMBLYEVENT/ADDED/MULTIPLE/COMPONENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTEVENT_PROPS = 'PROPS/COMPONENTEVENT/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 * @description A single dynamic field of a component event
 * has been modified.
 */
export const CHANGE_COMPONENTEVENT_DYNAMIC_FIELD_UPDATED = "CHANGE/COMPONENTEVENT/DYNAMIC/FIELD/UPDATED";

/**
 * @memberof ReduxActionTypes.Common
 * @description A single dynamic field of a component event
 * has been modified.
 */
 export const UPDATE_COMPONENTS_ORDER = "CHANGE/COMPONENTS/ORDER";

/**
 * /**
 * @memberof ReduxActionTypes.Common
 * @description A single dynamic field of a component event
 * has been modified.
 */
 export const BULK_EDIT_COMPONENT_EVENT = "BULK/EDIT/COMPONENT/EVENT";

 /**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ITEMEVENT_PROPS = 'PROPS/ITEMEVENT/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ITEMSEVENT_BY_COMPONENT_PROPS = 'PROPS/ITEMSEVENT/BY/COMPONENT/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ITEMS_BY_COMPONENT_PROPS = 'PROPS/ITEMS/BY/COMPONENT/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ERROR_PROPS = 'PROPS/ERROR_CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_IMAGE_PROPS = "PROPS/IMAGE/CHANGE";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_MILESTONE_PROPS = "PROPS/MILESTONE/CHANGE";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_DIRECTIONALLOADING_PROPS = 'PROPS/DIRECTIONALLOADING/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ALIGNMENT_PROPS = 'PROPS/ALIGNMENT/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_WATERFLOW_PROPS = 'PROPS/WATERFLOW/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_DECKTYPE_PROPS = 'PROPS/DECKTYPE/CHANGE';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_PROPS = "CHANGE/ASSETEVENT/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_NAME_PROPS = 'PROPS/ASSETEVENT/NAME/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_START_LOADING_STRUCTURE_TYPES = "CHANGE/ASSETEVENT/START/LOADING/STRUCTURE/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_SUCCESS_LOADING_STRUCTURE_TYPES = "CHANGE/ASSETEVENT/SUCCESS/LOADING/STRUCTURE/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_ERROR_LOADING_STRUCTURE_TYPES = "CHANGE/ASSETEVENT/ERROR/LOADING/STRUCTURE/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_START_LOADING_USERS_WORK_REPORTS = "CHANGE/ASSETEVENT/START/LOADING/USERS/WORK/REPORTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_SUCCESS_LOADING_USERS_WORK_REPORTS = "CHANGE/ASSETEVENT/SUCCESS/LOADING/USERS/WORK/REPORTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETEVENT_ERROR_LOADING_USERS_WORK_REPORTS = "CHANGE/ASSETEVENT/ERROR/LOADING/USERS/WORK/REPORTS";




/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENTCONDITION_PROPS = "CHANGE/EVENTCONDITION/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENTCONDITION_PROPS_ADD_NEW_PHOTO = 'PROPS/EVENTCONDITION/CHANGE/ADD/NEW/PHOTO';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_WORKPERFORMED_PROPS = "CHANGE/WORKPERFORMED/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_WORKPERFORMED_PROPS_ADD_NEW_PHOTO = 'PROPS/WORKPERFORMED/CHANGE/ADD/NEW/PHOTO';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_STRUCTURETYPE_IS_ASSIGNED_PROPS = 'CHANGE/STRUCTURETYPE/IS/ASSIGNED/PROPS';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_INSPECTIONTYPE_PROPS = 'PROPS/INSPECTIONTYPE/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TOAST_PROPS = 'PROPS/TOAST/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_DOCUMENT_PROPS = 'PROPS/DOCUMENT/CHANGE';
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TOAST_REMOVE_ACTIVE = "CHANGE/TOAST/REMOVE/ACTIVE";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_MENU_ACTIVE_PROPS = 'PROPS/MENU/ACTIVE/CHANGE';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_PERMISSIONS_PROPS = 'CHANGE/PERMISSIONS/PROPS';

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ORGANIZATION_PROPS = "CHANGE/ORGANIZATION/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const ORGANIZATION_LOADING_CURRENT_USER = "ORGANIZATION/LOADING/CURRENT/USER";
/**
 * @memberof ReduxActionTypes.Common
 */
export const ORGANIZATION_LOADED_CURRENT_USER = "ORGANIZATION/LOADED/CURRENT/USER";
/**
 * @memberof ReduxActionTypes.Common
 */
export const ORGANIZATION_ERROR_LOADING_CURRENT_USER = "ORGANIZATION/ERROR/LOADING/CURRENT/USER";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ORGANIZATION_PROPS = "CLEAR/ORGANIZATION/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ORGANIZATION_ERRORS = "CLEAR/ORGANIZATION/ERRORS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_JOBTITLE_PROPS = "CHANGE/JOBTITLE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_JOB_TITLE_PROPS = "CLEAR/JOBTITLE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_JOB_TITLE_ERRORS = "CLEAR/JOBTITLE/ERRORS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONTACT_PROPS = "CHANGE/CONTACT/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_CONTACT_PROPS = "CLEAR/CONTACTS/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_CONTACT_ERRORS = "CLEAR/CONTACTS/ERRORS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONTACT_START_LOADING_ORGANIZATIONS = "CHANGE/CONTACT/START/LOADING/ORGANIZATIONS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CONTACT_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/CONTACT/SUCCESS/LOADING/ORGANIZATIONS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CONTACT_ERROR_LOADING_ORGANIZATIONS = "CHANGE/CONTACT/ERROR/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CONTACT_START_LOADING_CLIENTS = "CHANGE/CONTACT/START/LOADING/CLIENTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CONTACT_SUCCESS_LOADING_CLIENTS = "CHANGE/CONTACT/SUCCESS/LOADING/CLIENTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CONTACT_ERROR_LOADING_CLIENTS = "CHANGE/CONTACT/ERROR/LOADING/CLIENTS";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CONTACT_START_LOADING_JOB_TITLES = "CHANGE/CONTACT/START/LOADING/JOB_TITLES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CONTACT_SUCCESS_LOADING_JOB_TITLES = "CHANGE/CONTACT/SUCCESS/LOADING/JOB_TITLES";
/**
* @memberof ReduxActionTypes.Common
*/
export const CHANGE_CONTACT_ERROR_LOADING_JOB_TITLES = "CHANGE/CONTACT/ERROR/LOADING/JOB_TITLES";







/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_MESSAGE_PROPS = "CLEAR/MESSAGE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_MESSAGE_ERRORS = "CLEAR/MESSAGE/ERRORS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ROLE_PROPS = "CLEAR/ROLE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ROLE_ERRORS = "CLEAR/ROLE/ERRORS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_CLAIMS_PROPS = "CLEAR/CLAIMS/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_CLAIMS_ERRORS = "CLEAR/CLAIMS/ERRORS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ASSEMBLY_TYPE_PROPS = "CLEAR/ASSEMBLYTYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ASSEMBLY_TYPE_ERRORS = "CLEAR/ASSEMBLYTYPE/ERRORS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ACCESS_TYPES_PROPS = "CLEAR/ACCESSTYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ACCESS_TYPES_ERRORS = "CLEAR/ACCESSTYPE/ERRORS";


/**
 * @memberof ReduxActionTypes.Common
 */
 export const CHANGE_ASSETTYPE_PROPS = "CHANGE/ASSETTYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ASSET_TYPES_PROPS = "CLEAR/ASSETTYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ASSET_TYPES_ERRORS = "CLEAR/ASSETTYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETTYPE_START_LOADING_ORGANIZATIONS = "CHANGE/ASSETTYPE/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETTYPE_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/ASSETTYPE/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSETTYPE_ERROR_LOADING_ORGANIZATIONS = "CHANGE/ASSETTYPE/ERROR/LOADING/ORGANIZATIONS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_COMPONENT_GROUP_PROPS = "CLEAR/COMPONENTGROUP/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_COMPONENT_GROUP_ERRORS = "CLEAR/COMPONENTGROUP/ERRORS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTGROUPS_START_LOADING_ORGANIZATIONS = "CHANGE/COMPONENTGROUPS/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTGROUPS_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/COMPONENTGROUPS/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTGROUPS_ERROR_LOADING_ORGANIZATIONS = "CHANGE/COMPONENTGROUPS/ERROR/LOADING/ORGANIZATIONS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_COMPONENT_TYPE_PROPS = "CLEAR/COMPONENTTYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_COMPONENT_TYPE_ERRORS = "CLEAR/COMPONENTTYPE/ERRORS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_START_LOADING_ORGANIZATIONS = "CHANGE/COMPONENTTYPE/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/COMPONENTTYPE/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_ERROR_LOADING_ORGANIZATIONS = "CHANGE/COMPONENTTYPE/ERROR/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_START_LOADING_ASSEMBLY_TYPES = "CHANGE/COMPONENTTYPE/START/LOADING/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_SUCCESS_LOADING_ASSEMBLY_TYPES = "CHANGE/COMPONENTTYPE/SUCCESS/LOADING/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_ERROR_LOADING_ASSEMBLY_TYPES = "CHANGE/COMPONENTTYPE/ERROR/LOADING/ASSEMBLY/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_START_LOADING_COMPONENT_GROUPS = "CHANGE/COMPONENTTYPE/START/LOADING/COMPONENT/GROUPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_SUCCESS_LOADING_COMPONENT_GROUPS = "CHANGE/COMPONENTTYPE/SUCCESS/LOADING/COMPONENT/GROUPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_ERROR_LOADING_COMPONENT_GROUPS = "CHANGE/COMPONENTTYPE/ERROR/LOADING/COMPONENT/GROUPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_START_LOADING_FIELDS = "CHANGE/COMPONENTTYPE/START/LOADING/FIELDS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_SUCCESS_LOADING_FIELDS = "CHANGE/COMPONENTTYPE/SUCCESS/LOADING/FIELDS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_COMPONENTTYPE_ERROR_LOADING_FIELDS = "CHANGE/COMPONENTTYPE/ERROR/LOADING/FIELDS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_CONDITIONS_PROPS = "CLEAR/CONDITONS/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_CONDITIONS_ERRORS = "CLEAR/CONDITIONS/ERRORS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CROSSINGFEATURE_PROPS = "CHANGE/CROSSINGFEATURE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_CROSSING_FEATURE_PROPS = "CLEAR/CROSSINGFEATURE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_CROSSING_FEATURE_ERRORS = "CLEAR/CROSSINGFEATURE/ERRORS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CROSSINGFEATURE_START_LOADING_ORGANIZATIONS = "CHANGE/CROSSINGFEATURE/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CROSSINGFEATURE_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/CROSSINGFEATURE/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_CROSSINGFEATURE_ERROR_LOADING_ORGANIZATIONS = "CHANGE/CROSSINGFEATURE/ERROR/LOADING/ORGANIZATIONS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_IMPACTTYPE_PROPS = "CHANGE/IMPACTTYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_IMPACT_TYPES_PROPS = "CLEAR/IMPACTTYPES/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_IMPACT_TYPES_ERRORS = "CLEARS/IMPACTTYPES/ERROS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_IMPACTTYPE_START_LOADING_ORGANIZATIONS = "CHANGE/IMPACTTYPE/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_IMPACTTYPE_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/IMPACTTYPE/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_IMPACTTYPE_ERROR_LOADING_ORGANIZATIONS = "CHANGE/IMPACTTYPE/ERROR/LOADING/ORGANIZATIONS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ASSET_PROPS = "CHANGE/ASSET/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_ASSET_PROPS = "CLEAR/ASSET/PROPS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_CLIENT_PROPS = "CLEAR/CLIENT/PROPS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_TRIP_PROPS = "CHANGE/TRIP/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_TRIP_PROPS = "CLEAR/TRIP/PROPS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const SAGA_EVENT_HAS_PHOTO = 'SAGA/EVENT/HAS/PHOTO';


/**
 * A WebSocket message has been recieved.
 * @memberof ReduxActionTypes.Common
 */
export const WEBSOCKET_MESSAGE_RECEIVED = "WEBSOCKET/MESSAGE/RECEIVED";

export const EVENT_STATUS_UPDATED = "EVENT/STATUS/UPDATED";

export const ROUTE_LEAVING_CURRENT = "ROUTE/LEAVING/CURRENT";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ACCESSTYPE_PROPS = "CHANGE/ACCESSTYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ACCESS_TYPES_START_LOADING_ORGANIZATIONS = "CHANGE/ACCESS/TYPES/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ACCESS_TYPES_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/ACCESS/TYPES/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_ACCESS_TYPES_ERROR_LOADING_ORGANIZATIONS = "CHANGE/ACCESS/TYPES/ERROR/LOADING/ORGANIZATIONS";


/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENTNOTE_PROPS = "CHANGE/EVENTNOTE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_EVENTRECOMMENDATION_PROPS = "CHANGE/EVENTRECOMMENDATION/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_MILEPOST_PROPS = "CHANGE/MILEPOST/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_SECTIONEVENT_PROPS = "CHANGE/SECTIONEVENT/PROPS";

/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_STRUCTURETYPE_PROPS = "CHANGE/STRUCTURETYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_STRUCTURE_TYPE_PROPS = "CLEAR/STRUCTURE/TYPE/PROPS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CLEAR_STRUCTURE_TYPE_ERRORS = "CLEAR/STRUCTURE/TYPE/ERRORS";
/**
 * @memberof ReduxActionTypes.Common
 */
 export const GET_STRUCTURE_TYPE_COMPONENTS = "GET/STRUCTURE/TYPE/COMPONENTS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_STRUCTURETYPE_START_LOADING_ORGANIZATIONS = "CHANGE/STRUCTURETYPE/START/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_STRUCTURETYPE_SUCCESS_LOADING_ORGANIZATIONS = "CHANGE/STRUCTURETYPE/SUCCESS/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_STRUCTURETYPE_ERROR_LOADING_ORGANIZATIONS = "CHANGE/STRUCTURETYPE/ERROR/LOADING/ORGANIZATIONS";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_STRUCTURETYPE_START_LOADING_ASSET_TYPES = "CHANGE/STRUCTURETYPE/START/LOADING/ASSET/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_STRUCTURETYPE_SUCCESS_LOADING_ASSET_TYPES = "CHANGE/STRUCTURETYPE/SUCCESS/LOADING/ASSET/TYPES";
/**
 * @memberof ReduxActionTypes.Common
 */
export const CHANGE_STRUCTURETYPE_ERROR_LOADING_ASSET_TYPES = "CHANGE/STRUCTURETYPE/ERROR/LOADING/ASSET/TYPES";


