import {
  Content,
  Footer,
} from "components/Layout";
import {
  Navbar,
  NavbarBrand,
  Dropdown,
  Nav,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
} from 'reactstrap';
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { changeMenuActiveProps } from 'store/actions/MenuActiveActions'
import { getOrganizations } from 'store/actions/OrganizationActions';
import { changeSelectedOrganization } from 'store/actions/AuthActions';
import {
  ROUTE_LOGOUT,
  ROUTE_ACCESSTYPES,
  ROUTE_USERS,
  ROUTE_EVENTS_DASHBOARD
} from 'config/constants';
import { Navigate } from 'react-router-dom'
import logoARE from 'assets/img/logo/ARE_01_White.svg';
import Avatar from 'components/Avatar';
import Utils from "common/utils";
import ModalValueSelector from "components/ModalValueSelector";

const BasicLayout = (props) => {
    const {
      children,
      currentUserIsSuperAdmin,
      getOrganizations,
      changeSelectedOrganization,
      organizations
    } = props;

    const [ redirectTo, setRedirectTo ] = useState('')
    const [ dropdownOpen, setDropdownOpen ] = useState(false)
    const [ showOrganizationSelectorModal, setShowOrganizationSelectorModal ] = useState(false);

    useEffect(() => {
      if (currentUserIsSuperAdmin) {
        getOrganizations();
      }
    }, [currentUserIsSuperAdmin, getOrganizations]);

    const handleItemMenuServies = value => {
        let redirectTo = ''
        localStorage.setItem("NavBarLocation", value);
        props.changeMenuActiveProps({
        sidebarActive: value
        })
        switch (value) {
        case 'managementAsset':
            redirectTo = window.location.pathname === ROUTE_ACCESSTYPES ? '' : ROUTE_ACCESSTYPES
            break;
        case 'managementSettings':
            redirectTo = window.location.pathname === ROUTE_USERS ? '' : ROUTE_USERS
        break;
        default:
            redirectTo = window.location.pathname === ROUTE_EVENTS_DASHBOARD ? '' : ROUTE_EVENTS_DASHBOARD
            break;
        }

        setDropdownOpen(prev => !prev)
        setRedirectTo(redirectTo)
    }

    const toggle = useCallback(() => {
        setDropdownOpen(prev => !prev);
    }, [setDropdownOpen]);

    const showOrganizationSelector = useCallback(() => {
      setShowOrganizationSelectorModal(true);
    }, [setShowOrganizationSelectorModal]);

    const hideOrganizationSelector = useCallback(() => {
      setShowOrganizationSelectorModal(false);
    }, [setShowOrganizationSelectorModal]);

    const onSelectedOrganizationConfirm = useCallback(selectedOrganization => {
      changeSelectedOrganization(selectedOrganization.id);
      hideOrganizationSelector();
      setRedirectTo("/");
    }, [changeSelectedOrganization, hideOrganizationSelector, setRedirectTo]);

    if(redirectTo !== '') return <Navigate to={redirectTo} />


    const defaultOrganization = props.organizations.find(x => x.id === props.user.organization);
    let tenantDropDownItem = null;

    if (currentUserIsSuperAdmin && defaultOrganization && defaultOrganization.name) {
      tenantDropDownItem = <DropdownItem onClick={showOrganizationSelector} className='sign-out'>
        Switch Tenant <small>({defaultOrganization.name})</small>
      </DropdownItem>;
    }

    return (
      <>
        <header>
          <Navbar className='navbar fixed-top custom-navbar'>
            <NavbarBrand href="/"><img src={logoARE} alt="img" width="130px" height="35px" className="logo-are-1" /></NavbarBrand>
            <Nav className="mr-auto">
              <Dropdown nav inNavbar isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle nav caret>
                  Services
                </DropdownToggle>
                <DropdownMenu className='drop-down-menu'>
                  <Row className="h-100">
                    <Col xs={5} className='col-menu-drop-down'>
                      <Row>
                        <Col xs={12} className='section-title'>Assets Group</Col>
                        <Col xs={12} className={`item-title ${props.sidebarActive === 'assetBridge' ? 'menu-active' : '' }`} onClick={() => handleItemMenuServies('assetBridge')}>Bridges </Col>
                        <Col xs={12} className={`item-title ${props.sidebarActive === '' ? 'menu-active' : '' }`}>Tracks</Col>
                      </Row>
                  </Col>
                    <Col xs={5} className='col-menu-drop-down'>
                      <Row>
                        <Col xs={12} className='section-title'>Management</Col>
                        <Col xs={12} className={`item-title ${props.sidebarActive === 'managementAsset' ? 'menu-active' : '' }`} onClick={() => handleItemMenuServies('managementAsset')}>Assets</Col>
                        <Col xs={12} className={`item-title ${props.sidebarActive === 'managementSettings' ? 'menu-active' : '' }`} onClick={() => handleItemMenuServies('managementSettings')}>Settings</Col>
                      </Row>
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown>
            </Nav>
            <Nav className="float-right">
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                <Avatar size={28} className="can-click avatar" /> <span className='hidden-xs'>{props.user ? props.user.email : ''}</span>
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem href={`/users/${props.user.idUser}`} className='sign-out' >Profile</DropdownItem>
                  {tenantDropDownItem}
                  <DropdownItem divider />
                  <DropdownItem href={ROUTE_LOGOUT} className='sign-out'>Sign Out </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar>
        </header>
        <main role="main">
            <Content fluid>
              {children}
              <Footer />
            </Content>
        </main>

        <ModalValueSelector
          show={showOrganizationSelectorModal}
          title="Select an organization"
          informationText="Select an organization from the list below:"
          onConfirm={onSelectedOrganizationConfirm}
          onCancel={hideOrganizationSelector}
          models={organizations}
          defaultValue={defaultOrganization}
        />
      </>
    );
}

function mapStateToProps(store) {
  return {
    user: store.auth.session,
    currentUserIsSuperAdmin: Utils.isSuperAdmin(store.auth.session),
    organizations: store.organization.organizations
  };
}

export default connect(mapStateToProps, {
  changeMenuActiveProps,
  getOrganizations,
  changeSelectedOrganization
})(BasicLayout);
