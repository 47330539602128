/**
 * Functions that create WebSocket channel names.
 * @namespace WebSocketChannelNameCreators
 */

/**
 * @memberof WebSocketChannelNameCreators
 * @description Creates a WebSocket channel name to witch the application
 * can subscribe and receive milestone status updates of the event.
 * @param {string} eventId - ID of the event.
 * @returns {string} the channel name.
 */
export function eventStatusUpdate(eventId) {
  return "EVENT/UPDATE/STATUS/" + eventId;
}

/**
 * @memberof WebSocketChannelNameCreators
 * @description Creates a WebSocket channel name to witch the application
 * can subscribe and receive notification for the user.
 * @param {string} userId - ID of the user.
 * @returns  {string} the channel name.
 */
export function notifications(userId) {
  return "USER/" + userId + "/NOTIFICATIONS";
}
