import * as actionTypes from './actionTypes'
import Cookies from "universal-cookie";

const cookies = new Cookies();

/**
 * @namespace AuthActions
 */

/**
 * @memberof AuthActions
 * @description Try to reload auth from localStorage
 */
export function reloadTokenAction(){
  return { type: actionTypes.SAGA_AUTH_LOAD }
}


/**
 * @memberof AuthActions
 * @description Make a request to login api
 */
export function loginRequestAction(values){
  return {
    type: actionTypes.SAGA_AUTH_LOGIN,
    props: values
  }
}

/**
 * @memberof AuthActions
 * @description Run a logout
 */
export function logoutAction(){
  cookies.get();
  cookies.remove("selectedSubdivisions");
  cookies.remove("selectedRailroads");
  cookies.remove("selectedClients");
  return {
    type: actionTypes.SAGA_AUTH_LOGOUT,
    props: {}
  }
}

export function errorMessage(msgError){
  return {
    type: actionTypes.SAGA_AUTH_ERROR,
    props: {msgError:msgError}
  }
}

export function forgotPassEmailRequestAction(values){
  return {
    type: actionTypes.SAGA_AUTH_FORGOT_PASS_EMAIL,
    props: values
  }
}
export function changePasswordAction(values){
  return {
    type: actionTypes.SAGA_AUTH_CHANGE_PASS,
    props: values
  }
}


/**
 * @memberof AuthActions
 * @description Change Auth props (no saga action)
 */
export function changeAuthPropsAction(props){
  return {
    type: actionTypes.CHANGE_AUTH_PROPS,
    props: props
  }
}

export function changeSelectedOrganization(organizationId) {
  return {
    type: actionTypes.SAGA_AUTH_CHANGE_SELECTED_ORGANIZATION,
    props: {
      organizationId
    }
  };
}

export function changeSelectedClient(clientId) {
  return {
    type: actionTypes.SAGA_AUTH_CHANGE_SELECTED_CLIENT,
    props: {
      clientId
    }
  };
}
