import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import { jobTitleFields } from 'common/objectStructures';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const initialState = {
    jobTitles: [],
    jobTitle: {},
    formValues: {},
    pagination: {},
    loading: false,
    errors: "",
    organizations: createState()
}

function reducerJobTitle(state = initialState, action) {
    switch (action.type) {

        case actionTypes.CHANGE_JOBTITLE_PROPS:
            if (action.props.formValues != null) {
                let newValues = { id: action.props.formValues.id }
                for (const [key, value] of Object.entries(jobTitleFields)) {
                    newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
                }
                action.props.formValues = newValues
            }
            return Object.assign({}, state, action.props)

        case actionTypes.CLEAR_JOB_TITLE_PROPS:
          return initialState;

        case actionTypes.CLEAR_JOB_TITLE_ERRORS:
          return {
            ...state,
            errors: ''
          };

        default:
            return state
    }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerJobTitle),
  {
    organizations: [
      actionTypes.CHANGE_JOBTITLE_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_JOBTITLE_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_JOBTITLE_ERROR_LOADING_ORGANIZATIONS
    ]
  }
);
