import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import { cloneDeep } from 'lodash';

const initialState = {
  assemblysEvent: [],
  assemblysEventForForm: [],
  assembliesEventForPhotos:[],
  assembliesEventArray: [],
  assemblyEvent: {},
  assemblySubPrototype:[],
  assemblySupPrototype:[],
  assemblyDeckPrototype:{},
  newAssemblyEvent: {},
  formValues: null,
  loading: false,
  error: ''
}

function reducerAssemblyEvent (state = initialState, action) {

  const clonedState = cloneDeep(state);

  switch (action.type) {
    case actionTypes.CHANGE_ASSEMBLYEVENT_PROPS:
      return Object.assign({}, state, action.props)

    case actionTypes.CHANGE_ASSEMBLYEVENT_ADD_MULTIPLE_PROPS:
      let allAssemblies = [...state.assemblysEvent, ...action.props.newAssemblies]
      return {
        ...state,
        assemblysEvent: allAssemblies,
        assembliesEventArray: allAssemblies
      }


    case actionTypes.CHANGE_ASSEMBLYEVENT_DYNAMIC_FIELD_UPDATED:
      return {
        ...clonedState,
        assembliesEventArray: clonedState.assembliesEventArray.map(assemblyEvent => {
          if (assemblyEvent.id !== action.props.assemblyEventId) {
            return assemblyEvent;
          }

          return {
            ...assemblyEvent,
            [action.props.fieldName]: action.props.newValue
          }
        }),
        assemblysEvent: clonedState.assemblysEvent.map(assemblyEvent => {
          if (assemblyEvent.id !== action.props.assemblyEventId) {
            return assemblyEvent;
          }

          return {
            ...assemblyEvent,
            [action.props.fieldName]: action.props.newValue
          }
        })
      }

      case actionTypes.CHANGE_COMPONENTEVENT_DYNAMIC_FIELD_UPDATED:
        return {
          ...clonedState,
          assembliesEventArray: clonedState.assembliesEventArray.map(assemblyEvent => {

            const componentEventIndex = assemblyEvent.components.findIndex(x => x.id === action.props.componentEventId);
            if (componentEventIndex === -1) {
              return assemblyEvent;
            }

            return {
              ...assemblyEvent,
              components: assemblyEvent.components.map((componentEvent, index) => {
                if (index !== componentEventIndex) {
                  return componentEvent;
                }

                return {
                  ...componentEvent,
                  [action.props.fieldName]: action.props.newValue
                }
              })
            }
          }),
          assemblysEvent: clonedState.assembliesEventArray.map(assemblyEvent => {

            const componentEventIndex = assemblyEvent.components.findIndex(x => x.id === action.props.componentEventId);
            if (componentEventIndex === -1) {
              return assemblyEvent;
            }

            return {
              ...assemblyEvent,
              components: assemblyEvent.components.map((componentEvent, index) => {
                if (index !== componentEventIndex) {
                  return componentEvent;
                }

                return {
                  ...componentEvent,
                  [action.props.fieldName]: action.props.newValue
                }
              })
            }
          })
        }

    case actionTypes.CHANGE_ASSEMBLYEVENT_SAVED:
      const modifiedAssemblyEvent = action.props.assemblyEvent
      const comparator = x => x.id === modifiedAssemblyEvent.id;
      let index = clonedState.assemblysEvent.findIndex(comparator);
      if (index >= 0) {
        clonedState.assemblysEvent.splice(index, modifiedAssemblyEvent);
      }

      index = clonedState.assembliesEventArray.findIndex(comparator);
      if (index >= 0) {
        clonedState.assembliesEventArray.splice(index, modifiedAssemblyEvent);
      }

      return clonedState;





    case actionTypes.CHANGE_ASSEMBLYEVENT_ADDED_MULTIPLE_COMPONENTS:
      if (action.props.componentEvents.length === 0) {
        return state;
      }

      const assemblyEventId = action.props.componentEvents[0].assemblyEvent.id;
      const assemblyEventIndex = clonedState.assembliesEventArray.findIndex(current => current.id === assemblyEventId);
      const assemblyEvent = clonedState.assembliesEventArray[assemblyEventIndex];
      assemblyEvent.componentEvents = assemblyEvent.componentEvents.concat(action.props.componentEvents);
      assemblyEvent.componentEvents.sort((a,b)=>(a.componentName > b.componentName) ? 1 : -1)
      const newAssembliesEventArray = clonedState.assembliesEventArray
      newAssembliesEventArray.splice(assemblyEventIndex, 1, assemblyEvent);
      return {
        ...clonedState,
        assembliesEventArray: newAssembliesEventArray
      }

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerAssemblyEvent);
