import React, {useState} from 'react';

import { Form, Input } from 'reactstrap';
import { MdSearch } from 'react-icons/md';

export default function SearchInput(props) {

  const [searchInput, setSearchInput] = useState("")

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    props.onChangeSearchInput(searchInput);
  }

  return (
    <Form inline className="cr-search-form" onSubmit={(e) => { handleSubmit(e) }}>
      <MdSearch
        size="20"
        className="cr-search-form__icon-search text-secondary"
      />
      <Input
        type="search"
        className="cr-search-form__input"
        placeholder="Search..."
        value={searchInput}
        onChange={(e) => { handleSearchInputChange(e) }}
      />
    </Form>
  );
}

