import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/railroad';

const endpointClient = API_SERVER + '/client';

export default class ApiRailRoad {

  // GET 'api/railroads'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchRailroads(props){
    return Utils.genericCall(`${endpoint}/paged?search=${props.search}&page=${props.page}`);
  }

  static getByClient(id){
    return Utils.genericCall(`${endpointClient}/${id}/railroad`);
  }

  static getByClients(props){
    return Utils.genericPost(`${endpoint}/pagedByClient?page=${props.page}`, props.ids);
  }

  static searchByClient(props){
     return Utils.genericCall(`${endpointClient}/${props.id}/railroad/paged?search=${props.search}&page=${props.page}&size=${props.size}&order=${props.order.id}&desc=${props.order.desc}`);
  }

  static searchByClientPage(props){
    return Utils.genericPost(`${endpointClient}/${props.id}/railroad/paged`, props.data);
 }

  static getRailroad(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static getRailroadListByCLient(clientId){
    return Utils.genericCall(`${endpointClient}/${clientId}/railroad/all`);
  }

  static getRailroadListByCLientReduced(clientId){
    return Utils.genericCall(`${endpointClient}/${clientId}/railroad/all_reduced`);
  }

  static editRailroad(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addRailroad(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteRailroad(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteRailroadChecked(data) {
    return Utils.genericPost(`${endpoint}/delete`, data);
  }

  static searchRailroadByName(data) {
    return Utils.genericCall(`${endpoint}/byName?search=${data}`);
  }

  static getContactByClientId(props) {
    return Utils.genericPost(`${endpoint}/client/${props.clientId}/paged`, props.data);
  }


  static getRailroadContactsPaged(railroadId, paginationOptions) {
    return Utils.genericPost(`${endpoint}/${railroadId}/contacts/paged`, paginationOptions);
  }

  static editRailroadContact(railroadId, contactId, contactData) {
    return Utils.genericPut(`${endpoint}/${railroadId}/contacts/${contactId}`, contactData);
  }

  static addRailroadContact(railroadId, contactData) {
    return Utils.genericPost(`${endpoint}/${railroadId}/contacts`, contactData);
  }

  static deleteRailroadContactList(contactIdList) {
    return Utils.genericPost(`${endpoint}/contacts/deleteList`, contactIdList);
  }



  static getRailroadDocumentsPaged(railroad, paginationOptions) {
    return Utils.genericPost(`${endpoint}/${railroad}/documents/paged`, paginationOptions);
  }

  static editRailroadDocument(documentId, documentData) {
    return Utils.genericPut(`${endpoint}/documents/${documentId}`, documentData);
  }

  static addRailroadDocument(railroadId, documentData, fileData) {

    //For files, we have to send the request as a FormData.
    let form = new FormData();
    form.append('file', fileData)
    form.append('document', JSON.stringify(documentData))

    //Remove the Content-Type header. By default is application/json
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    return Utils.genericPost(`${endpoint}/${railroadId}/documents`, form, headers);
  }

  static deleteRailroadDocumentList(documentIdList) {
    return Utils.genericPost(`${endpoint}/documents/deleteList`, documentIdList);
  }

  static getRailroadDocumentTypes(organizationId) {
    return Utils.genericCall(`${API_SERVER}/${organizationId}/railroad/documentTypes`);
  }

  static getRailroadJobTitles(organizationId) {
    return Utils.genericCall(`${API_SERVER}/${organizationId}/railroad/jobTitles`);
  }

  static getRailroadOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getRailroadClients() {
    return Utils.genericCall(`${endpoint}/clients`);
  }

  static getRailroadContacts() {
    return Utils.genericCall(`${endpoint}/contacts`);
  }
}
