import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MODALS_TYPES } from 'config/constants'
import { Alert } from 'reactstrap';


const ModalBanner = props => {

  const [classModal, setClassModal] = useState("");


  useEffect(() => {
    let modalStyle = MODALS_TYPES.find(x => x.type === props.modalType)
    setClassModal(modalStyle)
  }, [props.modalType]);

  return (
    <Alert className={classModal.class} isOpen={props.open} toggle={props.toggle}>
      {props.fromStoryBook ? <FontAwesomeIcon icon={classModal.storyBookIcon} className='mr-3' /> : <i className={classModal.icon}></i>}
      {props.msg}
    </Alert>
  );

}

export default ModalBanner;