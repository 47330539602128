import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {messageFields} from 'common/objectStructures';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const initialState = {
  loading: false,
  messages: [],
  message: {},
  types: [],
  events: [],
  formValues: null,
  pagination: {},
  errors: '',
  organizations: createState()
}

function reducerMessage (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_MESSAGE_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(messageFields)) {
          newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_MESSAGE_PROPS:
      return initialState;

    case actionTypes.CLEAR_MESSAGE_ERRORS:
      return {
        ...state,
        errors: ''
      }

    case actionTypes.CHANGE_MESSAGE:
      return {
        ...state,
        messages: [...state.messages.filter(x => x.id === action.props.message.id), action.props.message]
      };

    case actionTypes.CHANGE_MESSAGE_REMOVED:
      return {
        ...state,
        //remove deleted messages
        messages: state.messages.filter(x => action.props.removedMessagesIds.includes(x.id) === false)
      };

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerMessage),
  {
    organizations: [
      actionTypes.CHANGE_MESSAGE_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_MESSAGE_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_MESSAGE_ERROR_LOADING_ORGANIZATIONS
    ]
  }
);
