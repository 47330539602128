import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/milepostIncreases';

export default class ApiPage {

  // GET 'api/pages'
  static getAll(){
    return Utils.genericCall(`${endpoint}`);
  }

}
