import { all, call, put, takeLatest,select } from 'redux-saga/effects';
import * as actionTypes from 'store/actions/actionTypes';
// APIs to talk to...
//import ApiEventRecommendation from 'tests/mocks/ApiEventRecommendation.mock'
import ApiEventRecommendation from 'apiServices/ApiEventRecommendation';
import { cloneDeep } from 'lodash';


function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The recomendation cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props });
}

/*
 * get EventRecommendations
 */
function* getEvents(action) {
  try {
    yield loading();
    // get all events
    const allEventRecommendations = yield call(ApiEventRecommendation.getAll, action.props.assetId);
    // update state for events
    yield put({
      type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
        recommendations: allEventRecommendations,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getEventRecommendation(action) {
  try {
    yield loading();
    // get EventRecommendation
    const recommendations = yield call(ApiEventRecommendation.getEventRecommendation, action.props.assetId, action.props.eventId);

    yield put({
      type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
        recommendations: recommendations,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* addEventRecommendation(action) {
  try {
    yield loading();
    // add Event
    let loadedStructureType = yield select(store=>store.structureType)
    let newStructureType = cloneDeep(loadedStructureType)
    const eventRecommendation = yield call(ApiEventRecommendation.addEventRecommendation, action.props);
    const finalEventRecommendation = yield call(ApiEventRecommendation.getEventRecommendationById, eventRecommendation.id)
    newStructureType[action.props.structureId].eventRecommendations.push(finalEventRecommendation)
    //save new Event
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        loading: false,
        errors: '',
        [action.props.structureId]: newStructureType[action.props.structureId]
      }
    });
    if(action.props.callback){
      yield call(action.props.callback);
    }
    
  } catch (error) {
    yield loading(false, error);
  }

}


function* editEventRecommendation(action) {
  try {
    yield loading();
    

    let loadedStructureType = yield select(store=>store.structureType)
    let eventRecommendation = yield call(ApiEventRecommendation.editEventRecommendation, action.props);
    let newStructureType = cloneDeep(loadedStructureType)
    let index = newStructureType[action.props.structureId].eventRecommendations.findIndex(e => e.id === eventRecommendation.id)
    if(index !== -1){
      newStructureType[action.props.structureId].eventRecommendations[index] = eventRecommendation
    }
    //replace old with new event
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        loading: false,
        errors: '',
        [action.props.structureId]: newStructureType[action.props.structureId]
      }
    });
    //clean store data
    yield put({
      type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
        recommendation: {},
        loading: false,
        errors: ''
      }
    });

    if(action.props.callback){
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteEventRecommendation(action) {
  try {
    yield loading();
    // delete Event
    yield call(ApiEventRecommendation.deleteEventRecommendation, action.props.id);

    yield put({ type: actionTypes.SAGA_ASSEMBLY_EVENT, props: { assetEventId: action.props.sectionSelected } });

    yield put({
      type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
        eventRecommendation: {},
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getEventRecommendationById(action) {
  try {
    yield loading();
    const recommendation = yield call(ApiEventRecommendation.getEventRecommendationById, action.props.value.eventRecommendationId)

    yield put({
      type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
        recommendation: recommendation,
        loading: false,
        errors: ''
      }
    });

    yield call(action.props.callback);
  } catch (error) {
    yield loading(false, error);
  }

}

function* addEventRecommendationPrevtoNew(action) {
  try {
    yield loading();
    yield call(ApiEventRecommendation.addEventRecommendationPrevtoNew, action.props);

    yield put({
      type: actionTypes.SAGA_ASSEMBLY_EVENT,
      props: { assetEventId: action.props.sectionSelected }
    });

    yield put({
      type: actionTypes.SAGA_ASSETEVENT_BY_ASSET_GET,
      props: { data: { assetEventId: action.props.sectionSelected } }
    });

    // update state for Event
    yield put({
      type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
        eventRecommendation: {},
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getStatsRecommendationByClient(action) {
  try {
    yield loading();
    const recommendationStatsByClient = yield call(ApiEventRecommendation.getStatsRecommendationByClient, action.props);

    // update state for Event
    yield put({
      type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
        recommendationStatsByClient: recommendationStatsByClient,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getStatsRecommendationByClientPaged(action) {
  try {
    yield loading();
    const recommendationStatsByClientPaged = yield call(ApiEventRecommendation.getStatsRecommendationByClientPaged, action.props);
    // update state for Event
    yield put({
      type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
        recommendationStatsByClientPaged: recommendationStatsByClientPaged.content,
        pagination: {
          size: recommendationStatsByClientPaged.size,
          totalPages: recommendationStatsByClientPaged.totalPages,
          totalElements: recommendationStatsByClientPaged.totalElements,
          numberOfElements: recommendationStatsByClientPaged.numberOfElements,
          number: recommendationStatsByClientPaged.number
        },
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* editEventRecommendationEstimate(action) {
  try {
    yield loading();
    yield call(ApiEventRecommendation.editEventRecommendationEstimate, action.props.props);
    
    yield put({
      type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
    if (action.props.callback)
      yield call(action.props.callback);
  } catch (error) {
    yield loading(false, error);
  }
}

function* getEventRecommendationLastRefreshView() {
  try {
    yield loading();
    const lastRefreshView = yield call(ApiEventRecommendation.getEventRecommendationLastRefreshView);
    
    yield put({
      type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
        loading: false,
        errors: '',
        lastRefreshView
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}




function* getAllPriorities() {
  let loadedFields = yield select(store => store.recommendations.prioritiesOptions)

  try {
    if(!loadedFields || loadedFields.length === 0){
      yield loading();
      const allPriorities = yield call(ApiEventRecommendation.getAllPriorities);
      yield put({
        type: actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS, props: {
          loading: false,
          errors: '',
          prioritiesOptions:allPriorities
        }
      });
    }
  } catch (error) {
    yield loading(false, error);
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_ALL, getEvents),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_GET, getEventRecommendation),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_ADD, addEventRecommendation),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_DELETE, deleteEventRecommendation),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_GET_BY_ID, getEventRecommendationById),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_EDIT, editEventRecommendation),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_PREV_TO_NEW, addEventRecommendationPrevtoNew),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_STATS_BY_CLIENT, getStatsRecommendationByClient),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_STATS_BY_CLIENT_PAGED, getStatsRecommendationByClientPaged),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_ESTIMATE_EDIT, editEventRecommendationEstimate),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_PRIORITIES_GET_ALL,getAllPriorities),
    takeLatest(actionTypes.SAGA_EVENTRECOMMENDATION_LAST_REFRESH_VIEW, getEventRecommendationLastRefreshView),
  ]);
}

export default AuthWatcher;
