import { all, call, put, takeLatest,select, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiAssemblyType from 'apiServices/ApiAssemblyType'
import { ERROR_TYPE_DELETE_CONSTRAINTS } from 'common/constants/errorTypeConstants';

import { clearAssemblyTypeErrors } from '../actions/AssemblyTypeActions';
import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast } from '../actions/ToastActions';

const recordType = "assembly type";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (typeof errors === "object" && errors.type && errors.type === "constraintViolation") {
      errors = {
        type: ERROR_TYPE_DELETE_CONSTRAINTS
      }
    } else {
      errors = 'Something has not gone as it should.';
    }
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props });
}

/*
 * get AssemblyTypes
 */

function* getAssemblyTypes() {
  try {
    let loadedTypes = yield select(store => store.event.assemblyTypes.data);
    if(loadedTypes.length === 0){
      yield loading();
      // get assembly types
      const allAssemblys = yield call(ApiAssemblyType.getAll);
      // update state for assemblys
      yield put({
        type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
          assemblyTypes: allAssemblys,
          loading: false,
          errors: ""
        }
    });}
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchAssemblyTypes(action) {
  try {
    yield loading();

    // get all AssemblyTypes
    const assemblyTypes = yield call(ApiAssemblyType.searchAssemblyTypes, action.props);
    const pagination = {
      number: assemblyTypes.number,
      numberOfElements: assemblyTypes.numberOfElements,
      totalElements: assemblyTypes.totalElements,
      totalPages: assemblyTypes.totalPages,
      size: assemblyTypes.size
    };
    // update state for assemblyTypes
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
        assemblyTypes: assemblyTypes.content,
        pagination: pagination,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }
}

function* getAssemblyType(action) {
  try {
    yield loading();

    // get  AssemblyType
    const assemblyType = yield call(ApiAssemblyType.getAssemblyType, action.props.assemblyTypeId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: assemblyType.name,
        id: assemblyType.id,
        type: "assemblyType"
      }
    });
    // update state for assemblyTypes
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
        assemblyType: assemblyType,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* editAssemblyType(action) {

  yield put(clearAssemblyTypeErrors());

  try {
    yield loading();

    // update  AssemblyType
    yield call(ApiAssemblyType.editAssemblyType, action.props);
    // update state for assemblyTypes
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
        assemblyType: {},
        loading: false,
        errors: ""
      }
    });
    yield put(showRecordUpdatedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }
}

function* addAssemblyType(action) {

  yield put(clearAssemblyTypeErrors());

  try {
    yield loading();

    // add  AssemblyType
    yield call(ApiAssemblyType.addAssemblyType, action.props);
    // update state for assemblyTypes
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
        assemblyType: {},
        loading: false,
        errors: ""
      }
    });
    yield put(showRecordAddedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteAssemblyType(action) {

  yield put(clearAssemblyTypeErrors());

  try {
    yield loading();

    // delete  AssemblyType
    yield call(ApiAssemblyType.deleteAssemblyType, action.props);

    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
        loading: false,
        errors: ""
      }
    });
    yield put(showRecordDeletedToast(recordType));

    if (action.props.callback) {
      yield call(action.props.callback());
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteAssemblyTypeList(action) {

  yield put(clearAssemblyTypeErrors());

  try {
    yield loading();

    yield call(ApiAssemblyType.deleteAssemblyTypeList, action.props.list);
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
        loading: false,
        errors: ""
      }
    });
    yield put(showRecordDeletedToast(recordType));

    if (action.props.callback) {
      yield call(action.props.callback());
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiAssemblyType.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

function* getAssemblyTypesByOrganization() {

  try {
    let assemblyTypesLoaded = yield select(store=>store.assemblyType.assemblyTypesByOrganization)

    if(!assemblyTypesLoaded || assemblyTypesLoaded.length === 0){

    yield loading();

    let assemblyTypes = yield call(ApiAssemblyType.getAssemblyTypesByOrganization);

    // update state for superstructureTypes
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
        assemblyTypesByOrganization: assemblyTypes,
        loading: false,
        errors: ""
      }
    });
   
  }
  } catch (error) {
    yield loading(false, error);
  }
}

function* getAssemblyTypesByStructure(action) {

  try {
    yield loading();
    let assemblyTypes = yield call(ApiAssemblyType.getAssemblyTypesByStructure,action.props);

    // update state for superstructures
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
        assemblyTypesByStructure: assemblyTypes,
        loading: false,
        errors: ""
      }
    });
   
  } catch (error) {
    yield loading(false, error);
  }
}

function* getAllApproachesTypes() {

  try {
    yield loading();
    let approachesTypes = yield call(ApiAssemblyType.getAllApproachesTypes);
    
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
        approachesTypes: approachesTypes,
        loading: false,
        errors: ""
      }
    });
   
  } catch (error) {
    yield loading(false, error);
  }
}


/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPE_ALL, getAssemblyTypes),
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPE_SEARCH, searchAssemblyTypes),
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPE_GET, getAssemblyType),
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPE_EDIT, editAssemblyType),
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPE_ADD, addAssemblyType),
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPE_DELETE, deleteAssemblyType),
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPE_DELETE_CHECKED, deleteAssemblyTypeList),
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPE_LOAD_ORGANIZATIONS, loadOrganizations),
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPES_BY_ORGANIZATION, getAssemblyTypesByOrganization),
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPES_BY_STRUCTURE, getAssemblyTypesByStructure),
    takeLatest(actionTypes.SAGA_ASSEMBLYTYPES_APPROACHES_TYPES, getAllApproachesTypes),
  ]);
}

export default AuthWatcher;
