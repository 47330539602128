import * as actionTypes from '../actions/actionTypes'
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {assetTypeFields} from 'common/objectStructures'
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const initialState = {
  assetTypes: [],
  assetType: {},
  formValues: null,
  pagination: {},
  organizations: createState()
}

function reducerAssetType (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_ASSETTYPE_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(assetTypeFields)) {
          let defaultValue = "";
          switch (value.type) {
            case "number":
              defaultValue = 0;
              break;

            case "bool":
              defaultValue = false;
              break;

            case "list":
              defaultValue = [];
              break;

            default:
              defaultValue = "";
          }
          newValues[key] = action.props.formValues[key] || defaultValue;
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_ASSET_TYPES_PROPS:
      return initialState;

    case actionTypes.CLEAR_ASSET_TYPES_ERRORS:
      return {
        ...state,
        errors: ''
      }

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerAssetType),
  {
    organizations: [
      actionTypes.CHANGE_ASSETTYPE_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_ASSETTYPE_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_ASSETTYPE_ERROR_LOADING_ORGANIZATIONS
    ]
  }
);
