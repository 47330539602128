import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'

import ApiComponentEvent from 'apiServices/ApiComponentEvent'
import { cloneDeep } from 'lodash';
import { showToastAsError } from 'store/actions/ToastActions';


/*
 * get Components
 */
function* getComponentsEvent() {
  // get component by Assembly
  const allComponentsEvent = yield call(ApiComponentEvent.getAll);
  // update state for components
  yield put({
    type: actionTypes.CHANGE_COMPONENTEVENT_PROPS, props: {
      componentsEvent: allComponentsEvent,
    }
  });
}

function* searchComponentsEvent(action) {
  // search all Components
  const componentsEvent = yield call(ApiComponentEvent.searchComponentsEvent, action.props);
  // update state for components
  yield put({
    type: actionTypes.CHANGE_COMPONENTEVENT_PROPS, props: {
      componentsEvent: componentsEvent.content,
    }
  });
}

function* getComponentEvent(action) {
  // get component
  const componentEvent = yield call(ApiComponentEvent.getComponentEvent, action.props.componentId);
  yield put({
    type: actionTypes.CHANGE_NAMES_PROPS, props: {
      name: componentEvent.name,
      id: componentEvent.id,
      type: "component"
    }
  });
  if (Object.keys(action.props).length > 1)
    yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 4, ids: action.props, last: "component" } });
  // update state for components
  yield put({
    type: actionTypes.CHANGE_COMPONENTEVENT_PROPS, props: {
      componentEvent: componentEvent,
    }
  });
}

function* editComponentEvent(action) {
  let props = action.props;
  if (action.props.getComponents)
    props = props.props;
  // update  Component
  let editedComponent = yield call(ApiComponentEvent.editComponentEvent, props);
  let allComponents = yield select(store => store.componentEvent.componentsEvent)
  let copiedComponents = cloneDeep(allComponents)
  let index = copiedComponents.findIndex(component => component.id === editedComponent.id)

  copiedComponents[index] = editedComponent
  // update state for components
  yield put({
    type: actionTypes.CHANGE_COMPONENTEVENT_PROPS, props: {
      componentsEvent: copiedComponents,
    }
  });
  if (action.props.getComponents)
    yield getAllComponentEvent({ props: props.assemblyEvent.id })
}

function* editMultipleComponentEvent(action) {
  let props = action.props;
  if (action.props.getComponents)
    props = props.props;
  // update  Component
  yield call(ApiComponentEvent.editMultipleComponentEvent, props);
  // update state for components
  yield put({
    type: actionTypes.CHANGE_COMPONENTEVENT_PROPS, props: {
      componentEvent: {},
    }
  });
  if (action.props.getComponents)
    yield getAllComponentEvent({ props: props.assemblyEvent.id })
}

function* addComponentEvent(action) {
  let props = action.props;
  if (action.props.getComponents)
    props = props.props;

  // add  Component
  let componentEvent = yield call(ApiComponentEvent.addComponentEvent, props);
  // update state for components
  yield put({
    type: actionTypes.CHANGE_COMPONENTEVENT_PROPS, props: {
      newComponentEvent: componentEvent,
    }
  });

  if (action.props.getComponents)
    yield getAllComponentEvent({ props: props.assemblyEvent.id })
}

function* deleteComponentEvent(action) {
  // delete  Component
  let componentsEvent = cloneDeep(yield select(store => store.componentEvent.componentsEvent));

  yield call(ApiComponentEvent.deleteComponentEvent, action.props.id);
  let assemblyEvent = cloneDeep(yield select(store => store.assemblyEvent.newAssemblyEvent));

  if (assemblyEvent.length > 0 && action.props.isFromAddMemberPage) {
    let index = assemblyEvent[0].componentEvents.findIndex(x => x.id === action.props.id)
    assemblyEvent[0].componentEvents.splice(index, 1)
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        newAssemblyEvent: assemblyEvent,
      }
    });
  } else {

    let componentEventToSave = componentsEvent?.filter(e => e.id !== action.props.id)
    yield put({
      type: actionTypes.CHANGE_COMPONENTEVENT_PROPS, props: {
        componentsEvent: componentEventToSave,
      }
    });
  }
}

function* deleteMultipleComponentEvent(action) {
  // delete  Component

  yield call(ApiComponentEvent.deleteMultipleComponentEvent, action.props.componentsIdsArray);

}

function* getAllComponentEvent(action) {
  // get all component fields
  const componentsEvent = yield call(ApiComponentEvent.getAllComponentEvent, action.props);
  // update state for camponents
  yield put({
    type: actionTypes.CHANGE_COMPONENTEVENT_PROPS, props: {
      componentsEvent: componentsEvent,
    }
  });
}


function* updateSingleDynamicField(action) {

  const { componentEventId, fieldName, newValue, isFromAddMemberPage } = action.props;

  try {
    yield call(ApiComponentEvent.updateDynamicField, componentEventId, fieldName, newValue);
    let assemblyEvent = cloneDeep(yield select(store => store.assemblyEvent.newAssemblyEvent));
    let assembliesList = cloneDeep(yield select(store => store.assemblyEvent.assemblysEvent));

    if (assemblyEvent.length > 0 && isFromAddMemberPage) {
      let index = assemblyEvent[0].componentEvents.findIndex(x => x.id === componentEventId)
      let newComponent = cloneDeep(assemblyEvent[0].componentEvents.find(x => x.id === componentEventId))
      newComponent[fieldName] = newValue;
      assemblyEvent[0].componentEvents.splice(index, 1)
      assemblyEvent[0].componentEvents.push(newComponent)
      assemblyEvent[0].componentEvents.sort((a, b) => a.componentOrder > b.componentOrder ? 1 : -1);
      yield put({
        type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
          newAssemblyEvent: assemblyEvent,
        }
      });
    } else {
      assembliesList.forEach(assembly => {
        assembly.components.forEach(element => {
          if (element.id === componentEventId) {
            element[fieldName] = newValue
          }
        });
      });

      yield put({
        type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
          assemblysEvent: assembliesList,
        }
      });

    }

  } catch (error) {
    yield put(showToastAsError("Error updating field '" + fieldName + "'", "There was an error updating the field, please try again later."));
  }
}


function* updateComponentsOrders(action) {
  try {

    let props = action.props;

    // update   Assembly
    yield call(ApiComponentEvent.updateComponentsOrders, props);

  } catch (error) {
  }
}


function* bulkEditComponentEvent(action) {
  try {

    let props = action.props.props;
    let componentEvents = yield call(ApiComponentEvent.bulkEditComponentEvent, props);
    
    yield put({
      type: actionTypes.CHANGE_COMPONENTEVENT_PROPS, props: {
        componentsEvent: componentEvents,
      }
    });

    /**
     * Update the assembly properties references
     */
    let assembliesList = cloneDeep(yield select(store => store.assemblyEvent.assembliesEventArray))
    let newAssembly = assembliesList.find((e) => action.props.props.assemblyEventId === e.id);
    let index = assembliesList.findIndex((e) => action.props.props.assemblyEventId === e.id)
    if(action.props.props.count){
      newAssembly.count1 = action.props.props.count
    }
    if(action.props.props.depth1){
      newAssembly.depth = parseInt(action.props.props.depth1)
    }
    if(action.props.props.width2){
      newAssembly.width = parseInt(action.props.props.width2)
    }

    //Replace the updated assembly in the list
    assembliesList[index] = newAssembly
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        assembliesEventArray: assembliesList,
        assemblysEvent:assembliesList
      }
    });


    if (action.props.callback) {
      action.props.callback()
    }
  } catch (error) {
  }
}


/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_COMPONENTEVENT_ALL, getComponentsEvent),
    takeLatest(actionTypes.SAGA_COMPONENTEVENT_GET, getComponentEvent),
    takeLatest(actionTypes.SAGA_COMPONENTEVENT_SEARCH, searchComponentsEvent),
    takeLatest(actionTypes.SAGA_COMPONENTEVENT_EDIT, editComponentEvent),
    takeLatest(actionTypes.SAGA_COMPONENTEVENT_ADD, addComponentEvent),
    takeLatest(actionTypes.SAGA_COMPONENTEVENT_DELETE, deleteComponentEvent),
    takeLatest(actionTypes.SAGA_COMPONENTEVENT_DELETE_MULTIPLE, deleteMultipleComponentEvent),
    takeLatest(actionTypes.SAGA_COMPONENTEVENT_ALL_EVENT, getAllComponentEvent),
    takeLatest(actionTypes.SAGA_MULTIPLE_COMPONENTEVENT_EDIT, editMultipleComponentEvent),
    takeEvery(actionTypes.SAGA_COMPONENTEVENT_UPDATE_SINGLE_DYNAMIC_FIELD, updateSingleDynamicField),
    takeLatest(actionTypes.UPDATE_COMPONENTS_ORDER, updateComponentsOrders),
    takeLatest(actionTypes.BULK_EDIT_COMPONENT_EVENT, bulkEditComponentEvent),
  ]);
}

export default AuthWatcher;
