import { NavLink } from "react-router-dom";
import {
  NavItem,
  Button,
  NavLink as BSNavLink
} from "reactstrap";
import {
  MdMail,
  MdLocationOn
} from "react-icons/md";
import {
  FaUserTie,
  FaPencilAlt,
  FaTrain,
  FaUsers,
  FaCubes,
  FaEnvelope,
  FaUserCircle,
  FaRegIdCard,
  FaRegBuilding,
  FaListUl,
  FaFileAlt,
} from "react-icons/fa";
import { TiFlowChildren } from "react-icons/ti";
import { getMenuIcon } from "common/svgitems";
import { connect } from "react-redux";
import { changeHeaderProps } from "store/actions/HeaderActions";
import {
  // apps routes
  ROUTE_CLIENTS,
  ROUTE_USERS,
  ROUTE_MESSAGES,
  ROUTE_ASSEMBLYTYPES,
  ROUTE_COMPONENTTYPES,
  ROUTE_DOCUMENTTYPES,
  ROUTE_COMPONENTGROUPS,
  ROUTE_ROLES,
  ROUTE_CLAIMS,
  ROUTE_STRUCTURETYPES,
  ROUTE_IMPACTTYPES,
  ROUTE_ASSETTYPES,
  ROUTE_CROSSINGFEATURES,
  ROUTE_TRIPS,
  ROUTE_JOBTITLES,
  ROUTE_CONTACTS,
  ROUTE_ACCESSTYPES,
  ROUTE_ORGANIZATIONS,
  ROUTE_CONDITIONS,
  ROUTE_LOCATIONS,
  ROUTE_EVENTS_DASHBOARD,
  ROUTE_ALL_ASSETS,
} from "config/constants";
import Utils from "common/utils";


const Sidebar = (props) => {

  const {
    currentUserIsSuperAdmin,
    sidebarActive,
    handleSidebarControlButton,
    sideBarOpen
  } = props

  const getNavItems = () => {
    let navItems = [
      {
        to: ROUTE_EVENTS_DASHBOARD,
        name: "Inspections",
        exact: false,
        Icon: FaPencilAlt
      },
      {
        to: ROUTE_ALL_ASSETS,
        name: "Bridges",
        exact: false,
        Icon: FaTrain
      },
      {
        to: ROUTE_CLIENTS,
        name: "Clients",
        exact: false,
        Icon: FaUserTie
      },
      {
        to: ROUTE_TRIPS,
        name: "Trips",
        exact: false,
        Icon: MdLocationOn
      }
    ];

    return navItems;
  };

  const navSettings = () => {
    let ret = [
      { to: ROUTE_USERS, name: "Users", exact: false, Icon: FaUsers },
      { to: ROUTE_ROLES, name: "Roles", exact: false, Icon: FaCubes },
      { to: ROUTE_MESSAGES, name: "Messages", exact: false, Icon: FaEnvelope },
      { to: ROUTE_CONTACTS, name: "Contacts", exact: false, Icon: FaUserCircle },
      { to: ROUTE_JOBTITLES, name: "Job Titles", exact: false, Icon: FaRegIdCard },
      { to: ROUTE_DOCUMENTTYPES, name: "Document Types", exact: false, Icon: FaFileAlt }
    ];

    //Organizations are only available for SuperAdmin
    if (currentUserIsSuperAdmin) {
      ret.push({
        to: ROUTE_ORGANIZATIONS,
        name: "Organizations",
        exact: false,
        Icon: FaRegBuilding
      },
      { to: ROUTE_CLAIMS,
        name: "Claims",
        exact: false,
        Icon: FaListUl });
    }

    return ret;
  }

  const navAssetsComponents = () => [{
    to: ROUTE_ACCESSTYPES,
    name: "Access Types",
    exact: false,
    Icon: MdMail
  },
  {
    to: ROUTE_ASSEMBLYTYPES,
    name: "Assembly Types",
    exact: false,
    Icon: MdMail
  },
  {
    to: ROUTE_ASSETTYPES,
    name: "Asset Types",
    exact: false,
    Icon: MdMail
  },
  {
    to: ROUTE_COMPONENTGROUPS,
    name: "Component Groups",
    exact: false,
    Icon: MdMail
  },
  {
    to: ROUTE_COMPONENTTYPES,
    name: "Component Types",
    exact: false,
    Icon: MdMail
  },
  {
    to: ROUTE_CONDITIONS,
    name: "Conditions",
    exact: false,
    Icon: TiFlowChildren
  },
  {
    to: ROUTE_CROSSINGFEATURES,
    name: "Crossing Features",
    exact: false,
    Icon: MdMail
  },
  {
    to: ROUTE_IMPACTTYPES,
    name: "Impact Types",
    exact: false,
    Icon: MdMail
  },

  {
    to: ROUTE_LOCATIONS,
    name: "Locations",
    exact: false,
    Icon: TiFlowChildren
  },
  {
    to: ROUTE_STRUCTURETYPES,
    name: "Structure Types",
    exact: false,
    Icon: MdMail
  }
  ];

    let open = true;
    if (document.querySelector(".sidebar") != null) {
      open = document
        .querySelector(".sidebar")
        .classList.contains("side-bar--open");
    }
  
    return (
      <nav id="sidebar" className="sidebar-wrapper">
        <div className="sidebar-content desktop">
          <div id="mCSB_1" className="mCSB_inside" style={{maxHeight: "none"}} tabIndex="0">
            <div id="mCSB_1_container" className="mCSB_container" style={{position:"relative", top:0, left:0,}} dir="ltr">
              <div className=" sidebar-item sidebar-menu">
                  <div className="sidebar-icon">
                    <Button
                      className="mr-1 toggle-button-inside"
                      color="link"
                      onClick={handleSidebarControlButton}>
                     {getMenuIcon(sideBarOpen)}
                    </Button>
                  </div>
                <ul>
                  {

                    (sidebarActive === 'assetBridge' && window.location.pathname !== ROUTE_ACCESSTYPES && window.location.pathname !== ROUTE_USERS) &&
                    getNavItems().map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index}>
                        <BSNavLink
                          className="side-bar_nav-item"
                          id={`navItem-${name}-${index}`}
                          tag={NavLink}
                          to={to}
                          exact={exact.toString()}
                        >
                          <div className="d-flex">
                            <Icon className="nav-item-icon mr-3" />
                            <span className={!open ? "side-bar__nav-item-label" : "nav-item-label"}>{name}</span>
                          </div>
                        </BSNavLink>
                      </NavItem>
                    ))
                  }
                  {
                    (sidebarActive === 'managementAsset' || window.location.pathname === ROUTE_ACCESSTYPES) &&
                    navAssetsComponents().map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index}>
                        <BSNavLink
                         className="side-bar_nav-item"
                          tag={NavLink}
                          to={to}
                          exact={exact.toString()}
                        >
                          <div className="d-flex">
                            <Icon className="nav-item-icon mr-3" />
                            <span className={!open ? "side-bar__nav-item-label" : "nav-item-label"}>{name}</span>
                          </div>
                        </BSNavLink>
                      </NavItem>
                    ))
                  }
                  {
                    (sidebarActive === 'managementSettings' || window.location.pathname === ROUTE_USERS) &&
                    navSettings().map(({ to, name, exact, Icon }, index) => (
                      <NavItem key={index}>
                        <BSNavLink
                          className="side-bar_nav-item"
                          tag={NavLink}
                          to={to}
                          exact={exact.toString()}
                        >
                          <div className="d-flex">
                            <Icon className="nav-item-icon mr-3" />
                            <span className={!open ? "side-bar__nav-item-label" : "nav-item-label"}>{name}</span>
                          </div>
                        </BSNavLink>
                      </NavItem>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

    );
  }


function mapStateToProps(store) {
  return {
    sideBar: store.header.sideBar,
    user: store.claim.userClaims,
    sidebarActive: store.menuActive.sidebarActive,
    currentUserIsSuperAdmin: Utils.isSuperAdmin(store.auth.session)
  };
}

export default connect(mapStateToProps, { changeHeaderProps })(Sidebar);
