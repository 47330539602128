import { all, call, put, takeLatest,takeEvery,select, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiStructureType from 'apiServices/ApiStructureType'

import { clearStructureTypeErrors, clearStructureTypeProps } from '../actions/StructureTypeActions';
import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast, showErrorAddingRecord, showErrorUpdatingRecord } from '../actions/ToastActions';

const recordTypeName = "structure type";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The Structure type cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props });
}

/*
 * get StructureTypes
 */
function* getStructureTypes() {
  let loadedstructureTypes = yield select(store=>store.structureType.structureTypes)
 
  try {
    if(loadedstructureTypes.length === 0 || !loadedstructureTypes){
    yield loading();
    // get structure types
    const allStructures = yield call(ApiStructureType.getAll);
    // update state for structures
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        structureTypes: allStructures,
        loading: false,
        errors: ''
      }
    });}
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchStructureTypes(action) {
  try {
    yield loading();
    // get all StructureTypes
    const structureTypes = yield call(ApiStructureType.searchStructureTypes, action.props);
    const pagination = {
      number: structureTypes.number,
      numberOfElements: structureTypes.numberOfElements,
      totalElements: structureTypes.totalElements,
      totalPages: structureTypes.totalPages,
      size: structureTypes.size
    };
    // update state for structureTypes
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        structureTypes: structureTypes.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getStructureType(action) {
  try {
    yield loading();
    // get  StructureType
    const structureType = yield call(ApiStructureType.getStructureType, action.props.structureTypeId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: structureType.name,
        id: structureType.id,
        type: "structureType"
      }
    });

    // update state for structureTypes
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        structureType: structureType,
        loading: false,
        errors: '',
        ["" + structureType.id]: structureType
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editStructureType(action) {

  yield put(clearStructureTypeErrors());

  try {
    yield loading();
    // update  StructureType
    yield call(ApiStructureType.editStructureType, action.props);
    // update state for structureTypes
    yield put(clearStructureTypeProps());
    yield put(showRecordUpdatedToast(recordTypeName));
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        structureTypes: [],
        loading: false,
        errors: ''
      }
    })
  } catch (error) {
    yield put(showErrorUpdatingRecord(recordTypeName));
    // yield loading(false, error);
  }

}

function* addStructureType(action) {

  yield put(clearStructureTypeErrors());

  try {
    yield loading();
    // add  StructureType
    yield call(ApiStructureType.addStructureType, action.props);
    // update state for structureTypes
    yield put(clearStructureTypeProps());
    yield put(showRecordAddedToast(recordTypeName));
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        structureTypes: [],
        loading: false,
        errors: ''
      }
    })
  } catch (error) {
    yield put(showErrorAddingRecord(recordTypeName));
    // yield loading(false, error);
  }

}

function* deleteStructureType(action) {

  yield put(clearStructureTypeErrors());

  try {
    yield loading();
    // delete  StructureType
    yield call(ApiStructureType.deleteStructureType, action.props);
    yield put(clearStructureTypeProps());
    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        structureTypes: [],
        loading: false,
        errors: ''
      }
    })
    
  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteStructureTypeList(action) {

  yield put(clearStructureTypeErrors());

  try {
    yield loading();
    // delete  StructureType
    yield call(ApiStructureType.deleteStructureTypeList, action.props.list);
    yield put(clearStructureTypeProps());
    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* getSingleStructureType(action) {
  try {

    // yield put({ type: actionTypes.CHANGE_AUTH_PROPS, props:{
    //   loading: true,
    //   errors:''
    // }});

    yield loading();
    // get structure types
    const structureType = yield call(ApiStructureType.getSingleStructureType,action.props.id, action.props.assetId);
    // update state for structures
    const structureTypeId =structureType.structureType.id ;
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        // structureType: structureType,
        loading: false,
        errors: '',
        [structureTypeId]: structureType
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}


function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiStructureType.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}


function* loadAssetTypes() {
  try {
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_START_LOADING_ASSET_TYPES
    });

    const assetTypes = yield call(ApiStructureType.getAssetTypes);
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_SUCCESS_LOADING_ASSET_TYPES,
      props: {
        assetTypes
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_ERROR_LOADING_ASSET_TYPES,
      error: e.message
    })
  }
}



function* getComponents(action) {
  try {
    yield loading();
    // get  StructureType
    const components = yield call(ApiStructureType.getComponents, action.props);
    
    yield putResolve({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        structureTypecomponents: components,
        loading: false
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_STRUCTURETYPE_ALL, getStructureTypes),
    takeLatest(actionTypes.SAGA_STRUCTURETYPE_SEARCH, searchStructureTypes),
    takeLatest(actionTypes.SAGA_STRUCTURETYPE_GET, getStructureType),
    takeLatest(actionTypes.SAGA_STRUCTURETYPE_EDIT, editStructureType),
    takeLatest(actionTypes.SAGA_STRUCTURETYPE_ADD, addStructureType),
    takeLatest(actionTypes.SAGA_STRUCTURETYPE_DELETE, deleteStructureType),
    takeLatest(actionTypes.SAGA_STRUCTURETYPE_DELETE_CHECKED, deleteStructureTypeList),
    takeEvery(actionTypes.SAGA_SINGLESTRUCTURETYPE_GET, getSingleStructureType),
    takeLatest(actionTypes.SAGA_STRUCTURETYPE_LOAD_ORGANIZATIONS, loadOrganizations),
    takeLatest(actionTypes.SAGA_STRUCTURETYPE_LOAD_ASSET_TYPES, loadAssetTypes),
    takeLatest(actionTypes.GET_STRUCTURE_TYPE_COMPONENTS, getComponents)
  ]);
}

export default AuthWatcher;
