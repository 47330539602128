import * as actionTypes from './actionTypes'

/**
 * Redux actions for "Access Type".
 * @namespace AccessTypeActions
 */

/**
 * @memberof AccessTypeActions
 * @description Creates a Redux action that can be dispatched
 * to trigger the fetch of all "Access Type".
 *
 * This will trigger a SAGA action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getAccessTypes(){
  return { type: actionTypes.SAGA_ACCESSTYPE_ALL };
}

/**
 *
 * @memberof AccessTypeActions
 * @typedef filter
 * @property {string} id - Name of the property to filter.
 * @property {string} value - Value for filtering.
 * @property {string[]} arrayValue - Values for filtering. Use
 * this to apply more than one filter on the same property.
 */
/**
 * @memberof AccessTypeActions
 * @typedef {object} order
 * @property {string} id - Name of the property to order by.
 * @property {boolean} desc - true if the order has to be
 * descendant, false otherwise.
 */

/**
 * @memberof AccessTypeActions
 * @description Creates a Redux action that can be dispatched
 * to trigger the fetch of "Access Type" with pagination.
 * @deprecated We are not using pagination to get the accessTypes
 * @param {object} props
 * @param {number} props.page - Number of page.
 * @param {number} props.limit - Number of elements per page.
 * @param {string} props.search - Search keywords.
 * @param {filter[]} props.filterArr - Filters to apply.
 * @param {order} props.order - Property to order by.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadAccessTypePaged(props){
  return { type: actionTypes.SAGA_ACCESSTYPE_SEARCH, props: props };
}

/**
 * @memberof AccessTypeActions
 * @description Creates a Redux action that can be dispatched to
 * trigger the fetch of an "Access Type".
 *
 * This will trigger a SAGA action.
 *
 * @param {object} props
 * @param {string} props.accessTypeId - The ID of the "Access Type"
 * to fetch.
 * @returns A Redux action that can be dispatched.
 */
export function getAccessType(props){
  return { type: actionTypes.SAGA_ACCESSTYPE_GET, props: props };
}

/**
 * @memberof AccessTypeActions
 * @description Creates a Redux action that can be dispatched to
 * trigger the edition of an "Access Type".
 *
 * Any value added to props is going to be used as a
 * property of the model.
 *
 * This will trigger a SAGA action.
 *
 * @param {object} props
 * @param {string} props.accessTypeId
 * @returns A Redux action that can be dispatched.
 */
export function editAccessType(props){
  return { type: actionTypes.SAGA_ACCESSTYPE_EDIT, props: props };
}

/**
 * @memberof AccessTypeActions
 * @description Creates a Redux action that can be dispatched
 * to trigger the creation of an "Access Type".
 *
 * Any value added to props is going to be used as a
 * property of the model.
 *
 * This will trigger a SAGA action.
 *
 * @param {object} props
 * @returns a Redux action that can be dispatched
 */
export function addAccessType(props){
  return { type: actionTypes.SAGA_ACCESSTYPE_ADD, props: props };
}

export function deleteAccessType(props){
  return { type: actionTypes.SAGA_ACCESSTYPE_DELETE, props: props };
}

export function deleteAccessTypeList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_ACCESSTYPE_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

/**
 * @deprecated Use more granular actions. This is too
 * generic and cannot be understood easily.
 * @memberof AccessTypeActions
 * @description Creates a Redux action that changes
 * "Access Type" props.
 *
 * No SAGA action will be triggered.
 *
 * @param {object} props - props to change.
 * @returns A Redux action that can be dispatched.
 */
export function changeAccessTypeProps(props){
  return { type: actionTypes.CHANGE_ACCESSTYPE_PROPS, props: props };
}

/**
 * @memberof AccessTypeActions
 * @description Creates a Redux action that
 * clears "Access Type" props.
 *
 * No SAGA action will be triggered.
 *
 * @returns A Redux action that can be dispatched.
 */
export function clearAccessTypeProps() {
  return { type: actionTypes.CLEAR_ACCESS_TYPES_PROPS };
}

/**
 * @memberof AccessTypeActions
 * @description Clears "Access Type" errors messages.
 *
 * No SAGA action will be triggered.
 *
 * @returns A Redux action that can be dispatched.
 */
export function clearAccessTypeErrors() {
  return { type: actionTypes.CLEAR_ACCESS_TYPES_ERRORS };
}

/**
 * @memberof AccessTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations for AccessTypes.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_ACCESSTYPE_LOAD_ORGANIZATIONS };
}
