import { all, call, put, takeLatest,select } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiMilestone from 'apiServices/ApiMilestone'

/*
 * get Milestones
 */
function* getMilestones(action){

  let loadedMilestones = yield select(store=>store.milestone.milestones)
  if(loadedMilestones.length === 0 || action.props.forceLoad){
    // get first page Milestones
    const allMilestones = yield call(ApiMilestone.getAll);
    // update state for milestones
    yield put({ type: actionTypes.CHANGE_MILESTONE_PROPS, props:{
      milestones: allMilestones,
    }});
  }
}

function* getByEventType(action){
  // get first page Milestones
  const milestones = yield call(ApiMilestone.getByEventType, action.props);
  // update state for milestones
  yield put({ type: actionTypes.CHANGE_MILESTONE_PROPS, props:{
    milestones: milestones,
  }});
}

function* getByMilestoneStatus(action){
  // get first page Milestones
  const milestones = yield call(ApiMilestone.getByMilestoneStatus, action.props);
  // update state for milestones
  yield put({ type: actionTypes.CHANGE_MILESTONE_PROPS, props:{
    milestonesFiltered: milestones
  }});
}

function* getByEvent(action) {

  try {
    // get first page Milestones
    const milestones = yield call(ApiMilestone.getByEvent, action.props);
    // update state for milestones
    yield put({ type: actionTypes.CHANGE_MILESTONE_PROPS, props:{
      milestonesByEvent: milestones,
    }});

  } catch (error) {
    //TODO add an error message
  }
}

function* getMilestoneStatuses(){
  // get first page Milestone Statuses
  let loadedMilestones = yield select(store=>store.milestone.milestoneStatuses)
  if(loadedMilestones.length === 0){
  const allMilestoneStatus = yield call(ApiMilestone.getMilestoneStatuses);
  // update state for milestones status
  yield put({ type: actionTypes.CHANGE_MILESTONE_PROPS, props:{
    milestoneStatuses: allMilestoneStatus,
  }});}
}

function* getMilestoneStatusesByName() {
  let loadedMilestones = yield select(store=>store.milestone.milestoneStatuses)
  if(loadedMilestones.length === 0){
  // get first page Milestone Statuses
  const allMilestoneStatus = yield call(ApiMilestone.getMilestoneStatusesByName);
  // update state for milestones status
  yield put({ type: actionTypes.CHANGE_MILESTONE_PROPS, props:{
    milestoneStatuses: allMilestoneStatus,
  }});
}
}

function* getEventMilestoneStatus(action) {
  // get first page Milestone Statuses
  const eventMilestoneStatus = yield call(ApiMilestone.getEventMilestoneStatus, action.props);
  // update state for milestones status
  yield put({ type: actionTypes.CHANGE_MILESTONE_PROPS, props:{
    eventMilestoneStatus
  }});
}

function* getAllEventMilestoneStatuses(action) {
  // get first page Milestone Statuses
  const eventMilestoneStatuses = yield call(ApiMilestone.getAllEventMilestoneStatuses, action.props);
  // update state for milestones status
  yield put({ type: actionTypes.CHANGE_MILESTONE_PROPS, props:{
    eventMilestoneStatuses
  }});
}

function* putEventMilestoneStatus(action) {
  // get first page Milestone Statuses
  yield call(ApiMilestone.putEventMilestoneStatus, action.props);
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_MILESTONE_ALL, getMilestones),
    takeLatest(actionTypes.SAGA_MILESTONE_BY_EVENTTYPE, getByEventType),
    takeLatest(actionTypes.SAGA_MILESTONE_BY_MILESTONESTATUS, getByMilestoneStatus),
    takeLatest(actionTypes.SAGA_MILESTONE_BY_EVENT, getByEvent),
    takeLatest(actionTypes.SAGA_MILESTONE_STATUS_ALL, getMilestoneStatuses),
    takeLatest(actionTypes.SAGA_MILESTONE_STATUS_ALL_BY_NAME, getMilestoneStatusesByName),
    takeLatest(actionTypes.SAGA_EVENT_MILESTONE_STATUS, getEventMilestoneStatus),
    takeLatest(actionTypes.SAGA_ALL_EVENT_MILESTONE_STATUSES, getAllEventMilestoneStatuses),
    takeLatest(actionTypes.SAGA_PUT_EVENT_MILESTONE_STATUS, putEventMilestoneStatus),
  ]);
}

export default AuthWatcher;
