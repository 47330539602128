import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/assetType';

export default class ApiAssetType {

  // GET 'api/assetTypes'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchAssetTypes(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getAssetType(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editAssetType(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addAssetType(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteAssetType(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static getAssetTypeByAsset(id){
    return Utils.genericCall(`${endpoint}/asset/${id}`);
  }

  static deleteAssetTypeList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getAllByClient(clientId){
    return Utils.genericCall(`${endpoint}/getAllByClient?clientId=` + clientId.clientId);
  }

  static getAllAssetsByOrganization(){
    return Utils.genericCall(`${endpoint}/getAllByOrganization` );
  }
}
