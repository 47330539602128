import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/claim';

export default class ApiClaim {

  // GET 'api/claims'
  static getAll(){
    return Utils.genericCall(`${endpoint}`);
  }

  static searchClaims(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getClaim(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editClaim(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }
  
  static getUserclaims() {
    return Utils.genericCall(API_SERVER + '/claim/auth');
  }
}
