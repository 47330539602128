import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
    milePosts: [],
    formValues: null
}

function reducerMilePost(state = initialState, action) {
    switch (action.type) {

        case actionTypes.CHANGE_MILEPOST_PROPS:
            if (action.props.formValues != null) {
                const newValues = {
                    id: action.props.formValues.id,
                    name: action.props.formValues.name || ""
                };
                action.props.formValues = newValues
            }
            return Object.assign({}, state, action.props)

        default:
            return state
    }
}

export default onLoginCleanupReducer(initialState, reducerMilePost);
