import {
  Navbar,
  NavbarBrand,
  Dropdown,
  Nav,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
} from 'reactstrap';
import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { changeMenuActiveProps } from 'store/actions/MenuActiveActions';
import { getOrganizations } from 'store/actions/OrganizationActions';
import { findClients } from 'store/actions/UserActions';
import { getClient } from 'store/actions/ClientActions';
import { changeSelectedOrganization } from 'store/actions/AuthActions';
import {
  ROUTE_LOGOUT,
  ROUTE_ACCESSTYPES,
  ROUTE_USERS,
  ROUTE_EVENTS_DASHBOARD
} from 'config/constants';
import { Navigate } from 'react-router-dom'
import logoARE from 'assets/img/logo/ARE_01_White.svg';
import Avatar from 'components/Avatar';
import Utils from 'common/utils';
import ModalValueSelector from 'components/ModalValueSelector';

const FullScreenLayout = (props) => {
  const {
    children,
    currentUserIsSuperAdmin,
    currentUserIsClient,
    getOrganizations,
    changeSelectedOrganization,
    getClient,
    organizations,
    clients,
    findClients
  } = props;

  const [redirectTo, setRedirectTo] = React.useState('')
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [showOrganizationSelectorModal, setShowOrganizationSelectorModal] = useState(false);
  const [showClientSelectorModal, setShowClientSelectorModal] = useState(false);

  useEffect(() => {
    if (currentUserIsSuperAdmin) {
      getOrganizations();
    }
    if (currentUserIsClient) {
      findClients();
    }
  }, [currentUserIsSuperAdmin, getOrganizations, currentUserIsClient, findClients]);

  useEffect(() => {
    if(clients && clients.length > 0){
      getClient({clientId: clients[0].id})
    }
  }, [clients, getClient]);

  const handleItemMenuServies = value => {
    let redirectTo = ''
    localStorage.setItem("NavBarLocation", value);
    props.changeMenuActiveProps({
      sidebarActive: value
    })
    switch (value) {
      case 'managementAsset':
        redirectTo = window.location.pathname === ROUTE_ACCESSTYPES ? '' : ROUTE_ACCESSTYPES
        break;
      case 'managementSettings':
        redirectTo = window.location.pathname === ROUTE_USERS ? '' : ROUTE_USERS
        break;
      default:
        redirectTo = window.location.pathname === ROUTE_EVENTS_DASHBOARD ? '' : ROUTE_EVENTS_DASHBOARD
        break;
    }

    setDropdownOpen(!dropdownOpen)
    setRedirectTo(redirectTo)
  }

  const toggle = useCallback(() => {
    setDropdownOpen(prev => !prev)
  }, [setDropdownOpen]);


  const showOrganizationSelector = useCallback(() => {
    setShowOrganizationSelectorModal(true);
  }, [setShowOrganizationSelectorModal]);

  const showClientSelector = useCallback(() => {
    setShowClientSelectorModal(true);
  }, [setShowClientSelectorModal]);

  const hideOrganizationSelector = useCallback(() => {
    setShowOrganizationSelectorModal(false);
  }, [setShowOrganizationSelectorModal]);

  const hideClientSelector = useCallback(() => {
    setShowClientSelectorModal(false);
  }, [setShowClientSelectorModal]);

  const onSelectedOrganizationConfirm = useCallback(selectedOrganization => {
    changeSelectedOrganization(selectedOrganization.id);
    hideOrganizationSelector();
    setRedirectTo("/");
  }, [changeSelectedOrganization, hideOrganizationSelector, setRedirectTo]);

  const onSelectedClientConfirm = useCallback(selectedClient => {
    getClient(selectedClient.id);
    hideClientSelector();
    setRedirectTo("/");
  }, [getClient, hideClientSelector, setRedirectTo]);


  if (redirectTo !== '') return <Navigate to={redirectTo} />

  const defaultOrganization = props.organizations.find(x => x.id === props.user.organization);
  const defaultClient = props.clients[0];
  let tenantDropDownItem = null;
  let clientDropDownItem = null;

  if (currentUserIsSuperAdmin && defaultOrganization && defaultOrganization.name) {
    tenantDropDownItem = <DropdownItem onClick={showOrganizationSelector} className='sign-out'>
      Switch Tenant <small>({defaultOrganization.name})</small>
    </DropdownItem>;
  }

  if (currentUserIsClient && defaultClient && defaultClient.name) {
    clientDropDownItem = <DropdownItem onClick={showClientSelector} className='sign-out'>
      Switch Client <small>({defaultClient.name})</small>
    </DropdownItem>;
  }

  return (
    <>
      <header>
        <Navbar className='navbar fixed-top custom-navbar'>
          <NavbarBrand href="/"><img src={logoARE} alt="img" width="130px" height="35px" className="logo-are-1" /></NavbarBrand>
          <Nav className="mr-auto">
            <Dropdown nav inNavbar isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle nav caret>
                Services
              </DropdownToggle>
              <DropdownMenu className='drop-down-menu'>
                <Row className="h-100">
                  <Col xs={5} className='col-menu-drop-down'>
                    <Row>
                      <Col xs={12} className='section-title'>Assets Group</Col>
                      <Col xs={12} className={`item-title ${props.sidebarActive === 'assetBridge' ? 'menu-active' : ''}`} onClick={() => handleItemMenuServies('assetBridge')}>Bridges </Col>
                      <Col xs={12} className={`item-title ${props.sidebarActive === '' ? 'menu-active' : ''}`}>Tracks</Col>
                    </Row>
                  </Col>
                  <Col xs={5} className='col-menu-drop-down'>
                    <Row>
                      <Col xs={12} className='section-title'>Management</Col>
                      <Col xs={12} className={`item-title ${props.sidebarActive === 'managementAsset' ? 'menu-active' : ''}`} onClick={() => handleItemMenuServies('managementAsset')}>Assets</Col>
                      <Col xs={12} className={`item-title ${props.sidebarActive === 'managementSettings' ? 'menu-active' : ''}`} onClick={() => handleItemMenuServies('managementSettings')}>Settings</Col>
                    </Row>
                  </Col>
                </Row>
              </DropdownMenu>
            </Dropdown>
          </Nav>
          <Nav className="float-right">
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <Avatar size={28} className="can-click avatar" /> <span className='hidden-xs'>{props.user ? props.user.email : ''}</span>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem href={`/users/${props.user.idUser}`} className='sign-out' >Profile</DropdownItem>
                {currentUserIsSuperAdmin ? tenantDropDownItem : null}
                {currentUserIsClient && clients && clients.length > 1 ? clientDropDownItem : null}
                <DropdownItem divider />
                <DropdownItem href={ROUTE_LOGOUT} className='sign-out'>Sign Out </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Navbar>
      </header>
      <main role="main" className="h-100">
        <div className="h-100">
          {children}
        </div>
      </main>


      <ModalValueSelector
        show={currentUserIsSuperAdmin ? showOrganizationSelectorModal : showClientSelectorModal}
        title={currentUserIsSuperAdmin ? "Select an organization" : "Select a client"}
        informationText={currentUserIsSuperAdmin ? "Select an organization from the list below:" : "Select a client from the list below"}
        onConfirm={currentUserIsSuperAdmin ? onSelectedOrganizationConfirm : onSelectedClientConfirm}
        onCancel={currentUserIsSuperAdmin ? hideOrganizationSelector : hideClientSelector}
        models={currentUserIsSuperAdmin ? organizations : clients}
        defaultValue={currentUserIsSuperAdmin ? defaultOrganization : defaultClient}
      />
    </>
  );
}

function mapStateToProps(store) {
  return {
    user: store.auth.session,
    currentUserIsSuperAdmin: Utils.isSuperAdmin(store.auth.session),
    currentUserIsClient: Utils.isClient(store.auth.session),
    organizations: store.organization.organizations,
    clients: store.client.clients
  }
}

export default connect(mapStateToProps, {
  changeMenuActiveProps,
  getOrganizations,
  changeSelectedOrganization,
  findClients,
  getClient
})(FullScreenLayout);
