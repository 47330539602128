import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

let nextToastId = 1;

const toast = {
  showToast: false,
  type: 'error',
  message: '',
  info: '',
  loading: false,
}

const initialState = {
  ...toast,
  activeToasts: []
}

function reducerToast(state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_TOAST_PROPS:
      return {
        ...state,
        ...action.props,
        activeToasts: [
          ...state.activeToasts,
          {
            ...action.props,
            id: nextToastId++
          }
        ]
      }

    case actionTypes.CHANGE_TOAST_REMOVE_ACTIVE:
      return {
        ...state,
        activeToasts: state.activeToasts.filter(toastData => toastData.id !== action.toastId)
      }

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerToast);
