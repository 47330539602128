import { API_SERVER } from 'config/constants';
import Utils from 'common/utils'

const endpoint = API_SERVER;

export default class ApiImage {

  static getFilesAsset(assetEventId) {
    return Utils.genericCall(`${endpoint}/asset/${assetEventId}/documents`);
  }

  static getPhotosAsset(eventId) {
    return Utils.genericCall(`${endpoint}/eventPhoto/event/${eventId}`);
  }

  //   /eventPhoto/event/{eventId}/paged
  static getPhotosAssetPaged(props) {
    return Utils.genericPost(`${endpoint}/eventPhoto/event/${props.eventId}/paged`, props.data);
  }

  // /eventPhoto/bridge/{bridgeId}/year/{year}/paged"
  static getBridgePhotosPaged(props) {
    return Utils.genericPost(`${endpoint}/eventPhoto/bridge/${props.bridgeId}/year/${props.year}/paged`, props.data);
  }

  // "/eventPhoto/{id}"
  static getPhotoById(photoId) {
    return Utils.genericCall(`${endpoint}/eventPhoto/${photoId}`);
  }

  static uploadAssetEventPhoto(props) {
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    if(props.structureTypeId){
      return Utils.genericPost(`${endpoint}/assetEvent/${props.id}/photo/upload?structureTypeId=${props.structureTypeId}`, props.formData, headers);
    } else {
      return Utils.genericPost(`${endpoint}/assetEvent/${props.id}/photo/upload`, props.formData, headers);
    }
    }

  static uploadAssetFile(props) {
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    return Utils.genericPost(`${endpoint}/asset/${props.id}/documents/upload`, props.formData, headers);
  }

  static editPhotoTags(props) {
    return Utils.genericPut(`${endpoint}/eventPhoto/structureTypes`, props)
  }

  static deletePhoto(props) {
    return Utils.genericPost(`${endpoint}/eventPhoto/delete`, props)
  }

  static deletePhotoTags(props) {
    return Utils.genericPost(`${endpoint}/eventPhoto/${props.eventPhotoId}/deleteTags`, props.data)
  }

  // PHOTO INFO:
  // "/eventPhotoAssemblyComponentItemEvent" POST
  static addPhotoInfo(props) {
    return Utils.genericPost(`${endpoint}/eventPhotoAssemblyComponentItemEvent`, props)
  }

  // "/eventPhotoAssemblyComponentItemEvent/{id}" PUT
  static editPhotoInfo(props) {
    return Utils.genericPut(`${endpoint}/eventPhotoAssemblyComponentItemEvent/${props.id}`, props.data)
  }

  // "/eventPhotoAssemblyComponentItemEvent/{id}" DELETE
  static deletePhotoInfo(id) {
    return Utils.genericDelete(`${endpoint}/eventPhotoAssemblyComponentItemEvent/${id}`);
  }

  static getBridgePhotosPagedForCover(bridgeId, data) {
    return Utils.genericPost(`${endpoint}/eventPhoto/bridge/${bridgeId}/paged`, data);
  }

  static editPhotoNotes(props) {
    return Utils.genericPut(`${endpoint}/eventPhoto/notes`, props.data);
  }

  static generateZipAndSendEmailPhoto(props) {
    return Utils.genericPost(`${endpoint}/eventPhoto/generateZipAndSendEmail`, props.data);
  }

  static eventPhotoRotate(props) {
    return Utils.genericPut(`${endpoint}/eventPhoto/rotate`, props.data);
  }


  //remove all tags. receive array of ids of photos
  static ClearAllStructureTypes(props) {
    return Utils.genericPost(`${endpoint}/eventPhoto/clearTagsOfMultiplePhotos`, props.data);
  }


//add tag to multiple photos ids, receive array of photos ids and array of structure types
  static AddStructureTypesMultiplePhotos(props) {
    return Utils.genericPost(`${endpoint}/eventPhoto/assignMultipleToStructureType`, props.data);
  }
  
}
