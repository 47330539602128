import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/trip';

export default class ApiTrip {

  // GET 'api/trips'
  static getAll() {
    return Utils.genericCall(`${endpoint}`);
  }

  static searchTrips(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getTripAssets(props) {
    return Utils.genericPost(`${endpoint}/${props.tripId}/sections`, props.filterObject);
  }

  static getTripAssetsPaged(props) {
    return Utils.genericPost(`${endpoint}/${props.tripId}/sections/paged`, props.filterObject);
  }

  static getTrip(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static addTrip(data) {
    return Utils.genericPost(endpoint, data);
  }

  static addFirstSaveTrip(data) {
    return Utils.genericPost(`${endpoint}/firstSaveTrip`, data);
  }

  static addSecondSaveTrip(data) {
    return Utils.genericPost(`${endpoint}/secondSaveTrip`, data);
  }

  static editFirstSaveTrip(data) {
    return Utils.genericPut(`${endpoint}/firstSaveTrip/${data.id}`, data);
  }

  static editSecondSaveTrip(data) {
    return Utils.genericPut(`${endpoint}/secondSaveTrip/${data.id}`, data);
  }

  static deleteTrip(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteTripChecked(data) {
    return Utils.genericPost(`${endpoint}/delete`, data);
  }

  // /trip/complete/{id}
  static completeTrip(data) {
    return Utils.genericPut(`${endpoint}/complete/${data.id}`, data);
  }

  static loadTripPhotosPaged(props) {
    return Utils.genericPost(`${endpoint}/${props.tripId}/tripPhoto/paged`, props.data);
  }

  static UploadTripPhotos(tripId, data) {
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    return Utils.genericPost(`${endpoint}/${tripId}/tripPhoto/upload`, data, headers);
  }

  static generateFieldReports(tripId) {
    return Utils.genericPost(`${endpoint}/report/field/${tripId}`, {});
  }

  static getRoles() {
    return Utils.genericCall(`${endpoint}/roles`);
  }

  static getUsersRoles() {
    return Utils.genericCall(`${endpoint}/usersRoles`);
  }

  static getClients() {
    return Utils.genericCall(`${endpoint}/clients`);
  }
}
