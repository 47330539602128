import { all, call, put, takeLatest,select } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiComponent from 'tests/mocks/ApiComponent.mock'
import ApiComponent from 'apiServices/ApiComponent'


/*
 * get Components
 */
function* getComponents(){
  // get component by Assembly
  const allComponents = yield call(ApiComponent.getAll);
  // update state for components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    components: allComponents,
  }});
}

function* searchComponents(action){
  // search all Components
  const components = yield call(ApiComponent.searchComponents, action.props);
  // update state for components
  yield put({ type: actionTypes.CHANGE_ASSEMBLY_PROPS, props:{
    components: components.content,
  }});
}

function* getByAssembly(action) {
  const components = yield call(ApiComponent.getByAssembly, action.props);
  // get assembly components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    components: components,
  }});
}

function* getByAssemblyEvent(action) {
  const components = yield call(ApiComponent.getByAssemblyEvent, action.props);
  // get assembly components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    components: components,
  }});
}

function* searchByAssembly(action){
  // search all components by assembly
  const components = yield call(ApiComponent.searchByAssembly, action.props);
  // update state for components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    components: components.content,
  }});
}

function* getComponent(action){
  // get component
  const component = yield call(ApiComponent.getComponent, action.props.componentId);
  yield put({type: actionTypes.CHANGE_NAMES_PROPS, props:{
    name: component.name,
    id: component.id,
    type: "component"
  }});
  if (Object.keys(action.props).length > 1)
    yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: {level:4, ids: action.props, last: "component"}});
  // update state for components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    component: component,
  }});
}

function* editComponent(action) {
  let props = action.props;
  if (action.props.getComponents)
    props = props.props;

  // add  Component
  yield call(ApiComponent.editComponent, props);
  // update state for components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    component: {},
  }});

  if (action.props.getComponents)
    yield getByAssembly({props: props.assembly.id})
}

function* addComponent(action) {
  let props = action.props;
  if (action.props.getComponents)
    props = props.props;

  // add  Component
  let component = yield call(ApiComponent.addComponent, props);
  // update state for components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    newComponent: component,
  }});

  if (action.props.getComponents)
    yield getByAssembly({props: props.assembly.id})
}

function* addComponentToAssembly(action) {
  let props = action.props;
  if (action.props.getComponents)
    props = props.props;

  // add  Component
  yield call(ApiComponent.addComponentToAssembly, props);
  // update state for components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    loading: false,
  }});

  if (action.props.getComponents)
    yield getByAssembly({props: props.assembly.id})
}

function* addComponentToSubstructures(action) {
  let props = action.props;
  if (action.props.getComponents)
    props = props.props;

  // add  Component
  yield call(ApiComponent.addComponentToSubstructures, props);
  // update state for components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    loading: false,
  }});

  if (action.props.getComponents)
    yield getByAssembly({props: props.assembly.id})
}

function* updateComponentIntoAssembly(action) {
  let props = action.props;
  if (action.props.getComponents)
    props = props.props;

  // add  Component
  yield call(ApiComponent.updateComponentIntoAssembly, props);
  // update state for components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    loading: false,
  }});

  if (action.props.getComponents)
    yield getByAssembly({props: props.assembly.id})
}

function* updateComponentIntoSubstructures(action) {
  let props = action.props;
  if (action.props.getComponents)
    props = props.props;

  // add  Component
  yield call(ApiComponent.updateComponentIntoSubstructures, props);
  // update state for components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    loading: false,
  }});

  if (action.props.getComponents)
    yield getByAssembly({props: props.assembly.id})
}

function* deleteMultipleWithIndex(action) {
  let props = action.props;
  if (action.props.getComponents)
    props = props.props;

  // add  Component
  yield call(ApiComponent.deleteMultipleWithIndex, props);
  // update state for components
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    loading: false,
  }});

  if (action.props.getComponents)
    yield getByAssembly({props: props.assembly.id})
}

function* deleteComponent(action){
  // delete  Component
  yield call(ApiComponent.deleteComponent, action.props.id);

  if (action.props.assembly)
    yield getByAssembly({props: action.props.assembly.id})
}

function* removeAllcomponentsFromAssembly(action){
  // delete  Component
  try{
  yield call(ApiComponent.removeAllcomponentsFromAssembly, action.props);

  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    loading: false,
  }});
  }
  catch(e){
    
  }
}

function* getAllFields(action){
  let loadedFields = yield select(store=>store.component.allFields)
  //if we have data in store we dont reload it
  if(loadedFields.length === 0){
  // get all component fields
  const allFields = yield call(ApiComponent.getAllFields, action.props);
  // update state for camponents
  yield put({ type: actionTypes.CHANGE_COMPONENT_PROPS, props:{
    allFields: allFields,
  }});
}
}


/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_COMPONENT_ALL, getComponents),
    takeLatest(actionTypes.SAGA_COMPONENT_GET, getComponent),
    takeLatest(actionTypes.SAGA_COMPONENT_SEARCH, searchComponents),
    takeLatest(actionTypes.SAGA_COMPONENT_EDIT, editComponent),
    takeLatest(actionTypes.SAGA_COMPONENT_ADD, addComponent),
    takeLatest(actionTypes.SAGA_ASSEMBLY_COMPONENTS, getByAssembly),
    takeLatest(actionTypes.SAGA_COMPONENT_BY_ASSEMBLYSEVENT, getByAssemblyEvent),
    takeLatest(actionTypes.SAGA_ASSEMBLY_COMPONENTS_SEARCH, searchByAssembly),
    takeLatest(actionTypes.SAGA_COMPONENT_DELETE, deleteComponent),
    takeLatest(actionTypes.SAGA_COMPONENT_ALL_FIELDS, getAllFields),
    takeLatest(actionTypes.ADD_COMPONENT_TO_ASSEMBLY, addComponentToAssembly),
    takeLatest(actionTypes.ADD_COMPONENT_TO_SUBSTRUCTURE, addComponentToSubstructures),
    takeLatest(actionTypes.UPDATE_COMPONENT_TO_ASSEMBLY, updateComponentIntoAssembly),
    takeLatest(actionTypes.UPDATE_COMPONENT_TO_SUBSTRUCTURE, updateComponentIntoSubstructures),
    takeLatest(actionTypes.SAGA_DELETE_COMPONENS_TO_SUBSTRUCTURE, deleteMultipleWithIndex),
    takeLatest(actionTypes.SAGA_REMOVE_COMPONENTS_FROM_ASSEMBLY, removeAllcomponentsFromAssembly),
  ]);
}

export default AuthWatcher;
