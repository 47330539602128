import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiDocumentType from 'apiServices/ApiDocumentType'

import { clearDocumentTypeErrors, clearDocumentTypeProps } from '../actions/DocumentTypeActions';
import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast} from '../actions/ToastActions';

const recordTypeName = "document type";

function* loading(loading = true, errors = "") {
    // set loading state
    loading = !!loading;

    // error handling
    if (errors) {
        let errorMessage = errors.response.data.message;
        errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
            'The Document type cannot be removed because it is related to other objects in the application.'
            :
            'Something has not gone as it should.';
    }

    // update store props
    const props = { loading, errors };
    yield put({ type: actionTypes.CHANGE_DOCUMENTTYPE_PROPS, props });
}

/*
 * get DocumentTypes
 */

function* getDocumentTypes() {
    try {
        yield loading();
        // get document types
        const allDocuments = yield call(ApiDocumentType.getAll);
        // update state for documents
        yield put({
            type: actionTypes.CHANGE_DOCUMENTTYPE_PROPS, props: {
                documentTypes: allDocuments,
                loading: false,
                errors: ""
            }
        });
    } catch (error) {
        yield loading(false, error);
    }
}

function* searchDocumentTypes(action) {
    try {
        yield loading();

        // get all DocumentTypes
        const documentTypes = yield call(ApiDocumentType.searchDocumentTypes, action.props);
        const pagination = {
            number: documentTypes.number,
            numberOfElements: documentTypes.numberOfElements,
            totalElements: documentTypes.totalElements,
            totalPages: documentTypes.totalPages,
            size: documentTypes.size
        };
        // update state for documentTypes
        yield put({
            type: actionTypes.CHANGE_DOCUMENTTYPE_PROPS, props: {
                documentTypes: documentTypes.content,
                pagination: pagination,
                loading: false,
                errors: ""
            }
        });
    } catch (error) {
        yield put({
            type: actionTypes.CHANGE_PERMISSIONS_PROPS,
            props: {
                permissionsForthis: false
            }
        });
        yield loading(false, error);
    }
}

function* getDocumentType(action) {
    try {
        yield loading();

        // get  DocumentType
        const documentType = yield call(ApiDocumentType.getDocumentType, action.props.documentTypeId);

        yield put({
            type: actionTypes.CHANGE_DOCUMENTTYPE_PROPS, props: {
                documentType: documentType,
                loading: false,
                errors: ""
            }
        });
    } catch (error) {
        yield loading(false, error);
    }
}

function* editDocumentType(action) {

    yield put(clearDocumentTypeErrors());

    try {
        yield loading();

        // update  DocumentType
        yield call(ApiDocumentType.editDocumentType, action.props);
        // update state for documentTypes
        yield put(clearDocumentTypeProps());
        yield put(showRecordUpdatedToast(recordTypeName));
    } catch (error) {
        yield loading(false, error);
    }
}

function* addDocumentType(action) {

  yield put(clearDocumentTypeErrors());

    try {
        yield loading();

        // add  DocumentType
        yield call(ApiDocumentType.addDocumentType, action.props);
        // update state for documentTypes
        yield put(clearDocumentTypeProps());
        yield put(showRecordAddedToast(recordTypeName));
    } catch (error) {
        yield loading(false, error);
    }
}

function* deleteDocumentType(action) {

  yield put(clearDocumentTypeErrors());

    try {
        yield loading();

        // delete  DocumentType
        yield call(ApiDocumentType.deleteDocumentType, action.props);

        yield put(clearDocumentTypeProps());
        yield put(showRecordDeletedToast(recordTypeName));

        if (action.props.callback) {
          yield call(action.props.callback);
        }
    } catch (error) {
        yield loading(false, error);
    }
}

function* deleteDocumentTypeList(action) {

  yield put(clearDocumentTypeErrors());

    try {
        yield loading();

        yield call(ApiDocumentType.deleteDocumentTypeList, action.props.list);
        yield put(clearDocumentTypeProps());
        yield put(showRecordDeletedToast(recordTypeName));

        if (action.props.callback) {
          yield call(action.props.callback);
        }
    } catch (error) {
        yield loading(false, error);
    }
}


function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_DOCUMENTTYPE_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiDocumentType.getDocumentTypeOrganizations);
    yield put({
      type: actionTypes.CHANGE_DOCUMENTTYPE_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_DOCUMENTTYPE_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
    yield all([
        takeLatest(actionTypes.SAGA_DOCUMENTTYPE_ALL, getDocumentTypes),
        takeLatest(actionTypes.SAGA_DOCUMENTTYPE_SEARCH, searchDocumentTypes),
        takeLatest(actionTypes.SAGA_DOCUMENTTYPE_GET, getDocumentType),
        takeLatest(actionTypes.SAGA_DOCUMENTTYPE_EDIT, editDocumentType),
        takeLatest(actionTypes.SAGA_DOCUMENTTYPE_ADD, addDocumentType),
        takeLatest(actionTypes.SAGA_DOCUMENTTYPE_DELETE, deleteDocumentType),
        takeLatest(actionTypes.SAGA_DOCUMENTTYPE_DELETE_CHECKED, deleteDocumentTypeList),
        takeLatest(actionTypes.SAGA_DOCUMENTTYPE_LOAD_ORGANIZATIONS, loadOrganizations)
    ]);
}

export default AuthWatcher;
