import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
  fileAssetEventData: {},
  fileAssetData: {},
  filesAssetEventData: [],
  filesAssetData: [],
  photoData: [],
  photos: [],
  photosPagination: {},
  selectedPhoto: {},
  errors: '',
  loading: false
}

function imageReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_IMAGE_PROPS:
      return Object.assign({}, state, action.props)
    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, imageReducer);
