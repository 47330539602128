import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiAlignment from 'tests/mocks/ApiAlignment.mock'
import ApiAlignment from 'apiServices/ApiAlignment'
import CookieManager from 'common/cookieManager'


/*
 * get Alignment
 */
function* getAlignments(){
  // get water flows
  const allSections = yield call(ApiAlignment.getAll);
  // update state for sections
  yield put({ type: actionTypes.CHANGE_ALIGNMENT_PROPS, props:{
    alignments: allSections,
  }});
}

function* searchAlignments(action){
  // get all Alignment
  const alignments = yield call(ApiAlignment.searchAlignment, action.props);
  const pagination = { number: alignments.number,
	                     numberOfElements: alignments.numberOfElements,
	                     totalElements: alignments.totalElements,
	                     totalPages: alignments.totalPages,
	                     size: alignments.size};
  // update state for alignment
  yield put({ type: actionTypes.CHANGE_ALIGNMENT_PROPS, props:{
    alignments: alignments.content,
    pagination: pagination
  }});
}

function* getAlignment(action){
  // get  Alignment
  const alignment = yield call(ApiAlignment.getAlignment, action.props.alignmentId);
  yield putResolve({type: actionTypes.CHANGE_NAMES_PROPS, props:{
    name: alignment.name,
    id: alignment.id,
    type: "alignment"
  }});
  // update state for alignment
  yield put({ type: actionTypes.CHANGE_ALIGNMENT_PROPS, props:{
    alignment: alignment,
  }});
}

function* editAlignment(action){
  // update  Alignment
  yield call(ApiAlignment.editAlignment, action.props);
  // update state for alignment
  yield put({ type: actionTypes.CHANGE_ALIGNMENT_PROPS, props:{
    alignment: {},
  }});
  const cookieStored = CookieManager.getCookiesForTable("Alignment")
  yield searchAlignments(CookieManager.getObjectForSearch(cookieStored));
}

function* addAlignment(action){
  // add  Alignment
  yield call(ApiAlignment.addAlignment, action.props);
  // update state for alignment
  yield put({ type: actionTypes.CHANGE_ALIGNMENT_PROPS, props:{
    alignment: {},
  }});
  const cookieStored = CookieManager.getCookiesForTable("Alignment")
  yield searchAlignments(CookieManager.getObjectForSearch(cookieStored));
}

function* deleteAlignment(action){
  // delete  Alignment
  yield call(ApiAlignment.deleteAlignment, action.props);
  const cookieStored = CookieManager.getCookiesForTable("Alignment")
  yield searchAlignments(CookieManager.getObjectForSearch(cookieStored));
}
/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ALIGNMENT_ALL, getAlignments),
    takeLatest(actionTypes.SAGA_ALIGNMENT_SEARCH, searchAlignments),
    takeLatest(actionTypes.SAGA_ALIGNMENT_GET, getAlignment),
    takeLatest(actionTypes.SAGA_ALIGNMENT_EDIT, editAlignment),
    takeLatest(actionTypes.SAGA_ALIGNMENT_ADD, addAlignment),
    takeLatest(actionTypes.SAGA_ALIGNMENT_DELETE, deleteAlignment),
  ]);
}

export default AuthWatcher;
