import * as ActionTypes from '../../actions/actionTypes';

const onLoginCleanupReducer = (initialStatePart, wrappedReducer) => {

  return (state, action) => {

    if (action.type !== ActionTypes.CHANGE_AUTH_USER_PROPS) {
      return wrappedReducer(state, action);
    }

    const wrappedResult = wrappedReducer({...state, ...initialStatePart}, action);

    let ret = {
      ...state,
      ...wrappedResult
    }

    return ret;
  }
}

export default onLoginCleanupReducer;
