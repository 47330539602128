import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class CookieManager {

  /**
   * Sets pagination cookie for a table.
   *
   * @param {string} keyCookie Table name.
   * @param {string} defaultSortedColumn Default column to user for sort if no cookie exists
   * for this table.
   *
   * @returns Pagination properties.
   */
  static getCookiesForTable(keyCookie, defaultSortedColumn, defaultFilterArr = []){

    let cookieStored = cookies.get(keyCookie)

    let sortedCookie = defaultSortedColumn
    let pageSizeCookie = 10;
    let filterArr = defaultFilterArr;
    let sortOrderIsDesc = false;
    let currentPage = 0;

    if (cookieStored){

      if (cookieStored.pageSizeCookie) {
        pageSizeCookie = parseInt(cookieStored.pageSizeCookie)
      }

      if (cookieStored.sortedCookie) {
        sortedCookie = cookieStored.sortedCookie
      }

      if (cookieStored.filterArr && cookieStored.filterArr.length !== 0) {
        filterArr = cookieStored.filterArr;
      }

      if (cookieStored.sortOrderIsDesc) {
        sortOrderIsDesc = cookieStored.sortOrderIsDesc;
      }

      if (cookieStored.currentPage) {
        currentPage = cookieStored.currentPage;
      }
    }
    else {
      cookies.set(
        keyCookie,
        {pageSizeCookie, sortedCookie, filterArr, sortOrderIsDesc, currentPage},
        {
          path: "/"
        });
    }

    return {pageSizeCookie, sortedCookie, filterArr, sortOrderIsDesc, currentPage};
  }

  static setCookieForTable(keyCookie, valueCookie){
    cookies.set(keyCookie, valueCookie);
  }

  static getObjectForSearch(cookieStored){
    return {props: {
      search: "",
      page: 0,
      limit: cookieStored.pageSizeCookie,
      filterArr: [],
      order: {id: cookieStored.sortedCookie, desc: false}
    }}
  }
}
