import * as actionTypes from '../actions/actionTypes'
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const initialState = {
  contacts: [],
  /** Contacts of a client. This exists to not replace 'contacts' property. */
  clientContacts: [],
  contact: {},
  contactsOptions: [],
  formValues: null,
  pagination: {},
  loading: false,
  errors: '',

  organizations: createState(),
  clients: createState(),
  jobTitles: createState()
}

function reducerContact(state = initialState, action) {
    switch (action.type) {

        case actionTypes.CHANGE_CONTACT_PROPS:
            if (action.props.formValues != null) {
                const newValues = {
                    id: action.props.formValues.id,
                    firstName: action.props.formValues.firstName || "",
                    lastName: action.props.formValues.lastName || "",
                    email: action.props.formValues.email || "",
                    phone: action.props.formValues.phone || "",
                    address: action.props.formValues.address || "",
                    organization: action.props.formValues.organization || "",
                };
                action.props.formValues = newValues
            }
            return Object.assign({}, state, action.props)

        case actionTypes.CLEAR_CONTACT_PROPS:
          return initialState;

        case actionTypes.CLEAR_CONTACT_ERRORS:
          return {
            ...state,
            errors: ''
          };

        default:
            return state
    }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerContact),
  {
    organizations: [
      actionTypes.CHANGE_CONTACT_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_CONTACT_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_CONTACT_ERROR_LOADING_ORGANIZATIONS
    ],
    clients: [
      actionTypes.CHANGE_CONTACT_START_LOADING_CLIENTS,
      actionTypes.CHANGE_CONTACT_SUCCESS_LOADING_CLIENTS,
      actionTypes.CHANGE_CONTACT_ERROR_LOADING_CLIENTS
    ],
    jobTitles: [
      actionTypes.CHANGE_CONTACT_START_LOADING_JOB_TITLES,
      actionTypes.CHANGE_CONTACT_SUCCESS_LOADING_JOB_TITLES,
      actionTypes.CHANGE_CONTACT_ERROR_LOADING_JOB_TITLES
    ]
  }
);
