import * as actionTypes from '../actions/actionTypes'
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {alignmentFields} from 'common/objectStructures'

const initialState = {
  alignments: [],
  alignment: {},
  formValues: null,
  pagination: {},
}

function reducerAlignment (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_ALIGNMENT_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(alignmentFields)) {
          newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerAlignment);
