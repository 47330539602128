import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {roleFields} from 'common/objectStructures';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';



const initialState = {
  loading: false,
  roles: [],
  role: {},
  formValues: null,
  pagination: {},
  errors: '',

  organizations: createState(),
  claims: createState()
}

function reducerRole (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_ROLE_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(roleFields)) {
          newValues[key] = action.props.formValues[key] || value.defaultValue;
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_ROLE_PROPS:
      return initialState;

    case actionTypes.CLEAR_ROLE_ERRORS:
      return {
        ...state,
        errors: ''
      }

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerRole),
  {
    organizations: [
      actionTypes.CHANGE_ROLE_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_ROLE_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_ROLE_ERROR_LOADING_ORGANIZATIONS
    ],
    claims: [
      actionTypes.CHANGE_ROLE_START_LOADING_CLAIMS,
      actionTypes.CHANGE_ROLE_SUCCESS_LOADING_CLAIMS,
      actionTypes.CHANGE_ROLE_ERROR_LOADING_CLAIMS
    ]
  }
);
