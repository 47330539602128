import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/componentGroup';

export default class ApiComponentGroup {

  // GET 'api/componentGroups'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static searchComponentGroups(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getComponentGroup(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editComponentGroup(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addComponentGroup(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteComponentGroup(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteComponentGroupList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }
}
