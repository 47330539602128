import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/inspectionType';

export default class ApiInspectionType {

  // GET 'api/InspectionTypes'
  static getAllInspectionTypes(){
    return Utils.genericCall(endpoint);
  }

  static searchInspectionTypes(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getInspectionType(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editInspectionType(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addInspectionType(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteInspectionType(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

}
