import { all, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
import {INITIAL_NAMES} from 'common/constants'

function* updateNames(action){
  let names = sessionStorage.getItem('names');
  if (names == null) {
    names = INITIAL_NAMES
    sessionStorage.setItem('names', JSON.stringify(names));
  }  else {
    names = JSON.parse(names);
  }
  let actions = [actionTypes.SAGA_CLIENT_GET, actionTypes.SAGA_RAILROAD_GET,
    actionTypes.SAGA_SUBDIVISION_GET, actionTypes.SAGA_ASSET_GET];
    let namesKeys = ['client', 'railroad', 'subdivision', 'asset'];
  if (action.props.last){
    actions[4] = actionTypes["SAGA_" + action.props.last.toUpperCase() + "_GET"];
    namesKeys[4] = action.props.last;
  }
  if (action.props.type && action.props.type === 'events'){
    actions.push(actionTypes.SAGA_ASSETEVENT_GET);
    actions.push(actionTypes.SAGA_SECTIONEVENT_GET);
    namesKeys.push('assetEvent');
    namesKeys.push('sectionEvent');
  }
  if (action.props.type && action.props.type === 'message'){
    actions = [actionTypes.SAGA_MESSAGE_GET, actionTypes.SAGA_MESSAGEFIELD_GET];
    namesKeys = ['message', 'messageField'];
  }
  for(let i = action.props.level - 1; i >= 0; i--) {
    if (names[namesKeys[i]].id === '' || names[namesKeys[i]].id !== action.props.ids[namesKeys[i] + 'Id'])
      yield put({ type: actions[i], props: action.props.ids});
  }
}

function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_NAMES_UPDATE, updateNames),
  ]);
}

export default AuthWatcher;
