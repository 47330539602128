import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/eventNote';

export default class EventNote {

  // GET 'api/eventNote'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static getEventNote(assetId, eventId) {
    return Utils.genericPost(`${endpoint}/asset/${assetId}/event/${eventId}`, []);
  }

  static editEventNote(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static getEventNoteById(eventNoteId) {
    return Utils.genericCall(`${endpoint}/${eventNoteId}`);
  }

  static addEventNote(data) {
    return Utils.genericPost(`${endpoint}`, data);
  }

  // "/eventNote/{eventNoteId}/addPhotos" POST
  static addPhotosToNote(data) {
    return Utils.genericPost(`${endpoint}/${data[0].eventNote.id}/addPhotos`, data);
  }

  static deleteEventNote(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static eventNoteUnchanged(data) {
    return Utils.genericPost(`${endpoint}/unchanged`, data);
  }

  static eventNoteSound(data) {
    return Utils.genericPost(`${endpoint}/sound`, data);
  }

  static addEventNotePrevtoNew(data) {
    return Utils.genericPost(`${endpoint}/previous/${data.eventNoteId}`, data);
  }

  static deleteEventNoteArray(data) {
    return Utils.genericPost(`${endpoint}/delete`, data);
  }

  // "/eventNote/previous/{id}" GET
  static getPreviousEventNoteById(id) {
    return Utils.genericCall(`${endpoint}/previous/${id}`);
  }
}
