import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/impactType';

export default class ApiImpactType {

  // GET 'api/impactTypes'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static searchImpactTypes(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getImpactType(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editImpactType(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addImpactType(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteImpactType(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteImpactTypeList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }
}
