import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiCondition from 'apiServices/ApiCondition'

import { clearConditionErrors } from '../actions/ConditionActions';
import { showRecordAddedToast, showRecordDeletedToast, showRecordUpdatedToast } from '../actions/ToastActions';

const recordTypeName = "condition";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The condition cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_CONDITION_PROPS, props });
}

/*
 * get Conditions
 */
function* getConditions() {
  // get first page Conditions
  yield searchConditions({ props: { search: '', page: 0 } });
}

function* searchConditions(action) {
  try {
    yield loading();
    // get all Conditions
    const conditions = yield call(ApiCondition.searchConditions, action.props);
    const pagination = {
      number: conditions.number,
      numberOfElements: conditions.numberOfElements,
      totalElements: conditions.totalElements,
      totalPages: conditions.totalPages,
      size: conditions.size
    };
    // update state for conditions
    yield put({
      type: actionTypes.CHANGE_CONDITION_PROPS, props: {
        conditions: conditions.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getCondition(action) {
  try {
    yield loading();
    // get  Condition
    const condition = yield call(ApiCondition.getCondition, action.props.conditionId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: condition.name,
        id: condition.id,
        type: "condition"
      }
    });
    // update state for conditions
    yield put({
      type: actionTypes.CHANGE_CONDITION_PROPS, props: {
        condition: condition,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editCondition(action) {

  yield put(clearConditionErrors());

  try {
    yield loading();
    // update  Condition
    yield call(ApiCondition.editCondition, action.props);
    // update state for conditions
    yield put({
      type: actionTypes.CHANGE_CONDITION_PROPS, props: {
        condition: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordUpdatedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }

}

function* addCondition(action) {

  yield put(clearConditionErrors());

  try {
    yield loading();
    // add  Condition
    yield call(ApiCondition.addCondition, action.props);
    // update state for conditions
    yield put({
      type: actionTypes.CHANGE_CONDITION_PROPS, props: {
        condition: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordAddedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteCondition(action) {

  yield put(clearConditionErrors());

  try {
    yield loading();
    // delete  Condition
    yield call(ApiCondition.deleteCondition, action.props);

    yield put({
      type: actionTypes.CHANGE_CONDITION_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteConditionList(action) {

  yield put(clearConditionErrors());

  try {
    yield loading();
    yield call(ApiCondition.deleteConditionList, action.props.list);

    // update state for Condition
    yield put({
      type: actionTypes.CHANGE_CONDITION_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* getConditionsByComponentType(action) {
  try {
    yield loading();
    const condition = yield call(ApiCondition.getConditionsByComponentType, action.props.componentTypeId);

    yield put({
      type: actionTypes.CHANGE_CONDITION_PROPS, props: {
        conditionsByType: condition,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getConditionsByAssemblyType(action) {
  try {
    yield loading();
    const condition = yield call(ApiCondition.getConditionsByAssemblyType, action.props.assemblyTypeId);

    yield put({
      type: actionTypes.CHANGE_CONDITION_PROPS, props: {
        conditionsByType: condition,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}



function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_CONDITION_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiCondition.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_CONDITION_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_CONDITION_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}


function* loadAssemblyTypes() {
  try {
    yield put({
      type: actionTypes.CHANGE_CONDITION_START_LOADING_ASSEMBLY_TYPES
    });

    const assemblyTypes = yield call(ApiCondition.getAssemblyTypes);
    yield put({
      type: actionTypes.CHANGE_CONDITION_SUCCESS_LOADING_ASSEMBLY_TYPES,
      props: {
        assemblyTypes
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_CONDITION_ERROR_LOADING_ASSEMBLY_TYPES,
      error: e.message
    })
  }
}


function* loadComponentTypes() {
  try {
    yield put({
      type: actionTypes.CHANGE_CONDITION_START_LOADING_COMPONENT_TYPES
    });

    const componentTypes = yield call(ApiCondition.getComponentTypes);
    yield put({
      type: actionTypes.CHANGE_CONDITION_SUCCESS_LOADING_COMPONENT_TYPES,
      props: {
        componentTypes
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_CONDITION_ERROR_LOADING_COMPONENT_TYPES,
      error: e.message
    })
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_CONDITION_ALL, getConditions),
    takeLatest(actionTypes.SAGA_CONDITION_SEARCH, searchConditions),
    takeLatest(actionTypes.SAGA_CONDITION_GET, getCondition),
    takeLatest(actionTypes.SAGA_CONDITION_COMPONENTTYPE_GET, getConditionsByComponentType),
    takeLatest(actionTypes.SAGA_CONDITION_ASSEMBLYTYPE_GET, getConditionsByAssemblyType),
    takeLatest(actionTypes.SAGA_CONDITION_EDIT, editCondition),
    takeLatest(actionTypes.SAGA_CONDITION_ADD, addCondition),
    takeLatest(actionTypes.SAGA_CONDITION_DELETE, deleteCondition),
    takeLatest(actionTypes.SAGA_CONDITION_DELETE_CHECKED, deleteConditionList),
    takeLatest(actionTypes.SAGA_CONDITION_LOAD_ORGANIZATIONS, loadOrganizations),
    takeLatest(actionTypes.SAGA_CONDITION_LOAD_ASSEMBLY_TYPES, loadAssemblyTypes),
    takeLatest(actionTypes.SAGA_CONDITION_LOAD_COMPONENT_TYPES, loadComponentTypes),
  ]);
}

export default AuthWatcher;
