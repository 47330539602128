import * as actionTypes from './actionTypes'

/**
 * @namespace ConditionActions
 */

export function getConditions() {
  return { type: actionTypes.SAGA_CONDITION_ALL };
}

export function loadConditionsPaged(props) {
  return { type: actionTypes.SAGA_CONDITION_SEARCH, props: props };
}

export function getCondition(props) {
  return { type: actionTypes.SAGA_CONDITION_GET, props: props };
}

export function editCondition(props) {
  return { type: actionTypes.SAGA_CONDITION_EDIT, props: props };
}

export function addCondition(props) {
  return { type: actionTypes.SAGA_CONDITION_ADD, props: props };
}

export function deleteCondition(props) {
  return { type: actionTypes.SAGA_CONDITION_DELETE, props: props };
}

export function deleteConditionList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_CONDITION_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

export function getConditionByComponentType(props) {
  return { type: actionTypes.SAGA_CONDITION_COMPONENTTYPE_GET, props: props };
}

export function getConditionByAssemblyType(props) {
  return { type: actionTypes.SAGA_CONDITION_ASSEMBLYTYPE_GET, props: props };
}

/**
 * @memberof ConditionActions
 * @description Change Condition props (no saga action)
 */
export function changeConditionProps(props) {
  return { type: actionTypes.CHANGE_CONDITION_PROPS, props: props };
}

/**
 * @memberof ConditionActions
 * @description Clears Condition props.
 */
export function clearConditionProps() {
  return { type: actionTypes.CLEAR_CONDITIONS_PROPS };
}

/**
 * @memberof ConditionActions
 * @description Clears Condition errors messages.
 */
export function clearConditionErrors() {
  return { type: actionTypes.CLEAR_CONDITIONS_ERRORS };
}

/**
 * @memberof ConditionActions
 * @description Create a Redux action that can be dispatched
 * to load available organizations for conditions.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be disptached.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_CONDITION_LOAD_ORGANIZATIONS };
}

/**
 * @memberof ConditionActions
 * @description Creates a Redux action that can be dispatched
 * to load available assembly types for conditions.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadAssemblyTypes() {
  return { type: actionTypes.SAGA_CONDITION_LOAD_ASSEMBLY_TYPES };
}

/**
 * @memberof ConditionActions
 * @description Creates a Redux action that can be dispatched
 * to load available component types for conditions.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadComponentTypes() {
  return { type: actionTypes.SAGA_CONDITION_LOAD_COMPONENT_TYPES };
}

