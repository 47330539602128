import qs from 'qs';
import { API_AUTH_SERVER } from 'config/constants'
import Utils from 'common/utils'

export default class AuthApi {

  static login(values){
    values.client_id = 'inspectionBrowser';
    values.grant_type = 'password';
    const headers = {
      'Authorization': 'Basic aW5zcGVjdGlvbkJyb3dzZXI6c2VjcmV0',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    };
    return Utils.genericPost(API_AUTH_SERVER + '/oauth/token', qs.stringify(values), headers);
  }



}
