import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {structureTypeFields} from 'common/objectStructures';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const initialState = {
  structureTypes: [],
  structureType:{},
  formValues: null,
  pagination: {},
  loading: false,
  errors: '',
  organizations: createState(),
  assetTypes: createState()
}

function reducerStructureType (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_STRUCTURETYPE_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(structureTypeFields)) {
          newValues[key] = action.props.formValues[key] || value.defaultValue;
        }

        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_STRUCTURE_TYPE_PROPS:
      return initialState;

    case actionTypes.CLEAR_STRUCTURE_TYPE_ERRORS:
      return {
        ...state,
        errors: ''
      }

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerStructureType),
  {
    organizations: [
      actionTypes.CHANGE_STRUCTURETYPE_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_STRUCTURETYPE_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_STRUCTURETYPE_ERROR_LOADING_ORGANIZATIONS
    ],
    assetTypes: [
      actionTypes.CHANGE_STRUCTURETYPE_START_LOADING_ASSET_TYPES,
      actionTypes.CHANGE_STRUCTURETYPE_SUCCESS_LOADING_ASSET_TYPES,
      actionTypes.CHANGE_STRUCTURETYPE_ERROR_LOADING_ASSET_TYPES
    ]
  }
);
