import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
    sidebarActive: 'assetBridge',
}

function menuActiveReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CHANGE_MENU_ACTIVE_PROPS:
            return { ...state, ...action.props}

        default:
            return state
    }
}

export default onLoginCleanupReducer(initialState, menuActiveReducer);
