import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/assemblyType';

export default class ApiAssemblyType {

  // GET 'api/assemblyTypes'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchAssemblyTypes(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getAssemblyType(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editAssemblyType(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addAssemblyType(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteAssemblyType(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteAssemblyTypeList(list) {
    return new Promise(async (resolve, reject) => {

      try {
        const result = await Utils.genericPost(`${endpoint}/delete`, list);
        resolve(result);
      } catch(e) {

        //a status code of 405 means that it can't be deleted because it has associated objects.
        if (e.response.status !== 405) {
          reject(e);
        } else {
          reject({
            type: "constraintViolation"
          });
        }
      }
    });
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getAssemblyTypesByOrganization() {

    return Utils.genericCall(`${endpoint}/getAssemblyTypesByOrganization`);
  }

  static getAssemblyTypesByStructure(id) {

    return Utils.genericCall(`${endpoint}/byStructure/${id}`);
  }

  static getAllApproachesTypes(id) {

    return Utils.genericCall(`${endpoint}/getAllApproachesTypes`);
  }

}
