import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/componentType';

export default class ApiComponentType {

  // GET 'api/componentTypes'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchComponentTypes(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getComponentType(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editComponentType(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addComponentType(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteComponentType(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteComponentTypeList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getAssemblyTypes() {
    return Utils.genericCall(`${endpoint}/assemblyTypes`);
  }

  static getComponentGroups() {
    return Utils.genericCall(`${endpoint}/componentGroups`);
  }

  static getFields() {
    return Utils.genericCall(`${endpoint}/fields`);
  }

  static getComponentTypesByOrganization(){
    return Utils.genericCall(`${endpoint}/getComponentTypesByOrganization`);
  }

}
