import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/accessType';

export default class ApiAccessType {

  // GET 'api/accessType'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchAccessType(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getAccessType(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editAccessType(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addAccessType(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteAccessType(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteAccessTypeList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }
}
