import * as actionTypes from './actionTypes'

/**
 *
 * @namespace ContactActions
 */


export function getContacts() {
  return { type: actionTypes.SAGA_CONTACT_ALL };
}

export function loadContactsPaged(props) {
  return { type: actionTypes.SAGA_CONTACT_SEARCH_PAGE, props: props };
}

export function searchContacts(props) {
  return { type: actionTypes.SAGA_CONTACT_SEARCH, props: props };
}

/**
 * @memberof ContactActions
 * @description Creates a Redux action that can be dispatched
 * to fetch a Contact.
 *
 * This will trigger a Saga action.
 *
 * @param {object} props
 * @param {string} props.contactId - the ID of the contact to
 * fetch.
 * @returns A Redux action that can be dispatched.
 */
export function getContact(props) {
  return { type: actionTypes.SAGA_CONTACT_GET, props: props };
}

export function editContact(props, callback) {
  return { type: actionTypes.SAGA_CONTACT_EDIT, props: { props, callback } };
}

export function addContact(props, callback) {
  return { type: actionTypes.SAGA_CONTACT_ADD, props: { props, callback } };
}

export function deleteContact(props) {
  return { type: actionTypes.SAGA_CONTACT_DELETE, props: props };
}

export function deleteContactList(list, options = { callback: () => { } }) {
  return {
    type: actionTypes.SAGA_CONTACT_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

/**
 * @memberof ContactActions
 * @description Creates a Redux action that can be dispatched to
 * load all contacts (paged) of the client.
 *
 * This will trigger a Saga action.
 *
 * @param {object} props
 * @param {string} props.clientId
 * @returns a Redux action that can be dispatched.
 */
export function getContactByClientId(props) {
  return { type: actionTypes.SAGA_CONTACT_BY_CLIENT_ID, props: props };
}

export function getAllContactsOfClient(clientId) {
  return {
    type: actionTypes.SAGA_CONTACT_ALL_OF_CLIENT,
    props: {
      clientId
    }
  };
}

export function searchFilteredContacts(props) {
  return { type: actionTypes.SAGA_CONTACT_SEARCH_FILTER, props: props };
}

/**
 * @memberof ContactActions
 * @description Change Contact props (no saga action).
 *
 * This will not trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function changeContactProps(props) {
  return { type: actionTypes.CHANGE_CONTACT_PROPS, props: props };
}

/**
 * @memberof ContactActions
 * @description Clears contact props.
 *
 * Whit will not trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function clearContactProps() {
  return { type: actionTypes.CLEAR_CONTACT_PROPS };
}

/**
 * @memberof ContactActions
 * @description Clears contact current errors messages.
 *
 * This will not trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function clearContactErrors() {
  return { type: actionTypes.CLEAR_CONTACT_ERRORS };
}

/**
 * @memberof ContactActions
 * @deprecated Use getContact
 * @description returns a Redux action that will dispatch
 * the command to load a contact with the
 * provided ID.
 *
 * This will trigger a Saga action.
 *
 * @param {string} contactId contact ID
 * @returns the redux action to dispatch.
 */
export function getContactById(contactId) {
  return getContact({ contactId })
}

/**
 * @memberof ContactActions
 * @description creates a Redux action that can be dispatched
 * to fetch all organizations available for a contact.
 *
 * This will trigger a Saga action.
 * @returns A Redux action that can be dispatched.
 */
export function getOrganizations() {
  return { type: actionTypes.SAGA_CONTACT_GET_ORGANIZATIONS };
}

/**
 * @memberof ContactActions
 * @description Creates a Redux action that can be dispatched
 * to fetch all clients available for a contact.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getClients() {
  return { type: actionTypes.SAGA_CONTACT_GET_CLIENTS };
}

/**
 * @memberof ContactActions
 * @description Creates a Redux action that can be dispatched
 * to fetch all job titles available for a contact.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getJobTitles() {
  return { type: actionTypes.SAGA_CONTACT_GET_JOB_TITLES };
}
