import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const report = API_SERVER +'/importAssemblyType';
const endpoint = API_SERVER + '/asset';
const endpointSection = API_SERVER + '/sections';
const endpointEvent = API_SERVER + '/event';

const endpointSubdivision = API_SERVER + '/subdivision';

const importedReport = API_SERVER +'/reportMapping';

export default class ApiAsset {

  // GET 'api/assets'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static searchAssets(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getBySubdivision(id) {
    return Utils.genericCall(`${endpointSubdivision}/${id}/asset`);
  }

  static searchBySubdivision(props) {
    return Utils.genericCall(`${endpointSubdivision}/${props.id}/asset/paged?search=${props.search}&page=${props.page}&size=${props.size}&order=${props.order.id}&desc=${props.order.desc}`);
  }

  static searchByClient(props) {
    return Utils.genericPost(`${endpoint}/pagedByClient?search=${props.search}&page=${props.page}&year=${props.year}&rating=${props.spanRating}&traffic=${props.spanTraffic}`, props.ids);
  }

  static searchByRailroads(props) {
    return Utils.genericPost(`${endpoint}/pagedByRailroads?search=${props.search}&page=${props.page}&year=${props.year}&rating=${props.spanRating}&traffic=${props.spanTraffic}`, props.ids);
  }

  static searchBySubdivisions(props) {
    return Utils.genericPost(`${endpoint}/pagedBySubdivisions?search=${props.search}&page=${props.page}&year=${props.year}&rating=${props.spanRating}&traffic=${props.spanTraffic}`, props.ids);
  }

  static getChildrenByStructureType(props) {
    return Utils.genericCall(`${endpoint}/${props.id}/structureType/${props.structureTypeId}/children`)
  }

  static getAsset(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static getBridgesForFilters(props) {
    return Utils.genericPost(`${endpoint}/filters`, props);
  }

  static editAsset(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addAsset(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteAsset(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  
  static scheduleInspection(props) {
    return Utils.genericPost(`${endpoint}/${props.id}/event`, props.data);
  }

  static getByParent(id) {
    return Utils.genericCall(`${endpoint}/${id}/asset`);
  }

  static getAllFields() {
    return Utils.genericCall(`${endpoint}/fields`);
  }

  static getChildren(id) {
    return Utils.genericCall(`${endpoint}/${id}/children`);
  }

  static deleteAssetChecked(data) {
    return Utils.genericPost(`${endpoint}/delete`, data);
  }

  static getSectionBySubdivision(id) {
    return Utils.genericCall(`${endpointSubdivision}/${id}/section`);
  }

  static addImmediateActions(data) {
    return Utils.genericPut(`${endpointEvent}/immediateActions/${data.event.id}`, data);
  }

  static searchAssetsForTrip(props) {
    return Utils.genericPost(`${endpoint}/sections`, props);
  }

  static searchByTrip(props) {
    return Utils.genericPost(`${endpointSection}/trip/${props.tripId}/available`, props);
  }

  static addMultiAsset(data) {
    return Utils.genericPost(`${endpoint}/multiple`, data);
  }

  // static addCoverPhoto(assetId, data) {
  //   let headers = Utils.getHeaders();
  //   headers['Content-Type'] = '';
  //   return Utils.genericPost(`${endpoint}/${assetId}/photo/upload`, data, headers);
  // }

  static addCoverPhoto(assetId, eventPhotoId) {
    return Utils.genericPost(`${endpoint}/${assetId}/eventPhoto/${eventPhotoId}/assign`);
  }

  static uploadAssetPhoto(assetId, data) {
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    return Utils.genericPost(`${endpoint}/${assetId}/photo/upload`, data, headers);
  }

  static getAssetTypes() {
    return Utils.genericCall(`${endpoint}/assetTypes`);
  }

  static getCrossingFeatures() {
    return Utils.genericCall(`${endpoint}/crossingFeatures`);
  }

  static getWaterFlows() {
    return Utils.genericCall(`${endpoint}/waterFlows`);
  }

  static getAlignments() {
    return Utils.genericCall(`${endpoint}/alignments`);
  }

  static getImpactTypes() {
    return Utils.genericCall(`${endpoint}/impactTypes`);
  }

  static getClients() {
    return Utils.genericCall(`${endpoint}/clients`);
  }

  // static uploadReportFile(data) {
  //   let headers = Utils.getHeaders();
  //   headers['Content-Type'] = '';
  //   return Utils.genericPost(`${endpoint}/importBridge`, data, headers);
  // }


  static uploadReport(data) {
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    return Utils.genericPost(`${API_SERVER}/reportmapping/upload/${data.railroadId}`, data.file, headers);
  }


  static getUploadedReport(data) {
    return Utils.genericCall(`${report}/${data.railroadId}/${data.fileName}/${data.typeOfStructure}`);
  }

  static updateMultiple(data) {
    return Utils.genericPut(`${report}/updateMultiple`, data);
  }

  static getImportDataPaginated(data) {
    let headers = Utils.getHeaders();
    return Utils.genericPost(`${importedReport}/paged/${data.railroadId}/${data.fileName}`, data.paginatedData, headers);
  }

  static saveImportedData(data) {
    let headers = Utils.getHeaders();
    return Utils.genericPost(`${importedReport}/${data.railroadId}/${data.fileName}`, {}, headers);
  }


  static deleteMappedData(data) {
    return Utils.genericDelete(`${report}/${data.railroadId}/${data.fileName}/${data.typeOfStructure}`);
  }

  static cancelImport(data) {
    return Utils.genericDelete(`${importedReport}/${data.railroadId}/${data.fileName}`);
  }

}

