import React from 'react'
import Page from 'components/Page';
import ModalBanner from 'components/modalBanner';

export default function PermissionsPage(props) {
  return (
    <Page title="Permission Info" breadcrumbs={[]}>
      <ModalBanner
        fromStoryBook={true}
        modalType='information'
        msg={' We’re sorry. You don’t have permission to view this page. If you feel this is an error, please contact your administrator tlucero@are-corp.com to resolve this issue'}
        open={true}
        toggle={()=>{}}
      />
    </Page>
  )
}
