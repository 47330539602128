import { all, call, put, takeLatest,select, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiComponentType from 'apiServices/ApiComponentType'

import { clearComponentTypeErrors } from '../actions/ComponentTypeActions';
import { showRecordAddedToast, showRecordDeletedToast, showRecordUpdatedToast } from '../actions/ToastActions';

const recordTypeName = "component type";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The component type cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props });
}

/*
 * get ComponentTypes
 */

function* getComponentTypes() {
  try {
    yield loading();
    // get component types
    const allComponents = yield call(ApiComponentType.getAll);
    // update state for components
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props: {
        componentTypes: allComponents,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchComponentTypes(action) {
  try {
    yield loading();
    // get all ComponentTypes
    const componentTypes = yield call(ApiComponentType.searchComponentTypes, action.props);
    const pagination = {
      number: componentTypes.number,
      numberOfElements: componentTypes.numberOfElements,
      totalElements: componentTypes.totalElements,
      totalPages: componentTypes.totalPages,
      size: componentTypes.size
    };
    // update state for componentTypes
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props: {
        componentTypes: componentTypes.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}


function* getComponentTypesByOrganization() {
  try {
    let componentTypesLoaded = yield select(store=>store.componentType.componentTypeByOrganization)

    if(!componentTypesLoaded || componentTypesLoaded.length === 0){

    yield loading();
    // get all ComponentTypes by client
    const componentTypes = yield call(ApiComponentType.getComponentTypesByOrganization);

    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props: {
        componentTypeByOrganization: componentTypes,
        loading: false,
        errors: ''
      }
    });

  }
  } catch (error) {
    yield loading(false, error);
  }

}

function* getComponentType(action) {
  try {
    yield loading();
    // get  ComponentType
    const componentType = yield call(ApiComponentType.getComponentType, action.props.componentTypeId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: componentType.name,
        id: componentType.id,
        type: "componentType"
      }
    });
    // update state for componentTypes
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props: {
        componentType: componentType,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* editComponentType(action) {

  yield put(clearComponentTypeErrors());

  try {
    yield loading();
    // update  ComponentType
    yield call(ApiComponentType.editComponentType, action.props);
    // update state for componentTypes
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props: {
        componentType: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordUpdatedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }

}

function* addComponentType(action) {

  yield put(clearComponentTypeErrors());

  try {
    yield loading();
    // add  ComponentType
    yield call(ApiComponentType.addComponentType, action.props);
    // update state for componentTypes
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props: {
        componentType: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordAddedToast(recordTypeName));
  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteComponentType(action) {

  yield put(clearComponentTypeErrors());

  try {
    yield loading();

    // delete  ComponentType
    yield call(ApiComponentType.deleteComponentType, action.props);

    // update state for componentTypes
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteComponentTypeList(action) {

  yield put(clearComponentTypeErrors());

  try {
    yield loading();

    yield call(ApiComponentType.deleteComponentTypeList, action.props.list);

    // update state for componentTypes
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }
  } catch (error) {
    yield loading(false, error);
  }
}


function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiComponentType.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

function* loadAssemblyTypes() {
  try {
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_START_LOADING_ASSEMBLY_TYPES
    });

    const assemblyTypes = yield call(ApiComponentType.getAssemblyTypes);
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_SUCCESS_LOADING_ASSEMBLY_TYPES,
      props: {
        assemblyTypes
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_ERROR_LOADING_ASSEMBLY_TYPES,
      error: e.message
    })
  }
}


function* loadComponentGroups() {
  try {
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_START_LOADING_COMPONENT_GROUPS
    });

    const componentGroups = yield call(ApiComponentType.getComponentGroups);
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_SUCCESS_LOADING_COMPONENT_GROUPS,
      props: {
        componentGroups
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_ERROR_LOADING_COMPONENT_GROUPS,
      error: e.message
    })
  }
}

function* loadFields() {
  try {
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_START_LOADING_FIELDS
    });

    const fields = yield call(ApiComponentType.getFields);
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_SUCCESS_LOADING_FIELDS,
      props: {
        fields
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_ERROR_LOADING_FIELDS,
      error: e.message
    })
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_ALL, getComponentTypes),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_SEARCH, searchComponentTypes),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_GET, getComponentType),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_EDIT, editComponentType),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_ADD, addComponentType),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_DELETE, deleteComponentType),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_DELETE_CHECKED, deleteComponentTypeList),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_LOAD_ORGANIZATIONS, loadOrganizations),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_LOAD_ASSEMBLY_TYPES, loadAssemblyTypes),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_LOAD_COMPONENT_GROUPS, loadComponentGroups),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_LOAD_FIELDS, loadFields),
    takeLatest(actionTypes.SAGA_COMPONENTTYPE_BY_ORGANIZATION, getComponentTypesByOrganization),
  ]);
}

export default AuthWatcher;
