/**
 * @typedef {object} withSubobjectsLoadReducerStateType
 * @property {boolean} loading - true if it is loading, false otherwise.
 * @property {any[]} data - data loaded.
 * @property {string} error - error message.
 * @property {boolean} hasError - true if it has an error, false otherwise.
 */

/**
 * @typedef reduxAction
 * @property {string} type - type of the dispatched action.
 *
 *
 * @callback reduxReducer
 * @param {any} state - current state.
 * @param {reduxAction} action - dispatched action.
 * @returns {any} the new state.
 */

/**
 * Creates the enhanced state.
 *
 * @param {boolean} [loading=false] - true if it is loading, false otherwise.
 * @param {any[]} [data=[]] - data loaded.
 * @param {string} [error=""] - error message.
 *
 * @returns {withSubobjectsLoadReducerStateType}
 */
export const createState = (loading = false, data = [], error = "") => {
  return {loading, data, error, hasError: error.trim().length > 0};
};

/**
 * Enhances the state when subobjects started loading.
 *
 * @param {any} state - state to enhance.
 * @param {string} subObjectPropertyName - subobject property name that will hold the substate.
 * @returns {any} the new state with the subobject propert modified.
 */
const startLoadingSubObject = (state, subObjectPropertyName) => {
  return {
    ...state,
    [subObjectPropertyName]: createState(true)
  }
}

/**
 * Enhances the state when subobjects loaded successfully.
 *
 * @param {any} state - state to enhance.
 * @param {object} action - action dispatched.
 * @param {any} action.subObjectPropertyName
 * @param {string} subObjectPropertyName subobject property name that will hold the substate.
 * @returns {any} the new state with the subobject propert modified.
 */
const successLoadingSubObject = (state, action, subObjectPropertyName) => {
  return {
    ...state,
    [subObjectPropertyName]: createState(false, action.props[subObjectPropertyName])
  }
}

/**
 * Enhances the state when there was an error loading subobjects.
 *
 * @param {any} state - state to enhance.
 * @param {object} action - action dispatched.
 * @param {string} action.error - error message.
 * @param {string} subObjectPropertyName - subobject property name that will hold the substate.
 * @returns {any} the new state with the subobject propert modified.
 */
const errorLoadingSubObject = (state, action, subObjectPropertyName) => {
  return {
    ...state,
    [subObjectPropertyName]: createState(false, [], action.error)
  }
}

/**
 * Enhances/decorates a reducer with actions for:
 *  1. loading subobjects.
 *  2. success on loading subobjects.
 *  3. error on loading subobjects.
 *
 * The decoration only modifies a property of the state. This
 * property will be an object on the form:
 *
 *     {
 *      loading: boolean = false,
 *      data: any[] = [],
 *      error: string = ""
 *     }
 *
 * Where:
 *  - loading: indicates if subobjects are still being loaded.
 *  - data: subobjects loaded.
 *  - error: error message for when subobjects loading fails.
 *
 *
 * Use this reducer only when you have a property that has 3 states:
 *  1. loading.
 *  2. success loading.
 *  3. error loading.
 *
 *
 * @param {reduxReducer} wrappedReducer the reducer to be wrapped.
 * @param {object} config - a configuration object.
 * @param {string[]} config.subObjectPropertyName "subObjectPropertyName" is the
 * subobject property of the state that has to be enhanced. The value
 * of this property has to be an array with 3 elements where:
 *  - the first element is the action type for when the load
 *    of the subobjects has started.
 *  - the second element is the action type for when the load
 *    of the subobjects has been a success. Additional to this, the
 *    action needs to have a property with the same name as the
 *    enhanced property and has to be an array.
 *  - the third element is the action type for when there was an
 *    error loading the subobjects. Additional to this, the action needs
 *    to have an "error" property as a string.
 * @returns {any} the modified state if the action type is one of the provided in the
 * config parameter.
 */
const withSubobjectsLoadReducer = (wrappedReducer, config) => {
  return (state, action) => {
    //Get the actions that match with the type => [string, string, string]
    const matchingConfigValues = Object.values(config).find(current => current.includes(action.type));
    //Get the reduced state of the wrapped reducer.
    const wrappedReducerResult = wrappedReducer(state, action);

    //The action type provided is not supported by this configuration.
    if (!matchingConfigValues) {
      return wrappedReducerResult;
    }

    //Find the key of the config that has in the values array the action type => string
    const propertyName = Object.keys(config).find(k => Object.values(config[k]).includes(action.type));
    //Find the index of the action type from the values of the matching config => int
    const actionIndex = matchingConfigValues.findIndex(current => current === action.type);
    let thisResult = {};

    switch (actionIndex) {
      case 0: thisResult = startLoadingSubObject(state, propertyName); break;
      case 1: thisResult = successLoadingSubObject(state, action, propertyName); break;
      case 2: thisResult = errorLoadingSubObject(state, action, propertyName); break;
      default: thisResult = wrappedReducerResult;
    }

    return {
      ...wrappedReducerResult,
      ...thisResult
    }
  }
}

export default withSubobjectsLoadReducer;
