import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/message';
const endpointMessageType = API_SERVER + "/messageType";
const endpointMessageEvent = API_SERVER + "/messageEvent";

export default class ApiMessage {

  // GET 'api/messages'
  static getAll() {
    return Utils.genericCall(`${endpoint}/all`);
  }

  // GET 'api//messageType'
  static getMessageTypes() {
    return Utils.genericCall(`${endpointMessageType}`);
  }

  // GET 'api//messageEvent'
  static getMessageEvents() {
    return Utils.genericCall(`${endpointMessageEvent}`);
  }

  static searchMessages(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getMessage(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editMessage(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addMessage(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteMessage(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteMessageList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }
}
