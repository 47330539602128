import { call, put, select } from 'redux-saga/effects'

/**
 * @callback loadModelsApiMethodCallback
 * @returns any
 */

/**
 * @callback loadModelsPropsCreatorMethodCallback
 * @param {any} response - the response from the API.
 * @returns {any} the data to be send to the "onSuccess" action.
 */

/**
 * @callback loadModelsModelExtractorCallback
 * @param {any} currentStore - the current store value.
 * @returns {any[]} the models in the store.
 */

/**
 * Loads models from an API. This SAGA will trigger two of
 * three redux actions.
 *
 * If the modelExtractor callback returns an array with
 * elements, the API will not be called.
 *
 * @param {loadModelsApiMethodCallback} apiMethod - API function to call.
 * @param {loadModelsPropsCreatorMethodCallback} propsCreatorMethod - a function that will create the props object
 * for the redux action.
 * @param {loadModelsModelExtractorCallback} modelExtractor - a callback for extracting the models from the current store.
 * @param {object} actionsTypesNames - names of the actions to trigger.
 * @param {string} onStart - will be called when the fetch from the API is started.
 * @param {string} onSuccess - will be called when the API returns a success.
 * @param {string} onError - will be called when the API returns an error.
 * @yields objects to be used by ReduxSaga
 */
function* loadModels(apiMethod, propsCreatorMethod, modelExtractor, actionsTypesNames) {
  try {
    const existentModels = yield select(modelExtractor);

    if (existentModels.length > 0) {
      return;
    }

    yield put({ type: actionsTypesNames.onStart });
    const models = yield call(apiMethod);
    yield put({ type: actionsTypesNames.onSuccess, props: propsCreatorMethod(models)});
  } catch(error) {
    yield put({ type: actionsTypesNames.onError, error: error.message});
  }
}

export default loadModels;
