import React, { useEffect, useState, Fragment } from 'react';
import { Navigate } from 'react-router-dom'
import {
  Navbar,
  Nav,
  Row
} from 'reactstrap';
//import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Typography from 'components/Typography';
// app code
import SearchInput from 'components/SearchInput';
import bn from 'common/bemnames';
import { connect } from 'react-redux'

const bem = bn.create('header');


function Header(props) {

  const { 
    title, 
    breadcrumbs, 
    subdivision, 
    railroad 
  } = props;


  useEffect(()=>{
    removeElement()
  },[])

  const breadcrumbCollapsed = (breadcrumbs) => {
    if (!breadcrumbs) {
      return
    }
    return breadcrumbs.filter((breadcrumb) => breadcrumb.active === false)
  };

  const removeElement = () => {
    var element = document.getElementById("custom-css-styles");
    if (element && element.parentNode)
      element.parentNode.removeChild(element);
  }

  
  let client = breadcrumbs && breadcrumbs[1] ? breadcrumbs[1].name : null
  let searchInNav = null;
  if (props.onChangeSearchInput) {
    searchInNav = (
      <Nav navbar className={bem.e('nav-right')}>
        <SearchInput onChangeSearchInput={props.onChangeSearchInput} />
      </Nav>
    )
  }
  var breadcrumbslist = breadcrumbCollapsed(breadcrumbs);

  return (
    <Navbar className="cr-header position-fixed" >

      <div className={bem.e('div') + ' w-100'}>
        {props.navButton}
        {title && typeof title === 'string' ?
          <div>
            <Typography type="h1" className={bem.e('title ml-2')}>
              {title}
            </Typography>
            {railroad && subdivision !== undefined ?
              <div className='bridge-subtitle'>
                {client} - {railroad} - {subdivision}
              </div> : null
            }
          </div> :
          (
            title
          )}

        {breadcrumbslist && breadcrumbslist.length > 0 && (
          <Row className='pl-3'>
            {breadcrumbslist.map(({ name, active, url }, index) => (
              <Fragment key={index}>
                <label className='add-row-header-title mb-0 mr-1 pt-1'> &gt; </label>
                <a tag="a" href={url}>
                  {name}
                </a>
              </Fragment>
            ))}
          </Row>
        )}
      </div>

      <div className="d-flex ">
        {searchInNav}
      </div>
    </Navbar>
  );
}

function mapStateToProps(store) {
  return {
    title: store.header.title,
    breadcrumbs: store.header.breadcrumbs,
    user: store.claim.userClaims,
    onChangeSearchInput: store.header.onChangeSearchInput,
    toast: store.toast,
    subdivision: store.header.subdivision,
    railroad: store.header.railroad
  }
}

export default connect(mapStateToProps)(Header);
