import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {crossingFeatureFields} from 'common/objectStructures';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const initialState = {
  crossingFeatures: [],
  crossingFeature: {},
  formValues: null,
  pagination: {},
  loading: false,
  errors: '',
  organizations: createState()
}

function reducerCrossingFeature (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_CROSSINGFEATURE_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(crossingFeatureFields)) {
          newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_CROSSING_FEATURE_PROPS:
      return initialState;

    case actionTypes.CLEAR_CROSSING_FEATURE_ERRORS:
      return {
        ...state,
        errors: ''
      }

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerCrossingFeature),
  {
    organizations: [
      actionTypes.CHANGE_CROSSINGFEATURE_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_CROSSINGFEATURE_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_CROSSINGFEATURE_ERROR_LOADING_ORGANIZATIONS
    ]
  }
);
