import { all, call, put, takeLatest, select, putResolve } from 'redux-saga/effects';
import * as actionTypes from 'store/actions/actionTypes';
// APIs to talk to...
//import ApiTrip from 'tests/mocks/ApiTrip.mock'
import ApiTrip from 'apiServices/ApiTrip';
import ApiAssetEvent from 'apiServices/ApiAssetEvent';
import _ from 'lodash';
import { ROUTE_TRIP_BASE } from '../../config/constants';

import {
  showReportSendToEmailToast,
  showRerpotGenerationFailedToast,
  showRecordAddedToast,
  showRecordUpdatedToast,
  showRecordDeletedToast,
  showToastAsSuccess
} from '../actions/ToastActions';

const recordType = "trip";
const assetRecordType = "trip asset";

const getPaginationData = state => state.trip.paginationRequest;

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    console.log("errors", errors)
    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The trip cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_TRIP_PROPS, props });
}

/*
 * get Trips
 */
function* getTrips() {
  try {
    yield loading();
    // get all Trips
    const trips = yield call(ApiTrip.getAll);
    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        trips: trips,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchTrips(action) {
  try {
    yield loading();
    // get all Trips
    const trips = yield call(ApiTrip.searchTrips, action.props.props);

    const pagination = {
      number: trips.number,
      numberOfElements: trips.numberOfElements,
      totalElements: trips.totalElements,
      totalPages: trips.totalPages,
      size: trips.size
    };

    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        trips: trips.content,
        pagination: pagination,
        paginationRequest: action.props,
        loading: false,
        errors: ''
      }
    });

    if(action.props.callback){
      action.props.callback()
    }
  } catch (error) {
    yield loading(false, error);
  }

}

function* loadTripAssets(action) {
  try {
    yield loading();
    // get assets for a trip
    const tripAssets = yield call(ApiTrip.getTripAssetsPaged, action.props);

    const pagination = {
      number: tripAssets.number,
      numberOfElements: tripAssets.numberOfElements,
      totalElements: tripAssets.totalElements,
      totalPages: tripAssets.totalPages,
      size: tripAssets.size
    };
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        tripAssets: tripAssets.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* loadTripAssetsCallback(action) {
  try {
    yield loading();
    // get assets for a trip
    const tripAssets = yield call(ApiTrip.getTripAssets, action.props);

    const listOfClient = tripAssets.map(e => e.clientName);
    const listOfRailroad = tripAssets.map(e => e.railroadName);
    const listOfSubdivision = tripAssets.map(e => e.bridgeId.split('-',3)[2]);

    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assetSelectedForTrip: tripAssets,
        clientSelectedFroTrip: _.uniq(listOfClient),
        railroadSelectedForTrip: _.uniq(listOfRailroad),
        subdivisionSelectedForTrip: _.uniq(listOfSubdivision),
      }
    });

    if (action.props.callback)
      yield call(action.props.callback);

    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getTrip(action) {
  try {
    yield loading();
    // get  Trip
    const trip = yield call(ApiTrip.getTrip, action.props.tripId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: trip.name,
        id: trip.id,
        type: "trip"
      }
    });
    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        trip: trip,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getTripWithCallback(action) {
  try {
    yield loading();
    // get  Trip
    const trip = yield call(ApiTrip.getTrip, action.props.tripId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: trip.name,
        id: trip.id,
        type: "trip"
      }
    });
    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        trip: trip,
        loading: false,
        errors: ''
      }
    });
    yield call(action.props.callback);
  } catch (error) {
    yield loading(false, error);
  }

}

function* addTrip(action) {
  try {
    yield loading();
    // add  Trip
    yield call(ApiTrip.addTrip, action.props);
    yield put(showRecordAddedToast(recordType));
    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        trip: {},
        loading: false,
        errors: ''
      }
    });
    yield getTrips();
  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteTrip(action) {
  try {
    yield loading();
    // delete  Trip
    yield call(ApiTrip.deleteTrip, action.props);
    yield put(showRecordDeletedToast(recordType));
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
    yield searchTrips({ props: { search: '', page: 0, limit: 10 } });
  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteTripChecked(action) {
  try {
    yield loading();
    yield call(ApiTrip.deleteTripChecked, action.props);
    yield put(showRecordDeletedToast(recordType));
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
    const pagination = yield select(getPaginationData);
    yield searchTrips({ props: pagination });
  } catch (error) {
    yield loading(false, error);
  }

}

function* addFirstSaveTrip(action) {
  try {
    yield loading();
    // add  Trip
    const response = yield call(ApiTrip.addFirstSaveTrip, action.props);
    yield put(showRecordAddedToast(recordType));
    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        trip: response,
        loading: false,
        errors: '',
        redirectTo:`${ROUTE_TRIP_BASE}/${response.id}`
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* addSecondSaveTrip(action) {
  try {
    yield loading();

    // add  Trip
    yield call(ApiTrip.addSecondSaveTrip, action.props.data);
    yield put(showRecordAddedToast(assetRecordType));
    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        trip: {},
        loading: false,
        errors: ''
      }
    });

    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assetsForTrip: [],
        assetSelectedForTrip: [],
      }
    });

    yield call(action.props.callback);
  } catch (error) {
    yield loading(false, error);
  }

}

function* editFirstSaveTrip(action) {
  try {
    yield loading();
    // update  Trip
    const trip = yield call(ApiTrip.editFirstSaveTrip, action.props);
    yield put(showRecordUpdatedToast(recordType));
    // update state for trips
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: trip.name,
        id: trip.id,
        type: "trip"
      }
    });
    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        trip: trip,
        loading: false,
        errors: ''
      }
    });
    yield searchTrips({ props: { search: '', page: 0, limit: 10 } });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editSecondSaveTrip(action) {
  try {
    yield loading();
    // add  Trip
    yield call(ApiTrip.editSecondSaveTrip, action.props.data);
    yield put(showRecordUpdatedToast(assetRecordType));
    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        trip: {},
        loading: false,
        errors: ''
      }
    });
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assetsForTrip: [],
        assetSelectedForTrip: [],
        clientSelectedFroTrip: [],
        railroadSelectedForTrip: [],
        subdivisionSelectedForTrip: []
      }
    });
    yield call(action.props.callback);
  } catch (error) {
    yield loading(false, error);
  }

}

function* completeTrip(action) {
  try {
    yield loading();
    // update  Trip
    yield call(ApiTrip.completeTrip, action.props);
    yield put(showToastAsSuccess("Completed trip", "The trip has been set as completed."));
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    // update state for trips
    yield getTrip({ props: { tripId: action.props.id } });
    yield searchTrips({ props: { search: '', page: 0, limit: 10 } });
  } catch (error) {
    yield loading(false, error);
  }

}

function* loadTripPhotosPaged(action) {
  try {
    yield loading();
    // update  Trip
    const tripPhotos = yield call(ApiTrip.loadTripPhotosPaged, action.props);
    const pagination = {
      number: tripPhotos.number,
      numberOfElements: tripPhotos.numberOfElements,
      totalElements: tripPhotos.totalElements,
      totalPages: tripPhotos.totalPages,
      size: tripPhotos.size
    };
    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        tripPhotos: tripPhotos.content,
        tripPhotosPagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* UploadTripPhotos(action) {
  try {
    yield loading();
    // const uploadedPhotos = yield call(ApiTrip.UploadTripPhotos, action.props.tripId, action.props.data);
    // update state for trips
    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    let filterObject = {
      filterArr: [
        { id: "photoUploaded", arrayValue: [] }
      ],
      limit: 10,
      order: { id: "", desc: false },
      page: 0,
      search: ""
    };

    yield call(loadTripPhotosPaged, {
      props: { tripId: action.props.tripId, data: filterObject }
    });

    yield call(action.props.callback);
  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteTripAssets(action) {
  try {
    yield loading();
    // update  assetEvent
    yield call(ApiAssetEvent.deleteTripAssets, action.props.data);
    yield put(showRecordDeletedToast(assetRecordType));
    // update state for assetEvents
    yield put({
      type: actionTypes.CHANGE_ASSETEVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    let filterObject = {
      filterArr: [
        { id: "clientIds", arrayValue: [] },
        { id: "railroadIds", arrayValue: [] },
        { id: "subdivisionIds", arrayValue: [] }
      ],
      limit: 20,
      order: { id: "", desc: false },
      page: 0,
      search: ""
    };

    yield call(loadTripAssetsCallback, {
      props: { tripId: action.props.tripId, filterObject }
    });

    yield put({
      type: actionTypes.CHANGE_TRIP_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* generateTripFieldReports(action) {

  try {
    yield call(ApiTrip.generateFieldReports, action.props.tripId);
    yield put(showReportSendToEmailToast());
  } catch(error) {
    yield put(showRerpotGenerationFailedToast());
  }
}

function* getRoles() {
  try {
    yield put({ type: actionTypes.CHANGE_TRIP_START_LOADING_ROLES });
    const roles = yield call(ApiTrip.getRoles);
    yield put({ type: actionTypes.CHANGE_TRIP_SUCCESS_LOADING_ROLES, props: { roles }});
  } catch(error) {
    yield put({ type: actionTypes.CHANGE_TRIP_ERROR_LOADING_ROLES, error: error.message});
  }
}

function* getUsersRoles() {
  try {
    yield put({ type: actionTypes.CHANGE_TRIP_START_LOADING_USERS_ROLES });
    const usersRoles = yield call(ApiTrip.getUsersRoles);
    yield put({ type: actionTypes.CHANGE_TRIP_SUCCESS_LOADING_USERS_ROLES, props: { usersRoles }});
  } catch(error) {
    yield put({ type: actionTypes.CHANGE_TRIP_ERROR_LOADING_USERS_ROLES, error: error.message});
  }
}

function* loadClients() {
  try {
    yield put({ type: actionTypes.CHANGE_TRIP_START_LOADING_CLIENTS });
    const clients = yield call(ApiTrip.getClients);
    yield put({ type: actionTypes.CHANGE_TRIP_SUCCESS_LOADING_CLIENTS, props: { clients }});
  } catch(error) {
    yield put({ type: actionTypes.CHANGE_TRIP_ERROR_LOADING_CLIENTS, error: error.message});
  }
}


/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_TRIP_ALL, getTrips),
    takeLatest(actionTypes.SAGA_TRIP_SEARCH, searchTrips),
    takeLatest(actionTypes.SAGA_TRIP_LOAD_ASSETS, loadTripAssets),
    takeLatest(actionTypes.SAGA_TRIP_LOAD_ASSETS_CALLBACK, loadTripAssetsCallback),
    takeLatest(actionTypes.SAGA_TRIP_GET, getTrip),
    takeLatest(actionTypes.SAGA_TRIP_GET_CALLBACK, getTripWithCallback),
    takeLatest(actionTypes.SAGA_TRIP_ADD, addTrip),
    takeLatest(actionTypes.SAGA_TRIP_DELETE, deleteTrip),
    takeLatest(actionTypes.SAGA_TRIP_DELETE_CHECKED, deleteTripChecked),
    takeLatest(actionTypes.SAGA_TRIP_ADD_FIRST_SAVE, addFirstSaveTrip),
    takeLatest(actionTypes.SAGA_TRIP_ADD_SECOND_SAVE, addSecondSaveTrip),
    takeLatest(actionTypes.SAGA_TRIP_EDIT_FIRST_SAVE, editFirstSaveTrip),
    takeLatest(actionTypes.SAGA_TRIP_EDIT_SECOND_SAVE, editSecondSaveTrip),
    takeLatest(actionTypes.SAGA_TRIP_COMPLETE, completeTrip),
    takeLatest(actionTypes.SAGA_TRIP_LOAD_PHOTOS, loadTripPhotosPaged),
    takeLatest(actionTypes.SAGA_TRIP_UPLOAD_PHOTOS, UploadTripPhotos),
    takeLatest(actionTypes.SAGA_TRIP_DELETE_ASSETEVENT, deleteTripAssets),
    takeLatest(actionTypes.SAGA_TRIP_GENERATE_FIELD_REPORTS, generateTripFieldReports),
    takeLatest(actionTypes.SAGA_TRIP_GET_ROLES, getRoles),
    takeLatest(actionTypes.SAGA_TRIP_GET_USERS_ROLES, getUsersRoles),
    takeLatest(actionTypes.SAGA_TRIP_LOAD_CLIENTS, loadClients)
  ]);
}

export default AuthWatcher;
