import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/structureType';
const typeOfStructurEndpoint = API_SERVER + '/assemblyType/typeOfStructure';

export default class ApiStructureType {

  // GET 'api/structureTypes'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchStructureTypes(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getStructureType(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editStructureType(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addStructureType(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteStructureType(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteStructureTypeList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getSingleStructureType(id,idAssetEvent){
    return Utils.genericPost(`${endpoint}/${id}/assetEvent/${idAssetEvent}`);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getAssetTypes() {
    return Utils.genericCall(`${endpoint}/assetTypes`);
  }

  static getComponents(data){
    return Utils.genericCall(`${typeOfStructurEndpoint}/${data.typeOfStructure}/organization/${data.organizationId}`);
  }

  
}
