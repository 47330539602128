import { all, takeLatest, call, take, put, takeEvery } from 'redux-saga/effects';
import { eventChannel, buffers } from 'redux-saga';

import * as actionTypes from '../actions/actionTypes';
import * as webSocketEventTypes from "config/webSocketEventTypes";
import {
  endSession,
  subscribeToChannels,
  eventEmitter,
  startSession,
  removeChannelSubscriptions
} from '../../websockets/websockets';
import * as webSocketChannelNameCreators from 'config/webSocketChannelNameCreators';
import { showToastAsSuccess, showToastAsError } from '../actions/ToastActions';

/**
 * @namespace WebSocketSaga
 */



function connect(token) {
  return eventChannel(
    emit => {
      eventEmitter.on("MESSAGE_RECEIVED", message => {
        emit(message);
      });
      startSession(token);

      return () => {
        endSession();
      }
    },
    buffers.fixed(20)
  );
}

/**
 * @memberof WebSocketSaga
 * @description Starts a WebSocket session.
 *
 * @param {object} action
 * @param {object} action.props
 * @param {string} action.props.token - session token.
 * @param {string} action.props.userId - logged user ID.
 */
function* startWebSocketSession(action) {
  try {
    const eChannel = yield call(connect, action.props.token);
    yield put({
      type: actionTypes.SAGA_WEBSOCKET_SUBSCRIBE_TO_CHANNEL,
      props: {
        channelName: webSocketChannelNameCreators.notifications(action.props.userId),
        nonRemovable: true
      }
    });

    do {
      const nextMessage = yield take(eChannel);

      yield put({
        type: actionTypes.WEBSOCKET_MESSAGE_RECEIVED,
        props: {
          eventType: nextMessage.eventType,
          payload: nextMessage.payload
        }
      });
    } while(true);
  } catch (error) {
    console.warn("WebSocket session could not be started");
    console.warn(error);
  }
}

/**
 * @memberof WebSocketSaga
 * @description Ends the WebSocket session.
 */
function* endWebSocketSession() {
  try {
    yield call(endSession);
  } catch(e) {}
}

/**
 * @memberof WebSocketSaga
 * @description Subscribes to a channel.
 *
 * @param {object} action
 * @param {object} action.props
 * @param {string} action.props.channelName - name of the channel to witch to subscribe.
 * @param {boolean} [action.props.nonRemovable=false] - if the channel subscription shoult not
 * be removed until the user stops using the application.
 */
function* subscribeToChannel(action) {
  const channelName = action.props.channelName;
  const removable = !action.props.nonRemovable;
  try {
    yield call(subscribeToChannels, channelName, removable);
  } catch(error) {
    console.error("Error subscribing to channel " + channelName);
    console.error(error);
  }
}

/**
 * @memberof WebSocketSaga
 * @description Removes all removable channel subscriptions.
 */
function* removeSubscriptions() {
  try {
    yield call(removeChannelSubscriptions);
  } catch(e) {}
}

/**
 * @memberof WebSocketSaga
 * @description Captures WebSocket user notification message
 * @param {object} action
 * @param {object} action.props
 * @param {string} action.props.eventType - WebSocket event type. If it is equal to USER_NOTIFICATION,
 * the this generator will continue the execution.
 * @param {string} action.props.type - type of user notification (ERROR or SUCCESS).
 * @param {string} action.props.message - notification message.
 * @param {string} action.props.description - notification description.
 */
function* onWebSocketMessage(action) {
  const eventType = action.props.eventType;
  if (eventType === webSocketEventTypes.USER_NOTIFICATION) {
    const messageType = action.props.payload.type;
    const message = action.props.payload.message;
    const description = action.props.payload.description;

    switch (messageType) {
      case "ERROR":
        yield put(showToastAsError(message, description));
        break;

      case "SUCCESS":
        yield put(showToastAsSuccess(message, description));
        break;

      default:
    }
  }
}

function* WebSocketSaga() {
  yield all([
    takeLatest(actionTypes.SAGA_WEBSOCKET_START_SESSION, startWebSocketSession),
    takeLatest(actionTypes.SAGA_WEBSOCKET_END_SESSION, endWebSocketSession),
    takeLatest(actionTypes.SAGA_WEBSOCKET_SUBSCRIBE_TO_CHANNEL, subscribeToChannel),
    takeLatest(actionTypes.SAGA_WEBSOCKET_REMOVE_ALL_SUBSCRIPTIONS, removeSubscriptions),
    takeEvery(actionTypes.WEBSOCKET_MESSAGE_RECEIVED, onWebSocketMessage),
    takeLatest(actionTypes.ROUTE_LEAVING_CURRENT, removeSubscriptions)
  ]);
}

export default WebSocketSaga;
