import { combineReducers } from 'redux'
// app reducers
import auth  from 'store/reducers/authReducer'
import email from 'store/reducers/emailReducer'
import user  from 'store/reducers/userReducer'
import bridge from 'store/reducers/bridgeReducer'
import client from 'store/reducers/clientReducer'
import railroad from 'store/reducers/railroadReducer'
import subdivision from 'store/reducers/subdivisionReducer'
import event from 'store/reducers/eventReducer'
import assembly from 'store/reducers/assemblyReducer'
import component from 'store/reducers/componentReducer'
import names from 'store/reducers/namesReducer'
import header from 'store/reducers/headerReducer'
import error from 'store/reducers/errorReducer'
import message  from 'store/reducers/messageReducer'
import messageField  from 'store/reducers/messageFieldReducer'
import role  from 'store/reducers/roleReducer'
import assemblyType  from 'store/reducers/assemblyTypeReducer'
import componentType  from 'store/reducers/componentTypeReducer'
import componentGroup  from 'store/reducers/componentGroupReducer'
import documentType  from 'store/reducers/documentTypeReducer'
import eventType  from 'store/reducers/eventTypeReducer'
import claim from "store/reducers/claimReducer";
import structureType  from 'store/reducers/structureTypeReducer'
import impactType  from 'store/reducers/impactTypeReducer'
import assetType  from 'store/reducers/assetTypeReducer'
import crossingFeature  from 'store/reducers/crossingFeatureReducer'
import assetEvent from 'store/reducers/assetEventReducer'
import sectionEvent from 'store/reducers/sectionEventReducer'
import asset from 'store/reducers/assetReducer'
import trip from 'store/reducers/tripReducer'
import contact from 'store/reducers/contactReducer'
import organization from 'store/reducers/organizationReducer'
import milePosts from 'store/reducers/milePostReducer'
import recommendations from 'store/reducers/eventRecommendationReducer'
import workPerformed from 'store/reducers/workPerformedReducer'
import accessType from 'store/reducers/accessTypeReducer'
import eventCondition from 'store/reducers/eventConditionReducer'
import eventNote from 'store/reducers/eventNoteReducer'
import condition from 'store/reducers/conditionReducer'
import location from 'store/reducers/locationReducer'
import assemblyEvent from 'store/reducers/assemblyEventReducer'
import componentEvent from 'store/reducers/componentEventReducer'
import image from 'store/reducers/imageReducer'
import milestone from 'store/reducers/milestoneReducer'
import directionalLoading  from 'store/reducers/directionalLoadingReducer'
import waterFlow  from 'store/reducers/waterFlowReducer'
import alignment  from 'store/reducers/alignmentReducer'
import deckType  from 'store/reducers/deckTypeReducer'
import inspectionType from 'store/reducers/inspectionTypeReducer'
import toast from 'store/reducers/toastReducer'
import document from 'store/reducers/documentReducer'
import jobTitle from 'store/reducers/jobTitleReducer'
import menuActive from 'store/reducers/menuActiveReducer'
import permissionsReducer from 'store/reducers/permissionsReducer'

const rootReducer = combineReducers({
  auth,
  email,
  user,
  bridge,
  client,
  railroad,
  subdivision,
  event,
  names,
  header,
  message,
  messageField,
  assembly,
  component,
  role,
  assemblyType,
  componentType,
  componentGroup,
  documentType,
  eventType,
  claim,
  structureType,
  impactType,
  assetType,
  crossingFeature,
  assetEvent,
  sectionEvent,
  asset,
  trip,
  contact,
  organization,
  milePosts,
  recommendations,
  workPerformed,
  accessType,
  eventCondition,
  eventNote,
  condition,
  location,
  assemblyEvent,
  componentEvent,
  error,
  image,
  milestone,
  directionalLoading,
  waterFlow,
  alignment,
  deckType,
  inspectionType,
  toast,
  document,
  jobTitle,
  menuActive,
  permissionsReducer
});

export default rootReducer;
