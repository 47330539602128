import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/user';

export default class ApiUser {

  // GET 'api/users'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchUsers(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getUser(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editUser(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addUser(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteUser(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteUserList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static sendForgotPassword(data){
    return Utils.genericPost(API_SERVER + '/forgotpassword', data, {});
  }

  static changePassword(data){
    return Utils.genericPost(API_SERVER + '/forgotpassword/reset', data, {});
  }

  static uploadUserSegnature(props){
    let headers = Utils.getHeaders();
    headers['Content-Type'] = '';
    return Utils.genericPost(`${endpoint}/${props.userId}/photo/upload`, props.formData, headers);
  }

  static deleteUserSegnature(props){
    return Utils.genericDelete(`${endpoint}/${props.userId}/photo`);
  }


  // GET 'api/users'
  static getUsersForTrips() {
    return Utils.genericCall(`${endpoint}/usersForTrip`);
  }


  static getAllUserParentOrganization(){
    return Utils.genericCall(`${endpoint}/parentOrganizationWithRoles`);
  }

  static getUserProfile() {
    return Utils.genericCall(`${endpoint}/me`);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getClients() {
    return Utils.genericCall(`${endpoint}/clients`);
  }

  static findClients() {
    return Utils.genericCall(`${endpoint}/findClients`);
  }

  static getContacts() {
    return Utils.genericCall(`${endpoint}/contacts`);
  }

  static getRoles() {
    return Utils.genericCall(`${endpoint}/roles`);
  }

  static addContact(contactData) {
    return Utils.genericPost(`${endpoint}/contacts`, contactData);
  }

  static loadJobTitles() {
    return Utils.genericCall(`${endpoint}/jobTitles`);
  }

  static changeSelectedOrganization(organizationId) {
    return Utils.genericPost(`${endpoint}/switchOrganization`, {organizationId})
  }

  static cleanAttempts() {
    return Utils.genericPost(`${endpoint}/cleanAttempts`)
  }

  static remainingAttempts(user) {
    console.log("props",user)
    return Utils.genericPost(API_SERVER + `/failedAttempts`, user,{})
  }
  
}
