import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/organization';

export default class ApiPage {

  // GET 'api/pages'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static getTypes(){
    return Utils.genericCall(`${endpoint}/types`);
  }

  static searchOrganizations(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getOrganization(id) {
    return Utils.genericCall(`${endpoint}/${id}`)
  }

  static deleteOrganizationList(props) {

    return new Promise(async (resolve, reject) => {

      try {
        const result = await Utils.genericPost(`${endpoint}/delete`, props);
        resolve(result);
      } catch(e) {

        if (e.response.status !== 405) {
          reject(e);
        } else {
          reject({
            type: 'constraintViolation'
          });
        }
      }
    });
  }

  static editOrganization(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addOrganization(props) {
    return Utils.genericPost(endpoint, props)
  }

  static getCurrentUserOrganization() {
    return Utils.genericCall(`${endpoint}/myOrganization`);
  }
}
