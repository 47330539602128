import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/crossingFeature';

export default class ApiCrossingFeature {

  // GET 'api/crossingFeatures'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static searchCrossingFeatures(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getCrossingFeature(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editCrossingFeature(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addCrossingFeature(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteCrossingFeature(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteCrossingFeatureList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }
}
