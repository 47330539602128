import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer'

const initialState = {
  assemblys: [],
  assemblysEventForForm: [],
  assemblysBySection: [],
  assembly: {},
  newAssembly: {},
  assemblyGroup: [],
  assemblyGroupOnlyLastCompleted: [],
  formValues: null,
  allFields: [],
  assembliesArray: [],
}

function reducerAssembly (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_ASSEMBLY_PROPS:
      return Object.assign({}, state, action.props)

    case actionTypes.CHANGE_STRUCTURETYPE_IS_ASSIGNED_PROPS:

      let assemblyGroup = [].concat(state.assemblyGroup).map(element => {
        if(element.structureType.id === action.props.structureId && !element.structureType.isAssigned)
          element.structureType.isAssigned = true

        return element
      })
      return Object.assign({}, state, assemblyGroup)
    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerAssembly);
