import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {messageFieldFields} from 'common/objectStructures';

const initialState = {
  messageFields: [],
  messageField: {},
  formValues: null,
}

function reducerMessageField (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_MESSAGEFIELD_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(messageFieldFields)) {
          newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_MESSAGEFIELD_PROPS:
      return initialState;

    case actionTypes.CHANGE_MESSAGEFIELD:
      return {
        ...state,
        messageFields: [...state.messageFields.filter(x => x.id === action.props.messageField.id), action.props.messageField]
      };

    case actionTypes.CHANGE_MESSAGEFIELD_REMOVED:
      return {
        ...state,
        //remove deleted message FIELDS
        messageFields: state.messageFields.filter(x => action.props.removedMessageFieldsIds.includes(x.id) === false)
      };

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerMessageField);
