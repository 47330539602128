import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {eventConditionFields} from 'common/objectStructures';

const initialState = {
    eventConditions: [],
    eventCondition: {},
    pagination: {},
    formValues: null,
    loading: false,
    errors: ''
}

function reducerEventCondition(state = initialState, action) {
    switch (action.type) {

        case actionTypes.CHANGE_EVENTCONDITION_PROPS:
            if (action.props.formValues != null) {
                let newValues = {
                    id: action.props.formValues.id
                }
                for (const [key, value] of Object.entries(eventConditionFields)) {
                    newValues[key] = action.props.formValues[key] || (value.type === 'number' ? 0 : (value.type === 'bool' ? false : ''));
                }
                action.props.formValues = newValues
            }
            return Object.assign({}, state, action.props)

        case actionTypes.CHANGE_EVENTCONDITION_PROPS_ADD_NEW_PHOTO:
            let eventCondition = Object.assign({}, state.eventCondition, {})
            eventCondition.eventPhotoEventConditions = action.props.newPhoto
            return Object.assign({}, state, {eventCondition})

        default:
            return state
    }
}

export default onLoginCleanupReducer(initialState, reducerEventCondition);
