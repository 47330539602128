import { isString, isInteger } from 'lodash';
import jwtDecode from 'jwt-decode';
import { all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
import { INITIAL_NAMES } from 'common/constants'
// APIs to talk to...
import Cookies from "universal-cookie";
import ApiAuth from 'apiServices/ApiAuth';
import ApiUser from 'apiServices/ApiUser';
import ApiClaim from 'apiServices/ApiClaim';
import Utils from 'common/utils';
import { showToastAsError } from 'store/actions/ToastActions';

const cookies = new Cookies();

/*
 * Try to reload token from localStorage
 */
function* authLoadToken() {
  try {
    yield put({ type: actionTypes.CHANGE_AUTH_PROPS, props: { loading: true } });
    let token = localStorage.getItem('auth.token');
    let expire = parseInt(localStorage.getItem('auth.expire'), 10);
    if (!token || !isString(token) || !isInteger(expire)) {
      // can't retrieve the token from localStorage
      yield put({ type: actionTypes.RESET_AUTH_PROPS });
    }
    if (new Date(expire) < new Date()) {
      // token is expired...
      yield put({ type: actionTypes.SAGA_AUTH_LOGOUT });
    } else {
      // complete user login
      yield authLoadUserData(token);
    }
  } catch (error) {
    yield put({ type: actionTypes.CHANGE_AUTH_PROPS, props: { loading: false, errors: error.toString() } });
  }
}

/*
 * Make a login request
 */
function* authLogin(action) {
  try {
    const lastUserId = cookies.get('idUser');

    // reset all messages
    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        loading: true,
        errors: '',
        error: '',
        message: '',
      }
    });
    // api login call
    const response = yield call(ApiAuth.login, action.props);
    // save token in localStorage
    let expire = Date.now() + (1000 * response.expires_in);
    localStorage.setItem('auth.token', response.access_token);
    localStorage.setItem('auth.expire', expire);
    sessionStorage.setItem('names', JSON.stringify(INITIAL_NAMES));
    sessionStorage.setItem('sideBar', true);

    //New user id is stored in cookies to be able to compare in next login
    const decodedNewToken = jwtDecode(response.access_token);
    const newUserId = decodedNewToken.idUser;
    cookies.set('idUser', newUserId)

    //New user is not the same from last login.
    if (lastUserId !== newUserId) {
      yield put({
        type: actionTypes.CHANGE_AUTH_USER_PROPS
      });
    }

    const clamis = yield call(ApiClaim.getUserclaims);

    // localStorage.setItem('userClaims', JSON.stringify(clamis));
    cookies.set('userClaims', JSON.stringify(clamis), {
      path: "/" //root path
    });
    // complete user login
    yield authLoadUserData(response.access_token);

    //if user successfuly login, reset failed attempts to avoid block
    yield ApiUser.cleanAttempts()

  } catch (error) {
    let errorMessage
    const user = { user: action.props.username }
    let attempts = yield call(ApiUser.remainingAttempts, user)
    if (attempts !== 0) {
      errorMessage = "Incorrect username or password. You have " + attempts + " attempts left.";
    } else if (!Utils.isEmpty(error.response.data.error_description)) {
      errorMessage = error.response.data.error_description;
    }

    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        loading: false,
        errors: errorMessage,
      }
    })
  }
}

/*
 * After a succesful login (or loading token from localStore)
 * retrieve user data from server
 */
function* authLoadUserData(token, startWebsocketSession = true) {
  const tokenClaims = jwtDecode(token);

  // get user data
  //const loggedUser = yield call(ApiUser.getById, tokenClaims.jti);
  // save token, loggedUser, roles in session
  yield put({
    type: actionTypes.CHANGE_AUTH_PROPS, props: {
      loading: false,
      errors: '',
      message: '',
      token: token,
      session: tokenClaims,
      roles: [],
    }
  });


  //Start a WebSocket session.
  if (startWebsocketSession) {
    yield put({
      type: actionTypes.SAGA_WEBSOCKET_START_SESSION,
      props: {
        token: token,
        userId: tokenClaims.idUser
      }
    });
  }
}

/*
 * Remove token from redux store and localStorage
 */
function* authLogout(action) {
  try {
    cookies.set("inspections", null)
    cookies.set("BridgesPage", null)
    cookies.set("filtersBridges", null)
    cookies.set("InspectionsDashboardPage", null)
    cookies.set("InspectionsPage", null)
    cookies.set("order", null)
    localStorage.clear();
    sessionStorage.clear()
    yield put({ type: actionTypes.RESET_AUTH_PROPS });
    yield put({ type: actionTypes.SAGA_WEBSOCKET_END_SESSION });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        loading: false,
        errors: error.toString(),
      }
    })
  }
}



function* authForgotPassEmail(action) {
  try {

    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        loading: true,
        errors: ''
      }
    });
    yield call(ApiUser.sendForgotPassword, action.props);
    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        loading: false,
        errors: '',
        message: 'Look at the inbox for the email for change password'
      }
    });

  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        loading: false,
        errors: '',
        message: 'Look at the inbox for the email for change password'
      }
    })
  }
}

function* authChangePass(action) {
  try {
    const result = yield call(ApiUser.changePassword, action.props);
    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        redirectTo: result.returnUrl
      }
    });

  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        loading: false,
        errors: error,
        redirectTo: "/"
      }
    })
  }
}

function* authShowError(action) {
  try {
    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        loading: false,
        errors: action.props.msgError,
        message: ''
      }
    });

  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        loading: false,
        errors: error,
      }
    })
  }
}


function* changeSelectedOrganization(action) {
  try {
    const organizationId = action.props.organizationId;
    const newToken = yield call(ApiUser.changeSelectedOrganization, organizationId);



    //Redirect to home
    yield put({
      type: actionTypes.CHANGE_AUTH_PROPS, props: {
        loading: false,
        errors: "",
        redirectTo: "/"
      }
    })
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: [],
        loading: false,
        errors: ''
      }
    });
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
        assetTypes: [],
        loading: false,
        errors: ""
      }
    });
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: {
        assemblyTypesByOrganization: [],
        loading: false,
        errors: ""
      }
    });


    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clients: [],
        loading: false,
        errors: ''
      }
    });
    yield put({
      type: actionTypes.CHANGE_ASSET_START_LOADING_CLIENTS, props: {
        loading: false,
        errors: ''
      }
    });
    yield put({
      type: actionTypes.CHANGE_ASSET_SUCCESS_LOADING_CLIENTS, props: {
        loading: false,
        errors: ''
      }
    });
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        events: [],
        loading: false,
        errors: ''
      }
    });
    yield put({
      type: actionTypes.CHANGE_USER_PROPS, props: {
        users: [],
        loading: false,
        errors: ''
      }
    });
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
        assetTypesByOrganization: [],
        loading: false,
        errors: ""
      }
    });
    yield put({
      type: actionTypes.CHANGE_COMPONENTTYPE_PROPS, props: {
        componentTypeByOrganization: [],
        loading: false,
        errors: ''
      }
    });
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        allFields: [],
        loading: false,
        errors: ''
      }
    });
    //websocket session should have been set on login.
    yield authLoadUserData(newToken.token, false /* startWeboscketSession */);
    //checkt out userSaga#authLogin
    localStorage.setItem('auth.token', newToken.token)

  } catch (e) {
    yield put(showToastAsError("Error changing tenant", "There was an error trying to switch tenant. Please try again later."));
  }
}

function* changeSelectedClient(action) {
  let session = yield select(store => store.auth.session)
  session.client = action.props.clientId

  yield put({
    type: actionTypes.CHANGE_AUTH_PROPS, props: {
      loading: false,
      errors: "",
      redirectTo: "/",
      session: session
    }
  })

}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_AUTH_LOAD, authLoadToken),
    takeLatest(actionTypes.SAGA_AUTH_LOGIN, authLogin),
    takeEvery(actionTypes.SAGA_AUTH_LOGOUT, authLogout),
    takeLatest(actionTypes.SAGA_AUTH_FORGOT_PASS_EMAIL, authForgotPassEmail),
    takeLatest(actionTypes.SAGA_AUTH_CHANGE_PASS, authChangePass),
    takeLatest(actionTypes.SAGA_AUTH_ERROR, authShowError),
    takeLatest(actionTypes.SAGA_AUTH_CHANGE_SELECTED_ORGANIZATION, changeSelectedOrganization),
    takeLatest(actionTypes.SAGA_AUTH_CHANGE_SELECTED_CLIENT, changeSelectedClient)
  ]);
}

export default AuthWatcher;
