import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
// custom app components
import rootReducer from './reducers';
import watchAllSagas from './sagas';
import { composeWithDevTools } from 'redux-devtools-extension';
import LogRocket from 'logrocket';


//const logger = createLogger();
const saga = createSagaMiddleware();


const store = createStore(
    rootReducer,
    undefined,
    composeWithDevTools(
    applyMiddleware(saga, LogRocket.reduxMiddleware())
    )
  );

saga.run(watchAllSagas);

export default store;
