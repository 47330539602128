import * as actionTypes from './actionTypes'

/**
 * @namespace ClaimActions
 */

/**
 * @memberof ClaimActions
 * @description Creates a Redux action that can be dispatched
 * to fetch all claims.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getClaims() {
  return { type: actionTypes.SAGA_CLAIM_ALL };
}

/**
 * @memberof ClaimActions
 * @deprecated not used anymore
 * @description Creates a Redux action that can be dispatched
 * to fetch pageed claims.
 *
 * This will trigger a Saga action.
 *
 * @param {object} props - pagination options. //TODO place a better comment.
 * @returns A Redux action that can be dispatched.
 */
export function loadClaimsPaged(props) {
  return { type: actionTypes.SAGA_CLAIM_SEARCH, props: props };
}

/**
 * @memberof ClaimActions
 * @description Creates a Redux action that can be dispatched
 * to fetch a simple claim.
 *
 * This will trigger a Saga action.
 *
 * @param {object} props
 * @param {string} props.claimId - ID of the claim to fetch.
 * @returns A Redux action that can be dispatched.
 */
export function getClaim(props) {
  return { type: actionTypes.SAGA_CLAIM_GET, props: props };
}

/**
 * @memberof ClaimActions
 * @description Creates a Redux action that can be dispatched
 * to edit a claim.
 *
 * This will trigger a Saga action
 *
 * @param {object} props - claim data.
 * @param {strign} props.id - ID of the claim to edit.
 *
 * @returns A Redux action that can be dispatched.
 */
export function editClaim(props) {
  return { type: actionTypes.SAGA_CLAIM_EDIT, props: props };
}


/**
 * @memberof ClaimActions
 * @description Creates a Redux action that can be dispatched
 * to fetch users and claims.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getUserclaims() {
  return {
    type: actionTypes.SAGA_USER_CLAIMS,
  }
}

/**
 * @memberof ClaimActions
 * @deprecated Use more granular actions.
 * @description Change Claim props.
 *
 * This will not trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function changeClaimProps(props) {
  return { type: actionTypes.CHANGE_CLAIM_PROPS, props: props };
}

/**
 * @memberof ClaimActions
 * @deprecated User more granular actions.
 * @description Clears Claim props.
 *
 * This will not trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function clearClaimProps() {
  return { type: actionTypes.CLEAR_CLAIMS_PROPS };
}

/**
 * @memberof ClaimActions
 * @deprecated Clears Claim current errors.
 *
 * This will not trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function clearClaimErrors() {
  return { type: actionTypes.CLEAR_CLAIMS_ERRORS };
}
