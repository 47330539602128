import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiComponentGroup from 'apiServices/ApiComponentGroup'

import { clearComponentGroupErrors } from '../actions/ComponentGroupActions'
import { showRecordAddedToast, showRecordDeletedToast, showRecordUpdatedToast } from '../actions/ToastActions';

const recordTypeName = "component group";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The Component Group cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_COMPONENTGROUP_PROPS, props });
}

/*
 * get ComponentGroups
 */

function* getComponentGroups() {
  try {
    yield loading();
    // get component types
    const allComponents = yield call(ApiComponentGroup.getAll);
    // update state for components
    yield put({
      type: actionTypes.CHANGE_COMPONENTGROUP_PROPS, props: {
        componentGroups: allComponents,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchComponentGroups(action) {
  try {
    yield loading();
    // get all ComponentGroups
    const componentGroups = yield call(ApiComponentGroup.searchComponentGroups, action.props);
    const pagination = {
      number: componentGroups.number,
      numberOfElements: componentGroups.numberOfElements,
      totalElements: componentGroups.totalElements,
      totalPages: componentGroups.totalPages,
      size: componentGroups.size
    };
    // update state for componentGroups
    yield put({
      type: actionTypes.CHANGE_COMPONENTGROUP_PROPS, props: {
        componentGroups: componentGroups.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getComponentGroup(action) {
  try {
    yield loading();
    // get  ComponentGroup
    const componentGroup = yield call(ApiComponentGroup.getComponentGroup, action.props.componentGroupId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: componentGroup.name,
        id: componentGroup.id,
        type: "componentGroup"
      }
    });
    // update state for componentGroups
    yield put({
      type: actionTypes.CHANGE_COMPONENTGROUP_PROPS, props: {
        componentGroup: componentGroup,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editComponentGroup(action) {

  yield put(clearComponentGroupErrors());

  try {
    yield loading();
    // update  ComponentGroup
    yield call(ApiComponentGroup.editComponentGroup, action.props);
    // update state for componentGroups
    yield put({
      type: actionTypes.CHANGE_COMPONENTGROUP_PROPS, props: {
        componentGroup: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordUpdatedToast(recordTypeName));
  } catch (error) {
    yield loading(false, error);
  }

}

function* addComponentGroup(action) {

  yield put(clearComponentGroupErrors());

  try {
    yield loading();
    // add  ComponentGroup
    yield call(ApiComponentGroup.addComponentGroup, action.props);
    // update state for componentGroups
    yield put({
      type: actionTypes.CHANGE_COMPONENTGROUP_PROPS, props: {
        componentGroup: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordAddedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteComponentGroup(action) {

  yield put(clearComponentGroupErrors());

  try {
    yield loading();
    // delete  ComponentGroup
    yield call(ApiComponentGroup.deleteComponentGroup, action.props);


    // update state for componentGroups
    yield put({
      type: actionTypes.CHANGE_COMPONENTGROUP_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteComponentGroupList(action) {

  yield put(clearComponentGroupErrors());

  try {
    yield loading();
    yield call(ApiComponentGroup.deleteComponentGroupList, action.props.list);

    // update state for componentGroups
    yield put({
      type: actionTypes.CHANGE_COMPONENTGROUP_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }



  } catch (error) {
    yield loading(false, error);
  }
}


function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_COMPONENTGROUPS_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiComponentGroup.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_COMPONENTGROUPS_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_COMPONENTGROUPS_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_COMPONENTGROUP_ALL, getComponentGroups),
    takeLatest(actionTypes.SAGA_COMPONENTGROUP_SEARCH, searchComponentGroups),
    takeLatest(actionTypes.SAGA_COMPONENTGROUP_GET, getComponentGroup),
    takeLatest(actionTypes.SAGA_COMPONENTGROUP_EDIT, editComponentGroup),
    takeLatest(actionTypes.SAGA_COMPONENTGROUP_ADD, addComponentGroup),
    takeLatest(actionTypes.SAGA_COMPONENTGROUP_DELETE, deleteComponentGroup),
    takeLatest(actionTypes.SAGA_COMPONENTGROUP_DELETE_CHECKED, deleteComponentGroupList),
    takeLatest(actionTypes.SAGA_COMPONENTGROUP_LOAD_ORGANIZATIONS, loadOrganizations)
  ]);
}

export default AuthWatcher;
