import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/location';

export default class ApiLocation {

  // GET 'api/location'
  static getAll() {
    return Utils.genericCall(`${endpoint}/all`);
  }

  static searchLocations(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getLocation(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editLocation(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addLocation(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteLocation(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteLocationList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getLocationsByComponentType(componentTypeId) {
    return Utils.genericCall(`${endpoint}/componentType/${componentTypeId}`);
  }

  static getLocationsByAssemblyType(assemblyTypeId) {
    return Utils.genericCall(`${endpoint}/assemblyType/${assemblyTypeId}`);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getAssemblyTypes() {
    return Utils.genericCall(`${endpoint}/assemblyTypes`);
  }

  static getComponentTypes() {
    return Utils.genericCall(`${endpoint}/componentTypes`);
  }
}
