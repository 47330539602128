import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {INITIAL_NAMES} from 'common/constants';

const storage = sessionStorage.getItem('names')
const initialState = {
  names: storage == null ? INITIAL_NAMES : JSON.parse(storage)
}

function reducerNames (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_NAMES_PROPS:
      const storeNames = sessionStorage.getItem('names')
      var names = storeNames == null ? INITIAL_NAMES : JSON.parse(storeNames);
      //todo: check if names is null, in case session expires and we are not redirecting user to login page
      names[action.props.type] = {
        name: action.props.name,
        id: action.props.id
      }
      sessionStorage.setItem('names', JSON.stringify(names));
      return Object.assign({}, state, {names: names})

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerNames);
