import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTE_LOGIN } from 'config/constants'
import { connect } from 'react-redux';
import { leavingCurrentRoute } from 'store/actions/RouterActions';
import { reloadTokenAction } from "store/actions/AuthActions";
import { useNavigate, useParams } from "react-router-dom";

const LayoutRoute = (layoutProps) => {

  const [render, setRender] = useState(true)
  const navigate = useNavigate();
  const params = useParams();

  const { props, component: Component, layout: Layout, publicRoute, saveRedirect, ...rest } = layoutProps

  //Triggers every time the user leaves this route.
  useEffect(() => {

    //this is to validate the expiration token using "TO" properties from links which is not refreshing all the page through the navigation
    let current = Date.now()
    let expiration = parseInt(localStorage.getItem('auth.expire'), 10);
    if (current && expiration && ((current < expiration) === false)) {
      setRender(false)
      layoutProps.reloadTokenAction();
    }
    return () => {
      layoutProps.leavingCurrentRoute();
    };
  }, [layoutProps]);

  const pushPath = (url) => {
    let first = url.substr(0, 1)
    if (first === "/") {
      url = url.slice(1)
    }
    navigate("/" + url)
  }

  useEffect(() => {
    if (!publicRoute.publicRoute) {
      saveRedirect(rest.path)
    }
  }, [rest.path, publicRoute.publicRoute])


  return render ?
    <>
      {publicRoute.publicRoute ?
        <Layout props={rest} breakpoint={publicRoute.breakpoint} history={{ push: (e) => pushPath(e) }} > <Component {...rest} history={{ push: (e) => pushPath(e), goBack: () => window.history.back() }} match={{ params: params, url: window.location.pathname }} /> </Layout>
        :
        <Navigate to={ROUTE_LOGIN} />
      }
    </>
    :
    <><Navigate to={ROUTE_LOGIN} /></>
};

const mapDispatchToProps = {
  leavingCurrentRoute,
  reloadTokenAction
}

export default connect(null, mapDispatchToProps)(LayoutRoute);
