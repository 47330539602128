import * as actionTypes from './actionTypes'

/**
 * @namespace LocationActions
 */

export function getLocations() {
  return { type: actionTypes.SAGA_LOCATION_ALL };
}

export function loadLocationsPaged(props) {
  return { type: actionTypes.SAGA_LOCATION_SEARCH, props: props };
}

export function getLocation(props) {
  return { type: actionTypes.SAGA_LOCATION_GET, props: props };
}

export function editLocation(props) {
  return { type: actionTypes.SAGA_LOCATION_EDIT, props: props };
}

export function addLocation(props) {
  return { type: actionTypes.SAGA_LOCATION_ADD, props: props };
}

export function deleteLocation(props) {
  return { type: actionTypes.SAGA_LOCATION_DELETE, props: props };
}

export function deleteLocationList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_LOCATION_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

export function getLocationByComponentType(props) {
  return { type: actionTypes.SAGA_LOCATION_COMPONENTTYPE_GET, props: props };
}

export function getLocationByAssemblyType(props) {
  return { type: actionTypes.SAGA_LOCATION_ASSEMBLYTYPE_GET, props: props };
}

/**
 * @memberof LocationActions
 * @description Change Location props (no saga action)
 */
export function changeLocationProps(props) {
  return { type: actionTypes.CHANGE_LOCATION_PROPS, props: props };
}

/**
 * @memberof LocationActions
 * @description Clears Location props.
 */
export function clearLocationProps() {
  return { type: actionTypes.CLEAR_LOCATION_PROPS };
}

/**
 * @memberof LocationActions
 * @description Clears Location errors.
 */
export function clearLocationErrors() {
  return { type: actionTypes.CLEAR_LOCATION_ERRORS };
}

/**
 * @memberof LocationActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations for locations.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_LOCATION_LOAD_ORGANIZATIONS };
}

/**
 * @memberof LocationActions
 * @description Creates a Redux action that can be dispatched
 * to load available assembly types for locations.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadAssemblyTypes() {
  return { type: actionTypes.SAGA_LOCATION_LOAD_ASSEMBLY_TYPES };
}

/**
 * @memberof LocationActions
 * @description Creates a Redux action that can be dispatched
 * to load available component types for locations.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadComponentTypes() {
  return { type: actionTypes.SAGA_LOCATION_LOAD_COMPONENT_TYPES };
}

