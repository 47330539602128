import { useEffect } from 'react'
import { connect } from 'react-redux';
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css'

 function ErrorHandler (props) {
  const {
    error
  } = props

  useEffect(()=>{
    showError(error.error)
  },[error])

  function showError(error){
    Alert.error(error, {
            position: 'bottom-right',
            effect: 'slide',
            timeout: 'none'
        });
  }

    return props.children;
  
}

function mapStateToProps(store) {
  return {
    error: store.error
  }
}

export default connect(mapStateToProps, {})(ErrorHandler);
