import { all, call, put, takeLatest, select } from 'redux-saga/effects'


import * as actionTypes from 'store/actions/actionTypes'
import { clearOrganizationErrors } from '../actions/OrganizationActions';
import ApiOrganization from 'apiServices/ApiOrganization'
import { ERROR_TYPE_DELETE_CONSTRAINTS, ERROR_TYPE_UNKOWN } from 'common/constants/errorTypeConstants';

import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast } from '../actions/ToastActions';
import { cloneDeep } from 'lodash';

const recordType = "organization";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (typeof errors === "object" && errors.type && errors.type === 'constraintViolation') {
      errors = {
        type: ERROR_TYPE_DELETE_CONSTRAINTS
      };
    } else {
      errors = {
        type: ERROR_TYPE_UNKOWN
      }
    }

    /*
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The organization cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
    */
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_ORGANIZATION_PROPS, props });
}

/*
 * get Organizations
 */
function* getOrganizations() {

  let organizationsLoaded = yield select(state => state.organization.organizations);
  try {
     if(!organizationsLoaded || organizationsLoaded.length===0){
      // get all Organizations
      const organizations = yield call(ApiOrganization.getAll);

      // update state for organizations
      yield put({
        type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
          organizations: organizations,
        }
      });
    }
    
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
  }
}

function* getOrganization(action) {
  try {
    yield loading();
    // get  Organization
    const organization = yield call(ApiOrganization.getOrganization, action.props.organizationId);
    // update state for users
    yield put({
      type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
        organization: organization,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getOrganizationTypes() {

  let organizationTypes = yield select(state => state.organization.organizationTypes);

  // get all Organizations only if they were not previously loadad
  if (organizationTypes.length === 0) {
    organizationTypes = yield call(ApiOrganization.getTypes);
  }

  // update state for organizations
  yield put({
    type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
      organizationTypes: organizationTypes,
    }
  });
}

function* searchOrganizations(action) {
  try {
    yield loading();
    // get all Organizations
    const organizations = yield call(ApiOrganization.searchOrganizations, action.props);

    const pagination = {
      number: organizations.number,
      numberOfElements: organizations.numberOfElements,
      totalElements: organizations.totalElements,
      totalPages: organizations.totalPages,
      size: organizations.size,
      order: action.props.order.id,
      filterArr: action.props.filterArr
    };



    // update state for organizations
    yield put({
      type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
        organizations: organizations.content,
        pagination: pagination,
        loading: false,
        //errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* editOrganization(action) {

  yield put(clearOrganizationErrors());

  try {
    yield loading();
    let loadedOrganizations = yield select(state => state.organization.organizations);
    yield put({ type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: { edited: true } });
    // update  Organization
    let organization = yield call(ApiOrganization.editOrganization, action.props);
    
    let index =  loadedOrganizations.findIndex(x => x.id === organization.id)
    let organitationsToLoad = cloneDeep(loadedOrganizations)
    if(index !== -1){
      organitationsToLoad[index] = organization
    }
    // update state for organizations
    yield put({
      type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
        organization: {},
        loading: false
      }
    });
    yield put({
      type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
        organizations: organitationsToLoad,
      }
    });
    yield put(showRecordUpdatedToast(recordType));
  } catch (error) {
    yield loading(false, error);
  }
}

function* addOrganization(action) {

  yield put(clearOrganizationErrors());

  try {
    yield loading();
    let loadedOrganizations = yield select(state => state.organization.organizations);
    // add  Organization
    let organization = yield call(ApiOrganization.addOrganization, action.props);
    
    // update state for organizations
    let organitationsToLoad = cloneDeep(loadedOrganizations)
    organitationsToLoad.push(organization)
    yield put({
      type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
        client: {},
      }
    });

    // update state for organizations
    yield put({
      type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
        organization: {},
        loading: false,
      }
    });

    yield put({
      type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
        organizations: organitationsToLoad,
      }
    });

    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        structureTypes: [],
        loading: false,
        errors: ''
      }
    })
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clients: [],
        loading: false,
        errors: ''
      }
    });
    yield put(showRecordAddedToast(recordType));
  } catch (error) {
    yield loading(false, error);
  }

}

/**
 *
 * @callback emptyCallback
 * @returns {undefined}
 */

/**
 * Deletes a list of organizations.
 *
 * @param {object} action - the action of the saga.
 * @param {object} action.props - props provided to the saga.
 * @param {string[]} action.props.deleteList - the list of the Organizations IDs to be deleted.
 * @param {emptyCallback} [action.props.callback=emptyCallback] - an optional callback to be called when
 * the organizations have been deleted successfuly.
 * @yields objects to be used by ReduxSaga
 */
function* deleteOrganizationChecked(action) {

  yield put(clearOrganizationErrors());

  try {
    yield loading();
    let loadedOrganizations = yield select(state => state.organization.organizations);
    let deletedOrganizations = yield call(ApiOrganization.deleteOrganizationList, action.props.deleteList);

    let organitationsToLoad = cloneDeep(loadedOrganizations).filter( x => !deletedOrganizations.some(id => id === x.id))
    

    yield put({
      type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
        organization: {},
        loading: false
      }
    });

    yield put({
      type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: {
        organizations: organitationsToLoad,
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield put(showRecordDeletedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }
}

/**
 * Gets the current user organization.
 *
 * @yields objects to be used by ReduxSaga
 */
function* getCurrentUserOrganization() {

  try {
    yield put({ type: actionTypes.ORGANIZATION_LOADING_CURRENT_USER });
    const model = yield call(ApiOrganization.getCurrentUserOrganization);
    yield put({ type: actionTypes.ORGANIZATION_LOADED_CURRENT_USER, currentUserOrganization: model })
  } catch (error) {
    yield put({ type: actionTypes.ORGANIZATION_ERROR_LOADING_CURRENT_USER, error: error.message });
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ORGANIZATION_ALL, getOrganizations),
    takeLatest(actionTypes.SAGA_ORGANIZATION_TYPES, getOrganizationTypes),
    takeLatest(actionTypes.SAGA_ORGANIZATION_SEARCH, searchOrganizations),
    takeLatest(actionTypes.SAGA_ORGANIZATION_GET, getOrganization),
    takeLatest(actionTypes.SAGA_ORGANIZATION_DELETE_CHECKED, deleteOrganizationChecked),
    takeLatest(actionTypes.SAGA_ORGANIZATION_EDIT, editOrganization),
    takeLatest(actionTypes.SAGA_ORGANIZATION_ADD, addOrganization),
    takeLatest(actionTypes.SAGA_ORGANIZATION_CURRENT_USER, getCurrentUserOrganization)
  ]);
}

export default AuthWatcher;
