import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/assemblyEvent';

export default class ApiAssembly {

  // GET 'api/AssemblyEvent'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static searchAssemblysEvent(text) {
    return Utils.genericCall(`${endpoint}/paged?search=${text}`);
  }

  static getAssemblyEvent(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editAssemblyEvent(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addAssemblyEvent(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteAssemblyEvent(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  // /assemblyEvent/event/{eventId}"
  static getAllAssembliesByEventID(eventId) {
    return Utils.genericCall(`${API_SERVER}/assemblyEvent/event/${eventId}`);
  }

  static getAllAssemblysEvent(assetEventId, structureId) {
    return Utils.genericCall(`${endpoint}/assetEvent/${assetEventId}/structure/${structureId}`);
  }

  static getAllAssembliesBySectionEventAndStructureType(sectionEventId, structureTypeId) {
    return Utils.genericCall(`${endpoint}/sectionEvent/${sectionEventId}/structure/${structureTypeId}`);
  }

  static getAllAssemblysEventRecursively(sectionEventId, structureTypeId) {
    return Utils.genericCall(`${endpoint}/recursively/sectionEvent/${sectionEventId}/structureType/${structureTypeId}`);
  }

  static getAllAssembliesBySectionEventAndStructureTypeReduced(sectionEventId, structureTypeId) {
    return Utils.genericCall(`${endpoint}/sectionEvent/${sectionEventId}/structure/${structureTypeId}/reduced`);
  }

  static addMultiAssemblyEvent(data) {
    return Utils.genericPost(`${endpoint}/multiple`, data);
  }

  static getStructureAssemblyTree(data) {
    return Utils.genericCall(`${API_SERVER}/assemblyEvent/sectionEvent/${data.sectionEventId}/structure/${data.structureId}/tree`);
  }

  static getStructureAssemblySuperReduced(sectionEventId, structureTypeId) {
    return Utils.genericCall(`${endpoint}/sectionEvent/${sectionEventId}/structure/${structureTypeId}/super-reduced`);
  }

  static editMultiAssemblyEvent(data) {
    return Utils.genericPut(`${endpoint}/multiple`, data);
  }

  static deleteMultiAssemblyEvent(data) {
    return Utils.genericPost(`${endpoint}/deleteMultiple`, data);
  }

  static addMultipleAssemblies (data){
    return Utils.genericPost(`${endpoint}/${data.assetEventId}/addAssemblies`, data.props);
  }

  static addAssembly (data){
    return Utils.genericPost(`${endpoint}/${data.assetEventId}/addAssembly`, data);
  }


  static updateAssembliesOrders (data){
    return Utils.genericPost(`${endpoint}/updateOrders`, data);
  }

  static addMultipleComponents(assemblyEventId, componentTypeId, quantity) {
    return Utils.genericPost(`${endpoint}/${assemblyEventId}/addComponents`, {
      componentTypeId,
      quantity
    });
  }

  static addSingleComponents(assemblyEventId, componentTypeId) {
    return Utils.genericPost(`${endpoint}/${assemblyEventId}/addComponent`, {
      componentTypeId
    });
  }

  static copyComponentsAndItemEvents (data){
    return Utils.genericPost(`${endpoint}/copyComponentsAndItemEvents`, data);
  }

  static updateDynamicField(assemblyEventId, fieldName, newValue) {
    const ret = Utils.genericPut(`${endpoint}/${assemblyEventId}/field`, {
      fieldName,
      value: newValue
    });

    return ret;
  }
}
