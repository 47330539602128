import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const pagedObjectsDefaultState = createState(false, {content: []});

const initialState = {
  clients: [],
  client: {},
  railroads: [],
  clientReports: [],
  clientReportsPagination: {},
  formValues: null,
  pagination: {},
  edited: false,
  clientInspectionsMapStats: [],
  clientInspectionsMapPriority: [],
  clientStats: {},
  loading: false,
  loadingMapStats: true,
  loadingMapPriority: true,
  errors: '',
  clientBridgeSections: [],
  clientBridgeSectionsPagination:{},

  contactsPaged: pagedObjectsDefaultState,
  documentsPaged: pagedObjectsDefaultState,
  documentTypes: createState(),
  jobTitles: createState(),
  organizations: createState(),
  clientsForSelection: createState(),
  contacts: createState()
}

function reducerClient(state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_CLIENT_PROPS:
      if (action.props.formValues != null) {
        const newValues = {
          id: action.props.formValues.id,
          name: action.props.formValues.name || "",
          description: action.props.formValues.description || "",
          abbreviation: action.props.formValues.abbreviation || "",
          type: action.props.formValues.type || "",
          organization: action.props.formValues.organization || null
        };
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_CLIENT_PROPS:
      return initialState;

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerClient),
  {
    contactsPaged: [
      actionTypes.CHANGE_CLIENT_START_LOADING_CONTACTS_PAGED,
      actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_CONTACTS_PAGED,
      actionTypes.CHANGE_CLIENT_ERROR_LOADING_CONTACTS_PAGED
    ],
    documentsPaged: [
      actionTypes.CHANGE_CLIENT_START_LOADING_DOCUMENTS,
      actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_DOCUMENTS,
      actionTypes.CHANGE_CLIENT_ERROR_LOADING_DOCUMENTS
    ],
    documentTypes: [
      actionTypes.CHANGE_CLIENT_START_LOADING_DOCUMENT_TYPES,
      actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_DOCUMENT_TYPES,
      actionTypes.CHANGE_CLIENT_ERROR_LOADING_DOCUMENT_TYPES
    ],
    jobTitles: [
      actionTypes.CHANGE_CLIENT_START_LOADING_JOB_TITLES,
      actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_JOB_TITLES,
      actionTypes.CHANGE_CLIENT_ERROR_LOADING_JOB_TITLES
    ],
    organizations: [
      actionTypes.CHANGE_CLIENT_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_CLIENT_ERROR_LOADING_ORGANIZATIONS
    ],
    clientsForSelection: [
      actionTypes.CHANGE_CLIENT_START_LOADING_CLIENTS,
      actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_CLIENTS,
      actionTypes.CHANGE_CLIENT_ERROR_LOADING_CLIENTS
    ],
    contacts: [
      actionTypes.CHANGE_CLIENT_START_LOADING_CONTACTS,
      actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_CONTACTS,
      actionTypes.CHANGE_CLIENT_ERROR_LOADING_CONTACTS
    ]
  }
);
