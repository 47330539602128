import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/contact';

export default class ApiContact {

  // GET 'api/contacts'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static loadContactsPaged(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static searchContacts(props) {
    return Utils.genericCall(`${endpoint}/paged?search=${props.search}&page=${props.page}`);
  }

  static getContact(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editContact(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addContact(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteContact(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteContactList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getContactByClientId(props) {
    return Utils.genericPost(`${endpoint}/client/${props.clientId}/paged`, props.data);
  }

  static getAllContactsByClientId(clientId) {
    return Utils.genericCall(`${endpoint}/client/${clientId}`);
  }

  // "/contact/filter" POST
  static searchFilteredContacts(props) {
    return Utils.genericPost(`${endpoint}/filter`, props);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getClients() {
    return Utils.genericCall(`${endpoint}/clients`);
  }

  static getJobTitles() {
    return Utils.genericCall(`${endpoint}/jobTitles`);
  }
}
