import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/rolPage';

const endpointRol = API_SERVER + '/rol';

export default class ApiRailRoad {

  // GET 'api/rolPages'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchRolPages(props){
    return Utils.genericCall(`${endpoint}/paged?search=${props.search}&page=${props.page}`);
  }

  static getByRol(id){
    return Utils.genericCall(`${endpointRol}/${id}/rolPage`);
  }

  static getByRols(props){
    return Utils.genericPost(`${endpoint}/pagedByRol?page=${props.page}`, props.ids);
  }

  static searchByRol(props){
     return Utils.genericCall(`${endpointRol}/${props.id}/rolPage/paged?search=${props.search}&page=${props.page}`);
  }

  static getRolPage(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editRolPage(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addRolPage(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteRolPage(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }
}
