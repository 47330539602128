import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiWorkPerformed from 'tests/mocks/ApiWorkPerformed.mock'
import ApiWorkPerformed from 'apiServices/ApiWorkPerformed'
import { cloneDeep } from 'lodash';


/*
 * get WorkPerformeds
 */
function* getWorkPerformeds(action){
  // get all events
  const allWorkPerformeds = yield call(ApiWorkPerformed.getWorkPerformeds, action.props.assetId, action.props.eventId);
  // update state for events
  yield put({ type: actionTypes.CHANGE_WORKPERFORMED_PROPS, props:{
    workPerformeds: allWorkPerformeds,
  }});
}

function* addWorkPerformed(action) {
    let loadedStructureType = yield select(store=>store.structureType)
    let newStructureType = cloneDeep(loadedStructureType)
    // add Event
    const workPerformed = yield call(ApiWorkPerformed.addWorkPerformed, action.props.props);
    //call get to fill store with correct information
    const finalWorkPerformed = yield call(ApiWorkPerformed.getWorkPerformedById, workPerformed.id)
    finalWorkPerformed.hasPhotos = action.props.props.hasPhotos;
    newStructureType[action.props.props.structureId].workPerformeds.push(finalWorkPerformed)

    //save new Event
    yield put({
      type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
        loading: false,
        errors: '',
        [action.props.props.structureId]: newStructureType[action.props.props.structureId]
      }
    });

  if (action.props.callback) {
    yield call(action.props.callback, finalWorkPerformed);
  }
}

function* editWorkPerformed(action) {
  
  let loadedStructureType = yield select(store=>store.structureType)
  const workPerformed = yield call(ApiWorkPerformed.editWorkPerformed, action.props.props);
  let newStructureType = cloneDeep(loadedStructureType)
  let index = newStructureType[action.props.props.structureId].workPerformeds.findIndex(e => e.id === workPerformed.id)
  if(index !== -1){
    newStructureType[action.props.props.structureId].workPerformeds[index] = workPerformed
  }

  //replace old with new event
  yield put({
    type: actionTypes.CHANGE_STRUCTURETYPE_PROPS, props: {
      loading: false,
      errors: '',
      [action.props.props.structureId]: newStructureType[action.props.props.structureId]
    }
  });

  if (action.props.callback) {
    yield call(action.props.callback);
  }
}

function* deleteWorkPerformed(action) {
  // delete Event
  yield call(ApiWorkPerformed.deleteWorkPerformed, action.props.id);

  yield put({ type: actionTypes.SAGA_ASSEMBLY_EVENT, props: {assetEventId: action.props.sectionSelected} });

  yield put({ type: actionTypes.CHANGE_WORKPERFORMED_PROPS, props:{
    workPerformed: {},
  }});
}

function* getWorkPerformedById(action) {
  const workPerformed = yield call(ApiWorkPerformed.getWorkPerformedById, action.props.value.workPerformedId)

  yield put({ type: actionTypes.CHANGE_WORKPERFORMED_PROPS, props:{
    workPerformed: workPerformed,
  }});

  yield call(action.props.callback);
}

function* addPhotosToWorkPerformed(action) {
  try {
    const response = yield call(ApiWorkPerformed.addPhotosToWorkPerformed, action.props.data);

    yield put({
      type: actionTypes.CHANGE_WORKPERFORMED_PROPS_ADD_NEW_PHOTO,
      props: {
        newPhoto: response
      }
    });

    yield put({
      type: actionTypes.CHANGE_WORKPERFORMED_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    console.log('====================================');
    console.log(error);
    console.log('====================================');
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_WORKPERFORMED_ALL, getWorkPerformeds),
    //takeLatest(actionTypes.SAGA_WORKPERFORMED_GET, getWorkPerformed),
    takeLatest(actionTypes.SAGA_WORKPERFORMED_ADD, addWorkPerformed),
    takeLatest(actionTypes.SAGA_WORKPERFORMED_DELETE, deleteWorkPerformed),
    takeLatest(actionTypes.SAGA_WORKPERFORMED_GET_BY_ID, getWorkPerformedById),
    takeLatest(actionTypes.SAGA_WORKPERFORMED_EDIT, editWorkPerformed),
    takeLatest(actionTypes.SAGA_WORKPERFORMED_PHOTO, addPhotosToWorkPerformed),
    takeLatest(actionTypes.SAGA_WORKPERFORMED_PHOTO_REMOVE, addPhotosToWorkPerformed),
  ]);
}

export default AuthWatcher;