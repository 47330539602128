import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';


const pagedObjectsDefaultState = createState(false, {content: []});

const initialState = {
  railroads: [],
  railroadsList: [],
  railroad: {},
  subdivisions: [],
  formValues: null,
  pagination: {},
  edited: false,
  contactsPaged: pagedObjectsDefaultState,
  documentsPaged: pagedObjectsDefaultState,
  documentTypes: createState(),
  jobTitles: createState(),
  organizations: createState(),
  clients: createState(),
  contacts: createState()
}

function reducerRailroad (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_RAILROAD_PROPS:
      if (action.props.formValues!= null){
        const newValues = {
          id: action.props.formValues.id,
          name: action.props.formValues.name || "",
          dhsComment: action.props.formValues.dhsComment || "",
          description: action.props.formValues.description || "",
          bridgeEngineer: action.props.formValues.bridgeEngineer || null,
          chiefEngineer: action.props.formValues.chiefEngineer || null,
          className: action.props.formValues.className || "",
        };
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CHANGE_RAILROAD:
      return {
        ...state,
        railroads: [...state.railroads.filter(x => x.id !== action.props.railroad.id), action.props.railroad]
      };

    case actionTypes.CHANGE_RAILROAD_REMOVED:
      return {
        ...state,
        //remove deleted railroads
        railroads: state.railroads.filter(x => action.props.removedRailroadsIds.includes(x.id) === false)
      };

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerRailroad),
  {
    contactsPaged: [
      actionTypes.CHANGE_RAILROAD_START_LOADING_CONTACTS_PAGED,
      actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_CONTACTS_PAGED,
      actionTypes.CHANGE_RAILROAD_ERROR_LOADING_CONTACTS_PAGED
    ],
    documentsPaged: [
      actionTypes.CHANGE_RAILROAD_START_LOADING_DOCUMENTS,
      actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_DOCUMENTS,
      actionTypes.CHANGE_RAILROAD_ERROR_LOADING_DOCUMENTS
    ],
    documentTypes: [
      actionTypes.CHANGE_RAILROAD_START_LOADING_DOCUMENT_TYPES,
      actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_DOCUMENT_TYPES,
      actionTypes.CHANGE_RAILROAD_ERROR_LOADING_DOCUMENT_TYPES
    ],
    jobTitles: [
      actionTypes.CHANGE_RAILROAD_START_LOADING_JOB_TITLES,
      actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_JOB_TITLES,
      actionTypes.CHANGE_RAILROAD_ERROR_LOADING_JOB_TITLES
    ],
    organizations: [
      actionTypes.CHANGE_RAILROAD_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_RAILROAD_ERROR_LOADING_ORGANIZATIONS
    ],
    clients: [
      actionTypes.CHANGE_RAILROAD_START_LOADING_CLIENTS,
      actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_CLIENTS,
      actionTypes.CHANGE_RAILROAD_ERROR_LOADING_CLIENTS
    ],
    contacts: [
      actionTypes.CHANGE_RAILROAD_START_LOADING_CONTACTS,
      actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_CONTACTS,
      actionTypes.CHANGE_RAILROAD_ERROR_LOADING_CONTACTS
    ]
  }
);
