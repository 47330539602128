import { fork } from 'redux-saga/effects';

// import all watchers
import AuthSaga from 'store/sagas/authSaga';
import UserSaga from 'store/sagas/userSaga';
import BridgeSaga from 'store/sagas/bridgeSaga';
import ClientSaga from 'store/sagas/clientSaga';
import RailroadSaga from 'store/sagas/railroadSaga';
import SubdivisionSaga from 'store/sagas/subdivisionSaga';
import EventSaga from 'store/sagas/eventSaga';
import AssemblySaga from 'store/sagas/assemblySaga';
import ComponentSaga from 'store/sagas/componentSaga';
import NamesSaga from 'store/sagas/namesSaga';
import MessageSaga from 'store/sagas/messageSaga';
import MessageFieldSaga from 'store/sagas/messageFieldSaga';
import RoleSaga from 'store/sagas/roleSaga';
import RolPageSaga from 'store/sagas/rolPageSaga';
import AssemblyTypeSaga from 'store/sagas/assemblyTypeSaga';
import ComponentTypeSaga from 'store/sagas/componentTypeSaga';
import ComponentGroupSaga from 'store/sagas/componentGroupSaga';
import DocumentTypeSaga from 'store/sagas/documentTypeSaga';
import EventTypeSaga from 'store/sagas/eventTypeSaga';
import ClaimSaga from 'store/sagas/claimSaga';
import StructureTypeSaga from 'store/sagas/structureTypeSaga';
import ImpactTypeSaga from 'store/sagas/impactTypeSaga';
import AssetTypeSaga from 'store/sagas/assetTypeSaga';
import AssetEventSaga from 'store/sagas/assetEventSaga';
import SectionEventSaga from 'store/sagas/sectionEventSaga';
import CrossingFeatureSaga from 'store/sagas/crossingFeatureSaga';
import AssetSaga from 'store/sagas/assetSaga';
import TripSaga from 'store/sagas/tripSaga';
import ContactSaga from 'store/sagas/contactSaga';
import OrganizationSaga from 'store/sagas/organizationSaga';
import MilePostSaga from 'store/sagas/milePostSaga';
import EventRecommendationSaga from 'store/sagas/eventRecommendationSaga';
import WorkPerformedSaga from 'store/sagas/workPerformedSaga';
import AccessTypeSaga from 'store/sagas/accessTypeSaga';
import EventConditionSaga from 'store/sagas/eventConditionSaga';
import EventNoteSaga from 'store/sagas/eventNoteSaga';
import ConditionSaga from 'store/sagas/conditionSaga';
import LocationSaga from 'store/sagas/locationSaga';
import AssemblyEventSaga from 'store/sagas/assemblyEventSaga';
import ComponentEventSaga from 'store/sagas/componentEventSaga';
import ImageSaga from 'store/sagas/imageSaga';
import MilestoneSaga from 'store/sagas/milestoneSaga';
import DirectionalLoadingSaga from 'store/sagas/directionalLoadingSaga';
import WaterFlowSaga from 'store/sagas/waterFlowSaga';
import AlignmentSaga from 'store/sagas/alignmentSaga';
import InspectionTypeSaga from 'store/sagas/inspectionTypeSaga';
import documentSaga from 'store/sagas/documentSaga';
import jobTitleSaga from 'store/sagas/jobTitleSaga';
import MenuActiveSaga from 'store/sagas/menuActiveSaga';
import WebSocketSaga from 'store/sagas/webSocketSaga';

function* rootSagas() {
  yield fork(AuthSaga);
  yield fork(UserSaga);
  yield fork(BridgeSaga);
  yield fork(ClientSaga);
  yield fork(RailroadSaga);
  yield fork(SubdivisionSaga);
  yield fork(EventSaga);
  yield fork(AssemblySaga);
  yield fork(ComponentSaga);
  yield fork(NamesSaga);
  yield fork(MessageSaga);
  yield fork(MessageFieldSaga);
  yield fork(RoleSaga);
  yield fork(RolPageSaga);
  yield fork(AssemblyTypeSaga);
  yield fork(ComponentTypeSaga);
  yield fork(EventTypeSaga);
  yield fork(ClaimSaga);
  yield fork(StructureTypeSaga);
  yield fork(ImpactTypeSaga);
  yield fork(AssetTypeSaga);
  yield fork(CrossingFeatureSaga);
  yield fork(AssetEventSaga);
  yield fork(AssetSaga);
  yield fork(SectionEventSaga);
  yield fork(TripSaga);
  yield fork(ContactSaga);
  yield fork(OrganizationSaga);
  yield fork(MilePostSaga);
  yield fork(EventRecommendationSaga);
  yield fork(WorkPerformedSaga);
  yield fork(AccessTypeSaga);
  yield fork(EventConditionSaga);
  yield fork(EventNoteSaga);
  yield fork(ConditionSaga);
  yield fork(LocationSaga);
  yield fork(AssemblyEventSaga);
  yield fork(ComponentEventSaga);
  yield fork(ComponentGroupSaga);
  yield fork(ImageSaga);
  yield fork(MilestoneSaga);
  yield fork(DirectionalLoadingSaga);
  yield fork(WaterFlowSaga);
  yield fork(AlignmentSaga);
  yield fork(InspectionTypeSaga);
  yield fork(documentSaga);
  yield fork(DocumentTypeSaga);
  yield fork(jobTitleSaga);
  yield fork(MenuActiveSaga);
  yield fork(WebSocketSaga);
}

export default rootSagas;
