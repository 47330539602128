import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/role';
const claimEndpoint = API_SERVER + '/claim';

export default class ApiRole {

  // GET 'api/roles'
  static getAll(){
    return Utils.genericCall(`${endpoint}`);
  }

  static searchRoles(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getRole(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editRole(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addRole(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteRole(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteRoleList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getUserClaims(){
    return Utils.genericCall(`${claimEndpoint}/auth`);
  }

  static getAllParentOrganization(){
    return Utils.genericCall(`${endpoint}/parentOrganization`);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getClaims() {
    return Utils.genericCall(`${endpoint}/claims`);
  }
}
