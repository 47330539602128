import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
import ApiMessageField from 'apiServices/ApiMessageField'
import { showToastAsError, showRecordDeletedToast } from 'store/actions/ToastActions';

const recordType = "message field";

/*
 * get MessageFields
 */
function* getMessageFields(){
  // get first page MessageFields
  yield searchMessageFields({props: {search:'', page: 0}});
}

function* searchMessageFields(action){
  // get all MessageFields
  const messageFields = yield call(ApiMessageField.searchMessageFields, action.props);
  // update state for messageFields
  yield put({ type: actionTypes.CHANGE_MESSAGEFIELD_PROPS, props:{
    messageFields: messageFields.content,
  }});
}

function* getMessageField(action){
  // get  MessageField
  const messageField = yield call(ApiMessageField.getMessageField, action.props.messageFieldId);
  yield putResolve({type: actionTypes.CHANGE_NAMES_PROPS, props:{
    name: messageField.code,
    id: messageField.id,
    type: "messageField"
  }});
  // update state for messageFields
  yield put({ type: actionTypes.CHANGE_MESSAGEFIELD_PROPS, props:{
    messageField: messageField,
  }});
}

function* editMessageField(action){
  // update  MessageField
  const messageField = yield call(ApiMessageField.editMessageField, action.props);
  yield put({
    type: actionTypes.CHANGE_MESSAGEFIELD,
    props: {
      messageField
    }
  });
  // update state for messageFields
  yield put({ type: actionTypes.CHANGE_MESSAGEFIELD_PROPS, props:{
    messageField: {},
  }});
  yield getMessageField({props: {messageFieldId: action.props.id}});
}

function* addMessageField(action){
  // add  MessageField
  const messageField = yield call(ApiMessageField.addMessageField, action.props);
  yield put({
    type: actionTypes.CHANGE_MESSAGEFIELD,
    props: {
      messageField
    }
  });
  // update state for messageFields
  yield put({ type: actionTypes.CHANGE_MESSAGEFIELD_PROPS, props:{
    messageField: {},
  }});
  yield getByMessage({props: {messageId: action.props.message.id}});
}

function* deleteMessageField(action){
  // delete  MessageField
  yield call(ApiMessageField.deleteMessageField, action.props.id);
  yield getByMessage({props: {messageId: action.props.message.id}});
}

function* deleteMessageFieldList(action) {
  try {
    // delete  Message
    const removedMessageFieldsIds = yield call(ApiMessageField.deleteMessageFieldList, action.props.list);
    yield put({ type: actionTypes.CHANGE_MESSAGEFIELD_REMOVED, props: {
      removedMessageFieldsIds
    } });

    // update state for messages
    yield put({
      type: actionTypes.CHANGE_MESSAGEFIELD_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield put(showRecordDeletedToast(recordType));

  } catch (error) {
    yield put(showToastAsError(
      "Error deleting message field",
      "There was an error trying to delete the message field. Pleas try again."
    ));
  }

}

function* getByMessages(action) {
  // get all messageFields by messages
  const messageFields = yield call(ApiMessageField.getByMessages, action.props);
  // update state for messageFields
  yield put({ type: actionTypes.CHANGE_MESSAGEFIELD_PROPS, props:{
    messageFields: messageFields.content,
  }});
}

function* getByMessage(action) {
  const messageFields = yield call(ApiMessageField.getByMessage, action.props.messageId);
  yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: {level:1, ids: action.props, type: 'message'}});
  // get message messageFields
  yield put({ type: actionTypes.CHANGE_MESSAGEFIELD_PROPS, props:{
    messageFields: messageFields,
  }});
}

function* searchByMessage(action){
  // get all messageFields by message
  const messageFields = yield call(ApiMessageField.searchByMessage, action.props);
  // update state for messageFields
  yield put({ type: actionTypes.CHANGE_MESSAGEFIELD_PROPS, props:{
    messageFields: messageFields.content,
  }});
}
/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_MESSAGEFIELD_ALL, getMessageFields),
    takeLatest(actionTypes.SAGA_MESSAGEFIELD_SEARCH, searchMessageFields),
    takeLatest(actionTypes.SAGA_MESSAGEFIELD_GET, getMessageField),
    takeLatest(actionTypes.SAGA_MESSAGEFIELD_EDIT, editMessageField),
    takeLatest(actionTypes.SAGA_MESSAGEFIELD_ADD, addMessageField),
    takeLatest(actionTypes.SAGA_MESSAGEFIELD_DELETE, deleteMessageField),
    takeLatest(actionTypes.SAGA_MESSAGE_MESSAGEFIELDS, getByMessage),
    takeLatest(actionTypes.SAGA_MESSAGE_MESSAGEFIELDS_SEARCH, searchByMessage),
    takeLatest(actionTypes.SAGA_MESSAGEFIELD_BY_MESSAGES, getByMessages),
    takeLatest(actionTypes.SAGA_MESSAGEFIELD_DELETE_CHECKED, deleteMessageFieldList)
  ]);
}

export default AuthWatcher;
