import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/bridge';

const endpointSubdivision = API_SERVER + '/subdivision';

export default class ApiBridge {

  // GET 'api/bridges'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static searchBridges(props) {
    return Utils.genericCall(`${endpoint}/paged?search=${props.search}&page=${props.page}`);
  }

  static getBySubdivision(id) {
    return Utils.genericCall(`${endpointSubdivision}/${id}/bridge`);
  }

  static searchBySubdivision(props) {
    return Utils.genericCall(`${endpointSubdivision}/${props.id}/bridge/paged?search=${props.search}&page=${props.page}`);
  }

  static searchByClient(props) {
    return Utils.genericPost(`${endpoint}/pagedByClient?search=${props.search}&page=${props.page}&year=${props.year}&rating=${props.spanRating}&traffic=${props.spanTraffic}`, props.ids);
  }

  static searchByRailroads(props) {
    return Utils.genericPost(`${endpoint}/pagedByRailroads?search=${props.search}&page=${props.page}&year=${props.year}&rating=${props.spanRating}&traffic=${props.spanTraffic}`, props.ids);
  }

  static searchBySubdivisions(props) {
    return Utils.genericPost(`${endpoint}/pagedBySubdivisions?search=${props.search}&page=${props.page}&year=${props.year}&rating=${props.spanRating}&traffic=${props.spanTraffic}`, props.ids);
  }

  static getBridge(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editBridge(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addBridge(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteBridge(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static scheduleInspection(props) {
    return Utils.genericPost(`${endpoint}/${props.id}/event`, props.data);
  }

  // GET /sections/bridge/{bridgeId}
  static getSectionsByBridgeId(bridgeId) {
    return Utils.genericCall(`${API_SERVER}/sections/bridge/${bridgeId}`);
  }
}
