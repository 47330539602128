import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/bridgeSectionEvent';

const endpointAssetEvent = API_SERVER + '/assetEvent';

export default class ApiSectionEvent {

  // GET 'api/assetEvents'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchSectionEvents(props){
    return Utils.genericCall(`${endpoint}/paged?search=${props.search}&page=${props.page}`);
  }

  static getByAssetEvent(id){
    return Utils.genericCall(`${endpointAssetEvent}/${id}/bridgeSectionEvent`);
  }

  static getSectionEvent(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editSectionEvent(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addSectionEvent(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteSectionEvent(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }
}
