import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiContact from 'apiServices/ApiContact'

import { clearContactErrors } from '../actions/ContactActions';
import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast } from '../actions/ToastActions';

const recordType = "contact";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The contact cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_CONTACT_PROPS, props });
}

/*
 * get Contacts
 */
function* getContacts() {
  try {
    yield loading();
    // get all Contacts
    const contact = yield call(ApiContact.getAll);

    // update state for contact
    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        contacts: contact,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }
}

function* searchContacts() {
  try {
    yield loading();
    // get all Contacts
    const contacts = yield call(ApiContact.getAll);
    // update state for contact
    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        contacts: contacts,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getContact(action) {
  try {
    yield loading();
    // get  Contact
    const contact = yield call(ApiContact.getContact, action.props.contactId);
    // update state for contacts
    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        contact: contact,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editContact(action) {

  yield put(clearContactErrors());

  try {
    yield loading();
    // update  Contact
    yield call(ApiContact.editContact, action.props.props);
    // update state for contacts
    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        contact: {},
        loading: false,
        errors: ''
      }
    });
    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield put(showRecordUpdatedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* addContact(action) {

  yield put(clearContactErrors());

  try {
    yield loading();
    // add  Contact
    const contact = yield call(ApiContact.addContact, action.props.props);
    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        contact: contact,
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield put(showRecordAddedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteContact(action) {

  yield put(clearContactErrors());

  try {
    yield loading();
    // delete  Contact
    yield call(ApiContact.deleteContact, action.props);
    yield searchContacts();
    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
    yield put(showRecordDeletedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteContactList(action) {

  yield put(clearContactErrors());

  try {
    yield loading();
    // delete  Contact
    yield call(ApiContact.deleteContactList, action.props.list);

    if (action.props.callback && action.props.callback) {
      yield call(action.props.callback);
    }

    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
    yield put(showRecordDeletedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* getContactByClientId(action) {
  try {
    yield loading();
    // delete  Contact
    const contact = yield call(ApiContact.getContactByClientId, action.props);
    const pagination = {
      number: contact.number,
      numberOfElements: contact.numberOfElements,
      totalElements: contact.totalElements,
      totalPages: contact.totalPages,
      size: contact.size
    };
    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        contacts: contact.content,
        pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getAllContactsOfClient(action) {
  try {
    yield loading();
    const contacts = yield call(ApiContact.getAllContactsByClientId, action.props.clientId);
    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        clientContacts: contacts,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchFilteredContacts(action) {
  try {
    yield loading();
    // delete  Contact
    const contacts = yield call(ApiContact.searchFilteredContacts, action.props);

    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        contacts: contacts,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* loadContactsPaged(action) {
  try {
    yield loading();
    // get all contacts
    const contacts = yield call(ApiContact.loadContactsPaged, action.props);
    const pagination = {
      number: contacts.number,
      numberOfElements: contacts.numberOfElements,
      totalElements: contacts.totalElements,
      totalPages: contacts.totalPages,
      size: contacts.size
    };
    // update state for contacts
    yield put({
      type: actionTypes.CHANGE_CONTACT_PROPS, props: {
        contacts: contacts.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}




function* getOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_CONTACT_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiContact.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_CONTACT_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_CONTACT_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

function* getClients() {
  try {
    yield put({
      type: actionTypes.CHANGE_CONTACT_START_LOADING_CLIENTS
    });

    const clients = yield call(ApiContact.getClients);
    yield put({
      type: actionTypes.CHANGE_CONTACT_SUCCESS_LOADING_CLIENTS,
      props: {
        clients
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_CONTACT_ERROR_LOADING_CLIENTS,
      error: e.message
    })
  }
}


function* getJobTitles() {
  try {
    yield put({
      type: actionTypes.CHANGE_CONTACT_START_LOADING_JOB_TITLES
    });

    const jobTitles = yield call(ApiContact.getJobTitles);
    yield put({
      type: actionTypes.CHANGE_CONTACT_SUCCESS_LOADING_JOB_TITLES,
      props: {
        jobTitles
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_CONTACT_ERROR_LOADING_JOB_TITLES,
      error: e.message
    })
  }
}


/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_CONTACT_ALL, getContacts),
    takeLatest(actionTypes.SAGA_CONTACT_SEARCH, searchContacts),
    takeLatest(actionTypes.SAGA_CONTACT_GET, getContact),
    takeLatest(actionTypes.SAGA_CONTACT_EDIT, editContact),
    takeLatest(actionTypes.SAGA_CONTACT_ADD, addContact),
    takeLatest(actionTypes.SAGA_CONTACT_DELETE, deleteContact),
    takeLatest(actionTypes.SAGA_CONTACT_DELETE_CHECKED, deleteContactList),
    takeLatest(actionTypes.SAGA_CONTACT_BY_CLIENT_ID, getContactByClientId),
    takeLatest(actionTypes.SAGA_CONTACT_SEARCH_FILTER, searchFilteredContacts),
    takeLatest(actionTypes.SAGA_CONTACT_SEARCH_PAGE, loadContactsPaged),
    takeLatest(actionTypes.SAGA_CONTACT_ALL_OF_CLIENT, getAllContactsOfClient),
    takeLatest(actionTypes.SAGA_CONTACT_GET_ORGANIZATIONS, getOrganizations),
    takeLatest(actionTypes.SAGA_CONTACT_GET_CLIENTS, getClients),
    takeLatest(actionTypes.SAGA_CONTACT_GET_JOB_TITLES, getJobTitles)
  ]);
}

export default AuthWatcher;
