import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiImpactType from 'apiServices/ApiImpactType'

import { clearImpactTypeErrors } from '../actions/ImpactTypeActions';
import { showRecordAddedToast, showRecordDeletedToast, showRecordUpdatedToast } from '../actions/ToastActions';

const recordTypeName = "impact type";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The Impact Types cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_IMPACTTYPE_PROPS, props });
}

/*
 * get ImpactTypes
 */
function* getImpactTypes() {
  try {
    yield loading();
    // get item types
    const allItems = yield call(ApiImpactType.getAll);
    // update state for items
    yield put({
      type: actionTypes.CHANGE_IMPACTTYPE_PROPS, props: {
        impactTypes: allItems,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchImpactTypes(action) {
  try {
    yield loading();
    // get all ImpactTypes
    const impactTypes = yield call(ApiImpactType.searchImpactTypes, action.props);
    const pagination = {
      number: impactTypes.number,
      numberOfElements: impactTypes.numberOfElements,
      totalElements: impactTypes.totalElements,
      totalPages: impactTypes.totalPages,
      size: impactTypes.size
    };
    // update state for impactTypes
    yield put({
      type: actionTypes.CHANGE_IMPACTTYPE_PROPS, props: {
        impactTypes: impactTypes.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getImpactType(action) {
  try {
    yield loading();
    // get  ImpactType
    const impactType = yield call(ApiImpactType.getImpactType, action.props.impactTypeId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: impactType.name,
        id: impactType.id,
        type: "impactType"
      }
    });
    // update state for impactTypes
    yield put({
      type: actionTypes.CHANGE_IMPACTTYPE_PROPS, props: {
        impactType: impactType,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editImpactType(action) {

  yield put(clearImpactTypeErrors());

  try {
    yield loading();
    // update  ImpactType
    yield call(ApiImpactType.editImpactType, action.props);
    // update state for impactTypes
    yield put({
      type: actionTypes.CHANGE_IMPACTTYPE_PROPS, props: {
        impactType: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordUpdatedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }

}

function* addImpactType(action) {

  yield put(clearImpactTypeErrors());

  try {
    yield loading();
    // add  ImpactType
    yield call(ApiImpactType.addImpactType, action.props);
    // update state for impactTypes
    yield put({
      type: actionTypes.CHANGE_IMPACTTYPE_PROPS, props: {
        impactType: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordAddedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteImpactType(action) {

  yield put(clearImpactTypeErrors());

  try {
    yield loading();
    // delete  ImpactType
    yield call(ApiImpactType.deleteImpactType, action.props);

    // update state for impactTypes
    yield put({
      type: actionTypes.CHANGE_IMPACTTYPE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteImpactTypeList(action) {

  yield put(clearImpactTypeErrors());

  try {
    yield loading();
    yield call(ApiImpactType.deleteImpactTypeList, action.props.list);

    // update state for impactTypes
    yield put({
      type: actionTypes.CHANGE_IMPACTTYPE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_IMPACTTYPE_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiImpactType.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_IMPACTTYPE_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_IMPACTTYPE_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_IMPACTTYPE_ALL, getImpactTypes),
    takeLatest(actionTypes.SAGA_IMPACTTYPE_SEARCH, searchImpactTypes),
    takeLatest(actionTypes.SAGA_IMPACTTYPE_GET, getImpactType),
    takeLatest(actionTypes.SAGA_IMPACTTYPE_EDIT, editImpactType),
    takeLatest(actionTypes.SAGA_IMPACTTYPE_ADD, addImpactType),
    takeLatest(actionTypes.SAGA_IMPACTTYPE_DELETE, deleteImpactType),
    takeLatest(actionTypes.SAGA_IMPACTTYPE_DELETE_CHECKED, deleteImpactTypeList),
    takeLatest(actionTypes.SAGA_IMPACTTYPE_LOAD_ORGANIZATIONS, loadOrganizations)
  ]);
}

export default AuthWatcher;
