import { all, call, put, takeLatest, select, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// import CookieManager from 'common/cookieManager'
// APIs to talk to...
import ApiClient from 'apiServices/ApiClient'

import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast } from '../actions/ToastActions';
import { clearClientProps } from '../actions/ClientActions';

const recordType = "client";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response ? errors.response.data.message : 'uncaught error';
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The client cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_CLIENT_PROPS, props });
}

/*
 * get Clients
 */
function* getClients() {
  try {
    yield loading();
    // get first page Clients
    const allClients = yield call(ApiClient.getAll);
    // update state for clients
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clients: allClients,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getAllClients() {

  let allClients = yield select(state => state.client.clients);

  try {
    yield loading();

    if (!allClients || allClients.length === 0) {
      // get first page Clients
      allClients = yield call(ApiClient.getAllClients);
    }

    // update state for clients
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clients: allClients,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* searchClients(action) {
  try {
    yield loading();
    // get all Clients
    const clients = yield call(ApiClient.searchClients, action.props);
    // update state for clients
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clients: clients.content,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* searchClientsAdmin(action) {
  try {
    yield loading();
    // get all Messages
    const clients = yield call(ApiClient.searchClientsAdmin, action.props);
    const pagination = {
      number: clients.number,
      numberOfElements: clients.numberOfElements,
      totalElements: clients.totalElements,
      totalPages: clients.totalPages,
      size: clients.size
    };
    // update state for messages
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clients: clients.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getClient(action) {
  let currentClient = yield select(state => state.client.client);
  try {
    if(action.props.clientId  && currentClient.id !== action.props.clientId ){
    yield loading();
    // get  Client
    const client = yield call(ApiClient.getClient, action.props.clientId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: client.name,
        id: client.id,
        type: "client"
      }
    });
    // update state for clients
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        client: client,
        loading: false,
        errors: ''
      }
    });
  }
  } catch (error) {
    yield loading(false, error);
  }

}

function* editClient(action) {
  try {
    yield loading();
    yield put({ type: actionTypes.CHANGE_CLIENT_PROPS, props: { edited: true } });
    // update  Client
    yield call(ApiClient.editClient, action.props);
    // update state for clients
    yield put(clearClientProps());
    //reload the client data
    yield getClient({ props: { clientId: action.props.id } });

    yield put(showRecordUpdatedToast(recordType));

    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        client: {},
        loading: false,
        errors: ''
      }
    });

    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clients: [],
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }


}

function* addClient(action) {
  try {
    yield loading();
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clients: [],
        loading: false,
        errors: ''
      }
    });

    // add  Client
    yield call(ApiClient.addClient, action.props);
    // update state for clients
    yield put(clearClientProps());

    yield put(showRecordAddedToast(recordType));
  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteClient(action) {
  try {
    yield loading();
    // delete  Client
    yield call(ApiClient.deleteClient, action.props);
    yield searchClients({ props: { search: '', page: 0 } });
    // update state for clients
    yield put(clearClientProps());

    yield put(showRecordDeletedToast(recordType));

    if (action.props.callback) {
      action.props.callback();
    }
 yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clients: [],
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteClientChecked(action) {
  try {
    yield loading();

    yield call(ApiClient.deleteClientChecked, action.props.list);

    // update state for clients
    yield put(clearClientProps());

    yield put(showRecordDeletedToast(recordType));

    if (action.props.callback) {
      action.props.callback();
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* clientInspectionsMapStats(action) {
  try {
    yield loading();
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clientInspectionsMapStats: [],
        loadingMapStats: true,
      }
    });
    // delete  Client
    const clientInspectionsMapStats = yield call(ApiClient.clientInspectionsMapStats, action.props);

    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clientInspectionsMapStats,
        loadingMapStats: false,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* clientInspectionsMapPriority(action) {
  try {
    yield loading();
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clientInspectionsMapPriority: [],
        loadingMapPriority: true,
      }
    });
    // delete  Client
    const clientInspectionsMapPriority = yield call(ApiClient.clientInspectionsMapPriority, action.props);

    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clientInspectionsMapPriority,
        loadingMapPriority: false,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* inspectionsByClient(action) {
  try {
    yield loading();
    const reports = yield call(ApiClient.inspectionsByClient, action.props);
    const pagination = {
      number: reports.number,
      numberOfElements: reports.numberOfElements,
      totalElements: reports.totalElements,
      totalPages: reports.totalPages,
      size: reports.size
    };

    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clientReports: reports.content,
        clientReportsPagination: pagination,
        loading: false,
        errors: "",
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS,
      props: {
        loading: false,
        errors: error
      }
    });
  }
}

function* getClientStats(action) {
  try {
    yield loading();
    const stats = yield call(ApiClient.getClientStats, action.props);

    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clientStats: stats,
        loading: false,
        errors: "",
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS,
      props: {
        loading: false,
        errors: error
      }
    });
  }
}

function* getClientBridgesSections(action) {
  try {
    yield loading();
    const sections = yield call(ApiClient.getClientBridgesSections, action.props);

    const pagination = {
      number: sections.number,
      numberOfElements: sections.numberOfElements,
      totalElements: sections.totalElements,
      totalPages: sections.totalPages,
      size: sections.size
    };

    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS, props: {
        clientBridgeSections: sections.content,
        clientBridgeSectionsPagination: pagination,
        loading: false,
        errors: "",
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_CLIENT_PROPS,
      props: {
        loading: false,
        errors: error
      }
    });
  }
}

/**
 *
 * @param {*} action
 */
function* loadClientContactsPaged(action) {

  try {
    yield put({
      type: actionTypes.CHANGE_CLIENT_START_LOADING_CONTACTS_PAGED
    });

    const clientId = action.props.clientId;
    const paginationOptions = action.props.paginationOptions;
    const contacts = yield call(ApiClient.getClientContactsPaged, clientId, paginationOptions);

    yield put({
      type: actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_CONTACTS_PAGED,
      props: {
        contactsPaged: contacts
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_CLIENT_ERROR_LOADING_CONTACTS_PAGED,
      error: e.message
    })
  }
}

function* editClientContact(action) {
  try {
    yield loading(true);

    const clientId = action.props.clientId;
    const contactId = action.props.contactId;
    const contactData = action.props.contactData;

    yield call(ApiClient.editClientContact, clientId, contactId, contactData);
    yield put(showRecordUpdatedToast("contact"));
    yield loading(false);

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch(error) {
    yield loading(false, error)
  }
}

function* addClientContact(action) {
  try {
    yield* loading(true);
    const { clientId, contactData, callback } = action.props;
    yield call(ApiClient.addClientContact, clientId, contactData);
    yield put(showRecordAddedToast("contact"));
    yield* loading(false);
    if (callback) {
      yield call(callback);
    }
  } catch(error) {

    yield* loading(false, error);
  }
}

function* deleteClientContactList(action) {
  try {
    yield loading(true);
    const { contactIdList, callback } = action.props;
    yield call(ApiClient.deleteClientContactList, contactIdList);
    yield put(showRecordDeletedToast("contact"));
    yield loading(false);

    if (callback) {
      yield call(callback);
    }

  } catch(error) {
    yield loading(false, error);
  }
}











/**
 *
 * @param {*} action
 */
 function* loadClientDocumentsPaged(action) {

  try {
    yield put({
      type: actionTypes.CHANGE_CLIENT_START_LOADING_DOCUMENTS
    });

    const clientId = action.props.clientId;
    const paginationOptions = action.props.paginationOptions;
    const documents = yield call(ApiClient.getClientDocumentsPaged, clientId, paginationOptions);
    yield put({
      type: actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_DOCUMENTS,
      props: {
        documentsPaged: documents
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_CLIENT_ERROR_LOADING_DOCUMENTS,
      error: e.message
    })
  }
}

function* editClientDocument(action) {
  try {
    yield loading(true);

    const documentId = action.props.documentId;
    const documentData = action.props.documentData;

    yield call(ApiClient.editClientDocument, documentId, documentData);
    yield put(showRecordUpdatedToast("document"));
    yield loading(false);

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch(error) {
    yield loading(false, error)
  }
}

function* addClientDocument(action) {
  try {
    yield* loading(true);
    const { clientId, documentData, fileData, callback } = action.props;
    yield call(ApiClient.addClientDocument, clientId, documentData, fileData);
    yield put(showRecordAddedToast("document"));
    yield* loading(false);
    if (callback) {
      yield call(callback);
    }
  } catch(error) {

    yield* loading(false, error);
  }
}

function* deleteClientDocumentList(action) {
  try {
    yield loading(true);
    const { documentIdList, callback } = action.props;
    yield call(ApiClient.deleteClientDocumentList, documentIdList);
    yield put(showRecordDeletedToast("document"));
    yield loading(false);

    if (callback) {
      yield call(callback);
    }

  } catch(error) {
    yield loading(false, error);
  }
}

function* loadClientDocumentTypes(action) {
  try {
    yield put({
      type: actionTypes.CHANGE_CLIENT_START_LOADING_DOCUMENT_TYPES
    });

    const organizationId = action.props.organizationId;
    const documentTypes = yield call(ApiClient.getClientDocumentTypes, organizationId);

    yield put({
      type: actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_DOCUMENT_TYPES,
      props: {documentTypes}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_CLIENT_ERROR_LOADING_DOCUMENT_TYPES,
      error: error.message
    });
  }
}



function* loadClientJobTitles(action) {
  try {
    yield put({
      type: actionTypes.CHANGE_CLIENT_START_LOADING_JOB_TITLES
    });

    const organizationId = action.props.organizationId;
    const jobTitles = yield call(ApiClient.getClientJobTitles, organizationId);

    yield put({
      type: actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_JOB_TITLES,
      props: {jobTitles}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_CLIENT_ERROR_LOADING_JOB_TITLES,
      error: error.message
    });
  }
}



function* loadClientOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_CLIENT_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiClient.getClientOrganizations);

    yield put({
      type: actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_ORGANIZATIONS,
      props: {organizations}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_CLIENT_ERROR_LOADING_ORGANIZATIONS,
      error: error.message
    });
  }
}



function* loadClientClients() {
  try {
    yield put({
      type: actionTypes.CHANGE_CLIENT_START_LOADING_CLIENTS
    });

    const clients = yield call(ApiClient.getClientClients);

    yield put({
      type: actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_CLIENTS,
      props: {clientsForSelection: clients}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_CLIENT_ERROR_LOADING_CLIENTS,
      error: error.message
    });
  }
}



function* loadClientContacts() {
  try {
    yield put({
      type: actionTypes.CHANGE_CLIENT_START_LOADING_CONTACTS
    });

    const contacts = yield call(ApiClient.getClientContacts);

    yield put({
      type: actionTypes.CHANGE_CLIENT_SUCCESS_LOADING_CONTACTS,
      props: {contacts}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_CLIENT_ERROR_LOADING_CONTACTS,
      error: error.message
    });
  }
}





/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_CLIENT_ALL, getClients),
    takeLatest(actionTypes.SAGA_CLIENTS_ALL, getAllClients),
    takeLatest(actionTypes.SAGA_CLIENT_SEARCH, searchClients),
    takeLatest(actionTypes.SAGA_CLIENT_SEARCH_ADMIN, searchClientsAdmin),
    takeLatest(actionTypes.SAGA_CLIENT_GET, getClient),
    takeLatest(actionTypes.SAGA_CLIENT_EDIT, editClient),
    takeLatest(actionTypes.SAGA_CLIENT_ADD, addClient),
    takeLatest(actionTypes.SAGA_CLIENT_DELETE, deleteClient),
    takeLatest(actionTypes.SAGA_CLIENT_DELETE_CHECKED, deleteClientChecked),
    takeLatest(actionTypes.SAGA_CLIENT_INSPECTION_MAP_STATS, clientInspectionsMapStats),
    takeLatest(actionTypes.SAGA_CLIENT_INSPECTION_MAP_PRIORITY, clientInspectionsMapPriority),
    takeLatest(actionTypes.SAGA_CLIENT_GET_REPORTS, inspectionsByClient),
    takeLatest(actionTypes.SAGA_CLIENT_GET_STATS, getClientStats),
    takeLatest(actionTypes.SAGA_CLIENT_GET_BRIDGES_SECTIONS, getClientBridgesSections),
    takeLatest(actionTypes.SAGA_CLIENT_CONTACTS_LOAD_PAGED, loadClientContactsPaged),
    takeLatest(actionTypes.SAGA_CLIENT_CONTACT_EDIT, editClientContact),
    takeLatest(actionTypes.SAGA_CLIENT_CONTACT_ADD, addClientContact),
    takeLatest(actionTypes.SAGA_CLIENT_CONTACT_DELETE_LIST, deleteClientContactList),

    takeLatest(actionTypes.SAGA_CLIENT_DOCUMENTS_LOAD_PAGED, loadClientDocumentsPaged),
    takeLatest(actionTypes.SAGA_CLIENT_DOCUMENT_EDIT, editClientDocument),
    takeLatest(actionTypes.SAGA_CLIENT_DOCUMENT_ADD, addClientDocument),
    takeLatest(actionTypes.SAGA_CLIENT_DOCUMENT_DELETE_LIST, deleteClientDocumentList),
    takeLatest(actionTypes.SAGA_CLIENT_LOAD_DOCUMENT_TYPES, loadClientDocumentTypes),
    takeLatest(actionTypes.SAGA_CLIENT_LOAD_JOB_TITLES, loadClientJobTitles),
    takeLatest(actionTypes.SAGA_CLIENT_LOAD_ORGANIZATIONS, loadClientOrganizations),
    takeLatest(actionTypes.SAGA_CLIENT_LOAD_CLIENTS, loadClientClients),
    takeLatest(actionTypes.SAGA_CLIENT_LOAD_CONTACTS, loadClientContacts),
  ]);
}

export default AuthWatcher;
