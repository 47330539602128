import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const initialState = {
    documentTypes: [],
    documentType: {},
    formValues: null,
    pagination: {},
    loading: false,
    errors: "",
    organizations: createState()
}

function reducerDocumentType(state = initialState, action) {

    switch (action.type) {
      case actionTypes.CHANGE_DOCUMENTTYPE_PROPS:
        return {...state, ...action.props};

      case actionTypes.CLEAR_DOCUMENT_TYPE_PROPS:
        return initialState;

      case actionTypes.CLEAR_DOCUMENT_TYPE_ERRORS:
        return {
          ...state,
          errors: ''
        }

      default: return state;
    }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerDocumentType),
  {
    organizations: [
      actionTypes.CHANGE_DOCUMENTTYPE_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_DOCUMENTTYPE_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_DOCUMENTTYPE_ERROR_LOADING_ORGANIZATIONS
    ]
  }
);
