import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
  errors: '',
  loading: false,
  recommendations: [],
  recommendation: {},
  pagination: {},
  recommendationStatsByClient: {},
  recommendationStatsByClientPaged: [],
  formValues: null,
  lastRefreshView: ''
}

function reducerEventRecommendation(state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_EVENTRECOMMENDATION_PROPS:
      return Object.assign({}, state, action.props)

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerEventRecommendation);
