import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import {addLocationFields, locationFields} from 'common/objectStructures';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';

const initialState = {
  loading: false,
  locations: [],
  locationsByType: [],
  location: {},
  formValues: null,
  pagination: {},
  errors: '',
  organizations: createState(),
  assemblyTypes: createState(),
  componentTypes: createState(),
  itemTypes: createState()
}

function reducerCondition (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_LOCATION_PROPS:
      if (action.props.formValues!= null){
        let newValues = {id: action.props.formValues.id}
        for (const [key, value] of Object.entries(locationFields)) {
          newValues[key] = action.props.formValues[key] || value.defaultValue;
        }
        for (const [key, value] of Object.entries(addLocationFields)) {
          newValues[key] = action.props.formValues[key] || value.defaultValue;
        }
        action.props.formValues = newValues
      }
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_LOCATION_PROPS:
      return initialState;

    case actionTypes.CLEAR_LOCATION_ERRORS:
      return {
        ...state,
        errors: ''
      }

    default:
      return state
  }
}

export default withSubobjectsLoadReducer(
  onLoginCleanupReducer(initialState, reducerCondition),
  {
    organizations: [
      actionTypes.CHANGE_LOCATION_START_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_LOCATION_SUCCESS_LOADING_ORGANIZATIONS,
      actionTypes.CHANGE_LOCATION_ERROR_LOADING_ORGANIZATIONS
    ],
    assemblyTypes: [
      actionTypes.CHANGE_LOCATION_START_LOADING_ASSEMBLY_TYPES,
      actionTypes.CHANGE_LOCATION_SUCCESS_LOADING_ASSEMBLY_TYPES,
      actionTypes.CHANGE_LOCATION_ERROR_LOADING_ASSEMBLY_TYPES
    ],
    componentTypes: [
      actionTypes.CHANGE_LOCATION_START_LOADING_COMPONENT_TYPES,
      actionTypes.CHANGE_LOCATION_SUCCESS_LOADING_COMPONENT_TYPES,
      actionTypes.CHANGE_LOCATION_ERROR_LOADING_COMPONENT_TYPES
    ],
    itemTypes: [
      actionTypes.CHANGE_LOCATION_START_LOADING_ITEM_TYPES,
      actionTypes.CHANGE_LOCATION_SUCCESS_LOADING_ITEM_TYPES,
      actionTypes.CHANGE_LOCATION_ERROR_LOADING_ITEM_TYPES
    ]
  }
);
