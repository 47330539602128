import { all, call, put, takeLatest,select } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiInspectionType from 'tests/mocks/ApiInspectionType.mock'
import ApiInspectionType from 'apiServices/ApiInspectionType'


/*
 * get InspeciontTypes
 */
function* getInspeciontTypes(){
  let loadedInspectionTypes = yield select(store=>store.inspectionType.inspectionTypes)

  //if we have data in store we dont reload it
  if(loadedInspectionTypes.length === 0){
  // get inspection types
   loadedInspectionTypes = yield call(ApiInspectionType.getAllInspectionTypes);

   // update state for Inspections
   yield put({ type: actionTypes.CHANGE_INSPECTIONTYPE_PROPS, props:{
     inspectionTypes: loadedInspectionTypes,
   }});
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_INSPECTIONTYPE_ALL, getInspeciontTypes),
  ]);
}

export default AuthWatcher;