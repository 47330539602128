import {
  Content,
  Footer,
  Header,
  Sidebar,
} from "components/Layout";
import {
  Navbar,
  NavbarBrand,
  Dropdown,
  Nav,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
  Button
} from 'reactstrap';
import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  ROUTE_LOGOUT,
  ROUTE_ACCESSTYPES,
  ROUTE_USERS,
  ROUTE_EVENTS_DASHBOARD
} from 'config/constants';
import { Navigate } from 'react-router-dom'
import logoARE from 'assets/img/logo/ARE_01_White.svg';
import Avatar from 'components/Avatar';
import { changeMenuActiveProps } from 'store/actions/MenuActiveActions'
import { getOrganizations } from 'store/actions/OrganizationActions';
import { changeSelectedOrganization, changeSelectedClient } from 'store/actions/AuthActions';
import { findClients } from 'store/actions/UserActions';
import { getClient } from 'store/actions/ClientActions';
import { getMenuIcon } from "common/svgitems";
import { Fragment } from "react";
import Utils from "common/utils";
import ModalValueSelector from "components/ModalValueSelector";


const MainLayout = (props) => {
  const {
    children,
    breakpoint,
    changeMenuActiveProps,
    sideBar,
    sidebarActive,
    currentUserIsSuperAdmin,
    currentUserIsClient,
    getOrganizations,
    changeSelectedOrganization,
    changeSelectedClient,
    getClient,
    organizations,
    clients,
    client,
    findClients,
    user
  } = props

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [redirectTo, setRedirectTo] = useState("")
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [width, setWidth] = useState(window.innerWidth > 850)
  const [showOrganizationSelectorModal, setShowOrganizationSelectorModal] = useState(false)
  const [showClientSelectorModal, setShowClientSelectorModal] = useState(false);
  const [defaultClient, setDefaultClient] = useState(user.isClient && user.client ? user.client : null)
  const navbarLocation = localStorage.getItem("NavBarLocation")

  useEffect(() => {
    if (currentUserIsSuperAdmin) {
      getOrganizations();
    } else if (currentUserIsClient && clients.length === 0) {
      findClients();
    }
  }, [user, currentUserIsSuperAdmin, getOrganizations, currentUserIsClient, findClients, clients.length]);

  useEffect(()=>{
    if(user.client){
      getClient({clientId: user.client})
    }else if(clients && clients.length > 0 ){
      getClient({clientId: clients[0].id})
      changeSelectedClient(clients[0].id)
    }
  },[clients,getClient,changeSelectedClient, user.client ])

  useEffect(()=>{
    if(client){
      setDefaultClient(client)
      changeSelectedClient(client.id)
    }

  },[client,setDefaultClient,changeSelectedClient])

  useEffect(() => {
    let location = navbarLocation
    if (location) {
      changeMenuActiveProps({
        sidebarActive: location
      })
    } else {
      localStorage.setItem("NavBarLocation", "assetBridge")
    }
  }, [navbarLocation,changeMenuActiveProps])

  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const handleItemMenuServies = value => {
    let redirectTo = ''
    localStorage.setItem("NavBarLocation", value);
    props.changeMenuActiveProps({
      sidebarActive: value
    })
    switch (value) {
      case 'managementAsset':
        redirectTo = window.location.pathname === ROUTE_ACCESSTYPES ? '' : ROUTE_ACCESSTYPES
        break;
      case 'managementSettings':
        redirectTo = window.location.pathname === ROUTE_USERS ? '' : ROUTE_USERS
        break;
      default:
        redirectTo = window.location.pathname === ROUTE_EVENTS_DASHBOARD ? '' : ROUTE_EVENTS_DASHBOARD
        break;
    }

    setDropdownOpen(prev => !prev)
    setRedirectTo(redirectTo)
  }

  const checkBreakpoint = (breakpoint) => {
    switch (breakpoint) {
      case "xs":
      case "sm":
      case "md":
        openSidebar("close");
        break

      case "lg":
      case "xl":
      default:
        openSidebar(sideBar ? "open" : "close");
        break
    }
  }

  useEffect(() => {
    checkBreakpoint(breakpoint)
  }, [breakpoint,checkBreakpoint])

  const openSidebar = (openOrClose) => {
    if (document.querySelector(".cr-sidebar")) {
      if (openOrClose === "open") {
        return document
          .querySelector(".cr-sidebar")
          .classList.add("cr-sidebar--open");
      }
      document.querySelector(".cr-sidebar").classList.remove("cr-sidebar--open");
    }
  }

  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();
    setSideBarOpen(!sideBarOpen)
    setWidth(!width)
  };

  const showOrganizationSelector = useCallback(() => {
    setShowOrganizationSelectorModal(true);
  }, [setShowOrganizationSelectorModal]);

  const showClientSelector = useCallback(() => {
    setShowClientSelectorModal(true);
  }, [setShowClientSelectorModal]);

  const hideOrganizationSelector = useCallback(() => {
    setShowOrganizationSelectorModal(false);
  }, [setShowOrganizationSelectorModal]);

  const hideClientSelector = useCallback(() => {
    setShowClientSelectorModal(false);
  }, [setShowClientSelectorModal]);

  const onSelectedOrganizationConfirm = useCallback(selectedOrganization => {
    changeSelectedOrganization(selectedOrganization.id);
    hideOrganizationSelector();
    setRedirectTo("/");
  }, [changeSelectedOrganization, hideOrganizationSelector, setRedirectTo]);

  const onSelectedClientConfirm = useCallback(selectedClient => {
    getClient({clientId:selectedClient.id});
    hideClientSelector();
    //setRedirectTo("/");
  }, [getClient, hideClientSelector/*, setRedirectTo*/]);


  if (redirectTo !== '') return <Navigate to={redirectTo} />
  let hasSideBar = children[1].props.path !== "/dashboard"
  const defaultOrganization = organizations.find(x => x.id === user.organization);
  
  let tenantDropDownItem = null;
  let clientDropDownItem = null;

  if (currentUserIsSuperAdmin && defaultOrganization && defaultOrganization.name) {
    tenantDropDownItem = <DropdownItem onClick={showOrganizationSelector} className='sign-out'>
      Switch Tenant <small>({defaultOrganization.name})</small>
    </DropdownItem>;
  }

  if (currentUserIsClient && defaultClient && defaultClient.name && clients && clients.length > 1) {
    clientDropDownItem = <DropdownItem onClick={showClientSelector} className='sign-out'>
      Switch Client <small>({defaultClient.name})</small>
    </DropdownItem>;
  }

  const navButton = width ?
    <div className="empty-button">
    </div>
    :
    <Fragment>
      <div className="empty-button2">
      </div>
      <nav id="sidebar" className="sidebar-button-wrapper">
        <div className="sidebar-content">
          <div id="mCSB_1">
            <div id="mCSB_1_container" className="mCSB_container">
              <div className=" sidebar-item sidebar-menu">
                <div className="sidebar-icon sidebar-icon-outside ">
                  <Button
                    className="m-0 p-0"
                    color="link"
                    onClick={handleSidebarControlButton}>
                    {getMenuIcon(sideBarOpen)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>

   
  return (
    <Fragment>
      <header>
        <Navbar className='navbar fixed-top custom-navbar'>
          <NavbarBrand href="/"><img src={logoARE} alt="img" width="130px" height="35px" className="logo-are-1" /></NavbarBrand>
          <Nav className="mr-auto">
            <Dropdown nav inNavbar isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle nav caret>
                Services
              </DropdownToggle>
              <DropdownMenu className='drop-down-menu'>
                <Row className="h-100">
                  <Col xs={5} className='col-menu-drop-down'>
                    <Row>
                      <Col xs={12} className='section-title'>Assets Group</Col>
                      <Col xs={12} className={`item-title ${sidebarActive === 'assetBridge' ? 'menu-active' : ''}`} onClick={() => handleItemMenuServies('assetBridge')}>Bridges </Col>
                      <Col xs={12} className={`item-title ${sidebarActive === '' ? 'menu-active' : ''}`}>Tracks</Col>
                    </Row>
                  </Col>
                  <Col xs={5} className='col-menu-drop-down'>
                    <Row>
                      <Col xs={12} className='section-title'>Management</Col>
                      <Col xs={12} className={`item-title ${sidebarActive === 'managementAsset' ? 'menu-active' : ''}`} onClick={() => handleItemMenuServies('managementAsset')}>Assets</Col>
                      <Col xs={12} className={`item-title ${sidebarActive === 'managementSettings' ? 'menu-active' : ''}`} onClick={() => handleItemMenuServies('managementSettings')}>Settings</Col>
                    </Row>
                  </Col>
                </Row>
              </DropdownMenu>
            </Dropdown>
          </Nav>
          <Nav className="float-right">
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <Avatar size={28} className="can-click avatar" /> <span className='hidden-xs'>{user ? user.email : ''}</span>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem href={`/users/profile`} className='sign-out'>Profile</DropdownItem>

                {currentUserIsSuperAdmin ? tenantDropDownItem : null}
                {currentUserIsClient ? clientDropDownItem : null}

                <DropdownItem divider />
                <DropdownItem href={ROUTE_LOGOUT} className='sign-out'>Sign Out</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Navbar>
      </header>


      <div className={width ? "page-wrapper default-theme sidebar-bg bg1 toggled" : "page-wrapper default-theme sidebar-bg bg1"}>

        {
          window.location.pathname !== '/dashboard' ?

            <Sidebar
              sideBarOpen={sideBarOpen}
              handleSidebarControlButton={handleSidebarControlButton}
            />
            :
            null
        }

        <main role="main" className={hasSideBar ? "page-content  pl-0 mt-5" : "pl-0 mt-5"}>

          <Content fluid>
            <Header
              navButton={navButton}
            />
            {children}
            <Footer />
          </Content>
        </main>

      </div>

      <ModalValueSelector
        show={currentUserIsSuperAdmin ? showOrganizationSelectorModal : showClientSelectorModal}
        title={currentUserIsSuperAdmin ? "Select an organization" : "Select a client"}
        informationText={currentUserIsSuperAdmin ? "Select an organization from the list below:" : "Select a client from the list below"}
        onConfirm={currentUserIsSuperAdmin ? onSelectedOrganizationConfirm : onSelectedClientConfirm}
        onCancel={currentUserIsSuperAdmin ? hideOrganizationSelector : hideClientSelector}
        models={currentUserIsSuperAdmin ? organizations : clients}
        defaultValue={currentUserIsSuperAdmin ? defaultOrganization : defaultClient}
      />
    </Fragment>
  );
}

function mapStateToProps(store) {
  return {
    sideBar: store.header.sideBar,
    user: store.auth.session,
    sidebarActive: store.menuActive.sidebarActive,
    currentUserIsSuperAdmin: Utils.isSuperAdmin(store.auth.session),
    currentUserIsClient: Utils.isClient(store.auth.session),
    organizations: store.organization.organizations,
    clients: store.user.clients.data,
    client: store.client.client
  };
}

export default connect(mapStateToProps, {
  changeMenuActiveProps,
  getOrganizations,
  changeSelectedOrganization,
  changeSelectedClient,
  findClients,
  getClient
})(MainLayout);
