import React from "react";
import { connect } from "react-redux";
import componentQueries from "react-component-queries";
import Router from "config/Router";
import "styles/are_inspection.css";
import { reloadTokenAction } from "store/actions/AuthActions";
import {Circles} from "react-loader-spinner";
import EmptyLayout from "components/Layout/EmptyLayout";

class App extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.auth.token === "") {
      this.props.reloadTokenAction();
    }
  }

  render() {
    if (this.props.auth.loading) {
      return (
        <EmptyLayout>
          <div className="center-spinner">
            <Circles height={50} width={50} color="#101010" />
          </div>
        </EmptyLayout>
      );
    }
    return <Router {...this.props} />;
  }
}

function mapStateToProps(store) {
  return {
    auth: store.auth
  };
}

const query = ({ width }) => {
  width = window.innerWidth;

  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

const SessionApp = connect(mapStateToProps, { reloadTokenAction })(App);
export default componentQueries(query)(SessionApp);
//export default componentQueries(query)(App);
