import * as actionTypes from './actionTypes'

/**
 * @namespace DocumentTypeActions
 */


export function getDocumentTypes() {
    return { type: actionTypes.SAGA_DOCUMENTTYPE_ALL };
}

export function loadDocumentTypesPaged(props) {
    return { type: actionTypes.SAGA_DOCUMENTTYPE_SEARCH, props: props };
}

export function getDocumentType(props) {
    return { type: actionTypes.SAGA_DOCUMENTTYPE_GET, props: props };
}

export function editDocumentType(props) {
    return { type: actionTypes.SAGA_DOCUMENTTYPE_EDIT, props: props };
}

export function addDocumentType(props) {
    return { type: actionTypes.SAGA_DOCUMENTTYPE_ADD, props: props };
}

export function deleteDocumentType(props) {
    return { type: actionTypes.SAGA_DOCUMENTTYPE_DELETE, props: props };
}

export function deleteDocumentTypeList(list, options = {callback: () => {}}) {
    return {
        type: actionTypes.SAGA_DOCUMENTTYPE_DELETE_CHECKED,
        props: {
          list,
          callback: options.callback
        }
    };
}
/**
 * @memberof DocumentTypeActions
 * @description Change DocumentType props (no saga action)
 */
export function changeDocumentTypeProps(props) {
    return { type: actionTypes.CHANGE_DOCUMENTTYPE_PROPS, props: props };
}

/**
 * @memberof DocumentTypeActions
 * @description Clears Document Type props
 */
export function clearDocumentTypeProps() {
  return { type: actionTypes.CLEAR_DOCUMENT_TYPE_PROPS };
}

/**
 * @memberof DocumentTypeActions
 * @description Clears Document Type errors messages.
 */
export function clearDocumentTypeErrors() {
  return { type: actionTypes.CLEAR_DOCUMENT_TYPE_ERRORS };
}

/**
 * @memberof DocumentTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load organizations available for document types.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_DOCUMENTTYPE_LOAD_ORGANIZATIONS };
}
