import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/assetEvent';

const endpointSubdivision = API_SERVER + '/subdivision';

export default class ApiAssetEvent {

  // GET 'api/assetEvents'
  static getAll() {
    return Utils.genericCall(endpoint);
  }

  static searchAssetEvents(props) {
    return Utils.genericCall(`${endpoint}/paged?search=${props.search}&page=${props.page}`);
  }

  static getAssetEventByAssetId(assetEventId) {
    return Utils.genericCall(`${API_SERVER}/assetEventByAssetEventId/${assetEventId}`);
  }

  static getBySubdivision(id) {
    return Utils.genericCall(`${endpointSubdivision}/${id}/assetEvent`);
  }

  static getAssetEvent(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editAssetEvent(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addAssetEvent(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteAssetEvent(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static getAssetEventByEvent(id, eventId) {
    return Utils.genericCall(`${endpoint}/${id}/event/${eventId}`);
  }

  static getAllAssetEvent(assetId, eventId) {
    return Utils.genericCall(`${endpoint}/asset/${assetId}/event/${eventId}`);
  }

  // "/assetEvent/delete" POST
  static deleteTripAssets(data) {
    return Utils.genericPost(`${endpoint}/delete`, data);
  }

  static getStructureTypes(assetEventId) {
    return Utils.genericCall(`${endpoint}/${assetEventId}/structureTypes`);
  }

  static getUsersThatCanWorkOnReports(data) {
    return Utils.genericCall(`${endpoint}/${data.assetEventId}/reportsUsers/${data.milestoneId}`);
  }

  static getInspectors(assetEventId) {
    return Utils.genericCall(`${endpoint}/${assetEventId}/inspectors`);
  }
}
