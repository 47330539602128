import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
    organizations: [],
    organizationTypes: [],
    formValues: null,
    loading: false,
    errors: '',
    pagination: {},

    /**
     * Current logged user organization
     */
    currentUserOrganization: {
      loading: false,

      /**
       * This is an Organization object.
       */
      model: {},
      error: null
    }
}

function reducerOrganization(state = initialState, action) {
    switch (action.type) {

        case actionTypes.CHANGE_ORGANIZATION_PROPS:
            if (action.props.formValues != null) {
                const newValues = {
                    id: action.props.formValues.id,
                    name: action.props.formValues.name || "",
                    description: action.props.formValues.description || "",
                    isRemovable: action.props.formValues.isRemovable || "",
                    abbreviation: action.props.formValues.abbreviation || "",
                    type: action.props.formValues.type || "",
                    parent: action.props.formValues.parent
                };
                action.props.formValues = newValues
            }
            return Object.assign({}, state, action.props)


        case actionTypes.CLEAR_ORGANIZATION_PROPS:
          return {
            ...initialState,
            organizationTypes: state.organizationTypes
          };

        case actionTypes.CLEAR_ORGANIZATION_ERRORS:
          return {
            ...state,
            errors: ''
          }

        case actionTypes.ORGANIZATION_LOADING_CURRENT_USER:
          return {
            ...state,
            currentUserOrganization: {
              loading: true,
              model: {},
              error: null
            }
          }

        case actionTypes.ORGANIZATION_LOADED_CURRENT_USER:
          return {
            ...state,
            currentUserOrganization: {
              loading: false,
              model: action.currentUserOrganization,
              error: null
            }
          }

        case actionTypes.ORGANIZATION_ERROR_LOADING_CURRENT_USER:
          return {
            ...state,
            currentUserOrganization: {
              loading: false,
              model: {},
              error: action.error
            }
          }

        default:
            return state
    }
}

export default onLoginCleanupReducer(initialState, reducerOrganization);
