import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiRailroad from 'tests/mocks/ApiRailRoad.mock'
import ApiRailroad from 'apiServices/ApiRailRoad'

import { showRecordUpdatedToast, showRecordDeletedToast, showRecordAddedToast, showToastAsError } from '../actions/ToastActions';
const recordType = "railroad";

/*
 * get Railroads
 */
function* getRailroads(){
  // get railroad by Client
  const allRailroads = yield call(ApiRailroad.getAll);
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroads: allRailroads,
  }});
}

function* searchRailroads(action){
  // search all Railroads
  const railroads = yield call(ApiRailroad.searchRailroads, action.props);
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroads: railroads.content,
  }});
}

function* getByClients(action) {
  // get all railroads by clients
  const railroads = yield call(ApiRailroad.getByClients, action.props);
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroads: railroads.content,
  }});
}

function* getByClient(action) {
  yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: {level:1, ids: action.props}});
  // get client railroads first page
  const railroads = yield call(ApiRailroad.getByClient, action.props.clientId);
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroads: railroads,
  }});
}

function* searchByClient(action){
  // get all railroads by client
  const railroads = yield call(ApiRailroad.searchByClient, action.props);
  const pagination = { number: railroads.number,
    numberOfElements: railroads.numberOfElements,
    totalElements: railroads.totalElements,
    totalPages: railroads.totalPages,
    size: railroads.size};
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroads: railroads.content,
    pagination: pagination,
  }});
}

function* getRailroad(action){
  // get railroad
  const railroad = yield call(ApiRailroad.getRailroad, action.props.railroadId);
  yield put({type: actionTypes.CHANGE_NAMES_PROPS, props:{
    name: railroad.name,
    id: railroad.id,
    type: "railroad"
  }});
  if (Object.keys(action.props).length > 1)
    yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: {level:1, ids: action.props}});
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroad: railroad,
  }});
}

function* editRailroad(action){
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{edited: true}});
  // update  Railroad
  const railroad = yield call(ApiRailroad.editRailroad, action.props);
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroad
  }});

  yield put({ type: actionTypes.CHANGE_RAILROAD, props: {
    railroad
  } });

  // yield getRailroad({props: {railroadId: action.props.id}});
  yield put(showRecordUpdatedToast(recordType));
}

function* addRailroad(action){
  // add  Railroad
  const railroad = yield call(ApiRailroad.addRailroad, action.props);
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroad: {},
  }});

  yield put({ type: actionTypes.CHANGE_RAILROAD, props: {
    railroad
  } });

  // yield searchByClient({props: {id:action.props.organization.id, search:'', page: 0, size: 50, order: {id:'name', desc: false}}});
  yield put(showRecordAddedToast(recordType));
}

function* deleteRailroad(action){
  // delete  Railroad
  yield call(ApiRailroad.deleteRailroad, action.props.id);
  // yield searchByClient({props: {id:action.props.client.id, search:'', page: 0, size: 50, order: {id:'name', desc: false}}});
}

function* deleteRailroadChecked(action) {
  const deletedRailroadsIds = yield call(ApiRailroad.deleteRailroadChecked, action.props.list);
  yield put({ type: actionTypes.CHANGE_RAILROAD_REMOVED, props: {
    removedRailroadsIds: deletedRailroadsIds
  } });

  yield put(showRecordDeletedToast(recordType));
}

function* searchRailroadByName(action){
  // search all Railroads
  const railroads = yield call(ApiRailroad.searchRailroadByName, action.props);
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroads: railroads,
  }});
}

function* searchByClientPage(action){
  // search all Railroads
  const railroads = yield call(ApiRailroad.searchByClientPage, {id: action.props.filterObject.id, data: action.props.filterObject});
  const pagination = { number: railroads.number,
    numberOfElements: railroads.numberOfElements,
    totalElements: railroads.totalElements,
    totalPages: railroads.totalPages,
    size: railroads.size};
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroads: railroads.content,
    pagination: pagination,
  }});
}


function* getRailroadListByCLient(action){
  // search all Railroads
  const railroads = yield call(ApiRailroad.getRailroadListByCLient, action.props.clientId);
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroads: railroads
  }});
}


function* getRailroadListByCLientReduced(action){
  // search all Railroads
  const railroads = yield call(ApiRailroad.getRailroadListByCLientReduced, action.props.clientId);
  // update state for railroads
  yield put({ type: actionTypes.CHANGE_RAILROAD_PROPS, props:{
    railroads: railroads
  }});
}


/**
 *
 * @param {*} action
 */
 function* loadRailroadContactsPaged(action) {

  try {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_START_LOADING_CONTACTS_PAGED
    });

    const railroadId = action.props.railroadId;
    const paginationOptions = action.props.paginationOptions;
    const contacts = yield call(ApiRailroad.getRailroadContactsPaged, railroadId, paginationOptions);

    yield put({
      type: actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_CONTACTS_PAGED,
      props: {
        contactsPaged: contacts
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_ERROR_LOADING_CONTACTS_PAGED,
      error: e.message
    })
  }
}

function* editRailroadContact(action) {
  try {
    const railroadId = action.props.railroadId;
    const contactId = action.props.contactId;
    const contactData = action.props.contactData;

    yield call(ApiRailroad.editRailroadContact, railroadId, contactId, contactData);
    yield put(showRecordUpdatedToast("contact"));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch(error) {
    yield put(showToastAsError("Error editing contact", "There was an error trying to edit the contact. Please try again."))
  }
}

function* addRailroadContact(action) {
  try {
    const { railroadId, contactData, callback } = action.props;
    yield call(ApiRailroad.addRailroadContact, railroadId, contactData);
    yield put(showRecordAddedToast("contact"));

    if (callback) {
      yield call(callback);
    }
  } catch(error) {
    yield put(showToastAsError("Error creating contact", "There was an error trying to create the contact. Please try again."));
  }
}

function* deleteRailroadContactList(action) {
  try {
    const { contactIdList, callback } = action.props;
    yield call(ApiRailroad.deleteRailroadContactList, contactIdList);
    yield put(showRecordDeletedToast("contact"));

    if (callback) {
      yield call(callback);
    }

  } catch(error) {
    //TODO show error
  }
}


/**
 *
 * @param {*} action
 */
 function* loadRailroadDocumentsPaged(action) {

  try {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_START_LOADING_DOCUMENTS
    });

    const railroadId = action.props.railroadId;
    const paginationOptions = action.props.paginationOptions;
    const documents = yield call(ApiRailroad.getRailroadDocumentsPaged, railroadId, paginationOptions);
    yield put({
      type: actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_DOCUMENTS,
      props: {
        documentsPaged: documents
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_ERROR_LOADING_DOCUMENTS,
      error: e.message
    })
  }
}

function* editRailroadDocument(action) {
  try {

    const documentId = action.props.documentId;
    const documentData = action.props.documentData;

    yield call(ApiRailroad.editRailroadDocument, documentId, documentData);
    yield put(showRecordUpdatedToast("document"));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch(error) {
    //TODO show error
  }
}

function* addRailroadDocument(action) {
  try {
    const { railroadId, documentData, fileData, callback } = action.props;
    yield call(ApiRailroad.addRailroadDocument, railroadId, documentData, fileData);
    yield put(showRecordAddedToast("document"));
    if (callback) {
      yield call(callback);
    }
  } catch(error) {
    //TODO show error
  }
}

function* deleteRailroadDocumentList(action) {
  try {
    const { documentIdList, callback } = action.props;
    yield call(ApiRailroad.deleteRailroadDocumentList, documentIdList);
    yield put(showRecordDeletedToast("document"));

    if (callback) {
      yield call(callback);
    }

  } catch(error) {
    //TODO show error
  }
}

function* loadRailroadDocumentTypes(action) {
  try {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_START_LOADING_DOCUMENT_TYPES
    });

    const organizationId = action.props.organizationId;
    const documentTypes = yield call(ApiRailroad.getRailroadDocumentTypes, organizationId);

    yield put({
      type: actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_DOCUMENT_TYPES,
      props: {documentTypes}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_ERROR_LOADING_DOCUMENT_TYPES,
      error: error.message
    });
  }
}

function* loadRailroadJobtitles(action) {
  try {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_START_LOADING_JOB_TITLES
    });

    const organizationId = action.props.organizationId;
    const jobTitles = yield call(ApiRailroad.getRailroadJobTitles, organizationId);

    yield put({
      type: actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_JOB_TITLES,
      props: {jobTitles}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_ERROR_LOADING_JOB_TITLES,
      error: error.message
    });
  }
}

function* loadRailroadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiRailroad.getRailroadOrganizations);

    yield put({
      type: actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_ORGANIZATIONS,
      props: {organizations}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_ERROR_LOADING_ORGANIZATIONS,
      error: error.message
    });
  }
}


function* loadRailroadClients() {
  try {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_START_LOADING_CLIENTS
    });

    const clients = yield call(ApiRailroad.getRailroadClients);

    yield put({
      type: actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_CLIENTS,
      props: {clients}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_ERROR_LOADING_CLIENTS,
      error: error.message
    });
  }
}

function* loadRailroadContacts() {
  try {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_START_LOADING_CONTACTS
    });

    const contacts = yield call(ApiRailroad.getRailroadContacts);

    yield put({
      type: actionTypes.CHANGE_RAILROAD_SUCCESS_LOADING_CONTACTS,
      props: {contacts}
    });

  } catch(error) {
    yield put({
      type: actionTypes.CHANGE_RAILROAD_ERROR_LOADING_CONTACTS,
      error: error.message
    });
  }
}


/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_RAILROAD_ALL, getRailroads),
    takeLatest(actionTypes.SAGA_RAILROAD_GET, getRailroad),
    takeLatest(actionTypes.SAGA_RAILROAD_SEARCH, searchRailroads),
    takeLatest(actionTypes.SAGA_RAILROAD_EDIT, editRailroad),
    takeLatest(actionTypes.SAGA_RAILROAD_ADD, addRailroad),
    takeLatest(actionTypes.SAGA_CLIENT_RAILROADS, getByClient),
    takeLatest(actionTypes.SAGA_CLIENT_RAILROADS_SEARCH, searchByClient),
    takeLatest(actionTypes.SAGA_RAILROAD_DELETE, deleteRailroad),
    takeLatest(actionTypes.SAGA_RAILROAD_BY_CLIENTS, getByClients),
    takeLatest(actionTypes.SAGA_RAILROAD_DELETE_CHECKED, deleteRailroadChecked),
    takeLatest(actionTypes.SAGA_RAILROAD_BY_NAME, searchRailroadByName),
    takeLatest(actionTypes.SAGA_RAILROAD_SEARCH_BY_CLIENTS, searchByClientPage),
    takeLatest(actionTypes.SAGA_RAILROAD_LIST_BY_CLIENTS, getRailroadListByCLient),
    takeLatest(actionTypes.SAGA_RAILROAD_LIST_BY_CLIENTS_REDUCED, getRailroadListByCLientReduced),

    takeLatest(actionTypes.SAGA_RAILROAD_CONTACTS_LOAD_PAGED, loadRailroadContactsPaged),
    takeLatest(actionTypes.SAGA_RAILROAD_CONTACT_EDIT, editRailroadContact),
    takeLatest(actionTypes.SAGA_RAILROAD_CONTACT_ADD, addRailroadContact),
    takeLatest(actionTypes.SAGA_RAILROAD_CONTACT_DELETE_LIST, deleteRailroadContactList),

    takeLatest(actionTypes.SAGA_RAILROAD_DOCUMENTS_LOAD_PAGED, loadRailroadDocumentsPaged),
    takeLatest(actionTypes.SAGA_RAILROAD_DOCUMENT_EDIT, editRailroadDocument),
    takeLatest(actionTypes.SAGA_RAILROAD_DOCUMENT_ADD, addRailroadDocument),
    takeLatest(actionTypes.SAGA_RAILROAD_DOCUMENT_DELETE_LIST, deleteRailroadDocumentList),
    takeLatest(actionTypes.SAGA_RAILROAD_LOAD_DOCUMENT_TYPES, loadRailroadDocumentTypes),
    takeLatest(actionTypes.SAGA_RAILROAD_GET_JOB_TITLES, loadRailroadJobtitles),
    takeLatest(actionTypes.SAGA_RAILROAD_GET_ORGANIZATIONS, loadRailroadOrganizations),
    takeLatest(actionTypes.SAGA_RAILROAD_GET_CLIENTS, loadRailroadClients),
    takeLatest(actionTypes.SAGA_RAILROAD_GET_CONTACTS, loadRailroadContacts)
  ]);
}

export default AuthWatcher;
