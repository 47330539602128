import * as actionTypes from '../actions/actionTypes'
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
  components: [],
  component: {},
  newComponent: {},
  formValues: null,
  allFields: [],
}

function reducerComponent (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_COMPONENT_PROPS:
      return Object.assign({}, state, action.props)

    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerComponent);
