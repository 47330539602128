import * as actionTypes from './actionTypes'

/**
 * @namespace RoleActions
 */


export function getRoles() {
  return { type: actionTypes.SAGA_ROLE_ALL };
}

export function loadRolesPaged(props) {
  return { type: actionTypes.SAGA_ROLE_SEARCH, props: props };
}

export function getRole(props) {
  return { type: actionTypes.SAGA_ROLE_GET, props: props };
}

export function editRole(props) {
  return { type: actionTypes.SAGA_ROLE_EDIT, props: props };
}

export function addRole(props) {
  return { type: actionTypes.SAGA_ROLE_ADD, props: props };
}

export function deleteRole(props) {
  return { type: actionTypes.SAGA_ROLE_DELETE, props: props };
}

export function deleteRoleList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_ROLE_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

export function getParentOrganizationRoles() {
  return { type: actionTypes.SAGA_ROLE_ALL_PARENT_ORGANIZATION};
}


/*
 * Change Role props (no saga action)
 */
export function changeRoleProps(props) {
  return { type: actionTypes.CHANGE_ROLE_PROPS, props: props };
}

/**
 * Clear Role props.
 */
export function clearRoleProps() {
  return { type: actionTypes.CLEAR_ROLE_PROPS };
}

/**
 * Clear Role errors messages.
 */
export function clearRoleErrors() {
  return { type: actionTypes.CLEAR_ROLE_ERRORS };
}

/**
 * @memberof RoleActions
 * @description Creates a Redux action that can be dispatched to
 * get available organizations for roles.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getOrganizations() {
  return { type: actionTypes.SAGA_ROLE_GET_ORGANIZATIONS };
}

/**
 * @memberof RoleActions
 * @description Creates a Redux action that can be dispatched to
 * get available claims for roles.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getClaims() {
  return { type: actionTypes.SAGA_ROLE_GET_CLAIMS };
}
