import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/eventRecommendation';

export default class EventRecommendation {

  // GET 'api/eventRecommendations'
  static getAll(id){
    return Utils.genericCall(`${endpoint}/asset/${id}`);
  }

  static getEventRecommendation(assetId, eventId) {
    return Utils.genericCall(`${endpoint}/asset/${assetId}/event/${eventId}`);
  }

  static getEventRecommendationById(eventRecommendationId) {
    return Utils.genericCall(`${endpoint}/${eventRecommendationId}`);
  }

  static addEventRecommendation(data) {
    return Utils.genericPost(`${endpoint}`, data);
  }

  static addEventRecommendationPrevtoNew(data) {
    return Utils.genericPost(`${endpoint}/previous/${data.eventRecommendationId}`, data);
  }

  static editEventRecommendation(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static deleteEventRecommendation(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static getStatsRecommendationByClient(data) {
    return Utils.genericPost(`${endpoint}/organization/${data.clientId}/stats`, data);
  }

  static getStatsRecommendationByClientPaged(data) {
    return Utils.genericPost(`${endpoint}/organization/${data.clientId}/stats/paged`, data);
  }

  static editEventRecommendationEstimate(data) {
    return Utils.genericPut(`${endpoint}/${data.id}/estimatedReplacementCost`, data.estimate);
  }

  static getEventRecommendationLastRefreshView() {
    return Utils.genericCall(`${endpoint}/lastRefreshView`);
  }

  static getAllPriorities() {
    return Utils.genericCall(`${endpoint}/getPriorities`);
  }
}