import { all, call, put, takeLatest,select } from "redux-saga/effects";
import * as actionTypes from "store/actions/actionTypes";
// APIs to talk to...
import ApiImage from "apiServices/ApiImage";

import { showToastAsError, showToastAsSuccess  } from '../actions/ToastActions';

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The Image cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_IMAGE_PROPS, props });
}

function* getFilesAsset(action) {
  try {
    yield loading();
    let filesAssetEventData = yield call(ApiImage.getFilesAsset, action.props);
    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        filesAssetEventData: filesAssetEventData,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* uploadAssetEventPhoto(action) {
  try {
    yield loading();
    let fileAssetEventData = yield call(ApiImage.uploadAssetEventPhoto, action.props.data);
    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        fileAssetEventData: fileAssetEventData,
        loading: false,
        errors: ''
      }
    });
    yield put(showToastAsSuccess("Photo(s) uploaded successfully"));
    if (action.props.callback) {
      yield call(action.props.callback);
    }
  } catch (error) {
    yield put(showToastAsError("Photo(s) upload Failed"));
    yield loading(false, error);
  }

}

function* uploadAssetFile(action) {
  try {
    yield loading();
    let fileAssetEventData = yield call(ApiImage.uploadAssetFile, action.props);
    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        fileAssetEventData: fileAssetEventData,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getPhotosAsset(action) {
  try {
    yield loading();
    let photoData = yield call(ApiImage.getPhotosAsset, action.props.eventId);
    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        photoData: photoData,
        loading: false,
        errors: ''
      }
    }
    );
    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* getPhotoById(action) {
  try {
    yield loading();
    let photoData = yield call(ApiImage.getPhotoById, action.props.photoId);
    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        selectedPhoto: photoData,
        loading: false,
        errors: ''
      }
    }
    );
    if (action.props.callback) {
      yield call(action.props.callback);
    }
  } catch (error) {
    yield loading(false, error);
  }
}

function* getPhotosAssetPaged(action) {
  try {
    yield loading();
    let photoData = yield call(ApiImage.getPhotosAssetPaged, action.props);

    const pagination = {
      eventId: action.props.eventId,
      number: photoData.number,
      numberOfElements: photoData.numberOfElements,
      totalElements: photoData.totalElements,
      totalPages: photoData.totalPages,
      size: photoData.size
    };

    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        photos: photoData.content,
        photosPagination: pagination,
        loading: false,
        errors: ''
      }
    }
    );

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* getBridgePhotosPaged(action) {
  try {
    yield loading();
    let photoData = yield call(ApiImage.getBridgePhotosPaged, action.props);

    const pagination = {
      eventId: action.props.eventId,
      number: photoData.number,
      numberOfElements: photoData.numberOfElements,
      totalElements: photoData.totalElements,
      totalPages: photoData.totalPages,
      size: photoData.size
    };

    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        photos: photoData.content,
        photosPagination: pagination,
        loading: false,
        errors: ''
      }
    }
    );

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* editPhotoTags(action) {
  try {
    yield loading();
    yield call(ApiImage.editPhotoTags, action.props);
    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        photoData: {},
        loading: false,
        errors: ''
      }
    });
    /*yield getPhotosAsset({eventId: action.props.eventId})*/
    yield put({
      type: actionTypes.SAGA_FILE_ASSET_PHOTOS,
      props: { eventId: action.props.eventId }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* deletePhotoTags(action) {
  try {
    yield loading();

    let loadedPhotos = yield select(store=>store.image.photoData)
    let editedPhoto = yield call(ApiImage.deletePhotoTags, {eventPhotoId: action.props.eventPhotoId, data: action.props.data});
    let index = loadedPhotos.findIndex(photo=>photo.id === editedPhoto.id);
    if(index !== -1){
    loadedPhotos.splice(index,1);
    loadedPhotos.push(editedPhoto)
    }
    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        photoData: loadedPhotos,
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
 
  } catch (error) {
    yield loading(false, error);
  }

}

function* deletePhoto(action) {
  try {
    yield loading();
    yield call(ApiImage.deletePhoto, action.props.data);
    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        photoData: [],
        loading: false,
        errors: ''
      }
    });
    if (action.props.callback) {
      yield call(action.props.callback);
    }
    // yield getPhotosAsset({ props: { eventId: action.props.eventId } })
  } catch (error) {
    yield loading(false, error);
  }
}

function* addPhotoInfo(action) {

  try {
    yield loading();
    yield call(ApiImage.addPhotoInfo, action.props.data);

    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        loading: false,
        errors: ''
      }
    });

    yield call(getPhotoById, { props: { photoId: action.props.data.eventPhoto.id } });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* editPhotoInfo(action) {
  try {
    yield loading();

    yield call(ApiImage.editPhotoInfo, action.props);

    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        loading: false,
        errors: ''
      }
    });

    yield call(getPhotoById, { props: { photoId: action.props.data.eventPhoto.id } });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* deletePhotoInfo(action) {
  try {
    yield loading();

    yield call(ApiImage.deletePhotoInfo, action.props.id);

    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        loading: false,
        errors: ''
      }
    });

    yield call(getPhotoById, { props: { photoId: action.props.photoId } });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* getBridgePhotosPagedForCover(action) {
  try {
    yield loading();
    let photoData = yield call(ApiImage.getBridgePhotosPagedForCover, action.props.bridgeId, action.props.data);

    const pagination = {
      number: photoData.number,
      numberOfElements: photoData.numberOfElements,
      totalElements: photoData.totalElements,
      totalPages: photoData.totalPages,
      size: photoData.size
    };

    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        photos: photoData.content,
        photosPagination: pagination,
        loading: false,
        errors: ''
      }
    })

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }

}

function* editPhotoNotes(action) {
  try {
    yield loading();

    yield call(ApiImage.editPhotoNotes, action.props);

    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        loading: false,
        errors: ''
      }
    });


    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* generateZipAndSendEmailPhoto(action) {
  try {
    yield loading();

    yield call(ApiImage.generateZipAndSendEmailPhoto, action.props);

    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showToastAsSuccess("Photo Download Successful", "Download link will be sent to your E-mail."));
    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}


function* ClearAllStructureTypes(action) {
  try {
    yield loading();

    yield call(ApiImage.ClearAllStructureTypes, action.props);

    // yield put({
    //   type: actionTypes.CHANGE_IMAGE_PROPS,
    //   props: {
    //     loading: false,
    //     errors: ''
    //   }
    // });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}


function* AddStructureTypesMultiplePhotos(action) {
  try {
    yield loading();

    yield call(ApiImage.AddStructureTypesMultiplePhotos, action.props);

    // yield put({
    //   type: actionTypes.CHANGE_IMAGE_PROPS,
    //   props: {
    //     loading: false,
    //     errors: ''
    //   }
    // });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* eventPhotoRotate(action) {
  try {
    yield loading();

    yield call(ApiImage.eventPhotoRotate, action.props);

    yield put({
      type: actionTypes.CHANGE_IMAGE_PROPS,
      props: {
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

/*
 * Watcher
 */
function* ImageWatcher() {
  yield all([
    // image
    takeLatest(actionTypes.SAGA_FILE_ASSETEVENT_UPLOAD, uploadAssetEventPhoto),
    takeLatest(actionTypes.SAGA_FILE_ASSET_UPLOAD, uploadAssetFile),
    takeLatest(actionTypes.SAGA_FILE_ASSET_DOCUMENTS, getFilesAsset),
    takeLatest(actionTypes.SAGA_FILE_ASSET_PHOTOS, getPhotosAsset),
    takeLatest(actionTypes.SAGA_FILE_ASSET_PHOTOS_PAGED, getPhotosAssetPaged),
    takeLatest(actionTypes.SAGA_FILE_BRIDGE_PHOTOS_PAGED, getBridgePhotosPaged),
    takeLatest(actionTypes.SAGA_FILE_ASSET_GET_PHOTO_BY_ID, getPhotoById),
    takeLatest(actionTypes.SAGA_FILE_ASSET_PHOTOTAG, editPhotoTags),
    takeLatest(actionTypes.SAGA_FILE_ASSET_DELETE, deletePhoto),
    takeLatest(actionTypes.SAGA_PHOTO_TAG_DELETE,deletePhotoTags),
    takeLatest(actionTypes.SAGA_FILE_ASSET_ADD_PHOTO_INFO, addPhotoInfo),
    takeLatest(actionTypes.SAGA_FILE_ASSET_EDIT_PHOTO_INFO, editPhotoInfo),
    takeLatest(actionTypes.SAGA_FILE_ASSET_DELETE_PHOTO_INFO, deletePhotoInfo),
    takeLatest(actionTypes.SAGA_FILE_ASSET_GET_PHOTO, getBridgePhotosPagedForCover),
    takeLatest(actionTypes.SAGA_EVENT_PHOTO_NOTES, editPhotoNotes),
    takeLatest(actionTypes.SAGA_EVENT_PHOTO_SEND_EMAIL, generateZipAndSendEmailPhoto),
    takeLatest(actionTypes.SAGA_EVENT_PHOTO_ROTATE, eventPhotoRotate),
    takeLatest(actionTypes.SAGA_EVENT_PHOTO_CLEAR_STRUCTURETYPES, ClearAllStructureTypes),
    takeLatest(actionTypes.SAGA_EVENT_PHOTO_ADD_STRUCTURETYPES_MULTIPLE_PHOTOS, AddStructureTypesMultiplePhotos)
  ]);
}

export default ImageWatcher;
