import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/componentEvent';

export default class ApiComponentEvent {

  // GET 'api/ComponentEvent'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchComponentEvents(text){
    return Utils.genericCall(`${endpoint}/paged?search=${text}`);
  }

  static getComponentEvent(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editComponentEvent(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static editMultipleComponentEvent(data){
    return Utils.genericPost(`${endpoint}/updateMultiple`, data);
  }

  static addComponentEvent(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteComponentEvent(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteMultipleComponentEvent(componentsIdsArray){
    return Utils.genericPost(`${endpoint}/deleteMultiple`,componentsIdsArray);
  }

  static getAllComponentEvent(assemblyEventId) {
    return Utils.genericCall(`${endpoint}/assemblyEvent/${assemblyEventId}`);
  }

  static updateDynamicField(componentEventId, fieldName, newValue) {
    return Utils.genericPut(`${endpoint}/${componentEventId}/field`, {
      fieldName,
      value: newValue
    });
  }

  static updateComponentsOrders (data){
    return Utils.genericPost(`${endpoint}/updateOrders`, data);
  }


  static bulkEditComponentEvent (data){
    return Utils.genericPost(`${endpoint}/bulk`, data);
  }

}


