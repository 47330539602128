import * as actionTypes from './actionTypes'

/**
 * @namespace MessageActions
 */


export function getMessages() {
  return { type: actionTypes.SAGA_MESSAGE_ALL };
}

export function loadMessagesPaged(props) {
  return { type: actionTypes.SAGA_MESSAGE_SEARCH, props: props };
}

export function getMessage(props) {
  return { type: actionTypes.SAGA_MESSAGE_GET, props: props };
}

export function editMessage(props) {
  return { type: actionTypes.SAGA_MESSAGE_EDIT, props: props };
}

export function addMessage(props) {
  return { type: actionTypes.SAGA_MESSAGE_ADD, props: props };
}

export function deleteMessage(props) {
  return { type: actionTypes.SAGA_MESSAGE_DELETE, props: props };
}

export function deleteMessageList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_MESSAGE_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

export function getMessageTypes() {
  return { type: actionTypes.SAGA_MESSAGE_TYPES };
}

export function getMessageEvents() {
  return { type: actionTypes.SAGA_MESSAGE_EVENTS };
}

/**
 * @memberof MessageActions
 * @description Change Message props (no saga action)
 */
export function changeMessageProps(props) {
  return { type: actionTypes.CHANGE_MESSAGE_PROPS, props: props };
}

/**
 * @memberof MessageActions
 * @description clears Message props.
 */
export function clearMessageProps() {
  return { type: actionTypes.CLEAR_MESSAGE_PROPS };
}

/**
 * @memberof MessageActions
 * @description Clears Message errors.
 */
export function clearMessageErrors() {
  return { type: actionTypes.CLEAR_MESSAGE_ERRORS };
}

/**
 * @memberof MessageActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations for messages.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadMessageOrganizations() {
  return { type: actionTypes.SAGA_MESSAGE_LOAD_ORGANIZATIONS };
}
