import * as actionTypes from './actionTypes'

/**
 * @namespace ClientActions
 */

export function getClients() {
  return { type: actionTypes.SAGA_CLIENT_ALL };
}

export function getAllClients() {
  return {
    type: actionTypes.SAGA_CLIENTS_ALL
  };
}

export function searchClients(props) {
  return { type: actionTypes.SAGA_CLIENT_SEARCH, props: props };
}

export function searchClientsAdmin(props) {
  return { type: actionTypes.SAGA_CLIENT_SEARCH_ADMIN, props: props };
}

/**
 * @memberof ClientActions
 * @description Loads a client.
 *
 * If you want to load a client by an ID, use
 * @getClientById
 *
 * @param {*} props
 * @returns a Redux action.
 */
export function getClient(props) {
  return { type: actionTypes.SAGA_CLIENT_GET, props: props };
}

export function editClient(props) {
  return { type: actionTypes.SAGA_CLIENT_EDIT, props: props };
}

export function addClient(props) {
  return { type: actionTypes.SAGA_CLIENT_ADD, props: props };
}

export function deleteClient(props) {
  return { type: actionTypes.SAGA_CLIENT_DELETE, props: props };
}

export function clientInspectionsMapStats(props) {
  return { type: actionTypes.SAGA_CLIENT_INSPECTION_MAP_STATS, props: props };
}

export function clientInspectionsMapPriority(props) {
  return { type: actionTypes.SAGA_CLIENT_INSPECTION_MAP_PRIORITY, props: props };
}

export function inspectionsByClient(props) {
  return { type: actionTypes.SAGA_CLIENT_GET_REPORTS, props: props };
}

export function getClientStats(props) {
  return { type: actionTypes.SAGA_CLIENT_GET_STATS, props: props };
}

export function getClientBridgesSections(props) {
  return { type: actionTypes.SAGA_CLIENT_GET_BRIDGES_SECTIONS, props: props };
}

/**
 * @memberof ClientActions
 * @description Change Client props (no saga action)
 */
export function changeClientProps(props) {
  return { type: actionTypes.CHANGE_CLIENT_PROPS, props: props };
}

export function deleteClientChecked(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_CLIENT_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

/**
 * @memberof ClientActions
 * @description Clears Client props
 */
export function clearClientProps() {
  return { type: actionTypes.CLEAR_CLIENT_PROPS };
}

/**
 * @memberof ClientActions
 * @description Loads a client with the given ID.
 *
 * @param {string} clientId the ID of the client to load.
 * @returns a redux action.
 */
export function getClientById(clientId) {
  return getClient({ clientId });
}

/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be
 * dispatched to fetch contacts at the client level.
 *
 * This will trigger a Saga action.
 *
 * @param {string} clientId - the ID of the client.
 * @returns A Redux action that can be dispatched.
 */
export function getClientContactsPaged(clientId, paginationOptions) {
  return {
    type: actionTypes.SAGA_CLIENT_CONTACTS_LOAD_PAGED,
    props: {
      clientId,
      paginationOptions
    }
  }
}

/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be dispatched
 * to edit a client contact.
 *
 * This will trigger a Saga action.
 *
 * @param {string} clientId - the ID of the client.
 * @param {string} contactId - the ID of the contact.
 * @param {object} contactData - the data of the contact to save.
 * @param {function} [callback=()=>{}] - a callback that will be called when
 * the contact has been edited successfully.
 *
 * @returns A Redux action that can be dispatched.
 */
export function editClientContact(clientId, contactId, contactData, callback = () => {}) {
  return {
    type: actionTypes.SAGA_CLIENT_CONTACT_EDIT,
    props: {
      clientId,
      contactId,
      contactData,
      callback
    }
  };
}

/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be dispatched
 * to create a client contact.
 *
 * This will trigger a Saga action.
 *
 * @param {string} clientId - the ID of the client.
 * @param {object} contactData - the data of the contact to create.
 * @param {function} [callback=()=>{}] - a callback that will be called when
 * the contact has been created successfully.
 *
 * @returns A Redux action that can be dispatched.
 */
export function addClientContact(clientId, contactData, callback = () => {}) {
  return {
    type: actionTypes.SAGA_CLIENT_CONTACT_ADD,
    props: {
      clientId,
      contactData,
      callback
    }
  }
}

/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be dispatched
 * to delete a client contact.
 *
 * This will trigger a Saga action.
 *
 * @param {string} contactIdList - the IDs of contacts to delete.
 * @param {function} callback - a callback that will be called when
 * the contacts have been deleted.
 */
export function deleteClientContactList(contactIdList, callback = () => {}) {
  return {
    type: actionTypes.SAGA_CLIENT_CONTACT_DELETE_LIST,
    props: {
      contactIdList,
      callback
    }
  }
}




/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be
 * dispatched to fetch documents at the client level.
 *
 * This will trigger a Saga action.
 *
 * @param {string} clientId - the ID of the client.
 * @returns A Redux action that can be dispatched.
 */
 export function getClientDocumentsPaged(clientId, paginationOptions) {
  return {
    type: actionTypes.SAGA_CLIENT_DOCUMENTS_LOAD_PAGED,
    props: {
      clientId,
      paginationOptions
    }
  }
}

/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be dispatched
 * to edit a client document.
 *
 * This will trigger a Saga action.
 *
 * @param {string} documentId - the ID of the document.
 * @param {object} documentData - the data of the document to save.
 * @param {function} [callback=()=>{}] - a callback that will be called when
 * the document has been edited successfully.
 *
 * @returns A Redux action that can be dispatched.
 */
export function editClientDocument(documentId, documentData, callback = () => {}) {
  return {
    type: actionTypes.SAGA_CLIENT_DOCUMENT_EDIT,
    props: {
      documentId,
      documentData,
      callback
    }
  };
}

/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be dispatched
 * to create a client document.
 *
 * This will trigger a Saga action.
 *
 * @param {string} clientId - the ID of the client.
 * @param {object} documentData - the data of the document to create.
 * @param {function} [callback=()=>{}] - a callback that will be called when
 * the document has been created successfully.
 *
 * @returns A Redux action that can be dispatched.
 */
export function addClientDocument(clientId, documentData, fileData, callback = () => {}) {
  return {
    type: actionTypes.SAGA_CLIENT_DOCUMENT_ADD,
    props: {
      clientId,
      documentData,
      fileData,
      callback
    }
  }
}

/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be dispatched
 * to delete a client document.
 *
 * This will trigger a Saga action.
 *
 * @param {string} documentIdList - the IDs of documents to delete.
 * @param {function} callback - a callback that will be called when
 * the documents have been deleted.
 */
export function deleteClientDocumentList(documentIdList, callback = () => {}) {
  return {
    type: actionTypes.SAGA_CLIENT_DOCUMENT_DELETE_LIST,
    props: {
      documentIdList,
      callback
    }
  }
}

/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be dispatched
 * to load client document types.
 *
 * This will trigger a Saga action.
 *
 * @param {string} organizationId - ID of the organization where
 * the client belongs
 * @returns A Redux action that can be dispatched.
 */
export function loadDocumentTypes(organizationId) {
  return {
    type: actionTypes.SAGA_CLIENT_LOAD_DOCUMENT_TYPES,
    props: { organizationId }
  };
}


/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be dispatched to
 * load available job titles for clients.
 *
 * This will trigger a Saga action.
 *
 * @param {string} organizationId - ID of the organization where
 * the client belongs.
 * @returns A Redux action that can be dispatched.
 */
export function loadClientJobTitles(organizationId) {
  return {
    type: actionTypes.SAGA_CLIENT_LOAD_JOB_TITLES,
    props: {
      organizationId
    }
  };
}

/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be dispatched to
 * load available organizations for clients.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadClientOrganizations() {
  return { type: actionTypes.SAGA_CLIENT_LOAD_ORGANIZATIONS };
}

/**
 * @memberof ClientActions
 * @description Creates a Redux action that can be dispatched to
 * load available clients for clients.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadClientClients() {
  return { type: actionTypes.SAGA_CLIENT_LOAD_CLIENTS };
}

/**
 * @memberof ClientActions
 * @description Creates a aRedux action that can be dispatched to
 * load available contacts for clients.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadClientContacts() {
  return { type: actionTypes.SAGA_CLIENT_LOAD_CONTACTS };
}
