import * as actionTypes from './actionTypes'

/**
 * @namespace CrossingFeatureActions
 */

export function getCrossingFeatures() {
  return { type: actionTypes.SAGA_CROSSINGFEATURE_ALL };
}

export function loadCrossingFeaturesPaged(props) {
  return { type: actionTypes.SAGA_CROSSINGFEATURE_SEARCH, props: props };
}

export function getCrossingFeature(props) {
  return { type: actionTypes.SAGA_CROSSINGFEATURE_GET, props: props };
}

export function editCrossingFeature(props) {
  return { type: actionTypes.SAGA_CROSSINGFEATURE_EDIT, props: props };
}

export function addCrossingFeature(props) {
  return { type: actionTypes.SAGA_CROSSINGFEATURE_ADD, props: props };
}

export function deleteCrossingFeature(props) {
  return { type: actionTypes.SAGA_CROSSINGFEATURE_DELETE, props: props };
}

export function deleteCrossingFeatureList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_CROSSINGFEATURE_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

/**
 * @memberof CrossingFeatureActions
 * @description Change CrossingFeature props (no saga action)
 */
export function changeCrossingFeatureProps(props) {
  return { type: actionTypes.CHANGE_CROSSINGFEATURE_PROPS, props: props };
}

/**
 * @memberof CrossingFeatureActions
 * @description Clears Crossing Feature props.
 */
export function clearCrossingFeatureProps() {
  return { type: actionTypes.CLEAR_CROSSING_FEATURE_PROPS }
}

/**
 * @memberof CrossingFeatureActions
 * @description Clears Crossing Features errors messages.
 */
export function clearCrossingFeatureErrors() {
  return { type: actionTypes.CLEAR_CROSSING_FEATURE_ERRORS };
}

/**
 * @memberof CrossingFeatureActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations for Crossin Features.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_CROSSINGFEATURE_LOAD_ORGANIZATIONS };
}
