import { all, call, put, takeLatest,select } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiAssembly from 'tests/mocks/ApiAssembly.mock'
import ApiAssembly from 'apiServices/ApiAssembly'

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The assembly cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_ASSEMBLY_PROPS, props });
}

/*
 * get Assemblys
 */
function* getAssemblys() {
  try {
    yield loading();
    // get all assemblies
    const allAssemblys = yield call(ApiAssembly.getAll);
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        assemblys: allAssemblys,
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchAssemblys(action) {
  try {
    yield loading();
    // search all Assemblys
    const assemblys = yield call(ApiAssembly.searchAssemblys, action.props);
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_SPAN_PROPS, props: {
        assemblys: assemblys.content,
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getBySection(action) {
  try {
    yield loading();
    // get all assemblys by section
    const assemblys = yield call(ApiAssembly.getBySection, action.props);
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        assemblysBySection: assemblys,
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getAssembly(action) {
  try {
    yield loading();
    // get assembly
    const assembly = yield call(ApiAssembly.getAssembly, action.props.assemblyId);
    yield put({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: assembly.assemblyName,
        id: assembly.id,
        type: "assembly"
      }
    });
    if (Object.keys(action.props).length > 1)
      yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 4, ids: action.props, last: "assembly" } });
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        assembly: assembly,
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* editAssembly(action) {
  try {
    yield loading();
    let props = action.props;
    if (action.props.loadAgain)
      props = props.props;
    // update  Assembly
    let assembly = yield call(ApiAssembly.editAssembly, props);
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        newAssembly: assembly,
      }
    });
    if (action.props.loadAgain)
      yield getAllAssembliesBySectionAndStructureType({ props: action.props.params })
  } catch (error) {
    yield loading(false, error);
  }
}

function* addAssembly(action) {
  try {
    yield loading();
    let props = action.props;
    if (action.props.loadAgain)
      props = props.props;
    // add  Assembly
    let assembly = yield call(ApiAssembly.addAssembly, props);
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        newAssembly: assembly,
      }
    });
    if (action.props.loadAgain) {
      action.props.params.structureTypeId = assembly.assemblyType.structureType.id;
      yield getAllAssembliesBySectionAndStructureType({ props: action.props.params })
    }
  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteAssembly(action) {
  try {
    yield loading();
    // delete  Assembly
    yield call(ApiAssembly.deleteAssembly, action.props.id);
    yield getAllAssembliesBySectionAndStructureType({ props: action.props });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getAllByEventIdAndAssembly(action) {
  try {
    yield loading();
    let assemblyGroup;
    /*if (action.props.priorities) {
      assemblyGroup = yield call(ApiAssembly.getAllByEventIdAndAssembly, action.props.assetEventId, action.props.priorities);
    } else {
      assemblyGroup = yield call(ApiAssembly.getAllByEventIdAndAssembly, action.props.assetEventId);
    }*/

    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        assemblyGroup: assemblyGroup,
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getAllByAssembly(action) {
  try {
    yield loading();
    const assemblyGroupOnlyLastCompleted = yield call(ApiAssembly.getAllByAssembly, action.props.assetId);

    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        assemblyGroupOnlyLastCompleted: assemblyGroupOnlyLastCompleted,
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getAllFields(action) {
  try {
    let loadedFields = yield select(store => store.assembly.allFields)
    if (loadedFields.length === 0) {
      yield loading();
      // get all assembly fields
      const allFields = yield call(ApiAssembly.getAllFields, action.props);
      // update state for assemblies
      yield put({
        type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
          allFields: allFields,
        }
      });
    }
  } catch (error) {
    yield loading(false, error);
  }
}

function* getAllAssembliesBySectionAndStructureType(action) {
  try {
    yield loading();
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        assemblys: [],
      }
    });
    // get all assembly fields
    const allAssemblys = yield call(ApiAssembly.getAllAssembliesBySectionAndStructureType, action.props.sectionId, action.props.structureTypeId);
    // update state for assemblies
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        assemblys: allAssemblys,
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getAllAssemblysRecursively(action) {
  try {
    yield loading();
    // get all assembly fields
    const allAssemblys = yield call(ApiAssembly.getAllAssemblysRecursively, action.props.sectionId, action.props.structureTypeId);
    // update state for assemblies
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        assemblysForForm: allAssemblys,
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* addMultiAssembly(action) {
  try {
    yield loading();
    let props = action.props;
    if (action.props.loadAgain)
      props = props.props;
    // add  Assembly
    let assembly = yield call(ApiAssembly.addMultiAssembly, props);
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        newAssembly: assembly,
      }
    });
    if (action.props.loadAgain) {
      action.props.params.structureTypeId = assembly[0].assemblyType.structureType.id;
      action.props.params.sectionId = action.props.params.sectionId.id
      yield getAllAssembliesBySectionAndStructureType({ props: action.props.params })
    }
  } catch (error) {
    yield loading(false, error);
  }
}

function* editMultiAssembly(action) {
  try {
    yield loading();
    let props = action.props;
    if (action.props.loadAgain)
      props = props.props;
    // add  Assembly
    let assembly = yield call(ApiAssembly.editMultiAssembly, props);
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        newAssembly: assembly,
      }
    });
    if (action.props.loadAgain) {
      action.props.params.structureTypeId = assembly[0].assemblyType.structureType.id;
      action.props.params.sectionId = action.props.params.sectionId.id
      yield getAllAssembliesBySectionAndStructureType({ props: action.props.params })
    }
  } catch (error) {
    yield loading(false, error);
  }
}

function* getStructureAssemblyTree(action) {
  try {
    yield loading();
    let assemblies = yield call(ApiAssembly.getStructureAssemblyTree, action.props.props);

    let newAssemblyArray = assemblies.map((element, index) => {
      element.assembliesName = { name: element.assemblyName, id: element.assemblyType.id }
      if (element.accessType && element.accessType !== null)
        element.accessType = element.accessType.id
      element.No = index + 1
      if (element.components) {
        element.components = element.components.map(component => {
          component.componentTypeName = component.componentType.name
          component.fields = component.componentType.fields
          if (component.items)
            component.items = component.items.map(item => {
              item.fields = item.itemType.fields
              item.componentName = component.componentName
              return item
            })
          return component
        })
      }
      return element
    })

    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        assembliesArray: newAssemblyArray,
      }
    });
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        assembliesEventArray: newAssemblyArray,
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield loading(false);
  } catch (error) {
    yield loading(false, error);
  }
}

function* addAssemblyToAsset(action) {
  try {
    yield loading();
    // delete asset
    yield call(ApiAssembly.addAssemblyToAsset, action.props);

    // update state for assembly
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        loading: false
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}


function* addAssemblyToAssetWithOrder(action) {
  try {
    yield loading();
    // delete asset
    yield call(ApiAssembly.addAssemblyToAssetWithOrder, action.props);

    // update state for assembly
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        loading: false
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* addSubstructuresToAssetWithOrder(action) {
  try {
    yield loading();
    // delete asset
    yield call(ApiAssembly.addSubstructuresToAssetWithOrder, action.props);

    // update state for assembly
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        loading: false
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}


function* deleteMultipleWithOrder(action) {
  try {
    yield loading();
    // delete asset
    yield call(ApiAssembly.deleteMultipleWithOrder, action.props);

    // update state for assembly
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        loading: false
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}


function* deleteStructuresTypes(action) {
  try {
    yield loading();
    // delete asset
    yield call(ApiAssembly.deleteStructuresTypes, action.props);

    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ASSEMBLY_ALL, getAssemblys),
    takeLatest(actionTypes.SAGA_ASSEMBLY_GET, getAssembly),
    takeLatest(actionTypes.SAGA_ASSEMBLY_SEARCH, searchAssemblys),
    takeLatest(actionTypes.SAGA_ASSEMBLY_EDIT, editAssembly),
    takeLatest(actionTypes.SAGA_ASSEMBLY_ADD, addAssembly),
    takeLatest(actionTypes.SAGA_SECTION_ASSEMBLIES, getBySection),
    takeLatest(actionTypes.SAGA_ASSEMBLY_DELETE, deleteAssembly),
    takeLatest(actionTypes.SAGA_ASSEMBLY_EVENT, getAllByEventIdAndAssembly),
    takeLatest(actionTypes.SAGA_ASSEMBLY, getAllByAssembly),
    takeLatest(actionTypes.SAGA_ASSEMBLY_ALL_FIELDS, getAllFields),
    takeLatest(actionTypes.SAGA_ASSEMBLY_ALL_BY_SECTION_STRUCTURETYPE, getAllAssembliesBySectionAndStructureType),
    takeLatest(actionTypes.SAGA_ASSEMBLY_ALL_RECURSIVELY, getAllAssemblysRecursively),
    takeLatest(actionTypes.SAGA_ASSEMBLY_ADD_MULTI, addMultiAssembly),
    takeLatest(actionTypes.SAGA_ASSEMBLY_EDIT_MULTI, editMultiAssembly),
    takeLatest(actionTypes.SAGA_ASSEMBLY_GET_STRUCTURE_TREE, getStructureAssemblyTree),
    takeLatest(actionTypes.SAGA_ADD_ASSEMBLIY_TO_ASSET,addAssemblyToAsset),
    takeLatest(actionTypes.SAGA_ADD_ASSEMBLIY_TO_ASSET_BY_ORDER,addAssemblyToAssetWithOrder),
    takeLatest(actionTypes.SAGA_ADD_SUBSTRUCTURES_TO_ASSET_BY_ORDER,addSubstructuresToAssetWithOrder),
    takeLatest(actionTypes.SAGA_DELETE_ASSEMBLIY_TO_ASSET_BY_ORDER,deleteMultipleWithOrder),
    takeLatest(actionTypes.SAGA_SECTION_DELETE_ASSEMBLIES,deleteStructuresTypes),
  ]);
}

export default AuthWatcher;
