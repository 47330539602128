import { all, call, put, takeLatest,select } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiBridge from 'apiServices/ApiBridge'
import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast } from '../actions/ToastActions';
import { cloneDeep } from 'lodash';

const recordTypeName = "bridge";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The bridge cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_BRIDGE_PROPS, props });
}

/*
 * get Bridges
 */
function* getBridges(action) {
  try {
    yield loading();
    // get all bridges
    const allBridges = yield call(ApiBridge.getAll, action.props);
    // update state for bridges
    yield put({
      type: actionTypes.CHANGE_BRIDGE_PROPS, props: {
        bridges: allBridges,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getBridge(action) {
  try {
    yield loading();
    // get bridge
    const bridge = yield call(ApiBridge.getBridge, action.props.bridgeId);

    yield put({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: bridge.idString,
        id: bridge.id,
        type: "bridge"
      }
    });

    if (Object.keys(action.props).length > 1) {
      yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 3, ids: action.props } });
    }

    // update state for bridges
    yield put({
      type: actionTypes.CHANGE_BRIDGE_PROPS, props: {
        bridge: bridge,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* getBySubdivision(action) {
  try {
    yield loading();
    yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 3, ids: action.props } });

    // get all SubDivision Bridges first page
    yield searchBySubdivision({ props: { id: action.props.subdivisionId, search: '', page: 0 } });

  } catch (error) {
    yield loading(false, error);
  }
}

function* searchBySubdivision(action) {
  try {
    yield loading();

    // get all SubDivision Bridges
    const subdivisionBridges = yield call(ApiBridge.searchBySubdivision, action.props);

    // update state for Bridges
    yield put({
      type: actionTypes.CHANGE_BRIDGE_PROPS, props: {
        bridges: subdivisionBridges.content,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* searchBridges(action) {
  try {
    yield loading();

    // get all bridges
    const bridges = yield call(ApiBridge.searchBridges, action.props);

    // update state for bridges
    yield put({
      type: actionTypes.CHANGE_BRIDGE_PROPS, props: {
        bridges: bridges.content,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* editBridge(action) {
  try {
    yield loading();
    let bridge = cloneDeep(yield select(store => store.asset.newAsset));
    // update  bridge
    let editedBridge = yield call(ApiBridge.editBridge, action.props);

    // update state for bridges
    yield put({
      type: actionTypes.CHANGE_BRIDGE_PROPS, props: {
        bridge: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordUpdatedToast(recordTypeName));
    // yield getBridge({ props: { bridgeId: action.props.id } });


    if(bridge){
      bridge.sections = editedBridge.sections
      yield put({
        type: actionTypes.CHANGE_ASSET_PROPS, props: {
          newAsset: bridge,
          loading: false,
          errors: ''
        }
      });
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* addBridge(action) {
  try {
    yield loading();

    // add  bridge
    yield call(ApiBridge.addBridge, action.props);

    // update state for Bridges
    yield put({
      type: actionTypes.CHANGE_BRIDGE_PROPS, props: {
        bridge: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordAddedToast(recordTypeName));
    yield searchBySubdivision({ props: { id: action.props.subdivision.id, search: '', page: 0 } });

  } catch (error) {
    yield loading(false, error);
  }

}

function* searchByClient(action) {
  try {
    yield loading();

    // get all Client Bridges
    const clientBridges = yield call(ApiBridge.searchByClient, action.props);

    // update state for Bridges
    yield put({
      type: actionTypes.CHANGE_BRIDGE_PROPS, props: {
        bridges: clientBridges.content || [],
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* searchByRailroads(action) {
  try {
    yield loading();

    // get all Railroads Bridges
    const railroadsBridges = yield call(ApiBridge.searchByRailroads, action.props);

    // update state for Bridges
    yield put({
      type: actionTypes.CHANGE_BRIDGE_PROPS, props: {
        bridges: railroadsBridges.content,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* searchBySubdivisions(action) {
  try {
    yield loading();

    // get all Subdivisions Bridges
    const subdivisionsBridges = yield call(ApiBridge.searchBySubdivisions, action.props);

    // update state for Bridges
    yield put({
      type: actionTypes.CHANGE_BRIDGE_PROPS, props: {
        bridges: subdivisionsBridges.content,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteBridge(action) {
  try {
    yield loading();

    // delete bridge
    yield call(ApiBridge.deleteBridge, action.props.id);

    yield put(showRecordDeletedToast(recordTypeName));
    yield searchBySubdivision({ props: { id: action.props.subdivision.id, search: '', page: 0 } });

  } catch (error) {
    yield loading(false, error);
  }

}

function* getSectionsByBridgeId(action) {
  try {
    yield loading();

    // get all sections by bridge
    const sections = yield call(ApiBridge.getSectionsByBridgeId, action.props);

    // update state for bridges
    yield put({
      type: actionTypes.CHANGE_BRIDGE_PROPS, props: {
        sectionOptions: sections,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    yield loading(false, error);
  }
}


/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_BRIDGE_ALL, getBridges),
    takeLatest(actionTypes.SAGA_BRIDGE_GET, getBridge),
    takeLatest(actionTypes.SAGA_BRIDGE_SEARCH, searchBridges),
    takeLatest(actionTypes.SAGA_BRIDGE_EDIT, editBridge),
    takeLatest(actionTypes.SAGA_BRIDGE_ADD, addBridge),
    takeLatest(actionTypes.SAGA_SUBDIVISION_BRIDGES, getBySubdivision),
    takeLatest(actionTypes.SAGA_SUBDIVISION_BRIDGES_SEARCH, searchBySubdivision),
    takeLatest(actionTypes.SAGA_CLIENT_BRIDGES_SEARCH, searchByClient),
    takeLatest(actionTypes.SAGA_BRIDGE_DELETE, deleteBridge),
    takeLatest(actionTypes.SAGA_BRIDGE_BY_RAILROADS, searchByRailroads),
    takeLatest(actionTypes.SAGA_BRIDGE_BY_SUBDIVISIONS, searchBySubdivisions),
    takeLatest(actionTypes.SAGA_BRIDGE_SECTIONS_BY_BRIDGE, getSectionsByBridgeId)
  ]);
}

export default AuthWatcher;
