import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import App from './App';
import ErrorHandler from './errorHandler';
import { unregister } from "./registerServiceWorker";
// import "react-table/react-table.css";
import 'react-toastify/dist/ReactToastify.css';
import LogRocket from 'logrocket';

if(process.env.REACT_APP_LOGROCKET_KEY)
  LogRocket.init(`${process.env.REACT_APP_LOGROCKET_KEY}`);

ReactDOM.render(
    <Provider store={store}>
      <ErrorHandler>
        <App />
      </ErrorHandler>
    </Provider>
    , document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}

unregister();
