import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiClaim from 'apiServices/ApiClaim'

import { clearClaimErrors } from '../actions/ClaimActions';
import { showRecordUpdatedToast } from '../actions/ToastActions';

const recordType = "claim";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The claim cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_CLAIM_PROPS, props });
}

/*
 * get DualList
 */

function* getClaims() {
  try {
    yield loading();
    // get all DualList
    const claims = yield call(ApiClaim.getAll);
    yield put({
      type: actionTypes.CHANGE_CLAIM_PROPS, props: {
        claims: claims,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* searchClaims(action) {
  try {
    yield loading();
    // get all DualList
    const claims = yield call(ApiClaim.searchClaims, action.props);
    const pagination = {
      number: claims.number,
      numberOfElements: claims.numberOfElements,
      totalElements: claims.totalElements,
      totalPages: claims.totalPages,
      size: claims.size
    };
    // update state for claims
    yield put({
      type: actionTypes.CHANGE_CLAIM_PROPS, props: {
        claims: claims.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getClaim(action) {
  try {
    yield loading();
    // get  Claim
    const claim = yield call(ApiClaim.getClaim, action.props.claimId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: claim.name,
        id: claim.id,
        type: "claim"
      }
    });
    // update state for claims
    yield put({
      type: actionTypes.CHANGE_CLAIM_PROPS, props: {
        claim: claim,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editClaim(action) {

  yield put(clearClaimErrors());

  try {
    yield loading();
    // update  Claim
    yield call(ApiClaim.editClaim, action.props);
    // update state for claims
    yield put({
      type: actionTypes.CHANGE_CLAIM_PROPS, props: {
        claim: {},
        loading: false,
        errors: ''
      }
    });
    yield put(showRecordUpdatedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_CLAIM_ALL, getClaims),
    takeLatest(actionTypes.SAGA_CLAIM_SEARCH, searchClaims),
    takeLatest(actionTypes.SAGA_CLAIM_GET, getClaim),
    takeLatest(actionTypes.SAGA_CLAIM_EDIT, editClaim)
  ]);
}

export default AuthWatcher;
