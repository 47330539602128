import socketjs from 'sockjs-client';
import { API_SERVER } from 'config/constants';
import EventEmmiter from 'eventemitter3';

// const _messages = [];
// let _callback = () => {};
let socket = null;
// let subscribedChannels = [];
let connected = false;
let nonRemovableChannels = [];

export const eventEmitter = new EventEmmiter();

export function startSession(authenticationToken) {
  socket = new socketjs(API_SERVER + "/websocket?token=" + authenticationToken);

  socket.onmessage = (event) => {
    let message = JSON.parse(event.data)

    if (message.payload !== "") {
      message.payload = JSON.parse(message.payload);
    }

    eventEmitter.emit("MESSAGE_RECEIVED", message);
  };
  socket.onopen = () => {
    eventEmitter.emit("CONNECTION_OPEN");
    connected = true;
  };
  socket.onclose = () => {socket = null;};
}

export function endSession() {
  socket.close();
}

export function subscribeToChannels(channelName, removable = true) {

  if (socket === null) {
    return;
  }

  if (!removable) {
    nonRemovableChannels = nonRemovableChannels.filter(ch => ch !== channelName);
    nonRemovableChannels.push(channelName);
  }

  const subscribe = () => socket.send(JSON.stringify({
                      type: "SUBSCRIBE_TO_CHANNEL",
                      subscription: {
                        channelName
                      }
                    }));

  if (!connected) {
    eventEmitter.once("CONNECTION_OPEN", subscribe);
  } else {
    subscribe();
  }
}

export function removeChannelSubscriptions() {
  if (socket === null || !connected) {
    return;
  }

  socket.send(JSON.stringify({
    type: "REMOVE_SUBSCRIPTIONS",
    keep: nonRemovableChannels
  }));
}
