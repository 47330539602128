import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/jobTitle';

export default class ApiJobTitle {

  // GET 'api/jobTitle'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchJobTitle(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getJobTitle(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editJobTitle(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addJobTitle(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteJobTitle(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteJobTitleList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }
}
