import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiDirectionalLoading from 'tests/mocks/ApiDirectionalLoading.mock'
import ApiDirectionalLoading from 'apiServices/ApiDirectionalLoading'
import CookieManager from 'common/cookieManager'


/*
 * get DirectionalLoadings
 */
function* getDirectionalLoadings(){
  // get directional loadings
  const allSections = yield call(ApiDirectionalLoading.getAll);
  // update state for sections
  yield put({ type: actionTypes.CHANGE_DIRECTIONALLOADING_PROPS, props:{
    directionalLoadings: allSections,
  }});
}

function* searchDirectionalLoadings(action){
  // get all DirectionalLoadings
  const directionalLoadings = yield call(ApiDirectionalLoading.searchDirectionalLoadings, action.props);
  const pagination = { number: directionalLoadings.number,
	                     numberOfElements: directionalLoadings.numberOfElements,
	                     totalElements: directionalLoadings.totalElements,
	                     totalPages: directionalLoadings.totalPages,
	                     size: directionalLoadings.size};
  // update state for directionalLoadings
  yield put({ type: actionTypes.CHANGE_DIRECTIONALLOADING_PROPS, props:{
    directionalLoadings: directionalLoadings.content,
    pagination: pagination
  }});
}

function* getDirectionalLoading(action){
  // get  DirectionalLoading
  const directionalLoading = yield call(ApiDirectionalLoading.getDirectionalLoading, action.props.directionalLoadingId);
  yield putResolve({type: actionTypes.CHANGE_NAMES_PROPS, props:{
    name: directionalLoading.name,
    id: directionalLoading.id,
    type: "directionalLoading"
  }});
  // update state for directionalLoadings
  yield put({ type: actionTypes.CHANGE_DIRECTIONALLOADING_PROPS, props:{
    directionalLoading: directionalLoading,
  }});
}

function* editDirectionalLoading(action){
  // update  DirectionalLoading
  yield call(ApiDirectionalLoading.editDirectionalLoading, action.props);
  // update state for directionalLoadings
  yield put({ type: actionTypes.CHANGE_DIRECTIONALLOADING_PROPS, props:{
    directionalLoading: {},
  }});
  const cookieStored = CookieManager.getCookiesForTable("DirectionalLoadings")
  yield searchDirectionalLoadings(CookieManager.getObjectForSearch(cookieStored));
}

function* addDirectionalLoading(action){
  // add  DirectionalLoading
  yield call(ApiDirectionalLoading.addDirectionalLoading, action.props);
  // update state for directionalLoadings
  yield put({ type: actionTypes.CHANGE_DIRECTIONALLOADING_PROPS, props:{
    directionalLoading: {},
  }});
  const cookieStored = CookieManager.getCookiesForTable("DirectionalLoadings")
  yield searchDirectionalLoadings(CookieManager.getObjectForSearch(cookieStored));
}

function* deleteDirectionalLoading(action){
  // delete  DirectionalLoading
  yield call(ApiDirectionalLoading.deleteDirectionalLoading, action.props);
  const cookieStored = CookieManager.getCookiesForTable("DirectionalLoadings")
  yield searchDirectionalLoadings(CookieManager.getObjectForSearch(cookieStored));
}
/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_DIRECTIONALLOADING_ALL, getDirectionalLoadings),
    takeLatest(actionTypes.SAGA_DIRECTIONALLOADING_SEARCH, searchDirectionalLoadings),
    takeLatest(actionTypes.SAGA_DIRECTIONALLOADING_GET, getDirectionalLoading),
    takeLatest(actionTypes.SAGA_DIRECTIONALLOADING_EDIT, editDirectionalLoading),
    takeLatest(actionTypes.SAGA_DIRECTIONALLOADING_ADD, addDirectionalLoading),
    takeLatest(actionTypes.SAGA_DIRECTIONALLOADING_DELETE, deleteDirectionalLoading),
  ]);
}

export default AuthWatcher;
