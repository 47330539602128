import React, { Fragment } from "react";
import { withFormik } from "formik";
import Api from "common/Api";
import * as Yup from "yup";
import { setLocale } from "yup";
import {
  FaTasks,
  FaCheckCircle,
  FaCheck,
  FaClock,
  FaMapMarkerAlt,
  FaGlobe
} from "react-icons/fa";
import {
  Col,
  UncontrolledPopover,
  PopoverBody,
  ListGroup,
  ListGroupItem,
  Row,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledButtonDropdown
} from "reactstrap";
import moment from "moment";
import { isEmpty } from "lodash";
import Cookies from "universal-cookie";
import emptyimage from 'img/emptyimage.png';

/**
 * @namespace Utils
 */

const cookies = new Cookies();

const apiService = new Api({ timeout: 10000 });

async function genericPreviewAsync(endpoint, data) {
  try {
    const headers = Utils.getHeadersReport();
    await apiService.preview(endpoint, { data, headers });
  } catch (error) {
    console.log("Api.PREVIEW() error", error);
    throw error;
  }
}

async function genericPreviewByTypeFileAsync(type, endpoint, data) {
  try {
    const headers = Utils.getHeadersReportByType(type);
    await apiService.previewByType(endpoint, { data, headers }, type);
  } catch (error) {
    console.log("Api.PREVIEW() error", error);
    throw error;
  }
}

export default class Utils {
  static getHeaders() {
    let token = localStorage.getItem("auth.token");
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    };
  }

  static getHeadersReport() {
    let token = localStorage.getItem("auth.token");
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf"
    };
  }

  static handleImageError() {
    const error = ({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = emptyimage;
    }
    return error
  }


  static getHeadersReportByType(type) {
    let token = localStorage.getItem("auth.token");
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/" + type,
      Accept: "application/" + type
    };
  }

  static getHeadersDownloadReport() {
    let token = localStorage.getItem("auth.token");
    return {
      Authorization: "Bearer " + token
    };
  }

  static genericBreadCrumbs(level, names, edit, levels, levelIds) {
    edit = edit || false;
    var breadcrumbs = [];
    let result = {};
    let url = "";
    for (var i = 0; i < level; i++) {
      url =
        url + "/" + (i % 2 === 0 ? levels[i / 2].url : levelIds[(i - 1) / 2]);
      var breadcrumb = {
        name: i % 2 === 0 ? levels[i / 2].label : names[(i - 1) / 2],
        active: true
      };
      if (i < level - 1 || edit) {
        breadcrumb["url"] = url;
        breadcrumb["active"] = false;
      }
      breadcrumbs.push(breadcrumb);
      result[
        (i % 2 === 0
          ? levels[i / 2].url
          : levels[(i - 1) / 2].url.substring(
            0,
            levels[(i - 1) / 2].url.length - 1
          )) + "Url"
      ] = url;
    }
    result["breadcrumbs"] = breadcrumbs;
    return result;
  }

  static getBreadCrumbsEvent(params, level, names, edit) {
    edit = edit || false;
    names = [
      names.client.name,
      names.railroad.name,
      names.subdivision.name,
      names.bridge.name,
      names.event.name
    ];
    var { clientId, railroadId, subdivisionId, bridgeId, eventId } = params;
    var levels = [
      { label: "clients", url: "clients" },
      { label: "railroads", url: "railroads" },
      { label: "subdivisions", url: "subdivisions" },
      { label: "bridges", url: "bridges" },
      { label: "events", url: "events" }
    ];
    var levelIds = [clientId, railroadId, subdivisionId, bridgeId, eventId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsAssetEvent(params, level, names, edit) {
    edit = edit || false;
    names = [
      names.client.name,
      names.railroad.name,
      names.subdivision.name,
      names.bridge.name,
      names.assetEvent.name,
      names.sectionEvent.name
    ];
    var {
      clientId,
      railroadId,
      subdivisionId,
      bridgeId,
      assetEventId,
      sectionEventId
    } = params;
    var levels = [
      { label: "clients", url: "clients" },
      { label: "railroads", url: "railroads" },
      { label: "subdivisions", url: "subdivisions" },
      { label: "bridges", url: "bridges" },
      { label: "Asset Events", url: "assetEvents" },
      { label: "Section Events", url: "sectionEvents" }
    ];
    var levelIds = [
      clientId,
      railroadId,
      subdivisionId,
      bridgeId,
      assetEventId,
      sectionEventId
    ];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbs(params, level, names, edit, last, lastName) {
    edit = edit || false;
    last = last || "assembly";
    names = [
      names.client.name,
      names.railroad.name,
      names.subdivision.name,
      names.asset.name,
      names[last].name
    ];
    var { clientId, railroadId, subdivisionId, assetId } = params;
    var lastId = params[last + "Id"];
    var levels = [
      { label: "clients", url: "clients" },
      { label: "railroads", url: "railroads" },
      { label: "subdivisions", url: "subdivisions" },
      { label: "assets", url: "assets" },
      { label: lastName + "s", url: last + "s" }
    ];
    var levelIds = [clientId, railroadId, subdivisionId, assetId, lastId];
    var breadcrumbs = this.genericBreadCrumbs(
      level,
      names,
      edit,
      levels,
      levelIds
    );
    if (breadcrumbs.breadcrumbs.length >= 11) {
      delete breadcrumbs.breadcrumbs[10]["url"];
      delete breadcrumbs.breadcrumbs[9];
    }
    return breadcrumbs;
  }

  static getBreadCrumbsSubdivisionClient(
    params,
    level,
    names,
    edit,
    last,
    lastName
  ) {
    edit = edit || false;
    names = [names.client.name, names.subdivision.name];
    var { clientId, subdivisionId } = params;
    var levels = [
      { label: "clients", url: "clients" },
      { label: "subdivisions", url: "subdivisions" }
    ];
    var levelIds = [clientId, subdivisionId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsUser(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "users", url: "users" }];
    var levelIds = [params.userId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsContact(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Contacts", url: "contacts" }];
    var levelIds = [params.contactId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsOrganization(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Organizations", url: "organizations" }];
    var levelIds = [params.clientId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsCondition(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Conditions", url: "conditions" }];
    var levelIds = [params.conditionId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsLocation(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Locations", url: "locations" }];
    var levelIds = [params.locationId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsMessage(params, level, names, edit) {
    edit = edit || false;
    names = [names.message.name, names.messageField.name];
    var { messageId, messageFieldId } = params;
    var levels = [
      { label: "messages", url: "messages" },
      { label: "messageFields", url: "messageFields" }
    ];
    var levelIds = [messageId, messageFieldId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsTrip(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "trips", url: "trips" }];
    var levelIds = [params.tripId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsRole(params, level, names, edit) {
    edit = edit || false;
    names = [names.role.name];
    var { roleId } = params;
    var levels = [{ label: "roles", url: "roles" }];
    var levelIds = [roleId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsAssemblyType(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Assembly Types", url: "assemblyTypes" }];
    var levelIds = [params.assemblyTypeId];
    names = [names.assemblyType.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsComponentType(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Component Types", url: "componentTypes" }];
    var levelIds = [params.componentTypeId];
    names = [names.componentType.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsComponentGroup(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Component Groups", url: "componentGroups" }];
    var levelIds = [params.componentGroupId];
    names = [names.componentGroup.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsItemGroup(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Item Groups", url: "itemGroups" }];
    var levelIds = [params.itemGroupId];
    names = [names.itemGroup.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsItemType(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Item Types", url: "itemTypes" }];
    var levelIds = [params.itemTypeId];
    names = [names.itemType.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsStructureType(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Structure Types", url: "structureTypes" }];
    var levelIds = [params.structureTypeId];
    names = [names.structureType.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsClaim(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "claims", url: "claims" }];
    var levelIds = [params.claimId];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsImpactType(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Impact Types", url: "impactTypes" }];
    var levelIds = [params.impactTypeId];
    names = [names.impactType.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsAssetType(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Asset Types", url: "assetTypes" }];
    var levelIds = [params.assetTypeId];
    names = [names.assetType.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsCrossingFeature(params, level, names, edit) {
    edit = edit || false;
    var levels = [{ label: "Crossing Feature", url: "crossingFeatures" }];
    var levelIds = [params.crossingFeatureId];
    names = [names.crossingFeature.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsAssetAccess(params, level, names, edit) {
    edit = edit || false;
    var levels = [
      {
        label: "Access Types",
        url: "accessTypes"
      }
    ];
    var levelIds = [params.accessTypeId];
    names = [names.accessType.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static getBreadCrumbsJobTitle(params, level, names, edit) {
    edit = edit || false;
    var levels = [
      {
        label: "Job Titles",
        url: "jobTitles"
      }
    ];
    var levelIds = [params.jobTitleId];
    names = [names.jobTitle.name];
    return this.genericBreadCrumbs(level, names, edit, levels, levelIds);
  }

  static genericCall(endpoint) {
    try {
      const data = {};
      const headers = Utils.getHeaders();
      return apiService.get(endpoint, { data, headers });
    } catch (error) {
      console.log("Api.GET() error", error);
      return error;
    }
  }

  static genericPost(endpoint, data, headers) {
    try {
      headers = headers || Utils.getHeaders();
      return apiService.post(endpoint, { data, headers });
    } catch (error) {
      console.log("Api.POST() error", error);
      return error;
    }
  }

  static genericPut(endpoint, data) {
    try {
      const headers = Utils.getHeaders();
      return apiService.put(endpoint, { data, headers });
    } catch (error) {
      console.log("Api.PUT() error", error);
      return error;
    }
  }

  static genericDelete(endpoint) {
    try {
      const data = {};
      const headers = Utils.getHeaders();
      return apiService.delete(endpoint, { data, headers });
    } catch (error) {
      console.log("Api.DELETE() error", error);
      return error;
    }
  }

  static genericPreview(endpoint, data) {
    return genericPreviewAsync(endpoint, data).catch(error => {
      throw error;
    });
  }

  static genericPreviewByTypeFile(endpoint, data, type) {
    return genericPreviewByTypeFileAsync(type, endpoint, data).catch(error => {
      throw error;
    });
  }

  static genericDownload(endpoint) {
    try {
      const data = {};
      const headers = Utils.getHeadersDownloadReport();
      return apiService.get(endpoint, { data, headers });
    } catch (error) {
      console.log("Api.GET() error", error);
      return error;
    }
  }

  static genericDownloadPost(endpoint, data) {
    try {
      const headers = Utils.getHeadersDownloadReport();
      return apiService.previewPost(endpoint, { data, headers });
    } catch (error) {
      console.log("Api.Post() error", error);
      return error;
    }
  }

  static genericFormikEnhancer(form, defaultValues, validation, fieldsToDisplayInEditModal) {
    return props => {
      const { initialValues, onSubmit, onCancel, ...otherProps } = props;
      const formValues = Object.assign({}, defaultValues, initialValues, fieldsToDisplayInEditModal);
      const formikEnhancer = withFormik({
        enableReinitialize: false,
        mapPropsToValues: props => {
          let values = Object.assign(
            {},
            formValues,
            props.otherProps.newValues || {}
          );
          return { ...values, onCancel: onCancel };
        },
        mapValuesToPayload: x => x,
        validationSchema: validation,
        handleSubmit: onSubmit,
        validateOnBlur: false,
        validateOnChange: false
      });
      const MyEnhancedForm = formikEnhancer(form);
      return <MyEnhancedForm otherProps={{ ...otherProps }} />;
    };
  }

  static capitalizeFirstLetter(word) {
    if(word){
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return
  }

  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static sameAddress(addres1, addres2) {
    addres1 = this.isJson(addres1)
      ? this.getStringFromLocation(addres1)
      : addres1;
    addres2 = this.isJson(addres2)
      ? this.getStringFromLocation(addres2)
      : addres2;
    return addres1 === addres2;
  }

  static getStringFromLocation(locationJson) {
    let result = "";
    if (locationJson && locationJson !== "") {
      try {
        let location = JSON.parse(locationJson);
        // let i = 0;
        // if (location.address_components.length === 6)
        //   i = 1;
        // result = location.address_components[i].long_name;
        // if (location.address_components[i + 1])
        //   result += ", " + location.address_components[i + 1].long_name;
        // if (location.address_components[i + 2])
        //   result += ", " + location.address_components[i + 2].long_name;
        result = location.formatted_address;
      } catch (error) { }
    }
    return result;
  }

  static renderOptions(itemsArray, withoutEmpty) {
    itemsArray = itemsArray || [];
    let result = itemsArray.map((item, index) => (
      <option key={index + 1} value={item.id}>
        {item.name}
      </option>
    ));
    if (!withoutEmpty) result.unshift(<option key={0} value={""}></option>);
    return result;
  }

  static renderOptionsUsers(itemsArray, withoutEmpty) {
    itemsArray = itemsArray || [];
    let result = itemsArray.map(item => (
      <option key={item.id} value={item.id}>
        {item.username}
      </option>
    ));
    if (!withoutEmpty) result.unshift(<option key={0} value={""}></option>);
    return result;
  }

  static renderOptionsEventType(itemsArray, withEmpty) {
    itemsArray = itemsArray || [];
    let result = itemsArray.map(item => (
      <option key={item.id} value={item.id}>
        {item.description}
      </option>
    ));
    if (withEmpty) result.unshift(<option key={0} value={""}></option>);
    return result;
  }

  static getValue(objectValue, typeObject) {
    switch (typeObject) {
      case "filterDualList":
        return objectValue.map(e => (e.name ? e.name : e.id)).join(", ");
      case "stringList":
        return objectValue.map(e => e.name).join(", ");
      case "list":
        return objectValue
          .map(e => e.role.name + ": " + e.user.username)
          .join(", ");
      case "select":
        if (objectValue.length > 0)
          return objectValue.map(e => e?.name).join(", ");
        if (objectValue.componentName) return objectValue.componentName;
        return objectValue.name ? objectValue.name : "";
      case "location":
        return this.getStringFromLocation(objectValue);
      default:
        objectValue = objectValue === true ? "Yes" : objectValue;
        objectValue = objectValue === false ? "No" : objectValue;

        if (objectValue?.id && objectValue?.name) {
          return objectValue.name;
        }

        return objectValue;
    }
  }

  static builtUrl(defURl, params) {
    let result = defURl.slice(1);
    Object.entries(params).forEach(entry => {
      result = result.replace(":" + entry[0], entry[1]);
    });
    return result;
  }

  static computeDefaultAsset(allFields) {

    if (!allFields) {
      return {
        defaultValues: {},
        validation: Yup.mixed()
      }
    }

    // eslint-disable-next-line
    setLocale({ number: { min: "It must be greater than ${min}" } });
    let assetFields = allFields;
    let defaultValues = { id: null };
    for (let { name, type } of assetFields) {
      defaultValues[name] =
        type === "number"
          ? 0
          : type === "bool"
            ? false
            : type === "stringList"
              ? []
              : "";
    }

    let shapes = {};
    for (let field of assetFields) {
      let typeObject = field.type;
      let name = field.name;
      let yupValidation = Yup[
        ["location", "select", "lookup", "stringList"].includes(typeObject) ? "string" : typeObject
      ]();
      switch (typeObject) {
        case "select":
          yupValidation = Yup.object()
            .shape({
              id: Yup.string().nullable()
            })
            .nullable();
          break;

        case "lookup":
          yupValidation = Yup.array().of(Yup.string()).nullable();
          break;

        case "stringList":
          continue;

        default: yupValidation = Yup[typeObject === 'location' || typeObject === 'lookup' ? 'string' : typeObject]();
      }
      if (name === "name")
        yupValidation = yupValidation.required("Asset Name is required");
      if (typeObject === "number") yupValidation = yupValidation.min(0).nullable();
      if (typeObject === "location")
        yupValidation = yupValidation.test(
          "json-validation",
          "You have to select an option",
          value => {
            if (value)
              // eslint-disable-next-line
              return /^[\],:{}\s]*$/.test(
                value
                  // eslint-disable-next-line
                  .replace(/\\["\\\/bfnrtu]/g, "@")
                  .replace(
                    // eslint-disable-next-line
                    /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                    "]"
                  )
                  .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
              );
            else return true;
          }
        );
      shapes[name] = yupValidation;
    }
    const validation = Yup.object().shape(shapes);
    return { defaultValues, validation };
  }

  static computeDefaultAssembly(allFields, maxOrder) {
    // eslint-disable-next-line
    setLocale({
      // eslint-disable-next-line
      number: { min: "It must be greater than or equal to ${min}" }
    });
    // eslint-disable-next-line
    setLocale({ number: { max: "It must be less than or equal to ${max}" } });
    let assemblyFields = allFields;
    let defaultValues = { id: null };
    for (let { name, typeObject } of assemblyFields) {
      defaultValues[name] =
        typeObject === "number" ? "" : typeObject === "bool" ? false : "";
      if (name === "order") {
        defaultValues[name] = 1;
      }
    }

    let shapes = {};
    for (let field of assemblyFields) {
      let typeObject = field.type;
      let { name } = field;
      let yupValidation = Yup[
        typeObject === "location" ||
          typeObject === "select" ||
          typeObject === "stringList" ||
          typeObject === "lookup"
          ? "string"
          : typeObject ? typeObject : 'string'
      ]();
      if (name === "assemblyName") {
        yupValidation = yupValidation.required("Assembly Name is required");
      }
      if (typeObject === "number") {
        yupValidation = yupValidation.min(0);
      }
      if (name === "order") {
        yupValidation = yupValidation.min(1).max(maxOrder);
      }
      if (typeObject === "location") {
        yupValidation = yupValidation.test(
          "json-validation",
          "You have to select an option",
          function (value) {
            if (value)
              // eslint-disable-next-line
              return /^[\],:{}\s]*$/.test(
                value
                  // eslint-disable-next-line
                  .replace(/\\["\\\/bfnrtu]/g, "@")
                  .replace(
                    // eslint-disable-next-line
                    /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                    "]"
                  )
                  .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
              );
            else return true;
          }
        );
      }
      shapes[name] = yupValidation;
    }
    const validation = Yup.object().shape(shapes);
    return { defaultValues, validation };
  }

  static getInitialValuesAssembly(formValues, assemblyTypes, allFields) {
    if (formValues != null && assemblyTypes.length > 0) {
      let assemblyFields = allFields;
      let newValues = { id: formValues.id };
      for (let element of assemblyFields) {
        let { name } = element;
        let typeField = element.type;
        newValues[name] =
          formValues[name] ||
          (typeField === "number"
            ? ""
            : typeField === "bool"
              ? false
              : typeField === "stringList"
                ? []
                : "");
        if (typeField === "select") {
          if (formValues[name] && formValues[name] !== "") {
            newValues[name] = formValues[name].id;
          }
        }
        if (typeField === "location") {
          if (formValues[name] && formValues[name] !== "") {
            newValues[name] = this.getStringFromLocation(formValues[name]);
          }
        }
      }
      if (formValues["assemblyType"] && formValues["assemblyType"] !== "")
        newValues["assemblyType"] = formValues["assemblyType"].id;
      return newValues;
    }
    return null;
  }

  static computeDefaultComponent(allFields, maxOrder) {
    // eslint-disable-next-line
    setLocale({ number: { min: "It must be greater than ${min}" } });
    // eslint-disable-next-line
    setLocale({ number: { max: "It must be less than or equal to ${max}" } });
    let componentFields = allFields;
    let defaultValues = { id: null };
    for (let { name, typeObject } of componentFields) {
      defaultValues[name] =
        typeObject === "number" ? "" : typeObject === "bool" ? false : "";
      if (name === "componentOrder") {
        defaultValues[name] = 1;
      }
    }

    let shapes = {};
    for (let field of componentFields) {
      let typeObject = field.type;
      let { name } = field;
      let yupValidation = Yup[
        typeObject === "location" ||
          typeObject === "select" ||
          typeObject === "stringList" ||
          typeObject === "lookup"
          ? "string"
          : typeObject
      ]();
      if (name === "componentName") {
        yupValidation = yupValidation.required("Component Name is required");
      }

      if (typeObject === "number") {
        yupValidation = yupValidation.min(0);
      }

      if (name === "componentOrder") {
        yupValidation = yupValidation.min(1).max(maxOrder);
      }

      if (typeObject === "location") {
        yupValidation = yupValidation.test(
          "json-validation",
          "You have to select an option",
          function (value) {
            if (value)
              // eslint-disable-next-line
              return /^[\],:{}\s]*$/.test(
                value
                  // eslint-disable-next-line
                  .replace(/\\["\\\/bfnrtu]/g, "@")
                  .replace(
                    // eslint-disable-next-line
                    /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                    "]"
                  )
                  .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
              );
            else return true;
          }
        );
      }
      shapes[name] = yupValidation;
    }
    const validation = Yup.object().shape(shapes);
    return { defaultValues, validation };
  }

  static getInitialValuesComponent(formValues, componentTypes, allFields) {
    if (formValues != null && componentTypes.length > 0) {
      let componentFields = allFields;
      let newValues = { id: formValues.id };
      for (let element of componentFields) {
        let { name } = element;
        let typeField = element.type;
        newValues[name] =
          formValues[name] ||
          (typeField === "number"
            ? ""
            : typeField === "bool"
              ? false
              : typeField === "stringList"
                ? []
                : "");
        if (typeField === "select") {
          if (formValues[name] && formValues[name] !== "") {
            newValues[name] = formValues[name].id;
          }
        }
        if (typeField === "location") {
          if (formValues[name] && formValues[name] !== "") {
            newValues[name] = this.getStringFromLocation(formValues[name]);
          }
        }
      }
      if (formValues["componentType"] && formValues["componentType"] !== "")
        newValues["componentType"] = formValues["componentType"].id;
      return newValues;
    }
    return null;
  }

  /**
   *
   * @param {Array<object>} components
   * @param {object} oldComponentGroups
   * @returns {object} components grouped?
   */
  static groupComponents(components, oldComponentGroups) {
    oldComponentGroups = oldComponentGroups || {};
    return components.reduce((groups, e) => {
      let id = "";
      let title = "";
      e.items = {};
      if (e.componentType.componentGroup) {
        id = e.componentType.componentGroup.id;
        title = e.componentType.componentGroup.name;
      } else {
        id = "component-" + e.componentType.id;
        title = e.componentType.name;
      }
      if (id in groups) {
        groups[id].components.push(e);
      } else {
        groups[id] = {
          open: true,
          activeTab:
            oldComponentGroups[id] &&
              components.filter(x => x.id === oldComponentGroups[id].activeTab)
                .length > 0
              ? oldComponentGroups[id].activeTab
              : e.id,
          title: this.capitalizeFirstLetter(title) + " Information",
          components: [e]
        };
      }
      return groups;
    }, {});
  }

  static computeDefaultItem(allFields) {
    // eslint-disable-next-line
    setLocale({ number: { min: "It must be greater than ${min}" } });
    let itemFields = allFields;
    let defaultValues = { id: null };
    for (let { name, typeObject } of itemFields) {
      defaultValues[name] =
        typeObject === "number" ? "" : typeObject === "bool" ? false : "";
      if (name === "itemOrder") {
        defaultValues[name] = 1;
      }
    }

    let shapes = {};
    for (let field of itemFields) {
      let typeObject = field.type;
      let { name } = field;
      let yupValidation = Yup[
        typeObject === "location" ||
          typeObject === "select" ||
          typeObject === "stringList" ||
          typeObject === "lookup"
          ? "string"
          : typeObject
      ]();
      if (name === "itemName") {
        yupValidation = yupValidation.required("Item Name is required");
      }

      if (typeObject === "number") {
        yupValidation = yupValidation.min(0);
      }

      if (name === "itemOrder") {
        yupValidation = yupValidation.min(1);
      }

      if (typeObject === "location") {
        yupValidation = yupValidation.test(
          "json-validation",
          "You have to select an option",
          function (value) {
            if (value)
              // eslint-disable-next-line
              return /^[\],:{}\s]*$/.test(
                value
                  // eslint-disable-next-line
                  .replace(/\\["\\\/bfnrtu]/g, "@")
                  .replace(
                    // eslint-disable-next-line
                    /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                    "]"
                  )
                  .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
              );
            else return true;
          }
        );
      }
      shapes[name] = yupValidation;
    }
    const validation = Yup.object().shape(shapes);
    return { defaultValues, validation };
  }

  static getInitialValuesItem(formValues, itemTypes, allFields) {
    if (formValues != null && itemTypes.length > 0) {
      let itemFields = allFields;
      let newValues = { id: formValues.id };
      for (let element of itemFields) {
        let { name } = element;
        let typeField = element.type;
        newValues[name] =
          formValues[name] ||
          (typeField === "number"
            ? ""
            : typeField === "bool"
              ? false
              : typeField === "stringList"
                ? []
                : "");
        if (typeField === "select") {
          if (formValues[name] && formValues[name] !== "") {
            newValues[name] = formValues[name].id;
          }
        }
        if (typeField === "location") {
          if (formValues[name] && formValues[name] !== "") {
            newValues[name] = this.getStringFromLocation(formValues[name]);
          }
        }
      }
      if (formValues["itemType"] && formValues["itemType"] !== "")
        newValues["itemType"] = formValues["itemType"].id;
      return newValues;
    }
    return null;
  }

  static verifyFilters(filters, structureType) {
    if (!filters) {
      filters = [
        { id: "clientIds", arrayValue: [] },
        { id: "railroadIds", arrayValue: [] },
        { id: "subdivisionIds", arrayValue: [] }
      ];
      if (structureType) {
        filters.push({ id: "superstructureTypeIds", arrayValue: [] });
      }
      filters.push({ id: "bridgeName", value: "" });
      if (structureType) {
        filters.push({ id: "milestoneIds", arrayValue: [] });
        filters.push({ id: "milestoneStatusIds", arrayValue: [] });
        filters.push({ id: "eventTypeIds", arrayValue: [] });
        filters.push({ id: "bridgeId", value: "" });
      }
      filters.push({ id: "inspectionYears", arrayValue: [] });
      filters.push({ id: "years", arrayValue: [] });
    } else {
      if (filters.constructor !== Array) filters = [];
      if (filters.length < 1) filters.push({ id: "clientIds", arrayValue: [] });
      if (filters.length < 2)
        filters.push({ id: "railroadIds", arrayValue: [] });
      if (filters.length < 3)
        filters.push({ id: "subdivisionIds", arrayValue: [] });
      let len = 4;
      if (structureType) {
        if (filters.length < 4)
          filters.push({ id: "superstructureTypeIds", arrayValue: [] });
        len = 5;
      }
      if (filters.length < len) filters.push({ id: "bridgeName", value: "" });
      if (structureType) {
        if (filters.length < 6)
          filters.push({ id: "milestoneIds", arrayValue: [] });
        if (filters.length < 7)
          filters.push({ id: "milestoneStatusIds", arrayValue: [] });
        if (filters.length < 8)
          filters.push({ id: "eventTypeIds", arrayValue: [] });
        if (filters.length < 9) filters.push({ id: "bridgeId", value: "" });
      }
      if (filters.length < 10)
        filters.push({ id: "inspectionYears", arrayValue: [] });
      if (filters.length < 11) filters.push({ id: "years", arrayValue: [] });
    }
    return filters;
  }

  static verifyFiltersUsers(filters) {
    if (!filters) {
      filters = [{ id: "status", arrayValue: [] }];
    } else {
      if (filters.constructor !== Array) filters = [];
      if (filters.length < 1) filters.push({ id: "status", arrayValue: [] });
    }
    return filters;
  }

  static getColorRank(value) {
    return value < 0 ? "black" : value >= 3 ? "#74af78" : "#c90828";
  }

  static getNameIconStatus(status) {
    // milestoneId: 1, milestoneStatusId: 1, milestoneName: "Scheduled", milestoneStatusName: "Done"
    // milestoneId: -, milestoneStatusId: -, milestoneName: "Field Inspection", milestoneStatusName: "Not started"
    // milestoneId: -, milestoneStatusId: -, milestoneName: "Field Inspection", milestoneStatusName: "In Progress"
    // milestoneId: -, milestoneStatusId: -, milestoneName: "Field Inspection", milestoneStatusName: "Done"
    // milestoneId: 3, milestoneStatusId: -, milestoneName: "Initial Report", milestoneStatusName: "Preparing"
    // milestoneId: 3, milestoneStatusId: 6, milestoneName: "Initial Report", milestoneStatusName: "In Review"
    // milestoneId: 3, milestoneStatusId: 7, milestoneName: "Initial Report", milestoneStatusName: "Review Done"
    // milestoneId: 3, milestoneStatusId: -, milestoneName: "Initial Report", milestoneStatusName: "Submitted"
    // milestoneId: -, milestoneStatusId: -, milestoneName: "Final Report", milestoneStatusName: "Not started"
    // milestoneId: 4, milestoneStatusId: 10, milestoneName: "Final Report", milestoneStatusName: "Preparing"
    // milestoneId: 4, milestoneStatusId: 11, milestoneName: "Final Report", milestoneStatusName: "In Review"
    // milestoneId: -, milestoneStatusId: -, milestoneName: "Final Report", milestoneStatusName: "In Final QC"
    // milestoneId: 4, milestoneStatusId: 14, milestoneName: "Final Report", milestoneStatusName: "Final QC Done"
    // milestoneId: -, milestoneStatusId: -, milestoneName: "Final Report", milestoneStatusName: "Certified"
    // milestoneId: 4, milestoneStatusId: 16, milestoneName: "Final Report", milestoneStatusName: "Submitted"
    // milestoneId: 5, milestoneStatusId: 17, milestoneName: "Completed", milestoneStatusName: "n/a"

    let { milestoneName, milestoneStatusName } = status;
    if (milestoneName === "Unscheduled")
      return { Icon: FaMapMarkerAlt, color: "#bfbfbf" };
    if (milestoneName === "Scheduled")
      return { Icon: FaMapMarkerAlt, color: "#101010" };
    if (milestoneName === "Field Inspection")
      return { Icon: FaTasks, color: "#0271af" };
    if (milestoneStatusName === "Not Started")
      return { Icon: FaTasks, color: "#f0f0f0" };
    if (milestoneStatusName === "In Progress")
      return { Icon: FaTasks, color: "#0271af" };
    if (milestoneStatusName === "Certified")
      return { Icon: FaCheckCircle, color: "#1cbf2e" };
    if (milestoneStatusName === "Submitted To Client")
      return { Icon: FaGlobe, color: "#1cbf2e" };
    if (milestoneName === "Completed")
      return { Icon: FaCheck, color: "#1cbf2e" };
    if (
      milestoneStatusName === "In Review" ||
      milestoneStatusName === "In Final QC"
    )
      return { Icon: FaClock, color: "#bfbfbf" };
    return { Icon: FaClock, color: "#626262" };
  }

  static formatDate(date) {
    var day = date.getDate();
    day = (day < 10 ? "0" : "") + day;
    var month = date.getMonth() + 1;
    month = (month < 10 ? "0" : "") + month;
    var year = date.getFullYear();
    return month + "-" + day + "-" + year;
  }

  static renderStatus(status, index) {
    index = index || 0;
    if (status != null) {
      let { Icon, color } = this.getNameIconStatus(status);
      return (

        <div key={index} className="show-status">
          <span>
            <Icon color={color} size={16} />
          </span>
          <span>
            <b>{status.milestoneName}</b>
            <br />
            {status.milestoneStatusName}
            <br />
            {status.userAssigned}
          </span>
        </div>

      );
    }
    return null;
  }

  static renderStatusList(list) {
    if (list && list.length > 0)
      return list.map((s, i) =>
        this.renderStatus(
          {
            milestoneName: s.milestone.name,
            milestoneStatusName: s.milestoneStatus.name,
            userAssigned: s.userAssigned ? s.userAssigned.fullName : ""
          },
          i
        )
      );
    return null;
  }

  static renderStatusSection(status, index) {
    index = index || 0;
    if (status != null) {
      let { Icon, color } = this.getNameIconStatus(status);
      return (
        <Col sm="auto" className="status" key={index}>
          <span>
            <Icon color={color} size={16} />
          </span>{" "}
          <span>
            {status.milestoneName} - {status.milestoneStatusName}
          </span>
        </Col>
      );
    }
    return null;
  }

  static renderStatusListSections(list) {
    if (list && list.length > 0)
      return list.map((s, i) =>
        this.renderStatusSection(
          {
            milestoneName: s.milestone.name,
            milestoneStatusName: s.milestoneStatus.name
          },
          i
        )
      );
    return null;
  }

  static getDataFromImages(list) {
    return list.map(img => ({
      src: img.picture_url,
      thumbnail: img.thumbnail_url,
      thumbnailWidth: 125,
      thumbnailHeight: 125,
      caption: img.id,
      tags: [
        { value: img.visualId, title: img.visualId },
        {
          value: img.tags ? img.tags[0] || "" : "",
          title: img.tags ? img.tags[0] || "" : ""
        }
      ]
    }));
  }

  static eventFire(el, etype) {
    if (el) {
      if (el.fireEvent) {
        el.fireEvent("on" + etype);
      } else {
        var evObj = document.createEvent("Events");
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    }
  }

  static onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  static getPriorities(row) {
    let prior1 = row.amountPriority1 || 0;
    let prior2 = row.amountPriority2 || 0;
    return prior1 + prior2 + " (" + prior1 + " & " + prior2 + ")";
  }

  static clickOption = (func, classClick) => {
    return e => {
      this.eventFire(document.getElementsByClassName(classClick)[0], "click");
      func(e);
    };
  };

  static getDropdownButton = (
    name,
    className,
    Icon,
    items,
    classClick,
    attrs
  ) => {
    let nameElem = "dropdow" + name;
    attrs = attrs || {};
    if (!attrs.className) {
      attrs.className =
        className === "" ? "" : "btn btn-sm btn-round " + className;
    }
    return (
      <Fragment key={nameElem}>
        <UncontrolledButtonDropdown
          group={false}
        >
          <DropdownToggle tag="button" {...attrs}>
            <Icon />
          </DropdownToggle>
          {items.length > 0 && !attrs.disabled ? (
            <DropdownMenu
              className="actionbutton-dropdown"
            >
              {items.map((e, i) => (
                <DropdownItem
                  key={i}
                  onClick={
                    e.isLabel
                      ? () => { }
                      : this.clickOption(e.func, classClick)
                  }
                  disabled={e.disabled}
                  className={
                    e.disabled
                      ? "disabled-option"
                      : e.isLabel
                        ? "label-option"
                        : ""
                  }
                >
                  {e.title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          ) : null}
        </UncontrolledButtonDropdown>
      </Fragment>
    );
  };


  static getButtonPopover = (
    name,
    className,
    Icon,
    items,
    classClick,
    attrs
  ) => {
    let nameElem = "popover" + name;
    attrs = attrs || {};
    if (!attrs.className) {
      attrs.className =
        className === "" ? "" : "btn btn-sm btn-round " + className;
    }
    return (
      <Fragment key={nameElem}>
        <button id={nameElem} {...attrs}>
          <Icon />
        </button>
        {items.length > 0 && !attrs.disabled ? (
          <UncontrolledPopover
            trigger="legacy"
            placement="bottom-start"
            target={nameElem}
            className="actionbutton-popover"
          >
            <PopoverBody>
              <ListGroup>
                {items.map((e, i) => (
                  <Fragment key={i}>
                    <ListGroupItem
                      id={e.activePopover ? "popover_target" : null}
                      onClick={
                        e.isLabel
                          ? () => { }
                          : this.clickOption(e.func, classClick)
                      }
                      disabled={e.disabled}
                      className={
                        e.disabled
                          ? "disabled-option"
                          : e.isLabel
                            ? "label-option"
                            : ""
                      }
                    >
                      {e.title}
                    </ListGroupItem>
                    {e.activePopover && e.popover ?
                      e.popover
                      :
                      null
                    }
                  </Fragment>
                )
                )}
              </ListGroup>
            </PopoverBody>
          </UncontrolledPopover>
        ) : null}
      </Fragment>
    );
  };

  static getBooleanLocalStorage(key) {
    let value = localStorage.getItem(key) || "null";
    return value === "null" ? null : value === "true";
  }

  static getStringLocalStorage(key, defaultStr) {
    let value = localStorage.getItem(key) || defaultStr;
    return value === defaultStr ? null : value;
  }

  static getIntLocalStorage(key) {
    let value = localStorage.getItem(key) || "";
    return value === "" ? null : parseInt(value);
  }

  static getIdLocalStorage(key) {
    let value = localStorage.getItem(key) || "";
    return value === "" ? null : { id: value };
  }

  static getIDateLocalStorage(key) {
    let value = localStorage.getItem(key) || "";
    return value === "" ? null : moment(value);
  }

  static getParametersImmediateActions(eventId) {
    return {
      event: { id: eventId },
      needRailroadBridgeEngineerRbeReview: this.getBooleanLocalStorage(
        "needRailroad"
      ),
      slowOrderRecommended: this.getBooleanLocalStorage("needOrder"),
      needBridgeOutOfService: this.getBooleanLocalStorage("needBridge"),
      interimInspectionRequired: this.getBooleanLocalStorage(
        "interimInspectionRequired"
      ),
      description: this.getStringLocalStorage("note", "null"),
      interimInspectionScope: this.getStringLocalStorage("scope", "null"),
      interimInspectionFrequency: this.getStringLocalStorage(
        "frequency",
        "null"
      ),
      temperature: this.getIntLocalStorage("temperature"),
      inspectedBy: this.getIdLocalStorage("inspectedBy"),
      dateInspected: this.getIDateLocalStorage("dateInspection"),
      inspectionType: this.getIdLocalStorage("inspectionType"),
      weather: this.getStringLocalStorage("weather", "")
    };
  }

  static getFullUserName(user) {
    if (isEmpty(user)) {
      return "getFullUserName() Error";
    }
    if (user.firstName && user.lastName) {
      return `${user.lastName}  ${user.firstName}`;
    }
    if (user.firstName && user.email) {
      return `${user.firstName}  (${user.email})`;
    }
    if (user.lastName && user.email) {
      return `${user.lastName}  (${user.email})`;
    }
    return user.email;
  }

  static getFullUserNameWithoutEmail(user) {
    if (isEmpty(user)) {
      return "getFullUserName() Error";
    }
    if (user.firstName && user.lastName) {
      return `${user.firstName}  ${user.lastName}`;
    }
    if (user.firstName) {
      return `${user.firstName}`;
    }
    if (user.lastName) {
      return `${user.lastName}`;
    }
    return user.email;
  }

  static getUsername(user) {
    if (isEmpty(user)) {
      return "getUsername() Error";
    }
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    if (user.firstName) {
      return user.firstName;
    }
    if (user.lastName) {
      return user.lastName;
    }
    return user.email;
  }

  static isEmpty(val) {
    return val === undefined || val == null || val.length <= 0 ? true : false;
  }

  static getNewItemsArray(component, itemType, times) {
    if (component.items && component.items.length > 0) {
      if (
        component.items.filter(e => e.itemType.id === itemType.id).length > 0
      ) {
        component.items = component.items.filter(
          e => e.itemType.id !== itemType.id
        );
        for (let i = 0; i < times; i++) {
          component.items.push({
            componentName: component.componentName,
            itemType: itemType,
            itemOrder: component.items.length + 1,
            itemName: `${itemType.name}`
          });
        }
      } else {
        for (let i = 0; i < times; i++) {
          component.items.push({
            componentName: component.componentName,
            itemType: itemType,
            itemOrder: component.items.length + 1,
            itemName: `${itemType.name}`
          });
        }
      }
    } else {
      component.items = [];
      for (let i = 0; i < times; i++) {
        component.items.push({
          componentName: component.componentName,
          itemType: itemType,
          itemOrder: component.items.length + 1,
          itemName: `${itemType.name}`
        });
      }
    }
    return component;
  }

  /**
   * @memberof Utils
   * @description Checks if the user is a "SuperAdmin".
   *
   * @param {object} user
   * @param {(string | boolean)} user.superAdmin - a boolean or a string representation of a
   * boolean that indicates if the user is SuperAdmin or not.
   * @returns {boolean} true if user is a "SuperAdmin", false otherwise.
   */
  static isSuperAdmin(user) {
    if (user) {
      return user.superAdmin
    }
    return false
  }

  /**
   * @memberof Utils
   * @description Checks if the user has the provided claim. If the
   * user is "SuperAdmin"
   *
   * @param {string} claim - the claim to validate.
   * @param {object} user - the user info.
   * @param {(boolean|string)} user.superAdmin - indicates if the user is
   * super admin or not. true or e string "true" is treated as super admin,
   * any other value is considered false.
   * @returns {boolean} true if it is allowed, false otherwise.
   */
  static isAllowed(claim, user) {
    return this.isAllowedAtLeastOne([claim], user);
  }

  /**
   * @memberof Utils
   * @description Checks if the user has at least one of the provided
   * claims or is SuerAdmin.
   *
   * @param {string[]} claims - claims to be ckecked.
   * @param {object} user - the user info.
   * @param {(boolean|string)} user.superAdmin - indicates if the user is
   * super admin or not. true or the string "true" is treated as super admin,
   * any other value is considered false.
   * @returns {boolean} true if it is allowed, false otherwise.
   */
  static isAllowedAtLeastOne(claims, user) {

    if (Utils.isSuperAdmin(user)) {
      return true;
    }

    const userClaimsLocalStorage = cookies.get('userClaims')
    if (!userClaimsLocalStorage) {
      return false;
    }

    return claims.some(cl => userClaimsLocalStorage.includes(cl));
  }

  /**
   * Verifies if user object is from a client.
   *
   * @param {object} user
   * @param {boolean} user.isClient - true if it is a client,
   * false otherwise.
   * @returns true if user is a client, false otherwise.
   */
  static isClient(user) {
    return user.isClient
  }

  /**
   * Verifies if user object is from an organization.
   *
   * @param {object} user
   * @param {boolean} isOrganization - true if it is an organization,
   * false otherwise.
   * @returns true if user is from an organization, false otherwise.
   */
  static isOrganization(user) {
    return !user.isClient;
  }

  static generateMembersRow(members) {
    if (members && members.length > 0) {
      return members.map((element, index) => {
        let label = ''

        if (element.assembly !== null)
          label = element.assembly

        if (element.assembly && element.component !== null)
          label = element.assembly + ' - ' + element.component

        if (element.assembly && element.component !== null && element.item !== null)
          label = element.assembly + ' - ' + element.component + ' - ' + element.item

        return <Row key={index}><Col>{label}</Col></Row>
      })
    } else
      return null
  }

  static generateRowByStringList(stringList){
    if(stringList!=null && stringList.length>0){
      return stringList.map((element,index)=>{
        return <Row key={index}><Col>{element}</Col></Row>
      })
    }else{
      return null;
    }
  }

  static generateMembersRowDiv(members) {
    if (members && members.length > 0) {
      return members.map((element, index) => {
        let label = ''
        if (element.assembly_name !== null)
          label = label + element.assembly_name

        if (element.assembly_name && element.component_name !== null)
          label = element.assembly_name + ' - ' + element.component_name

        if (element.assembly_name && element.component_name !== null && element.item_name !== null)
          label = element.assembly_name + ' - ' + element.component_name + ' - ' + element.item_name


        return <div key={index}>{label}</div>
      })
    } else
      return null
  }

  static generateLocationsRow(members) {
    if (members && members.length > 0) {
      return members.map((element, index) => <Row key={index}><Col>{element.locations}</Col></Row>)
    } else
      return null
  }

  static generateLocationsRowDiv(members) {
    if (members && members.length > 0) {
      return members.map((element, index) => <div key={index}>{element.locations}</div>)
    } else
      return null
  }

  static generateMembers(members) {
    if (members && members.length > 0) {
      return members.map((element, index) => {
        let label = ''
        if (element.assembly !== null)
          label = label + element.assembly

        if (element.assembly && element.component !== null)
          label = element.assembly + ' - ' + element.component

        if (element.assembly && element.component !== null && element.item !== null)
          label = element.assembly + ' - ' + element.component + ' - ' + element.item


        return label
      })[0]
    } else
      return null
  }
}
