import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
import ApiAssemblyEvent from 'apiServices/ApiAssemblyEvent'
import { cloneDeep } from 'lodash';
import { showToastAsError } from 'store/actions/ToastActions';

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The assembly cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props });
}

/*
 * get AssemblysEvent
 */
function* getAssemblysEvent() {
  // get all assemblies
  const allAssemblysEvent = yield call(ApiAssemblyEvent.getAll);
  // update state for assemblys
  yield put({
    type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
      assemblysEvent: allAssemblysEvent,
    }
  });
}

function* searchAssemblysEvent(action) {
  // search all Assemblys
  const assemblysEvent = yield call(ApiAssemblyEvent.searchAssemblysEvent, action.props);
  // update state for assemblys
  yield put({
    type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
      assemblysEvent: assemblysEvent.content,
    }
  });
}

function* getAssemblyEvent(action) {
  // get assembly
  yield loading();
  const assemblyEvent = yield call(ApiAssemblyEvent.getAssemblyEvent, action.props.assemblyId);
  yield put({
    type: actionTypes.CHANGE_NAMES_PROPS, props: {
      name: assemblyEvent.assemblyName,
      id: assemblyEvent.id,
      type: "assemblyEvent"
    }
  });
  if (Object.keys(action.props).length > 1)
    yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: 4, ids: action.props, last: "assembly" } });
  // update state for assemblys
  yield put({
    type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
      assemblyEvent: assemblyEvent,
      loading:false
    }
  });
}

function* editAssemblyEvent(action) {
  try {
    let props = action.props;
    if (action.props.loadAgain)
      props = props.props;
      

      // update  Assembly
      let newAssembly = yield call(ApiAssemblyEvent.editAssemblyEvent, props);
      yield put({
        type: actionTypes.CHANGE_ASSEMBLYEVENT_SAVED, props: {
          assemblyEvent: newAssembly
        }
      });

      let storedAssemblies = cloneDeep(yield select(store => store.assemblyEvent.assemblysEvent));
      let index = storedAssemblies.findIndex(e => e.id === props.id);
      if(index !== -1){
        storedAssemblies[index]=newAssembly
        yield put({
          type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
            assemblysEvent: storedAssemblies,
            assembliesEventArray: storedAssemblies
          }
        });
      }
        
    if (action.props.loadAgain) {
      yield getAllAssembliesBySectionEventAndStructureType({ props: action.props.params })
    }
  } catch (error) {
    yield loading(false, error);
  }
}

function* addAssemblyEvent(action) {
  let props = action.props;
  if (action.props.loadAgain)
    props = props.props;
  // add  Assembly
  let assemblyEvent = yield call(ApiAssemblyEvent.addAssemblyEvent, props);
  // update state for assemblys
  yield put({
    type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
      newAssemblyEvent: assemblyEvent,
    }
  });
  yield put({
    type: actionTypes.CHANGE_STRUCTURETYPE_IS_ASSIGNED_PROPS, props: {
      structureId: action.props.params.structureTypeId
    }
  });
  if (action.props.loadAgain) {
    action.props.params.structureTypeId = assemblyEvent.assemblyType.structureType.id
    yield getAllAssembliesBySectionEventAndStructureType({ props: action.props.params })
  }
}

function* deleteAssemblyEvent(action) {
  // delete  Assembly
  yield call(ApiAssemblyEvent.deleteAssemblyEvent, action.props.id);
  yield getAllAssembliesBySectionEventAndStructureType({ props: action.props });
}

function* getAllAssemblysEvent(action) {
  yield put({
    type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
      assemblysEvent: [],
    }
  });
  // get all assembly fields
  const allAssemblys = yield call(ApiAssemblyEvent.getAllAssemblysEvent, action.props.assetEventId, action.props.structureId);
  // update state for assemblies
  yield put({
    type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
      assemblysEvent: allAssemblys,
    }
  });
}

function* getAllAssembliesBySectionEventAndStructureType(action) {

  try {
    yield loading();
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        assemblysEvent: [],
        assembliesEventArray: [],
      }
    });
    // get all assembly fields
    const allAssemblys = yield call(ApiAssemblyEvent.getAllAssembliesBySectionEventAndStructureType, action.props.sectionEventId, action.props.structureTypeId);
    // update state for assemblies
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        assemblysEvent: allAssemblys,
        assembliesEventArray: allAssemblys,
        loading:false
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getStructureAssemblySuperReduced(action) {

  try {
    yield loading();
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        assemblysEvent: [],
        assembliesEventArray: [],
      }
    });
    // get all assembly fields
    const allAssemblys = yield call(ApiAssemblyEvent.getStructureAssemblySuperReduced, action.props.sectionEventId, action.props.structureTypeId);
    // update state for assemblies
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        assemblysEvent: allAssemblys,
        assembliesEventArray: allAssemblys
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* getAllAssemblysEventRecursively(action) {
  // get all assembly fields
  const allAssemblys = yield call(ApiAssemblyEvent.getAllAssemblysEventRecursively, action.props.sectionEventId, action.props.structureTypeId);
  // update state for assemblies
  yield put({
    type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
      assemblysEventForForm: allAssemblys,
    }
  });
}

function* getAllAssembliesBySectionEventAndStructureTypeReduced(action) {
  // get all assembly fields
  const allAssemblys = yield call(ApiAssemblyEvent.getAllAssembliesBySectionEventAndStructureTypeReduced, action.props.sectionEventId, action.props.structureTypeId);
  // update state for assemblies
  yield put({
    type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS,
    props: {
      assemblysEventForForm: allAssemblys,
    }
  });
}

function* getAllAssembliesByEventID(action) {
  const allAssemblys = yield call(ApiAssemblyEvent.getAllAssembliesByEventID, action.props);
  // update state for assemblies
  yield put({
    type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
      assembliesEventForPhotos: allAssemblys,
    }
  });
}

function* addMultiAssemblyEvent(action) {
  try {
    yield loading();
    let props = action.props;
    if (action.props.loadAgain)
      props = props.props;
    // add  Assembly
    let assemblyEvent = yield call(ApiAssemblyEvent.addMultiAssemblyEvent, props);
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        newAssemblyEvent: assemblyEvent,
      }
    });
    if (action.props.loadAgain) {
      action.props.params.sectionEventId = action.props.params.sectionId
      yield getAllAssembliesBySectionEventAndStructureType({ props: action.props.params })
    }
  } catch (error) {
    yield loading(false, error);
  }
}

function* getStructureAssemblyTree(action) {
  try {
    yield loading();
    let assembliesEvent = yield call(ApiAssemblyEvent.getStructureAssemblyTree, action.props.props);

    let newAssemblyEventArray = assembliesEvent.map((element, index) => {
      element.assembliesName = { name: element.assemblyName, id: element.assemblyType.id }
      element.No = index + 1
      if (element.accessType && element.accessType !== null)
        element.accessType = element.accessType.id
      if (element.componentEvents) {
        element.components = element.componentEvents.map(component => {
          component.componentTypeName = component.componentType.name
          component.fields = component.componentType.fields
          if (component.itemEvents)
            component.items = component.itemEvents.map(item => {
              item.fields = item.itemType.fields
              item.componentName = component.componentName
              return item
            })
          return component
        })
      }
      return element
    })

    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        assemblysEvent: newAssemblyEventArray,
        assembliesEventArray: newAssemblyEventArray,
      }
    });
    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield loading(false);
  } catch (error) {
    yield loading(false, error);
  }
}

function* clearStructureAssemblyTree() {
  try {
    yield loading();
    yield put({
      type: actionTypes.CHANGE_ASSEMBLY_PROPS, props: {
        assembliesArray: [],
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* editMultiAssemblyEvent(action) {
  try {
    yield loading();
    let props = action.props;
    if (action.props.loadAgain)
      props = props.props;
    // add  Assembly
    let assemblyEvent = yield call(ApiAssemblyEvent.editMultiAssemblyEvent, props);
    
    let newAssemblyList = cloneDeep(yield select(store => store.assemblyEvent.assemblysEvent));
    let index
    assemblyEvent.forEach(e => {
      index = newAssemblyList.findIndex(item => item.id === e.id)
      newAssemblyList[index] = e
    })
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        assembliesEventArray: newAssemblyList,
        assemblysEvent: newAssemblyList,
      }
    });
    if (action.props.loadAgain) {
      action.props.params.sectionEventId = action.props.params.sectionId
      yield getAllAssembliesBySectionEventAndStructureType({ props: action.props.params })
    }
  } catch (error) {
    yield loading(false, error);
  }
}


//save assemblys in build process without upate props data
function* editMultiAssemblyEventInBuildProcess(action) {
  try {
    yield loading();
    let props = action.props;
    if (action.props.loadAgain)
      props = props.props;
    // add  Assembly
    yield call(ApiAssemblyEvent.editMultiAssemblyEvent, props);

  } catch (error) {
    yield loading(false, error);
  }
}



function* addMultipleAssemblies(action) {
  try {
    yield loading();
    let props = action.props;
    // add  Assembly
    let assemblyEvent = yield call(ApiAssemblyEvent.addMultipleAssemblies, props);
    // update state for assemblys
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_ADD_MULTIPLE_PROPS, props: {
        newAssemblies: assemblyEvent,
      }
    });

    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        newAssemblyEvent: assemblyEvent,
      }
    });


  } catch (error) {
    yield loading(false, error);
  }
}

function* addAssembly(action) {
  try {
    yield loading();
    let props = action.props;
    // add  Assembly
    let assemblyEvent = yield call(ApiAssemblyEvent.addAssembly, props);
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_ADD_MULTIPLE_PROPS, props: {
        newAssemblies: [assemblyEvent],
      }
    });
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        newAssemblyEvent: [assemblyEvent],
      }
    });


  } catch (error) {
    yield loading(false, error);
  }
}


function* addMultipleComponents(action) {
  try {
    yield loading();
    const props = action.props;
    let assemblyEvent = cloneDeep(yield select(store => store.assemblyEvent.newAssemblyEvent));
    const componentEvents = yield call(ApiAssemblyEvent.addMultipleComponents, props.assemblyEventId, props.componentTypeId, +props.quantity);
    if (assemblyEvent.length > 0 && props.isFromAddMemberPage) {
      assemblyEvent[0].componentEvents = componentEvents[0].assemblyEvent.componentEvents
      yield put({
        type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
          newAssemblyEvent: assemblyEvent,
        }
      });
    } else {
      yield put({ type: actionTypes.CHANGE_ASSEMBLYEVENT_ADDED_MULTIPLE_COMPONENTS, props: { componentEvents } });
    }
    yield loading(false);
  } catch (error) {
    yield loading(false, error);
  }
}


function* addSingleComponents(action) {
  try {
    yield loading();
    const props = action.props;
    let assemblyEvent = cloneDeep(yield select(store => store.assemblyEvent.newAssemblyEvent));
    const componentEvents = yield call(ApiAssemblyEvent.addSingleComponents, props.assemblyEventId, props.componentTypeId);
    if (assemblyEvent.length > 0 && props.isFromAddMemberPage) {
      assemblyEvent[0].componentEvents = componentEvents.assemblyEvent.componentEvents
      yield put({
        type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
          newAssemblyEvent: assemblyEvent,
        }
      });
    }
    yield loading(false);
  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteMultiAssemblyEvent(action) {
  // delete Multiple Assembies
  //arrayAssembliesId is an array of ids from assemblies
  try {
    yield call(ApiAssemblyEvent.deleteMultiAssemblyEvent, action.props.arrayAssembliesId);
    yield getAllAssembliesBySectionEventAndStructureType({ props: action.props });
  } catch (error) {
    yield loading(false, error);
  }
}

function* copyComponentsAndItemEvents(action) {

  //copy all components from an assembly to others
  let props = action.props.props
  try {
    yield call(ApiAssemblyEvent.copyComponentsAndItemEvents, props);

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}


function* updateAssembliesOrders(action) {
  try {
    yield loading();
    let props = action.props;

    // update   Assembly
    yield call(ApiAssemblyEvent.updateAssembliesOrders, props);

    let storeAssemblies = yield select(state => state.assemblyEvent.assemblysEvent);
    let allAssemblys = cloneDeep(storeAssemblies)
    let newAssemblysArray = [];
    props.forEach(element => {
      let assembly = allAssemblys.find(row => row.id === element.id)
      assembly.order = element.order;
      newAssemblysArray.push(assembly)
    });

    // update store for assemblies
    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        assemblysEvent: newAssemblysArray,
        assembliesEventArray: newAssemblysArray
      }
    });

  } catch (error) {
    yield loading(false, error);
  }
}


function* updateSingleDynamicField(action) {

  const { assemblyEventId, fieldName, newValue, options, isFromDetailedAdd } = action.props;
  let assemblyEvent = cloneDeep(yield select(store => store.assemblyEvent.newAssemblyEvent));
  let StructureTypeAssemblies = cloneDeep(yield select(store => store.assemblyEvent.assemblysEvent));
  let index = StructureTypeAssemblies.findIndex(element=>element.id === assemblyEventId )
  if(index !== -1){
    StructureTypeAssemblies[index][fieldName] = newValue
  }

  try {
    yield call(ApiAssemblyEvent.updateDynamicField, assemblyEventId, fieldName, newValue);

    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
        assembliesEventArray: StructureTypeAssemblies,
        assemblyEvent: StructureTypeAssemblies,
      }
    });

    yield put({
      type: actionTypes.CHANGE_ASSEMBLYEVENT_DYNAMIC_FIELD_UPDATED,
      props: {
        assemblyEventId,
        fieldName,
        newValue
      }
    });

    if (isFromDetailedAdd) {
      assemblyEvent[0][fieldName] = newValue;
      yield put({
        type: actionTypes.CHANGE_ASSEMBLYEVENT_PROPS, props: {
          newAssemblyEvent: assemblyEvent,
        }
      });

    }

    if (options?.callback) {
      yield call(options.callback);
    }

  } catch (error) {
    console.log("ERROR",error)
    yield put(showToastAsError("Error updating field '" + fieldName + "'", "There was an error updating the field, please try again later."));
  }
}


/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_ALL, getAssemblysEvent),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_GET, getAssemblyEvent),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_SEARCH, searchAssemblysEvent),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_EDIT, editAssemblyEvent),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_ADD, addAssemblyEvent),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_DELETE, deleteAssemblyEvent),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_ALL_EVENT, getAllAssemblysEvent),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_ALL_BY_SECTIONEVENT_STRUCTURETYPE, getAllAssembliesBySectionEventAndStructureType),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_ALL_BY_SECTIONEVENT_STRUCTURETYPE_SUPER_REDUCED,getStructureAssemblySuperReduced),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_ALL_RECURSIVELY, getAllAssemblysEventRecursively),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_GET_ALL_BY_EVENT_ID, getAllAssembliesByEventID),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_ADD_MULTI, addMultiAssemblyEvent),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_GET_STRUCTURE_TREE, getStructureAssemblyTree),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_GET_STRUCTURE_TREE, clearStructureAssemblyTree),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_EDIT_MULTI, editMultiAssemblyEvent),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_EDIT_MULTI_BUILD_PROCESS, editMultiAssemblyEventInBuildProcess),
    takeLatest(actionTypes.SAGA_ASSEMBLYEVENT_REDUCED, getAllAssembliesBySectionEventAndStructureTypeReduced),
    takeLatest(actionTypes.DELETE_MULTIPLE_ASSEMBLYEVENT, deleteMultiAssemblyEvent),
    takeLatest(actionTypes.SAGA_ADD_MULTI_ASSEMBLIES, addMultipleAssemblies),
    takeLatest(actionTypes.SAGA_ADD_SPAN, addAssembly),
    takeLatest(actionTypes.SAGA_ADD_MULTI_COMPONENTS, addMultipleComponents),
    takeLatest(actionTypes.SAGA_ADD_SINGLE_COMPONENTS, addSingleComponents),
    takeLatest(actionTypes.UPDATE_ASSEMBLIES_ORDER, updateAssembliesOrders),
    takeLatest(actionTypes.COPY_ASSEMBLY_COMPONENTS_ITEMS, copyComponentsAndItemEvents),

    takeEvery(actionTypes.SAGA_ASSEMBLYEVENT_UPDATE_SINGLE_DYNAMIC_FIELD, updateSingleDynamicField)
  ]);
}

export default AuthWatcher;
