import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiMessage from 'apiServices/ApiMessage'

import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast } from '../actions/ToastActions';
import { clearMessageErrors } from '../actions/MessageActions';

const recordType = "message";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The message cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_MESSAGE_PROPS, props });
}

/*
 * get Messages
 */
function* getMessages() {
  // // get first page Messages
  // yield searchMessages({ props: { search: '', page: 0 } });

  try {

    yield loading();
    // get item types
    const allItems = yield call(ApiMessage.getAll);
      // update state for items
      yield put({
        type: actionTypes.CHANGE_MESSAGE_PROPS, props: {
          messages: allItems,
          loading: false,
          errors: ""
        }
      });

  } catch (error) {
    yield loading(false, error);
  }

}

function* getMessageTypes() {
  try {
    yield loading();
    // get  Message types
    const types = yield call(ApiMessage.getMessageTypes);
    // update state for messages
    yield put({
      type: actionTypes.CHANGE_MESSAGE_PROPS, props: {
        types: types,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getMessageEvents() {
  try {
    yield loading();
    // get  Message types
    const events = yield call(ApiMessage.getMessageEvents);
    // update state for messages
    yield put({
      type: actionTypes.CHANGE_MESSAGE_PROPS, props: {
        events: events,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchMessages(action) {
  try {
    yield loading();
    // get all Messages
    const messages = yield call(ApiMessage.searchMessages, action.props);
    const pagination = {
      number: messages.number,
      numberOfElements: messages.numberOfElements,
      totalElements: messages.totalElements,
      totalPages: messages.totalPages,
      size: messages.size
    };
    // update state for messages
    yield put({
      type: actionTypes.CHANGE_MESSAGE_PROPS, props: {
        messages: messages.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getMessage(action) {
  try {
    yield loading();
    // get  Message
    const message = yield call(ApiMessage.getMessage, action.props.messageId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: message.name,
        id: message.id,
        type: "message"
      }
    });
    // update state for messages
    yield put({
      type: actionTypes.CHANGE_MESSAGE_PROPS, props: {
        message: message,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editMessage(action) {

  yield put(clearMessageErrors());

  try {
    yield loading();
    // update  Message
    const message = yield call(ApiMessage.editMessage, action.props);
    yield put({
      type: actionTypes.CHANGE_MESSAGE,
      props: {
        message
      }
    });
    // update state for messages
    yield put({
      type: actionTypes.CHANGE_MESSAGE_PROPS, props: {
        message: {},
        loading: false,
        errors: ''
      }
    });
    yield put(showRecordUpdatedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* addMessage(action) {

  yield put(clearMessageErrors());

  try {
    yield loading();
    // add  Message
    const message = yield call(ApiMessage.addMessage, action.props);
    yield put({
      type: actionTypes.CHANGE_MESSAGE,
      props: {
        message
      }
    });
    // update state for messages
    yield put({
      type: actionTypes.CHANGE_MESSAGE_PROPS, props: {
        message: {},
        loading: false,
        errors: ''
      }
    });
    yield put(showRecordAddedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteMessage(action) {

  yield put(clearMessageErrors());

  try {
    yield loading();
    // delete  Message
    yield call(ApiMessage.deleteMessage, action.props);

    // update state for messages
    yield put({
      type: actionTypes.CHANGE_MESSAGE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield put(showRecordDeletedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteMessageList(action) {

  yield put(clearMessageErrors());

  try {
    yield loading();
    // delete  Message
    const removedMessageIds = yield call(ApiMessage.deleteMessageList, action.props.list);
    yield put({ type: actionTypes.CHANGE_MESSAGE_REMOVED, props: {
      removedMessageIds
    } });

    // update state for messages
    yield put({
      type: actionTypes.CHANGE_MESSAGE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield put(showRecordDeletedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_MESSAGE_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiMessage.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_MESSAGE_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_MESSAGE_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    });
  }
}



/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_MESSAGE_ALL, getMessages),
    takeLatest(actionTypes.SAGA_MESSAGE_SEARCH, searchMessages),
    takeLatest(actionTypes.SAGA_MESSAGE_GET, getMessage),
    takeLatest(actionTypes.SAGA_MESSAGE_EDIT, editMessage),
    takeLatest(actionTypes.SAGA_MESSAGE_ADD, addMessage),
    takeLatest(actionTypes.SAGA_MESSAGE_DELETE, deleteMessage),
    takeLatest(actionTypes.SAGA_MESSAGE_DELETE_CHECKED, deleteMessageList),
    takeLatest(actionTypes.SAGA_MESSAGE_TYPES, getMessageTypes),
    takeLatest(actionTypes.SAGA_MESSAGE_EVENTS, getMessageEvents),
    takeLatest(actionTypes.SAGA_MESSAGE_LOAD_ORGANIZATIONS, loadOrganizations)
  ]);
}

export default AuthWatcher;
