import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/alignment';

export default class ApiAlignment {

  // GET 'api/alignments'
  static getAll(){
    return Utils.genericCall(endpoint);
  }

  static searchAlignments(props){
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getAlignment(id){
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editAlignment(data){
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addAlignment(data){
    return Utils.genericPost(endpoint, data);
  }

  static deleteAlignment(id){
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

}
