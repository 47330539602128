export const typeString = "string";
export const typeNumber = "number";
export const typeSelect = "select";
export const typeFieldsList = "fieldsList";
export const typeTypeahead = "typeahead";

export const userFieldsRegularUser = {
  username: { type: 'string', name: 'Username', readonly: false, show: true },
  person: { type: 'dataContact', name: 'Contact', readonly: false, show: true },
  userRoles: {
    type: 'list', name: 'Role', readonly: false, show: false,
    toText: item => (item.userRoles && item.userRoles.length > 0 ?
      item.userRoles.reduce((acc, currValue) => (acc + currValue.role.name + ","), "") :
      "Without Roles")
  }
}

export const userFields = {
  username: { type: 'string', name: 'Username', readonly: false, show: true },
  organization: { type: 'list', name: 'Organization', readonly: false, show: false },
  client: {type: 'list', name: 'Client', readonly: false, show: true},
  person: { type: 'dataContact', name: 'Contact', readonly: false, show: true },
  userRoles: {
    type: 'list', name: 'Role', readonly: false, show: false,
    toText: item => (item.userRoles && item.userRoles.length > 0 ?
      item.userRoles.reduce((acc, currValue) => (acc + currValue.role.name + ","), "") :
      "Without Roles")
  },
  isBlocked: { type: 'bool', name: 'Is Blocked', readonly: false, show: true },
  superAdmin: { type: 'bool', name: 'Is Super Admin', readonly: false, show: true },
}

export const messageFields = {
  organization: { type: "list", name: "Organization", readonly: false, show: true},
  name: { type: 'string', name: 'Name', readonly: false, show: true },
  description: { type: 'string', name: 'Description', readonly: false, show: true },
  event: { type: 'string', name: 'Event', readonly: false, show: true },
  type: { type: 'string', name: 'Type', readonly: false, show: true },
  emailFrom: { type: 'email', name: 'Email From', readonly: false, show: true },
  emailSubject: { type: 'string', name: 'Subject', readonly: false, show: true },
  content: { type: 'richtext', name: 'Content', readonly: false, show: true },
}

export const messageFieldFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
  objectName: { type: 'string', name: 'Object Name', readonly: false, show: true },
  property: { type: 'string', name: 'Property', readonly: false, show: true },
  keyString: { type: 'string', name: 'Key String', readonly: false, show: true },
}

export const clientFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
  abbreviation: { type: 'string', name: 'Client Abbreviation', readonly: false, show: true },
  description: { type: 'string', name: 'Description', readonly: false, show: true }
}

export const railroadFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
  description: { type: 'string', name: 'Description', readonly: false, show: true },
  className: {
    type: 'select', name: 'Class', readonly: false, show: false,
    list: [{ id: 'Class I', name: 'Class I' }, { id: 'Class II', name: 'Class II' }, { id: 'Class III', name: 'Class III' }]
  },
  bridgeEngineer: { type: 'dataContact', name: 'Bridge Manager', readonly: false, show: true },
  chiefEngineer: { type: 'dataContact', name: 'Chief Engineer', readonly: false, show: true },
}

export const subdivisionFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
  manager: { type: 'dataContact', name: 'Manager', readonly: false, show: true },
  roadMaster: { type: 'dataContact', name: 'Road Master', readonly: false, show: true },
  bridgeProgramManager: { type: 'dataContact', name: 'Bridge Program Manager', readonly: false, show: true },
  milepostIncreases: { type: 'list', name: 'Mile Post Increases', readonly: false, show: false },
  directionalLoading: { type: 'select', name: 'Type', readonly: false, show: false, list: 'directionalLoadings' },
}


export const bridgeFields = {
  archived: { type: 'bool', name: 'Archived', readonly: false, show: false },
  activeFaults: { type: 'bool', name: 'Active Faults', readonly: false, show: false },
  idString: { type: 'string', name: 'Bridge ID', readonly: false, show: true },
  location: {
    type: 'location', name: 'Location', readonly: false, show: true, toText: item => {
      if (item.location && item.location !== "") {
        let result = JSON.parse(item.location);
        return result.formatted_address;
      } else return "";
    }
  },
  crossingFeature: { type: 'string', name: 'Crossing Feature', readonly: false, show: true },
  dangerousFlora: { type: 'bool', name: 'Dangerous Flora', readonly: false, show: false },
  dangerousFloraDescription: { type: 'string', name: 'Dangerous Flora Description', readonly: false, show: false },
  dangerousFauna: { type: 'bool', name: 'Dangerous Fauna', readonly: false, show: false },
  dangerousFaunaDescription: { type: 'string', name: 'Dangerous Fauna Description', readonly: false, show: false },
  latitude: { type: 'number', name: 'Latitude', readonly: false, show: false },
  longitude: { type: 'number', name: 'Longitude', readonly: false, show: false },
  milePostIncreases: {
    type: 'select', name: 'Mile Post Increases', readonly: false, show: false,
    list: [{ id: 'North to South', name: 'North to South' }, { id: 'South to North', name: 'South to North' },
    { id: 'East to West', name: 'East to West' }, { id: 'West to East', name: 'West to East' }]
  },
  bridgeType: { type: 'select', name: 'Bridge Type', readonly: false, show: false, list: 'bridgeTypes' },
  nearesthyrailaccessupmile: {
    type: 'location', name: 'Nearest Hyrail Access Up Mile', readonly: false, show: false, toText: item => {
      if (item.location && item.location !== "") {
        let result = JSON.parse(item.location);
        return result.formatted_address;
      } else return "";
    }
  },
  nearesthyrailaccessdownmile: {
    type: 'location', name: 'Nearest Hyrail Access Down Mile', readonly: false, show: false, toText: item => {
      if (item.location && item.location !== "") {
        let result = JSON.parse(item.location);
        return result.formatted_address;
      } else return "";
    }
  },
  tidalwaters: { type: 'bool', name: 'Tidal Waters', readonly: false, show: false }
}

export const assemblyFields = {
  order: { type: 'number', name: 'Order', readonly: false, show: true, toText: item => "Assembly " + item.order },
  assemblyType: { type: 'select', name: 'Type', readonly: false, show: false, list: 'assemblyTypes' },
  assemblyName: { type: 'string', name: 'Name', readonly: false, show: true, toText: item => " " + item.assemblyName },
}

export const assemblyFieldsDB = {
  "id": 0,
  "name": "Assembly",
  "icon": "fafa",
  "fields": [],
  "level": 1
}

export const assemblyTypeFields = {
  organization: { type: "select", name: "organization", label: "Organization", required: true, onlyForSuperAdmin: true, defaultValue: null },
  structureType: { type: 'select', name: "structureType", label: 'Structure Type', defaultValue: null, required: true },
  abutment: { type: "bool", name: "abutment", label: "Is Abutment", defaultValue: false },
  typeOfApproach: { type: "select", name: "typeOfApproach", label: "Approach Type", defaultValue: null },
  namingConvention: { type: 'string', name: 'namingConvention', label: "Assembly Name", required: false, defaultValue: "" },
  addOrderIntoName: { type: "bool", name: "addOrderIntoName", label: "Add Order to Name", defaultValue: false },
  abbreviation: { type: 'string', name: 'abbreviation', label: "Abbreviation", required: false, defaultValue: "" },
  name: { type: 'string', name: 'name', label: "Assembly Type Name", required: true, defaultValue: "" },
  icon: { type: 'string', name: 'icon', label: "Icon", defaultValue: "" },
  blocked: { type: "bool", name: "blocked", label: "Blocked", defaultValue: false },
  fields: { type: 'fieldsList', name: "fields", label: 'Fields', defaultValue: [] }
}

export const componentFields = {
  componentName: { type: 'string', name: 'Name', readonly: false, show: true, toText: item => "Component " + item.componentName },
  componentOrder: { type: 'number', name: 'Order', readonly: false, show: true, toText: item => "Component " + item.componentOrder },
  componentType: { type: 'select', name: 'Type', readonly: false, show: false, list: 'componentTypes' },
  componentSpacing: { type: 'number', name: 'Spacing', readonly: false, show: true, toText: item => "(" + (item.componentSpacing || "0") + " f)" }
}

export const componentTypeFields = {
  organization: { type: "select", name: "organization", label: "Organization", onlyForSuperAdmin: true, defaultValue: null, required: true },
  name: { type: 'string', name: "name", label: 'Name', defaultValue: "", required: true },
  icon: { type: 'string', name: "icon", label: 'Icon', defaultValue: "" },
  assemblyTypes: { type: 'typeahead', name: "assemblyTypes", label: 'Assembly Types', placeHolder: "Assembly Types...", defaultValue: [] },
  componentGroup: { type: 'select', name: "componentGroup", label: 'Component Group', defaultValue: null },
  namingConvention: { type: 'string', name: 'namingConvention', label: "Naming Convention", required: false, defaultValue: "" },
  abbreviation: { type: 'string', name: 'abbreviation', label: "Abbreviation", required: false, defaultValue: "" },
  addOrderIntoName: { type: "bool", name: "addOrderIntoName", label: "Add Order to Name", defaultValue: false },
  preselected: { type: "bool", name: "preselected", label: "Add a bridge default component", defaultValue: false },
  blocked: { type: "bool", name: "blocked", label: "Blocked", defaultValue: false },
  fields: { type: 'fieldsList', name: "fields", label: 'Fields', defaultValue: [] }
}

export const superStructureMaterialFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true, toText: item => " " + item.superStructureMaterialName },
}

export const componentGroupFields = {
  organization: { type: "select", name: "Organization", readonly: false, show: true },
  name: { type: 'string', name: 'Name', readonly: false, show: true, toText: item => " " + item.componentGroupName }
}

export const eventTypeFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true, toText: item => " " + item.eventTypeName },
}

export const inspectionTypeFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true, toText: item => " " + item.inspectionTypeName },
}

export const roleFields = {
  organization: { type: 'select', name: "organization", label: 'Organization', required: true, onlyForSuperAdmin: true, defaultValue: null },
  name: { type: 'string', name: "name", label: 'Name', required: true, defaultValue: "" },
  canWorkOnReports: { type: "bool", name: "canWorkOnReports", label: "Can work on reports", defaultValue: false },
  claims: { type: 'duallist', name: "claims", label: 'Claims', defaultValue: [] },
}

export const claimFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
  key: { type: 'string', name: 'Key', readonly: false, show: true },
  description: { type: 'string', name: 'description', readonly: false, show: true }
}

export const structureTypeFields = {
  organization: { type: "select", name: "organization", label: "Organization", required: true, onlyForSuperAdmin: true, defaultValue: null },
  assetType: { type: 'select', name: "assetType", label: 'Asset Type', required: true, defaultValue: null },
  name: { type: 'string', name: "name", label: 'Name', required: true, defaultValue: "" },
  order: { type: 'number', name: "order", label: 'Order', defaultValue: 0 },
  icon: { type: 'string', name: "icon", label: 'Icon', defaultValue: "" },
  type: { type: 'select', name: "type", label: 'Type', required: false, defaultValue: null },
  includeInInspections: { type: 'bool', name: "includeInInspections", label: 'Include in Inspections', defaultValue: false },

}

export const impactTypeFields = {
  organization: { type: "select", name: "Organization", readonly: false, show: true },
  name: { type: 'string', name: 'Name', readonly: false, show: true, toText: item => " " + item.impactTypeName }
}

export const assetTypeFields = {
  //this field is used for editions
  organization: {type: "select", name: "organization", label:"Organization", readonly: false, show: true},
  name: { type: 'string', name: 'name', label:"Name", readonly: false, show: true },
  icon: { type: 'string', name: 'icon', label:"Icon", readonly: false, show: true },
  level: { type: 'number', name: 'level', label:"Allowed Level", readonly: false, show: true },
  fields: { type: 'stringList', name: 'fields', label:"Fields", readonly: false, show: true },
}

export const crossingFeatureFields = {
  organization: { type: "select", name: "Organization", readonly: false, show: true },
  name: { type: 'string', name: 'Name', readonly: false, show: true, toText: item => " " + item.crossingFeatureName },
}

export const assetEventFields = {
  idString: { type: 'string', name: 'Bridge ID', readonly: false, show: true },
  latitude: { type: 'number', name: 'Latitude', readonly: false, show: false },
  longitude: { type: 'number', name: 'Longitude', readonly: false, show: false },
}

export const directionalLoadingFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
}

export const waterFlowFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
}

export const alignmentFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
}

export const deckTypeFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
}

export const sectionFields = {
  order: { type: 'number', name: 'Order', readonly: false, show: true, min: 0, toText: item => "Section " + item.order },
  location: { type: 'location', name: 'Location', readonly: false, show: false },
  type: { type: 'select', name: 'Type', readonly: false, show: false, list: 'superstructures' },
  deckType: { type: 'string', name: 'Deck Type', readonly: false, show: false },
  numberOfWalkways: { type: 'number', name: 'Number of Walkways', readonly: false, show: false },
  yearBuilt: { type: 'number', name: 'Year Built', readonly: false, show: false },
  planOnFile: { type: 'string', name: 'Plan on File', readonly: false, show: false },
  length: { type: 'number', name: 'Length', readonly: false, show: true, min: 0, toText: item => "(" + (item.length || "0") + " f)" },
  maximumStructureHeight: { type: 'number', name: 'Maximum Structure Height', readonly: false, show: false },
  latitudeStart: { type: 'number', name: 'Latitude Start', readonly: false, show: false },
  longitudeStart: { type: 'number', name: 'Longitude Start', readonly: false, show: false },
  impactType: { type: 'select', name: 'Impact Type', readonly: false, show: false, list: 'impactTypes' },
  superstructure: {
    type: 'select', name: 'Superstructure', readonly: false, show: false
  },
  numberofTracks: { type: 'number', name: 'Number of Tracks', readonly: false, show: false },
  railSize: { type: 'number', name: 'Rail Size', readonly: false, show: false },
  degreeOfCurve: { type: 'string', name: 'Degree Of Curve', readonly: false, show: false },
  superElevation: { type: 'string', name: 'Super Elevation', readonly: false, show: false },
  subjectToScour: { type: 'bool', name: 'Subject To Scour', readonly: false, show: false },
  vegetationRemovalNeeded: { type: 'bool', name: 'Vegetation Removal Needed', readonly: false, show: false },
  corrosiveMaterialsExposure: { type: 'bool', name: 'Corrosive Materials Exposure', readonly: false, show: false },
  subjectToHeavyDrift: { type: 'bool', name: 'Subject To Heavy Drift', readonly: false, show: false },
  dangerousFauna: { type: 'bool', name: 'Dangerous Fauna', readonly: false, show: false },
  dangerousFlora: { type: 'bool', name: 'Dangerous Flora', readonly: false, show: false },
  tidalWaters: { type: 'bool', name: 'Tidal Waters', readonly: false, show: false },
}

export const tripFields = {
  eventType: { type: 'select', name: 'Type of Inspection*', readonly: false, show: false, list: 'typeInspection' },
  name: { type: 'string', name: 'Name of Trip*', readonly: false, show: true },
  description: { type: 'string', name: 'Description*', readonly: false, show: true },
  dateFrom: { type: 'date', name: 'Date From*', readonly: false, show: true },
  dateTo: { type: 'date', name: 'Date To*', readonly: false, show: true },
  tripUsers: { type: 'list', name: 'Select Users', readonly: false, show: true }
}

export const eventFields = {
  sections: { type: 'duallist', name: 'Sections', readonly: false, show: false },
  eventType: { type: 'select', name: 'Type of Event*', readonly: false, show: false, list: 'typeEvent' },
  inspectionType: { type: 'select', name: 'Type of Inspection*', readonly: false, show: false, list: 'typeInspection' },
  description: { type: 'string', name: 'Description', readonly: false, show: true },
  eventsUser: { type: 'list', name: 'Select Users', readonly: false, show: true },
  dateOfInspection: { type: 'date', name: 'Date', readonly: false, show: true }
}

export const contactFields = {
  firstName: { type: 'string', name: 'First Name', readonly: false, show: true },
  lastName: { type: 'string', name: 'Last Name', readonly: false, show: true },
  email: { type: 'email', name: 'Email', readonly: false, show: true },
  phone: { type: 'string', name: 'Phone', readonly: false, show: true },
  jobTitle: { type: 'list', name: 'jobTitle', readonly: false, show: true },
  organization: { type: 'list', name: 'Organization', readonly: false, show: false },
  address: {
    type: 'location', name: 'Address', readonly: false, show: true, toText: item => {
      if (item.address && item.address !== "") {
        let result = JSON.parse(item.location);
        return result.address_components[0].short_name + ", " + result.address_components[1].short_name + ", " + result.address_components[2].long_name;
      } else return "";
    }
  },
}

export const accessTypeFields = {
  name: { type: 'string', name: 'name', label:'Name', readonly: false, show: true },
}

export const jobTitleFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
}

export const organizationFields = {
  name: { type: 'string', name: 'Name', readonly: false, show: true },
  description: { type: 'string', name: ' Description', readonly: false, show: true, optional: true },
  abbreviation: { type: 'string', name: 'Client Abbreviation', readonly: false, show: true }
}

export const addConditionFields = {
  organization: { type: "select", name: "organization", label: "Organization", required: true, defaultValue: null, onlyForSuperAdmin: true },
  name: { type: "string", name: "name", label: "Name", defaultValue: "", required: true },
  order: { type: "number", name: "order", label: "Order", defaultValue: 0 },
  measurement: { type: "number", name: "measurement", label: "Measurement", defaultValue: 0 },
  description: { type: "textarea", name: "description", label: "Description", defaultValue: "" },
  assemblyTypes: { type: "typeahead", name: "assemblyTypes", label: "Assembly Types", placeHolder: "Assembly Types...", defaultValue: []},
  componentTypes: { type: "typeahead", name: "componentTypes", label: "Component Types", placeHolder: "Component Types...", defaultValue: [] },
  showInDiagram: { type: "bool", name: "showInDiagram", label: "Show in Diagram", defaultValue: false }
};

export const conditionFields = {
  organization: { type: "select", name: "organization", label: "Organization", required: true, defaultValue: null, onlyForSuperAdmin: true },
  name: { type: "string", name: "name", label: "Name", defaultValue: "", required: true },
  order: { type: "number", name: "order", label: "Order", defaultValue: 0 },
  measurement: { type: "number", name: "measurement", label: "Measurement", defaultValue: 0 },
  description: { type: "textarea", name: "description", label: "Description", defaultValue: "" },
  assemblyType: { type: 'select', name: "assemblyType", label: 'Assembly Type', defaultValue: null },
  componentType: { type: 'select', name: "componentType", label: 'Component Type', defaultValue: null },
  showInDiagram: { type: 'bool', name: "showInDiagram", label: 'Show in Diagram', defaultValue: false }
}

const baseElementFields = {
  organization: { type: typeSelect, name: "organization", label: "Organization", required: true, defaultValue: null, onlyForSuperAdmin: true, disableForEdition: true },
  name: { type: typeString, name: "name", label: "Name", defaultValue: "", required: true },
  order: { type: typeNumber, name: "order", label: "Order", defaultValue: 0 }
}

export const addElementFields = {
  ...baseElementFields,
  assemblyTypes: { type: typeTypeahead, name: "assemblyTypes", label: "Assembly Types", defaultValue: [] },
  componentTypes: { type: typeTypeahead, name: "componentTypes", label: "ComponentTypes", defaultValue: [] }
}

export const editElementFields = {
  ...baseElementFields,
  assemblyType: { type: typeSelect, name: "assemblyType", label: "Assembly Type", defaultValue: null, disableForEdition: true },
  componentType: { type: typeSelect, name: "componentType", label: "Component Type", defaultValue: null, disableForEdition: true }
}


const baseLocationFields = {
  organization: { type: "select", name: "organization", label: "Organization", defaultValue: null, required: true, onlyForSuperAdmin: true, disableForEdition: true },
  name: { type: 'string', name: "name", label: 'Name', required: true, defaultValue: "" }
}

export const addLocationFields = {
  ...baseLocationFields,
  assemblyTypes: { type: "typeahead", name: "assemblyTypes", label: "Assembly Types", placeHolder: "Assembly Types...", defaultValue: []},
  componentTypes: { type: "typeahead", name: "componentTypes", label: "Component Types", placeHolder: "Component Types...", defaultValue: [] },
}

export const locationFields = {
  ...baseLocationFields,
  assemblyType: { type: 'select', name: "assemblyType", label: 'Assembly Type', defaultValue: null, disableForEdition: true },
  componentType: { type: 'select', name: "componentType", label: 'Component Type', defaultValue: null, disableForEdition: true },
}

export const eventConditionFields = {
  condition: { type: 'list', name: 'Condition', readonly: false, show: true },
  locations: { type: 'list', name: 'Locations', readonly: false, show: true }
}

export const eventRecommendationFields = {
  recommendation: { type: 'string', name: 'Recommendation', readonly: false, show: true },
  priority: { type: 'number', name: 'Priority', readonly: false, show: false },
}

export const eventNoteFields = {
  note: { type: 'string', name: 'Note', readonly: false, show: true },
  priority: { type: 'number', name: 'Priority', readonly: false, show: false },
  locations: { type: 'list', name: 'Locations', readonly: false, show: true }
}

export const assetFieldsForMultiBridge = [
  { name: "milePost", type: "number", label: "Milepost" },
  { name: "name", type: "string", label: "Culvert ID", minWidth: 100 },
  { name: "numSections", type: "number", label: "No. of Sections", minWidth: 80, args: { min: 0, step: 1 }, integer: true },
  { name: "location", type: "location", label: "Location" },
  { name: "latitudeStart", type: "number", label: "Latitude" },
  { name: "longitudeStart", type: "number", label: "Longitude" }
]

export const assetFieldsForMultiSection = [
  { name: "superstructure", type: "select", label: "Superstructure" },
  { name: "milePost", type: "number", label: "Milepost" },
  { name: "numberOfSpansSection", type: "number", label: "No. of Spans", args: { min: 0, step: 1 }, integer: true },
  { name: "deckType", type: "select", label: "Deck Type" },
  { name: "maximumStructureHeight", type: "number", label: "Section Height (ft)", args: { min: 0 } },
  { name: "length", type: "number", label: "Section Length (ft)", args: { min: 0 } }
]
