import { Content } from 'components/Layout';
import React from 'react';

const EmptyLayout = ({ children, ...restProps }) => (
  <main className="cr-app" {...restProps}>
    <Content fluid className="bg-gradient">
      {children}
    </Content>
  </main>
);

export default EmptyLayout;
