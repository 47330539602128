import { all, call, put, takeLatest, select, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiAssetType from 'apiServices/ApiAssetType'

import { clearAssetTypeErrors } from '../actions/AssetTypeActions';
import { showRecordAddedToast, showRecordDeletedToast, showRecordUpdatedToast } from '../actions/ToastActions';

const recordTypeName = "asset type";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The asset type cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_ASSETTYPE_PROPS, props });
}

/*
 * get AssetTypes
 */

function* getAssetTypes() {
  try {
    let loadedAssetTypes = yield select(store=>store.assetType.assetTypes)
    //if we have data in store we dont reload it

    if(loadedAssetTypes.length === 0){

    yield loading();

    // get item types
    const allItems = yield call(ApiAssetType.getAll);
      // update state for items
      yield put({
        type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
          assetTypes: allItems,
          loading: false,
          errors: ""
        }
      });
    }
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchAssetTypes(action) {
  try {
    yield loading();
    // get all AssetTypes
    const assetTypes = yield call(ApiAssetType.searchAssetTypes, action.props);
    const pagination = {
      number: assetTypes.number,
      numberOfElements: assetTypes.numberOfElements,
      totalElements: assetTypes.totalElements,
      totalPages: assetTypes.totalPages,
      size: assetTypes.size
    };
    // update state for assetTypes
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
        assetTypes: assetTypes.content,
        pagination: pagination,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getAssetType(action) {
  try {
    yield loading();
    // get  AssetType
    const assetType = yield call(ApiAssetType.getAssetType, action.props.assetTypeId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: assetType.name,
        id: assetType.id,
        type: "assetType"
      }
    });
    // update state for assetTypes
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
        assetType: assetType,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}


function* getAllByClient(action) {
  try {
    yield loading();
    // get  AssetType
    const allItems  = yield call(ApiAssetType.getAllByClient, action.props);
      // update state for items
      yield put({
        type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
          assetTypesByClient: allItems,
          loading: false,
          errors: ""
        }
      });

      if (action.props.callback) {
        yield call(action.props.callback);
      }
      
  } catch (error) {
    yield loading(false, error);
  }

}

function* getAllAssetsByOrganization() {
  try {

    let assetTypesLoaded = yield select(store=>store.assetType.assetTypesByOrganization)
    if(!assetTypesLoaded || assetTypesLoaded.length===0){
    yield loading();
    // get  AssetType
    const allItems  = yield call(ApiAssetType.getAllAssetsByOrganization);
      // update state for items
      yield put({
        type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
          assetTypesByOrganization: allItems,
          loading: false,
          errors: ""
        }
      });
    }
      
  } catch (error) {
    yield loading(false, error);
  }

}


function* editAssetType(action) {

  yield put(clearAssetTypeErrors());

  try {
    yield loading();
    // update  AssetType
    yield call(ApiAssetType.editAssetType, action.props);
    // update state for assetTypes
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
        assetType: {},
        loading: false,
        errors: ""
      }
    });

    yield put(showRecordUpdatedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }

}

function* addAssetType(action) {

  yield put(clearAssetTypeErrors());

  try {
    yield loading();

    // add  AssetType
    yield call(ApiAssetType.addAssetType, action.props);
    // update state for assetTypes
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
        assetType: {},
        loading: false,
        errors: ""
      }
    });

    yield put(showRecordAddedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteAssetType(action) {

  yield put(clearAssetTypeErrors());

  try {
    yield loading();
    // delete  AssetType
    yield call(ApiAssetType.deleteAssetType, action.props);

    // update state for assetTypes
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
        loading: false,
        errors: ""
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }


  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteAssetTypeList(action) {

  yield put(clearAssetTypeErrors());

  try {
    yield loading();

    yield call(ApiAssetType.deleteAssetTypeList, action.props.list);

    // update state for assetTypes
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
        loading: false,
        errors: ""
      }
    });


    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* getAssetTypeByAsset(action) {
  try {
    yield loading();
    // get item types
    const assetTypes = yield call(ApiAssetType.getAssetTypeByAsset, action.props);
    // update state for items
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_PROPS, props: {
        assetTypes: assetTypes,
        loading: false,
        errors: ""
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiAssetType.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_ASSETTYPE_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    });
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ASSETTYPE_ALL, getAssetTypes),
    takeLatest(actionTypes.SAGA_ASSETTYPE_ALL_BY_CLIENT, getAllByClient),
    takeLatest(actionTypes.SAGA_ASSETTYPE_ALL_BY_ORGANIZATION, getAllAssetsByOrganization),
    takeLatest(actionTypes.SAGA_ASSETTYPE_SEARCH, searchAssetTypes),
    takeLatest(actionTypes.SAGA_ASSETTYPE_GET, getAssetType),
    takeLatest(actionTypes.SAGA_ASSETTYPE_EDIT, editAssetType),
    takeLatest(actionTypes.SAGA_ASSETTYPE_ADD, addAssetType),
    takeLatest(actionTypes.SAGA_ASSETTYPE_DELETE, deleteAssetType),
    takeLatest(actionTypes.SAGA_ASSETTYPE_BY_ASSET, getAssetTypeByAsset),
    takeLatest(actionTypes.SAGA_ASSETTYPE_DELETE_CHECKED, deleteAssetTypeList),
    takeLatest(actionTypes.SAGA_ASSETTYPE_LOAD_ORGANIZATIONS, loadOrganizations)
  ]);
}

export default AuthWatcher;
