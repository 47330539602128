import { API_SERVER } from 'config/constants'
import Utils from 'common/utils'

const endpoint = API_SERVER + '/condition';

export default class ApiCondition {

  // GET 'api/conditions'
  static getAll() {
    return Utils.genericCall(`${endpoint}/all`);
  }

  static searchConditions(props) {
    return Utils.genericPost(`${endpoint}/paged`, props);
  }

  static getCondition(id) {
    return Utils.genericCall(`${endpoint}/${id}`);
  }

  static editCondition(data) {
    return Utils.genericPut(`${endpoint}/${data.id}`, data);
  }

  static addCondition(data) {
    return Utils.genericPost(endpoint, data);
  }

  static deleteCondition(id) {
    return Utils.genericDelete(`${endpoint}/${id}`);
  }

  static deleteConditionList(list) {
    return Utils.genericPost(`${endpoint}/delete`, list);
  }

  static getConditionsByComponentType(componentTypeId) {
    return Utils.genericCall(`${endpoint}/componentType/${componentTypeId}`);
  }

  static getConditionsByAssemblyType(assemblyTypeId) {
    return Utils.genericCall(`${endpoint}/assemblyType/${assemblyTypeId}`);
  }

  static getOrganizations() {
    return Utils.genericCall(`${endpoint}/organizations`);
  }

  static getAssemblyTypes() {
    return Utils.genericCall(`${endpoint}/assemblyTypes`);
  }

  static getComponentTypes() {
    return Utils.genericCall(`${endpoint}/componentTypes`);
  }
}
