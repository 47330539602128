import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';
import withSubobjectsLoadReducer, { createState } from './generic/withSubobjectsLoadReducer';


const initialState = {
  assets: [],
  assetsForTrip: [],
  assetSelectedForTrip: [],
  clientSelectedFroTrip: [],
  railroadSelectedForTrip: [],
  subdivisionSelectedForTrip: [],
  asset: {},
  assetLevelOne: {},
  pagination: {},
  formValues: null,
  allFields: [],
  openModalReport: false,
  assetFromInspections: {},
  loading: false,
  errors: '',

  assetTypes: createState(),
  crossingFeatures: createState(),
  waterFlows: createState(),
  alignments: createState(),
  impactTypes: createState(),
  clients: createState()
}

function reducerAsset (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_ASSET_PROPS:
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_ASSET_PROPS:
      return initialState;

    default:
      return state
  }
}

let wrappedReducer = onLoginCleanupReducer(initialState, reducerAsset);

export default withSubobjectsLoadReducer(
  wrappedReducer,
  {
    assetTypes: [
      actionTypes.CHANGE_ASSET_START_LOADING_ASSET_TYPES,
      actionTypes.CHANGE_ASSET_SUCCESS_LOADING_ASSET_TYPES,
      actionTypes.CHANGE_ASSET_ERROR_LOADING_ASSET_TYPES
    ],
    crossingFeatures: [
      actionTypes.CHANGE_ASSET_START_LOADING_CROSSING_FEATURES,
      actionTypes.CHANGE_ASSET_SUCCESS_LOADING_CROSSING_FEATURES,
      actionTypes.CHANGE_ASSET_ERROR_LOADING_CROSSING_FEATURES
    ],
    waterFlows: [
      actionTypes.CHANGE_ASSET_START_LOADING_WATER_FLOWS,
      actionTypes.CHANGE_ASSET_SUCCESS_LOADING_WATER_FLOWS,
      actionTypes.CHANGE_ASSET_ERROR_LOADING_WATER_FLOWS
    ],
    alignments: [
      actionTypes.CHANGE_ASSET_START_LOADING_ALIGNMENTS,
      actionTypes.CHANGE_ASSET_SUCCESS_LOADING_ALIGNMENTS,
      actionTypes.CHANGE_ASSET_ERROR_LOADING_ALIGNMENTS
    ],
    impactTypes: [
      actionTypes.CHANGE_ASSET_START_LOADING_IMPACT_TYPES,
      actionTypes.CHANGE_ASSET_SUCCESS_LOADING_IMPACT_TYPES,
      actionTypes.CHANGE_ASSET_ERROR_LOADING_IMPACT_TYPES
    ],
    clients: [
      actionTypes.CHANGE_ASSET_START_LOADING_CLIENTS,
      actionTypes.CHANGE_ASSET_SUCCESS_LOADING_CLIENTS,
      actionTypes.CHANGE_ASSET_ERROR_LOADING_CLIENTS
    ]
  }
)
