import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiRolPage from 'tests/mocks/ApiRolPage.mock'
import ApiRolPage from 'apiServices/ApiRolPage'

/*
 * get RolPages
 */
function* getRolPages(){
  // get first page RolPages
  yield searchRolPages({props: {search:'', page: 0}});
}

function* searchRolPages(action){
  // get all RolPages
  const rolPages = yield call(ApiRolPage.searchRolPages, action.props);
  // update state for rolPages
  yield put({ type: actionTypes.CHANGE_ROLPAGE_PROPS, props:{
    rolPages: rolPages.content,
  }});
}

function* getRolPage(action){
  // get  RolPage
  const rolPage = yield call(ApiRolPage.getRolPage, action.props.rolPageId);
  yield putResolve({type: actionTypes.CHANGE_NAMES_PROPS, props:{
    name: rolPage.code,
    id: rolPage.id,
    type: "rolPage"
  }});
  // update state for rolPages
  yield put({ type: actionTypes.CHANGE_ROLPAGE_PROPS, props:{
    rolPage: rolPage,
  }});
}

function* editRolPage(action){
  // update  RolPage
  yield call(ApiRolPage.editRolPage, action.props);
  // update state for rolPages
  yield put({ type: actionTypes.CHANGE_ROLPAGE_PROPS, props:{
    rolPage: {},
  }});
  yield getRolPage({props: {rolPageId: action.props.id}});
}

function* addRolPage(action){
  // add  RolPage
  yield call(ApiRolPage.addRolPage, action.props);
  // update state for rolPages
  yield put({ type: actionTypes.CHANGE_ROLPAGE_PROPS, props:{
    rolPage: {},
  }});
  yield getByRol({props: {rolId: action.props.rol.id}});
}

function* deleteRolPage(action){
  // delete  RolPage
  yield call(ApiRolPage.deleteRolPage, action.props.id);
  yield getByRol({props: {rolId: action.props.rol.id}});
}

function* getByRols(action) {
  // get all rolPages by rols
  const rolPages = yield call(ApiRolPage.getByRols, action.props);
  // update state for rolPages
  yield put({ type: actionTypes.CHANGE_ROLPAGE_PROPS, props:{
    rolPages: rolPages.content,
  }});
}

function* getByRol(action) {
  const rolPages = yield call(ApiRolPage.getByRol, action.props.rolId);
  yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: {level:1, ids: action.props, type: 'rol'}});
  // get rol rolPages
  yield put({ type: actionTypes.CHANGE_ROLPAGE_PROPS, props:{
    rolPages: rolPages,
  }});
}

function* searchByRol(action){
  // get all rolPages by rol
  const rolPages = yield call(ApiRolPage.searchByRol, action.props);
  // update state for rolPages
  yield put({ type: actionTypes.CHANGE_ROLPAGE_PROPS, props:{
    rolPages: rolPages.content,
  }});
}
/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ROLPAGE_ALL, getRolPages),
    takeLatest(actionTypes.SAGA_ROLPAGE_SEARCH, searchRolPages),
    takeLatest(actionTypes.SAGA_ROLPAGE_GET, getRolPage),
    takeLatest(actionTypes.SAGA_ROLPAGE_EDIT, editRolPage),
    takeLatest(actionTypes.SAGA_ROLPAGE_ADD, addRolPage),
    takeLatest(actionTypes.SAGA_ROLPAGE_DELETE, deleteRolPage),
    takeLatest(actionTypes.SAGA_ROLE_ROLPAGES, getByRol),
    takeLatest(actionTypes.SAGA_ROLE_ROLPAGES_SEARCH, searchByRol),
    takeLatest(actionTypes.SAGA_ROLPAGE_BY_ROLS, getByRols),
  ]);
}

export default AuthWatcher;
