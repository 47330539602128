import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiSectionEvent from 'tests/mocks/ApiSectionEvent.mock'
import ApiSectionEvent from 'apiServices/ApiSectionEvent'


/*
 * get SectionEvents
 */
function* getSectionEvents(action){
  // get all sectionEvents
  const allSectionEvents = yield call(ApiSectionEvent.getAll, action.props);
  // update state for sectionEvents
  yield put({ type: actionTypes.CHANGE_SECTIONEVENT_PROPS, props:{
    sectionEvents: allSectionEvents,
  }});
}

function* getSectionEvent(action){
  // get sectionEvent
  const sectionEvent = yield call(ApiSectionEvent.getSectionEvent, action.props.sectionEventId);
  yield put({type: actionTypes.CHANGE_NAMES_PROPS, props:{
    name: 'Section ' + sectionEvent.order,
    id: sectionEvent.id,
    type: "sectionEvent"
  }});
  if (Object.keys(action.props).length > 1)
    yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: {level:5, ids: action.props, type: 'events'}});
  // update state for sectionEvents
  yield put({ type: actionTypes.CHANGE_SECTIONEVENT_PROPS, props:{
    sectionEvent: sectionEvent,
  }});
}

function* getByAssetEvent(action) {
  if (Object.keys(action.props).length > 1)
    yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: {level:5, ids: action.props, type: 'events'}});
  const sectionEvents = yield call(ApiSectionEvent.getByAssetEvent, action.props.assetEventId);
  // get all sectionEvents by AssetEvent
  yield put({ type: actionTypes.CHANGE_SECTIONEVENT_PROPS, props:{
    sectionEvents: sectionEvents,
  }});
}

function* searchSectionEvents(action){
  // get all sectionEvents
  const sectionEvents = yield call(ApiSectionEvent.searchSectionEvents, action.props);
  // update state for sectionEvents
  yield put({ type: actionTypes.CHANGE_SECTIONEVENT_PROPS, props:{
    sectionEvents: sectionEvents.content,
  }});
}

function* editSectionEvent(action){
  // update  sectionEvent
  yield call(ApiSectionEvent.editSectionEvent, action.props);
  // update state for sectionEvents
  yield put({ type: actionTypes.CHANGE_SECTIONEVENT_PROPS, props:{
    sectionEvent: {},
  }});
  yield getSectionEvent({props: {sectionEventId: action.props.id}});
}

function* addSectionEvent(action){
  // add  sectionEvent
  yield call(ApiSectionEvent.addSectionEvent, action.props);
  // update state for SectionEvents
  yield put({ type: actionTypes.CHANGE_SECTIONEVENT_PROPS, props:{
    sectionEvent: {},
  }});
  yield getByAssetEvent({props: {assetEventId:action.props.assetEvent.id}});
}

function* deleteSectionEvent(action){
  // delete sectionEvent
  yield call(ApiSectionEvent.deleteSectionEvent, action.props.id);
  yield getByAssetEvent({props: {assetEventId:action.props.assetEvent.id}});
}
/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_SECTIONEVENT_ALL, getSectionEvents),
    takeLatest(actionTypes.SAGA_SECTIONEVENT_GET, getSectionEvent),
    takeLatest(actionTypes.SAGA_SECTIONEVENT_SEARCH, searchSectionEvents),
    takeLatest(actionTypes.SAGA_SECTIONEVENT_EDIT, editSectionEvent),
    takeLatest(actionTypes.SAGA_SECTIONEVENT_ADD, addSectionEvent),
    takeLatest(actionTypes.SAGA_ASSETEVENT_SECTIONEVENTS, getByAssetEvent),
    takeLatest(actionTypes.SAGA_SECTIONEVENT_DELETE, deleteSectionEvent),
  ]);
}

export default AuthWatcher;
