import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
import ApiCrossingFeature from 'apiServices/ApiCrossingFeature'

import { clearCrossingFeatureErrors } from '../actions/CrossingFeatureActions';
import { showRecordAddedToast, showRecordDeletedToast, showRecordUpdatedToast } from '../actions/ToastActions';

const recordTypeName = "crossing feature";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The Crossing Feature cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_CROSSINGFEATURE_PROPS, props });
}

/*
 * get CrossingFeatures
 */
function* getCrossingFeatures() {
  try {
    yield loading();
    // get item types
    const allItems = yield call(ApiCrossingFeature.getAll);
    // update state for items
    yield put({
      type: actionTypes.CHANGE_CROSSINGFEATURE_PROPS, props: {
        crossingFeatures: allItems,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchCrossingFeatures(action) {
  try {
    yield loading();
    // get all CrossingFeatures
    const crossingFeatures = yield call(ApiCrossingFeature.searchCrossingFeatures, action.props);
    const pagination = {
      number: crossingFeatures.number,
      numberOfElements: crossingFeatures.numberOfElements,
      totalElements: crossingFeatures.totalElements,
      totalPages: crossingFeatures.totalPages,
      size: crossingFeatures.size
    };
    // update state for crossingFeatures
    yield put({
      type: actionTypes.CHANGE_CROSSINGFEATURE_PROPS, props: {
        crossingFeatures: crossingFeatures.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* getCrossingFeature(action) {
  try {
    yield loading();
    // get  CrossingFeature
    const crossingFeature = yield call(ApiCrossingFeature.getCrossingFeature, action.props.crossingFeatureId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: crossingFeature.name,
        id: crossingFeature.id,
        type: "crossingFeature"
      }
    });
    // update state for crossingFeatures
    yield put({
      type: actionTypes.CHANGE_CROSSINGFEATURE_PROPS, props: {
        crossingFeature: crossingFeature,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editCrossingFeature(action) {

  yield put(clearCrossingFeatureErrors());

  try {
    yield loading();
    // update  CrossingFeature
    yield call(ApiCrossingFeature.editCrossingFeature, action.props);
    // update state for crossingFeatures
    yield put({
      type: actionTypes.CHANGE_CROSSINGFEATURE_PROPS, props: {
        crossingFeature: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordUpdatedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }

}

function* addCrossingFeature(action) {

  yield put(clearCrossingFeatureErrors());

  try {
    yield loading();
    // add  CrossingFeature
    yield call(ApiCrossingFeature.addCrossingFeature, action.props);
    // update state for crossingFeatures
    yield put({
      type: actionTypes.CHANGE_CROSSINGFEATURE_PROPS, props: {
        crossingFeature: {},
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordAddedToast(recordTypeName));

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteCrossingFeature(action) {

  yield put(clearCrossingFeatureErrors());

  try {
    yield loading();

    // delete  CrossingFeature
    yield call(ApiCrossingFeature.deleteCrossingFeature, action.props);

    // update state for crossingFeatures
    yield put({
      type: actionTypes.CHANGE_CROSSINGFEATURE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteCrossingFeatureList(action) {

  yield put(clearCrossingFeatureErrors());

  try {
    yield loading();
    yield call(ApiCrossingFeature.deleteCrossingFeatureList, action.props.list);

    // update state for crossingFeatures
    yield put({
      type: actionTypes.CHANGE_CROSSINGFEATURE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordDeletedToast(recordTypeName));

    if (action.props.callback) {
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* loadOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_CROSSINGFEATURE_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiCrossingFeature.getOrganizations);
    yield put({
      type: actionTypes.CHANGE_CROSSINGFEATURE_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_CROSSINGFEATURE_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_CROSSINGFEATURE_ALL, getCrossingFeatures),
    takeLatest(actionTypes.SAGA_CROSSINGFEATURE_SEARCH, searchCrossingFeatures),
    takeLatest(actionTypes.SAGA_CROSSINGFEATURE_GET, getCrossingFeature),
    takeLatest(actionTypes.SAGA_CROSSINGFEATURE_EDIT, editCrossingFeature),
    takeLatest(actionTypes.SAGA_CROSSINGFEATURE_ADD, addCrossingFeature),
    takeLatest(actionTypes.SAGA_CROSSINGFEATURE_DELETE, deleteCrossingFeature),
    takeLatest(actionTypes.SAGA_CROSSINGFEATURE_DELETE_CHECKED, deleteCrossingFeatureList),
    takeLatest(actionTypes.SAGA_CROSSINGFEATURE_LOAD_ORGANIZATIONS, loadOrganizations)
  ]);
}

export default AuthWatcher;
