import * as actionTypes from './actionTypes'

export function getOrganizations(){
  return { type: actionTypes.SAGA_ORGANIZATION_ALL };
}

export function getOrganization(props){
  return { type: actionTypes.SAGA_ORGANIZATION_GET, props: props };
}

export function getOrganizationTypes(){
  return { type: actionTypes.SAGA_ORGANIZATION_TYPES };
}

export function loadOrganizationsPaged(props) {
  return { type: actionTypes.SAGA_ORGANIZATION_SEARCH, props: props };
}

/**
 * Deletes a list of organizations.
 *
 * @param {Array<string>} deleteList List of 'Organization' to be deleted. Each element
 * of the list should be an 'Organization' ID.
 * @param {Function} callback If set, a callback for when this action success. No parameters
 * will be supplied to this callback.
 */
export function deleteOrganizationChecked(deleteList, options = {callback: () => {}}) {

  const props = {
    deleteList: [...deleteList],
    callback: options.callback
  }

  return { type: actionTypes.SAGA_ORGANIZATION_DELETE_CHECKED, props }
}

export function addOrganization(props) {
  return { type: actionTypes.SAGA_ORGANIZATION_ADD, props };
}

export function editOrganization(props) {
  return { type: actionTypes.SAGA_ORGANIZATION_EDIT, props };
}

/*
 * Change Organization props (no saga action)
 */
export function changeOrganizationProps(props){
  return { type: actionTypes.CHANGE_ORGANIZATION_PROPS, props: props };
}

/**
 * Clears current organization props and sets default values.
 */
export function clearOrganizationProps() {
  return { type: actionTypes.CLEAR_ORGANIZATION_PROPS };
}

/**
 * Clears organization errors messages
 */
export function clearOrganizationErrors() {
  return { type: actionTypes.CLEAR_ORGANIZATION_ERRORS }
}

/**
 * Creates a Redux action that dispatch a request to get
 * the Organization of the current user.
 * @returns Redux action to dispatch.
 */
export function getCurrentUserOrganization() {
  return { type: actionTypes.SAGA_ORGANIZATION_CURRENT_USER };
}
