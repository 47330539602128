import { all, call, put, takeLatest, select } from 'redux-saga/effects'

import * as actionTypes from 'store/actions/actionTypes'
import loadModels from './shared/loadModels';
import ApiAsset from 'apiServices/ApiAsset'
import ApiEvent from 'apiServices/ApiEvent'
import {
  showRecordAddedToast,
  showRecordUpdatedToast,
  showToastAsSuccess
} from '../actions/ToastActions';

const assetRecordTypeName = "asset";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {

    if (errors.response.data.message) {
      let errorMessage = errors.response.data.message;
      errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
        'The asset cannot be removed because it is related to other objects in the application.'
        :
        'Something has not gone as it should.';
    } else {
      errors = 'Something has not gone as it should.';
    }

  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_ASSET_PROPS, props });
}

/*
 * get Assets
 */
function* getAssets(action) {
  try {
    yield loading();
    // get all assets
    const allAssets = yield call(ApiAsset.getAll, action.props);
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: allAssets,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getAssetById(params, title, assetId, level) {
  try {
    yield loading();
    // get asset
    const asset = yield call(ApiAsset.getAsset, assetId);
    yield put({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: asset.name,
        id: asset.id,
        type: title
      }
    });
    if (Object.keys(params).length > 1)
      yield put({ type: actionTypes.SAGA_NAMES_UPDATE, props: { level: level, ids: params } });
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        asset: asset,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getAsset(action) {
  yield getAssetById(action.props, "asset", action.props.assetId, 3);
}

function* getAssetChild(action) {
  yield getAssetById(action.props, "assetChild", action.props.assetChildId, 4);
}

function* searchBySubdivision(action) {
  try {
    yield loading();
    // get all SubDivision Assets
    const subdivisionAssets = yield call(ApiAsset.searchBySubdivision, action.props);
    const pagination = {
      number: subdivisionAssets.number,
      numberOfElements: subdivisionAssets.numberOfElements,
      totalElements: subdivisionAssets.totalElements,
      totalPages: subdivisionAssets.totalPages,
      size: subdivisionAssets.size
    };
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: subdivisionAssets.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchAssets(action) {
  try {
    yield loading();
    // get all Assets
    const assets = yield call(ApiAsset.searchAssets, action.props);
    const pagination = {
      number: assets.number,
      numberOfElements: assets.numberOfElements,
      totalElements: assets.totalElements,
      totalPages: assets.totalPages,
      size: assets.size
    };
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: assets.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }

}

function* editAsset(action) {
  try {
    yield loading();
    // update  asset
    yield call(ApiAsset.editAsset, action.props);
    yield put(showRecordUpdatedToast(assetRecordTypeName));

    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        asset: {},
        loading: false,
        errors: ''
      }
    });
    if (action.props.subdivision)
      yield getAsset({ props: { assetId: action.props.id } });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editAssetChild(action) {
  try {
    yield loading();
    // update  asset
    yield call(ApiAsset.editAsset, action.props);
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        asset: {},
        loading: false,
        errors: ''
      }
    });
    yield getAsset({ props: { assetId: action.props.rootAssetId } });
  } catch (error) {
    yield loading(false, error);
  }

}

function* addAsset(action) {
  try {
    yield loading();
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        asset: {},
        loading: false,
        errors: ''
      }
    });
    // add  asset
   let newAsset = yield call(ApiAsset.addAsset, action.props);

   //******************************* THIS GETS IS A CONDITIONAL BECAUSE WE DONT KNOW IF IS IT NECESSARY TO BUILD BRIDGES FROM ANOTHER SECTIONS ANYMORE ********************* */
   //todo: TEST CREATING BRIDGES FROM ANOTHER SECTIONS (NO BRIDGES TAB)
   if(!action.props.bridgesTab){
    if (action.props.parent)
      yield getByParent({ props: action.props.parent.id });
    if (action.props.rootAssetId)
      yield getAsset({ props: { assetId: action.props.rootAssetId } });
    if (!action.props.parent && !action.props.rootAssetId)
      yield searchBySubdivision({ props: { id: action.props.subdivision.id, search: '', page: 0, order: { id: "name", desc: false }, size: 10 } });
   }
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        newAsset: newAsset,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchByClient(action) {
  try {
    yield loading();
    // get all Client Assets
    const clientAssets = yield call(ApiAsset.searchByClient, action.props);
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: clientAssets.content,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchByRailroads(action) {
  try {
    yield loading();
    // get all Railroads Assets
    const railroadsAssets = yield call(ApiAsset.searchByRailroads, action.props);
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: railroadsAssets.content,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* searchBySubdivisions(action) {
  try {
    yield loading();
    // get all Subdivisions Assets
    const subdivisionsAssets = yield call(ApiAsset.searchBySubdivisions, action.props);
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: subdivisionsAssets.content,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getBySubdivision(action) {
  try {
    yield loading();
    // get all Subdivisions Assets
    const subdivisionsAssets = yield call(ApiAsset.getBySubdivision, action.props);
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: subdivisionsAssets,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getByParent(action) {
  try {
    yield loading();
    // get all Parent Assets
    const parentAssets = yield call(ApiAsset.getByParent, action.props);
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: parentAssets,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteAsset(action) {
  try {
    yield loading();
    // delete asset
    yield call(ApiAsset.deleteAsset, action.props.id);
    if (action.props.subdivision)
      yield searchBySubdivision({ props: { id: action.props.subdivision.id, search: '', page: 0, order: { id: "name", desc: false }, size: 10 } });
    if (action.props.rootAssetId && action.props.rootAssetId !== action.props.id)
      yield getAsset({ props: { assetId: action.props.rootAssetId } });

    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}









function* getAllFields(action) {
  try {
    yield loading();
    const existentModels = yield select(store => store.asset.allFields);

    if (existentModels.length > 0) {
      return;
    }

    // get all assets
    const allFields = yield call(ApiAsset.getAllFields, action.props);
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        allFields: allFields,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getChildren(action) {
  try {
    yield loading();
    // get all Parent Assets
    const children = yield call(ApiAsset.getChildren, action.props);
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: children,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteAssetChecked(action) {
  try {
    yield loading();
    yield call(ApiAsset.deleteAssetChecked, action.props.data);

    if(action.props.subdivision){
      yield searchBySubdivision(
        {
          props:
          {
            id: action.props.subdivision.id,
            search: '',
            page: 0,
            order: { id: 'name', desc: false }
          }
        }
      );
    }

    if(action.props.callback){
      yield call(action.props.callback);
    }

    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getAssetLevelOne(action) {
  try {
    yield loading();
    const asset = yield call(ApiAsset.getAsset, action.props.assetId);

    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assetLevelOne: asset,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getChildrenByStructureType(action) {
  try {
    yield loading();
    // get all Subdivisions Assets
    const assets = yield call(ApiAsset.getChildrenByStructureType, action.props);
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: assets,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* getSectionBySubdivision(action) {
  try {
    yield loading();
    // get all Subdivisions Sections
    const subdivisionsSections = yield call(ApiAsset.getSectionBySubdivision, action.props);
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: subdivisionsSections,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* addImmediateActions(action) {
  try {
    yield loading();

    // response an event
     yield call(ApiAsset.addImmediateActions, action.props.data);

    const event = yield call(ApiEvent.getEvent, action.props.data.event.id);

    // update state for events
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        event: event
      }
    });

    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        loading: false,
        errors: ''
      }
    });
    yield put(showToastAsSuccess("Success", "Changes saved."));
    action.props.callback();

  } catch (error) {
    // yield put(showToastAsError("Error", "There has been an error saving your data."));
    yield loading(false, error);
  }

}

function* searchAssetsForTrip(action) {
  try {
    yield loading();
    // get all Assets
    const assets = yield call(ApiAsset.searchAssetsForTrip, action.props);
    const pagination = {
      number: assets.number,
      numberOfElements: assets.numberOfElements,
      totalElements: assets.totalElements,
      totalPages: assets.totalPages,
      size: assets.size
    };
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assetsForTrip: assets.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchAssetsForTripCallback(action) {
  try {
    yield loading();
    // get all Assets
    const assets = yield call(ApiAsset.searchAssetsForTrip, action.props.filterObject);
    const pagination = {
      number: assets.number,
      numberOfElements: assets.numberOfElements,
      totalElements: assets.totalElements,
      totalPages: assets.totalPages,
      size: assets.size
    };
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assetsForTrip: assets.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
    yield call(action.props.callback);
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchByTrip(action){
  try {
    yield loading();
    // get all Assets
    const assets = yield call(ApiAsset.searchByTrip, action.props);
    const pagination = {
      number: assets.number,
      numberOfElements: assets.numberOfElements,
      totalElements: assets.totalElements,
      totalPages: assets.totalPages,
      size: assets.size
    };
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assetsForTrip: assets.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }
}

function* searchByTripCallback(action) {
  try {
    yield loading();
    // get all Assets
    const assets = yield call(ApiAsset.searchByTrip, action.props.filterObject);
    const pagination = {
      number: assets.number,
      numberOfElements: assets.numberOfElements,
      totalElements: assets.totalElements,
      totalPages: assets.totalPages,
      size: assets.size
    };
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assetsForTrip: assets.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
    yield call(action.props.callback);
  } catch (error) {
    yield loading(false, error);
  }
}

function* addMultiAsset(action) {
  try {
    yield loading();
    // add multi asset
    yield call(ApiAsset.addMultiAsset, action.props);
    // update state for assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    yield put(showRecordAddedToast(assetRecordTypeName));

  } catch (error) {
    yield loading(false, error);
  }
}


function* getBridgesForFilters(action) {
  try {
    yield loading();
    // get bridges for filters
    const bridges = yield call(ApiAsset.getBridgesForFilters, action.props);
    // update state for Assets
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        assets: bridges,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}


function* uploadAssetPhoto(action) {
  try {
    yield loading();
    yield call(ApiAsset.uploadAssetPhoto, action.props.assetId, action.props.data);

    // update event props
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if(action.props.callback){
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* addCoverPhoto(action) {
  try {
    yield loading();
    yield call(ApiAsset.addCoverPhoto, action.props.assetId, action.props.eventPhotoId);

    // update event props
    yield put({
      type: actionTypes.CHANGE_EVENT_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if(action.props.callback){
      yield call(action.props.callback);
    }

  } catch (error) {
    yield loading(false, error);
  }
}

function* getAssetTypes() {
  yield loadModels(
    ApiAsset.getAssetTypes,
    assetTypes => ({assetTypes}),
    state => state.asset.assetTypes.data,
    {
      onStart: actionTypes.CHANGE_ASSET_START_LOADING_ASSET_TYPES,
      onSuccess: actionTypes.CHANGE_ASSET_SUCCESS_LOADING_ASSET_TYPES,
      onError: actionTypes.CHANGE_ASSET_ERROR_LOADING_ASSET_TYPES
    }
  );
}

function* getCrossingFeatures() {
  yield loadModels(
    ApiAsset.getCrossingFeatures,
    crossingFeatures => ({crossingFeatures}),
    state => state.asset.crossingFeatures.data,
    {
      onStart: actionTypes.CHANGE_ASSET_START_LOADING_CROSSING_FEATURES,
      onSuccess: actionTypes.CHANGE_ASSET_SUCCESS_LOADING_CROSSING_FEATURES,
      onError: actionTypes.CHANGE_ASSET_ERROR_LOADING_CROSSING_FEATURES
    }
  )
}

function* getWaterFlows() {
  yield loadModels(
    ApiAsset.getWaterFlows,
    waterFlows => ({waterFlows}),
    state => state.asset.waterFlows.data,
    {
      onStart: actionTypes.CHANGE_ASSET_START_LOADING_WATER_FLOWS,
      onSuccess: actionTypes.CHANGE_ASSET_SUCCESS_LOADING_WATER_FLOWS,
      onError: actionTypes.CHANGE_ASSET_ERROR_LOADING_WATER_FLOWS
    }
  );
}

function* getAlignments() {
  yield loadModels(
    ApiAsset.getAlignments,
    alignments => ({alignments}),
    state => state.asset.alignments.data,
    {
      onStart: actionTypes.CHANGE_ASSET_START_LOADING_ALIGNMENTS,
      onSuccess: actionTypes.CHANGE_ASSET_SUCCESS_LOADING_ALIGNMENTS,
      onError: actionTypes.CHANGE_ASSET_ERROR_LOADING_ALIGNMENTS
    }
  );
}

function* getImpactTypes() {
  yield loadModels(
    ApiAsset.getImpactTypes,
    impactTypes => ({impactTypes}),
    state => state.asset.impactTypes.data,
    {
      onStart: actionTypes.CHANGE_ASSET_START_LOADING_IMPACT_TYPES,
      onSuccess: actionTypes.CHANGE_ASSET_SUCCESS_LOADING_IMPACT_TYPES,
      onError: actionTypes.CHANGE_ASSET_ERROR_LOADING_IMPACT_TYPES
    }
  );
}


function* loadClients() {
 
  yield loadModels(
    ApiAsset.getClients,
    clients => ({clients}),
    () => [],//return an empty array to force the call to the API
    {
      onStart: actionTypes.CHANGE_ASSET_START_LOADING_CLIENTS,
      onSuccess: actionTypes.CHANGE_ASSET_SUCCESS_LOADING_CLIENTS,
      onError: actionTypes.CHANGE_ASSET_ERROR_LOADING_CLIENTS
    }
  );
    
}

function* uploadReport(action) {
  try {
   var fileName = yield call(ApiAsset.uploadReport, action.props.data);
   
    
    if(action.props.callback){
     action.props.callback(fileName);
    }
    
  } catch (error) {
    if(action.props.callback){
      action.props.callback("error");
     }
  }
}


function* getUploadedReport(action) {
  try {
    let result = yield call(ApiAsset.getUploadedReport, action.props.data);
    // update event props
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        mappingData: result,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    console.log("getUploadedReport",error);
  }
}

function* updateMultiple(action) {
  try {
 
    yield call(ApiAsset.updateMultiple, action.props.data);
    // update event props

  } catch (error) {
    console.log("updateMultiple",error);
  }
}

function* getImportData(action) {
  try {

    let result = yield call(ApiAsset.getImportDataPaginated, action.props.data);
    // update event props
    yield put({
      type: actionTypes.CHANGE_ASSET_PROPS, props: {
        importedData: result,
        loading: false,
        errors: ''
      }
    });

  } catch (error) {
    console.log("getImportData",error);
  }
}

function* saveImportedData(action) {
  try {
   
     yield call(ApiAsset.saveImportedData, action.props.data);
    // update event props

  } catch (error) {
    console.log("saveImportedData",error);
  }
}



function* deleteMappedData(action) {
  try {
   
     yield call(ApiAsset.deleteMappedData, action.props.data);
  } catch (error) {
    console.log("deleteMappedData",error);
  }
}

function* cancelImport(action) {
  try {
   
     yield call(ApiAsset.cancelImport, action.props.data);

     action.props.callback()

  } catch (error) {
    console.log("deleteMappedData",error);
  }
}



/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ASSET_ALL, getAssets),
    takeLatest(actionTypes.SAGA_ASSET_GET, getAsset),
    takeLatest(actionTypes.SAGA_ASSET_LEVEL_ONE_GET, getAssetLevelOne),
    takeLatest(actionTypes.SAGA_ASSETCHILD_GET, getAssetChild),
    takeLatest(actionTypes.SAGA_ASSETCHILD_EDIT, editAssetChild),
    takeLatest(actionTypes.SAGA_ASSET_SEARCH, searchAssets),
    takeLatest(actionTypes.SAGA_ASSET_EDIT, editAsset),
    takeLatest(actionTypes.SAGA_ASSET_ADD, addAsset),
    takeLatest(actionTypes.SAGA_ASSET_MULTI_ADD, addMultiAsset),
    takeLatest(actionTypes.SAGA_SUBDIVISION_ASSETS, getBySubdivision),
    takeLatest(actionTypes.SAGA_ASSET_ASSETS, getByParent),
    takeLatest(actionTypes.SAGA_SUBDIVISION_ASSETS_SEARCH, searchBySubdivision),
    takeLatest(actionTypes.SAGA_CLIENT_ASSETS_SEARCH, searchByClient),
    takeLatest(actionTypes.SAGA_ASSET_DELETE, deleteAsset),
    takeLatest(actionTypes.SAGA_ASSET_BY_RAILROADS, searchByRailroads),
    takeLatest(actionTypes.SAGA_ASSET_BY_SUBDIVISIONS, searchBySubdivisions),
    takeLatest(actionTypes.SAGA_ASSET_ALL_FIELDS, getAllFields),
    takeLatest(actionTypes.SAGA_ASSET_CHILDREN, getChildren),
    takeLatest(actionTypes.SAGA_ASSET_DELETE_CHECKED, deleteAssetChecked),
    takeLatest(actionTypes.SAGA_ASSET_BY_STRUCTURE_TYPE, getChildrenByStructureType),
    takeLatest(actionTypes.SAGA_SUBDIVISION_SECTIONS, getSectionBySubdivision),
    takeLatest(actionTypes.SAGA_ASSET_IMMEDIATE_ACTIONS, addImmediateActions),
    takeLatest(actionTypes.SAGA_ASSET_FOR_TRIP, searchAssetsForTrip),
    takeLatest(actionTypes.SAGA_ASSET_FOR_TRIP_CALLBACK, searchAssetsForTripCallback),
    takeLatest(actionTypes.SAGA_TRIP_SECTIONS_SEARCH, searchByTrip),
    takeLatest(actionTypes.SAGA_TRIP_SECTIONS_SEARCH_CALLBACK, searchByTripCallback),
    takeLatest(actionTypes.SAGA_ASSET_FOR_FILTERS, getBridgesForFilters),
    takeLatest(actionTypes.SAGA_ASSET_ADD_COVER_PHOTO, addCoverPhoto),
    takeLatest(actionTypes.SAGA_ASSET_UPLOAD_PHOTO, uploadAssetPhoto),
    takeLatest(actionTypes.SAGA_ASSET_GET_ASSET_TYPES, getAssetTypes),
    takeLatest(actionTypes.SAGA_ASSET_GET_CROSSING_FEATURES, getCrossingFeatures),
    takeLatest(actionTypes.SAGA_ASSET_GET_WATER_FLOWS, getWaterFlows),
    takeLatest(actionTypes.SAGA_ASSET_GET_ALIGNMENTS, getAlignments),
    takeLatest(actionTypes.SAGA_ASSET_GET_IMPACT_TYPES, getImpactTypes),
    takeLatest(actionTypes.SAGA_ASSET_LOAD_CLIENTS, loadClients),
    takeLatest(actionTypes.SAGA_EVENT_UPLOAD_REPORT, uploadReport),
    takeLatest(actionTypes.SAGA_EVENT_GET_UPLOADED_REPORT, getUploadedReport),
    takeLatest(actionTypes.SAGA_EVENT_UPDATE_MULTIPLE, updateMultiple),
    takeLatest(actionTypes.SAGA_EVENT_GET_IMPORT_DATA, getImportData),
    takeLatest(actionTypes.SAGA_EVENT_SAVE_IMPORTED_DATA, saveImportedData),
    takeLatest(actionTypes.SAGA_EVENT_DELETE_MAPPED_DATA, deleteMappedData),
    takeLatest(actionTypes.SAGA_EVENT_CANCEL_BRIDGE_IMPORT, cancelImport),
  ]);
}

export default AuthWatcher;
