import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
  loading: false,
  claims: [],
  claim: {},
  formValues: null,
  pagination: {},
  errors: '',
  userClaims: [],
}

function reducerClaim (state = initialState, action) {
  switch (action.type) {

    case actionTypes.CHANGE_CLAIM_PROPS:
      return Object.assign({}, state, action.props)

    case actionTypes.CLEAR_CLAIMS_PROPS:
      return initialState;

    case actionTypes.CLEAR_CLAIMS_ERRORS:
      return {
        ...state,
        errors: ''
      }


    default:
      return state
  }
}

export default onLoginCleanupReducer(initialState, reducerClaim);
