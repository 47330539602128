import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects';
import * as actionTypes from 'store/actions/actionTypes';
// APIs to talk to...
import ApiRole from 'apiServices/ApiRole';

import { clearRoleErrors } from '../actions/RoleActions';
import { showRecordAddedToast, showRecordUpdatedToast, showRecordDeletedToast } from '../actions/ToastActions';

const recordType = "role";

function* loading(loading = true, errors = "") {
  // set loading state
  loading = !!loading;

  // error handling
  if (errors) {
    let errorMessage = errors.response.data.message;
    errors = errorMessage.toLowerCase().includes('constraintviolationexception') ?
      'The Role cannot be removed because it is related to other objects in the application.'
      :
      'Something has not gone as it should.';
  }

  // update store props
  const props = { loading, errors };
  yield put({ type: actionTypes.CHANGE_ROLE_PROPS, props });
}

/*
 * get Roles
 */
function* getRoles() {
  try {
    yield loading();
    // get all Roles
    const roles = yield call(ApiRole.getAll);
    yield put({
      type: actionTypes.CHANGE_ROLE_PROPS, props: {
        roles: roles,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }
}

function* searchRoles(action) {
  try {
    yield loading();
    // get all Roles
    const roles = yield call(ApiRole.searchRoles, action.props);
    const pagination = {
      number: roles.number,
      numberOfElements: roles.numberOfElements,
      totalElements: roles.totalElements,
      totalPages: roles.totalPages,
      size: roles.size
    };
    // update state for roles
    yield put({
      type: actionTypes.CHANGE_ROLE_PROPS, props: {
        roles: roles.content,
        pagination: pagination,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }
}

function* getRole(action) {
  try {
    yield loading();
    // get  Role
    const role = yield call(ApiRole.getRole, action.props.roleId);
    yield putResolve({
      type: actionTypes.CHANGE_NAMES_PROPS, props: {
        name: role.name,
        id: role.id,
        type: "role"
      }
    });
    // update state for roles
    yield put({
      type: actionTypes.CHANGE_ROLE_PROPS, props: {
        role: role,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield loading(false, error);
  }

}

function* editRole(action) {

  yield put(clearRoleErrors());

  try {
    yield loading();
    // update  Role
    yield call(ApiRole.editRole, action.props.values);
    // update state for roles
    yield put({
      type: actionTypes.CHANGE_ROLE_PROPS, props: {
        role: {},
        loading: false,
        errors: ''
      }
    });
    // yield getRole({ props: { roleId: action.props.values.id } });
    // const newUser = action.props.user;
    // newUser.authorities = yield call(ApiRole.getUserClaims);
    // yield put({
    //   type: actionTypes.CHANGE_USER_PROPS, props: {
    //     user : newUser
    //   }
    // });

    yield put(showRecordUpdatedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* addRole(action) {

  yield put(clearRoleErrors());

  try {
    yield loading();
    // add  Role
    yield call(ApiRole.addRole, action.props);
    // update state for roles
    yield put({
      type: actionTypes.CHANGE_ROLE_PROPS, props: {
        role: {},
        loading: false,
        errors: ''
      }
    });
    yield put(showRecordAddedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }

}

function* deleteRole(action) {

  yield put(clearRoleErrors());

  try {
    yield loading();
    // delete  Role
    yield call(ApiRole.deleteRole, action.props);

    // update state for roles
    yield put({
      type: actionTypes.CHANGE_ROLE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }
    yield put(showRecordDeletedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }
}

function* deleteRoleList(action) {

  yield put(clearRoleErrors());

  try {
    yield loading();
    // delete  Role
    yield call(ApiRole.deleteRoleList, action.props.list);

    // update state for roles
    yield put({
      type: actionTypes.CHANGE_ROLE_PROPS, props: {
        loading: false,
        errors: ''
      }
    });

    if (action.props.callback) {
      yield call(action.props.callback);
    }

    yield put(showRecordDeletedToast(recordType));

  } catch (error) {
    yield loading(false, error);
  }
}




function* getParentOrganizationRoles() {
  try {
    yield loading();
    // get all Roles
    const roles = yield call(ApiRole.getAllParentOrganization);
    yield put({
      type: actionTypes.CHANGE_ROLE_PROPS, props: {
        roles: roles,
        loading: false,
        errors: ''
      }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_PERMISSIONS_PROPS,
      props: {
        permissionsForthis: false
      }
    });
    yield loading(false, error);
  }
}

function* getOrganizations() {
  try {
    yield put({
      type: actionTypes.CHANGE_ROLE_START_LOADING_ORGANIZATIONS
    });

    const organizations = yield call(ApiRole.getOrganizations);

    yield put({
      type: actionTypes.CHANGE_ROLE_SUCCESS_LOADING_ORGANIZATIONS,
      props: {
        organizations
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_ROLE_ERROR_LOADING_ORGANIZATIONS,
      error: e.message
    })
  }
}

function* getClaims() {
  try {
    yield put({
      type: actionTypes.CHANGE_ROLE_START_LOADING_CLAIMS
    });

    const claims = yield call(ApiRole.getClaims);

    yield put({
      type: actionTypes.CHANGE_ROLE_SUCCESS_LOADING_CLAIMS,
      props: {
        claims
      }
    });

  } catch(e) {
    yield put({
      type: actionTypes.CHANGE_ROLE_ERROR_LOADING_CLAIMS,
      error: e.message
    })
  }
}

/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_ROLE_ALL, getRoles),
    takeLatest(actionTypes.SAGA_ROLE_SEARCH, searchRoles),
    takeLatest(actionTypes.SAGA_ROLE_GET, getRole),
    takeLatest(actionTypes.SAGA_ROLE_EDIT, editRole),
    takeLatest(actionTypes.SAGA_ROLE_ADD, addRole),
    takeLatest(actionTypes.SAGA_ROLE_DELETE, deleteRole),
    takeLatest(actionTypes.SAGA_ROLE_DELETE_CHECKED, deleteRoleList),
    takeLatest(actionTypes.SAGA_ROLE_ALL_PARENT_ORGANIZATION, getParentOrganizationRoles),
    takeLatest(actionTypes.SAGA_ROLE_GET_ORGANIZATIONS, getOrganizations),
    takeLatest(actionTypes.SAGA_ROLE_GET_CLAIMS, getClaims)
  ]);
}

export default AuthWatcher;
