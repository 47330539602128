import * as actionTypes from './actionTypes'

/**
 * Redux action creators for "Assembly Type"
 * @namespace AssemblyTypeActions
 */

/**
 * @memberof AssemblyTypeActions
 * @description Creates a Redux action that can be dispatched for
 * fetching all "Assembly Type".
 *
 * This will trigger a SAGA action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getAssemblyTypes(){
  return { type: actionTypes.SAGA_ASSEMBLYTYPE_ALL };
}

/**
 * @memberof AssemblyTypeActions
 * @deprecated We are not using pagination to get this data anymore
 * @description Creates a Redux action that can be dispatched for
 * fetching "Assembly Type" paged with filters and order.
 *
 * this will trigger a SAGA action.
 *
 * @param {object} props //TODO document the structure of props.
 * @returns A Redux action that can be dispatched.
 */
export function loadAssemblyTypesPaged(props){
  return { type: actionTypes.SAGA_ASSEMBLYTYPE_SEARCH, props: props };
}

/**
 * @memberof AssemblyTypeActions
 * @description Creates a Redux action that can be dispatched for
 * fetching a single "Assembly Type".
 *
 * this will trigger a SAGA action.
 *
 * @param {object} props
 * @param {string} props.assemblyTypeId - the ID of the assembly type to fetch.
 * @returns A Redux action that can be dispatched.
 */
export function getAssemblyType(props){
  return { type: actionTypes.SAGA_ASSEMBLYTYPE_GET, props: props };
}

/**
 * @memberof AssemblyTypeActions
 * @description Creates a Redux action that can be dispatched for
 * the edition of an "Assembly Type".
 *
 * this will trigger a SAGA action.
 *
 * Any data included in the props parameter will be sent
 * to edit the "Assembly Type".
 *
 * @param {object} props - data of the "Assembly Type".
 * @param {string} props.id - the ID of the "Assembly Type" to edit.
 * @returns A Redux action that can be dispatched.
 */
export function editAssemblyType(props){
  return { type: actionTypes.SAGA_ASSEMBLYTYPE_EDIT, props: props };
}

/**
 * @memberof AssemblyTypeActions
 * @description Creates a Redux action that can be dispatched for
 * the creation of an "ASsembly Type".
 *
 * This will trigger a SAGA action.
 *
 * Any data included in the props parameter will be sent to
 * the creation of the "Assembly Type".
 *
 * @param {object} props - data of the "Assembly Type".
 * @returns A Redux action that can be dispatched.
 */
export function addAssemblyType(props){
  return { type: actionTypes.SAGA_ASSEMBLYTYPE_ADD, props: props };
}

/**
 * @memberof AssemblyTypeActions
 * @deprecated Changed for deleteAssemblyTypeList to delete a list or a single element
 * @description Creates a Redux action that can be dispatched for
 * the deletion of an "Assembly Type".
 *
 * This will trigger a SAGA action.
 *
 * @param {string} assemblyTypeId - the ID of the "Assembly Type" to delete.
 * @returns A Redux action that can be dispatched.
 */
export function deleteAssemblyType(assemblyTypeId){
  return { type: actionTypes.SAGA_ASSEMBLYTYPE_DELETE, props: assemblyTypeId };
}

/**
 * @memberof AssemblyTypeActions
 * @description Creates a Redux action that can be dispatched to trigger
 * the deletion of multiple "Assembly Type".
 *
 * This will trigger a SAGA action.
 *
 * @param {string[]} list - IDs of the "Assembly Type" to delete.
 * @param {object} options - Additional options.
 * @param {function} options.callback - A callback to be called when
 * the deletion of the "Assembly Type" is completed successfully.
 * @returns A Redux action that can be dispatched.
 */
export function deleteAssemblyTypeList(list, options = {callback: () => {}}) {
  return {
    type: actionTypes.SAGA_ASSEMBLYTYPE_DELETE_CHECKED,
    props: {
      list,
      callback: options.callback
    }
  };
}

/**
 * @memberof AssemblyTypeActions
 * @deprecated User a more granular action.
 * @deprecated Change AssemblyType props.
 *
 * This will not trigger a SAGA action.
 *
 * @param {object} props
 * @returns A Redux action that can be dispatched.
 */
export function changeAssemblyTypeProps(props){
  return { type: actionTypes.CHANGE_ASSEMBLYTYPE_PROPS, props: props };
}

/**
 * @memberof AssemblyTypeActions
 * @description Clears AssemblyType props. Use this
 * to reset the store of "Assembly Type" to the initial
 * state.
 *
 * This will not trigger a SAGA action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function clearAssemblyTypeProps() {
  return { type: actionTypes.CLEAR_ASSEMBLY_TYPE_PROPS };
}

/**
 * @memberof AssemblyTypeActions
 * @description Clears Assembly Type current errors messages.
 *
 * This will not trigger a SAGA action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function clearAssemblyTypeErrors() {
  return { type: actionTypes.CLEAR_ASSEMBLY_TYPE_ERRORS };
}

/**
 * @memberof AssemblyTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available organizations for Assembly Types.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function loadOrganizations() {
  return { type: actionTypes.SAGA_ASSEMBLYTYPE_LOAD_ORGANIZATIONS };
}

/**
 * @memberof AssemblyTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available assemblyTypes for a client.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
export function getAssemblyTypesByOrganization() {
  return {
    type: actionTypes.SAGA_ASSEMBLYTYPES_BY_ORGANIZATION,
  };
}

/**
 * @memberof AssemblyTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available assemblyTypes for a client.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
 export function getAssemblyTypesByStructure(structureTypeId) {
  return {
    type: actionTypes.SAGA_ASSEMBLYTYPES_BY_STRUCTURE, props: structureTypeId
  };
}

/**
 * @memberof AssemblyTypeActions
 * @description Creates a Redux action that can be dispatched
 * to load available approachesTypes for Form.
 *
 * This will trigger a Saga action.
 *
 * @returns A Redux action that can be dispatched.
 */
 export function getAllApproachesTypes() {
  return {
    type: actionTypes.SAGA_ASSEMBLYTYPES_APPROACHES_TYPES
  };
}