import React, { useEffect } from 'react';

import PropTypes from 'common/propTypes';
import bn from 'common/bemnames';

import { connect } from 'react-redux';
import { changeHeaderProps } from 'store/actions/HeaderActions'

/**
 * @private
 */
const bem = bn.create('page');

/**
 * @memberof Page
 * @typedef changeHeaderPropsParam
 * @property {string} props.title
 * @property {object} props.breadcrumbs
 * @property {object} props.railroad
 * @property {object} props.subdivision
 */

/**
 * @memberof Page
 * @callback changeHeaderPropsCallback
 * @param {changeHeaderPropsParam} props
 * @returns {object}
 */

/**
 * @memberof Page
 * @typedef {object} Breadcrumb
 * @property {string} name - Name to display.
 * @property {boolean} active - If it is active.
 */

/**
 * @description Defines the structure of a page.
 * @function Page
 * @param {object} props - React props.
 * @param {string|Class} props.title - Title of the page.
 * @param {Breadcrumb[]} props.breadcrumbs - Breadcrumbs to display.
 * @param {string} props.className - Additional CSS classes to be applied to the container element.
 * @param {any[]} props.children - Elements to be rendered inside the page.
 * @param {object} [props.railroad={}] - Railroad data.
 * @param {object} [props.subdivision={}] - Subdivision data.
 * @param {boolean} [props.withoutpadding=false] - If the padding has to be removed.
 * @param {changeHeaderPropsCallback} props.changeHeaderProps
 *
 */
const Page = (props) => {

  const {
    title, 
    breadcrumbs, 
    railroad, 
    subdivision,
    changeHeaderProps,
    className, 
    tag: Tag, 
    children, 
    withoutpadding, 
    ...otherProps
  } = props

  //pass the header attributes to render title and breadcrumbs
  useEffect(() => {
    let element = document.getElementsByTagName("html");
    if (element && element[0] && element[0].style[0] !== "overflow-y") {
      element[0].style.cssText = 'overflow-y: auto';
    }
  }, [])

  useEffect(()=>{
    changeHeaderProps({ title: title })
  },[title])

  useEffect(()=>{
    changeHeaderProps({ breadcrumbs: breadcrumbs })
  },[breadcrumbs])

  useEffect(()=>{
    changeHeaderProps({ railroad: railroad })
  },[railroad])

  useEffect(()=>{
    changeHeaderProps({ subdivision: subdivision })
  },[subdivision])

  otherProps.title = "";

  let showPadding = withoutpadding ? '' : '';
  const classes = bem.b(showPadding, className);
  return (
    <Tag className={classes + " header-fixed"} {...otherProps}>
      {children}
    </Tag>
  );
}


Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  withoutpadding: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
};

Page.defaultProps = {
  tag: 'div',
  title: '',
};



export default connect(null, { changeHeaderProps })(Page);
