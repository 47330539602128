import { all, call, put, takeLatest, putResolve } from 'redux-saga/effects'
import * as actionTypes from 'store/actions/actionTypes'
// APIs to talk to...
//import ApiWaterFlow from 'tests/mocks/ApiWaterFlow.mock'
import ApiWaterFlow from 'apiServices/ApiWaterFlow'
import CookieManager from 'common/cookieManager'


/*
 * get WaterFlow
 */
function* getWaterFlows(){
  // get water flows
  const allSections = yield call(ApiWaterFlow.getAll);
  // update state for sections
  yield put({ type: actionTypes.CHANGE_WATERFLOW_PROPS, props:{
    waterFlows: allSections,
  }});
}

function* searchWaterFlows(action){
  // get all WaterFlow
  const waterFlows = yield call(ApiWaterFlow.searchWaterFlow, action.props);
  const pagination = { number: waterFlows.number,
	                     numberOfElements: waterFlows.numberOfElements,
	                     totalElements: waterFlows.totalElements,
	                     totalPages: waterFlows.totalPages,
	                     size: waterFlows.size};
  // update state for waterFlow
  yield put({ type: actionTypes.CHANGE_WATERFLOW_PROPS, props:{
    waterFlows: waterFlows.content,
    pagination: pagination
  }});
}

function* getWaterFlow(action){
  // get  WaterFlow
  const waterFlow = yield call(ApiWaterFlow.getWaterFlow, action.props.waterFlowId);
  yield putResolve({type: actionTypes.CHANGE_NAMES_PROPS, props:{
    name: waterFlow.name,
    id: waterFlow.id,
    type: "waterFlow"
  }});
  // update state for waterFlow
  yield put({ type: actionTypes.CHANGE_WATERFLOW_PROPS, props:{
    waterFlow: waterFlow,
  }});
}

function* editWaterFlow(action){
  // update  WaterFlow
  yield call(ApiWaterFlow.editWaterFlow, action.props);
  // update state for waterFlow
  yield put({ type: actionTypes.CHANGE_WATERFLOW_PROPS, props:{
    waterFlow: {},
  }});
  const cookieStored = CookieManager.getCookiesForTable("WaterFlow")
  yield searchWaterFlows(CookieManager.getObjectForSearch(cookieStored));
}

function* addWaterFlow(action){
  // add  WaterFlow
  yield call(ApiWaterFlow.addWaterFlow, action.props);
  // update state for waterFlow
  yield put({ type: actionTypes.CHANGE_WATERFLOW_PROPS, props:{
    waterFlow: {},
  }});
  const cookieStored = CookieManager.getCookiesForTable("WaterFlow")
  yield searchWaterFlows(CookieManager.getObjectForSearch(cookieStored));
}

function* deleteWaterFlow(action){
  // delete  WaterFlow
  yield call(ApiWaterFlow.deleteWaterFlow, action.props);
  const cookieStored = CookieManager.getCookiesForTable("WaterFlow")
  yield searchWaterFlows(CookieManager.getObjectForSearch(cookieStored));
}
/*
 * Watcher
 */
function* AuthWatcher() {
  yield all([
    takeLatest(actionTypes.SAGA_WATERFLOW_ALL, getWaterFlows),
    takeLatest(actionTypes.SAGA_WATERFLOW_SEARCH, searchWaterFlows),
    takeLatest(actionTypes.SAGA_WATERFLOW_GET, getWaterFlow),
    takeLatest(actionTypes.SAGA_WATERFLOW_EDIT, editWaterFlow),
    takeLatest(actionTypes.SAGA_WATERFLOW_ADD, addWaterFlow),
    takeLatest(actionTypes.SAGA_WATERFLOW_DELETE, deleteWaterFlow),
  ]);
}

export default AuthWatcher;
