import * as actionTypes from '../actions/actionTypes';
import onLoginCleanupReducer from './generic/onLoginCleanupReducer';

const initialState = {
  onChangeSearchInput: false,
  sideBar: true,
  title :'Page',
  breadcrumbs:[],
}

function reducer (state = initialState, action) {
  if (action.type === actionTypes.CHANGE_HEADER_PROPS) {
    if (action.props.sideBar != null){
        var openSideBar = sessionStorage.getItem('sideBar') === 'true';
        openSideBar = action.props.sideBar;
        sessionStorage.setItem('sideBar', openSideBar);
    }
    return Object.assign({}, state, action.props)
  }
  return state
}

export default onLoginCleanupReducer(initialState, reducer)
