export const INITIAL_NAMES = {
  client: {name:'', id:''},
  railroad: {name:'', id:''},
  subdivision: {name:'', id:''},
  bridge: {name:'', id:''},
  asset: {name:'', id:''},
  accessType: {name:'', id:''},
  assetChild: {name:'', id:''},
  event: {name:'', id:''},
  inspection: {name:'', id:''},
  assembly: {name:'', id:''},
  component: {name:'', id:''},
  message: {name:'', id:''},
  messageField: {name:'', id:''},
  assemblyType: {name:'', id:''},
  componentType: {name:'', id:''},
  componentGroup: {name:'', id:''},
  role: {name:'', id:''},
  eventType: {name:'', id:''},
  claim: {name:'', id:''},
  structureType: {name:'', id:''},
  impactType: {name:'', id:''},
  assetType: {name:'', id:''},
  crossingFeature: {name:'', id:''},
  assetEvent: {name:'', id:''},
  sectionEvent: {name:'', id:''},
  trip: {name:'', id:''},
  contact: {name:'', id:''},
  organization: {name:'', id:''},
  condition: {name:'', id:''},
  location: {name:'', id:''},
  element: {name: '', id: ''},
  directionalLoading: {name:'', id:''},
  waterFlow: {name:'', id:''},
  alignment: {name:'', id:''},
  deckType: {name:'', id:''},
  jobTitle: {name:'', id:''},
};
